import { format } from 'date-fns'
import { Box, css, Theme, Typography, useTheme } from '@mui/material'

import { Module, ModuleStatus } from '@queries/modules'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'

import { getModuleIcon, getModuleTag } from './ModuleCard.utils'

const generateModuleCardHeaderCssFromTheme = (theme: Theme) => ({
  header: css({
    backgroundColor: theme.vars.palette.secondary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1rem',
  }),
  headerLeftSection: css({
    display: 'flex',
    gap: '1rem',
  }),
  statusChip: css({
    alignSelf: 'flex-start',
  }),
})

type ModuleCardHeaderProps = {
  module: Module
}

const ModuleCardHeader: React.FC<ModuleCardHeaderProps> = ({
  module: { status, title, type, activationDate },
}) => {
  const theme = useTheme()
  const moduleCardHeaderCss = generateModuleCardHeaderCssFromTheme(theme)
  const isAvailable = status === ModuleStatus.AVAILABLE

  const moduleTag = getModuleTag({ status })

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '1rem',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.main,
        }),
      })}
      data-testid="module-card-header"
    >
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.common.white,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.75rem',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.darker,
            }),
          })}
        >
          <Icon
            data-testid="module-card-icon"
            variant={getModuleIcon({ type, status }) as IconVariant}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            justifyContent: 'center',
          }}
        >
          <Typography
            fontWeight={600}
            variant="h6"
            sx={(theme) => ({
              color: theme.vars.palette.text.secondary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {title}
          </Typography>
          {!isAvailable && activationDate && (
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.secondary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {`Activated ${format(activationDate, 'MMMM d, yyyy')}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={moduleCardHeaderCss.statusChip}>{moduleTag}</Box>
    </Box>
  )
}

export default ModuleCardHeader
