import { Box } from '@mui/material'

import ColumnElement from './ColumnElement'

export interface SidesheetDualColumnValues {
  key: string
  label: string | JSX.Element
  /**
   * If the value supplied is a [React element](https://react.dev/reference/react/isValidElement), it will be rendered as-is.
   * Otherwise, it will be wrapped in a `<Typography />` component.
   */
  value: number | string | string[] | JSX.Element
}

interface SidesheetDualColumnProps {
  items: SidesheetDualColumnValues[]
}

const SidesheetDualColumn: React.FC<SidesheetDualColumnProps> = ({ items }) => {
  const filteredItems = items.filter(
    ({ value }) => !Array.isArray(value) || value.length > 0,
  )

  return (
    <Box
      data-testid="sidesheet-dual-column"
      sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
    >
      {filteredItems.map(({ key, label, value }) => (
        <ColumnElement key={key} label={label} value={value} />
      ))}
    </Box>
  )
}

export default SidesheetDualColumn
