import { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import fetchErrorIcon from '@app-assets/fetch-error.svg'
import {
  MODULES,
  ModuleStatus,
  ModuleUnion,
  ModulesData,
  ModulesVariables,
} from '@queries/modules'
import { Context } from '@components/App'
import { ComponentError } from '@common/ComponentError'
import { Loader } from '@common/Loader'

import ModuleCard from './components/ModuleCard'

const Modules: React.FC = () => {
  const [availableModules, setAvailableModules] = useState<ModuleUnion[]>([])
  const [activeModules, setActiveModules] = useState<ModuleUnion[]>([])

  const {
    state: { dwExpertsCustomer },
  } = useContext(Context)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { error, loading } = useQuery<ModulesData, ModulesVariables>(MODULES, {
    variables: {
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
    },
    onCompleted: ({ modules }) => {
      if (modules) {
        setActiveModules(
          Object.values(modules)?.filter(
            (module) =>
              module?.status === ModuleStatus.ACTIVE ||
              module?.status === ModuleStatus.ONBOARDING,
          ),
        )
        setAvailableModules(
          Object.values(modules)?.filter(
            (module) => module?.status === ModuleStatus.AVAILABLE,
          ),
        )
      }
    },
  })

  if (error) {
    return <ComponentError includeReloadButton errorIcon={fetchErrorIcon} />
  }

  return (
    <Box
      id="modules-page"
      data-testid="modules"
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        overflowY: 'auto',
        padding: '1.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          maxWidth: '700px',
        }}
      >
        <Box
          data-testid="module-page-header"
          sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        >
          <Typography fontWeight={600} variant="body1">
            Deepwatch modules
          </Typography>
          <Typography variant="body2">
            Deepwatch offers a wide variety of modules. If you&apos;re
            interested in a new service that Deepwatch offers, request a meeting
            to learn more.
          </Typography>
        </Box>

        <Box>
          {loading ? (
            <Loader centered={true} strokeWidth={1} size={120} />
          ) : (
            <>
              <Box
                data-testid="my-modules"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem 0',
                }}
              >
                <Typography fontWeight={600} variant="body1">
                  My modules
                </Typography>
                {activeModules.map((module) => (
                  <ModuleCard module={module} key={module.type} />
                ))}
              </Box>

              {!!availableModules.length && (
                <>
                  <Box
                    component="hr"
                    sx={(theme) => ({
                      border: `0.5px solid ${theme.vars.palette.secondary.light}`,
                      margin: '2rem 0',
                    })}
                  />
                  <Box
                    data-testid="available-modules"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    <Typography fontWeight={600} variant="body1">
                      Available modules
                    </Typography>
                    <Typography variant="body2">
                      Modules that Deepwatch offers that you do not currently
                      have enabled. If you&rsquo;re interested in learning more,
                      you can request a meeting.
                    </Typography>
                    <Typography variant="body2">
                      If you&rsquo;re interested in a service, reach out to your
                      Account Executive on Slack.
                    </Typography>

                    {availableModules.map((module) => (
                      <ModuleCard module={module} key={module.type} />
                    ))}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Modules
