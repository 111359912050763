import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ThreatIntelReportDetail } from '@models/ThreatIntel'
import { SidesheetDualColumn } from '@common/SidesheetDualColumn'
import { DetectionCatalogStatusTag } from '@components/Coverage/DetectionCatalog/DetectionCatalogStatusTag'
import { formatStatus } from '@components/Coverage/DetectionCatalog/DetectionCatalog.utils'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import { defaultTheme } from '@mui-theme/index'
import { useCustomer } from '@hooks/useCustomer'

import RelatedTicketsTable from '../RelatedTicketsTable/RelatedTicketsTable'

type ThreatIntelSideSheetDetailsProps = Pick<
  ThreatIntelReportDetail,
  'relatedTickets' | 'associatedDetections' | 'targetedIndustries'
>

const ThreatIntelSideSheetDetails = ({
  relatedTickets,
  associatedDetections,
  targetedIndustries,
}: ThreatIntelSideSheetDetailsProps) => {
  const {
    customer: { isLimitedMdrExperience },
  } = useCustomer()
  const { featureNgMdr } = useFlags()
  const renderTargetedIndustries = () => {
    let value: string | string[] = 'Not applicable'

    if (targetedIndustries) {
      if (targetedIndustries.length > 1) {
        value = targetedIndustries
      } else if (targetedIndustries.length === 1) {
        value = targetedIndustries[0]
      }
    }

    return (
      <CollapsiblePanel title="Details">
        <SidesheetDualColumn
          items={[
            {
              key: 'Targeted industries',
              label: 'Targeted industries',
              value,
            },
          ]}
        />
      </CollapsiblePanel>
    )
  }

  const renderDetections = (): JSX.Element => {
    if (associatedDetections.length > 0) {
      return (
        <Box>
          <List>
            {associatedDetections.map((detection) => {
              const status = formatStatus(detection.status)
              return (
                <ListItem
                  key={detection.title}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                      '.MuiListItemText-primary': {
                        color: defaultTheme.palette.text.primary,
                      },
                    },
                  }}
                >
                  <ListItemText
                    primary={detection.title}
                    sx={{
                      width: '70%',
                      '.MuiListItemText-primary': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                  <ListItemText
                    sx={{
                      textAlign: 'right',
                      margin: 'auto 10px auto auto',
                      '.MuiTypography-h5': {
                        fontSize: '0.75rem',
                      },
                    }}
                  >
                    <DetectionCatalogStatusTag status={status.status} />
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
        </Box>
      )
    }
    return (
      <Typography color="textPrimary" variant="body2">
        This threat intelligence report has no associated detections
      </Typography>
    )
  }

  const shouldShowDetections =
    !(featureNgMdr && isLimitedMdrExperience) && associatedDetections.length > 0

  return (
    <Box data-testid="threat-intel-sidesheet-details">
      {!!relatedTickets.length && (
        <Box data-testid="related-tickets-details" sx={{ padding: '1rem' }}>
          <Typography
            fontWeight={600}
            variant="body1"
            sx={{ padding: '1rem 0' }}
          >{`${relatedTickets.length} related tickets`}</Typography>

          <RelatedTicketsTable data={relatedTickets} />
        </Box>
      )}

      {shouldShowDetections ? (
        <CollapsiblePanel
          title="Detections"
          count={associatedDetections.length}
        >
          {renderDetections()}
        </CollapsiblePanel>
      ) : null}

      {renderTargetedIndustries()}
    </Box>
  )
}

export default ThreatIntelSideSheetDetails
