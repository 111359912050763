import { colors } from '@design-system/theme'

import { CustomSVGProps } from './types'

const MacOSLogo: React.FC<CustomSVGProps> = ({
  primaryColor = colors.util.navy[100],
  size = 20,
}) => (
  <svg
    data-testid="mac-logo"
    height={size}
    width={size}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / logo-apple">
      <path
        id="Vector"
        d="M12.274 4.81128C10.8565 4.81128 10.2575 5.48769 9.27028 5.48769C8.25813 5.48769 7.4861 4.8162 6.25774 4.8162C5.0554 4.8162 3.77325 5.55026 2.95903 6.80077C1.81575 8.56421 2.00982 11.8854 3.86149 14.7148C4.52384 15.7276 5.40837 16.8635 6.56853 16.8759H6.58962C7.5979 16.8759 7.89743 16.2156 9.28505 16.2079H9.30614C10.673 16.2079 10.9472 16.872 11.9513 16.872H11.9724C13.1325 16.8597 14.0645 15.6011 14.7269 14.5921C15.2036 13.8665 15.3808 13.5023 15.7464 12.6814C13.0679 11.6646 12.6375 7.86741 15.2866 6.41159C14.478 5.39909 13.3417 4.81269 12.2705 4.81269L12.274 4.81128Z"
        fill={primaryColor}
      />
      <path
        id="Vector_2"
        d="M11.9618 1.125C11.1181 1.1823 10.1337 1.71949 9.55713 2.42086C9.034 3.05648 8.60369 3.99937 8.77244 4.91379H8.83994C9.73853 4.91379 10.6582 4.37273 11.1954 3.67945C11.7129 3.01957 12.1052 2.08441 11.9618 1.125Z"
        fill={primaryColor}
      />
    </g>
  </svg>
)

export default MacOSLogo
