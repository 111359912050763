import {
  RadialBarDatum,
  RadialBarSerie,
  ResponsiveRadialBar,
} from '@nivo/radial-bar'
import { Box, useColorScheme, useTheme } from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import { useSecurityIndexContext } from '@hooks/useSecurityIndexContext'
import { highlightMetric } from '@components/Home/SecurityIndex/SecurityIndex.utils'
import { Loader } from '@common/Loader'
import { useDateFilterableStyles } from '@hooks/index'

interface SiMetricsChartProps {
  active: string
}

const SiMetricsChart = ({ active }: SiMetricsChartProps) => {
  const {
    yourIndex,
    preTransformedIndexValues,
    yourIndexLoading,
    yourIndexError,
  } = useSecurityIndexContext()

  const { mode } = useColorScheme()
  const theme = useTheme()

  const chartMaxValues = {
    logSourceValue: 50,
    detectionsValue: 30,
    enterpriseValue: 20,
  }

  const chartMetrics = [
    [
      {
        id: 'si-source-types',
        data: [{ x: 'percentage', y: preTransformedIndexValues?.logSources }],
        color: theme.vars.palette.info.main,
      },
    ],
    [
      {
        id: 'si-detections',
        data: [{ x: 'percentage', y: preTransformedIndexValues?.detections }],
        color: theme.vars.palette.important.main,
      },
    ],
    [
      {
        id: 'si-enterprise-coverage',
        data: [
          { x: 'percentage', y: preTransformedIndexValues?.enterpriseCoverage },
        ],
        color:
          mode === 'light'
            ? theme.vars.palette.success.light
            : theme.vars.palette.success.main,
      },
    ],
  ]

  const [logSourcesMetric, detectionsMetric, enterpriseCoverageMetric] =
    chartMetrics
  const { id: logSourcesId, color: logSourcesColor } = logSourcesMetric[0]
  const { id: detectionsId, color: detectionsColor } = detectionsMetric[0]
  const { id: enterpriseCoverageId, color: enterpriseCoverageColor } =
    enterpriseCoverageMetric[0]
  const yourIndexMetric = `${
    Math.round(Number(yourIndex?.latestIndex) * 100) / 100
  }`

  const metric = ({ center }) => {
    return (
      <Box component="g" tabIndex={0} data-testid="metric">
        <Box
          component="text"
          x={center[0]}
          y={center[1] - 12}
          textAnchor="middle"
          dominantBaseline="central"
          sx={(theme) => ({
            fontSize: '28px',
            fontWeight: '600',
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {yourIndexMetric}
        </Box>
      </Box>
    )
  }

  const yourIndexText = ({ center }) => {
    return (
      <Box component="g" tabIndex={0} data-testid="your-index-text">
        <Box
          component="text"
          x={center[0] + 2}
          y={center[1] + 12}
          textAnchor="middle"
          dominantBaseline="central"
          sx={(theme) => ({
            fontSize: theme.typography.caption.fontSize,
            fontWeight: '500',
            textTransform: 'uppercase',
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.text.primary,
            }),
          })}
        >
          YOUR CURRENT INDEX
        </Box>
      </Box>
    )
  }

  const dateFilterableStyles = useDateFilterableStyles()

  if (yourIndexLoading) {
    return (
      <Box sx={[{ borderRadius: '5px' }, dateFilterableStyles]}>
        <Loader size={200} centered />
      </Box>
    )
  }

  if (yourIndexError || !yourIndex || !preTransformedIndexValues) {
    return (
      <Box
        sx={[
          (theme) => ({
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            borderRadius: '5px',
            transition: 'border-color 0.5s ease-out',
            textAlign: 'center',
            flex: 1,
          }),
          dateFilterableStyles,
        ]}
      >
        <ComponentError errorContainerStyles={{ height: '100%' }} />
      </Box>
    )
  }

  const trackColor =
    mode === 'light'
      ? theme.vars.palette.secondary.main
      : theme.vars.palette.secondary.light

  return (
    <Box
      sx={[
        {
          textAlign: 'center',
          flex: '0 1 32%',
          '@media screen and (min-width: 1700px)': {
            flex: '0 1 16%',
          },
          borderRadius: '5px',
        },
        dateFilterableStyles,
      ]}
      data-testid="si-chart"
    >
      <Box
        id="si-metric-simon-chart"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          '& .enabled-source-types-container': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            clipPath: 'inset(0 0 48% 0)',
          },
          '& .enabled-detections-container': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            clipPath: 'inset(51% 0 0 20%)',
          },
          '& .enterprise-coverage-container': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            clipPath: 'inset(50% 60% 0 0%)',
          },
          '@media screen and (max-width: 768px)': {
            height: '35vh',
            width: '100%',
            '& .enabled-source-types-container': {
              clipPath: 'inset(0 0 48% 0)',
            },
            '& .enabled-detections-container': {
              clipPath: 'inset(51% 0 0 20%)',
            },
            '& .enterprise-coverage-container': {
              clipPath: 'inset(50% 52% 0 0%)',
            },
          },
        }}
        data-testid="si-metric-simon-chart"
      >
        <Box
          className="enabled-source-types-container"
          data-testid={`enabled-source-types-container ${
            active === logSourcesId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={chartMaxValues.logSourceValue}
            startAngle={-88}
            endAngle={88}
            cornerRadius={0}
            innerRadius={0.7}
            colors={[highlightMetric(active, logSourcesId, logSourcesColor)]}
            tracksColor={trackColor}
            data={logSourcesMetric as RadialBarSerie<RadialBarDatum>[]}
            isInteractive={false}
            layers={['tracks', 'bars', metric]}
          />
        </Box>
        <Box
          className="enabled-detections-container"
          data-testid={`enabled-detections-container ${
            active === detectionsId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={chartMaxValues.detectionsValue}
            startAngle={92}
            endAngle={198}
            cornerRadius={0}
            innerRadius={0.7}
            colors={[highlightMetric(active, detectionsId, detectionsColor)]}
            tracksColor={trackColor}
            data={detectionsMetric as RadialBarSerie<RadialBarDatum>[]}
            isInteractive={false}
            layers={['tracks', 'bars', yourIndexText]}
          />
        </Box>
        <Box
          className="enterprise-coverage-container"
          data-testid={`enterprise-coverage-container ${
            active === enterpriseCoverageId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={chartMaxValues.enterpriseValue}
            startAngle={205}
            endAngle={268}
            cornerRadius={0}
            innerRadius={0.7}
            tracksColor={trackColor}
            isInteractive={false}
            colors={[
              highlightMetric(
                active,
                enterpriseCoverageId,
                enterpriseCoverageColor,
              ),
            ]}
            data={enterpriseCoverageMetric as RadialBarSerie<RadialBarDatum>[]}
            layers={['tracks', 'bars']}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default SiMetricsChart
