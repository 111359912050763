import { useSearchParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Alert, Box, Collapse, IconButton } from '@mui/material'

import { Context } from '@components/App'
import Icon from '@common/Icon'

import { SecurityIndexTabs } from './components/SecurityIndexTabs'
import SecurityIndexBans from './components/Bans/SecurityIndexBans'
import { SecurityIndexContextProvider } from './context/SecurityIndexContext'

const SecurityIndex = () => {
  const today = new Date()
  const jan12024 = new Date('2024-01-01T00:00')
  const mar12024 = new Date('2024-03-01T00:00')

  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  const [selectedTrendEvent, setSelectedTrendEvent] = useState<Date>()

  const [bannerOpen, setBannerOpen] = useState(true)

  const {
    state: {
      user: { id },
      customer,
      dwExpertsCustomer,
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  useEffect(() => {
    if (
      selectedTrendEvent &&
      (selectedTrendEvent < startDate || selectedTrendEvent > endDate)
    ) {
      setSelectedTrendEvent(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const indexStartDate =
    customer.maturityScoreStartDate ?? dwExpertsCustomer.maturityScoreStartDate

  const indexStartAsDate = indexStartDate ? new Date(indexStartDate) : undefined
  indexStartAsDate?.setHours(0, 0, 0, 0)
  const jan12024Eligible = indexStartAsDate
    ? indexStartAsDate < jan12024 && today < mar12024
    : false

  const localStorageBannerKey = `si-banner-2024-01-01-${id}`

  return (
    <Box sx={{ overflow: 'auto', width: '100%' }}>
      <SecurityIndexContextProvider selectedCustomer={selectedCustomer}>
        <Box
          id="security-index"
          data-testid="security-index"
          sx={{ padding: '1rem 1.5rem' }}
        >
          {jan12024Eligible && !localStorage.getItem(localStorageBannerKey) && (
            <Collapse in={bannerOpen}>
              <Alert
                action={
                  <IconButton color="inherit" size="small">
                    <Icon
                      variant="close"
                      onClick={() => {
                        localStorage.setItem(localStorageBannerKey, 'closed')
                        setBannerOpen(false)
                      }}
                    />
                  </IconButton>
                }
                icon={<Icon variant="informationCircle" />}
                severity="info"
                sx={{ marginBottom: '.5rem' }}
                variant="filled"
              >
                On January 1st, the Security Index calculation was updated to be
                technology and brand agnostic. This will affect your score.
              </Alert>
            </Collapse>
          )}

          <SecurityIndexBans selectedTrendEvent={selectedTrendEvent} />
          <SecurityIndexTabs
            selectedTrendEventState={[
              selectedTrendEvent,
              setSelectedTrendEvent,
            ]}
          />
        </Box>
      </SecurityIndexContextProvider>
    </Box>
  )
}

export default SecurityIndex
