import { useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import {
  GET_SECURITY_INDEX_CHANGE_HISTORY,
  GetSecurityIndexChangeHistory,
  SecurityIndexVariables,
} from '@queries/securityIndex'

import { IndexTrend } from './IndexTrend'
import { IndexTrendEvents } from './IndexTrendEvents'
import { SecurityIndexMetrics } from './SecurityIndexMetrics'

interface YourIndex {
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const YourIndex: React.FC<YourIndex> = ({ selectedTrendEventState }) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''
  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const {
    data: { getSecurityIndexChangeHistory } = {
      getSecurityIndexChangeHistory: { events: [] },
    },
    loading: loading,
    error: changeHistoryError,
  } = useQuery<GetSecurityIndexChangeHistory, SecurityIndexVariables>(
    GET_SECURITY_INDEX_CHANGE_HISTORY,
    {
      variables: {
        selectedCustomer,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const formattedEvents = useMemo(
    () =>
      getSecurityIndexChangeHistory?.events?.map((event) => ({
        ...event,
        createdAt: new Date(`${event.createdAt}T00:00`),
      })),
    [getSecurityIndexChangeHistory?.events],
  )

  return (
    <Box
      data-testid="security-index-your-index"
      sx={{
        flexWrap: 'wrap',
        display: 'flex',
        width: '100%',
        gap: '1rem',
        marginBottom: '3em',
      }}
    >
      <IndexTrend
        events={formattedEvents}
        selectedTrendEventState={selectedTrendEventState}
      />
      <IndexTrendEvents
        loading={loading && !getSecurityIndexChangeHistory?.events?.length}
        error={changeHistoryError}
        events={formattedEvents}
        selectedTrendEventState={selectedTrendEventState}
      />
      <SecurityIndexMetrics />
    </Box>
  )
}
export default YourIndex
