import { ReactElement, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DocumentNode } from '@apollo/client'
import { Box } from '@mui/material'

import apolloClient from '@config/apolloClient'
import { useTicketsContext, useTicketEditContext } from '@hooks/index'
import { GetTicketDetailsVariables } from '@queries/ticket'
import { ComponentError } from '@common/ComponentError'
import { Context } from '@components/App'
import { Loader } from '@common/Loader'

interface TicketDataHandlerProps<Response> {
  graphqlQuery: DocumentNode
  renderContent: (data: Response) => ReactElement
}

export default function TicketDataHandler<T>({
  graphqlQuery,
  renderContent,
}: TicketDataHandlerProps<T>) {
  const {
    state: { dwExpertsCustomer },
  } = useContext(Context)
  const { sysId, isFetchLoading, fetchError } = useTicketEditContext()
  const { fetchError: ticketSettingsError, ticketSettingsDataLoading } =
    useTicketsContext()
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const data = apolloClient.readQuery<
    { getTicketDetails: T },
    GetTicketDetailsVariables
  >({
    query: graphqlQuery,
    variables: {
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
      ticketId: sysId,
    },
  })

  /**
   * We check if data is falsy before checking loading states to make sure
   * we show a loading state only if the cache is empty and the data is waiting
   * on the backend
   */
  if (!data && (isFetchLoading || ticketSettingsDataLoading)) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
          padding: '24px',
        }}
      >
        <Loader strokeWidth={1} size={50} />
      </Box>
    )
  }

  if (ticketSettingsError || fetchError || !data) {
    return <ComponentError />
  }

  return renderContent(data.getTicketDetails as T)
}
