import { colors } from '@design-system/theme'

import { CustomSVGProps } from './types'

const WindowsLogo: React.FC<CustomSVGProps> = ({
  primaryColor = colors.util.navy[100],
  size = 20,
}) => (
  <svg
    data-testid="windows-logo"
    width={size}
    height={size}
    viewBox={`0 0 18 18`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / logo-windows">
      <path
        id="Vector"
        d="M16.875 9.31641H8.15625V15.6094L16.875 16.875V9.31641Z"
        fill={primaryColor}
      />
      <path
        id="Vector_2"
        d="M7.59375 9.31641H1.125V14.5898L7.59375 15.5285V9.31641Z"
        fill={primaryColor}
      />
      <path
        id="Vector_3"
        d="M16.875 1.125L8.15625 2.36953V8.75391H16.875V1.125Z"
        fill={primaryColor}
      />
      <path
        id="Vector_4"
        d="M7.59375 2.45093L1.125 3.37554V8.75444H7.59375V2.45093Z"
        fill={primaryColor}
      />
    </g>
  </svg>
)

export default WindowsLogo
