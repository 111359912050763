import { ReactNode, useState } from 'react'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash'
import powershell from 'react-syntax-highlighter/dist/esm/languages/hljs/powershell'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import Icon from '@common/Icon'

SyntaxHighlighter.registerLanguage('bash', bash)
SyntaxHighlighter.registerLanguage('powershell', powershell)

const ThreatIntelFullReportCodeBlock = ({
  children,
  language,
}: {
  children: ReactNode
  language: string | undefined
}) => {
  const [showClipboardMessage, setShowClipboardMessage] = useState(false)

  const theme = useTheme()

  const displayClipboardMessage = () => {
    if (!showClipboardMessage) {
      setShowClipboardMessage(true)
      setTimeout(() => {
        setShowClipboardMessage(false)
      }, 3000)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(children as string)
    displayClipboardMessage()
  }

  return (
    <Box data-testid="codeblock">
      <Box
        sx={{ display: 'flex', alignItems: 'center', paddingBottom: '0.25rem' }}
      >
        <Typography fontWeight={500} fontStyle="italic" variant="caption">
          {(language || 'txt').toUpperCase()}
        </Typography>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IconButton data-testid="copy-button" onClick={handleCopy}>
            <Icon
              size={16}
              variant={showClipboardMessage ? 'clipboardSuccess' : 'clipboard'}
            />
          </IconButton>
          {showClipboardMessage && (
            <Box
              sx={(theme) => ({
                alignItems: 'center',
                border: `1px solid ${theme.vars.palette.secondary.main}`,
                borderRadius: '5px',
                display: 'flex',
                padding: '0.25rem',
                right: 0,
              })}
            >
              <Typography variant="caption">Copied!</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <SyntaxHighlighter
        language={language || 'plaintext'}
        PreTag="div"
        customStyle={{
          // Have to set both since theme sets both properties
          backgroundColor: theme.vars.palette.secondary.darker,
          background: theme.vars.palette.secondary.darker,
          padding: '0.5rem',
          fontFamily: 'Roboto Mono, monospace',
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: theme.typography.body2.fontSize,
          margin: 0,
          color: theme.vars.palette.text.secondary,
        }}
        CodeTag="span"
        wrapLines
        wrapLongLines
      >
        {children as string}
      </SyntaxHighlighter>
    </Box>
  )
}

export default ThreatIntelFullReportCodeBlock
