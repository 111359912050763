// Module
var code = `**PLEASE READ THIS DOCUMENT CAREFULLY.** These Terms of Use ("Terms")
govern the relationship between Deepwatch, Inc. ("Deepwatch", "we",
"us", or "our") and the company ("you" or "your") on whose behalf these
Terms are being agreed to. These Terms govern your access to and use of
Deepwatch Security Center / Platform Interface (collectively the
"Program").

By accessing and/or using a Program, you acknowledge and agree that you
have read, understand, and agree to be bound by these Terms. In
addition, the person accessing and/or using any aspect of the Program,
hereby represents and warrants that such person has full authority to
agree to these Terms on your behalf. You shall be responsible for your
compliance with these Terms.

If the person seeking to access and/or use the Program does not agree to
all of the terms and conditions provided below, in whole or in part, on
your behalf or does not have the authority to legally bind you to these
Terms, please do not access or use the Program.

These Terms apply as of the date that anyone accesses and/or uses the
Program on your behalf ("Effective Date") and remain in effect through
your Deepwatch subscription term for you and your designated Users.

**TERMS OF USE**

A. Program Interface

1.  Deepwatch has developed and owns a cloud-based software application called Deepwatch Security Center / Platform Interface that compiles data and analytics on security programs and policies for use by Deepwatch's existing customers during the Subscription Term. The Program is designed for your company's internal use of automated visibility into various cybersecurity-related assessments including but not limited to the following:

    a. Deepwatch's security index/maturity model, dashboards, metrics, key performance indicators, various analytics, and automation for active monitoring management; and

    b. recommendations, transformations, integrations, data, monitoring, support, maintenance, training, text, images, and other content made available by or on behalf of Deepwatch within or in addition to the Program features.

    c. self-service user and application group management, including but not limited to, providing and updating application group access as it relates to the systems used and provided by Deepwatch.

2.  No service level agreements are applicable to the Program unless otherwise provided for in an Order Form under the Agreement.

B. Authorized User(s) Access and Restricted Use

1.  The term "User" means any person who has been authorized by you to access and use the Program, been provided login access credentials (e.g., user name, password) as designated by Deepwatch or you, and accepted and agreed to these Terms by accessing and/or using of the Program.

2.  These Terms do not constitute an agreement for the sale or license of any software. Subject to these Terms, Deepwatch hereby grants each User a limited, revocable, non-exclusive, non-sublicensable, non-transferable, and worldwide (subject to applicable export laws) right to access and use the Program, solely for your internal business operations.

3.  As a condition to access and use the Program, each User hereby agrees to abide by these Terms and is obligated to immediately notify Deepwatch in writing of any violation of these Terms by any User upon becoming aware of such violation.

4.  Each User is responsible for the control of and/or access to such User's account, including limiting access to the User's user name and password and agrees to take all reasonable precautions to protect the User's user name and password as well as access to the User's account. Each User shall immediately notify Deepwatch in writing in the event that the User discovers or believes that the User's account, user name, or password has been accessed in any unauthorized way. Each User's access credentials will be screened by Deepwatch to verify the User's authorization to access and/or use the Program through its Zero-Trust remote access solution. Deepwatch shall not be liable to you, any User, or any third party for any failure to prevent unauthorized access to any User account. Each User is: (i) prohibited from sharing any assigned access credentials with any other person, entity, or device; and (ii) shall not designate any person or entity offering or providing any product or service that is competitive with Deepwatch, the Program, or any of Deepwatch's subscription services ("Deepwatch Competitor") as a User or allow any Deepwatch Competitor to access or use the Program in any way. You will cause your Users to comply with these Terms and shall be responsible and liable for the acts, errors, negligence, and omissions of any of your Users.

5.  For the avoidance of doubt, the Program, including all User-visible aspects, constitutes Deepwatch Confidential Information and is subject to your and each User's confidentiality obligations as described below in these Terms.

6.  Without limiting the foregoing, Users may not:

    a. use the Program for any purpose other than the purposes for which it is intended as described in these Terms;

    b. rent, lease, lend, sell, resell, license, sublicense, assign, distribute, timeshare, offer in a service bureau, or otherwise make the Program available to any third party in any manner, other than to an authorized User as permitted herein;

    c. bypass or breach any security device or protection used by the Program;

    d. input, upload, transmit, store, or otherwise provide to or through the Program any information or materials that are obscene, threatening, injurious, defamatory, or otherwise unlawful or that contain, transmit, store, or activate any virus, worm, time bomb, Trojan horse, and/or any other harmful or malicious code, file, script, agent, program, or code;

    e. reverse engineer, decompile, or disassemble any portion of the Program or otherwise attempt to discover or derive the source code, object code, or any underlying structure, idea, know-how, or algorithm relevant to the Program or any documentation or data related to the Program;

    f. modify, translate, or create derivative works based on the Program or any software used in the Program;

    g. access or use the Program in any manner to assist or take part in the development, marketing, or sale of any product and/or service potentially competitive with the Program;

    h. use the Program in any illegal manner or in any way that infringes the Intellectual Property Rights (as defined below) or any other right of any third party; or

    i. copy any features, functions, integrations, interfaces, or graphics of the Program.

C. Ownership

1.  Deepwatch (or its suppliers, if applicable) owns all right, title, and interest in and to all software, hardware, algorithms, methodologies, and other technology used by Deepwatch to provide and included in the Program. All intellectual property and proprietary rights in and to all of the foregoing are the exclusive property of Deepwatch and its suppliers.

2.  You have no obligation to provide us with any ideas, suggestions, enhancement requests, feedback, recommendations, proposals, and/or other information provided related to the Program (collectively, "Feedback"). However, if you and/or any User submits Feedback to us, all such Feedback will become our sole and exclusive property. You hereby irrevocably assign and transfer to Deepwatch, without charge, all of your right, title, and interest in and to all Feedback including all intellectual property rights therein.

3.  All rights not expressly granted to you in these Terms are reserved by Deepwatch including, without limitation, all Intellectual Property Rights in and to the Program, software, and any related services or solutions provided thereby, including any and all updates, upgrades, improvements, enhancements, and modifications from time to time; brand names, logos, and trademarks; and any software and related documentation, applications, inventions, and/or other technology developed in connection with the Program, and all intellectual property and proprietary rights in and related to any of the foregoing (collectively, "Program IP").

4.  For the purpose of these Terms, "Intellectual Property Rights" means any and all rights in and to all forms of intellectual property including, without limitation, patents (including patent applications), trademarks (including trademark applications), copyrights, trade secrets, methodologies, logos, techniques, processes, know-how, formulae, algorithms, logic designs, screen displays, schematics, source and object code computer programs or software and any related documentation, documents, mask work rights, designs, ideas, product information, inventions and improvements thereto (whether or not patentable), and all works of authorship fixed in any medium of expression (including any form of online, digital, or electronic medium), and irrespective of whether copyrightable and/or registered.

D. Confidentiality.

1.  Each party (the "Disclosing Party") might disclose or make accessible to the other party (the "Receiving Party") business, technical, product, marketing, and/or financial information relating to the Disclosing Party's business (hereinafter referred to as "Confidential Information"). Confidential Information includes, without limitation, any non-public information regarding features, functionality, and performance of the Program. Confidential Information of Users includes non-public data provided through the Program to enable the provision of access to, and use of, the content, data, and information recorded and/or stored within the Program for Users ("Customer Data"), but explicitly excludes any Vendor Information (defined below).

2.  Notwithstanding anything to the contrary contained herein, Confidential Information shall not include any information that the Receiving Party can document (i) is or becomes generally available to the public, (ii) was in its possession or known by it prior to receipt from the Disclosing Party, (iii) was rightfully disclosed to it without restriction by a third party, or (iv) was independently developed without use of any Confidential Information of the Disclosing Party.

3.  With respect to Confidential Information of the Disclosing Party, the Receiving Party shall: (i) use the same degree of care to protect the confidentiality, and prevent the unauthorized access to, use, or disclosure, of such Confidential Information, it uses to protect its own proprietary and confidential information of like nature, which shall not be less than a reasonable degree of care, (ii) hold all such Confidential Information in strict confidence and not use, sell, copy, transfer, reproduce, or divulge such Confidential Information to any third party, (iii) not use such Confidential Information for any purposes whatsoever other than the provision or use of the Program, or as otherwise authorized by, these Terms.

4.  The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent necessary to comply with a court or regulatory order or applicable law; provided, however, that, to the extent legally permissible, the Receiving Party promptly provides advance written notice of such disclosure to the Disclosing Party and, upon request and at the Disclosing Party's expense, uses reasonable efforts to try to secure confidential treatment of such Confidential Information, in whole or in part.

5.  The Receiving Party acknowledges that breach of any of its confidentiality obligations might cause irreparable harm to the Disclosing Party for which the Disclosing Party may not be fully or adequately compensated by recovery of monetary damages. Accordingly, in the event of any actual or threatened violation by the Receiving Party of any of its confidentiality obligations under this Section, the Disclosing Party shall be entitled to seek injunctive relief in addition to any other remedies that may be available at law or in equity, without the necessity of posting bond or proving actual damages.

E. Data Usage

You hereby grant to Deepwatch a perpetual, irrevocable, worldwide, non-exclusive, and non-transferable right and license to all data inputted, uploaded, transmitted, stored, and/or otherwise transmitted to or through the Program by any User solely for the purpose of functioning of the Program.

F. Term and Termination or Suspension

1.  Term: As an existing active Deepwatch customer, access to the Program will be co-terminus with the Subscription Term under the Agreement as an active User under your business account with Deepwatch.

2.  Termination: Deepwatch, in its discretion, may terminate access to and use of the Program at any time upon 30 days' prior written notice. Deepwatch may also immediately terminate access to and use of the Program if: (i) you or any User is in breach of these Terms or the Agreement and fail to cure such breach within ten (10) business days after receipt of written notice; or (ii) you cease business operations or become subject to insolvency proceedings and the proceedings are not dismissed within ninety (90) days. Upon expiration or termination for any reason, you and all Users' access to the Program will be deactivated and neither you nor any User is allowed to access or use the Program.

3.  Suspension: Deepwatch, in its sole discretion, may suspend any User's account and access to the Program at any time and without notice if Deepwatch believes you or any of your Users is in violation of these Terms. Although Deepwatch has no obligation to monitor use of the Program, we may do so at our sole discretion and may prohibit and/or restrict any use we believe may be (or alleged to be) in violation of these Terms, or any Deepwatch policy, or agreement with Deepwatch.

G. Updates / Modifications

Deepwatch may, in its sole discretion and at any time, make any changes to the Program that it deems necessary or useful to (i) maintain or enhance (a) the quality or delivery to customers, (b) the competitive strength of, or market for, Deepwatch's products or services, (c) the Program's cost efficiency or performance, or (ii) to comply with applicable law. Deepwatch reserves the right, with or without notice, to amend or modify these Terms at any time in its sole discretion by posting such amendment or modification or revised version of these Terms on its website. If you object to any such change or modification, you and all Users should immediately cease using the Program. If any User continues to use the Program after a revised version of these Terms has been posted on the Deepwatch website, you agree that you and all Users will be deemed to accept and comply with such modified terms. Except as stated above in this Section, these Terms may not be amended, modified, or superseded except by a written document executed by you and us.

H. Warranties and Warranty Disclaimer

1.  Each Party warrants that it has the authority to agree to these Terms and, in connection with its performance under these Terms, shall comply with all applicable laws. You represent and warrant that you have accessed and reviewed these Terms and any related Program policies provided by Deepwatch, understand the requirements thereof, and agree to comply with these Terms.

2.  YOU ACKNOWLEDGE THAT DEEPWATCH DOES NOT CONTROL THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT THE PROGRAM MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. THE PROGRAM AND ITS DATA ARE PROVIDED "AS IS". EXCEPT AS SET FORTH IN THIS SECTION, DEEPWATCH MAKES NO (AND HEREBY DISCLAIMS ALL) WARRANTIES, REPRESENTATIONS, AND/OR CONDITIONS, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO ACCESS TO, USE OF, AND/OR THE PROVISION OF THE PROGRAM INCLUDING, WITHOUT LIMITATION, THE INABILITY TO USE OR ACCESS THE PROGRAM. DEEPWATCH DOES NOT WARRANT THAT INFORMATION PROVIDED BY THE PROGRAM WILL DETECT, IDENTIFY, OR REVEAL ANY SECURITY GAP OR PREDICT ANY CYBER ATTACK OR UNAUTHORIZED ACCESS, THAT ANY PROGRAM OUTPUT WILL BE ACCURATE OR COMPLETE, THAT ANY ASSESSMENT AND/OR ANY RECOMMENDATIONS ABOUT YOUR SECURITY PROGRAM ARE ACCURATE, OR THAT OPERATION OF THE PROGRAM WILL BE ERROR-FREE OR OPERATE WITHOUT INTERRUPTION OR DOWNTIME. FURTHER, DEEPWATCH SHALL NOT BE RESPONSIBLE FOR ANY LIMITATIONS, DELAYS, AND/OR OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND/OR ANY ELECTRONIC COMMUNICATIONS. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DEEPWATCH DISCLAIMS ANY AND ALL LIABILITY FOR, AND SHALL NOT BE RESPONSIBLE IN ANY WAY REGARDING, ANY THIRD-PARTY CONTENT, INFORMATION, MATERIALS, LINKS, FILES, DATA, AND/OR SEARCH RESULTS THAT MAY BE ACCESSIBLE THROUGH THE PROGRAM.

I. Personal Information

The Program uses cookies to help organize, collect, process, and store data and/or metadata of Users (e.g., name, phone number, company and e-mail address of Users using the Program to create outputs or contact Deepwatch in connection with the provision of the Program use and support) which may be considered personal identifiable information in some jurisdictions. In addition, the Pendio.io experience platform is being used to monitor metrics of usage of and within the Program. By using the Program, you and all Users hereby consent to the use of cookies related to the Program. Deepwatch's processing and treatment of any such personal information is  described in Deepwatch's Privacy Policy available at <https://www.iubenda.com/privacy-policy/46757738/legal>. The place of processing of personal data described above is in the United States. The primary owner contact email address is: [privacy@Deepwatch.com](mailto:privacy@deepwatch.com).

For the avoidance of doubt, Pendo.IO is primarily used by the Program and is a product analysis tool used to examine the use of internally developed products, prepare reports on application use, and share them with Deepwatch for internal development and optimization. Personal Data processed include: cookies, usage data, and unique customer identifiers. Place of processing: United States . Category of personal data collected: internet information and identifiers. This processing may constitute a sale of personal data based on applicable laws. In addition to the information in this Section, Users can find information regarding how to opt-out of the processing/sale of personal data in the section detailing the Rights of Users and/or the Rights of California consumers described in Deepwatch's Privacy Policy available at
<https://www.iubenda.com/privacy-policy/46757738/legal>.

J. Liability Disclaimer

TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL: (I) DEEPWATCH BE LIABLE TO YOU, ANY USER, OR ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, COVER, RELIANCE, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY KIND, HOWEVER CAUSED, OR FOR ANY LOSS OF BUSINESS, REVENUE, ANTICIPATED SAVINGS, PROFIT, USE, AND/OR LOSS OR CORRUPTION OF ANY DATA AND/OR COST OF DATA RECONSTRUCTION OR PROCUREMENT OF SUBSTITUTE OR REPLACEMENT GOODS, SERVICES, INVENTORY, OR EQUIPMENT, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR UNDER ANY OTHER THEORY OF LIABILITY, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THESE TERMS AND/OR YOUR OR ANY USER'S ACCESS TO OR USE OF THE PROGRAM EVEN IF YOU HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF ANY SUCH LOSS OR DAMAGES; AND (II) DEEPWATCH'S ENTIRE AND MAXIMUM LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS AND/OR ANY ACCESS TO AND/OR USE OF THE PROGRAM, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEED IN THE AGGREGATE ONE HUNDRED UNITED STATES DOLLARS (US\$100). THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THESE TERMS WILL NOT INCREASE DEEPWATCH'S LIABILITY. THE PARTIES ACKNOWLEDGE THAT ANY ACCESS TO AND/OR USE OF THE PROGRAM IN ANY MANNER IS IN RELIANCE UPON THESE LIMITATIONS OF LIABILITY AND THAT ALL SUCH LIMITATIONS FORM AN ESSENTIAL BASIS OF THE CONTRACT BETWEEN THE PARTIES.

**GENERAL PROVISIONS**

A. All provisions of these Terms relating to disclaimers of warranties, remedies, damages, confidentiality, payment obligations, restrictions on use, liability, intellectual property and data ownership, and any other terms that either expressly or by their nature should survive, shall survive any expiration or termination of these Terms, and shall continue in full force and effect.

B. Neither party may issue any press release regarding these Terms without the other party's prior written consent. Either party may include the other party's name and logo in its list of customers and vendors, as applicable, subject to and in accordance with the other party's standard guidelines unless otherwise provided in the Agreement.

C. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.

D. The heading references herein are for convenience purposes only and shall not be deemed to limit or affect any of the provisions.

E. Deepwatch shall not be liable for any failure of or delay in the performance of the Program, these Terms, or the Agreement for the period that such failure or delay is beyond Deepwatch's reasonable control, materially affects the performance of any of its obligations under these Terms, and/or could not reasonably have been foreseen or provided against, but will not be excused for failure or delay resulting from only general economic conditions or other general market effects.

F. These Terms and any claim, controversy, right, obligation, or dispute arising under or related to these Terms and/or the interpretation and enforcement of the rights, performance obligations, and/or duties of the parties shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to conflicts of laws principles. The parties irrevocably consent to the exclusive jurisdiction and venue of the state and federal courts in Wilmington, Delaware. The parties waive any right to a jury trial in connection with any action or litigation in any way arising out of or related to these Terms or the Program.

G. These Terms constitute the entire agreement between you and Deepwatch regarding your and your Users' access to and/or use of the Program. In the event of any conflict between these Terms and the Agreement, these Terms will take precedence over the Agreement unless otherwise provided herein. These Terms will be construed and interpreted fairly, in accordance with their plain meaning, and there will be no presumption or inference against the party drafting these Terms in construing, applying, or interpreting any of the provisions.

H. The parties are independent contractors. These Terms do not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the parties. There are no third party beneficiaries under these Terms.

I. Deepwatch shall not be responsible or liable for any delay or failure to perform hereunder due to any circumstances beyond its reasonable control including, without limitation, acts of God, acts or orders of government, flood, fire, earthquakes, civil unrest, disease, epidemic and/or pandemic, acts of terror, strikes or other labor problems, service disruptions involving hardware, software or power systems, and denial of service attacks.

J. No failure or delay by either party in exercising any right, remedy, or obligation under these Terms will constitute a waiver of that right, remedy, or obligation. Any waiver of any right, remedy, or obligation under these Terms must be in writing and signed by a duly authorized representative of each party. A waiver on one occasion shall not be construed as a waiver of any right, remedy, or obligation on any future occasion. Except as otherwise expressly stated in these Terms, the remedies provided herein are in addition to, and not exclusive of, any other remedies of a party at law or in equity.

K. All notices (except for routine business communications, e.g., maintenance windows) shall be sent in accordance with the terms of
the Agreement.
`;
// Exports
export default code;