import { useCallback, useMemo } from 'react'
import { format } from 'date-fns'

import type { ValueFormatter } from '@nivo/axes'

interface ChartData {
  date: string
}

/**
 * @description A hook to format x-axis date values according to our chart anatomy specifications
 * @param {ChartData[]} chartData The data to be formatted, must have a `date` property with a valid ISO date string
 * @returns {ValueFormatter} A function that formats the x-axis date
 * @example
 * ```tsx
 * const ChartComponent: React.FC = () => {
 *   const formatXAxis = useXAxisDateFormatting(chartData)
 *   return (
 *     <ResponsiveBar
 *       // ...
 *       axisBottom={{ format: formatXAxis }}
 *     />
 *   )
 * }
 * ```
 */
export function useXAxisDateFormatting(
  chartData: ChartData[],
): ValueFormatter<string> {
  const { firstDate, lastDate, isFirstDateInPriorYear, shouldShowBookends } =
    useMemo(() => {
      const firstDate = chartData.at(0)?.date
      const lastDate = chartData.at(-1)?.date
      const isFirstDateInPriorYear =
        firstDate &&
        new Date(firstDate).getFullYear() < new Date().getFullYear()
      const shouldShowBookends = chartData.length > 14

      return { firstDate, lastDate, isFirstDateInPriorYear, shouldShowBookends }
    }, [chartData])

  const formatXAxis = useCallback(
    (value: string): string => {
      const dateFormat = `MMM d${isFirstDateInPriorYear ? ', yyyy' : ''}`
      return !shouldShowBookends || [firstDate, lastDate].includes(value)
        ? format(value, dateFormat)
        : ''
    },
    [firstDate, lastDate, isFirstDateInPriorYear, shouldShowBookends],
  )

  return formatXAxis
}
