import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Navigate } from 'react-router'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { OktaGroup, User } from '@models/index'
import {
  GET_USER_GROUPS,
  GET_USER_MANAGEMENT_SETTINGS,
  GetUserGroupsData,
  GetUserGroupsVariables,
} from '@queries/user'
import InlineMessage, {
  InlineMessageType,
} from '@common/InlineMessage/InlineMessage'
import { ComponentError } from '@common/ComponentError'
import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { Dialog } from '@common/Dialog'
import { AlertSeverity, useToast } from '@hooks/useToast'

import { DeleteUserModal } from './DeleteUserModal'
import { DeactivateUserModal } from './DeactivateUserModal'
import { ActivateUserModal } from './ActivateUserModal'
import { AddUserModal } from './AddUserModal'
import { EditUserModal } from './EditUserModal'
import { ResendEmailUserModal } from './ResendEmailUserModal'
import UserManagementSettingsTable, {
  USER_MANAGEMENT_TABLE_ID_PREFIX,
} from './UserManagementTable/UserManagementSettingsTable'

export enum ModalTitles {
  ADD = 'Add User',
  EDIT = 'Edit User',
  ACTIVATE = 'Activate User',
  DEACTIVATE = 'Deactivate User',
  RESEND_EMAIL = 'Resend Email',
  DELETE = 'Delete User',
}

const UserManagementSettings: React.FC = () => {
  const {
    state: { customer, homePath, user },
    dispatch: globalDispatch,
  } = useContext(Context)
  const { handleShowToast } = useToast()

  const [selectedUser, setSelectedUser] = useState<User>()
  const [userGroups, setUserGroups] = useState<Array<OktaGroup>>()
  const [openModal, setOpenModal] = useState<null | ModalTitles>(null)
  const userIdForModal = useRef<string | undefined>(undefined) // Save the user id to re-focus the row after closing the modal

  const {
    data: { getUsers: users, getCustomerGroups: customerGroups } = {
      getUsers: [],
      getCustomerGroups: [],
    },
    loading,
    error: userManagementSettingsError,
  } = useQuery(GET_USER_MANAGEMENT_SETTINGS)

  const [getUserGroups] = useLazyQuery<
    GetUserGroupsData,
    GetUserGroupsVariables
  >(GET_USER_GROUPS)

  /** When closing the modal, re-focus the first button in the row which was selected to improve a11y ux  */
  useEffect(() => {
    if (openModal === null) {
      document
        .getElementById(
          `${USER_MANAGEMENT_TABLE_ID_PREFIX}${userIdForModal.current}`,
        )
        ?.querySelector('button')
        ?.focus()
    }
  }, [openModal])

  const clearUser = () => {
    setSelectedUser(undefined)
    setUserGroups(undefined)
  }

  const onAddUserClick = useCallback(() => {
    setSelectedUser(undefined)
    setOpenModal(ModalTitles.ADD)
  }, [setOpenModal])

  // TODO: create type that reflects the user after data table mapping
  const onEditUserClick = useCallback(
    async (user: User): Promise<void> => {
      userIdForModal.current = user.id
      const { data, error: userGroupsError } = await getUserGroups({
        variables: {
          input: {
            userId: user?.id as string,
          },
        },
      })

      if (userGroupsError) {
        handleShowToast(
          AlertSeverity.Error,
          'We were unable to fetch data. Check your connection then try again. If the problem persists, please contact our support team for assistance.',
        )
        return
      }

      setSelectedUser(user)
      setUserGroups(data?.getUsersGroupMembership)
      setOpenModal(ModalTitles.EDIT)
    },
    [getUserGroups, globalDispatch, setOpenModal, handleShowToast],
  )

  const onDeactivateUserClick = useCallback(
    async (user: User): Promise<void> => {
      userIdForModal.current = user.id
      setSelectedUser(user)
      setOpenModal(ModalTitles.DEACTIVATE)
    },
    [setOpenModal, setSelectedUser],
  )

  const onActivateUserClick = useCallback(
    async (user: User): Promise<void> => {
      userIdForModal.current = user.id
      setSelectedUser(user)
      setOpenModal(ModalTitles.ACTIVATE)
    },
    [setSelectedUser, setOpenModal],
  )

  const onReactivateUserClick = useCallback(
    async (user: User): Promise<void> => {
      userIdForModal.current = user.id
      setSelectedUser(user)
      setOpenModal(ModalTitles.RESEND_EMAIL)
    },
    [setSelectedUser, setOpenModal],
  )

  const onDeleteUserClick = useCallback(
    async (user: User): Promise<void> => {
      userIdForModal.current = user.id
      setSelectedUser(user)
      setOpenModal(ModalTitles.DELETE)
    },
    [setSelectedUser, setOpenModal],
  )

  if (user.isDWEmployee || !user.isAdmin) {
    return <Navigate to={homePath} />
  }

  if (userManagementSettingsError) {
    return (
      <ComponentError
        errorSubText="Check your connection and reload the page. If the problem persists, contact our support team for assistance."
        includeReloadButton={true}
        errorIcon={fetchErrorIcon}
      />
    )
  }

  return (
    <Box
      id="user-management"
      data-testid="usermanagementsettings"
      sx={{ overflow: 'auto', width: '100%', padding: '1.5rem' }}
    >
      {customer.settings?.isOktaFederated && (
        <InlineMessage
          title="Federation message"
          message="We have detected your organization has federated users from an
                  external identity provider. You may edit and delete the users
                  below, however these changes will only apply to
                  Deepwatch's Okta organization and any Deepwatch
                  provisioned app (i.e. the IdP the users were federated to). 
                  Consider updating the users in your organization's
                  identity provider to ensure the changes persist. If you have
                  any questions, please reach out to your Customer Success
                  Manager."
          variant={InlineMessageType.INFORMATIONAL}
        />
      )}

      <UserManagementSettingsTable
        loading={loading}
        users={users}
        handleActivateUser={onActivateUserClick}
        handleDeactivateUser={onDeactivateUserClick}
        handleReactivateUser={onReactivateUserClick}
        handleDeleteUser={onDeleteUserClick}
        handleEditUser={onEditUserClick}
        handleAddUser={onAddUserClick}
      />
      <Dialog
        title={openModal ?? ''}
        isOpen={openModal !== null}
        onClose={() => setOpenModal(null)}
      >
        {(() => {
          switch (openModal) {
            case ModalTitles.ADD:
              return (
                <AddUserModal
                  closeModal={() => setOpenModal(null)}
                  user={selectedUser as User}
                  isLastAdmin={false}
                  customerGroups={customerGroups}
                  title={ModalTitles.ADD}
                  clearUser={clearUser}
                  userGroups={userGroups}
                />
              )
            case ModalTitles.EDIT:
              return (
                <EditUserModal
                  closeModal={() => setOpenModal(null)}
                  user={
                    users.find((val) => val.id === selectedUser?.id) as User
                  }
                  isLastAdmin={checkForLastAdmin(users)}
                  customerGroups={customerGroups}
                  title={ModalTitles.EDIT}
                  userGroups={userGroups}
                />
              )
            case ModalTitles.ACTIVATE:
              return (
                <ActivateUserModal
                  closeModal={() => setOpenModal(null)}
                  userId={selectedUser?.id ?? ''}
                  userName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                />
              )
            case ModalTitles.DEACTIVATE:
              return (
                <DeactivateUserModal
                  closeModal={() => setOpenModal(null)}
                  userId={selectedUser?.id ?? ''}
                  userName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                />
              )
            case ModalTitles.RESEND_EMAIL:
              return (
                <ResendEmailUserModal
                  closeModal={() => setOpenModal(null)}
                  userId={selectedUser?.id ?? ''}
                />
              )
            case ModalTitles.DELETE:
              return (
                <DeleteUserModal
                  closeModal={() => setOpenModal(null)}
                  userId={selectedUser?.id ?? ''}
                  userName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                />
              )
          }
        })()}
      </Dialog>
    </Box>
  )
}

export default UserManagementSettings

export const checkForLastAdmin = (users: User[]): boolean => {
  return (
    1 ===
    users.reduce((count: number, user) => (user.isAdmin ? ++count : count), 0)
  )
}
