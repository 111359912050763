import { gql } from '@apollo/client'

import { Ticket } from '@models/index'

export interface AddTicketCommentVariables {
  input: {
    ticketId: string
    ticketData: TicketCommentInput
  }
}

export type TicketDataInput = Pick<
  Ticket,
  | 'customerValidateState'
  | 'resolutionNotes'
  | 'resolutionCode'
  | 'state'
  | 'priority'
> & {
  comment: string
}

export interface UpdateTicketVariables {
  input: {
    ticketId: string
    ticketData: Partial<TicketDataInput>
  }
}

export type TicketCommentInput = Pick<TicketDataInput, 'comment'>
/**
 * @category Mutations
 * @description Mutation to add a comment to a ticket
 */
export const UPDATE_TICKET = gql`
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input)
  }
`
