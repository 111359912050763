export const addDisablePointerEventsClass = (isOpen: boolean): void => {
  const body = document.querySelector('body')

  if (body) {
    if (isOpen) {
      body.classList.add('disable-pointer-events')
    } else {
      body.classList.remove('disable-pointer-events')
    }
  }
}
