import { ResponsiveBar } from '@nivo/bar'
import { Box, Typography, useColorScheme, useTheme } from '@mui/material'

import { colors } from '@design-system/theme'
import { themeConfig } from '@common/nivo/utils'
import {
  ChartType,
  useEnvironmentHealthChartDetailsSideSheet,
} from '@components/EnvironmentHealth/Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import { SourceType } from '@models/EnvHealth'

import { SourceTypeCalculations, keyColors } from '../SourceUtilization.utils'
import { mockSourceTypes } from '../../../../../../mockData/EnvHealthMockData'

/**
 * Modify the mock data to have the same sourcetype name and different dates
 */
const modifiedMockData = mockSourceTypes.map<SourceType>(
  (sourceType, index) => {
    const date = new Date(sourceType.date)
    date.setDate(date.getDate() + index)
    return {
      ...sourceType,
      name: mockSourceTypes[0].name,
      date: date.toISOString(),
    }
  },
)

const UtilizationByDateBySourcetypeChart = ({ data }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  const { setSideSheetData, setIsOpen } =
    useEnvironmentHealthChartDetailsSideSheet()

  const {
    name,
    sourceIP,
    averageIngestOverPeriod,
    deltaGb,
    deltaPercentage,
    deviationColor,
    totalAlertsOverPeriod,
  } = new SourceTypeCalculations(modifiedMockData)

  const handleClick = () => {
    setSideSheetData({
      chartType: ChartType.MULTI_DAY,
      sourceTypes: modifiedMockData,
      chartTitle: 'Ingest Volume (GB)',
      details: [
        { key: 'Source type', label: 'Source type', value: name },
        {
          key: 'Source IP',
          label: 'Source IP',
          value: sourceIP,
        },
        {
          key: 'Average ingest this period',
          label: 'Average ingest this period',
          value: `${Math.round(averageIngestOverPeriod)}GB`,
        },
        {
          key: 'Ingest deviation',
          label: 'Ingest deviation',
          value: (
            <Typography
              variant="body2"
              color={deviationColor}
            >{`${deltaPercentage}% (${deltaGb.toFixed(2)}GB)`}</Typography>
          ),
        },
        {
          key: 'Alerts',
          label: 'Alerts',
          value: totalAlertsOverPeriod,
        },
      ],
    })
    setIsOpen(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
    >
      <ResponsiveBar
        data={data}
        keys={['value1', 'value2', 'value3']}
        indexBy="ranking"
        margin={{
          top: 50,
          right: 19,
          bottom: 36,
          left: 36,
        }}
        padding={0.6}
        groupMode="grouped"
        indexScale={{ type: 'band', round: true }}
        borderColor={colors.util.navy[400]}
        valueScale={{
          type: 'linear',
          max: 'auto',
          min: 0,
        }}
        colors={keyColors}
        enableLabel={false}
        theme={themeConfig(mode, theme)}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'food',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0,
        }}
        onClick={handleClick}
      />
    </Box>
  )
}

export default UtilizationByDateBySourcetypeChart
