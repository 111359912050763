/* eslint-disable @getify/proper-ternary/nested */
import { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import Icon from '@common/Icon'
import PopoverContent from '@common/PopoverContent'

import Day from './Day'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DatePickerButtonStyle = (theme: Theme) => ({
  alignItems: 'center',
  backgroundColor: theme.vars.palette.secondary.light,
  border: `1px solid ${theme.vars.palette.secondary.main}`,
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  padding: '0.25rem 1rem',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.main,
  }),
})

interface DatePickerProps {
  defaultEndDate?: Date
  defaultStartDate?: Date
  disabledDate?: (date: Date, startDate?: Date, endDate?: Date) => boolean
  disabledSubmit?: (startDate?: Date, endDate?: Date) => boolean
  endDate?: Date
  loading: boolean
  onCalendarIconHover: (isHovering: boolean) => void
  onChange?: (startDate?: Date, endDate?: Date) => void
  onReset?: (startDate?: Date, endDate?: Date) => void
  onSubmit: (startDate?: Date, endDate?: Date) => void
  startDate?: Date
  shouldShow?: boolean
}

const DatePicker: React.FC<DatePickerProps> = ({
  startDate,
  endDate,
  onSubmit,
  onReset,
  onCalendarIconHover,
  onChange,
  disabledSubmit,
  disabledDate,
  defaultStartDate,
  defaultEndDate,
  loading,
  shouldShow = true,
}) => {
  const startDateToUse = startDate || defaultStartDate
  const endDateToUse = endDate || defaultEndDate

  //? initialize startDate without time, if a Date is given
  const startDateWithoutTime = startDateToUse
    ? new Date(
        startDateToUse.getFullYear(),
        startDateToUse.getMonth(),
        startDateToUse.getDate(),
      )
    : undefined

  //? initialize endDate without time, if a Date is given
  const endDateWithoutTime = endDateToUse
    ? new Date(
        endDateToUse.getFullYear(),
        endDateToUse.getMonth(),
        endDateToUse.getDate(),
      )
    : undefined

  //? initialize defaultStartDate without time, if a Date is given
  const defaultStartDateWithoutTime = defaultStartDate
    ? new Date(
        defaultStartDate.getFullYear(),
        defaultStartDate.getMonth(),
        defaultStartDate.getDate(),
      )
    : undefined

  //? initialize defaultEndDate without time, if a Date is given
  const defaultEndDateWithoutTime = defaultEndDate
    ? new Date(
        defaultEndDate.getFullYear(),
        defaultEndDate.getMonth(),
        defaultEndDate.getDate(),
      )
    : undefined

  const [defaultResetDates] = useState<[Date | undefined, Date | undefined]>([
    defaultStartDateWithoutTime,
    defaultEndDateWithoutTime,
  ])

  const [selectedDates, setSelectedDates] = useState<
    [Date | undefined, Date | undefined]
  >([startDateWithoutTime, endDateWithoutTime])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [monthIncrement, setMonthIncrement] = useState<number>(0)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [shift(), offset(8)],
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const dateSelectionIndex = useRef<0 | 1>(startDate && !endDate ? 1 : 0)
  const [selectedStartDate, selectedEndDate] = selectedDates

  useEffect(() => {
    const newDefaultStartDateWithoutTime = defaultStartDate
      ? new Date(
          defaultStartDate.getFullYear(),
          defaultStartDate.getMonth(),
          defaultStartDate.getDate(),
        )
      : undefined

    const newDefaultEndDateWithoutTime = defaultEndDate
      ? new Date(
          defaultEndDate.getFullYear(),
          defaultEndDate.getMonth(),
          defaultEndDate.getDate(),
        )
      : undefined

    if (newDefaultStartDateWithoutTime && newDefaultEndDateWithoutTime) {
      setSelectedDates([
        newDefaultStartDateWithoutTime,
        newDefaultEndDateWithoutTime,
      ])
    }
  }, [defaultStartDate, defaultEndDate])

  const handleDateSelect = (date: Date) => {
    const newSelection: typeof selectedDates = [
      selectedStartDate,
      selectedEndDate,
    ]
    const dateLessThanStartDate =
      dateSelectionIndex.current === 1 &&
      selectedStartDate &&
      date.getTime() < selectedStartDate.getTime()
    const dateGreaterThanEndDate =
      dateSelectionIndex.current === 0 &&
      selectedEndDate &&
      date.getTime() > selectedEndDate.getTime()
    if (dateLessThanStartDate || dateGreaterThanEndDate) {
      dateSelectionIndex.current = 1
      return updateAndEmitSelectedDates([date, undefined])
    }
    newSelection[dateSelectionIndex.current] = date
    updateAndEmitSelectedDates(newSelection)
    dateSelectionIndex.current = dateSelectionIndex.current === 0 ? 1 : 0
  }

  const updateAndEmitSelectedDates = (
    newDatesSelection: typeof selectedDates,
  ) => {
    setSelectedDates(newDatesSelection)
    onChange?.(...newDatesSelection)
  }

  const todayWithTime = new Date()
  const today = new Date(
    todayWithTime.getFullYear(),
    todayWithTime.getMonth(),
    todayWithTime.getDate(),
  )

  const leftCalendarStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1 + monthIncrement,
  )
  const leftCalendarEndDate = new Date(
    today.getFullYear(),
    today.getMonth() + monthIncrement,
    0,
  )
  const rightCalendarStartDate = new Date(
    today.getFullYear(),
    today.getMonth() + monthIncrement,
  )
  const rightCalendarEndDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1 + monthIncrement,
    0,
  )

  const calendars: Array<null | Date>[][] = [[], []]

  calendars.forEach((calendar, index) => {
    let startDate = leftCalendarStartDate
    let calendarStartDateDayOfWeek = leftCalendarStartDate.getDay()
    let calendarEndDateDayOfMonth = leftCalendarEndDate.getDate()
    if (index === 1) {
      startDate = rightCalendarStartDate
      calendarStartDateDayOfWeek = rightCalendarStartDate.getDay()
      calendarEndDateDayOfMonth = rightCalendarEndDate.getDate()
    }

    let week: Array<null | Date> = Array(calendarStartDateDayOfWeek).fill(null)
    for (let i = 1; i <= calendarEndDateDayOfMonth; ++i) {
      week.push(new Date(startDate.getFullYear(), startDate.getMonth(), i))
      if (
        (i + calendarStartDateDayOfWeek) % 7 === 0 ||
        i === calendarEndDateDayOfMonth
      ) {
        calendar.push(week)
        week = []
      }
    }
  })

  /**
   * The date filterable border is shown when either the tooltip or the date picker is open.
   */
  useEffect(() => {
    onCalendarIconHover(isOpen || isTooltipOpen)
  }, [isTooltipOpen, isOpen, onCalendarIconHover])

  if (!shouldShow) {
    return null
  }

  return (
    <Box data-testid="date-picker">
      <Tooltip
        data-testid="calendar-icon-tooltip"
        disableFocusListener
        disableTouchListener
        open={isTooltipOpen}
        onOpen={() => setIsTooltipOpen(true)}
        onClose={() => setIsTooltipOpen(false)}
        title="Only highlighted items will be affected by global filter"
      >
        <Button
          data-testid="date-picker-button"
          disabled={loading}
          ref={refs.setReference}
          {...getReferenceProps()}
          sx={DatePickerButtonStyle}
        >
          {loading ? (
            <Skeleton data-testid="skeleton-block" sx={{ width: '100%' }} />
          ) : (
            <>
              <Icon
                sx={(theme) => ({
                  mr: 1,
                  color: 'text.primary',
                  ...theme.applyStyles('dark', {
                    color: 'text.secondary',
                  }),
                })}
                variant={'calendarOutline'}
              />

              <Typography
                data-testid="dw-datepicker-label"
                sx={(theme) => ({
                  color: 'text.primary',
                  ...theme.applyStyles('dark', {
                    color: 'text.secondary',
                  }),
                })}
              >
                {selectedStartDate
                  ? selectedStartDate.toLocaleDateString()
                  : 'mm/dd/yyyy'}
                {' - '}
                {selectedEndDate
                  ? selectedEndDate.toLocaleDateString()
                  : 'mm/dd/yyyy'}
              </Typography>
            </>
          )}
        </Button>
      </Tooltip>

      {isOpen && (
        <FloatingFocusManager context={context}>
          <PopoverContent
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            setFloating={refs.setFloating}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                padding: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1.5rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton
                    data-testid="previous-month-button"
                    onClick={() => setMonthIncrement(monthIncrement - 1)}
                  >
                    <Icon size={28} variant="chevronBackCircleOutline" />
                  </IconButton>
                </Box>

                {calendars.map((calendar, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1.5rem',
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      sx={(theme) => ({
                        color: 'text.primary',
                        display: 'flex',
                        justifyContent: 'center',
                        ...theme.applyStyles('dark', {
                          color: 'text.secondary',
                        }),
                      })}
                    >
                      {index === 0
                        ? `${
                            months[leftCalendarStartDate.getMonth()]
                          } ${leftCalendarStartDate.getFullYear()}`
                        : `${
                            months[rightCalendarStartDate.getMonth()]
                          } ${rightCalendarStartDate.getFullYear()}`}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5rem',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'grid',
                          justifyItems: 'center',
                          gridTemplateColumns: 'repeat(7, 1fr)',
                        }}
                      >
                        <Typography>Su</Typography>
                        <Typography>Mo</Typography>
                        <Typography>Tu</Typography>
                        <Typography>We</Typography>
                        <Typography>Th</Typography>
                        <Typography>Fr</Typography>
                        <Typography>Sa</Typography>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {calendar.map((week, index) => (
                          <Box
                            key={index}
                            sx={{
                              alignItems: 'center',
                              display: 'grid',
                              gridTemplateColumns: 'repeat(7, 1fr)',
                              justifyItems: 'center',
                            }}
                          >
                            {week.map((date, index) => (
                              <Day
                                key={index}
                                date={date}
                                disabledDate={disabledDate}
                                handleDateSelect={handleDateSelect}
                                startDate={selectedStartDate}
                                endDate={selectedEndDate}
                              />
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton
                    data-testid="next-month-button"
                    onClick={() => setMonthIncrement(monthIncrement + 1)}
                  >
                    <Icon size={28} variant="chevronForwardCircleOutline" />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    onReset?.(defaultResetDates[0], defaultResetDates[1])
                    setSelectedDates(defaultResetDates)
                    setMonthIncrement(0)
                    dateSelectionIndex.current = 0
                  }}
                >
                  Reset
                </Button>
                {onSubmit && (
                  <Button
                    disabled={disabledSubmit?.(
                      selectedStartDate,
                      selectedEndDate,
                    )}
                    onClick={() => {
                      onSubmit(selectedStartDate, selectedEndDate)
                      setIsOpen(false)
                    }}
                    variant="contained"
                  >
                    Apply
                  </Button>
                )}
              </Box>
            </Box>
          </PopoverContent>
        </FloatingFocusManager>
      )}
    </Box>
  )
}

export default DatePicker
