import { useContext } from 'react'

import { Context } from '@components/App'

import { NotificationsContext } from './NotificationsContext'

export const useNotificationsContext = () => {
  const notificationsContext = useContext(NotificationsContext)

  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  if (!notificationsContext) {
    throw new Error(
      'NotificationsContext must be used within NotificationsContextProvider',
    )
  }

  // Notifications are only available for customers, not internal DW
  if (isDWEmployee) {
    return {
      notifications: [],
      unreadNotificationsCount: 0,
      toggleNotificationReadStatus: () => {},
      loadingNotifications: false,
      fetchNotificationsError: null,
      fetchMoreNotifications: () => {},
      fetchingMore: false,
      shouldShowBadge: false,
    }
  }

  return notificationsContext
}
