import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import { User } from '@models/User'
import Icon from '@common/Icon'

const UserStatusCell = (props: CellContext<User, any>) => {
  const status = props.getValue()
  const userStatus =
    typeof status === 'string' && status ? status.toLowerCase() : ''
  return <div>{getStatus(userStatus)}</div>
}

export default UserStatusCell

// TODO: This function smells and ought to be refactored. DO NOT reuse this pattern.
const getStatus = (status: string): JSX.Element => {
  // TODO: set flag depending on timestamp comparison from when the invite was
  // TODO: sent and date.now()
  const expiredInvite = false
  switch (status) {
    case 'staged':
    case 'provisioned':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            variant={expiredInvite ? 'warning' : 'checkmarkCircle'}
            size={14}
            sx={(theme) => ({
              color: expiredInvite
                ? theme.vars.palette.error.main
                : theme.vars.palette.warning.main,
              marginRight: 0.5,
            })}
          ></Icon>
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {expiredInvite ? 'Invite Expired' : 'Invite Pending'}
          </Typography>
        </Box>
      )
    case 'active':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            variant="checkmarkCircle"
            size={14}
            sx={(theme) => ({
              color: theme.vars.palette.success.light,
              marginRight: 0.5,
            })}
          ></Icon>
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            Active
          </Typography>
        </Box>
      )
    case 'deprovisioned':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            variant="banOutline"
            size={14}
            sx={(theme) => ({
              color: theme.vars.palette.error.light,
              marginRight: 0.5,
            })}
          ></Icon>
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            Deactivated
          </Typography>
        </Box>
      )
    // TODO: Why is there no logic in these blocks?
    case 'recovery':
    case 'password expired':
    case 'locked out':
    case 'suspended':
    default:
      return <></>
  }
}
