import startCase from 'lodash/startCase'
import { Box, IconButton, Typography } from '@mui/material'

import Icon from '@common/Icon'

const technologyIcons = {
  splunk: 'splunk',
  crowdstrike: 'crowdstrike',
  azure: 'azure',
  palo: 'paloAlto',
  'tenable.io': 'tenable',
  'microsoft sentinel': 'microsoft',
}

type ModuleTechnologyFieldProps = {
  technology: string | null
  technologyUrl: string | null
}

const ModuleTechnologyField: React.FC<ModuleTechnologyFieldProps> = ({
  technology,
  technologyUrl,
}) => {
  if (!technology) {
    return '--'
  }

  return (
    <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
      {technologyIcons[technology.toLowerCase()] && (
        <Icon variant={technologyIcons[technology.toLowerCase()]} />
      )}
      <Typography variant="body2">{startCase(technology)}</Typography>
      {technologyUrl && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            window.open(technologyUrl, '_blank')
          }}
        >
          <Icon size={14} variant="openOutline" />
        </IconButton>
      )}
    </Box>
  )
}

export default ModuleTechnologyField
