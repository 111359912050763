import { format } from 'date-fns'

import { SourceType } from '@models/EnvHealth'
import { DailySourceTypesDeviations } from '@queries/environmentHealth'

export const utilizationByDate = [
  {
    ranking: 'A III',
    value: 140,
  },
  {
    ranking: 'A II',
    value: 140,
  },
  {
    ranking: 'A I',
    value: 140,
  },
  {
    ranking: 'B III',
    value: 160,
  },
  {
    ranking: 'B II',
    value: 160,
  },
  {
    ranking: 'B I',
    value: 160,
  },
  {
    ranking: 'C III',
    value: 200,
  },
  {
    ranking: 'C II',
    value: 200,
  },
  {
    ranking: 'C I',
    value: 200,
  },
  {
    ranking: 'D III',
    value: 180,
  },
  {
    ranking: 'D II',
    value: 180,
  },
  {
    ranking: 'D I',
    value: 180,
  },
]

export const utilizationByDateBySourcetype = [
  {
    ranking: 'A III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'A II',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'A I',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'B III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'B II',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'B I',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'C III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'C II',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'C I',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'D III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'D II',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'D I',
    value1: 140,
    value2: 200,
    value3: 500,
  },
]

export const utilizationByDateBySourcetypeDeviation = [
  {
    ranking: 'A III',
    value1: 140,
    value2: -200,
    value3: 500,
  },
  {
    ranking: 'A II',
    value1: -140,
    value2: 200,
    value3: -500,
  },
  {
    ranking: 'A I',
    value1: 140,
    value2: -200,
    value3: 500,
  },
  {
    ranking: 'B III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'B II',
    value1: -140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'B I',
    value1: 140,
    value2: -200,
    value3: -500,
  },
  {
    ranking: 'C III',
    value1: 140,
    value2: -200,
    value3: 500,
  },
  {
    ranking: 'C II',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'C I',
    value1: 140,
    value2: 200,
    value3: -500,
  },
  {
    ranking: 'D III',
    value1: 140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'D II',
    value1: -140,
    value2: 200,
    value3: 500,
  },
  {
    ranking: 'D I',
    value1: -140,
    value2: -200,
    value3: 500,
  },
]

export const mockForwarder1 = { activeCount: 114, atRiskCount: 16 }

export const mockForwarder2 = { activeCount: 34, atRiskCount: 2 }

export const mockSourceTypesDeviation = {
  lt10Count: 32,
  gte10Count: 53,
  gt25Count: 23,
  gt50Count: 14,
}

const date = format(new Date(), 'yyyy-MM-dd')

export const mockSourceTypes: SourceType[] = [
  {
    date,
    name: 'atqui:stabilis',
    gb: 3.3938,
    sourceIP: '202.93.254.93',
    averageIngestOverPeriod: 7.9295,
    avgIngestOverAllTime: 3.4132,
    ingestDeviation: { gb: 13.0194, delta: -79.32274023347061 },
    alerts: 67,
    openTickets: [],
    index: 'solus',
  },
  {
    date,
    name: 'perferendis_tenus',
    gb: 2.27,
    sourceIP: '91.255.233.16',
    averageIngestOverPeriod: 5.8537,
    avgIngestOverAllTime: 6.5122,
    ingestDeviation: { gb: 4.2422, delta: -65.14234820797887 },
    alerts: 3,
    openTickets: [],
    index: 'vulticulus',
  },
  {
    date,
    name: 'bestia.trado',
    gb: 6.6693,
    sourceIP: 'd1fc:8e11:faa7:c479:b452:e935:a401:afb5',
    averageIngestOverPeriod: 18.9416,
    avgIngestOverAllTime: 10.9302,
    ingestDeviation: { gb: 4.2608999999999995, delta: -38.982818246692645 },
    alerts: 65,
    openTickets: [],
    index: 'perferendis',
  },
  {
    date,
    name: 'comprehendo.umerus',
    gb: 3.5977,
    sourceIP: '6ea7:c77a:e0de:25ac:48ad:5efb:d3ce:8ad9',
    averageIngestOverPeriod: 3.8002,
    avgIngestOverAllTime: 5.1281,
    ingestDeviation: { gb: 3.4696, delta: 67.65858700103352 },
    alerts: 96,
    openTickets: [],
    index: 'vulgo',
  },
  {
    date,
    name: 'ater:barba',
    gb: 1.1065,
    sourceIP: 'accb:09ae:26f7:c919:caec:bb73:bca4:192e',
    averageIngestOverPeriod: 14.8371,
    avgIngestOverAllTime: 2.4581,
    ingestDeviation: { gb: 14.6484, delta: 595.9236808917457 },
    alerts: 91,
    openTickets: [],
    index: 'terminatio',
  },
  {
    date,
    name: 'cimentarius_testimonium',
    gb: 2.1103,
    sourceIP: '180.41.20.134',
    averageIngestOverPeriod: 6.0001,
    avgIngestOverAllTime: 6.1017,
    ingestDeviation: { gb: 3.9914, delta: -65.41455659898061 },
    alerts: 39,
    openTickets: [],
    index: 'dicta',
  },
  {
    date,
    name: 'adflicto:avaritia',
    gb: 3.1938,
    sourceIP: 'e01c:6eaa:fd4f:b9c4:8f3b:f988:cb50:f624',
    averageIngestOverPeriod: 19.4743,
    avgIngestOverAllTime: 14.5468,
    ingestDeviation: { gb: 11.353, delta: -78.04465586933209 },
    alerts: 30,
    openTickets: [],
    index: 'aequitas',
  },
  {
    date,
    name: 'toties.atavus',
    gb: 7.468,
    sourceIP: 'b72c:d6d5:6077:e0ba:abbc:cafb:a3ec:b61f',
    averageIngestOverPeriod: 17.6565,
    avgIngestOverAllTime: 8.1202,
    ingestDeviation: { gb: 0.6522000000000006, delta: -8.031821876308472 },
    alerts: 58,
    openTickets: [],
    index: 'ipsum',
  },
  {
    date,
    name: 'temperantia.adduco',
    gb: 3.856,
    sourceIP: '0e56:aba8:1702:3540:d8bf:ed6f:ccba:abb4',
    averageIngestOverPeriod: 11.2102,
    avgIngestOverAllTime: 13.6524,
    ingestDeviation: { gb: 9.7964, delta: -71.75588174972899 },
    alerts: 17,
    openTickets: [],
    index: 'appello',
  },
]

export const DailySourceTypesDeviationsMock: DailySourceTypesDeviations = {
  gt50Count: 75,
  gt25Count: 4,
  gte10Count: 12,
  lt10Count: 9,
}
