import { useFragment, useQuery } from '@apollo/client'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { Context } from '@components/App'
import { ReportType } from '@models/ThreatIntel'
import {
  GET_THREAT_INTEL_REPORTS_LIST_QUERY,
  GetThreatIntelReportsData,
  GetThreatIntelReportsVariables,
  ThreatIntelReportsData,
} from '@queries/threatIntel'
import { THREAT_INTEL_REPORT_DATA_FRAGMENT } from '@fragments/threatIntel'
import { useMostRecentValue } from '@hooks/index'
import { Loader } from '@common/Loader'

import { useThreatIntelReportsContext } from './context'
import ThreatIntelReportsHeader from './components/ThreatIntelReportsHeader'
import ThreatIntelReportsList from './components/ThreatIntelReportsList/ThreatIntelReportsList'
import ThreatIntelSideSheet from './components/ThreatIntelSideSheet/ThreatIntelSideSheet'

const ThreatIntelReports: React.FC = () => {
  const {
    state: {
      dwExpertsCustomer: { customerShortName: dwExpertCustomerShortName },
    },
  } = useContext(Context)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { isSideSheetOpen, activeReportId, closeSideSheet } =
    useThreatIntelReportsContext()

  const initialAdvisories: ThreatIntelReportsData = {
    pagination: {
      limit: 3,
      offset: 0,
      total: 3,
    },
    threatIntelReports: [],
  }

  const {
    data: { getThreatIntelReports: advisoryReportData } = {
      getThreatIntelReports: initialAdvisories,
    },
    loading: advisoriesLoading,
  } = useQuery<GetThreatIntelReportsData, GetThreatIntelReportsVariables>(
    GET_THREAT_INTEL_REPORTS_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer: selectedCustomer || dwExpertCustomerShortName,
        input: {
          pagination: {
            offset: 0,
            limit: 3,
          },
          reportTypes: [ReportType.CUSTOMER_ADVISORY],
        },
      },
    },
  )

  const { data: activeReport } = useFragment({
    fragment: THREAT_INTEL_REPORT_DATA_FRAGMENT,
    fragmentName: 'ThreatIntelReportData',
    from: {
      __typename: 'ThreatIntelReport',
      id: activeReportId,
    },
  })

  /** Prevent showing an empty sidesheet state when toggling the sidesheet closed */
  const mostRecentActiveReport = useMostRecentValue(
    Object.keys(activeReport).length ? activeReport : null,
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '1rem',
        gridTemplateRows: 'minmax(0, auto) minmax(0, 100%)',
        padding: '1.5rem',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ gridRow: 1 }}>
        <Typography
          color="textPrimary"
          variant="h6"
          sx={{ marginBottom: '1rem' }}
        >
          Recent Significant Cyber Events and Customer Advisories
        </Typography>
        {advisoriesLoading && !advisoryReportData?.threatIntelReports.length ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader strokeWidth={1} size={120} />
          </Box>
        ) : (
          <ThreatIntelReportsHeader
            advisories={advisoryReportData.threatIntelReports}
          />
        )}
      </Box>

      <ThreatIntelReportsList />
      <ThreatIntelSideSheet
        isOpen={isSideSheetOpen}
        closeSideSheet={closeSideSheet}
        threatIntelReport={mostRecentActiveReport}
        animationEnabled={false}
      />
    </Box>
  )
}

export default ThreatIntelReports
