import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { ThreatIntelReportLabel } from '@models/ThreatIntel'
import { FilterChipsHeader, FilterValue } from '@common/FilterChips'
import { useThreatIntelReportsInput } from '@threatIntelHooks/index'

const ThreatIntelFilterLabel = () => {
  const { filters } = useThreatIntelReportsInput()
  const [, setSearchParams] = useSearchParams()

  const deleteChip = (filterGroup: string, filterValue: FilterValue) => {
    const name = `${(filterValue as ThreatIntelReportLabel).type}_label`

    setSearchParams((prevParams) => {
      prevParams.delete(name, (filterValue as ThreatIntelReportLabel).name)
      if (filterGroup === 'detection') {
        prevParams.delete('useCaseId')
        prevParams.delete('detection')
      }

      return prevParams
    })
  }

  return (
    <Box id="threat-intel-report-filter-chips">
      <FilterChipsHeader
        deleteChip={deleteChip}
        selectedFilters={{ filters }}
        hasActiveFilters={false}
        operatorText={'and'}
      />
    </Box>
  )
}

export default ThreatIntelFilterLabel
