import React from 'react'
import { Form, Formik } from 'formik'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { formatISO, sub } from 'date-fns'
import { Box, Button, TextField, Typography } from '@mui/material'

import { EDR_ASSETS_FILTER_OPTIONS } from '@queries/medr'
import FilterOptionSelect from '@common/FilterOptionSelect'

export interface EDRAssetFiltersForm {
  hostname: string[]
  lastReboot: string[]
  lastSeen: string[]
  operatingSystem: string[]
  platform: string[]
  rfmStatus: string[]
  status: string[]
}

export enum TimeFilterValues {
  LAST_24_HRS = '<24 hrs',
  LAST_3_DAYS = '1-3 days',
  LAST_7_DAYS = '3-7 days',
  LAST_TWO_WEEKS = '1-2 weeks',
  OVER_TWO_WEEKS = '2+ weeks',
}

export const TIME_FILTER_MAPPING = {
  [TimeFilterValues.LAST_24_HRS]: [
    formatISO(sub(new Date(), { days: 1 }), { representation: 'date' }),
    formatISO(new Date(), { representation: 'date' }),
  ],
  [TimeFilterValues.LAST_3_DAYS]: [
    formatISO(sub(new Date(), { days: 3 }), { representation: 'date' }),
    formatISO(sub(new Date(), { days: 1 }), { representation: 'date' }),
  ],
  [TimeFilterValues.LAST_7_DAYS]: [
    formatISO(sub(new Date(), { days: 7 }), { representation: 'date' }),
    formatISO(sub(new Date(), { days: 3 }), { representation: 'date' }),
  ],
  [TimeFilterValues.LAST_TWO_WEEKS]: [
    formatISO(sub(new Date(), { weeks: 2 }), {
      representation: 'date',
    }),
    formatISO(sub(new Date(), { weeks: 1 }), {
      representation: 'date',
    }),
  ],
  [TimeFilterValues.OVER_TWO_WEEKS]: [
    formatISO(sub(new Date(), { years: 1 }), {
      representation: 'date',
    }),
    formatISO(sub(new Date(), { weeks: 2 }), {
      representation: 'date',
    }),
  ],
}

interface EDRAssetFilterSideSheetProps {
  selectedFilters: EDRAssetFiltersForm
  handleFilterChange: (value: EDRAssetFiltersForm) => void
  toggleSidesheet: VoidFunction
}

const EDRAssetFilterSideSheet: React.FC<EDRAssetFilterSideSheetProps> = ({
  selectedFilters,
  handleFilterChange,
  toggleSidesheet,
}) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    data: {
      edrAssetsFilterOptions: { operatingSystem, platform, rfmStatus, status },
    } = {
      edrAssetsFilterOptions: selectedFilters,
    },
  } = useQuery(EDR_ASSETS_FILTER_OPTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { selectedCustomer },
  })

  const resetValues = {
    hostname: [],
    lastReboot: [],
    lastSeen: [],
    operatingSystem: [],
    platform: [],
    rfmStatus: [],
    status: [],
  }

  return (
    <Box data-testid="edr-asset-filter-sidesheet" sx={{ padding: '1rem' }}>
      <Formik
        enableReinitialize
        initialValues={selectedFilters}
        onSubmit={handleFilterChange}
      >
        {({ handleSubmit, resetForm, setFieldValue, values }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                paddingBottom: 'calc(0.5rem + 72px)',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
              >
                <Typography fontWeight={600} variant="body2">
                  Host name
                </Typography>
                <TextField
                  id="hostname"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('hostname', [e.target.value])
                  }}
                  name="hostname"
                  placeholder="Host name"
                />
              </Box>

              {status.length > 0 && (
                <FilterOptionSelect
                  label={'Status'}
                  name="status"
                  selectedValues={values.status}
                  values={status}
                />
              )}

              {rfmStatus.length > 0 && (
                <FilterOptionSelect
                  label={'RFM Status'}
                  name="rfmStatus"
                  selectedValues={values.rfmStatus}
                  values={rfmStatus}
                />
              )}

              {platform.length > 0 && (
                <FilterOptionSelect
                  label={'Platform'}
                  name="platform"
                  selectedValues={values.platform}
                  values={platform}
                />
              )}

              {operatingSystem.length > 0 && (
                <FilterOptionSelect
                  label={'OS'}
                  name="operatingSystem"
                  selectedValues={values.operatingSystem}
                  values={operatingSystem}
                />
              )}

              <FilterOptionSelect
                label={'Last seen'}
                name="lastSeen"
                selectedValues={values.lastSeen}
                values={Object.values(TimeFilterValues)}
                variant="single"
              />

              <FilterOptionSelect
                label={'Last rebooted'}
                name="lastReboot"
                selectedValues={values.lastReboot}
                values={Object.values(TimeFilterValues)}
                variant="single"
              />
            </Box>

            <Box
              sx={(theme) => ({
                backgroundColor: theme.vars.palette.common.white,
                borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: '1rem',
                padding: '1rem',
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '100%',
                ...theme.applyStyles('dark', {
                  backgroundColor: theme.vars.palette.secondary.dark,
                  borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
                }),
              })}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  resetForm({ values: resetValues })
                  handleSubmit()
                }}
              >
                Clear all
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit()
                  toggleSidesheet()
                }}
              >
                Apply
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EDRAssetFilterSideSheet
