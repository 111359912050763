import {
  ColumnDef,
  ColumnSizingState,
  Header,
  OnChangeFn,
  Updater,
} from '@tanstack/react-table'
import {
  MutableRefObject,
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { flushSync } from 'react-dom'
import isEqual from 'lodash/isEqual'

import { ResizableColumnsUtils } from './resizableColumnsUtils'

interface UseResizableColumnsArgs<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnDef: Array<ColumnDef<T, any>>
  debug?: boolean
}

export interface UseResizableColumnsReturn<T> {
  columnSizing: ColumnSizingState
  tableHeaderRefs: Array<RefObject<HTMLTableCellElement>>
  /**
   * Pass this to the container of the table.
   *
   * The parent of this element MUST have `width: 100%`, or otherwise prevent itself from growing beyond its parents.
   * If the parent element does not constrain the table, resizing the viewport will not resize the table as expected.
   */
  tableContainerRef: MutableRefObject<HTMLDivElement | null>
  /** Records the initial column/table widths and the header being resized, then passes the event to the TanStack resize handler */
  handleMouseDown: (
    /** A mouse event; will be passed to the TanStack resize handler */
    event: React.MouseEvent,
    /** The header being resized */
    header: Header<T, unknown>,
  ) => void
  /**
   * If the column was not resized after the `onMouseDown` event, resets the column width
   * to match the originally rendered width (before any resizing)
   */
  handleMouseUp: () => void
  /**
   * This function is called when the TanStack table handles a column resize event; should
   * be passed to the `useReactTable` hook.
   *
   * After the TanStack table handles the drag event and updates the column sizing state,
   * this function adjusts column sizes to constrain them to the initial full table width.
   */
  onColumnSizingChange: OnChangeFn<ColumnSizingState>
  /**
   * Resets the column width to match the originally rendered width (before any resizing)
   *
   * In the future, we may want to update the "original" width after a window resize, but
   * for now it is only set once. This means that this function has undefined behavior if
   * called after a window resize.
   */
  resetColumnSize: (
    /** The header for the column being reset */
    header: Header<T, unknown>,
  ) => void
}

/**
 * @description A hook for managing resizable columns in a TanStack table
 * @param {UseResizableColumnsArgs<any>} args Arguments for the useResizableColumns hook
 * @param {Array<ColumnDef<any, any>>} args.columnDef The array of TanStack column definitions for the table being resized
 * @param {boolean} [args.debug] Whether to log debug information
 * @returns {UseResizableColumnsReturn<any>} The return values for the useResizableColumns hook
 * @example
 * # Prerequisites
 *
 * 1. When defining columns, set explicit sizes and min-sizes for any columns which should have a default width or non-standard min-width.
 * ```tsx
 * const columns = useMemo(
 *  () => [
 *    {
 *      // Other properties...
 *      size: 100, // An explicit size
 *      minSize: 50, // An explicit minimum size, will be respected when resizing
 *      // maxSize is not currently supported
 *    },
 *    {
 *      // If no size is provided, the column will grow to the maximum width
 *    },
 * ]
 * ```
 * 2. Ensure each column definition has a unique `id` property.
 * ```tsx
 * const columns = useMemo(
 *   () => [
 *     {
 *       id: 'column1',
 *       // Other properties...
 *     },
 *     columnHelper.accessor('someKey', {
 *       id: 'column2',
 *     },
 *   ],
 *   [],
 * )
 * ```
 * 3. Pass all of the required arguments to the `useResizableColumns` hook below the column definitions.
 * ```tsx
 * const {
 *   columnSizing,
 *   tableHeaderRefs,
 *   handleMouseUp,
 *   handleMouseDown,
 *   onColumnSizingChange,
 *   resetColumnSize,
 *   tableContainerRef,
 * } = useResizableColumns({
 *   columnDef,
 * })
 * ```
 * 4. Pass the `columnSizing` and `onColumnSizingChange` values to the `useReactTable` hook. Also, make sure to set the `columnResizeMode` to 'onChange'.
 * ```tsx
 * const table = useReactTable({
 *   state: {
 *     columnSizing,
 *   },
 *   columnResizeMode: 'onChange',
 *   onColumnSizingChange,
 * })
 * 5. Pass the values from the `useResizableColumns` hook to the table container, and `<TableHeader />` component.
 * ```tsx
 * <div ref={tableContainerRef}>
 *   <TableHeader
 *     key={header.id}
 *     header={header}
 *     table={table}
 *     // Hook values
 *     width={columnSizing[header.id]}
 *     thRef={tableHeaderRefs[index]}
 *     onResizeHandleMouseUp={handleMouseUp}
 *     onResizeHandleMouseDown={handleMouseDown}
 *     onResetColumnSize={resetColumnSize}
 *   />
 * </div>
 * ```
 * > Note
 * > If you are already passing a ref to the table container, you can use the `mergeRefs` utility to combine the refs.
 */
export function useResizableColumns<T>(
  args: UseResizableColumnsArgs<T>,
): UseResizableColumnsReturn<T> {
  const { columnDef, debug } = args

  if (!columnDef.every((column) => column.id !== undefined)) {
    throw new Error(
      'To use the useResizableColumns hook, all column definitions must have a defined id',
    )
  }

  /**
   * True if column sizes have changed since resizing started; used to determine the difference
   * between a drag and a click, to enable resetting column size on single-click.
   */
  const columnsWereResized = useRef<boolean>(false)
  /** Holds the header which is currently, or most recently, resized */
  const lastResizedHeader = useRef<Header<T, unknown> | null>(null)
  /** Hold the initial column sizing for resetting column widths */
  const initialColumnSizing = useRef<ColumnSizingState>({})
  const tableHeaderRefs: Array<RefObject<HTMLTableCellElement>> = useMemo(
    () => columnDef.map(() => createRef<HTMLTableCellElement>()),
    [columnDef],
  )
  const tableContainerRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null) // Used for adjusting the table after window resize
  const initialColumnSizingState = columnDef.reduce(
    (acc, column, index) => ({
      ...acc,
      [column.id!]:
        // eslint-disable-next-line security/detect-object-injection
        tableHeaderRefs[index]?.current?.clientWidth ?? column.size, // Actual size after first render // Initial size from column definitions
    }),
    {},
  )
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(
    initialColumnSizingState,
  )
  const [columnSizingBeforeResize, setColumnSizingBeforeResize] =
    useState<ColumnSizingState>(initialColumnSizingState)
  const [tableWidth, setTableWidth] = useState<number>(0)

  /**
   * Initialize column sizing once all refs are ready
   *
   * Should only happen once, unless the column definition changes
   */
  useEffect(() => {
    if (Object.keys(initialColumnSizing.current).length === 0) {
      const columnSizing = columnDef.reduce(
        (acc, column, index) => ({
          ...acc,
          // Non-null assertion accounted for by throwing error above
          [column.id!]:
            // eslint-disable-next-line security/detect-object-injection
            tableHeaderRefs[index].current?.clientWidth,
        }),
        {},
      )
      initialColumnSizing.current = columnSizing
      setColumnSizing(columnSizing)
    }
  }, [columnDef, tableHeaderRefs])

  /**
   * Resize columns when the window is resized
   *
   * Current behavior uses the first column as the "resized" column; width is taken from this column first if
   * the column is being narrowed. In the future, we may want to make the column resizing proportional across
   * all columns when the window is resized.
   */
  const handleWindowResize = useCallback(() => {
    const newTableWidth = tableContainerRef.current?.clientWidth // Takes the scrollbar into account
    if (newTableWidth) {
      const resizeUtils = new ResizableColumnsUtils(
        columnDef,
        columnSizing,
        columnSizingBeforeResize,
        newTableWidth,
      )
      resizeUtils.preventTableOverflow()
      resizeUtils.growColumnsProportionally()
      setColumnSizing(resizeUtils.modifiedColumnSizing)
    }
  }, [columnDef, columnSizing, tableContainerRef, columnSizingBeforeResize])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  const onColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback(
    (updater: Updater<ColumnSizingState>) => {
      const result =
        typeof updater === 'function' ? updater(columnSizing) : updater
      if (!lastResizedHeader.current) {
        return
      }
      const resizeUtils = new ResizableColumnsUtils(
        columnDef,
        result,
        columnSizingBeforeResize,
        tableWidth,
        lastResizedHeader.current,
      )
      resizeUtils.subtractWidthFromSubsequentColumns()
      resizeUtils.addWidthToSubsequentColumns()
      resizeUtils.ensureMinWidth()
      resizeUtils.addWidthToPriorColumn()
      resizeUtils.preventTableOverflow()
      if (debug) {
        resizeUtils.debug()
      }
      columnsWereResized.current =
        columnsWereResized.current ||
        isEqual(columnSizingBeforeResize, resizeUtils.modifiedColumnSizing)
      setColumnSizing(resizeUtils.modifiedColumnSizing)
    },
    [columnSizing, columnSizingBeforeResize, columnDef, tableWidth, debug],
  )

  const handleMouseDown = useCallback(
    (event: React.MouseEvent, header: Header<T, unknown>) => {
      event.stopPropagation()
      lastResizedHeader.current = header
      columnsWereResized.current = false
      flushSync(() => {
        setColumnSizingBeforeResize(columnSizing)
        setTableWidth(
          Object.values(columnSizing).reduce((acc, width) => acc + width, 0),
        )
      })
      header.getResizeHandler()(event)
    },
    [columnSizing],
  )

  const resetColumnSize = useCallback(
    (header: Header<T, unknown>) => {
      const columnId = header.column.id
      lastResizedHeader.current = header
      onColumnSizingChange((previousColumnSizing) => ({
        ...previousColumnSizing,
        [columnId]: initialColumnSizing.current[columnId as string],
      }))
    },
    [onColumnSizingChange],
  )

  /** Either reset the column size if we have not dragged, or do nothing */
  const handleMouseUp = useCallback(() => {
    if (!columnsWereResized.current && lastResizedHeader.current) {
      resetColumnSize(lastResizedHeader.current)
    }
  }, [resetColumnSize])

  const values: UseResizableColumnsReturn<T> = {
    columnSizing,
    tableHeaderRefs,
    tableContainerRef,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
  }

  return values
}
