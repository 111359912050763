import { CustomSVGProps } from './types'

const CrowdStrikeLogo: React.FC<CustomSVGProps> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 13"
    fill="none"
    data-testid="crowd-strike-logo"
  >
    <path
      d="M13.184 12.9049C12.7467 11.9162 11.8684 10.6474 8.42796 8.83428C6.84321 7.95923 4.13227 6.62215 1.69934 4.07056C1.91891 4.9911 3.05038 7.01417 7.91976 9.54129C9.26374 10.2658 11.5444 10.9536 13.184 12.9049Z"
      fill="currentColor"
    />
    <path
      d="M12.7423 11.138C12.3415 9.96444 11.6186 8.46242 8.18044 6.23134C6.50676 5.10698 4.0502 3.6893 0.815918 0.0949707C1.04718 1.0647 2.06989 3.58916 7.21939 6.86551C8.91193 8.04079 11.0944 8.76632 12.7423 11.138Z"
      fill="currentColor"
    />
  </svg>
)

export default CrowdStrikeLogo
