import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import { CustomerHighlight } from '@models/DeepwatchExpert'
import Icon from '@common/Icon'

export interface CustomerLicenseUtilCellValues {
  usageGb: number | null
  usageLicenseGb: number
}

interface CustomerLicenseUtilCellProps {
  cellContext: CellContext<CustomerHighlight, CustomerLicenseUtilCellValues>
}

const CustomerLicenseUtilCell: React.FC<CustomerLicenseUtilCellProps> = ({
  cellContext,
}) => {
  const { usageGb, usageLicenseGb } = cellContext.getValue()

  if (usageGb === null) {
    return (
      <Typography color="warning" variant="body2">
        Error
      </Typography>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        color: theme.vars.palette.text.secondary,
      })}
    >
      {usageGb > usageLicenseGb ? (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.25rem' }}>
          <Typography variant="body2" fontWeight={500} color="error">
            {usageGb}
          </Typography>
          <Icon
            variant="alertCircle"
            size={18}
            sx={(theme) => ({
              color: theme.vars.palette.error.main,
            })}
          />
        </Box>
      ) : (
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {usageGb}
        </Typography>
      )}
    </Box>
  )
}

export default CustomerLicenseUtilCell
