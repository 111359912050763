import React from 'react'
import { useLocation } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Box, Link, Tooltip, Typography } from '@mui/material'

import { Paths } from '@components/App/Types'
import { ReportType, ThreatIntelReport } from '@models/ThreatIntel'
import { BackButton } from '@common/BackButton'
import Icon from '@common/Icon'
import { zIndex } from '@components/App/Styles/zIndex'

import CyberIntelBriefBackground from './CyberIntelBriefBackground'
import CustomerAdvisoryBackground from './CustomerAdvisoryBackground'

type FullReportHeaderProps = Pick<
  ThreatIntelReport,
  'title' | 'type' | 'source' | 'createdDate' | 'sourceMaterial'
>
const FullReportHeader: React.FC<FullReportHeaderProps> = ({
  title,
  type,
  source,
  createdDate,
  sourceMaterial,
}) => {
  const location = useLocation()

  return (
    <Box
      data-testid="full-report-header"
      sx={{
        minHeight: '230px', // this is necessary due to SVG scaling
        position: 'relative',
        gridColumn: '1 / 3',
        gridRow: 1,
        padding: '2rem',
      }}
    >
      {type === ReportType.CUSTOMER_ADVISORY ? (
        <CustomerAdvisoryBackground
          sx={(theme) => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: theme.vars.palette.warning.dark,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.warning.main,
            }),
          })}
        />
      ) : (
        <CyberIntelBriefBackground
          sx={[
            (theme) => ({
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              color: theme.vars.palette.success.dark,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.success.main,
              }),
            }),
          ]}
        />
      )}

      <BackButton
        previousPath={`${Paths.THREAT_INTEL_REPORTS}?${location.state?.searchParams ?? ''}`}
        containerStyles={{ position: 'absolute', top: 0, left: 0 }}
      />

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
        data-testid="full-report-logo-area"
      >
        <Icon
          size={29}
          variant="atiLogo"
          sx={(theme) => ({
            color:
              type === ReportType.CUSTOMER_ADVISORY
                ? theme.vars.palette.severity.medium
                : theme.vars.palette.success.light,
            fill: theme.vars.palette.common.black,
            ...theme.applyStyles('dark', {
              color:
                type === ReportType.CUSTOMER_ADVISORY
                  ? theme.vars.palette.severity.medium
                  : theme.vars.palette.success.dark,
            }),
          })}
        />

        <Tooltip
          title="Report Author"
          placement="top-start"
          sx={{ zIndex: zIndex.CELL_HOVER_BUTTONS }}
        >
          <Typography variant="caption" color="textPrimary">
            Deepwatch Adversary Tactics & Intelligence
          </Typography>
        </Tooltip>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            textAlign: 'center',
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          fontWeight={600}
          variant="h4"
        >
          {title}
        </Typography>

        <Box
          data-testid="full-report-header-details"
          sx={{ display: 'flex', gap: '0.5rem' }}
        >
          <Tooltip
            title="Intel Source"
            placement="top-start"
            sx={{ zIndex: zIndex.CELL_HOVER_BUTTONS }}
          >
            <Typography variant="caption">{source}</Typography>
          </Tooltip>
          {createdDate && (
            <>
              <Typography variant="caption">&#x2022;</Typography>
              <Typography variant="caption">
                {format(parseISO(createdDate), 'MMM d, yyyy')}
              </Typography>
            </>
          )}
          {sourceMaterial && (
            <>
              <Typography variant="caption">&#x2022;</Typography>
              <Link
                href={sourceMaterial.link}
                target="_blank"
                rel="noopener noreferrer"
                variant="caption"
              >
                {sourceMaterial.name}
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default FullReportHeader
