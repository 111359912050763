import { useQuery } from '@apollo/client'
import { Link, Skeleton, Typography } from '@mui/material'
import React from 'react'

import { GET_YOUR_SECURITY_INDEX } from '@queries/securityIndex'

import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import { AnswerId } from '../CyberAssistantQuestions'

const SecurityIndexAnswer: React.FC<{
  selectedCustomer: string | null
}> = ({ selectedCustomer }) => {
  const {
    data: { getYourSecurityIndex: { latestIndex } } = {
      getYourSecurityIndex: { latestIndex: 0 },
    },
    loading,
    error,
  } = useQuery(GET_YOUR_SECURITY_INDEX, {
    variables: {
      selectedCustomer,
    },
  })

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  if (error) {
    return <EmptyDataAnswer />
  }

  return (
    <>
      Your current Security Index Score is:{' '}
      <Typography fontWeight={600} color={'textSecondary'} component={'span'}>
        {latestIndex}{' '}
      </Typography>
      <br />
      <br />
      Your Security Index Score is calculated based on your ingested log
      sources, enabled detections, and enterprise coverage. Each of these is
      weighted differently.
      <br />
      <br />
      Work with your Deepwatch Experts to increase your score over time. Review
      your change history on the{' '}
      <Link
        href={`/mdr/security-index/change-history${selectedCustomer ? `?customer=${selectedCustomer}` : ''}`}
        variant="body1"
      >
        Security Index
      </Link>{' '}
      page to see what has impacted your score recently.
      <FeedbackForm answerId={AnswerId.SI_SCORE} />
    </>
  )
}

export default SecurityIndexAnswer
