import { useEffect, useMemo } from 'react'
import DatePicker, { DatePickerProps } from 'react-datepicker'
import { endOfMonth, startOfMonth, startOfYear, subYears } from 'date-fns'
import { GlobalStyles } from '@mui/material'

import { zIndex } from '@components/App/Styles/zIndex'

import YearSelection from './YearSelection'
import MonthSelection from './MonthSelection'

export interface MonthPickerProps {
  date: Date
  setDate(date: Date): void
  isMonthDisabled(date: Date): boolean
  isYearDisabled?(date: Date): boolean
  closePopoverMenu?(): void
  setDateTo?: 'endOfMonth' | 'startOfMonth'
  popperContainer?: DatePickerProps['popperContainer']
}

const MonthPicker = ({
  date,
  setDate,
  isMonthDisabled,
  isYearDisabled,
  closePopoverMenu,
  setDateTo = 'startOfMonth',
  popperContainer,
}: MonthPickerProps) => {
  const now = useMemo(() => new Date(), [])

  const parsedDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0)

  /**
   * Get two years ago, last year, and current year (in that order)
   * Although this isn't computationally heavy I'm still wrapping it
   * in a useMemo since this component is going to update a lot from
   * the date being updated and this really only needs to be calculated
   * on the initial mount
   */
  const years = useMemo<[Date, Date, Date]>(() => {
    const currentYear = startOfYear(new Date())
    const twoYearsAgo = subYears(currentYear, 2)
    const lastYear = subYears(currentYear, 1)

    return [twoYearsAgo, lastYear, currentYear]
  }, [])

  const [twoYearsAgo, lastYear, currentYear] = years

  /**
   * This effect is used to check if we should adjust the date selection based on the
   * month disabled condition. If the current date is disabled then we calculate the
   * soonest valid date. We count backwards to the start of each month
   * in the year until it is not disabled.
   * This logic is for comparing dates within the same year. If the disabled dates are in a different year
   * then this logic will not cover it. However, you should only be using the month disabled prop in a way
   * that only works per year
   */
  useEffect(() => {
    // Check if the date month is invalid and adjust based on that
    if (!isMonthDisabled(date)) {
      return
    }

    // Reverse the array of months so we can find the most recent non-disabled month
    const startOfMonths = new Array(date.getMonth())
      .fill(0)
      .map((_, index) => new Date(date.getFullYear(), index, 1))
      .reverse()

    let firstValidMonth: Date | undefined

    for (const monthDate of startOfMonths) {
      if (!isMonthDisabled(monthDate)) {
        firstValidMonth = monthDate

        break
      }
    }

    if (!firstValidMonth) {
      return
    }

    setDate(firstValidMonth)
  }, [date, setDate, isMonthDisabled])

  const handleDateChange = (newDate) => {
    const parsedDate =
      setDateTo === 'endOfMonth' ? endOfMonth(newDate) : startOfMonth(newDate)

    setDate(parsedDate)
  }

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          '& .react-datepicker__tab-loop, & .react-datepicker-wrapper': {
            position: 'absolute',
            top: 0,
          },
          '.react-datepicker-popper': {
            zIndex: zIndex.DROPDOWN_MENU,
          },
          '& .react-datepicker': {
            padding: '18px 12px 12px',
            border: `1px solid ${theme.vars.palette.text.primary}`,
            borderRadius: '5px',
            height: 'auto',
            minHeight: '341px',
            width: '380px',
            fontFamily: 'Inter, sans-serif',
            zIndex: zIndex.DROPDOWN_MENU,
            top: '-10px',
            backgroundColor: theme.vars.palette.background.paper,
            color: theme.vars.palette.text.primary,
            boxShadow: 'rgb(0 0 0 / 35%) 0 5px 15px',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.dark,
            }),

            '[role="listbox"]': {
              height: 'auto',
            },

            // Hide year label when year is selected
            '& .react-datepicker__aria-live': {
              display: 'none',
            },

            '& .react-datepicker__month': {
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '100%',
            },

            '& .react-datepicker__month-wrapper': {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '8px',
            },

            '& .react-datepicker__month-text': {
              flex: '1',
            },
            '[data-isactive="true"]': {
              '&:hover': {
                color: theme.vars.palette.text.secondary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.background.paper,
                }),
              },
            },
          },
        })}
      />
      <DatePicker
        selected={parsedDate}
        onChange={handleDateChange}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        onClickOutside={closePopoverMenu}
        renderCustomHeader={(props) => (
          <YearSelection
            currentYear={currentYear}
            lastYear={lastYear}
            twoYearsAgo={twoYearsAgo}
            updateDate={setDate}
            isYearDisabled={isYearDisabled}
            {...props}
          />
        )}
        // Setting show four column month picker to true with showTwoColumMonthPicker defaulting to false shows three columns
        showFourColumnMonthYearPicker={false}
        showTwoColumnMonthYearPicker={false}
        renderMonthContent={(monthIndex, shortMonthText) => (
          <MonthSelection
            date={parsedDate}
            monthIndex={monthIndex}
            monthLabel={shortMonthText}
            isMonthDisabled={isMonthDisabled}
            onDateSelect={closePopoverMenu}
          />
        )}
        showPopperArrow={false}
        open
        minDate={twoYearsAgo}
        maxDate={now}
        customInput={<></>}
        popperContainer={popperContainer}
      />
    </>
  )
}

export default MonthPicker
