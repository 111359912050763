import { Detections, TicketPriorityEnum, TicketStateEnum } from '@models/index'
import {
  DeprecatedDetectionCatalogFormatStatus,
  DetectionCatalogFormatStatus,
} from '@models/Detections'

import {
  DeprecatedDetectionCatalogStatus,
  DetectionStatusType,
  lowerCaseDetectionStatus,
} from '../Coverage.utils'
import {
  DetectionStatus,
  DeprecatedDetectionStatus,
} from './DetectionCatalogStatusTag/DetectionCatalogStatusTag'

export const formatNoMappingDetection = (arr: Array<string>): string => {
  if (arr.length === 0) {
    return 'No Mapping'
  } else if (arr.length === 1) {
    return `${arr[0]}`
  } else if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`
  } else {
    return arr.join(', ').replace(/,([^,]*)$/, ' and$1')
  }
}

export const formatDetectionTitle = (title: string): string => {
  const splitTitle = title.split(/dwa_.+:/i)[1]
  if (splitTitle) {
    return splitTitle.trim()
  } else {
    return title
  }
}

export const formatStatus = (
  status: string,
): DeprecatedDetectionCatalogFormatStatus => {
  const lowercaseStatus = status.toLowerCase() as DeprecatedDetectionStatus
  let sortValue = '1'
  if (lowercaseStatus === 'enabled') {
    sortValue = '2'
  } else if (lowercaseStatus === 'available') {
    sortValue = '3'
  }
  return {
    variant: 'secondary',
    status: lowercaseStatus,
    sortValue: sortValue,
  }
}

export const formatDetectionStatus = (
  status: string,
): DetectionCatalogFormatStatus => {
  const lowercaseStatus = status.toLowerCase() as DetectionStatus // We convert to lowercase in case the status coming from the backend is not consistent
  let sortValue: string
  switch (lowercaseStatus) {
    // TODO: we can get rid of `lowerCaseDetectionStatus` if we are consistent with the status coming from the backend
    case lowerCaseDetectionStatus[DetectionStatusType.BLOCKED]: {
      sortValue = '1'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.ELIGIBLE]: {
      sortValue = '2'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.ENABLED]: {
      sortValue = '3'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.NOT_APPLICABLE]: {
      sortValue = '4'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.PENDING_REVIEW]: {
      sortValue = '5'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.TRIAL]: {
      sortValue = '6'
      break
    }
    default: {
      sortValue = '7'
      break
    }
  }
  return {
    variant: 'secondary',
    status: lowercaseStatus,
    sortValue: sortValue,
  }
}

export const formatSideSheetDetectionStatus = (
  status: string,
): DetectionCatalogFormatStatus => {
  const lowercaseStatus = status.toLowerCase() as DetectionStatus // We convert to lowercase in case the status coming from the backend is not consistent
  let sortValue: string
  switch (lowercaseStatus) {
    // TODO: we can get rid of `lowerCaseDetectionStatus` if we are consistent with the status coming from the backend
    case lowerCaseDetectionStatus[DetectionStatusType.ELIGIBLE]: {
      sortValue = '1'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.ENABLED]: {
      sortValue = '2'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.BLOCKED]: {
      sortValue = '3'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.NOT_APPLICABLE]: {
      sortValue = '4'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.PENDING_REVIEW]: {
      sortValue = '5'
      break
    }
    case lowerCaseDetectionStatus[DetectionStatusType.TRIAL]: {
      sortValue = '6'
      break
    }
    default: {
      sortValue = '7'
      break
    }
  }
  return {
    variant: 'secondary',
    status: lowercaseStatus,
    sortValue: sortValue,
  }
}

export const includeUnavailableDetections = (
  detections: Detections[],
  includeUnavailable: boolean,
): Detections[] => {
  return detections.filter((detection) => {
    if (includeUnavailable) {
      return detection.status === DeprecatedDetectionCatalogStatus.Unavailable
    }
    return (
      detection.status === DeprecatedDetectionCatalogStatus.Enabled ||
      detection.status === DeprecatedDetectionCatalogStatus.Available
    )
  })
}

export const emptyDetection: Detections = {
  useCase: '',
  title: '',
  customerVersion: '',
  releaseVersion: '',
  description: '',
  mitreTactics: [''],
  mitreTechniques: [''],
  dataTypes: [''],
  dataAvailable: false,
  availableInVersion: false,
  status: '',
  logSource: '',
  relatedThreatIntelReports: [
    {
      createdDate: '',
      title: '',
      type: '',
      id: '',
    },
  ],
  reviewedOn: '',
  reviewer: '',
  reviewNotes: '',
  statusDetails: '',
  relatedTickets: [
    {
      sysId: '',
      priority: TicketPriorityEnum.Critical,
      state: TicketStateEnum.New,
      ticketNumber: '',
    },
  ],
  daysInTrialMode: null,
}
