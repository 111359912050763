import React from 'react'
import { Box, Theme, Typography } from '@mui/material'

import PopoverListItem from '@common/PopoverListItem'
import Icon from '@common/Icon'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import { IconVariant } from '@common/Icon/Icons'

interface UserAvatarMenuItemProps {
  icon: IconVariant
  onClick: VoidFunction
  text: string
  isExternal?: boolean
}

export const UserAvatarMenuItem: React.FC<UserAvatarMenuItemProps> = ({
  icon,
  onClick,
  text,
  isExternal,
}) => (
  <PopoverListItem
    onClick={onClick}
    onKeyDown={(e) => handleKeyboardAction(e, onClick)}
    sx={{
      borderBottom: 'none !important',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '.6rem',
      paddingBottom: '.6rem',
    }}
  >
    <Icon
      variant={icon}
      sx={(theme) => ({
        color: theme.vars.palette.text.primary,
      })}
    />
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={(theme: Theme) => ({
          textTransform: 'uppercase',
          color: theme.vars.palette.text.primary,
          fontWeight: 500,
        })}
        variant="body2"
      >
        {text}
      </Typography>
      {isExternal ? (
        <Icon
          sx={(theme) => ({
            marginLeft: '.25rem',
            color: theme.vars.palette.text.primary,
          })}
          size={11}
          variant="openOutline"
        />
      ) : null}
    </Box>
  </PopoverListItem>
)
