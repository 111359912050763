import { AppAction, AppState } from '../Types'

export const setGlobalLoading = (state: AppState, action: AppAction) => {
  return {
    ...state,
    globalLoading: action['globalLoading'],
  }
}

export const setHomePath = (state: AppState, action: AppAction) => {
  return {
    ...state,
    homePath: action['homePath'],
  }
}

export const setDateFilter = (state: AppState, action: AppAction) => {
  return {
    ...state,
    dateFilter: {
      ...state.dateFilter,
      ...action['dateFilter'],
    },
  }
}

export const setTermsAccepted = (
  state: AppState,
  action: AppAction,
): AppState => {
  return {
    ...state,
    termsAccepted: action['termsAccepted'],
  }
}
