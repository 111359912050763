import { useContext, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import {
  DOWNLOAD_REPORT,
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
  GetReportsData,
  GetReportsVariables,
} from '@queries/report'
import { ComponentError } from '@common/ComponentError'
import { Context } from '@components/App'
import { GenerateReportModal } from '@components/Reports/GenerateReportModal'
import { Report } from '@models/index'
import { handleDownload, handleKeyboardAction } from '@utils/index'
import { IconVariant } from '@common/Icon/Icons'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { Dialog } from '@common/Dialog'
import { AlertSeverity, useToast } from '@hooks/useToast'

interface RecentReportsInterface {
  selectedCustomer: string | null
}

const RecentReports = ({
  selectedCustomer,
}: RecentReportsInterface): JSX.Element => {
  const { state } = useContext(Context)
  const { handleShowToast } = useToast()
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false)

  const {
    loading,
    data: { getReports: reports } = { getReports: [] },
    previousData: { getReports: oldReports } = { getReports: [] },
    startPolling,
    stopPolling,
  } = useQuery<GetReportsData, GetReportsVariables>(GET_REPORTS, {
    variables: {
      selectedCustomer,
    },
    onCompleted: (data) => {
      if (data) {
        //Currently using polling as in the Reports component, but would like to upgrade to subscriptions when able
        const result = reports.some((x) => x.status === 'Requested')
        if (result) {
          startPolling(10000)
        } else {
          stopPolling()
        }
        const oldRequestedReports = oldReports.filter(
          (x) => x.status === 'Requested',
        )

        oldRequestedReports.forEach((oldReport) => {
          const match = reports.find(
            (newReport) =>
              oldReport.id === newReport.id &&
              newReport.status !== 'Requested' &&
              newReport.userName === state.user.username,
          )

          if (match) {
            handleShowToast(
              AlertSeverity.Success,
              'Report has successfully been generated!',
            )
          }
        })
      }
    },
  })

  const [downloadReport] = useLazyQuery<
    DownloadReportData,
    DownloadReportVariables
  >(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
    },
  })

  const handleDownloadButtonClick = (report: Report) => {
    downloadReport({
      variables: {
        input: { reportId: report.id },
        selectedCustomer,
      },
    })
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Loader strokeWidth={2} size={50} />
        </Box>
      )
    }

    if (!reports) {
      return <ComponentError />
    }

    const areReportsValid = reports.some((val) => val.status !== 'Failed')

    if (reports.length > 0 && areReportsValid) {
      const listOptions = reports
        .filter(
          (reports) =>
            reports.status === 'Generated' || reports.status === 'Requested',
        )
        .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))

        .map((report, index) => {
          return {
            id: report.id,
            key: index,
            name: report.name,
            rightIcon: 'downloadOutline' as IconVariant,
            loading: report.status === 'Requested' ? true : false,
            onClick: () => handleDownloadButtonClick(report),
          }
        })
        .slice(0, 3)

      return (
        <List>
          {listOptions.map((option) => {
            return (
              <ListItem
                key={option.id}
                onClick={option.onClick}
                onKeyUp={(event) => handleKeyboardAction(event, option.onClick)}
              >
                <ListItemText primary={option.name} />
                {option.loading ? (
                  <Loader />
                ) : (
                  <ListItemIcon sx={{ marginRight: 0 }}>
                    <Icon variant={option.rightIcon} size={20} />
                  </ListItemIcon>
                )}
              </ListItem>
            )
          })}
        </List>
      )
    } else {
      return (
        <Box
          data-testid="report-empty-state"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            padding: '0.5rem',
          }}
        >
          <Icon
            size={150}
            variant="reportsEmptyState"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
          />
          <Typography color="textPrimary" fontWeight={600} variant="body2">
            No generated reports
          </Typography>

          <Button
            onClick={() => setIsGenerateReportModalOpen(true)}
            variant="text"
          >
            Generate Report
          </Button>
        </Box>
      )
    }
  }

  return (
    <Box id="recent-reports" data-testid="recent-reports">
      <Box sx={{ padding: '1rem 0' }}>
        <Typography variant="h6" color="textPrimary">
          Recently generated reports
        </Typography>

        {!loading && !reports && (
          <Icon variant="warningOutline" size={20} color="warning" />
        )}
      </Box>

      {renderContent()}

      <Dialog
        title="Monthly Report"
        isOpen={isGenerateReportModalOpen}
        onClose={() => setIsGenerateReportModalOpen(false)}
      >
        <GenerateReportModal
          closeModal={() => setIsGenerateReportModalOpen(false)}
        />
      </Dialog>
    </Box>
  )
}

export default RecentReports
