import {
  SortingState,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import { useState, useMemo } from 'react'
import { Box, Typography } from '@mui/material'

import { TableHeader } from '@common/Table'
import {
  buildGenericCell,
  buildGenericHeader,
} from '@common/Table/utils/table.utils'
import { DeviationAmount, SourcetypeDeviation } from '@models/EnvHealth'
import Icon from '@common/Icon'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'

const DeviationCell: React.FC<DeviationAmount> = ({ gb, delta }) => {
  const cellColor = delta > 0 ? 'success' : 'error'

  const roundedDelta = Math.round(delta)
  const signedGb = gb * (delta >= 0 ? 1 : -1)
  const roundedGb = signedGb.toFixed(2)
  const formattedCellData = `${roundedGb}GB (${roundedDelta}%)`

  return (
    <Box sx={{ display: 'flex', gap: '0.5rem' }} data-testid="deviation-cell">
      <Icon
        variant={delta > 0 ? 'arrowUpCircleOutline' : 'arrowDownCircleOutline'}
        sx={(theme) => ({
          color:
            delta > 0
              ? theme.vars.palette.success.main
              : theme.vars.palette.error.main,
        })}
      />
      <Typography
        data-testid={`deviation-cell-text-${cellColor}`}
        color={cellColor}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        noWrap={true}
        variant="body2"
      >
        {formattedCellData}
      </Typography>
    </Box>
  )
}

interface TopSourceTypeUtilizationDeviationTableProps {
  data: SourcetypeDeviation[]
}

export const TopSourceTypeUtilizationDeviationTable: React.FC<
  TopSourceTypeUtilizationDeviationTableProps
> = ({ data }) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'deviationAmount',
      desc: true,
    },
  ])

  const topSourceTypesUsageDeviationColumns = useMemo(() => {
    const columnHelper = createColumnHelper<SourcetypeDeviation>()

    return [
      columnHelper.accessor('logSource', {
        header: () => buildGenericHeader('Source type'),
        cell: (cell) => buildGenericCell(cell),
        sortingFn: 'textCaseSensitive',
      }),
      columnHelper.accessor('deviationAmount', {
        header: () => buildGenericHeader('Deviation Amount'),
        cell: (cell) => {
          return <DeviationCell {...cell.getValue()} />
        },
        sortingFn: (rowA, rowB) =>
          rowA.original.deviationAmount.gb - rowB.original.deviationAmount.gb,
        sortDescFirst: true,
      }),
    ]
  }, [])

  const table = useReactTable({
    columns: topSourceTypesUsageDeviationColumns,
    data: useMemo(
      () =>
        data
          ? data.sort((a, b) => a.deviationAmount.gb - b.deviationAmount.gb)
          : [],
      [data],
    ),
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <CommonTableContainer>
      <CommonTable>
        <thead data-testid="eh-ban-side-sheet__table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader<SourcetypeDeviation>
                  key={header.id}
                  header={header}
                  width={'50%'}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid="eh-ban-side-sheet__table-body">
          {table.getRowModel().rows.map((row) => (
            <MemoizedTanStackTableRow
              disableCursor
              key={row.id}
              rowId={`eh-ban-side-sheet__row-${row.id}`}
              testId="eh-ban-side-sheet__row"
              isSelected={false}
              visibleCells={row.getVisibleCells()}
            />
          ))}
        </tbody>
      </CommonTable>
    </CommonTableContainer>
  )
}
