import { useContext } from 'react'

import { Context } from '@components/App'

import type { Theme } from '@mui/material'
import type { CSSObject, SystemStyleObject } from '@mui/system'

interface GenerateDateFilterableStylesParams {
  /**
   * @description An optional function to specify the non-highlighted border color in terms of the theme.
   * @example
   * ```tsx
   * const dateFilterableStyles = useDateFilterableStyles({
   *   // ...
   *   getDefaultBorderColor: (theme) => ({
   *     light: theme.vars.palette.primary.main,
   *     dark: theme.vars.palette.primary.main
   *   }),
   * })
   */
  getDefaultBorderColor?: (theme: Theme) => {
    light: CSSObject['borderColor']
    dark: CSSObject['borderColor']
  }
}

const defaultOptions: Required<GenerateDateFilterableStylesParams> = {
  getDefaultBorderColor: () => ({
    light: 'transparent',
    dark: 'transparent',
  }),
} as const

/**
 * @description Generate border highlight styles to be used in the `sx` prop of a component to indicate it is affected by the date filter
 * @param {GenerateDateFilterableStylesParams} options Optional parameters to customize the generated styles
 * @returns {SxStyle} The generated styles
 */
export function useDateFilterableStyles(
  options?: GenerateDateFilterableStylesParams,
): ((theme: Theme) => SystemStyleObject<Theme>) | null {
  const { getDefaultBorderColor = defaultOptions.getDefaultBorderColor } =
    options ?? defaultOptions
  const { state } = useContext(Context)
  const isHighlighted = state.dateFilter.isCalendarIconHovering

  return (theme) => {
    const highlightColor = {
      light: theme.vars.palette.primary.dark,
      dark: theme.vars.palette.primary.main,
    }
    const defaultBorderColor = getDefaultBorderColor(theme)

    return {
      transition: 'ease-out border-color .3s',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: isHighlighted
        ? highlightColor['light']
        : defaultBorderColor['light'],
      ...theme.applyStyles('dark', {
        borderColor: isHighlighted
          ? highlightColor['dark']
          : defaultBorderColor['dark'],
      }),
    }
  }
}
