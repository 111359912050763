/* eslint-disable @getify/proper-ternary/nested */
/* eslint-disable security/detect-object-injection */
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  useNavigate,
  useSearchParams,
  useLocation,
  NavigateOptions,
} from 'react-router-dom'
import { Box, Button, IconButton } from '@mui/material'

import { SideSheet, sideSheetHeaderCss } from '@common/SideSheet'
import { Context as AppContext } from '@components/App'
import { ThreatIntelReport } from '@models/ThreatIntel'
import { navigateUserType } from '@utils/index'
import { Paths } from '@components/App/Types'
import { ComponentError } from '@common/ComponentError'
import Icon from '@common/Icon'

import { getSideSheetHeaderText } from './ThreatIntelSideSheet.utils'
import ThreatIntelSideSheetContent from './ThreatIntelSideSheetContent'

export enum ThreatIntelSideSheetArrowDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface BaseThreatIntelSideSheetProps {
  isOpen: boolean
  closeSideSheet: () => void
  threatIntelReport: ThreatIntelReport | null
}

export type ThreatIntelSideSheetPropsDiscriminatedUnion =
  | {
      animationEnabled: true
      onArrowClick(direction: ThreatIntelSideSheetArrowDirection): void
      animationDirection: ThreatIntelSideSheetArrowDirection | undefined
      setAnimationDirection(
        value: ThreatIntelSideSheetArrowDirection | undefined,
      ): void
    }
  | {
      animationEnabled?: false
      onArrowClick?: never
      animationDirection?: never
      setAnimationDirection?: never
    }

export type ThreatIntelSideSheetProps = BaseThreatIntelSideSheetProps &
  ThreatIntelSideSheetPropsDiscriminatedUnion

const ANIMATION_DURATION = 350

const ThreatIntelSideSheet = ({
  isOpen,
  closeSideSheet,
  threatIntelReport,
  animationEnabled,
  animationDirection,
  onArrowClick,
  setAnimationDirection,
}: ThreatIntelSideSheetProps) => {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const location = useLocation()

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const [previousReport, setPreviousReport] =
    useState<ThreatIntelReport | null>(threatIntelReport)

  const isPreviousReportSameReport =
    previousReport &&
    threatIntelReport &&
    previousReport.id === threatIntelReport.id

  const shouldAnimate =
    animationEnabled && animationDirection && !isPreviousReportSameReport

  const animateSidesheet = useCallback(() => {
    const timeout = setTimeout(() => {
      setAnimationDirection?.(undefined)
      setPreviousReport(threatIntelReport)
    }, ANIMATION_DURATION)

    return () => {
      clearTimeout(timeout)
    }
  }, [threatIntelReport, setAnimationDirection, setPreviousReport])

  // Animate on report chnage
  useEffect(() => {
    if (!shouldAnimate) {
      if (!isPreviousReportSameReport) {
        // Even if not animating, still set the previous report if it changed, so that a future animation can be triggered
        setPreviousReport(threatIntelReport)
      }
      return
    }

    return animateSidesheet()
  }, [
    shouldAnimate,
    threatIntelReport,
    isPreviousReportSameReport,
    setPreviousReport,
    animateSidesheet,
  ])

  const isThreatIntelReportsPath =
    location.pathname === Paths.THREAT_INTEL_REPORTS

  const handleOpenReport = () => {
    if (!threatIntelReport) {
      return
    }

    const options: NavigateOptions | undefined =
      isThreatIntelReportsPath && !!searchParams.size
        ? {
            state: {
              searchParams: searchParams.toString(),
            },
          }
        : undefined

    navigateUserType(
      isDWEmployee,
      `/threat-intel/${threatIntelReport.id}`,
      navigate,
      customerShortName,
      options,
    )
  }

  return (
    <SideSheet
      data-testid={`threat-intel-sidesheet-${isOpen ? 'open' : 'closed'}`}
      open={isOpen}
      closeSideSheet={closeSideSheet}
      renderTitle={() => (
        <Box
          sx={sideSheetHeaderCss.header}
          data-testid="threat-intel-sidesheet-header"
        >
          <Box sx={sideSheetHeaderCss.headerContent}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={sideSheetHeaderCss.titleContent}>
                {getSideSheetHeaderText(threatIntelReport?.type)}
              </Box>
              {animationEnabled && (
                <Box>
                  <IconButton
                    onClick={() =>
                      onArrowClick(ThreatIntelSideSheetArrowDirection.UP)
                    }
                    disabled={!isOpen}
                    data-testid="threat-intel-up-arrow"
                  >
                    <Icon variant="arrowUpCircleOutline" />
                  </IconButton>

                  <IconButton
                    onClick={() =>
                      onArrowClick(ThreatIntelSideSheetArrowDirection.DOWN)
                    }
                    disabled={!isOpen}
                    data-testid="threat-intel-down-arrow"
                  >
                    <Icon variant="arrowDownCircleOutline" />
                  </IconButton>
                </Box>
              )}
            </Box>
            <IconButton onClick={closeSideSheet} disabled={!isOpen}>
              <Icon variant="closeOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
      footer={
        threatIntelReport && (
          <Button
            onClick={handleOpenReport}
            disabled={!isOpen}
            variant="contained"
          >
            Read full report
          </Button>
        )
      }
    >
      <Box
        id="threat-intel-sidesheet"
        data-testid="threat-intel-sidesheet-test-id"
      >
        {!threatIntelReport ? (
          <ComponentError />
        ) : (
          <ThreatIntelSideSheetContent threatIntelReport={threatIntelReport} />
        )}
      </Box>
    </SideSheet>
  )
}

export default ThreatIntelSideSheet
