import { LinearProgressProps, Theme } from '@mui/material'

export interface BreakPoint {
  threshold: number
  color: string
}
/**
 * Determine bar color for linear progress bars
 * @param breakpoints an array of break point values containing the threshold and color
 * @param value number representing the progress for the progress bar
 * @param theme theme object to get starting color
 * @returns color for the bar
 */
export const linearProgressBarColor = (
  breakpoints: BreakPoint[],
  value: number,
  theme: Theme,
): LinearProgressProps['color'] =>
  breakpoints.reduce(
    (prevBreakpoint, breakpoint) => {
      if (
        value >= breakpoint.threshold &&
        breakpoint.threshold >= prevBreakpoint.threshold
      ) {
        return breakpoint
      }
      return prevBreakpoint
    },
    { threshold: 0, color: theme.vars.palette.text.primary },
  ).color as LinearProgressProps['color']
