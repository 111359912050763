import { useState } from 'react'
import { Box, Link, Typography } from '@mui/material'

import {
  isValidSIEMLink,
  isValidSIEMSearch,
} from '@components/Tickets/TicketLibrary/TicketLibrary.utils'

import { AdditionalDetailsRow } from '../../Types'
import { defineColumnKeys, setPTagsAndLabels } from '../../utils'
import { additionalFieldsUniqueLabelNames } from './AdditionalDetailsUtils'

const AdditionalDetailsRowMapping = {
  threatHuntOutcomes: 'Threat Hunt outcomes',
  ioc: 'IOC',
}

interface AdditionalDetailsTopRowProps {
  data: AdditionalDetailsRow
}
// only handles Threat Hunt outcomes and IOC; everything else is handled by AdditionalDetailsColumn
export const AdditionalDetailsTopRow: React.FC<
  AdditionalDetailsTopRowProps
> = ({ data }) =>
  Object.entries(data).map(([key, value]) => {
    switch (key) {
      case 'threatHuntOutcomes':
      case 'ioc':
        return (
          <Box>
            <Typography fontWeight={600} variant="body2">
              {AdditionalDetailsRowMapping[key]}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {value}
            </Typography>
          </Box>
        )
      default:
        return null
    }
  })

interface AdditionalDetailsColumnProps {
  data: AdditionalDetailsRow
  colIndex: number
}

const AdditionalDetailsColumn: React.FC<AdditionalDetailsColumnProps> = ({
  data,
  colIndex,
}) => {
  const twoColData = { ...data }
  delete twoColData.ioc
  delete twoColData.threatHuntOutcomes
  delete twoColData.__typename
  const columns = defineColumnKeys(twoColData)
  const [clipboardMessage, setClipboardMessage] = useState(false)

  const SIEMLabelStyles = {
    marginBottom: 8,
    display: 'block',
  }

  const handleSplunkSearchCopyToClipboard = (splunkSearch: string) => {
    navigator.clipboard.writeText(splunkSearch)
    displayClipboardMessage()
  }

  const displayClipboardMessage = () => {
    if (!clipboardMessage) {
      setClipboardMessage(true)
      setTimeout(() => {
        setClipboardMessage(false)
      }, 3000)
    }
  }

  return (
    <>
      {Object.entries(twoColData)
        .filter((key) => columns.at(colIndex)!.includes(key[0]))
        .map(([key, value]) => {
          switch (key) {
            case 'cisControl':
            case 'incidentType':
              return (
                <Box key={key}>
                  <Typography fontWeight={600} variant="body2">
                    {additionalFieldsUniqueLabelNames(key)}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="body2"
                  >
                    {value}
                  </Typography>
                </Box>
              )
            case 'splunkLink':
              return (
                <Box>
                  <Typography
                    sx={{
                      ...SIEMLabelStyles,
                      textAlign: 'left',
                      marginBottom: 0,
                    }}
                    fontWeight={600}
                    variant="body2"
                  >
                    SIEM link
                  </Typography>

                  {typeof value === 'string' && isValidSIEMLink(value) ? (
                    <Link
                      href={value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in new tab
                    </Link>
                  ) : (
                    <Typography
                      sx={(theme) => ({
                        color: theme.vars.palette.text.primary,
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      })}
                    >
                      --
                    </Typography>
                  )}
                </Box>
              )
            case 'splunkSearch':
              return (
                <Box sx={{ position: 'relative' }}>
                  <Typography
                    fontWeight={600}
                    sx={{
                      ...SIEMLabelStyles,
                      textAlign: 'left',
                      marginBottom: 0,
                    }}
                    variant="body2"
                  >
                    SIEM search
                  </Typography>

                  {isValidSIEMSearch(value) && typeof value === 'string' ? (
                    <Link
                      onClick={() => handleSplunkSearchCopyToClipboard(value)}
                      sx={{ cursor: 'pointer' }}
                    >
                      Copy to clipboard
                    </Link>
                  ) : (
                    <Typography
                      sx={(theme) => ({
                        color: theme.vars.palette.text.primary,
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      })}
                    >
                      --
                    </Typography>
                  )}

                  {clipboardMessage && (
                    <Typography
                      sx={(theme) => ({
                        background: theme.vars.palette.common.white,
                        border: `1px solid ${theme.vars.palette.secondary.main}`,
                        boxShadow: '0 1px 4px rgb(25 41 46 / 40%)',
                        borderRadius: '2px',
                        padding: '6px',
                        position: 'absolute',
                        top: 0,
                        ...theme.applyStyles('dark', {
                          background: theme.vars.palette.secondary.darker,
                          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
                        }),
                      })}
                      variant="caption"
                    >
                      Copied to clipboard!
                    </Typography>
                  )}
                </Box>
              )

            default:
              return (
                <Box>
                  <Typography fontWeight={600} variant="body2">
                    {setPTagsAndLabels(key)}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="body2"
                  >
                    {value || '--'}
                  </Typography>
                </Box>
              )
          }
        })}
    </>
  )
}

export default AdditionalDetailsColumn
