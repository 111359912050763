import { useState } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import {
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
  Box,
  Theme,
  TextField,
} from '@mui/material'

import {
  ADD_THREAT_INTEL_REPORT_FEEDBACK,
  AddThreatIntelReportFeedbackData,
  AddThreatIntelReportFeedbackVariables,
} from '@mutations/threatIntel'
import { Loader } from '@common/Loader'

interface ThreatIntelFeedbackProps {
  customerShortName: string
  reportId: string
}

export enum FeedbackLikeOptions {
  ACCURATE_INFO = 'accurateInfo',
  RELEVANT_INDUSTRY = 'relevantIndustry',
  ACTIONABLE = 'actionable',
  SHAREABLE = 'shareable',
  UP_TO_DATE = 'upToDate',
  MORE_INFO = 'moreInfo',
}

export interface ReportFeedbackFormValues {
  isReportValuable?: boolean
  accurateInfo: boolean
  relevantIndustry: boolean
  actionable: boolean
  shareable: boolean
  upToDate: boolean
  moreInfo?: string
}

export const ThreatIntelFeedback: React.FC<ThreatIntelFeedbackProps> = ({
  customerShortName,
  reportId,
}) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false)

  const [addReportFeedback, { loading, error }] = useMutation<
    AddThreatIntelReportFeedbackData,
    AddThreatIntelReportFeedbackVariables
  >(ADD_THREAT_INTEL_REPORT_FEEDBACK)

  const handleSubmit = async (values: ReportFeedbackFormValues) => {
    await addReportFeedback({
      onCompleted: () => {
        setFeedbackSubmitted(true)
      },
      onError: () => {
        setFeedbackSubmitted(true)
      },
      variables: {
        selectedCustomer: customerShortName,
        input: {
          threatIntelReportFeedback: {
            reportId,
            isReportValuable: values.isReportValuable as boolean,
            ...values,
          },
        },
      },
    })
  }

  const renderFeedbackStatus = () => {
    if (error) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontWeight={600} variant="body1" color="error">
            We were unable to submit feedback.
          </Typography>
          <Typography variant="body1">
            Please refresh the page and try again.
          </Typography>
        </Box>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          fontWeight={600}
          variant="body1"
          sx={(theme) => ({
            color: theme.vars.palette.success.dark,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.success.light,
            }),
          })}
        >
          We appreciate the feedback!
        </Typography>
        <Typography variant="body2">
          Thank you for making the product better.
        </Typography>
      </Box>
    )
  }

  const initialValues: ReportFeedbackFormValues = {
    isReportValuable: undefined,
    accurateInfo: false,
    relevantIndustry: false,
    actionable: false,
    shareable: false,
    upToDate: false,
    moreInfo: undefined,
  }

  if (feedbackSubmitted) {
    return renderFeedbackStatus()
  }

  const formControlLabelStyles = (theme: Theme) => ({
    border: 'none',
    alignItems: 'center',
    '.MuiFormControlLabel-label': {
      border: 'none',
      color: theme.vars.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      padding: '0',
      margin: '4px',
      ...theme.applyStyles('dark', {
        color: theme.vars.palette.text.secondary,
      }),
    },
  })

  const checkboxStyles = (theme: Theme) => ({
    visibility: 'visible',
    position: 'relative',
    appearance: 'none',
    '&.Mui-checked + span': {
      backgroundColor: 'transparent',
    },
    '.MuiSvgIcon-root': {
      fill: theme.vars.palette.text.primary,
      ...theme.applyStyles('dark', {
        fill: theme.vars.palette.text.secondary,
      }),
    },
  })

  return (
    <Box data-testid="report-feedback">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleChange, handleSubmit, setFieldValue, values }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          >
            {reportId && values.isReportValuable === undefined && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
              >
                <Typography fontWeight={600} variant="body1">
                  Feedback
                </Typography>
                <Typography variant="body2">
                  Your feedback helps us refine our reports and provide a better
                  experience.
                </Typography>
                <Typography fontWeight={500} variant="body1">
                  Was this report valuable?
                </Typography>

                <Box sx={{ display: 'flex' }}>
                  <Button
                    onClick={() => {
                      setFieldValue('isReportValuable', true)
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setFieldValue('isReportValuable', false)
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            )}

            {values.isReportValuable === true && (
              <>
                <Typography fontWeight={600} variant="body1">
                  What did you like?
                </Typography>

                <FormControlLabel
                  label={'I found the information accurate'}
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      id="feedback-like-option-1"
                      name={FeedbackLikeOptions.ACCURATE_INFO}
                      checked={values.accurateInfo}
                      hidden={true}
                      onChange={handleChange}
                      sx={checkboxStyles}
                    />
                  }
                />

                <FormControlLabel
                  label={'Was relevant to my industry'}
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      id="feedback-like-option-2"
                      name={FeedbackLikeOptions.RELEVANT_INDUSTRY}
                      checked={values.relevantIndustry}
                      hidden={true}
                      onChange={handleChange}
                      sx={checkboxStyles}
                    />
                  }
                />

                <FormControlLabel
                  label={'I found it actionable'}
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      id="feedback-like-option-2"
                      name={FeedbackLikeOptions.ACTIONABLE}
                      checked={values.actionable}
                      hidden={true}
                      onChange={handleChange}
                      sx={checkboxStyles}
                    />
                  }
                />

                <FormControlLabel
                  label={'It was information I can share with my team'}
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      id="feedback-like-option-2"
                      name={FeedbackLikeOptions.SHAREABLE}
                      checked={values.shareable}
                      hidden={true}
                      onChange={handleChange}
                      sx={checkboxStyles}
                    />
                  }
                />

                <FormControlLabel
                  label={'It was up-to-date'}
                  sx={formControlLabelStyles}
                  control={
                    <Checkbox
                      id="feedback-like-option-2"
                      name={FeedbackLikeOptions.UP_TO_DATE}
                      checked={values.upToDate}
                      hidden={true}
                      onChange={handleChange}
                      sx={checkboxStyles}
                    />
                  }
                />

                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? <Loader /> : 'Submit'}
                </Button>
              </>
            )}

            {values.isReportValuable === false && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontWeight={600} variant="body1">
                  How can we improve?
                </Typography>

                <TextField
                  multiline
                  name={FeedbackLikeOptions.MORE_INFO}
                  onChange={handleChange}
                  placeholder="More information, additional context to my environment, etc"
                  value={values.moreInfo}
                />

                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? <Loader /> : 'Submit'}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Formik>
    </Box>
  )
}
