import { createPortal } from 'react-dom'
import { Formik, FormikErrors } from 'formik'
import { format, startOfYear } from 'date-fns/fp'
import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import {
  GENERATE_REPORT,
  GenerateReportData,
  GenerateReportVariables,
} from '@mutations/report'
import { GET_REPORTS } from '@queries/report'
import { Context as AppContext } from '@components/App'
import PopoverContent from '@common/PopoverContent'
import Icon from '@common/Icon'
import { trackAnalyticEvent } from '@utils/analytics'
import { MonthPicker } from '@common/MonthPicker'
import { AlertSeverity, useToast } from '@hooks/useToast'

import {
  isBeforeStartDate,
  initialStartDate,
  initialEndDate,
  isAfterEndDate,
} from './GenerateReportModalHelpers'

interface GenerateReportModalProps {
  closeModal: VoidFunction
}

const GenerateReportModal: React.FC<GenerateReportModalProps> = ({
  closeModal,
}) => {
  const [isStartDateOpen, setIsStartDateOpen] = useState(false)
  const [isEndDateOpen, setIsEndDateOpen] = useState(false)

  const {
    refs: startDateRefs,
    floatingStyles: startDateFloatingStyles,
    context: startDateContext,
  } = useFloating({
    middleware: [
      offset(() => {
        return {
          mainAxis: -30,
          crossAxis: -30,
        }
      }),
    ],
    open: isStartDateOpen,
    onOpenChange: setIsStartDateOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
  })

  const { getFloatingProps: getStartDateFloatingProps } = useInteractions([
    useClick(startDateContext),
    useDismiss(startDateContext),
    useRole(startDateContext),
  ])

  const {
    refs: endDateRefs,
    floatingStyles: endDateFloatingStyles,
    context: endDateContext,
  } = useFloating({
    middleware: [
      offset(() => {
        return {
          mainAxis: -30,
          crossAxis: -30,
        }
      }),
    ],
    open: isEndDateOpen,
    onOpenChange: setIsEndDateOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
  })

  const { getFloatingProps: getEndDateFloatingProps } = useInteractions([
    useClick(endDateContext),
    useDismiss(endDateContext),
    useRole(endDateContext),
  ])

  const {
    state: {
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const { handleShowToast } = useToast()

  const [generateReport] = useMutation<
    GenerateReportData,
    GenerateReportVariables
  >(GENERATE_REPORT, {
    onError: () => {
      handleShowToast(
        AlertSeverity.Error,
        'Your report has failed to generate, please try again in a minute.',
      )
    },
    refetchQueries: [GET_REPORTS],
  })

  const toggleModal = () => {
    closeModal()
  }

  const handleSubmit = ({
    reportName,
    startDate,
    endDate,
  }: {
    reportName: string
    startDate: Date
    endDate: Date
  }) => {
    generateReport({
      variables: {
        input: {
          reportId: 0,
          reportName: reportName,
          startDate: format('yyyy-MM-dd', startDate),
          endDate: format('yyyy-MM-dd', endDate),
        },
        selectedCustomer: customerShortName,
      },
    })

    toggleModal()
    handleShowToast(AlertSeverity.Info, 'Generating report', 5000)
  }

  const now = new Date()

  return (
    <Box id="generate-report-modal" sx={{ padding: '1.5rem' }}>
      <Formik
        initialValues={{
          reportName: '',
          startDate: initialStartDate,
          endDate: initialEndDate,
        }}
        onSubmit={handleSubmit}
        validate={({ reportName, startDate, endDate }) => {
          const errors: FormikErrors<{
            reportName: string
            startDate: string
            endDate: string
          }> = {}

          // validate report name
          if (!reportName) {
            errors.reportName = 'The report name is required'
          } else if (reportName.length < 3 || reportName.length > 256) {
            errors.reportName =
              'The report name must be between 3 and 256 characters.'
          }

          // validate start date and end date
          if (startDate > endDate) {
            errors.startDate = 'Start date must come before end date.'
          }

          return errors
        }}
      >
        {({
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm,
          errors,
          values,
          dirty,
          isValid,
          touched,
        }) => (
          <>
            <TextField
              sx={{ marginBottom: '1.5rem' }}
              error={errors.reportName !== undefined && touched.reportName}
              helperText={
                errors.reportName ||
                (dirty &&
                  'The report name must be between 3 and 256 characters.')
              }
              label={'Report Name'}
              placeholder={'Enter a report name'}
              required={true}
              value={values.reportName}
              name="reportName"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Box sx={{ marginTop: '1rem', display: 'flex', gap: '30px' }}>
              <TextField
                name="startDate"
                type="text"
                label="From"
                value={
                  values.startDate ? format('MMM yyyy', values.startDate) : ''
                }
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon variant="calendarOutline" />
                      </InputAdornment>
                    ),
                  },
                }}
                ref={startDateRefs.setReference}
                onFocus={() => setIsStartDateOpen(true)}
                onMouseDown={(event) => {
                  event?.stopPropagation()
                  setIsStartDateOpen((prevIsOpen) => !prevIsOpen)
                }}
              />
              {isStartDateOpen && (
                <FloatingFocusManager context={startDateContext}>
                  <PopoverContent
                    floatingStyles={{
                      border: 'none',
                      ...startDateFloatingStyles,
                    }}
                    sx={{
                      visibility: 'hidden',
                    }}
                    getFloatingProps={getStartDateFloatingProps}
                    setFloating={startDateRefs.setFloating}
                  >
                    <MonthPicker
                      closePopoverMenu={() => setIsStartDateOpen(false)}
                      date={values.startDate}
                      setDate={(date) => setFieldValue('startDate', date)}
                      isMonthDisabled={(date: Date) =>
                        date > now || isAfterEndDate(values.endDate, date)
                      }
                      popperContainer={({ children }) =>
                        createPortal(
                          children,
                          document.querySelector('.MuiDialog-container')!,
                        )
                      }
                    />
                  </PopoverContent>
                </FloatingFocusManager>
              )}
              <TextField
                name="endDate"
                type="text"
                label="To"
                value={values.endDate ? format('MMM yyyy', values.endDate) : ''}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon variant="calendarOutline" />
                      </InputAdornment>
                    ),
                  },
                }}
                ref={endDateRefs.setReference}
                onFocus={() => setIsEndDateOpen(true)}
                onMouseDown={(event) => {
                  event?.stopPropagation()
                  setIsEndDateOpen((prevIsOpen) => !prevIsOpen)
                }}
              />

              {isEndDateOpen && (
                <FloatingFocusManager context={endDateContext}>
                  <PopoverContent
                    sx={{
                      visibility: 'hidden',
                    }}
                    floatingStyles={{
                      ...endDateFloatingStyles,
                    }}
                    getFloatingProps={getEndDateFloatingProps}
                    setFloating={endDateRefs.setFloating}
                  >
                    <MonthPicker
                      closePopoverMenu={() => setIsEndDateOpen(false)}
                      date={values.endDate}
                      setDate={(date) => setFieldValue('endDate', date)}
                      isMonthDisabled={(date) =>
                        date > now || isBeforeStartDate(values.startDate, date)
                      }
                      isYearDisabled={(date) =>
                        isBeforeStartDate(startOfYear(values.startDate), date)
                      }
                      setDateTo="endOfMonth"
                      popperContainer={({ children }) =>
                        createPortal(
                          children,
                          document.querySelector('.MuiDialog-container')!,
                        )
                      }
                    />
                  </PopoverContent>
                </FloatingFocusManager>
              )}
            </Box>

            {errors.startDate !== undefined && (
              <Typography variant="caption" color="error">
                <>{errors.startDate}</>
              </Typography>
            )}

            {errors.endDate !== undefined && (
              <Typography variant="caption" color="error">
                <>{errors.endDate}</>
              </Typography>
            )}

            {errors.endDate !== undefined && (
              <Typography variant="caption" color="error">
                {errors.endDate as string}
              </Typography>
            )}

            <Box
              data-testid="generate-report-modal-button"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '0.5rem',
                gap: '0.5rem',
              }}
            >
              <Button
                onClick={toggleModal}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    toggleModal()
                  }
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  submitForm()
                  trackAnalyticEvent('report_created', {
                    report_name: values.reportName,
                  })
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submitForm()
                  }
                }}
                disabled={!isValid || !dirty}
                variant="contained"
              >
                Generate Report
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  )
}

export default GenerateReportModal
