import React, { useContext } from 'react'

import { Context } from '@components/App'
import { addUserContextToAnalytics } from '@utils/analytics'

export const AnalyticsWrapper: React.FC<{
  children?: unknown
}> = ({ children }) => {
  const { state } = useContext(Context)
  const oktaSession = state?.oktaSession
  const user = state?.user
  const customer = state?.customer

  addUserContextToAnalytics(oktaSession, user, customer)

  return <>{children}</>
}
