import { TicketMttxResponse } from '@queries/ticket'
import { toTimeUnits } from '@utils/DateTimeUtils'

export type MttxKey = keyof TicketMttxResponse

const EXCLUDE_FROM_FORMATTING: Partial<MttxKey>[] = [
  'customerAssignmentTime',
  'customerAcknowledgeTime',
  'customerValidationTime',
]

export const formatMttxValue = (key: MttxKey, value: number | string) => {
  if (!EXCLUDE_FROM_FORMATTING.includes(key) && typeof value === 'number') {
    return toTimeUnits(value)
  }

  return value
}
