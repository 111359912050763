import { Box, SxProps, Theme } from '@mui/material'

interface CyberIntelBriefBackgroundProps {
  sx?: SxProps<Theme>
}

const CyberIntelBriefBackground: React.FC<CyberIntelBriefBackgroundProps> = ({
  sx,
}) => (
  <Box
    component="svg"
    viewBox="0 0 1181 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
  >
    <g opacity="0.15" clipPath="url(#clip0_24307_57129)">
      <mask
        id="mask0_24307_57129"
        maskUnits="userSpaceOnUse"
        x="-170"
        y="-53"
        width="883"
        height="282"
      >
        <ellipse
          cx="271.561"
          cy="88.1297"
          rx="441.054"
          ry="139.868"
          transform="rotate(-1.49851 271.561 88.1297)"
          fill="url(#paint0_radial_24307_57129)"
        />
      </mask>
      <g mask="url(#mask0_24307_57129)">
        <path
          d="M1025.14 608.589C968.722 679.311 871.99 707.01 761.095 694.51C650.208 682.012 525.238 629.322 412.488 539.38C299.739 449.438 220.595 339.302 183.766 233.965C146.935 128.621 152.444 28.152 208.86 -42.5704C265.277 -113.293 362.009 -140.991 472.904 -128.492C583.791 -115.993 708.762 -63.3036 821.511 26.6385C934.26 116.581 1013.4 226.717 1050.23 332.054C1087.06 437.398 1081.56 537.867 1025.14 608.589Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="551.604"
          y="-28.0428"
          width="513.038"
          height="351.778"
          transform="rotate(38.58 551.604 -28.0428)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M986.835 -648.762C1057.56 -592.346 1085.26 -495.613 1072.76 -384.718C1060.26 -273.831 1007.57 -148.861 917.626 -36.1114C827.684 76.6379 717.548 155.782 612.211 192.611C506.867 229.442 406.398 223.933 335.676 167.516C264.953 111.1 237.255 14.3677 249.754 -96.5272C262.253 -207.414 314.943 -332.385 404.885 -445.134C494.827 -557.883 604.963 -637.028 710.3 -673.856C815.644 -710.688 916.113 -705.179 986.835 -648.762Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="350.202"
          y="-175.229"
          width="513.038"
          height="351.778"
          transform="rotate(-51.42 350.202 -175.229)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M404.398 888.148C314.497 898.264 226.511 849.45 156.935 762.197C87.3637 674.95 36.2534 549.325 20.1263 406.001C3.99911 262.676 25.9137 128.835 74.3563 28.3085C122.802 -72.2247 197.74 -139.371 287.641 -149.487C377.542 -159.603 465.528 -110.789 535.104 -23.5358C604.675 63.7113 655.785 189.336 671.912 332.66C688.039 475.985 666.125 609.826 617.682 710.353C569.236 810.886 494.299 878.032 404.398 888.148Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="519.726"
          y="103.143"
          width="513.038"
          height="351.778"
          transform="rotate(83.58 519.726 103.143)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1266.39 -28.0207C1276.51 61.8801 1227.69 149.866 1140.44 219.442C1053.19 289.013 927.57 340.124 784.246 356.251C640.921 372.378 507.08 350.463 406.554 302.021C306.02 253.575 238.874 178.637 228.758 88.7361C218.642 -1.16467 267.456 -89.1506 354.709 -158.727C441.956 -228.298 567.581 -279.408 710.905 -295.535C854.23 -311.662 988.072 -289.748 1088.6 -241.305C1189.13 -192.859 1256.28 -117.922 1266.39 -28.0207Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="481.387"
          y="-143.348"
          width="513.038"
          height="351.778"
          transform="rotate(-6.42004 481.387 -143.348)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M845.308 755.488C772.51 809.199 671.904 810.917 568.023 770.142C464.149 729.369 357.074 646.13 271.445 530.071C185.817 414.012 137.874 287.145 129.564 175.865C121.253 64.5781 152.577 -31.0416 225.376 -84.7525C298.174 -138.464 398.779 -140.182 502.661 -99.4067C606.535 -58.6342 713.61 24.6048 799.238 140.664C884.867 256.723 932.809 383.59 941.12 494.87C949.431 606.157 918.106 701.777 845.308 755.488Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="552.682"
          y="17.989"
          width="513.038"
          height="351.778"
          transform="rotate(53.58 552.682 17.989)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1133.74 -468.933C1187.45 -396.135 1189.17 -295.529 1148.39 -191.648C1107.62 -87.7742 1024.38 19.3008 908.32 104.93C792.261 190.558 665.394 238.501 554.114 246.811C442.827 255.122 347.207 223.798 293.496 150.999C239.786 78.2007 238.067 -22.4044 278.842 -126.286C319.615 -230.16 402.854 -337.235 518.913 -422.863C634.972 -508.492 761.839 -556.434 873.119 -564.745C984.406 -573.056 1080.03 -541.731 1133.74 -468.933Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="396.236"
          y="-176.307"
          width="513.038"
          height="351.778"
          transform="rotate(-36.42 396.236 -176.307)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M173.362 864.858C83.9066 851.361 11.5528 781.438 -33.0698 679.151C-77.6892 576.87 -94.5438 442.298 -73.0264 299.683C-51.5089 157.068 4.2996 33.4591 77.1096 -51.1039C149.925 -135.673 239.688 -181.136 329.144 -167.639C418.599 -154.142 490.953 -84.219 535.576 18.0686C580.195 120.349 597.05 254.921 575.532 397.536C554.015 540.151 498.206 663.76 425.396 748.323C352.581 832.892 262.818 878.355 173.362 864.858Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="487.936"
          y="136.452"
          width="513.038"
          height="351.778"
          transform="rotate(98.58 487.936 136.452)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1243.11 203.009C1229.61 292.464 1159.69 364.818 1057.4 409.441C955.122 454.06 820.55 470.915 677.935 449.397C535.32 427.88 411.711 372.071 327.148 299.261C242.579 226.446 197.116 136.683 210.613 47.2276C224.11 -42.2281 294.033 -114.582 396.321 -159.204C498.601 -203.824 633.173 -220.679 775.788 -199.161C918.403 -177.644 1042.01 -121.835 1126.57 -49.0251C1211.14 23.79 1256.61 113.553 1243.11 203.009Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="514.702"
          y="-111.563"
          width="513.038"
          height="351.778"
          transform="rotate(8.57996 514.702 -111.563)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M633.584 850.841C549.365 883.881 451.743 859.502 361.955 793.23C272.173 726.962 190.29 618.846 137.617 484.579C84.9445 350.313 71.4716 215.36 92.2455 105.721C113.021 -3.92529 168.026 -88.1794 252.246 -121.219C336.465 -154.258 434.087 -129.879 523.875 -63.6069C613.657 2.66094 695.54 110.777 748.212 245.043C800.885 379.31 814.358 514.263 793.584 623.902C772.809 733.548 717.803 817.802 633.584 850.841Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="541.808"
          y="62.7322"
          width="513.038"
          height="351.778"
          transform="rotate(68.58 541.808 62.7322)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1229.09 -257.207C1262.13 -172.988 1237.75 -75.3657 1171.48 14.4224C1105.21 104.204 997.093 186.087 862.826 238.76C728.56 291.432 593.607 304.905 483.968 284.131C374.322 263.356 290.068 208.351 257.028 124.131C223.989 39.912 248.368 -57.7099 314.64 -147.498C380.908 -237.28 489.024 -319.163 623.291 -371.835C757.557 -424.508 892.51 -437.981 1002.15 -417.207C1111.8 -396.432 1196.05 -341.426 1229.09 -257.207Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="440.981"
          y="-165.432"
          width="513.038"
          height="351.778"
          transform="rotate(-21.42 440.981 -165.432)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-43.7747 782.575C-126.689 746.385 -178.48 660.118 -195.108 549.766C-211.735 439.422 -193.185 305.073 -135.49 172.887C-77.794 40.7009 8.10531 -64.252 100.321 -127.089C192.543 -189.93 291.014 -210.612 373.929 -174.422C456.843 -138.232 508.634 -51.9651 525.262 58.3863C541.889 168.73 523.339 303.079 465.644 435.265C407.948 567.452 322.049 672.404 229.833 735.241C137.611 798.083 39.1396 818.765 -43.7747 782.575Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="448.606"
          y="160.404"
          width="513.038"
          height="351.778"
          transform="rotate(113.58 448.606 160.404)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1160.82 420.145C1124.63 503.059 1038.36 554.85 928.012 571.478C817.669 588.105 683.319 569.555 551.133 511.86C418.947 454.164 313.994 368.265 251.157 276.049C188.316 183.827 167.634 85.3559 203.824 2.44157C240.014 -80.4727 326.281 -132.264 436.632 -148.892C546.976 -165.519 681.325 -146.969 813.511 -89.2734C945.698 -31.5777 1050.65 54.3216 1113.49 146.537C1176.33 238.759 1197.01 337.23 1160.82 420.145Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="538.649"
          y="-72.2383"
          width="513.038"
          height="351.778"
          transform="rotate(23.58 538.649 -72.2383)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M997.935 636.265C938.488 704.459 840.639 727.912 730.395 710.587C620.158 693.263 497.605 635.173 388.886 540.398C280.168 445.624 205.903 332.14 173.704 225.297C141.503 118.447 151.389 18.3135 210.837 -49.8808C270.284 -118.075 368.133 -141.528 478.377 -124.203C588.613 -106.879 711.167 -48.7888 819.885 45.9857C928.604 140.76 1002.87 254.244 1035.07 361.087C1067.27 467.937 1057.38 568.07 997.935 636.265Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="552.621"
          y="-20.4151"
          width="513.038"
          height="351.778"
          transform="rotate(41.08 552.621 -20.4151)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1014.51 -621.556C1082.71 -562.109 1106.16 -464.26 1088.83 -354.016C1071.51 -243.779 1013.42 -121.226 918.646 -12.5075C823.872 96.2113 710.388 170.476 603.545 202.675C496.695 234.876 396.562 224.99 328.367 165.542C260.173 106.095 236.72 8.24619 254.045 -101.998C271.369 -212.235 329.459 -334.788 424.234 -443.507C519.008 -552.225 632.492 -626.49 739.335 -658.689C846.185 -690.89 946.318 -681.004 1014.51 -621.556Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="357.83"
          y="-176.242"
          width="513.038"
          height="351.778"
          transform="rotate(-48.92 357.83 -176.242)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M365.587 888.476C275.331 894.661 189.558 842.055 123.854 751.85C58.1546 661.652 12.5727 533.917 2.71261 390.026C-7.14748 246.134 20.5843 113.376 73.3657 15.0587C126.151 -83.2656 203.946 -147.08 294.202 -153.264C384.459 -159.449 470.232 -106.844 535.936 -16.6387C601.635 73.5599 647.217 201.294 657.077 345.186C666.937 489.077 639.205 621.836 586.424 720.153C533.639 818.477 455.844 882.291 365.587 888.476Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="515.047"
          y="109.245"
          width="513.038"
          height="351.778"
          transform="rotate(86.08 515.047 109.245)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1266.73 10.7848C1272.91 101.041 1220.31 186.814 1130.1 252.518C1039.9 318.217 912.17 363.799 768.279 373.659C624.387 383.52 491.629 355.788 393.312 303.006C294.987 250.221 231.173 172.426 224.989 82.1696C218.804 -8.0869 271.409 -93.8598 361.614 -159.564C451.813 -225.263 579.547 -270.845 723.439 -280.705C867.33 -290.565 1000.09 -262.833 1098.41 -210.052C1196.73 -157.267 1260.54 -79.4716 1266.73 10.7848Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="487.5"
          y="-138.677"
          width="513.038"
          height="351.778"
          transform="rotate(-3.92004 487.5 -138.677)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M811.866 775.184C736.794 825.668 636.21 822.997 534.206 777.729C432.21 732.464 328.867 644.634 248.382 524.95C167.898 405.267 125.535 276.429 122.086 164.893C118.637 53.3489 154.103 -40.8133 229.175 -91.2978C304.247 -141.782 404.831 -139.111 506.835 -93.843C608.832 -48.5784 712.174 39.2519 792.659 158.936C873.144 278.619 915.506 407.457 918.955 518.993C922.404 630.537 886.938 724.699 811.866 775.184Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="551.688"
          y="25.6206"
          width="513.038"
          height="351.778"
          transform="rotate(56.08 551.688 25.6206)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1153.43 -435.491C1203.91 -360.419 1201.24 -259.835 1155.98 -157.831C1110.71 -55.8345 1022.88 47.5078 903.196 127.993C783.513 208.477 654.675 250.84 543.139 254.289C431.595 257.738 337.433 222.272 286.948 147.2C236.464 72.1281 239.135 -28.4563 284.403 -130.46C329.668 -232.457 417.498 -335.799 537.182 -416.284C656.865 -496.769 785.703 -539.131 897.239 -542.58C1008.78 -546.029 1102.95 -510.563 1153.43 -435.491Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="403.868"
          y="-175.313"
          width="513.038"
          height="351.778"
          transform="rotate(-33.92 403.868 -175.313)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M135.792 855.137C47.0099 837.751 -22.225 764.739 -62.3434 660.602C-102.459 556.473 -113.428 421.293 -85.7099 279.753C-57.9921 138.212 3.15507 17.1551 79.5844 -64.1515C156.019 -145.464 247.68 -186.968 336.462 -169.582C425.243 -152.196 494.478 -79.1835 534.597 24.9531C574.712 129.083 585.681 264.262 557.963 405.802C530.245 547.343 469.098 668.4 392.669 749.707C316.234 831.019 224.574 872.523 135.792 855.137Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="481.838"
          y="141.146"
          width="513.038"
          height="351.778"
          transform="rotate(101.08 481.838 141.146)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1233.39 240.581C1216 329.363 1142.99 398.598 1038.85 438.716C934.724 478.832 799.545 489.801 658.005 462.083C516.464 434.365 395.407 373.218 314.1 296.789C232.788 220.354 191.284 128.693 208.67 39.9114C226.056 -48.8704 299.068 -118.105 403.205 -158.224C507.335 -198.339 642.514 -209.308 784.054 -181.59C925.595 -153.872 1046.65 -92.7252 1127.96 -16.2959C1209.27 60.1388 1250.78 151.8 1233.39 240.581Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="519.395"
          y="-105.464"
          width="513.038"
          height="351.778"
          transform="rotate(11.08 519.395 -105.464)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M596.188 861.212C510.607 890.546 414.142 861.932 327.33 791.807C240.524 721.686 163.435 610.101 116.669 473.665C69.9032 337.228 62.3297 201.816 87.8661 93.1878C113.404 -15.4479 172.033 -97.2225 257.613 -126.557C343.193 -155.891 439.659 -127.277 526.471 -57.1515C613.276 12.9695 690.365 124.554 737.131 260.991C783.897 397.427 791.471 532.839 765.934 641.467C740.396 750.103 681.768 831.878 596.188 861.212Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="538.875"
          y="69.8485"
          width="513.038"
          height="351.778"
          transform="rotate(71.08 538.875 69.8485)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1239.45 -219.811C1268.79 -134.23 1240.17 -37.7646 1170.05 49.0472C1099.93 135.853 988.343 212.942 851.907 259.708C715.47 306.474 580.058 314.047 471.43 288.511C362.794 262.973 281.02 204.344 251.686 118.764C222.351 33.1837 250.965 -63.2819 321.091 -150.094C391.212 -236.9 502.796 -313.988 639.233 -360.754C775.669 -407.52 911.081 -415.094 1019.71 -389.557C1128.35 -364.019 1210.12 -305.391 1239.45 -219.811Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="448.094"
          y="-162.498"
          width="513.038"
          height="351.778"
          transform="rotate(-18.92 448.094 -162.498)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-77.5486 763.458C-158.805 723.686 -206.784 635.241 -218.583 524.27C-230.381 413.306 -205.989 279.893 -142.582 150.35C-79.1754 20.8058 11.2201 -80.3003 106.089 -139.055C200.964 -197.814 300.244 -214.181 381.501 -174.409C462.758 -134.637 510.736 -46.1924 522.535 64.7793C534.333 175.743 509.941 309.156 446.534 438.699C383.128 568.243 292.732 669.349 197.863 728.104C102.988 786.863 3.70824 803.23 -77.5486 763.458Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="441.502"
          y="163.356"
          width="513.038"
          height="351.778"
          transform="rotate(116.08 441.502 163.356)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1141.71 453.923C1101.94 535.179 1013.49 583.158 902.521 594.957C791.558 606.755 658.145 582.363 528.601 518.956C399.058 455.549 297.952 365.154 239.197 270.285C180.438 175.41 164.071 76.13 203.843 -5.12679C243.615 -86.3836 332.06 -134.362 443.031 -146.161C553.995 -157.959 687.407 -133.567 816.951 -70.1603C946.495 -6.75364 1047.6 83.6419 1106.36 178.511C1165.11 273.386 1181.48 372.666 1141.71 453.923Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="541.607"
          y="-65.1303"
          width="513.038"
          height="351.778"
          transform="rotate(26.08 541.607 -65.1303)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M969.552 662.732C907.186 728.268 808.408 747.431 699.025 725.314C589.649 703.198 469.746 639.817 365.265 540.391C260.783 440.964 191.539 324.349 164.032 216.203C136.522 108.05 150.766 8.44353 213.132 -57.0928C275.498 -122.629 374.276 -141.791 483.66 -119.674C593.036 -97.5588 712.938 -34.1778 817.419 65.2488C921.901 164.675 991.145 281.29 1018.65 389.436C1046.16 497.589 1031.92 597.196 969.552 662.732Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="553.306"
          y="-12.7496"
          width="513.038"
          height="351.778"
          transform="rotate(43.58 553.306 -12.7496)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1040.98 -593.176C1106.52 -530.81 1125.68 -432.032 1103.56 -322.649C1081.45 -213.273 1018.06 -93.3701 918.638 11.1112C819.211 115.592 702.596 184.837 594.45 212.344C486.297 239.854 386.691 225.61 321.154 163.244C255.618 100.878 236.456 2.09992 258.573 -107.284C280.688 -216.66 344.069 -336.562 443.496 -441.043C542.922 -545.525 659.537 -614.769 767.683 -642.277C875.836 -669.786 975.443 -655.542 1040.98 -593.176Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="365.497"
          y="-176.931"
          width="513.038"
          height="351.778"
          transform="rotate(-46.42 365.497 -176.931)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M326.803 887.121C236.362 889.363 152.966 833.066 91.2589 740.081C29.5565 647.103 -10.4103 517.502 -13.9846 373.317C-17.5588 229.132 15.9374 97.7102 72.9571 1.78861C129.981 -94.1396 210.486 -154.5 300.926 -156.742C391.366 -158.983 474.763 -102.687 536.47 -9.70159C598.172 83.277 638.139 212.878 641.713 357.063C645.287 501.247 611.791 632.67 554.771 728.591C497.748 824.519 417.243 884.879 326.803 887.121Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="510.11"
          y="115.151"
          width="513.038"
          height="351.778"
          transform="rotate(88.58 510.11 115.151)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1265.37 49.5675C1267.61 140.008 1211.32 223.405 1118.33 285.111C1025.35 346.814 895.751 386.78 751.566 390.355C607.381 393.929 475.959 360.433 380.038 303.413C284.109 246.389 223.749 165.885 221.507 75.4443C219.266 -14.9961 275.562 -98.3927 368.547 -160.099C461.526 -221.802 591.127 -261.769 735.312 -265.343C879.496 -268.917 1010.92 -235.421 1106.84 -178.401C1202.77 -121.378 1263.13 -40.8728 1265.37 49.5675Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="493.402"
          y="-133.74"
          width="513.038"
          height="351.778"
          transform="rotate(-1.42004 493.402 -133.74)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M777.6 793.397C700.397 840.558 600.025 833.502 500.093 783.828C400.168 734.158 300.755 641.903 225.568 518.823C150.38 395.742 113.677 265.179 115.097 153.599C116.517 42.0105 156.056 -50.5152 233.259 -97.677C310.461 -144.839 410.834 -137.782 510.766 -88.1084C610.691 -38.4378 710.104 53.8166 785.291 176.897C860.479 299.978 897.182 430.541 895.762 542.121C894.342 653.709 854.803 746.235 777.6 793.397Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="550.365"
          y="33.1962"
          width="513.038"
          height="351.778"
          transform="rotate(58.58 550.365 33.1962)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1171.65 -401.224C1218.81 -324.022 1211.75 -223.649 1162.08 -123.717C1112.41 -23.7922 1020.15 75.6207 897.074 150.808C773.993 225.996 643.43 262.699 531.85 261.279C420.261 259.859 327.736 220.32 280.574 143.117C233.412 65.9145 240.469 -34.4576 290.143 -134.39C339.813 -234.315 432.068 -333.728 555.148 -408.915C678.228 -484.103 808.792 -520.806 920.372 -519.386C1031.96 -517.966 1124.49 -478.427 1171.65 -401.224Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="411.45"
          y="-173.987"
          width="513.038"
          height="351.778"
          transform="rotate(-31.42 411.45 -173.987)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M98.6797 843.788C10.7408 822.546 -55.2435 746.583 -90.7813 640.795C-126.317 535.015 -131.378 399.486 -97.5131 259.289C-63.6478 119.093 2.7216 0.817902 82.6247 -77.0775C162.533 -154.978 255.917 -192.445 343.856 -171.203C431.795 -149.961 497.779 -73.9977 533.317 31.7897C568.853 137.57 573.914 273.099 540.049 413.296C506.184 553.493 439.814 671.767 359.911 749.663C280.003 827.563 186.619 865.03 98.6797 843.788Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="475.541"
          y="145.566"
          width="513.038"
          height="351.778"
          transform="rotate(103.58 475.541 145.566)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1222.04 277.697C1200.8 365.636 1124.83 431.62 1019.05 467.158C913.267 502.694 777.738 507.755 637.541 473.89C497.344 440.025 379.07 373.655 301.174 293.752C223.274 213.844 185.807 120.46 207.049 32.5207C228.291 -55.4183 304.254 -121.403 410.042 -156.94C515.822 -192.476 651.351 -197.537 791.548 -163.672C931.745 -129.807 1050.02 -63.4374 1127.91 16.4657C1205.82 96.3744 1243.28 189.758 1222.04 277.697Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="523.82"
          y="-99.1628"
          width="513.038"
          height="351.778"
          transform="rotate(13.58 523.82 -99.1628)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M558.373 869.932C471.594 895.505 376.468 862.711 292.798 788.866C209.134 715.025 136.985 600.184 96.2152 461.837C55.445 323.491 53.7853 187.877 84.0357 80.4664C114.288 -26.9519 176.428 -106.091 263.206 -131.665C349.984 -157.238 445.11 -124.444 528.78 -50.5981C612.445 23.2425 684.593 138.083 725.363 276.43C766.134 414.777 767.793 550.39 737.543 657.801C707.29 765.219 645.151 844.359 558.373 869.932Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="535.634"
          y="76.8269"
          width="513.038"
          height="351.778"
          transform="rotate(73.58 535.634 76.8269)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1248.18 -181.998C1273.76 -95.2192 1240.96 -0.0934844 1167.12 83.5769C1093.27 167.241 978.434 239.39 840.087 280.16C701.741 320.93 566.127 322.59 458.716 292.339C351.298 262.087 272.159 199.947 246.585 113.169C221.012 26.3906 253.806 -68.7351 327.652 -152.405C401.492 -236.07 516.333 -308.218 654.68 -348.988C793.027 -389.759 928.64 -391.418 1036.05 -361.168C1143.47 -330.915 1222.61 -268.776 1248.18 -181.998Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="455.075"
          y="-159.261"
          width="513.038"
          height="351.778"
          transform="rotate(-16.42 455.075 -159.261)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-110.458 742.891C-189.902 699.613 -233.977 609.16 -240.925 497.779C-247.871 386.406 -217.683 254.185 -148.686 127.53C-79.689 0.875546 15.0307 -96.1913 112.372 -150.752C209.72 -205.316 309.619 -217.337 389.064 -174.059C468.508 -130.78 512.584 -40.3272 519.531 71.0534C526.477 182.426 496.289 314.648 427.292 441.302C358.295 567.957 263.575 665.024 166.234 719.585C68.8861 774.149 -31.013 786.17 -110.458 742.891Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="434.275"
          y="165.999"
          width="513.038"
          height="351.778"
          transform="rotate(118.58 434.275 165.999)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1121.14 486.835C1077.86 566.279 987.408 610.354 876.027 617.301C764.654 624.248 632.433 594.06 505.778 525.063C379.124 456.066 282.057 361.346 227.496 264.005C172.932 166.657 160.911 66.7577 204.189 -12.6869C247.468 -92.1315 337.921 -136.207 449.301 -143.154C560.674 -150.1 692.896 -119.912 819.55 -50.9151C946.205 18.0818 1043.27 112.801 1097.83 210.143C1152.4 307.491 1164.42 407.39 1121.14 486.835Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="544.249"
          y="-57.8985"
          width="513.038"
          height="351.778"
          transform="rotate(28.58 544.249 -57.8985)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M940.038 687.931C874.873 750.684 775.352 765.52 667.038 738.653C558.731 711.787 441.707 643.236 341.662 539.347C241.617 435.458 177.525 315.933 154.761 206.691C131.995 97.4403 150.571 -1.45002 215.736 -64.2036C280.901 -126.957 380.421 -141.793 488.736 -114.925C597.043 -88.0599 714.067 -19.5092 814.112 84.3802C914.157 188.27 978.248 307.794 1001.01 417.037C1023.78 526.287 1005.2 625.177 940.038 687.931Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="553.652"
          y="-5.06201"
          width="513.038"
          height="351.778"
          transform="rotate(46.08 553.652 -5.06201)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1066.18 -563.662C1128.93 -498.497 1143.77 -398.977 1116.9 -290.662C1090.04 -182.355 1021.49 -65.3308 917.597 34.7141C813.708 134.759 694.183 198.851 584.941 221.615C475.69 244.381 376.8 225.805 314.046 160.64C251.293 95.4752 236.457 -4.04492 263.325 -112.36C290.19 -220.667 358.741 -337.691 462.63 -437.736C566.52 -537.781 686.044 -601.872 795.287 -624.636C904.537 -647.402 1003.43 -628.827 1066.18 -563.662Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="373.185"
          y="-177.276"
          width="513.038"
          height="351.778"
          transform="rotate(-43.92 373.185 -177.276)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M288.116 884.072C197.664 882.366 116.802 822.485 59.2103 726.897C1.62234 631.316 -32.6533 500.095 -29.9349 355.891C-27.2165 211.688 11.9804 81.8521 73.1298 -11.4911C134.284 -104.841 217.345 -161.632 307.797 -159.926C398.249 -158.221 479.11 -98.3403 536.702 -2.7522C594.29 92.8293 628.566 224.05 625.848 368.254C623.129 512.457 583.932 642.293 522.783 735.636C461.629 828.986 378.568 885.777 288.116 884.072Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="504.921"
          y="120.833"
          width="513.038"
          height="351.778"
          transform="rotate(91.08 504.921 120.833)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1262.32 88.2589C1260.62 178.711 1200.74 259.573 1105.15 317.165C1009.57 374.753 878.346 409.028 734.142 406.31C589.939 403.592 460.103 364.395 366.76 303.245C273.41 242.091 216.619 159.03 218.324 68.5783C220.03 -21.8737 279.911 -102.735 375.499 -160.327C471.08 -217.915 602.301 -252.191 746.505 -249.473C890.708 -246.754 1020.54 -207.557 1113.89 -146.408C1207.24 -85.2541 1264.03 -2.19313 1262.32 88.2589Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="499.085"
          y="-128.547"
          width="513.038"
          height="351.778"
          transform="rotate(1.07997 499.085 -128.547)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M742.57 810.102C663.384 853.851 563.415 842.423 465.745 788.438C368.081 734.456 272.787 637.953 203.04 511.71C133.292 385.467 102.32 253.427 108.605 142.015C114.891 30.595 158.429 -60.1179 237.615 -103.867C316.801 -147.617 416.77 -136.189 514.44 -82.2032C612.104 -28.2212 707.398 68.2817 777.145 194.525C846.893 320.768 877.865 452.807 871.58 564.22C865.294 675.64 821.757 766.352 742.57 810.102Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="548.711"
          y="40.7159"
          width="513.038"
          height="351.778"
          transform="rotate(61.08 548.711 40.7159)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1188.35 -366.196C1232.1 -287.01 1220.67 -187.041 1166.69 -89.3708C1112.71 8.29257 1016.2 103.587 889.96 173.334C763.717 243.082 631.677 274.054 520.265 267.769C408.845 261.483 318.132 217.945 274.383 138.759C230.633 59.5726 242.061 -40.3961 296.047 -138.066C350.029 -235.73 446.532 -331.024 572.775 -400.771C699.018 -470.519 831.057 -501.491 942.47 -495.206C1053.89 -488.92 1144.6 -445.382 1188.35 -366.196Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="418.966"
          y="-172.337"
          width="513.038"
          height="351.778"
          transform="rotate(-28.92 418.966 -172.337)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M62.0974 830.837C-24.8312 805.779 -87.4392 727.01 -118.329 619.773C-149.216 512.543 -148.361 376.922 -108.413 238.336C-68.4648 99.7499 3.00051 -15.5172 86.2253 -89.8531C169.456 -164.194 264.385 -197.552 351.314 -172.494C438.243 -147.437 500.851 -68.6675 531.74 38.5694C562.628 145.799 561.773 281.42 521.825 420.006C481.876 558.592 410.411 673.859 327.186 748.195C243.955 822.536 149.026 855.894 62.0974 830.837Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="469.056"
          y="149.719"
          width="513.038"
          height="351.778"
          transform="rotate(106.08 469.056 149.719)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1209.08 314.274C1184.02 401.202 1105.25 463.81 998.018 494.7C890.788 525.587 755.167 524.733 616.581 484.784C477.995 444.836 362.728 373.371 288.392 290.146C214.051 206.915 180.693 111.986 205.751 25.0571C230.808 -61.8716 309.578 -124.48 416.815 -155.369C524.044 -186.257 659.665 -185.402 798.251 -145.453C936.837 -105.505 1052.1 -34.0398 1126.44 49.185C1200.78 132.416 1234.14 227.345 1209.08 314.274Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="527.964"
          y="-92.6829"
          width="513.038"
          height="351.778"
          transform="rotate(16.08 527.964 -92.6829)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M520.213 877.006C432.402 898.769 338.797 861.857 258.428 784.432C178.064 707.012 110.993 589.134 76.2966 449.14C41.5999 309.147 45.8571 173.59 80.7639 67.601C115.673 -38.3955 181.205 -114.749 269.017 -136.513C356.828 -158.276 450.433 -121.364 530.802 -43.9392C611.166 33.4805 678.237 151.359 712.933 291.352C747.63 431.346 743.373 566.903 708.466 672.892C673.557 778.888 608.025 855.242 520.213 877.006Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="532.091"
          y="83.6596"
          width="513.038"
          height="351.778"
          transform="rotate(76.08 532.091 83.6596)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1255.25 -143.839C1277.01 -56.028 1240.1 37.5767 1162.68 117.946C1085.26 198.31 967.379 265.381 827.385 300.077C687.392 334.774 551.835 330.517 445.846 295.61C339.85 260.701 263.496 195.169 241.732 107.357C219.969 19.5459 256.881 -74.0587 334.306 -154.428C411.726 -234.792 529.604 -301.863 669.598 -336.559C809.591 -371.256 945.148 -366.999 1051.14 -332.092C1157.13 -297.183 1233.49 -231.651 1255.25 -143.839Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="461.906"
          y="-155.715"
          width="513.038"
          height="351.778"
          transform="rotate(-13.92 461.906 -155.715)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-142.438 720.909C-219.919 674.207 -260.007 581.917 -262.089 470.34C-264.171 358.77 -228.244 227.991 -153.788 104.466C-79.3322 -19.0581 19.5313 -111.901 119.16 -162.164C218.795 -212.43 319.124 -220.082 396.605 -173.379C474.086 -126.676 514.174 -34.3871 516.256 77.1906C518.338 188.761 482.411 319.539 407.955 443.064C333.499 566.588 234.636 659.431 135.007 709.694C35.3718 759.96 -64.9566 767.612 -142.438 720.909Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="426.94"
          y="168.33"
          width="513.038"
          height="351.778"
          transform="rotate(121.08 426.94 168.33)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1099.16 518.813C1052.45 596.294 960.164 636.382 848.587 638.464C737.017 640.546 606.238 604.619 482.713 530.163C359.189 455.707 266.346 356.844 216.083 257.215C165.817 157.58 158.165 57.2512 204.868 -20.23C251.571 -97.7112 343.86 -137.799 455.438 -139.881C567.008 -141.963 697.786 -106.036 821.311 -31.5802C944.835 42.8757 1037.68 141.739 1087.94 241.368C1138.21 341.003 1145.86 441.332 1099.16 518.813Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="546.576"
          y="-50.5665"
          width="513.038"
          height="351.778"
          transform="rotate(31.08 546.576 -50.5665)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M909.454 711.818C841.614 771.669 741.542 782.15 634.502 750.583C527.47 719.019 413.547 645.429 318.129 537.275C222.711 429.121 163.894 306.914 145.917 196.783C127.938 86.6432 150.809 -11.3427 218.65 -71.1941C286.49 -131.045 386.562 -141.526 493.602 -109.959C600.634 -78.3953 714.557 -4.80529 809.975 103.349C905.393 211.503 964.21 333.71 982.187 443.841C1000.17 553.981 977.295 651.967 909.454 711.818Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="553.664"
          y="2.62818"
          width="513.038"
          height="351.778"
          transform="rotate(48.58 553.664 2.62818)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1090.07 -533.077C1149.92 -465.237 1160.4 -365.165 1128.84 -258.125C1097.27 -151.093 1023.68 -37.1704 915.527 58.2477C807.373 153.666 685.166 212.483 575.035 230.46C464.895 248.439 366.909 225.568 307.058 157.727C247.207 89.8872 236.726 -10.1853 268.292 -117.225C299.857 -224.257 373.447 -338.18 481.601 -433.598C589.755 -529.016 711.962 -587.833 822.093 -605.81C932.233 -623.789 1030.22 -600.918 1090.07 -533.077Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="380.88"
          y="-177.285"
          width="513.038"
          height="351.778"
          transform="rotate(-41.42 380.88 -177.285)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M249.597 879.342C159.305 873.693 81.1328 810.342 27.7651 712.333C-25.5989 614.33 -54.1182 481.739 -45.1123 337.792C-36.1064 193.844 8.71653 65.8414 73.8794 -24.7456C139.047 -115.339 224.506 -168.453 314.797 -162.804C405.089 -157.155 483.262 -93.8035 536.629 4.20573C589.993 102.208 618.513 234.799 609.507 378.747C600.501 522.694 555.678 650.697 490.515 741.284C425.348 831.877 339.889 884.991 249.597 879.342Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="499.488"
          y="126.287"
          width="513.038"
          height="351.778"
          transform="rotate(93.58 499.488 126.287)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1257.59 126.78C1251.94 217.071 1188.59 295.244 1090.58 348.612C992.577 401.976 859.986 430.495 716.039 421.489C572.091 412.483 444.088 367.66 353.501 302.498C262.908 237.33 209.794 151.871 215.443 61.5795C221.092 -28.7121 284.444 -106.885 382.453 -160.252C480.455 -213.617 613.047 -242.136 756.994 -233.13C900.941 -224.124 1028.94 -179.301 1119.53 -114.138C1210.12 -48.9709 1263.24 36.4883 1257.59 126.78Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="504.535"
          y="-123.109"
          width="513.038"
          height="351.778"
          transform="rotate(3.57996 504.535 -123.109)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M706.847 825.264C625.827 865.518 526.452 849.74 431.23 791.546C336.014 733.355 245.02 632.787 180.845 503.622C116.671 374.457 91.4875 241.192 102.627 130.16C113.767 19.1204 161.219 -69.6071 242.239 -109.861C323.258 -150.114 422.633 -134.337 517.856 -76.1423C613.071 -17.9517 704.065 82.6161 768.24 211.781C832.414 340.946 857.598 474.211 846.459 585.243C835.319 696.283 787.866 785.011 706.847 825.264Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="546.732"
          y="48.1562"
          width="513.038"
          height="351.778"
          transform="rotate(63.58 546.732 48.1562)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1203.51 -330.473C1243.76 -249.453 1227.99 -150.078 1169.79 -54.8556C1111.6 40.3601 1011.03 131.354 881.868 195.529C752.703 259.703 619.438 284.887 508.406 273.747C397.367 262.607 308.639 215.155 268.385 134.135C228.132 53.1159 243.909 -46.2592 302.104 -141.482C360.294 -236.697 460.862 -327.691 590.027 -391.866C719.192 -456.04 852.457 -481.224 963.489 -470.085C1074.53 -458.945 1163.26 -411.492 1203.51 -330.473Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="426.4"
          y="-170.359"
          width="513.038"
          height="351.778"
          transform="rotate(-26.42 426.4 -170.359)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M26.1158 816.292C-59.6371 787.466 -118.75 706.041 -144.932 597.559C-171.113 489.084 -164.343 353.629 -118.388 216.918C-72.4326 80.2058 3.99257 -31.8343 90.3807 -102.469C176.775 -173.109 273.069 -202.295 358.822 -173.469C444.575 -144.643 503.687 -63.2181 529.87 45.2642C556.051 153.739 549.281 289.194 503.326 425.905C457.37 562.617 380.945 674.657 294.557 745.292C208.163 815.932 111.869 845.117 26.1158 816.292Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="462.397"
          y="153.572"
          width="513.038"
          height="351.778"
          transform="rotate(108.58 462.397 153.572)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1194.54 350.257C1165.71 436.01 1084.29 495.123 975.807 521.305C867.332 547.486 731.877 540.716 595.166 494.761C458.454 448.806 346.414 372.38 275.779 285.992C205.139 199.598 175.953 103.304 204.779 17.5512C233.605 -68.2018 315.03 -127.314 423.512 -153.497C531.987 -179.678 667.442 -172.908 804.153 -126.953C940.865 -80.9973 1052.91 -4.57205 1123.54 81.8161C1194.18 168.21 1223.37 264.504 1194.54 350.257Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="531.822"
          y="-86.0244"
          width="513.038"
          height="351.778"
          transform="rotate(18.58 531.822 -86.0244)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M481.78 882.401C393.103 900.314 301.198 859.354 224.282 778.497C147.371 697.645 85.5064 576.953 56.9491 435.58C28.3917 294.206 38.5578 158.964 78.0547 54.5983C117.554 -49.7747 186.355 -123.197 275.032 -141.11C363.709 -159.023 455.614 -118.063 532.53 -37.2055C609.441 43.646 671.305 164.338 699.863 305.712C728.42 447.085 718.254 582.327 678.757 686.693C639.258 791.066 570.457 864.488 481.78 882.401Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="528.252"
          y="90.3311"
          width="513.038"
          height="351.778"
          transform="rotate(78.58 528.252 90.3311)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1260.65 -105.403C1278.56 -16.7261 1237.6 75.1794 1156.74 152.095C1075.89 229.006 955.199 290.871 813.826 319.428C672.452 347.985 537.21 337.819 432.844 298.322C328.471 258.823 255.049 190.022 237.136 101.345C219.224 12.6682 260.184 -79.2373 341.041 -156.153C421.892 -233.064 542.584 -294.928 683.958 -323.486C825.331 -352.043 960.573 -341.877 1064.94 -302.38C1169.31 -262.881 1242.73 -194.08 1260.65 -105.403Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="468.575"
          y="-151.873"
          width="513.038"
          height="351.778"
          transform="rotate(-11.42 468.575 -151.873)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-173.43 697.551C-248.801 647.513 -284.825 553.563 -282.038 442C-279.251 330.446 -237.654 201.359 -157.881 81.1996C-78.1077 -38.9597 24.7114 -127.402 126.438 -173.271C228.171 -219.143 328.738 -222.412 404.108 -172.374C479.478 -122.336 515.502 -28.3857 512.715 83.1766C509.929 194.731 468.331 323.818 388.558 443.977C308.785 564.137 205.966 652.579 104.24 698.448C2.5068 744.32 -98.0599 747.589 -173.43 697.551Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="419.509"
          y="170.332"
          width="513.038"
          height="351.778"
          transform="rotate(123.58 419.509 170.332)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1075.79 549.807C1025.76 625.178 931.807 661.201 820.245 658.415C708.69 655.628 579.603 614.031 459.444 534.258C339.284 454.485 250.842 351.666 204.973 249.939C159.101 148.206 155.832 47.6394 205.87 -27.7309C255.908 -103.101 349.858 -139.125 461.421 -136.338C572.975 -133.552 702.062 -91.9545 822.222 -12.1814C942.381 67.5916 1030.82 170.411 1076.69 272.137C1122.56 373.87 1125.83 474.437 1075.79 549.807Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="548.577"
          y="-43.1302"
          width="513.038"
          height="351.778"
          transform="rotate(33.58 548.577 -43.1302)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M877.858 734.355C807.472 791.191 707.038 797.296 601.477 761.091C495.923 724.888 385.319 646.399 294.709 534.185C204.1 421.972 150.669 297.316 137.513 186.505C124.355 75.6862 151.479 -21.2088 221.865 -78.0441C292.252 -134.879 392.686 -140.985 498.247 -104.779C603.801 -68.5766 714.405 9.91268 805.014 122.126C895.624 234.34 949.055 358.995 962.211 469.806C975.369 580.625 948.245 677.52 877.858 734.355Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="553.341"
          y="10.321"
          width="513.038"
          height="351.778"
          transform="rotate(51.08 553.341 10.321)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1112.6 -501.479C1169.44 -431.092 1175.54 -330.658 1139.34 -225.097C1103.14 -119.543 1024.65 -8.93926 912.433 81.6704C800.22 172.28 675.564 225.711 564.753 238.867C453.934 252.025 357.039 224.901 300.204 154.514C243.369 84.1281 237.263 -16.3063 273.469 -121.867C309.671 -227.421 388.161 -338.025 500.374 -428.635C612.588 -519.244 737.243 -572.675 848.054 -585.831C958.873 -598.989 1055.77 -571.865 1112.6 -501.479Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="388.569"
          y="-176.962"
          width="513.038"
          height="351.778"
          transform="rotate(-38.92 388.569 -176.962)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M211.321 872.929C121.362 863.347 46.0271 796.646 -3.01472 696.402C-52.0531 596.165 -74.7617 462.456 -59.4855 319.039C-44.2093 175.621 6.15443 49.6954 75.2066 -37.9631C144.264 -125.627 231.958 -174.963 321.917 -165.381C411.877 -155.799 487.212 -89.0982 536.253 11.1456C585.292 111.382 608 245.092 592.724 388.509C577.448 531.927 527.084 657.853 458.032 745.511C388.975 833.175 301.281 882.511 211.321 872.929Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="493.822"
          y="131.491"
          width="513.038"
          height="351.778"
          transform="rotate(96.08 493.822 131.491)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1251.18 165.053C1241.6 255.012 1174.9 330.347 1074.65 379.389C974.416 428.427 840.707 451.136 697.29 435.859C553.872 420.583 427.946 370.22 340.288 301.167C252.623 232.11 203.288 144.416 212.87 54.4566C222.452 -35.5027 289.153 -110.838 389.397 -159.879C489.633 -208.918 623.343 -231.626 766.76 -216.35C910.178 -201.074 1036.1 -150.71 1123.76 -81.6581C1211.43 -12.6011 1260.76 75.0935 1251.18 165.053Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="509.743"
          y="-117.449"
          width="513.038"
          height="351.778"
          transform="rotate(6.07996 509.743 -117.449)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M670.495 838.852C587.797 875.534 489.205 855.437 396.611 793.144C304.024 730.855 217.504 626.414 159.024 494.573C100.545 362.731 81.1986 228.494 97.1703 118.054C113.143 7.60576 164.421 -78.9675 247.119 -115.649C329.817 -152.33 428.41 -132.233 521.003 -69.9402C613.59 -7.65172 700.111 96.7895 758.59 228.631C817.069 360.472 836.416 494.709 820.444 605.15C804.471 715.598 753.193 802.171 670.495 838.852Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="544.429"
          y="55.4975"
          width="513.038"
          height="351.778"
          transform="rotate(66.08 544.429 55.4975)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1217.1 -294.123C1253.78 -211.425 1233.68 -112.833 1171.39 -20.2394C1109.1 72.3476 1004.66 158.868 872.819 217.348C740.977 275.827 606.741 295.173 496.3 279.202C385.852 263.229 299.279 211.951 262.597 129.253C225.916 46.5548 246.013 -52.0375 308.306 -144.631C370.594 -237.218 475.036 -323.739 606.877 -382.218C738.718 -440.697 872.955 -460.044 983.396 -444.072C1093.84 -428.099 1180.42 -376.821 1217.1 -294.123Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="433.744"
          y="-168.057"
          width="513.038"
          height="351.778"
          transform="rotate(-23.92 433.744 -168.057)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-9.19513 800.194C-93.6091 767.655 -149.114 683.729 -170.539 574.208C-191.964 464.695 -179.292 329.664 -127.417 195.087C-75.5422 60.51 5.6974 -48.0899 95.0844 -114.889C184.478 -181.694 281.953 -206.651 366.367 -174.112C450.781 -141.574 506.286 -57.6474 527.711 51.8736C549.136 161.387 536.464 296.418 484.589 430.995C432.714 565.572 351.474 674.172 262.087 740.971C172.694 807.775 75.2188 832.733 -9.19513 800.194Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="455.578"
          y="157.138"
          width="513.038"
          height="351.778"
          transform="rotate(111.08 455.578 157.138)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1178.45 385.573C1145.91 469.987 1061.98 525.492 952.459 546.917C842.946 568.342 707.915 555.67 573.338 503.795C438.761 451.92 330.161 370.681 263.362 281.294C196.557 191.9 171.6 94.4249 204.139 10.0109C236.677 -74.403 320.604 -129.908 430.125 -151.333C539.638 -172.758 674.669 -160.086 809.246 -108.211C943.823 -56.3361 1052.42 24.9035 1119.22 114.29C1186.03 203.684 1210.98 301.159 1178.45 385.573Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="535.387"
          y="-79.1991"
          width="513.038"
          height="351.778"
          transform="rotate(21.08 535.387 -79.1991)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M443.151 886.114C353.777 900.141 263.746 855.212 190.43 771.076C117.119 686.947 60.5781 563.672 38.2145 421.187C15.851 278.702 31.9066 144.032 75.9182 41.4888C119.933 -61.0618 191.87 -131.413 281.244 -145.441C370.618 -159.469 460.65 -114.539 533.965 -30.4036C607.276 53.7257 663.817 177.001 686.181 319.486C708.545 461.971 692.489 596.641 648.477 699.184C604.463 801.735 532.525 872.086 443.151 886.114Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="524.128"
          y="96.8218"
          width="513.038"
          height="351.778"
          transform="rotate(81.08 524.128 96.8218)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1264.36 -66.7761C1278.39 22.5979 1233.46 112.629 1149.33 185.945C1065.2 259.256 941.922 315.797 799.437 338.16C656.952 360.524 522.282 344.468 419.739 300.457C317.188 256.442 246.837 184.505 232.809 95.1306C218.781 5.75665 263.711 -84.2747 347.846 -157.59C431.976 -230.901 555.251 -287.442 697.736 -309.806C840.221 -332.17 974.891 -316.114 1077.43 -272.102C1179.98 -228.088 1250.34 -156.15 1264.36 -66.7761Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="475.074"
          y="-147.751"
          width="513.038"
          height="351.778"
          transform="rotate(-8.92004 475.074 -147.751)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M-203.375 672.862C-276.491 619.584 -308.383 524.152 -300.733 412.817C-293.083 301.491 -245.894 174.341 -160.956 57.7755C-76.0174 -58.7897 30.5617 -142.663 134.192 -184.051C237.829 -225.442 338.443 -224.321 411.559 -171.043C484.675 -117.765 516.566 -22.333 508.916 89.0017C501.266 200.329 454.078 327.478 369.139 444.044C284.201 560.609 177.622 644.482 73.9915 685.87C-29.6458 727.261 -130.259 726.14 -203.375 672.862Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="411.997"
          y="172.009"
          width="513.038"
          height="351.778"
          transform="rotate(126.08 411.997 172.009)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1051.11 579.747C997.831 652.863 902.399 684.755 791.065 677.105C679.738 669.455 552.588 622.266 436.023 537.328C319.457 452.389 235.584 345.81 194.196 242.18C152.805 138.543 153.926 37.9293 207.204 -35.1867C260.482 -108.303 355.914 -140.194 467.249 -132.544C578.576 -124.894 705.725 -77.7056 822.291 7.23283C938.856 92.1712 1022.73 198.75 1064.12 302.381C1105.51 406.018 1104.39 506.631 1051.11 579.747Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="550.256"
          y="-35.6249"
          width="513.038"
          height="351.778"
          transform="rotate(36.08 550.256 -35.6249)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
      </g>
    </g>
    <g opacity="0.05" clipPath="url(#clip1_24307_57129)">
      <mask
        id="mask1_24307_57129"
        maskUnits="userSpaceOnUse"
        x="968"
        y="-174"
        width="292"
        height="404"
      >
        <ellipse
          cx="1113.59"
          cy="28.1092"
          rx="205.185"
          ry="140.263"
          transform="rotate(-75 1113.59 28.1092)"
          fill="url(#paint1_radial_24307_57129)"
        />
      </mask>
      <g mask="url(#mask1_24307_57129)">
        <path
          d="M1785.17 583.261C1744.29 663.969 1655.2 710.739 1544.08 721.026C1432.96 731.311 1299.9 705.105 1171.23 639.94C1042.56 574.775 942.695 483.011 885.237 387.351C827.776 291.684 812.762 192.191 853.637 111.483C894.512 30.7755 983.602 -15.9943 1094.72 -26.2809C1205.84 -36.5667 1338.91 -10.3601 1467.57 54.8048C1596.24 119.97 1696.11 211.734 1753.57 307.394C1811.03 403.06 1826.04 502.554 1785.17 583.261Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1192.19"
          y="56.0875"
          width="513.038"
          height="351.778"
          transform="rotate(26.8602 1192.19 56.0875)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1492.26 -640.097C1572.97 -599.222 1619.74 -510.133 1630.02 -399.011C1640.31 -287.896 1614.1 -154.829 1548.94 -26.1602C1483.77 102.508 1392.01 202.374 1296.35 259.831C1200.68 317.293 1101.19 332.306 1020.48 291.431C939.775 250.556 893.005 161.467 882.718 50.3447C872.432 -60.7697 898.639 -193.837 963.804 -322.506C1028.97 -451.174 1120.73 -551.04 1216.39 -608.497C1312.06 -665.958 1411.55 -680.972 1492.26 -640.097Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="965.086"
          y="-47.1166"
          width="513.038"
          height="351.778"
          transform="rotate(-63.1398 965.086 -47.1166)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1234.15 983.082C1148.18 1011.25 1052.11 981.324 966.261 910.022C880.418 838.726 804.856 726.102 759.952 589.041C715.048 451.98 709.319 316.478 736.333 208.207C763.348 99.9292 823.084 18.9606 909.056 -9.20541C995.028 -37.3714 1091.1 -7.44691 1176.94 63.8546C1262.79 135.151 1338.35 247.775 1383.25 384.836C1428.16 521.897 1433.89 657.399 1406.87 765.67C1379.86 873.948 1320.12 954.916 1234.15 983.082Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1187.62"
          y="191.012"
          width="513.038"
          height="351.778"
          transform="rotate(71.8602 1187.62 191.012)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1892.08 -89.0801C1920.24 -3.10819 1890.32 92.9588 1819.02 178.808C1747.72 264.651 1635.1 340.213 1498.04 385.116C1360.98 430.02 1225.47 435.749 1117.2 408.736C1008.93 381.72 927.957 321.984 899.791 236.012C871.625 150.04 901.549 53.9732 972.851 -31.8756C1044.15 -117.719 1156.77 -193.281 1293.83 -238.184C1430.89 -283.088 1566.4 -288.817 1674.67 -261.804C1782.94 -234.788 1863.91 -175.052 1892.08 -89.0801Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1100.01"
          y="-42.5485"
          width="513.038"
          height="351.778"
          transform="rotate(-18.1398 1100.01 -42.5485)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1638.92 763.63C1578.55 831.009 1480.39 853.127 1370.39 834.303C1260.4 815.479 1138.65 755.725 1031.24 659.479C923.818 563.233 851.105 448.748 820.364 341.477C789.621 234.198 800.87 134.209 861.241 66.8303C921.611 -0.548153 1019.77 -22.6663 1129.77 -3.84182C1239.76 14.9813 1361.51 74.7355 1468.93 170.982C1576.35 267.228 1649.06 381.712 1679.8 488.984C1710.54 596.263 1699.29 696.252 1638.92 763.63Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1202.59"
          y="100.945"
          width="513.038"
          height="351.778"
          transform="rotate(41.8602 1202.59 100.945)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1672.63 -493.854C1740.01 -433.483 1762.12 -335.325 1743.3 -225.327C1724.48 -115.336 1664.72 6.4146 1568.48 113.833C1472.23 221.251 1357.75 293.963 1250.47 324.704C1143.19 355.447 1043.21 344.199 975.827 283.828C908.449 223.457 886.331 125.298 905.155 15.3002C923.978 -94.6903 983.733 -216.441 1079.98 -323.859C1176.23 -431.277 1290.71 -503.99 1397.98 -534.731C1505.26 -565.474 1605.25 -554.225 1672.63 -493.854Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1009.94"
          y="-57.526"
          width="513.038"
          height="351.778"
          transform="rotate(-48.1398 1009.94 -57.526)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1003.2 1007.21C912.869 1012.16 827.82 958.395 763.351 867.303C698.886 776.218 655.047 647.875 647.148 503.863C639.248 359.85 668.785 227.482 722.9 129.892C777.019 32.296 855.677 -30.4527 946.009 -35.4079C1036.34 -40.363 1121.39 13.4058 1185.86 104.497C1250.32 195.582 1294.16 323.925 1302.06 467.938C1309.96 611.95 1280.42 744.318 1226.31 841.908C1172.19 939.504 1093.53 1002.25 1003.2 1007.21Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1916.21 141.865C1921.16 232.197 1867.4 317.246 1776.3 381.715C1685.22 446.18 1556.88 490.019 1412.86 497.918C1268.85 505.818 1136.48 476.281 1038.89 422.166C941.297 368.046 878.548 289.389 873.593 199.057C868.638 108.725 922.407 23.6761 1013.5 -40.7933C1104.58 -105.258 1232.93 -149.096 1376.94 -156.996C1520.95 -164.896 1653.32 -135.359 1750.91 -81.2435C1848.51 -27.1244 1911.25 51.5327 1916.21 141.865Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1139.09"
          y="-18.1932"
          width="513.038"
          height="351.778"
          transform="rotate(-3.13978 1139.09 -18.1932)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1450.98 900.001C1375.23 949.459 1274.69 945.418 1173.31 898.765C1071.94 852.116 969.805 762.886 890.957 642.118C812.109 521.349 771.505 391.946 769.576 280.374C767.646 168.793 804.391 75.1225 880.143 25.665C955.896 -23.7924 1056.43 -19.7515 1157.81 26.9011C1259.18 73.5505 1361.32 162.78 1440.17 283.549C1519.01 404.317 1559.62 533.72 1561.55 645.293C1563.48 756.873 1526.73 850.544 1450.98 900.001Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1201.03"
          y="146.966"
          width="513.038"
          height="351.778"
          transform="rotate(56.8602 1201.03 146.966)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1809 -305.912C1858.46 -230.159 1854.42 -129.62 1807.76 -28.2427C1761.11 73.1281 1671.88 175.265 1551.12 254.112C1430.35 332.96 1300.94 373.564 1189.37 375.494C1077.79 377.423 984.121 340.679 934.663 264.926C885.206 189.174 889.247 88.635 935.899 -12.7428C982.549 -114.114 1071.78 -216.25 1192.55 -295.098C1313.32 -373.945 1442.72 -414.55 1554.29 -416.479C1665.87 -418.409 1759.54 -381.664 1809 -305.912Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1055.96"
          y="-55.964"
          width="513.038"
          height="351.778"
          transform="rotate(-33.1398 1055.96 -55.964)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M773.877 970.745C685.34 952.152 617.106 878.203 578.409 773.53C539.715 668.864 530.588 533.548 560.231 392.398C589.874 251.248 652.664 131.035 730.193 50.7763C807.728 -29.4874 899.945 -69.7401 988.482 -51.1467C1077.02 -32.5532 1145.25 41.3956 1183.95 146.069C1222.64 250.735 1231.77 386.051 1202.13 527.201C1172.49 668.351 1109.7 788.564 1032.17 868.822C954.631 949.086 862.413 989.339 773.877 970.745Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1129.61"
          y="261.532"
          width="513.038"
          height="351.778"
          transform="rotate(101.86 1129.61 261.532)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1879.74 371.189C1861.15 459.726 1787.2 527.96 1682.53 566.656C1577.86 605.35 1442.54 614.477 1301.39 584.834C1160.24 555.192 1040.03 492.402 959.772 414.872C879.509 337.338 839.256 245.12 857.849 156.583C876.443 68.0464 950.392 -0.187828 1055.07 -38.8844C1159.73 -77.5783 1295.05 -86.705 1436.2 -57.0624C1577.35 -27.4198 1697.56 35.3703 1777.82 112.9C1858.08 190.435 1898.33 282.652 1879.74 371.189Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1170.53"
          y="15.4512"
          width="513.038"
          height="351.778"
          transform="rotate(11.8602 1170.53 15.4512)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1764.15 615.888C1719.79 694.736 1628.75 737.575 1517.28 743.005C1405.83 748.434 1274.03 716.448 1148.33 645.733C1022.62 575.018 926.854 478.985 873.624 380.91C820.39 282.828 809.731 182.774 854.088 103.926C898.444 25.0781 989.489 -17.7611 1100.95 -23.1908C1212.41 -28.6202 1344.21 3.36589 1469.91 74.0812C1595.62 144.796 1691.38 240.829 1744.61 338.904C1797.85 436.987 1808.51 537.04 1764.15 615.888Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1194.73"
          y="63.3501"
          width="513.038"
          height="351.778"
          transform="rotate(29.3602 1194.73 63.3501)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1524.89 -619.08C1603.74 -574.724 1646.57 -483.679 1652 -372.214C1657.43 -260.757 1625.45 -128.959 1554.73 -3.25538C1484.02 122.448 1387.98 218.216 1289.91 271.446C1191.83 324.68 1091.77 335.339 1012.93 290.983C934.077 246.626 891.238 155.582 885.808 44.1166C880.379 -67.3407 912.365 -199.139 983.08 -324.842C1053.8 -450.545 1149.83 -546.313 1247.9 -599.543C1345.99 -652.777 1446.04 -663.437 1524.89 -619.08Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="972.348"
          y="-49.6621"
          width="513.038"
          height="351.778"
          transform="rotate(-60.6398 972.348 -49.6621)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1196.22 991.288C1109.1 1015.68 1014.43 981.591 931.77 906.613C849.119 831.64 778.541 715.827 739.659 576.938C700.777 438.049 700.964 302.426 732.674 195.437C764.387 88.4401 827.598 10.1542 914.717 -14.235C1001.84 -38.6241 1096.51 -4.53773 1179.16 70.4405C1261.81 145.414 1332.39 261.226 1371.27 400.115C1410.16 539.004 1409.97 674.628 1378.26 781.617C1346.55 888.613 1283.33 966.899 1196.22 991.288Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1184.28"
          y="197.947"
          width="513.038"
          height="351.778"
          transform="rotate(74.3602 1184.28 197.947)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1900.29 -51.1518C1924.68 35.9668 1890.59 130.637 1815.61 213.294C1740.64 295.945 1624.83 366.523 1485.94 405.405C1347.05 444.288 1211.43 444.101 1104.44 412.39C997.441 380.678 919.155 317.467 894.766 230.348C870.377 143.229 904.463 48.559 979.442 -34.098C1054.41 -116.749 1170.23 -187.327 1309.12 -226.209C1448.01 -265.092 1583.63 -264.905 1690.62 -233.194C1797.61 -201.482 1875.9 -138.27 1900.29 -51.1518Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1106.95"
          y="-39.2173"
          width="513.038"
          height="351.778"
          transform="rotate(-15.6398 1106.95 -39.2173)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1610.18 789.705C1546.93 854.386 1447.9 872.202 1338.82 848.597C1229.76 824.994 1110.73 759.986 1007.61 659.146C904.495 558.306 836.845 440.759 810.813 332.249C784.778 223.731 800.378 124.328 863.63 59.6466C926.883 -5.03437 1025.91 -22.8498 1134.98 0.754755C1244.05 24.3577 1363.08 89.3656 1466.2 190.206C1569.31 291.046 1636.96 408.593 1663 517.103C1689.03 625.621 1673.43 725.024 1610.18 789.705Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1203.17"
          y="108.618"
          width="513.038"
          height="351.778"
          transform="rotate(44.3602 1203.17 108.618)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1698.7 -465.111C1763.38 -401.859 1781.2 -302.829 1757.6 -193.757C1733.99 -84.6921 1668.98 34.3363 1568.14 137.454C1467.3 240.572 1349.76 308.221 1241.25 334.254C1132.73 360.288 1033.33 344.689 968.645 281.436C903.964 218.184 886.148 119.154 909.753 10.0816C933.356 -98.9831 998.364 -218.011 1099.2 -321.129C1200.04 -424.247 1317.59 -491.897 1426.1 -517.929C1534.62 -543.963 1634.02 -528.364 1698.7 -465.111Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1017.62"
          y="-58.1014"
          width="513.038"
          height="351.778"
          transform="rotate(-45.6398 1017.62 -58.1014)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M964.439 1005.32C873.976 1006.33 791.354 948.906 730.919 855.089C670.489 761.279 632.29 631.146 630.68 486.926C629.069 342.706 664.352 211.752 722.673 116.616C780.997 21.4729 862.317 -37.7852 952.779 -38.7954C1043.24 -39.8055 1125.86 17.6219 1186.3 111.439C1246.73 205.249 1284.93 335.382 1286.54 479.602C1288.15 623.822 1252.87 754.776 1194.55 849.912C1136.22 945.055 1054.9 1004.31 964.439 1005.32Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1914.32 180.63C1915.33 271.092 1857.9 353.715 1764.09 414.149C1670.28 474.58 1540.14 512.778 1395.92 514.389C1251.7 515.999 1120.75 480.716 1025.61 422.396C930.472 364.071 871.214 282.752 870.204 192.289C869.193 101.827 926.621 19.2043 1020.44 -41.2304C1114.25 -101.661 1244.38 -139.859 1388.6 -141.47C1532.82 -143.08 1663.77 -107.797 1758.91 -49.4767C1854.05 8.84799 1913.31 90.1673 1914.32 180.63Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1144.92"
          y="-13.1712"
          width="513.038"
          height="351.778"
          transform="rotate(-0.639777 1144.92 -13.1712)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1416.47 917.749C1338.63 963.855 1238.37 955.432 1139.12 904.402C1039.88 853.375 941.732 759.776 868.228 635.683C794.723 511.59 759.802 380.539 762.741 268.989C765.68 157.43 806.476 65.4515 884.313 19.3454C962.151 -26.7607 1062.42 -18.3382 1161.66 32.692C1260.9 83.7187 1359.05 177.318 1432.56 301.411C1506.06 425.504 1540.98 556.555 1538.04 668.106C1535.1 779.664 1494.31 871.643 1416.47 917.749Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1199.61"
          y="154.526"
          width="513.038"
          height="351.778"
          transform="rotate(59.3602 1199.61 154.526)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1826.74 -271.396C1872.85 -193.558 1864.43 -93.2915 1813.4 5.9548C1762.37 105.194 1668.77 203.342 1544.68 276.846C1420.59 350.351 1289.54 385.272 1177.98 382.333C1066.43 379.394 974.448 338.598 928.341 260.76C882.235 182.923 890.658 82.656 941.688 -16.5903C992.715 -115.83 1086.31 -213.977 1210.41 -287.482C1334.5 -360.986 1465.55 -395.908 1577.1 -392.969C1688.66 -390.029 1780.64 -349.234 1826.74 -271.396Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1063.52"
          y="-54.5364"
          width="513.038"
          height="351.778"
          transform="rotate(-30.6398 1063.52 -54.5364)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M736.923 958.889C649.281 936.451 584.337 859.596 550.243 753.335C516.152 647.08 512.936 511.495 548.707 371.772C584.479 232.049 652.453 114.69 733.409 37.8895C814.371 -38.9158 908.257 -75.1078 995.898 -52.6701C1083.54 -30.2324 1148.48 46.6224 1182.58 152.884C1216.67 259.138 1219.88 394.724 1184.11 534.447C1148.34 674.169 1080.37 791.529 999.412 868.329C918.45 945.134 824.564 981.326 736.923 958.889Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1123.26"
          y="265.866"
          width="513.038"
          height="351.778"
          transform="rotate(104.36 1123.26 265.866)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1867.89 408.146C1845.45 495.787 1768.6 560.731 1662.33 594.825C1556.08 628.917 1420.49 632.132 1280.77 596.361C1141.05 560.59 1023.69 492.616 946.89 411.659C870.084 330.697 833.892 236.812 856.33 149.17C878.768 61.5285 955.622 -3.41517 1061.88 -37.5091C1168.14 -71.6007 1303.72 -74.8164 1443.45 -39.0451C1583.17 -3.27378 1700.53 64.7001 1777.33 145.657C1854.13 226.619 1890.33 320.504 1867.89 408.146Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1174.86"
          y="21.8108"
          width="513.038"
          height="351.778"
          transform="rotate(14.3602 1174.86 21.8108)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1741.73 647.569C1693.98 724.407 1601.15 763.234 1489.56 763.797C1377.97 764.359 1247.69 726.654 1125.19 650.523C1002.69 574.392 911.206 474.274 862.304 373.97C813.399 273.659 807.114 173.236 854.868 96.3977C902.621 19.5596 995.448 -19.2675 1107.04 -19.83C1218.63 -20.3925 1348.91 17.3121 1471.41 93.4431C1593.91 169.574 1685.4 269.693 1734.3 369.997C1783.2 470.307 1789.49 570.731 1741.73 647.569Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1196.96"
          y="70.7209"
          width="513.038"
          height="351.778"
          transform="rotate(31.8602 1196.96 70.7209)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1556.57 -596.668C1633.41 -548.914 1672.23 -456.087 1672.8 -344.492C1673.36 -232.904 1635.65 -102.626 1559.52 19.8727C1483.39 142.372 1383.27 233.86 1282.97 282.761C1182.66 331.666 1082.23 337.951 1005.4 290.197C928.559 242.444 889.731 149.617 889.169 38.0215C888.607 -73.5665 926.311 -203.844 1002.44 -326.343C1078.57 -448.842 1178.69 -540.33 1279 -589.231C1379.31 -638.136 1479.73 -644.421 1556.57 -596.668Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="979.718"
          y="-51.8947"
          width="513.038"
          height="351.778"
          transform="rotate(-58.1398 979.718 -51.8947)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1157.97 997.841C1069.87 1018.41 976.772 980.223 897.465 901.711C818.162 823.204 752.703 704.423 719.916 563.97C687.129 423.517 693.232 288.031 729.579 182.527C765.928 77.0158 832.494 1.56163 920.594 -19.0043C1008.69 -39.5702 1101.79 -1.38673 1181.09 77.1256C1260.4 155.633 1325.86 274.414 1358.64 414.866C1391.43 555.319 1385.33 690.806 1348.98 796.31C1312.63 901.821 1246.06 977.275 1157.97 997.841Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1180.65"
          y="204.729"
          width="513.038"
          height="351.778"
          transform="rotate(76.8602 1180.65 204.729)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1906.84 -12.9028C1927.4 75.1967 1889.22 168.29 1810.71 247.598C1732.2 326.9 1613.42 392.359 1472.97 425.146C1332.51 457.933 1197.03 451.831 1091.52 415.484C986.011 379.134 910.557 312.568 889.991 224.469C869.425 136.369 907.608 43.2758 986.121 -36.032C1064.63 -115.334 1183.41 -180.793 1323.86 -213.58C1464.31 -246.368 1599.8 -240.265 1705.3 -203.918C1810.82 -167.568 1886.27 -101.002 1906.84 -12.9028Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1113.73"
          y="-35.586"
          width="513.038"
          height="351.778"
          transform="rotate(-13.1398 1113.73 -35.586)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1580.33 814.502C1514.31 876.362 1414.6 889.841 1306.66 861.501C1198.73 833.164 1082.65 763.026 984.03 657.784C885.409 552.541 822.951 432.155 801.676 322.613C780.4 213.062 800.321 114.434 866.335 52.5739C932.348 -9.28645 1032.06 -22.7653 1140 5.57448C1247.93 33.9123 1364.01 104.05 1462.63 209.292C1561.25 314.535 1623.71 434.921 1644.99 544.463C1666.26 654.014 1646.34 752.642 1580.33 814.502Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1203.41"
          y="116.309"
          width="513.038"
          height="351.778"
          transform="rotate(46.8602 1203.41 116.309)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1723.5 -435.259C1785.36 -369.246 1798.84 -269.533 1770.5 -161.594C1742.16 -53.6626 1672.02 62.417 1566.78 161.038C1461.54 259.659 1341.15 322.117 1231.61 343.391C1122.06 364.668 1023.43 344.747 961.573 278.733C899.713 212.72 886.234 113.007 914.574 5.06807C942.911 -102.863 1013.05 -218.943 1118.29 -317.564C1223.53 -416.185 1343.92 -478.643 1453.46 -499.917C1563.01 -521.193 1661.64 -501.273 1723.5 -435.259Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1025.31"
          y="-58.3449"
          width="513.038"
          height="351.778"
          transform="rotate(-43.1398 1025.31 -58.3449)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M925.795 1001.75C835.375 998.81 755.336 937.834 699.051 841.47C642.77 745.113 610.285 613.438 614.966 469.285C619.648 325.132 660.61 195.842 723.024 103.34C785.444 10.8318 869.27 -44.8228 959.691 -41.8861C1050.11 -38.9494 1130.15 22.0273 1186.44 118.391C1242.72 214.748 1275.2 346.423 1270.52 490.576C1265.84 634.729 1224.88 764.019 1162.46 856.521C1100.04 949.029 1016.22 1004.68 925.795 1001.75Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1910.75 219.272C1907.81 309.693 1846.83 389.731 1750.47 446.016C1654.11 502.297 1522.44 534.783 1378.29 530.101C1234.13 525.419 1104.84 484.458 1012.34 422.043C919.833 359.624 864.178 275.797 867.115 185.376C870.052 94.956 931.028 14.9173 1027.39 -41.3677C1123.75 -97.6488 1255.42 -130.134 1399.58 -125.452C1543.73 -120.771 1673.02 -79.8093 1765.52 -17.3943C1858.03 45.0249 1913.68 128.852 1910.75 219.272Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1150.53"
          y="-7.90805"
          width="513.038"
          height="351.778"
          transform="rotate(1.86023 1150.53 -7.90805)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1381.21 933.971C1301.44 976.638 1201.64 963.85 1104.71 908.539C1007.79 853.232 913.819 755.441 845.797 628.26C777.775 501.079 748.604 368.629 756.406 257.313C764.208 145.989 808.977 55.8772 888.752 13.2103C968.527 -29.4567 1068.33 -16.6687 1165.26 38.6421C1262.18 93.949 1356.15 191.741 1424.17 318.922C1492.19 446.103 1521.36 578.552 1513.56 689.868C1505.76 801.192 1460.99 891.304 1381.21 933.971Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1197.85"
          y="162.014"
          width="513.038"
          height="351.778"
          transform="rotate(61.8602 1197.85 162.014)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1842.97 -236.147C1885.64 -156.372 1872.85 -56.5684 1817.54 40.3575C1762.23 137.277 1664.44 231.248 1537.26 299.27C1410.08 367.292 1277.63 396.463 1166.31 388.661C1054.99 380.859 964.877 336.09 922.21 256.315C879.543 176.54 892.331 76.7364 947.642 -20.1896C1002.95 -117.109 1100.74 -211.08 1227.92 -279.102C1355.1 -347.124 1487.55 -376.295 1598.87 -368.493C1710.19 -360.691 1800.3 -315.922 1842.97 -236.147Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1071.02"
          y="-52.7809"
          width="513.038"
          height="351.778"
          transform="rotate(-28.1398 1071.02 -52.7809)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M700.523 945.432C613.943 919.193 552.414 839.578 522.987 731.931C493.563 624.291 496.265 488.694 538.096 350.664C579.928 212.635 652.957 98.352 737.186 25.1563C821.421 -48.0444 916.796 -80.1067 1003.38 -53.8675C1089.95 -27.6283 1151.48 51.9862 1180.91 159.634C1210.34 267.274 1207.63 402.871 1165.8 540.9C1123.97 678.929 1050.94 793.213 966.712 866.408C882.477 939.609 787.102 971.671 700.523 945.432Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1116.72"
          y="269.922"
          width="513.038"
          height="351.778"
          transform="rotate(106.86 1116.72 269.922)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1854.43 444.547C1828.19 531.126 1748.58 592.656 1640.93 622.082C1533.29 651.506 1397.69 648.805 1259.67 606.973C1121.64 565.141 1007.35 492.113 934.157 407.883C860.957 323.648 828.894 228.273 855.133 141.694C881.373 55.1145 960.987 -6.41504 1068.63 -35.8415C1176.27 -65.2659 1311.87 -62.5643 1449.9 -20.7325C1587.93 21.0994 1702.21 94.1278 1775.41 178.357C1848.61 262.592 1880.67 357.967 1854.43 444.547Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1178.92"
          y="28.3529"
          width="513.038"
          height="351.778"
          transform="rotate(16.8602 1178.92 28.3529)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1717.95 678.235C1666.89 752.917 1572.46 787.659 1460.95 783.353C1349.44 779.047 1220.93 735.696 1101.87 654.294C982.81 572.892 895.776 468.878 851.296 366.537C806.814 264.188 804.915 163.586 855.975 88.9043C907.034 14.2224 1001.47 -20.5188 1112.98 -16.213C1224.49 -11.9075 1353 31.4437 1472.06 112.846C1591.12 194.248 1678.15 298.262 1722.63 400.603C1767.12 502.952 1769.01 603.553 1717.95 678.235Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1198.86"
          y="78.1724"
          width="513.038"
          height="351.778"
          transform="rotate(34.3602 1198.86 78.1724)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1587.24 -572.885C1661.92 -521.825 1696.66 -427.393 1692.36 -315.879C1688.05 -204.373 1644.7 -75.864 1563.3 43.1978C1481.89 162.26 1377.88 249.293 1275.54 293.773C1173.19 338.256 1072.59 340.154 997.907 289.095C923.225 238.035 888.484 143.603 892.79 32.089C897.095 -79.4174 940.447 -207.926 1021.85 -326.988C1103.25 -446.05 1207.26 -533.083 1309.61 -577.563C1411.95 -622.046 1512.56 -623.944 1587.24 -572.885Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="987.175"
          y="-53.7872"
          width="513.038"
          height="351.778"
          transform="rotate(-55.6398 987.175 -53.7872)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1119.47 1002.71C1030.55 1019.42 939.214 977.209 863.407 895.312C787.604 813.421 727.389 691.897 700.759 550.148C674.13 408.399 686.137 273.308 727.051 169.489C767.968 65.6641 837.762 -6.81469 926.675 -23.5182C1015.59 -40.2216 1106.93 1.98614 1182.73 83.8831C1258.54 165.774 1318.75 287.298 1345.38 429.047C1372.01 570.796 1360 705.887 1319.09 809.706C1278.17 913.531 1208.38 986.01 1119.47 1002.71Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1176.72"
          y="211.345"
          width="513.038"
          height="351.778"
          transform="rotate(79.3602 1176.72 211.345)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1911.71 25.6035C1928.42 114.516 1886.21 205.855 1804.31 281.663C1722.42 357.466 1600.9 417.681 1459.15 444.31C1317.4 470.94 1182.31 458.933 1078.49 418.019C974.663 377.101 902.184 307.308 885.481 218.395C868.777 129.482 910.985 38.1429 992.882 -37.6647C1074.77 -113.467 1196.3 -173.683 1338.05 -200.312C1479.8 -226.942 1614.89 -214.935 1718.7 -174.02C1822.53 -133.103 1895.01 -63.3093 1911.71 25.6035Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1120.35"
          y="-31.6507"
          width="513.038"
          height="351.778"
          transform="rotate(-10.6398 1120.35 -31.6507)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1549.42 837.97C1480.77 896.892 1380.57 906.009 1273.97 872.988C1167.37 839.969 1054.46 764.835 960.527 655.391C866.59 545.947 809.443 422.951 792.967 312.585C776.489 202.211 800.693 104.546 869.342 45.6238C937.991 -13.2982 1038.2 -22.4148 1144.8 10.6062C1251.39 43.6249 1364.3 118.76 1458.24 228.203C1552.17 337.647 1609.32 460.643 1625.8 571.009C1642.27 681.383 1618.07 779.048 1549.42 837.97Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1203.32"
          y="124.003"
          width="513.038"
          height="351.778"
          transform="rotate(49.3602 1203.32 124.003)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1746.97 -404.351C1805.9 -335.702 1815.01 -235.496 1781.99 -128.896C1748.97 -22.3035 1673.84 90.6061 1564.39 184.543C1454.95 278.479 1331.95 335.626 1221.59 352.103C1111.21 368.58 1013.55 344.376 954.627 275.727C895.705 207.078 886.588 106.872 919.609 0.272296C952.628 -106.32 1027.76 -219.23 1137.21 -313.166C1246.65 -407.103 1369.65 -464.25 1480.01 -480.726C1590.39 -497.204 1688.05 -473 1746.97 -404.351Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1033"
          y="-58.2524"
          width="513.038"
          height="351.778"
          transform="rotate(-40.6398 1033 -58.2524)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M887.343 996.497C797.137 989.619 719.834 925.209 667.806 826.482C615.782 727.762 589.071 594.795 600.036 450.983C611.001 307.172 657.563 179.791 723.954 90.1001C790.349 0.402664 876.523 -51.5425 966.729 -44.6645C1056.94 -37.7865 1134.24 26.6234 1186.27 125.35C1238.29 224.07 1265 357.038 1254.04 500.849C1243.07 644.661 1196.51 772.041 1130.12 861.732C1063.72 951.43 977.55 1003.37 887.343 996.497Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1905.49 257.721C1898.61 347.927 1834.2 425.23 1735.48 477.258C1636.76 529.283 1503.79 555.994 1359.98 545.028C1216.17 534.063 1088.79 487.501 999.095 421.111C909.398 354.716 857.453 268.541 864.331 178.335C871.209 88.1287 935.619 10.826 1034.35 -41.2021C1133.07 -93.2266 1266.03 -119.938 1409.84 -108.972C1553.66 -98.0071 1681.04 -51.4452 1770.73 14.9452C1860.42 81.3402 1912.37 167.515 1905.49 257.721Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1155.91"
          y="-2.40362"
          width="513.038"
          height="351.778"
          transform="rotate(4.36022 1155.91 -2.40362)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1345.29 948.645C1263.73 987.791 1164.58 970.662 1070.16 911.176C975.741 851.694 886.125 749.897 823.716 619.87C761.306 489.843 737.94 356.247 750.59 245.377C763.241 134.499 811.898 46.4261 893.458 7.27946C975.018 -31.8672 1074.17 -14.7379 1168.59 44.748C1263 104.23 1352.62 206.027 1415.03 336.054C1477.44 466.082 1500.81 599.677 1488.16 710.547C1475.5 821.425 1426.85 909.498 1345.29 948.645Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1195.77"
          y="169.427"
          width="513.038"
          height="351.778"
          transform="rotate(64.3602 1195.77 169.427)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1857.64 -200.219C1896.79 -118.659 1879.66 -19.5076 1820.18 74.9135C1760.69 169.328 1658.9 258.944 1528.87 321.354C1398.84 383.763 1265.25 407.13 1154.38 394.48C1043.5 381.828 955.425 333.172 916.278 251.612C877.132 170.052 894.261 70.9008 953.747 -23.5203C1013.23 -117.935 1115.03 -207.551 1245.05 -269.961C1375.08 -332.37 1508.68 -355.737 1619.55 -343.086C1730.42 -330.435 1818.5 -281.779 1857.64 -200.219Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1078.43"
          y="-50.7016"
          width="513.038"
          height="351.778"
          transform="rotate(-25.6398 1078.43 -50.7016)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M664.743 930.406C579.391 900.415 521.393 818.193 496.69 709.364C471.988 600.543 480.602 465.193 528.415 329.12C576.228 193.046 654.171 82.0575 741.513 12.6055C828.861 -56.8513 925.544 -84.7228 1010.9 -54.7321C1096.25 -24.7413 1154.25 57.4813 1178.95 166.31C1203.65 275.131 1195.04 410.481 1147.22 546.554C1099.41 682.628 1021.47 793.617 934.126 863.068C846.778 932.525 750.096 960.397 664.743 930.406Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1110.01"
          y="273.692"
          width="513.038"
          height="351.778"
          transform="rotate(109.36 1110.01 273.692)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1839.4 480.323C1809.41 565.676 1727.19 623.674 1618.36 648.377C1509.54 673.078 1374.19 664.464 1238.12 616.652C1102.04 568.839 991.055 490.895 921.603 403.553C852.146 316.205 824.275 219.522 854.265 134.17C884.256 48.8174 966.479 -9.18077 1075.31 -33.8837C1184.13 -58.5848 1319.48 -49.9712 1455.55 -2.15843C1591.63 45.6544 1702.61 123.598 1772.07 210.94C1841.52 298.288 1869.39 394.971 1839.4 480.323Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1182.69"
          y="35.0579"
          width="513.038"
          height="351.778"
          transform="rotate(19.3602 1182.69 35.0579)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1692.86 707.842C1638.59 780.225 1542.73 810.814 1431.51 801.648C1320.3 792.483 1193.81 743.568 1078.41 657.05C963.011 570.532 880.597 462.821 840.624 358.636C800.648 254.445 803.139 153.856 857.408 81.4725C911.677 9.0888 1007.53 -21.5003 1118.75 -12.3344C1229.97 -3.16918 1356.46 45.7463 1471.86 132.264C1587.26 218.782 1669.67 326.493 1709.64 430.678C1749.62 534.869 1747.13 635.458 1692.86 707.842Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1200.43"
          y="85.7046"
          width="513.038"
          height="351.778"
          transform="rotate(36.8602 1200.43 85.7046)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1616.84 -547.793C1689.23 -493.525 1719.81 -397.667 1710.65 -286.447C1701.48 -175.235 1652.57 -48.7394 1566.05 66.6584C1479.53 182.056 1371.82 264.47 1267.64 304.443C1163.45 344.419 1062.86 341.928 990.472 287.659C918.089 233.391 887.5 137.533 896.666 26.313C905.831 -84.8994 954.746 -211.395 1041.26 -326.792C1127.78 -442.19 1235.49 -524.604 1339.68 -564.577C1443.87 -604.553 1544.46 -602.062 1616.84 -547.793Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="994.707"
          y="-55.367"
          width="513.038"
          height="351.778"
          transform="rotate(-53.1398 994.707 -55.367)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1080.79 1005.91C991.231 1018.72 901.82 972.564 829.657 887.438C757.499 802.318 702.641 678.284 682.22 535.508C661.799 392.732 679.687 258.293 725.091 156.359C770.498 54.4169 843.387 -14.9485 932.944 -27.7577C1022.5 -40.567 1111.91 5.58475 1184.07 90.7105C1256.23 175.83 1311.09 299.864 1331.51 442.64C1351.93 585.416 1334.04 719.855 1288.64 821.79C1243.23 923.732 1170.34 993.097 1080.79 1005.91Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1914.9 64.2844C1927.71 153.841 1881.56 243.252 1796.43 315.416C1711.31 387.574 1587.28 442.431 1444.5 462.852C1301.73 483.273 1167.29 465.385 1065.35 419.981C963.413 374.574 894.048 301.685 881.238 212.129C868.429 122.572 914.581 33.1608 999.707 -39.0024C1084.83 -111.161 1208.86 -166.018 1351.64 -186.439C1494.41 -206.86 1628.85 -188.972 1730.79 -143.568C1832.73 -98.1612 1902.09 -25.2724 1914.9 64.2844Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1126.79"
          y="-27.431"
          width="513.038"
          height="351.778"
          transform="rotate(-8.13978 1126.79 -27.431)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1517.52 860.074C1446.37 915.945 1345.86 920.682 1240.8 883.043C1135.75 845.406 1026.22 765.418 937.151 651.981C848.078 538.544 796.35 413.172 784.704 302.192C773.057 191.205 801.498 94.6881 872.651 38.8166C943.805 -17.0549 1044.31 -21.7919 1149.37 15.8475C1254.42 53.4843 1363.95 133.472 1453.02 246.909C1542.09 360.346 1593.82 485.718 1605.47 596.698C1617.11 707.686 1588.67 804.202 1517.52 860.074Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1202.89"
          y="131.687"
          width="513.038"
          height="351.778"
          transform="rotate(51.8602 1202.89 131.687)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1769.07 -372.454C1824.94 -301.3 1829.68 -200.792 1792.04 -95.7338C1754.4 9.31693 1674.42 118.842 1560.98 207.915C1447.54 296.988 1322.17 348.716 1211.19 360.363C1100.2 372.01 1003.69 343.569 947.815 272.415C891.943 201.261 887.206 100.753 924.846 -4.305C962.482 -109.356 1042.47 -218.881 1155.91 -307.954C1269.34 -397.027 1394.72 -448.755 1505.7 -460.401C1616.68 -472.049 1713.2 -443.608 1769.07 -372.454Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1040.69"
          y="-57.828"
          width="513.038"
          height="351.778"
          transform="rotate(-38.1398 1040.69 -57.828)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M849.157 989.558C759.337 978.752 684.917 911.031 637.245 810.129C589.576 709.233 568.69 575.227 585.918 432.031C603.146 288.835 655.22 163.606 725.459 76.8964C795.704 -9.81953 884.062 -57.9564 973.883 -47.1502C1063.7 -36.344 1138.12 31.3765 1185.79 132.279C1233.46 233.174 1254.35 367.18 1237.12 510.377C1219.89 653.573 1167.82 778.801 1097.58 865.511C1027.34 952.227 938.978 1000.36 849.157 989.558Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1141.73"
          y="252.034"
          width="513.038"
          height="351.778"
          transform="rotate(96.8602 1141.73 252.034)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1898.56 295.907C1887.75 385.728 1820.03 460.147 1719.13 507.819C1618.24 555.488 1484.23 576.374 1341.03 559.146C1197.84 541.918 1072.61 489.845 985.9 419.605C899.184 349.361 851.047 261.002 861.853 171.182C872.659 81.3615 940.38 6.94181 1041.28 -40.7304C1142.18 -88.3993 1276.18 -109.285 1419.38 -92.0571C1562.58 -74.8293 1687.8 -22.7555 1774.51 47.484C1861.23 117.728 1909.37 206.087 1898.56 295.907Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1161.04"
          y="3.33418"
          width="513.038"
          height="351.778"
          transform="rotate(6.86022 1161.04 3.33418)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1308.75 961.74C1225.56 997.291 1127.25 975.854 1035.52 912.306C943.785 848.762 858.695 743.152 802.016 610.527C745.338 477.901 727.821 343.414 745.295 233.201C762.77 122.98 815.223 37.1135 898.412 1.56169C981.602 -33.9901 1079.91 -12.5522 1171.65 50.9957C1263.38 114.539 1348.47 220.149 1405.15 352.775C1461.83 485.4 1479.34 619.888 1461.87 730.101C1444.39 840.321 1391.94 926.188 1308.75 961.74Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1193.36"
          y="176.74"
          width="513.038"
          height="351.778"
          transform="rotate(66.8602 1193.36 176.74)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1870.73 -163.681C1906.29 -80.4909 1884.85 17.8186 1821.3 109.555C1757.76 201.285 1652.15 286.376 1519.52 343.054C1386.9 399.733 1252.41 417.249 1142.19 399.775C1031.97 382.3 946.108 329.848 910.556 246.658C875.004 163.468 896.442 65.1584 959.99 -26.5781C1023.53 -118.308 1129.14 -203.399 1261.77 -260.077C1394.39 -316.756 1528.88 -334.273 1639.09 -316.798C1749.32 -299.323 1835.18 -246.871 1870.73 -163.681Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1085.74"
          y="-48.2946"
          width="513.038"
          height="351.778"
          transform="rotate(-23.1398 1085.74 -48.2946)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M629.653 913.828C545.69 880.143 491.334 795.469 471.401 685.666C451.47 575.871 465.98 441.026 519.682 307.168C573.385 173.31 656.096 65.8262 746.384 0.250086C836.679 -65.3305 934.485 -88.9583 1018.45 -55.2731C1102.41 -21.5878 1156.77 63.0863 1176.7 172.889C1196.63 282.684 1182.12 417.529 1128.42 551.388C1074.72 685.246 992.005 792.729 901.717 858.305C811.422 923.886 713.616 947.514 629.653 913.828Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1103.14"
          y="277.161"
          width="513.038"
          height="351.778"
          transform="rotate(111.86 1103.14 277.161)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1822.83 515.417C1789.14 599.38 1704.47 653.737 1594.66 673.669C1484.87 693.6 1350.02 679.091 1216.17 625.388C1082.31 571.685 974.824 488.974 909.248 398.686C843.668 308.392 820.04 210.585 853.725 126.622C887.41 42.6591 972.084 -11.6974 1081.89 -31.6297C1191.68 -51.5607 1326.53 -37.0514 1460.39 16.6513C1594.24 70.3541 1701.73 153.065 1767.3 243.353C1832.88 333.648 1856.51 431.454 1822.83 515.417Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1186.16"
          y="41.9296"
          width="513.038"
          height="351.778"
          transform="rotate(21.8602 1186.16 41.9296)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1666.5 736.325C1609.13 806.273 1512.03 832.652 1401.31 818.643C1290.61 804.636 1166.36 750.249 1054.85 658.78C943.336 567.311 865.699 456.107 830.308 350.278C794.914 244.442 801.791 144.058 859.166 74.1102C916.54 4.16256 1013.64 -22.2161 1124.35 -8.20765C1235.06 5.79987 1359.3 60.1864 1470.82 151.656C1582.33 243.125 1659.97 354.328 1695.36 460.157C1730.75 565.993 1723.88 666.378 1666.5 736.325Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1201.68"
          y="93.302"
          width="513.038"
          height="351.778"
          transform="rotate(39.3602 1201.68 93.302)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1645.32 -521.428C1715.27 -464.053 1741.65 -366.953 1727.64 -256.238C1713.63 -145.531 1659.25 -21.2901 1567.78 90.2239C1476.31 201.738 1365.11 279.375 1259.28 314.766C1153.44 350.159 1053.06 343.283 983.108 285.908C913.161 228.534 886.782 131.433 900.79 20.7189C914.798 -89.988 969.184 -214.229 1060.65 -325.743C1152.12 -437.257 1263.33 -514.895 1369.16 -550.285C1474.99 -585.679 1575.38 -578.802 1645.32 -521.428Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1002.3"
          y="-56.6067"
          width="513.038"
          height="351.778"
          transform="rotate(-50.6398 1002.3 -56.6067)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1042.01 1007.4C951.979 1016.29 864.666 966.284 796.284 878.092C727.907 789.906 678.513 663.597 664.339 520.066C650.165 376.535 673.9 243.005 723.707 143.147C773.518 43.2833 849.363 -22.8368 939.393 -31.7274C1029.42 -40.6181 1116.74 9.38983 1185.12 97.5822C1253.49 185.769 1302.89 312.078 1317.06 455.608C1331.24 599.139 1307.5 732.67 1257.69 832.527C1207.88 932.391 1132.04 998.511 1042.01 1007.4Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1916.4 103.058C1925.29 193.088 1875.28 280.401 1787.09 348.782C1698.9 417.159 1572.6 466.554 1429.07 480.728C1285.53 494.902 1152 471.166 1052.15 421.359C952.282 371.549 886.162 295.704 877.272 205.673C868.381 115.643 918.389 28.3303 1006.58 -40.0512C1094.77 -108.428 1221.08 -157.823 1364.61 -171.997C1508.14 -186.17 1641.67 -162.435 1741.53 -112.628C1841.39 -62.8178 1907.51 13.0274 1916.4 103.058Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1133.04"
          y="-22.9503"
          width="513.038"
          height="351.778"
          transform="rotate(-5.63978 1133.04 -22.9503)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1484.69 880.762C1411.16 933.477 1310.54 933.825 1207.23 891.639C1103.92 849.456 997.987 764.766 913.947 647.552C829.906 530.338 783.696 402.829 776.902 291.447C770.107 180.057 802.731 84.8724 876.254 32.1577C949.777 -20.5569 1050.4 -20.9053 1153.71 21.2809C1257.02 63.4641 1362.95 148.154 1446.99 265.368C1531.03 382.582 1577.24 510.091 1584.04 621.473C1590.83 732.863 1558.21 828.048 1484.69 880.762Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1202.13"
          y="139.343"
          width="513.038"
          height="351.778"
          transform="rotate(54.3602 1202.13 139.343)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1789.76 -339.621C1842.47 -266.098 1842.82 -165.479 1800.64 -62.1628C1758.45 41.1464 1673.76 147.078 1556.55 231.118C1439.33 315.159 1311.83 361.369 1200.44 368.163C1089.05 374.958 993.869 342.334 941.155 268.811C888.44 195.288 888.092 94.6688 930.278 -8.64748C972.461 -111.957 1057.15 -217.888 1174.36 -301.929C1291.58 -385.969 1419.09 -432.179 1530.47 -438.974C1641.86 -445.768 1737.04 -413.145 1789.76 -339.621Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1048.34"
          y="-57.0678"
          width="513.038"
          height="351.778"
          transform="rotate(-35.6398 1048.34 -57.0678)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M811.315 980.977C722.051 966.263 650.656 895.361 607.431 792.475C564.208 689.596 549.188 554.807 572.645 412.498C596.103 270.189 653.589 147.352 727.544 63.7882C801.504 -19.7812 891.878 -64.0181 981.142 -49.3043C1070.41 -34.5905 1141.8 36.3117 1185.03 139.198C1228.25 242.076 1243.27 376.866 1219.81 519.174C1196.35 661.483 1138.87 784.32 1064.91 867.884C990.952 951.453 900.578 995.69 811.315 980.977Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1135.78"
          y="256.917"
          width="513.038"
          height="351.778"
          transform="rotate(99.3602 1135.78 256.917)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1889.98 333.755C1875.26 423.018 1804.36 494.413 1701.47 537.639C1598.6 580.861 1463.81 595.882 1321.5 572.424C1179.19 548.967 1056.35 491.48 972.787 417.525C889.218 343.565 844.981 253.191 859.695 163.927C874.409 74.6638 945.311 3.26895 1048.2 -39.9566C1151.08 -83.1791 1285.86 -98.1996 1428.17 -74.7421C1570.48 -51.2845 1693.32 6.20215 1776.88 80.157C1860.45 154.117 1904.69 244.491 1889.98 333.755Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1165.92"
          y="9.2897"
          width="513.038"
          height="351.778"
          transform="rotate(9.36022 1165.92 9.2897)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1271.68 973.221C1187.02 1005.11 1089.74 979.405 1000.86 911.916C911.992 844.431 831.589 735.211 780.75 600.239C729.91 465.267 718.276 330.144 740.541 220.798C762.808 111.445 818.955 27.9475 903.617 -3.94172C988.278 -35.831 1085.56 -10.1253 1174.44 57.3636C1263.31 124.848 1343.71 234.069 1394.55 369.04C1445.39 504.012 1457.02 639.136 1434.76 748.481C1412.49 857.834 1356.34 941.332 1271.68 973.221Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1190.65"
          y="183.939"
          width="513.038"
          height="351.778"
          transform="rotate(69.3602 1190.65 183.939)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1882.22 -126.612C1914.11 -41.9508 1888.4 55.3301 1820.91 144.207C1753.43 233.078 1644.21 313.481 1509.24 364.321C1374.27 415.16 1239.14 426.794 1129.8 404.529C1020.44 382.263 936.947 326.115 905.057 241.453C873.168 156.792 898.874 59.5112 966.363 -29.3661C1033.85 -118.237 1143.07 -198.64 1278.04 -249.48C1413.01 -300.319 1548.13 -311.953 1657.48 -289.688C1766.83 -267.421 1850.33 -211.274 1882.22 -126.612Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1092.94"
          y="-45.5755"
          width="513.038"
          height="351.778"
          transform="rotate(-20.6398 1092.94 -45.5755)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M595.316 895.74C512.902 858.424 462.291 771.46 447.167 660.892C432.044 550.332 452.422 416.248 511.912 284.859C571.403 153.471 658.723 49.6978 751.786 -11.8776C844.855 -73.4572 943.599 -92.7962 1026.01 -55.4806C1108.43 -18.165 1159.04 68.7996 1174.16 179.367C1189.28 289.927 1168.91 424.011 1109.42 555.4C1049.93 686.788 962.606 790.561 869.543 852.137C776.474 913.716 677.73 933.055 595.316 895.74Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1096.12"
          y="280.332"
          width="513.038"
          height="351.778"
          transform="rotate(114.36 1096.12 280.332)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <path
          d="M1804.73 549.745C1767.42 632.159 1680.45 682.77 1569.89 697.894C1459.33 713.017 1325.24 692.64 1193.85 633.149C1062.47 573.659 958.693 486.338 897.118 393.276C835.538 300.206 816.199 201.462 853.514 119.049C890.83 36.6348 977.795 -13.9765 1088.36 -29.1004C1198.92 -44.2232 1333.01 -23.8458 1464.39 35.6446C1595.78 95.1351 1699.56 182.456 1761.13 275.518C1822.71 368.588 1842.05 467.332 1804.73 549.745Z"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
        <rect
          x="1189.32"
          y="48.9369"
          width="513.038"
          height="351.778"
          transform="rotate(24.3602 1189.32 48.9369)"
          stroke="currentColor"
          strokeWidth="0.973144"
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_24307_57129"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(202.098 121.697) rotate(3.87171) scale(374.321 126.88)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_24307_57129"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1081.27 61.7712) rotate(8.30008) scale(175.581 126.194)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_24307_57129">
        <rect
          width="615"
          height="209"
          fill="white"
          transform="translate(-2 7)"
        />
      </clipPath>
      <clipPath id="clip1_24307_57129">
        <rect
          width="661"
          height="521.948"
          fill="white"
          transform="translate(650 -8.73438) rotate(-11.7197)"
        />
      </clipPath>
    </defs>
  </Box>
)

export default CyberIntelBriefBackground
