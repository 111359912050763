import { Box } from '@mui/material'
import { useRef, useState } from 'react'
import {
  arrow,
  autoUpdate,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import PopoverContent from '@common/PopoverContent'
import Icon from '@common/Icon'

import CyberAssistantHeader from './Header/CyberAssistantHeader'
import CyberAssistantSearch from './CyberAssistantSearch/CyberAssistantSearch'
import KnowledgeBase from './KnowledgeBase/KnowledgeBase'

export type CyberAssistantView = 'search' | 'knowledgeBase'

const CyberAssistant = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [viewType, setViewType] = useState<CyberAssistantView>('knowledgeBase')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const arrowRef = useRef<SVGSVGElement>(null)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      offset({ mainAxis: 13 }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 5 }), // Padding for border-radius
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const toggleView = () => {
    if (viewType === 'search') {
      setSearchTerm('')
      setViewType('knowledgeBase')
    } else {
      setViewType('search')
    }
  }

  const handleViewTypeChange = (viewType: CyberAssistantView) => {
    setViewType(viewType)
  }

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  return (
    <>
      <Box
        aria-label="cyber-assistant"
        data-testid="cyber-assistant"
        ref={refs.setReference}
        {...getReferenceProps()}
        sx={{
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <Icon size={24} variant="bookOutline" sx={{ color: 'text.primary' }} />
      </Box>
      {isOpen && (
        <FloatingFocusManager context={context}>
          <PopoverContent
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            setFloating={refs.setFloating}
            arrowProps={{ arrowRef, context }}
          >
            <Box
              id="cyber-assistant"
              sx={{
                width: '653px',
                height: { sm: '75vh' },
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CyberAssistantHeader
                onClose={() => {
                  setIsOpen(false), setSearchTerm('')
                }}
                viewType={viewType}
                onToggleView={toggleView}
              />
              <Box
                sx={() => ({
                  display: 'contents',
                })}
              >
                {viewType === 'knowledgeBase' ? (
                  <Box
                    sx={{
                      display: 'contents',
                    }}
                  >
                    <KnowledgeBase
                      handleViewTypeChange={handleViewTypeChange}
                      handleSearchTermChange={handleSearchTermChange}
                    />
                  </Box>
                ) : (
                  <CyberAssistantSearch
                    searchTerm={searchTerm}
                    handleSearchTermChange={handleSearchTermChange}
                  />
                )}
              </Box>
            </Box>
          </PopoverContent>
        </FloatingFocusManager>
      )}
    </>
  )
}

export default CyberAssistant
