import {
  ReportLabelType,
  ReportType,
  ThreatIntelReport,
  ThreatIntelReportLabel,
} from '@models/ThreatIntel'
import {
  SortDirection,
  ThreatIntelReportSortOptions,
} from '@queries/threatIntel'

export const DEFAULT_THREAT_INTEL_REPORTS_INPUT = {
  sortBy: ThreatIntelReportSortOptions.Date,
  sortDirection: SortDirection.Descending,
}

export const getThreatIntelReportLabels = (searchParams: URLSearchParams) => {
  const labelTypes = Object.values(ReportLabelType)
  const labels: ThreatIntelReportLabel[] = []

  searchParams.forEach((value, key) => {
    const type = key.split('_label')[0] as ReportLabelType

    if (labelTypes.includes(type)) {
      labels.push({
        type,
        name: value,
      })
    }
  })

  return labels
}

export const isReportLabelSelected = (
  selectedLabels: ThreatIntelReportLabel[],
  label: ThreatIntelReportLabel,
) => selectedLabels.some((l) => l.type === label.type && l.name === label.name)

export const emptyReport: ThreatIntelReport = {
  id: '',
  title: '',
  type: ReportType.CYBER_INTEL_BRIEF,
  labels: [],
  source: '',
  createdDate: '',
  overview: '',
  associatedDetections: [],
  relatedTickets: [],
  externalReferences: [],
  relatedIntelReports: [],
  markAsRead: false,
  publicUrl: '',
  sourceMaterial: null,
  targetedIndustries: null,
}
