import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { format } from 'date-fns'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Box, Button, Skeleton, Typography } from '@mui/material'

import {
  FormattedSecurityIndexEvent,
  ChangeHistoryType,
} from '@models/SecurityIndex'
import { getIconVariant } from '@components/Home/SecurityIndex/SecurityIndex.utils'
import { Context, setDateFilter } from '@components/App'
import { ComponentError } from '@common/ComponentError'
import { useAwaitedCustomer } from '@hooks/useCustomer'
import { Loader } from '@common/Loader'

import {
  GET_SECURITY_INDEX_NEAREST_CHANGE_DATES,
  GetSecurityIndexNearestChangeDates,
  SecurityIndexVariables,
} from '../../../../../graphql/queries/securityIndex'
import { CarouselCard } from './CarouselCard'
import CarouselHeader from './CarouselHeader'
import { useDrag } from './useDrag'

import 'react-horizontal-scrolling-menu/dist/styles.css'
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>
type Carousel = {
  loading: boolean
  events: FormattedSecurityIndexEvent[] | null
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const boxStyle = {
  height: '100%',
  position: 'relative',
  margin: '1.5em 0 3em',
  '&.carousel-border': {
    border: '1px solid transparent',
    transition: 'ease-out border-color 0.5s',
  },

  '.react-horizontal-scrolling-menu--separator': {
    margin: '0 8px',
  },
  '.react-horizontal-scrolling-menu--scroll-container': {
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    gap: '1rem',
    '&::-webkit-scrollbar': {
      display: 'none' /* Chrome and Chromium based */,
    },
  },
  '.no-events': {
    justifyContent: 'center',
    cursor: 'default',
  },
}

const today = new Date()
const DATE_FORMAT = 'yyyy-MM-dd'

/**
 * @description Converts a date string to a date object at midnight
 * @param {string} dateString A date string formatted as 'yyyy-MM-dd'
 * @returns {Date} A date object at midnight of the given date string
 */
function convertDateStringToDateAtMidnight(dateString: string): Date {
  return new Date(`${dateString}T00:00`)
}

/**
 * @description Returns either:
 * A. The nearest date in the past with a significant security index affecting change
 *
 * or, if the security index was not yet being calculated for the customer at that time,
 *
 * B. The date at which the security index started to be calculated for the customer
 *
 * In the situation where the nearest start date returned from the query is
 * less than the customer's maturity score date, we want to set the start date
 * to the customer's maturity score date. Otherwise, the date picker will
 * highlight dates that are prior to the customer's start date, which
 * shouldn't be possible.
 * @param {GetSecurityIndexNearestChangeDates['getSecurityIndexNearestChangeDates']} securityIndexNearestChangeDates The nearest dates in the past and future with security index affecting changes
 * @param {string} maturityScoreStartDateString The date at which the customer's maturity score was first calculated, formatted as 'yyyy-MM-dd'
 * @returns {Date} The nearest date of either a security index affecting change, or the customer's first maturity score calculation
 */
export function getNearestStartDate(
  securityIndexNearestChangeDates: GetSecurityIndexNearestChangeDates['getSecurityIndexNearestChangeDates'],
  maturityScoreStartDateString: string,
): Date {
  const maturityScoreStartDate = maturityScoreStartDateString
    ? convertDateStringToDateAtMidnight(maturityScoreStartDateString)
    : convertDateStringToDateAtMidnight(format(today, DATE_FORMAT))
  const securityIndexNearestStartDate = convertDateStringToDateAtMidnight(
    securityIndexNearestChangeDates.nearestStartDate,
  )
  const nearestDate = Math.max(
    securityIndexNearestStartDate.valueOf(),
    maturityScoreStartDate.valueOf(),
  )
  return new Date(nearestDate)
}

const Carousel: React.FC<Carousel> = ({
  events,
  loading,
  selectedTrendEventState,
}) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const [nearestChangeLoading, setNearestChangeLoading] = useState(false)
  const [nearestChangeNull, setNearestChangeNull] = useState(false)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''
  const [selectedTrendEvent, setSelectedTrendEvent] = selectedTrendEventState
  const apiRef = useRef({} as scrollVisibilityApiType)

  const {
    dispatch,
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const { maturityScoreStartDate } = useAwaitedCustomer()

  useEffect(() => {
    /**
     * Get all cards with a data attribute containing "active"
     * the testid contains the index that we need to tell the
     * carousel where to scroll to and position the selected cards.
     */
    const selectedCards =
      Array.from(document.querySelectorAll("[data-cardActive='active']"))
        .map((selected) => selected.getAttribute('data-testid'))
        .map((idString) => idString?.split('card-')[1]) ?? ''

    if (Object.keys(apiRef.current).length) {
      if (selectedCards.length) {
        apiRef.current.scrollToItem(
          // Scroll to the first active card in the array.
          apiRef.current.getItemById(selectedCards[0] ?? ''),
          'smooth',
          'center',
        )
      } else {
        apiRef.current.scrollToItem(apiRef.current.getItemById('0'))
      }
    }
  }, [events, selectedTrendEvent])

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  const handleItemClick = (eventDate: Date) => () => {
    if (dragging) {
      return false
    }
    setSelectedTrendEvent(
      eventDate.getTime() === selectedTrendEvent?.getTime()
        ? undefined
        : eventDate,
    )
  }

  const [getNearestChangeDates] = useLazyQuery<
    GetSecurityIndexNearestChangeDates,
    SecurityIndexVariables
  >(GET_SECURITY_INDEX_NEAREST_CHANGE_DATES)

  const handleViewNearestChange = async () => {
    setNearestChangeLoading(true)
    // we only need to query for the nearest start and end dates
    // since by updating the date filter that will send respective
    // queries for other security index components
    const {
      data: { getSecurityIndexNearestChangeDates } = {
        data: getSecurityIndexNearestChangeDates,
      },
    } = await getNearestChangeDates({
      variables: {
        selectedCustomer,
        startDate: format(startDate, DATE_FORMAT),
        endDate: format(endDate, DATE_FORMAT),
      },
    })

    if (!getSecurityIndexNearestChangeDates || !maturityScoreStartDate) {
      setNearestChangeNull(true)
      return
    }

    const nearestStartDate = getNearestStartDate(
      getSecurityIndexNearestChangeDates,
      maturityScoreStartDate,
    )

    const nearestEndDate = convertDateStringToDateAtMidnight(
      getSecurityIndexNearestChangeDates.nearestEndDate,
    )

    dispatch(
      setDateFilter({
        startDate: nearestStartDate,
        endDate: nearestEndDate,
        defaultStartDate: nearestStartDate,
        defaultEndDate: nearestEndDate,
      }),
    )
    setNearestChangeLoading(false)
    setNearestChangeNull(false)
  }

  const cardDescription = (card: FormattedSecurityIndexEvent) => {
    const formattedDate =
      card.createdAt.getFullYear() < today.getFullYear()
        ? format(card.createdAt, 'MMM do, yyyy')
        : format(card.createdAt, 'MMM do')
    if (card.type === ChangeHistoryType.APP_VERSION_CHANGE) {
      if (card.dataSource?.length >= 1) {
        return `${formattedDate} • ${card.dataSource}`
      }
      return formattedDate
    }
    return card.dataSource?.length > 1
      ? `${formattedDate} • ${card.dataSource[0]} + ${
          card.dataSource.length - 1
        } more`
      : `${formattedDate} • ${card.dataSource}`
  }

  const cardTitle = (card) => {
    return card.type === ChangeHistoryType.APP_VERSION_CHANGE
      ? 'DWA Version Change'
      : card.sourceName
  }

  const featureFlagNearestChange = () => {
    if (nearestChangeNull || !maturityScoreStartDate) {
      return null
    }

    return nearestChangeLoading ? (
      <Loader centered size={50} />
    ) : (
      <Button
        variant="contained"
        onClick={handleViewNearestChange}
        sx={{ marginTop: 1 }}
      >
        View nearest change
      </Button>
    )
  }

  const getCarousel = () => {
    if (!events) {
      return <ComponentError />
    }
    if (events && events.length <= 0) {
      return (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography color="textPrimary" variant="body2">
            {nearestChangeNull
              ? 'There have been no changes made to your environment.'
              : `No changes have been made to your environment during the selected
            date range.`}
          </Typography>

          {featureFlagNearestChange()}
        </Box>
      )
    }
    return events?.map((event, index) => {
      return (
        <CarouselCard
          key={index}
          testId={`card-${index}`}
          added={event.action}
          title={cardTitle(event)}
          info={cardDescription(event)}
          iconVariant={getIconVariant(event.type)}
          onClick={handleItemClick(event.createdAt)}
          // eslint-disable-next-line security/detect-object-injection
          link={getSeparatorClass(events)[index]}
          isActive={selectedTrendEvent?.getTime() === event.createdAt.getTime()}
        />
      )
    })
  }

  const getSeparatorClass = (
    events: FormattedSecurityIndexEvent[],
  ): string[] => {
    const matches: string[] = []
    for (let i = 0; i < events.length - 1; i++) {
      const curr = events.at(i)!
      const next = events.at(i + 1)!

      if (next && curr.createdAt.getTime() === next.createdAt.getTime()) {
        matches.push('same-date')
      } else {
        matches.push('')
      }
    }
    return matches
  }

  if (loading) {
    return (
      <Skeleton
        sx={{ minHeight: '75px', width: '100%' }}
        data-testid="skeleton-block"
      />
    )
  }

  return (
    <Box sx={boxStyle}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        Header={<CarouselHeader data={events} />}
        scrollContainerClassName={
          events && events.length <= 0 ? 'no-events' : ''
        }
        apiRef={apiRef}
      >
        {getCarousel()}
      </ScrollMenu>
    </Box>
  )
}

export default Carousel
