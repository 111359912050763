import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams } from 'react-router-dom'

import { ADD_CYBER_ASSISTANT_FEEDBACK } from '@mutations/cyberAssistant'
import { Loader } from '@common/Loader'

interface AnswerDetailsProps {
  answerId: string
}

const ButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.lighter,
    color: theme.vars.palette.text.secondary,
    ':hover': {
      backgroundColor: theme.vars.palette.secondary.light,
    },
  }),
  minWidth: '111px',
})

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
    whiteSpace: 'pre-line',
    textWrap: 'wrap',
  }),
})

const formControlLabelStyles: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.secondary.darker,
  border: 'none',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
  }),
  '.MuiFormControlLabel-label': {
    border: 'none',
    color: theme.vars.palette.secondary.darker,
    ...theme.applyStyles('dark', {
      color: theme.vars.palette.common.white,
    }),
    padding: '0',
    margin: '4px',
  },
})

const checkboxStyles: SxProps<Theme> = (theme: Theme) => ({
  visibility: 'visible',
  position: 'relative',
  color: theme.vars.palette.secondary.darker,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
  }),
  appearance: 'none',
  '&.Mui-checked + span': {
    backgroundColor: 'transparent',
    color: theme.vars.palette.secondary.darker,
    ...theme.applyStyles('dark', {
      color: theme.vars.palette.common.white,
    }),
  },
  '.MuiSvgIcon-root': {
    fill: theme.vars.palette.secondary.darker,
    ...theme.applyStyles('dark', {
      fill: theme.vars.palette.text.primary,
    }),
  },
})

const FeedbackForm = ({ answerId }: AnswerDetailsProps) => {
  const [answerFeedbackText, setAnswerFeedbackText] = useState<string>(
    'Was this answer helpful?',
  )
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const initalFormValues = {
    isAnswerValuable: null,
    accurateInfo: false,
    relevantIndustry: false,
    actionable: false,
    shareable: false,
    upToDate: false,
    improvementSuggestion: null,
  }

  const formLabelOptions = [
    {
      name: 'accurateInfo',
      label: 'I found the information accurate',
    },
    {
      name: 'relevantIndustry',
      label: 'Was relevant to my industry',
    },
    {
      name: 'actionable',
      label: 'I found it actionable',
    },
    {
      name: 'shareable',
      label: 'It was information I can share with my team',
    },
    {
      name: 'upToDate',
      label: 'It was up-to-date',
    },
  ]

  const [addAssistantFeedback, { loading, error }] = useMutation(
    ADD_CYBER_ASSISTANT_FEEDBACK,
  )

  const handleSubmit = async (values) => {
    await addAssistantFeedback({
      onCompleted: () => {
        setFeedbackSubmitted(true)
      },
      onError: () => {
        setFeedbackSubmitted(true)
      },
      variables: {
        selectedCustomer,
        input: {
          predeterminedQuestion: answerId,
          ...values,
        },
      },
    })
  }

  const renderFeedbackStatus = () => {
    if (error) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            py: '40px',
          }}
        >
          <Typography
            fontWeight={600}
            variant="body1"
            color="red"
            sx={{ pb: '20px' }}
          >
            We were unable to submit feedback.
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Please refresh the page and try again.
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          py: '40px',
        }}
      >
        <Typography fontWeight={600} variant="body1" color="green">
          We appreciate the feedback!
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Thank you for making the product better.
        </Typography>
      </Box>
    )
  }

  const renderSubmitButton = () => {
    if (loading) {
      return <Loader />
    }

    return 'Submit'
  }

  const validationSchema = Yup.object({
    isAnswerValuable: Yup.boolean()
      .nullable()
      .required('You must indicate if the answer was valuable.'),

    improvementSuggestion: Yup.string()
      .nullable()
      .test(
        'is-suggestion-required',
        'Please provide a suggestion for improvement.',
        function (value) {
          const { isAnswerValuable } = this.parent
          if (isAnswerValuable === false && !value) {
            return this.createError({
              message: 'Please provide a suggestion for improvement.',
            })
          }
          if (value && value.length > 8192) {
            return this.createError({
              message: 'Suggestion must be less than 8192 characters.',
            })
          }
          return true
        },
      ),
  })

  return (
    <Box
      sx={{
        overflowY: 'auto',
        py: '16px',
      }}
    >
      {feedbackSubmitted ? (
        renderFeedbackStatus()
      ) : (
        <Formik
          initialValues={initalFormValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ paddingTop: '40px' }}>
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    ...typographyContent(theme),
                    paddingBottom: '8px',
                  })}
                >
                  {answerFeedbackText}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {values.isAnswerValuable === false && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        multiline
                        name="improvementSuggestion"
                        onChange={handleChange}
                        placeholder="More information, additional context to my environment, etc"
                        value={values.improvementSuggestion || ''}
                        sx={{ width: '555px', pl: 0.25 }}
                      />
                      {touched.improvementSuggestion &&
                        errors.improvementSuggestion && (
                          <Typography
                            color="error"
                            variant="body2"
                            sx={{ marginTop: '4px' }}
                          >
                            {errors.improvementSuggestion}
                          </Typography>
                        )}
                      <Button
                        variant="contained"
                        sx={{ maxWidth: 'fit-content', mt: 2 }}
                        type="submit"
                        disabled={loading || !values.improvementSuggestion}
                      >
                        {renderSubmitButton()}
                      </Button>
                    </Box>
                  )}
                  {values.isAnswerValuable === true && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {formLabelOptions.map((option, i) => (
                        <Box key={option.label}>
                          <FormControlLabel
                            label={option.label}
                            sx={(theme) => ({
                              ...formControlLabelStyles(theme),
                            })}
                            control={
                              <Checkbox
                                id={`feedback-like-option-${i}`}
                                name={option.name}
                                checked={values[option.name]}
                                hidden={true}
                                onChange={handleChange}
                                sx={checkboxStyles}
                              />
                            }
                          />
                        </Box>
                      ))}
                      {touched.accurateInfo && errors.accurateInfo && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ marginTop: '4px' }}
                        >
                          {errors.accurateInfo}
                        </Typography>
                      )}
                      <Button
                        variant="contained"
                        sx={{ maxWidth: 'fit-content', mt: 2 }}
                        disabled={
                          loading ||
                          ![
                            'accurateInfo',
                            'relevantIndustry',
                            'actionable',
                            'shareable',
                            'upToDate',
                          ].some((checkbox) => values[`${checkbox}`] === true)
                        }
                        type="submit"
                      >
                        {renderSubmitButton()}
                      </Button>
                    </Box>
                  )}
                  {values.isAnswerValuable === null && (
                    <>
                      <Button
                        variant="contained"
                        sx={(theme) => ({ ...ButtonStyle(theme) })}
                        onClick={() => {
                          setFieldValue('isAnswerValuable', true)
                          setAnswerFeedbackText('What did you like?')
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        sx={(theme) => ({ ...ButtonStyle(theme) })}
                        onClick={() => {
                          setFieldValue('isAnswerValuable', false)
                          setAnswerFeedbackText('How can we improve?')
                        }}
                      >
                        No
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  )
}

export default FeedbackForm
