import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import {
  ChangeHistoryEventTableItem,
  ChangeHistoryType,
} from '@models/SecurityIndex'
import { getIconVariant } from '@components/Home/SecurityIndex/SecurityIndex.utils'
import Icon from '@common/Icon'

const ActionCell = (
  props: CellContext<ChangeHistoryEventTableItem, string>,
) => {
  // TODO: This is temporary since the backend is still using log source and not source type. Will be removed later.
  const eventText = () => {
    if (props.getValue() === 'log source added') {
      return 'source type added'
    }
    if (props.getValue() === 'log source removed') {
      return 'source type removed'
    }
    return props.getValue()
  }

  const { action, type } = props.row.original

  if (props.row.depth > 0) {
    return null
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Icon
        variant={getIconVariant(type)}
        size={18}
        sx={(theme) => ({
          color:
            action || type === ChangeHistoryType.APP_VERSION_CHANGE
              ? theme.vars.palette.text.primary
              : theme.vars.palette.error.light,
          marginRight: '0.5rem',
          verticalAlign: 'top',
          ...theme.applyStyles('dark', {
            color:
              action || type === ChangeHistoryType.APP_VERSION_CHANGE
                ? theme.vars.palette.text.secondary
                : theme.vars.palette.error.light,
          }),
        })}
      />
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {eventText()}
      </Typography>
    </Box>
  )
}

export default ActionCell
