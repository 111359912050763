import { Box, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ComponentError } from '@common/ComponentError'
import {
  SidesheetDualColumnValues,
  SidesheetDualColumn,
} from '@common/SidesheetDualColumn'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import { EnvironmentHealthBan } from '@queries/environmentHealth'
import { ErrorLabel } from '@common/ErrorLabel'
import { useCustomer } from '@hooks/useCustomer'

import { TopSourceTypeUtilizationDeviationTable } from '../TopSourceTypeUtilizationDeviationTable'

interface EnvHealthSideSheetContentProps {
  isOpen: boolean
  data: EnvironmentHealthBan | null
}

const EnvHealthSideSheetContent: React.FC<EnvHealthSideSheetContentProps> = ({
  data,
  isOpen,
}) => {
  const {
    customer: { splunkLicenseType, splunkDeploymentModel },
  } = useCustomer()
  const { featureDwLicense } = useFlags()

  if (!data) {
    return <ComponentError errorContainerStyles={{ height: '100%' }} />
  }

  const dualColumnItems: SidesheetDualColumnValues[] = [
    {
      key: 'License type',
      label: 'License type',
      value: splunkLicenseType ?? <ErrorLabel iconSize={16} fontSize={14} />,
    },
    {
      key: 'Deployment model',
      label: 'Deployment model',
      value: splunkDeploymentModel ?? (
        <ErrorLabel iconSize={16} fontSize={14} />
      ),
    },
    {
      key: featureDwLicense ? 'Deepwatch license' : 'Current license capacity',
      label: featureDwLicense
        ? 'Deepwatch license'
        : 'Current license capacity',
      value: `${data.licenseCapacity} GB`,
    },
    {
      key: 'Average usage',
      label: 'Average usage',
      value: `${Math.round(data.avgLicenseUsage)} GB`,
    },
    {
      key: '# of Overages',
      label: '# of overages',
      value: data.daysOverLicensePeriod,
    },
  ]

  return (
    <Box>
      <CollapsiblePanel
        data-testid="eh-side-sheet-details"
        title="Details"
        shouldAllowFocus={isOpen}
      >
        <SidesheetDualColumn items={dualColumnItems} />
      </CollapsiblePanel>
      <CollapsiblePanel
        title="Top source type usage deviation from average"
        shouldAllowFocus={isOpen}
      >
        <TopSourceTypeUtilizationDeviationTable
          data={data.topSourceTypesUsageDeviation}
        />
      </CollapsiblePanel>
      {data.licenseCapacity < 100 && (
        <CollapsiblePanel
          title="What happens if I have an overage?"
          shouldAllowFocus={isOpen}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            If you exceed your license over 45 times in a rolling 60-day period,
            SIEM search will be turned off and alerts will not be accurate.
          </Typography>
        </CollapsiblePanel>
      )}
    </Box>
  )
}

export default EnvHealthSideSheetContent
