export enum BetaAgreementStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type Customer = {
  customerShortName: string
  customerName: string
  maturityScoreStartDate?: string
  customerDwaAppVersion?: string
  deepwatchLatestDwaAppVersion?: string
  settings?: { isOktaFederated: boolean }
  numOfEmployees?: number
  industry?: string
  address?: CustomerAddress
  splunkLink?: string
  splunkLicenseType?: string
  splunkDeploymentModel?: string
  splunkVersion?: string
  edrEnabled?: boolean
  fwEnabled?: boolean
  mdrEnabled?: boolean
  threatSignalEnabled?: boolean
  vmEnabled?: boolean
  ngMdrEnabled?: boolean
  ngMedrEnabled?: boolean
  ngMfwEnabled?: boolean
  crowdstrikeUrl?: string
  sentinelEnabled?: boolean
  sentinelUrl?: string
  isLimitedMdrExperience?: boolean
  betaAgreementStatus?: BetaAgreementStatus | null
  serviceNgMedrTechnology?: string
}

export type CustomerAddress = {
  street: string
  city: string
  state: string
  postalCode: number
}
