import { CustomLayerProps, ResponsiveLine, Serie } from '@nivo/line'
import { DotsItem } from '@nivo/core'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Box, Typography, useColorScheme, useTheme } from '@mui/material'
import { cogOutline, serverOutline, pulseOutline } from 'ionicons/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CHANGE_HISTORY_LOG_SOURCE_REMOVED,
  ChangeHistoryType,
  FormattedSecurityIndexEvent,
  IndexTrends,
} from '@models/SecurityIndex'
import { useSecurityIndexContext } from '@hooks/useSecurityIndexContext'
import { ComponentError } from '@common/ComponentError'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

import SliceTooltip from './SliceTooltip/SliceTooltip'
import TrendSelector from './TrendSelector/TrendSelector'
import IndexDetails from './IndexDetails/IndexDetails'
import Indicator from './Indicator/Indicator'
import TrendSelectorPopover from './TrendSelector/TrendSelectorPopover'

interface IndexTrend {
  events: FormattedSecurityIndexEvent[] | null | undefined
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

export enum IndexTrendTitles {
  YOUR_INDEX = 'Your Index',
  AVG_DW_CUSTOMER = 'Avg. DW customer',
  AVG_INDUSTRY = 'Avg. Industry',
  PAST_PERIOD = 'Past period',
}

const trendKeyToTitle: { [Property in keyof IndexTrends]: string } = {
  yourTrend: IndexTrendTitles.YOUR_INDEX,
  avgCustomerTrend: IndexTrendTitles.AVG_DW_CUSTOMER,
  avgIndustryTrend: IndexTrendTitles.AVG_INDUSTRY,
  pastPeriodTrend: IndexTrendTitles.PAST_PERIOD,
}

export const domParser = new DOMParser()
const xmlSerializer = new XMLSerializer()

const mapAndFlattenSerieDatumSortedByDateAsc = (data: readonly Serie[]) =>
  data
    .flatMap((serie) => serie.data.map((datum) => datum.x as Date))
    .sort((a, b) => a.getTime() - b.getTime())

const IndexTrend: React.FC<IndexTrend> = ({
  events,
  selectedTrendEventState,
}) => {
  const [selectedTrendDate, setSelectedTrendEvent] = selectedTrendEventState
  const { yourIndex, yourIndexError } = useSecurityIndexContext()

  const { mode } = useColorScheme()
  const theme = useTheme()
  const { featureRemoveTogglePopovers } = useFlags()

  const trends = yourIndex?.trends
  const [
    yourTrendSerie,
    avgCustomerTrendSerie,
    avgIndustryTrendSerie,
    pastPeriodTrendSerie,
  ] = useMemo(
    () =>
      Object.keys(trendKeyToTitle).map((key) => ({
        id: trendKeyToTitle[key],
        data: trends?.[key]
          .map(
            ({ metricDate, index }: { metricDate: string; index: number }) => ({
              x: new Date(`${metricDate}T00:00`),
              y: index,
            }),
          )
          .sort((a, b) => a.x.getTime() - b.x.getTime()),
      })),
    [trends],
  )
  const [chartTrends, setChartTrends] = useState<Serie[]>([])

  useEffect(() => {
    const newChartTrends: Serie[] = []
    if (chartTrends.some(({ id }) => id === IndexTrendTitles.AVG_DW_CUSTOMER)) {
      newChartTrends.push(avgCustomerTrendSerie)
    }
    if (chartTrends.some(({ id }) => id === IndexTrendTitles.AVG_INDUSTRY)) {
      newChartTrends.push(avgIndustryTrendSerie)
    }
    if (chartTrends.some(({ id }) => id === IndexTrendTitles.PAST_PERIOD)) {
      newChartTrends.push(pastPeriodTrendSerie)
    }
    setChartTrends(newChartTrends)
  }, [trends])

  /**
   * Custom Nivo chart layer which draws the green "Your Index" line on top of the dark gray one
   * highlighting interaction with the event indicators at a given point in time.
   * @param props
   */
  const yourIndexLineOverlay = (props: CustomLayerProps) => {
    const yourIndexPoints = props.points.filter((point) =>
      selectedTrendDate
        ? point.serieId === IndexTrendTitles.YOUR_INDEX &&
          point.data.x <= selectedTrendDate
        : point.serieId === IndexTrendTitles.YOUR_INDEX,
    )
    if (yourIndexPoints.length) {
      const allTrendDatesSortedAsc = mapAndFlattenSerieDatumSortedByDateAsc(
        props.data as Serie[],
      )
      const latestSortedDate =
        allTrendDatesSortedAsc[allTrendDatesSortedAsc.length - 1]
      const yourIndexCoordinates = yourIndexPoints.map(({ x, y }) => ({
        x: x,
        y: y,
      }))
      const latestDataPoint = yourIndexPoints[yourIndexPoints.length - 1]
      const latestYourIndexTrendDate = latestDataPoint.data.x as Date
      return (
        <g>
          <path
            d={props.lineGenerator(yourIndexCoordinates) ?? undefined}
            fill="none"
            stroke={trendColors[IndexTrendTitles.YOUR_INDEX]}
            style={{ pointerEvents: 'none' }}
          />
          {!selectedTrendDate ||
          latestYourIndexTrendDate.getTime() === latestSortedDate.getTime() ? (
            <DotsItem
              x={latestDataPoint.x}
              y={latestDataPoint.y}
              datum={latestDataPoint.data}
              size={props.pointSize ?? 0}
              color={trendColors[IndexTrendTitles.YOUR_INDEX]}
              borderWidth={props.pointBorderWidth ?? 0}
              borderColor={latestDataPoint.borderColor}
              labelYOffset={props.pointLabelYOffset}
            />
          ) : undefined}
        </g>
      )
    }
  }

  /**
   * Custom Nivo chart layer that adds the bottom (x) axis tick extrema in the desired format
   * @param props
   */
  const xAxisExtrema = (props: CustomLayerProps) => {
    if (props.points.length) {
      const allTrendDatesSortedAsc = mapAndFlattenSerieDatumSortedByDateAsc(
        props.data,
      )
      return (
        <Box component="g">
          <Box
            component="text"
            sx={(theme) => ({
              fontSize: theme.typography.caption.fontSize,
            })}
            x={4}
            y={props.innerHeight + 8 + 17}
            fill={theme.vars.palette.text.primary}
          >
            {format(allTrendDatesSortedAsc[0], 'MMM d')}
          </Box>
          <Box
            component="text"
            sx={(theme) => ({
              direction: 'rtl',
              fontSize: theme.typography.caption.fontSize,
            })}
            x={props.innerWidth - 4}
            y={props.innerHeight + 8 + 17}
            fill={theme.vars.palette.text.primary}
          >
            {format(
              allTrendDatesSortedAsc[allTrendDatesSortedAsc.length - 1],
              'MMM d',
            )}
          </Box>
        </Box>
      )
    }
  }

  const yourTrendEventIndicators = (props: CustomLayerProps) => {
    const gap = 2
    const polygonHeight = 7
    const rectPadding = 4
    const rectHeight = 14
    const rectWidth = 14
    const fontHeight = 14 //? appx. 14px height with 12pt Inter, sans-serif font type
    return props.points
      .filter((point) => point.serieId === IndexTrendTitles.YOUR_INDEX)
      .map((point) => {
        const xDate = point.data.x as Date
        let indicatorRectWidth = rectWidth
        const pointEvents = events?.filter(
          (event) => event.createdAt.getTime() === xDate.getTime(),
        )
        const isIcon = pointEvents?.length === 1
        if (pointEvents?.length) {
          /**
           * Calculate the padding needed for the text element
           * to be positioned in the center of the indicator
           */
          const digits = String(pointEvents.length).length
          const textWidth = digits * 3.5 //? this may not be completely accurate where digits > 3 or those that contain multiple '1's due to kerning
          indicatorRectWidth = digits < 2 ? rectWidth : digits * 7
          let innerHTML = `<text style="font:12px Inter, sans-serif" x="${point.x - textWidth}" y="${
            point.y -
            gap -
            polygonHeight -
            (rectHeight + rectPadding) +
            fontHeight
          }">${pointEvents.length}</text>` //? bottom spacing from trend line (gap) - height of indicator polygon/arrow - rectHeight and its top and bottom padding + the height of the text
          if (isIcon) {
            let sVGDataString = ''
            if (pointEvents[0].type === ChangeHistoryType.APP_VERSION_CHANGE) {
              sVGDataString = cogOutline
            } else if (pointEvents[0].type === ChangeHistoryType.LOG_SOURCE) {
              sVGDataString = serverOutline
            } else {
              sVGDataString = pulseOutline
            }

            innerHTML = sVGDataString.split(',')[1] //remove Data URL

            const svgDocument = domParser.parseFromString(
              innerHTML,
              'image/svg+xml',
            )
            const svg = svgDocument.children.item(0)
            svg?.setAttribute('width', '14px')
            svg?.setAttribute('height', '14px')
            svg?.setAttribute('x', String(point.x - rectWidth / 2))
            svg?.setAttribute(
              'y',
              String(
                point.y - gap - polygonHeight - rectHeight - rectPadding / 2,
              ),
            )
            svg?.setAttribute(
              'data-testid',
              String(
                pointEvents[0].type === ChangeHistoryType.APP_VERSION_CHANGE
                  ? 'version-change'
                  : pointEvents[0].type,
              ),
            )
            innerHTML = xmlSerializer.serializeToString(svgDocument)
          }

          return (
            <Indicator
              key={point.id}
              x={point.x}
              y={point.y}
              rectWidth={indicatorRectWidth}
              groupClassNames={{
                pink:
                  isIcon &&
                  pointEvents[0].type !==
                    ChangeHistoryType.APP_VERSION_CHANGE &&
                  pointEvents[0].action === CHANGE_HISTORY_LOG_SOURCE_REMOVED,
                active: xDate.getTime() === selectedTrendDate?.getTime(),
                inactive:
                  selectedTrendDate &&
                  xDate.getTime() !== selectedTrendDate.getTime(),
              }}
              innerHTML={innerHTML}
              onClick={() => {
                xDate.getTime() === selectedTrendDate?.getTime()
                  ? setSelectedTrendEvent(undefined)
                  : setSelectedTrendEvent(xDate)
              }}
            />
          )
        }
      })
  }

  const toggleTrend = (trendToToggle: string, active: boolean): void => {
    if (active) {
      switch (trendToToggle) {
        case IndexTrendTitles.AVG_DW_CUSTOMER:
          setChartTrends([...chartTrends, avgCustomerTrendSerie])
          return

        case IndexTrendTitles.AVG_INDUSTRY:
          setChartTrends([...chartTrends, avgIndustryTrendSerie])
          return

        case IndexTrendTitles.PAST_PERIOD:
          setChartTrends([...chartTrends, pastPeriodTrendSerie])
          return
      }
    }
    const filterTrend = chartTrends.filter(
      (trend) => trend.id !== trendToToggle,
    )
    setChartTrends(filterTrend)
  }

  const chartData = [yourTrendSerie, ...chartTrends]

  const trendColors = {
    [IndexTrendTitles.YOUR_INDEX]: theme.vars.palette.success.main,
    [IndexTrendTitles.AVG_DW_CUSTOMER]: theme.vars.palette.important.main,
    [IndexTrendTitles.AVG_INDUSTRY]: theme.vars.palette.warning.main,
    [IndexTrendTitles.PAST_PERIOD]: theme.vars.palette.text.primary,
  }

  const dateFilterableStyles = useDateFilterableStyles({
    getDefaultBorderColor: (theme) => ({
      light: theme.vars.palette.secondary.main,
      dark: theme.vars.palette.secondary.lighter,
    }),
  })

  const getLineColors = (availableTrends: Serie[]): string[] => {
    return availableTrends.map(({ id }) => {
      if (id === IndexTrendTitles.YOUR_INDEX) {
        return theme.vars.palette.secondary.darker
      }

      // eslint-disable-next-line security/detect-object-injection
      return trendColors[id]
    })
  }

  const renderContent = () => {
    return (
      <Box
        className="trend-container"
        sx={[
          {
            position: 'relative',
            width: '99%',
            height: '340px',
            backgroundColor: theme.vars.palette.secondary.light,
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            borderRadius: '5px',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.main,
            }),
          },
          dateFilterableStyles,
        ]}
      >
        {yourIndexError || !events ? (
          <ComponentError errorContainerStyles={{ height: '100%' }} />
        ) : (
          <ResponsiveLine
            curve="linear"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: theme.vars.palette.text.primary,
                  },
                  line: {
                    strokeWidth: 0,
                  },
                },

                domain: {
                  line: {
                    stroke:
                      // eslint-disable-next-line @getify/proper-ternary/nested
                      mode === 'dark'
                        ? theme.vars.palette.secondary.lighter
                        : theme.vars.palette.secondary.main,
                    strokeWidth: 1,
                  },
                },
              },
              grid: {
                line: {
                  strokeWidth: 0,
                },
              },
            }}
            data={chartData}
            enableGridX={false}
            enableGridY
            xScale={{ type: 'time', precision: 'day' }}
            margin={{ top: 40, right: 30, bottom: 40, left: 40 }}
            lineWidth={1.25}
            colors={getLineColors(chartData)}
            enableSlices="x"
            enablePoints
            axisBottom={{
              tickValues: 0,
            }}
            axisLeft={{
              format: '>-0.1f',
              tickValues: 10,
            }}
            layers={[
              'axes',
              'areas',
              'crosshair',
              'lines',
              'mesh',
              'slices',
              'grid',
              yourIndexLineOverlay,
              xAxisExtrema,
              yourTrendEventIndicators,
            ]}
            sliceTooltip={({ slice }) => (
              <SliceTooltip slice={slice} boxColors={trendColors} />
            )}
          />
        )}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '1rem',
        width: '100%',
        gap: '11px',
        '@media screen and (max-width: 768px)': {
          flexDirection: 'column',
        },
      }}
      data-testid="index-trend"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight={600} variant="body1">
            Index trend
          </Typography>
          {featureRemoveTogglePopovers ? (
            <TrendSelector setChartTrends={toggleTrend} />
          ) : (
            <TrendSelectorPopover setChartTrends={toggleTrend} />
          )}
        </Box>
        {renderContent()}
      </Box>
      <IndexDetails />
    </Box>
  )
}

export default IndexTrend
