import { useCallback, useState } from 'react'
import { debounce } from 'throttle-debounce'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import Icon from '@common/Icon'

interface Props {
  initialValue: string | undefined
  updateSearchValue(value: string): void
  onSearchDelete(): void
}

export default function ThreatIntelReportSearch({
  initialValue,
  updateSearchValue,
  onSearchDelete,
}: Props) {
  const [searchValue, setSearchValue] = useState(initialValue || '')

  /**
   * Have to pass the debounce directly as the callback in useCallback
   * so the debounce doesn't get reset. If you do an inline function
   * returning the debounce function it won't work since it will
   * clear the pending debounce
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateSearch = useCallback(debounce(600, updateSearchValue), [
    updateSearchValue,
  ])

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    setSearchValue(value)

    debounceUpdateSearch(value)
  }

  const handleSearchInputDelete = () => {
    setSearchValue('')
    onSearchDelete()
  }

  return (
    <div
      className="threat-intel-report-filter"
      data-testid="threat-intel-report-filter"
    >
      <TextField
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Icon variant="searchOutline" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!searchValue}
                  onClick={handleSearchInputDelete}
                >
                  <Icon variant="close" />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        id="threatIntelReportFilter"
        name={'threatIntelReportFilter'}
        type={'text'}
        onChange={handleSearchUpdate}
        placeholder={'Search reports'}
        value={searchValue}
      />
    </div>
  )
}
