import { ApolloError } from '@apollo/client'

import {
  Ticket,
  TicketPriorityEnum,
  TicketStateEnum,
  TicketEditEnum,
} from '@models/index'
import { UserInfo } from '@models/User'
import { TicketDataInput } from '@mutations/ticket'

export type TicketStatusData = Pick<
  Ticket,
  | 'assignedUser'
  | 'assignedUserEmail'
  | 'impact'
  | 'priority'
  | 'state'
  | 'customerValidateState'
  | 'lastUpdateUser'
  | 'sysCreatedOn'
  | 'assignedToDeepwatch'
  | 'ticketNumber'
>

//NOTE: available fields for v1
export type AdditionalDetailsRow = {
  cisControl?: string | null
  incidentType?: string | null
  splunkLink?: string | null
  documentationLink?: string | null
  soarLink?: string | null
  threatHuntOutcomes?: string | null
  ioc?: string | null
  __typename?: string
}

export type TicketContactData = Pick<
  Ticket,
  | 'serviceOffering'
  | 'assignmentGroup'
  | 'category'
  | 'subcategory'
  | 'channel'
  | 'caseType'
  | 'contactUser'
  | 'agentType'
>

export interface TicketResolutionFormProps {
  resolutionCodeEnum: TicketEditEnum[]
  sysId: string
  closeModal?: VoidFunction
  data?: TicketResolutionData
  draftTicketFields?: Partial<TicketDataInput>
  inModal?: boolean
  onChange?: (ticketFields: Partial<TicketDataInput>) => void
  ticketNumber?: string
  resetDraftTicketFields?: VoidFunction
  readonly?: boolean
}

export type TicketResolutionFormValues = {
  resolutionCode: string
  resolutionNotes: string
}

export type TicketResolutionData = Pick<
  Ticket,
  'resolutionCode' | 'resolutionNotes' | 'state'
>

export enum TicketActivityTypeEnum {
  FieldChanges = 'Field changes',
  Comment = 'Additional comments',
  Placeholder = 'Placeholder',
}

export type FieldValues = {
  previousValue?: string
  value: string
}

export type FieldChange = {
  priority: {
    previousValue?: TicketPriorityEnum
    value: TicketPriorityEnum
  }
  state: {
    previousValue?: TicketStateEnum
    value: TicketStateEnum
  }
  customerValidateState: FieldValues
  contactUser: FieldValues
}

export type ClientSideErrors = {
  customerValidateState?: string
}

export interface BaseTicketActivity {
  createdDate: string
  id: string
  userInfo: UserInfo
}

export interface TicketFieldChangeActivity extends BaseTicketActivity {
  fieldChanges: Partial<FieldChange>[]
  type: TicketActivityTypeEnum.FieldChanges
}

export interface TicketCommentActivity extends BaseTicketActivity {
  error?: ApolloError
  status?: 'pending' | 'error' | 'created'
  text: string
  type: TicketActivityTypeEnum.Comment
}

export interface TicketPlaceholderActivity extends BaseTicketActivity {
  type: TicketActivityTypeEnum.Placeholder
  text: string
}

export type TicketActivityType =
  | TicketFieldChangeActivity
  | TicketCommentActivity
  | TicketPlaceholderActivity

export type TicketEditContextModel = {
  sysId: string
  hasValidChangesToSave: boolean
  saveDraftTicketFields: (ticketFields: Partial<Ticket>) => void
  isSubmissionLoading: boolean
  submissionError: ApolloError | undefined
  submitDraftTicketFields(): Promise<void>
  isFetchLoading: boolean
  fetchError: ApolloError | undefined
  isEditable: boolean | undefined
  draftTicketFields: Partial<TicketDataInput>
  resetDraftTicketFields: VoidFunction
  clientSideErrors: ClientSideErrors
  resetClientSideErrorsField: (field?: string) => void
  isMutationSuccessful: boolean
}
