import { Box, Typography } from '@mui/material'

import Icon from '@common/Icon'

interface NoResultsProps {
  textColor?: string
  description?: string
  descriptionTextColor?: string
}

const NoResults = ({
  textColor,
  description,
  descriptionTextColor,
}: NoResultsProps) => {
  return (
    <Box
      id="no-results-found"
      data-testid="no-results-found"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Icon
        size={250}
        variant="noResults"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          fill: theme.vars.palette.secondary.main,
        })}
      />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="h5" color={textColor}>
          No results found
        </Typography>
        {description && (
          <Typography
            variant="body2"
            color={descriptionTextColor}
            sx={{ paddingTop: 0.5 }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default NoResults
