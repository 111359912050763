import { AppAction, ActionTypes, Paths, Terms, AppState } from '../Types'

export const setGlobalLoading = (globalLoading: boolean): AppAction => ({
  type: ActionTypes.SET_GLOBAL_LOADING,
  globalLoading,
})

export const setHomePath = (homePath: Paths): AppAction => ({
  type: ActionTypes.SET_HOME_PATH,
  homePath,
})

export const setDateFilter = (
  dateFilter: Partial<AppState['dateFilter']>,
): AppAction => ({
  type: ActionTypes.SET_DATE_FILTER,
  dateFilter,
})

export const setTermsAccepted = (termsAccepted: Terms): AppAction => ({
  type: ActionTypes.SET_TERMS_ACCEPTED,
  termsAccepted,
})
