import React from 'react'
import { Card } from '@mui/material'

import { ComponentError, ComponentErrorProps } from '@common/ComponentError'

export enum ComponentErrorType {
  GENERIC = 'GENERIC',
  NO_DATA = 'NO_DATA',
}

export interface ErrorCardProps extends ComponentErrorProps {
  errorType?: ComponentErrorType
}

export const componentErrorContent: Record<
  ComponentErrorType,
  Pick<ComponentErrorProps, 'errorText' | 'errorSubText'>
> = {
  [ComponentErrorType.GENERIC]: {
    errorText: 'Something went wrong.',
    errorSubText: 'Please reload the page and try again.',
  },
  [ComponentErrorType.NO_DATA]: {
    errorText: 'No data.',
    errorSubText:
      'There are too many data points that are incomplete or missing. Please check again later.',
  },
}

export const ErrorCard: React.FC<ErrorCardProps> = ({
  errorType = ComponentErrorType.GENERIC,
}) => {
  const { errorText, errorSubText } =
    componentErrorContent[errorType as keyof typeof componentErrorContent]

  return (
    <Card
      data-testid="card-component-error"
      sx={(theme) => ({
        alignItems: 'center',
        backgroundColor: theme.vars.palette.secondary.light,
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        boxShadow: 'none',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.main,
          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
        }),
      })}
    >
      <ComponentError errorText={errorText} errorSubText={errorSubText} />
    </Card>
  )
}
