import { Box, Theme, Typography, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ModuleStatus, ModuleUnion } from '@queries/modules'

import { ModuleCardBodyAvailableText } from './ModuleCardBodyAvailableText'
import {
  formatActiveModuleProperties,
  formatNullableField,
} from './ModuleCard.utils'

const generateModuleCardBodyCssFromTheme = (theme: Theme) => ({
  cardBody: {
    gap: '1.5rem',
  },
  cardBodyActive: {
    display: 'flex',
    '.technology-icon-container': {
      display: 'flex',
      width: '18px',
      height: '18px',
      padding: '0 2.389px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '3px',
    },
  },
  cardBodyAvailable: {
    display: 'grid',
    ul: {
      paddingLeft: '1rem',
      li: {
        paddingBottom: '.5rem',
        '&:nth-last-child(1)': {
          paddingBottom: 0,
        },
      },
      'li::marker': {
        color: theme.vars.palette.text.primary,
      },
    },
  },
})

type ModuleCardBodyProps = {
  module: ModuleUnion
}

const ModuleCardBody: React.FC<ModuleCardBodyProps> = ({ module }) => {
  const theme = useTheme()
  const moduleCardBodyCss = generateModuleCardBodyCssFromTheme(theme)
  const { featureNgMdr, featureDwLicense } = useFlags()

  if (
    module.status === ModuleStatus.ACTIVE ||
    module.status === ModuleStatus.ONBOARDING
  ) {
    const moduleProperties = formatActiveModuleProperties(
      module,
      featureDwLicense,
    )
    return (
      <Box
        sx={[
          moduleCardBodyCss.cardBody,
          moduleCardBodyCss.cardBodyActive,
          {
            justifyContent:
              moduleProperties.length > 2 ? 'space-between' : 'flex-start',
          },
        ]}
        data-testid="module-card-body"
      >
        {moduleProperties.map((property, i) => (
          <Box
            key={`${module.type}-${property.title}-${i}`}
            data-testid="module-card-body-property"
          >
            <Typography fontWeight={500} variant="body2">
              {property.title}
            </Typography>
            {typeof property.value === 'string' ? (
              <Typography
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="body2"
              >
                {formatNullableField(property.value)}
              </Typography>
            ) : (
              formatNullableField(property.value)
            )}
          </Box>
        ))}
      </Box>
    )
  }

  if (!ModuleCardBodyAvailableText[module.type]) {
    return null
  }

  const supportedTechnologies =
    ModuleCardBodyAvailableText[module.type].supportedTechnologies?.slice(
      0,
      featureNgMdr ? undefined : -1,
    ) ?? [] // filter out MS Sentinel if feature flag is off

  return (
    <Box
      sx={[moduleCardBodyCss.cardBody, moduleCardBodyCss.cardBodyAvailable]}
      data-testid="module-card-body"
    >
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {ModuleCardBodyAvailableText[module.type]?.description}
      </Typography>
      {supportedTechnologies.length > 0 && (
        <Box data-testid="module-card-supported-technologies">
          <Typography
            fontWeight={600}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body1"
          >
            Supported Technologies:
          </Typography>
          <ul>
            {supportedTechnologies.map((technology, i) => (
              <li key={`${module.type}-${technology.split(' ')[0]}-${i}`}>
                <Typography variant="body2">{technology}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      <Box data-testid="module-card-key-features">
        <Typography
          sx={{
            paddingBottom: '0.5rem',
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          }}
          fontWeight={600}
          variant="body1"
        >
          Key Features:
        </Typography>
        <ul>
          {ModuleCardBodyAvailableText[module.type]?.keyFeatures.map(
            (feature, i) => {
              const [title, content] = feature.split(':')

              return (
                <li key={`${module.type}-${feature.split(' ')[0]}-${i}`}>
                  <Typography component="span">
                    <Typography
                      component="span"
                      fontWeight={600}
                      variant="body2"
                      sx={(theme) => ({
                        color: theme.vars.palette.text.primary,
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      })}
                    >
                      {title}:
                    </Typography>{' '}
                    <Typography component="span" variant="body2">
                      {content?.trim()}
                    </Typography>
                  </Typography>
                </li>
              )
            },
          )}
        </ul>
      </Box>
      {!!ModuleCardBodyAvailableText[module.type]?.dependencies.length && (
        <Box data-testid="module-card-dependencies">
          <Typography
            fontWeight={600}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            Dependencies:
          </Typography>
          <ul>
            {ModuleCardBodyAvailableText[module.type].dependencies.map(
              (dependency, i) => (
                <li key={`${module.type}-${dependency.split(' ')[0]}-${i}`}>
                  <Typography variant="body2" color="textPrimary">
                    {dependency}
                  </Typography>
                </li>
              ),
            )}
          </ul>
        </Box>
      )}
    </Box>
  )
}

export default ModuleCardBody
