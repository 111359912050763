import { FormControlLabel, Switch } from '@mui/material'

export interface SuToggleProps {
  disablePopover: boolean
  pastPeriodChart: boolean
  setPastPeriodChart: React.Dispatch<React.SetStateAction<boolean>>
}

const SuToggle: React.FC<SuToggleProps> = ({
  pastPeriodChart,
  setPastPeriodChart,
}) => {
  return (
    <FormControlLabel
      className="switch-label"
      tabIndex={0}
      labelPlacement="start"
      onKeyUp={() => setPastPeriodChart(!pastPeriodChart)}
      sx={(theme) => ({
        '.MuiFormControlLabel-label': {
          color: theme.palette.text.primary,
        },
        margin: '0 8px 0',
        padding: '12px',
      })}
      control={
        <Switch
          onClick={() => setPastPeriodChart(!pastPeriodChart)}
          checked={pastPeriodChart}
          sx={{
            marginLeft: '8px',
          }}
        />
      }
      label="Compare to previous period"
    />
  )
}

export default SuToggle
