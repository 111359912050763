import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'

import { useAwaitedCustomer } from '@hooks/useCustomer'
import { BetaAgreementModal } from '@components/BetaAgreementModal/BetaAgreementModal'
import { BetaAgreementStatus } from '@models/Customer'

interface BetaFeaturesStatusChipProps {
  betaAgreementStatus?: BetaAgreementStatus | null
}

const BetaFeaturesStatusChip: React.FC<BetaFeaturesStatusChipProps> = ({
  betaAgreementStatus,
}) => {
  const betaFeaturesStatus =
    betaAgreementStatus === BetaAgreementStatus.ACCEPTED
      ? 'enabled'
      : 'disabled'
  return (
    <Typography
      component="span"
      sx={{
        border: '1px solid',
        borderRadius: 4,
        px: 1,
        py: 0.5,
        borderColor: ({ palette }) =>
          palette.mode === 'light'
            ? palette.secondary.dark
            : palette.secondary.lighter,
      }}
    >
      {betaFeaturesStatus}
    </Typography>
  )
}

export const BetaFeaturesSection: React.FC = () => {
  const { betaAgreementStatus } = useAwaitedCustomer()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Box data-testid="beta-features-section">
      <Typography
        pb={1}
        fontWeight={600}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        Beta Features
      </Typography>
      <Typography pb={2}>
        Beta features are currently{' '}
        <BetaFeaturesStatusChip betaAgreementStatus={betaAgreementStatus} /> for
        your organization.
      </Typography>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        CHANGE
      </Button>
      <BetaAgreementModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  )
}
