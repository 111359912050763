// since keywordSearch is just a string, this function will return it as an array of one string
// so that it is easier to work with when returning the JSX

import { Typography } from '@mui/material'

export const convertKeywordSearchToArray = (searchString: string): string[] => {
  if (searchString.trim() === '') {
    return []
  } else {
    return [searchString]
  }
}

const filterGroupToChipLabel = {
  sysUpdatedOn: 'Last updated',
  sysCreatedOn: 'Age',
  state: 'Status',
  module: 'Module',
  mitreTactics: 'MITRE',
  labels: 'Label',
  useCase: 'Detection',
} as const

export const formatChipLabel = (
  filterGroup: string,
  filterValue: string,
  textColor?: React.CSSProperties['color'],
  fontSize?: number,
): React.ReactNode => {
  let label = filterGroup.charAt(0).toUpperCase() + filterGroup.slice(1)

  switch (filterGroup) {
    case 'keywordSearch':
      return (
        <Typography
          color={textColor}
          fontSize={fontSize}
        >{`"${filterValue}"`}</Typography>
      )
    default:
      // eslint-disable-next-line security/detect-object-injection
      label = filterGroupToChipLabel[filterGroup] ?? label
      break
  }

  return (
    <>
      <div className="filter-chip-label">
        <Typography color={textColor} fontSize={fontSize}>
          {`${label}:`}
        </Typography>
      </div>
      <Typography
        fontWeight={600}
        color={textColor}
        fontSize={fontSize}
        sx={{ paddingLeft: 0.25 }}
      >
        {filterValue}
      </Typography>
    </>
  )
}
