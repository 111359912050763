const Clipboard = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 7.5V6.108C8.25 4.973 9.095 4.01 10.226 3.916C10.599 3.886 10.974 3.859 11.349 3.836M15.75 18H18C18.5967 18 19.169 17.7629 19.591 17.341C20.0129 16.919 20.25 16.3467 20.25 15.75V6.108C20.25 4.973 19.405 4.01 18.274 3.916C17.9 3.88498 17.5256 3.85831 17.151 3.836M17.151 3.836C17.009 3.3767 16.7226 2.97493 16.3357 2.68954C15.9489 2.40414 15.4808 2.25011 15 2.25H13.5C13.0192 2.25011 12.5511 2.40414 12.1643 2.68954C11.7774 2.97493 11.492 3.3767 11.35 3.836C11.285 4.046 11.25 4.269 11.25 4.5V5.25H17.25V4.5C17.25 4.269 17.216 4.046 17.151 3.836ZM15.75 18.75V16.875C15.75 15.9799 15.3944 15.1215 14.7615 14.4885C14.1285 13.8556 13.2701 13.5 12.375 13.5H10.875C10.5766 13.5 10.2905 13.3815 10.0795 13.1705C9.86853 12.9595 9.75 12.6734 9.75 12.375V10.875C9.75 9.97989 9.39442 9.12145 8.76149 8.48851C8.12855 7.85558 7.27011 7.5 6.375 7.5H5.25M6.75 7.5H4.875C4.254 7.5 3.75 8.004 3.75 8.625V20.625C3.75 21.246 4.254 21.75 4.875 21.75H14.625C15.246 21.75 15.75 21.246 15.75 20.625V16.5C15.75 14.1131 14.8018 11.8239 13.114 10.136C11.4261 8.44821 9.13695 7.5 6.75 7.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Clipboard
