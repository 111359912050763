import { Ticket } from './Tickets'

export interface DataPoint {
  date: string
  gb: number
}

export interface DataVolume {
  name: string
  gb: number
}

export interface DeviationAmount {
  gb: number
  delta: number
}
export interface SourcetypeDeviation {
  logSource: string
  deviationAmount: DeviationAmount
}
export interface LicenseUtilization {
  licenseType: string
  deploymentModel: string
  licenseCapacity: number
  avgLicenseUsage: number
  daysOverLicensePeriod: number
  topSourceTypesUsageDeviation: SourcetypeDeviation[]
}

export type SourceType = {
  /** The date to which this usage corresponds */
  date: string
  /** Sourcetype name, or "other" */
  name: string
  /** Data volume usage for this source type on this date */
  gb: number
  sourceIP: string
  /** Average for the source type over the requested period; same across all dates for the period/source type */
  averageIngestOverPeriod: number
  /** Historical all-time average for this source type; same across all dates for the source type */
  avgIngestOverAllTime: number
  /** Deviation between the average over the period an the average over all time */
  ingestDeviation: DeviationAmount // TODO: this will be calculated on the frontend; we will need to implement a data transformation function to calculate this for all incoming data
  alerts: number
  /** The open tickets associated with this source type; same across all dates for the source type */
  openTickets: Ticket[]
  /** The index to which this usage belongs */
  index: string
}

export enum Metric {
  UTILIZATION = 'UTILIZATION',
  ALERTS = 'ALERTS',
}

export enum Dimension {
  DATE = 'DATE',
  SOURCETYPE = 'SOURCETYPE',
  INDEX = 'INDEX',
}

export enum Segment {
  NO_VALUE = 'NO_VALUE',
  SOURCETYPE = 'SOURCETYPE',
  INDEX = 'INDEX',
  DEVIATION = 'DEVIATION',
  AVERAGE = 'AVERAGE',
}

export type EnvironmentHealthUtilAlertsNoValue = {
  previousPeriodUtilization: DataPoint[]
  currentPeriod: {
    licenseCapacity: DataPoint[]
    topSourceTypes: SourceType[]
  }
}

export type SegmentedSourceType = {
  topSourceTypes: SourceType[]
  averageIngestVolumeOverPeriodBySourceType?: DataVolume[] // don't want to separate due to the grouping of "other" source types in the date range
  numSourceTypes?: number // segment index
}
