import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from '@mui/material'

import {
  ENVIRONMENT_HEALTH_DAILY_SOURCE_TYPES_DEVIATIONS,
  EnvironmentHealthDailySourceTypesDeviations,
  EnvironmentHealthForwarderCountsVariables,
} from '@queries/environmentHealth'
import { ErrorCard } from '@common/ErrorCard'
import { LoadingCard } from '@common/LoadingCard'

import { StatusDetailsCard } from './StatusDetailCard'

const DailySourceTypesDeviations: React.FC = () => {
  const theme = useTheme()
  const StatusDetailsColor = {
    GREEN: theme.palette.success.main,
    RED: theme.palette.error.main,
    ORANGE: theme.palette.severity?.high || '',
    YELLOW: theme.palette.severity?.medium || '',
    BLUE: theme.palette.severity?.low || '',
  }
  const [searchParams] = useSearchParams()
  const {
    data: { environmentHealthDailySourceTypesDeviations } = {
      environmentHealthDailySourceTypesDeviations: null,
    },
    error,
    loading,
  } = useQuery<
    EnvironmentHealthDailySourceTypesDeviations,
    EnvironmentHealthForwarderCountsVariables
  >(ENVIRONMENT_HEALTH_DAILY_SOURCE_TYPES_DEVIATIONS, {
    variables: {
      selectedCustomer: searchParams.get('customer'),
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading && !environmentHealthDailySourceTypesDeviations) {
    return <LoadingCard />
  } else if (error || !environmentHealthDailySourceTypesDeviations) {
    return <ErrorCard />
  }

  const { gt25Count, gt50Count, gte10Count, lt10Count } =
    environmentHealthDailySourceTypesDeviations

  return (
    <StatusDetailsCard
      label="Source types"
      breakdownLabel="Source type ingest deviation"
      tooltipLabel="Source type ingest deviation"
      breakdown={[
        {
          label: '> 50%',
          value: gt50Count,
          color: StatusDetailsColor.RED,
        },
        {
          label: '26% - 50%',
          value: gt25Count,
          color: StatusDetailsColor.ORANGE,
        },
        {
          label: '10% - 25%',
          value: gte10Count,
          color: StatusDetailsColor.YELLOW,
        },
        {
          label: '< 10%',
          value: lt10Count,
          color: StatusDetailsColor.BLUE,
        },
      ]}
    />
  )
}

export default DailySourceTypesDeviations
