import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionProps,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Icon from '@common/Icon'

export interface CollapsiblePanelProps {
  /** Children are rendered in the expanded panel */
  children: React.ReactNode
  /** Text content to use in the header of the collapsible panel */
  title: string
  /** If provided, this value displayed next to the header; if a zero-value count is supplied, the panel will be collapsed by default  */
  count?: number | string
  /** If `true`, the panel will default to a closed state; overrides the logic from the `count` prop */
  defaultClosed?: boolean
  /** Defaults to `true`; if set to `false`, sets a `-1` tab index on the panel; useful when rendering within a hidden sidesheet */
  shouldAllowFocus?: boolean
  /** Defaults to `true`; if set to `false`, the panel will not have a top border */
  borderTop?: boolean
  /** Optional styles applied to the outer `<Accordion />` component through the `sx` prop */
  accordionStyles?: AccordionProps['sx']
}

const borderTopNoneStyle = {
  '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
    '&:before': { opacity: 0 },
  },
}

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  children,
  title,
  count,
  defaultClosed,
  shouldAllowFocus = true,
  borderTop = true,
  accordionStyles,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  /**
   * When the `count` changes:
   *
   * Expand the panel if the count is not provided, or is non-zero.
   * Collapse the panel if the count is zero.
   *
   * This is to ensure panels with no content are collapsed by default.
   */
  useEffect(() => {
    const hasZeroCount =
      count !== undefined && typeof count === 'number' && count === 0
    setExpanded(!hasZeroCount && !defaultClosed)
  }, [count, defaultClosed])

  const summary = count !== undefined ? `${title} (${count})` : title

  return (
    <Accordion
      sx={[
        { padding: '0 1rem' },
        ...(Array.isArray(accordionStyles)
          ? accordionStyles
          : [accordionStyles]),
      ]}
      expanded={expanded}
      onChange={() => setExpanded((currentExpanded) => !currentExpanded)}
    >
      <AccordionSummary
        data-testid="collapsible-panel-header"
        sx={borderTop ? undefined : borderTopNoneStyle}
        id="panel-header"
        tabIndex={shouldAllowFocus ? 0 : -1}
        expandIcon={
          <Icon variant="chevronDown" size={18} sx={{ marginTop: '5px' }} />
        }
      >
        <Typography variant="body2">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails data-testid="collapsible-panel-children">
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
