const MDRLogo = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        id="paint0_linear_24227_35153"
        x1="21.5262"
        y1="1"
        x2="-0.248161"
        y2="19.8571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC368" />
        <stop offset="1" stopColor="#FF4D85" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24227_35153"
        x1="15.5859"
        y1="8.31641"
        x2="8.22486"
        y2="15.4858"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC368" />
        <stop offset="1" stopColor="#FF4D85" />
      </linearGradient>
    </defs>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.44338L10.4471 3.33996L9.66524 2.34797L12 1L14.2999 2.32782L13.518 3.31981L12 2.44338ZM18.6527 6.28432L19.5919 5.38317L21.5263 6.5V8.53906H20.2763V7.22169L18.6527 6.28432ZM20.2763 15.4643V16.7783L18.4443 17.836L19.3835 18.7372L21.5263 17.5V15.4643H20.2763ZM13.3445 20.7804L12 21.5566L10.5423 20.715L9.76046 21.707L12 23L14.1264 21.7723L13.3445 20.7804ZM5.42996 17.7634L3.72372 16.7783V15.4643H2.47372V17.5L4.4908 18.6646L5.42996 17.7634ZM3.72372 7.22169V8.53906H2.47372V6.5L4.37642 5.40148L5.31558 6.30263L3.72372 7.22169Z"
      fill="url(#paint0_linear_24227_35153)"
    />
    <path
      d="M11.2202 15.68L11.3672 13.0913L9.19389 14.5167L8.41406 13.1616L10.7344 11.9982L8.41406 10.8349L9.19389 9.47976L11.3672 10.9052L11.2202 8.31641H12.7734L12.6328 10.9052L14.8061 9.47976L15.5859 10.8349L13.2592 11.9982L15.5859 13.1616L14.8061 14.5167L12.6328 13.0913L12.7734 15.68H11.2202Z"
      fill="url(#paint1_linear_24227_35153)"
    />
  </svg>
)

export default MDRLogo
