import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Typography } from '@mui/material'
import { gql, useMutation } from '@apollo/client'

import AgreementModal from '@components/AgreementModal/AgreementModal'
import betaAgreementContent from '@app-assets/beta_agreement.md'
import { useAwaitedCustomer } from '@hooks/useCustomer'
import { setCustomer } from '@components/App/Actions/Customer'
import { Context } from '@components/App/Provider'
import { BetaAgreementStatus } from '@models/Customer'

import { betaCustomerShortNames } from './betaAgreementModalConstants'

const agreementVersion = '2024-12-12' // Update this version whenever the agreement content changes

interface BetaAgreementInput {
  status: BetaAgreementStatus
  version: string
}

interface CreateBetaAgreementData {
  createBetaAgreement: boolean
}

export const CREATE_BETA_AGREEMENT = gql`
  mutation CreateBetaAgreement(
    $status: BetaAgreementStatus!
    $version: AWSDate!
  ) {
    createBetaAgreement(input: { status: $status, version: $version })
  }
`

interface ControlledBetaAgreementModalProps {
  /**
   * If provided, the modal state will be controlled via this prop, and
   * will not automatically open based on the customer beta agreement status.
   * Required if `onClose` is provided
   */
  isOpen: boolean
  /**
   * Callback to close the modal; should set the `isOpen` prop value to `false`.
   * Required if `isOpen` is provided
   */
  onClose: () => void
}

interface UncontrolledBetaAgreementModalProps {
  isOpen?: never
  onClose?: never
}

type BetaAgreementModalProps =
  | ControlledBetaAgreementModalProps
  | UncontrolledBetaAgreementModalProps

export const BetaAgreementModal: React.FC<BetaAgreementModalProps> = ({
  isOpen: controlledIsOpen,
  onClose,
}) => {
  const { featureBetaAgreement } = useFlags()
  const { dispatch } = useContext(Context)
  const customer = useAwaitedCustomer()
  const [internalIsOpen, setInternalIsOpen] = useState(false)
  const [createBetaAgreement] = useMutation<
    CreateBetaAgreementData,
    BetaAgreementInput
  >(CREATE_BETA_AGREEMENT)

  /**
   * Open the modal if:
   * - the feature flag is enabled
   * - the customer has not yet submitted a beta agreement response
   * - the customer is part of the list of customers who should see the beta agreement
   * - the modal open state is not controlled
   */
  useEffect(() => {
    if (
      featureBetaAgreement &&
      customer.betaAgreementStatus === null &&
      betaCustomerShortNames.includes(customer.customerShortName) &&
      controlledIsOpen === undefined
    ) {
      setInternalIsOpen(true)
    }
  }, [
    customer.betaAgreementStatus,
    customer.customerShortName,
    featureBetaAgreement,
    controlledIsOpen,
  ])

  const updateAgreementStatus = useCallback(
    (status: BetaAgreementStatus) => {
      // Close modal immediately, allow mutation to run in the background
      if (onClose) {
        onClose()
      }
      setInternalIsOpen(false)
      createBetaAgreement({
        variables: { version: agreementVersion, status },
        optimisticResponse: { createBetaAgreement: true }, // Optimistically update the app state
        update: (_cache, { data }) => {
          // Update the app state with the new beta agreement status if the mutation was successful
          if (data?.createBetaAgreement) {
            dispatch(
              setCustomer({
                ...customer,
                betaAgreementStatus: status,
              }),
            )
          }
        },
        onError: () => {
          // If the mutation fails, revert the app state to the previous beta agreement status
          dispatch(
            setCustomer({
              ...customer,
              betaAgreementStatus: customer.betaAgreementStatus,
            }),
          )
        },
      })
    },
    [createBetaAgreement, customer, dispatch, onClose],
  )

  if (!featureBetaAgreement) {
    return null
  }

  const isOpen = controlledIsOpen ?? internalIsOpen // Controlled state takes precedence over internal

  return (
    <AgreementModal
      isOpen={isOpen}
      title="Opt in to Beta Features?"
      markdownContent={betaAgreementContent}
      acceptConfig={{
        variant: 'outlined',
        onClick: () => updateAgreementStatus(BetaAgreementStatus.ACCEPTED),
      }}
      declineConfig={{
        variant: 'contained',
        onClick: () => updateAgreementStatus(BetaAgreementStatus.DECLINED),
      }}
      version={agreementVersion}
    >
      <Typography paddingBottom={1} variant="body1" fontWeight="bold">
        Introducing beta features!
      </Typography>
      <Typography variant="body2">
        Opting in to beta features gives your organization early access to new
        features and functionality within the Security Center. Review the below
        Beta agreement, and{' '}
        <Typography variant="body2" component="span" fontWeight="bold">
          if you decide to opt in, your Deepwatch Experts will keep you updated
          on the latest features.
        </Typography>
      </Typography>
    </AgreementModal>
  )
}
