import { useQuery } from '@apollo/client'
import { Box, Skeleton, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { MY_SQUAD, MySquadData } from '@queries/dwExperts'

const MySquad = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const { loading, data: { mySquad: dwExperts } = { mySquad: [] } } =
    useQuery<MySquadData>(MY_SQUAD, {
      variables: { selectedCustomer },
    })

  // remove squad director and principal analyst
  const filteredExperts = dwExperts.filter(
    ({ jobTitle }) =>
      jobTitle !== 'Squad Manager' && jobTitle !== 'Principal Analyst',
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '1rem',
      }}
    >
      <Box data-testid="my-deepwatch-experts">
        <Typography fontWeight={600} variant="body1" color="textPrimary">
          My Deepwatch Team
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '1.5rem 2rem',
        }}
      >
        {loading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                }}
              >
                <Skeleton
                  sx={{ minHeight: '17px', width: '40%' }}
                  data-testid="skeleton-block"
                />
                <Skeleton
                  sx={{ minHeight: '17px', width: '20%' }}
                  data-testid="skeleton-block"
                />
              </Box>
            ))}
          </>
        ) : (
          filteredExperts.map(({ jobTitle, name }, index: number) => {
            return (
              <Box
                key={`${name}-${index}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                }}
              >
                <Typography
                  fontWeight={600}
                  color="textPrimary"
                  variant="body2"
                >
                  {jobTitle}
                </Typography>
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                >
                  {name}
                </Typography>
              </Box>
            )
          })
        )}
      </Box>
    </Box>
  )
}

export default MySquad
