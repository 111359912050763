import DateButton from './DateButton'
import { MonthPickerProps } from './MonthPicker'

const MonthSelection: React.FC<
  {
    date: Date
    monthIndex: number
    monthLabel: string
    onDateSelect?(): void
  } & Pick<MonthPickerProps, 'isMonthDisabled'>
> = ({ date, monthIndex, monthLabel, isMonthDisabled, onDateSelect }) => {
  const monthDateObj = new Date(date.getFullYear(), monthIndex, 0)
  const isActive = date.getMonth() === monthIndex
  const isDisabled = isMonthDisabled(monthDateObj)

  return (
    <DateButton
      isDisabled={isDisabled}
      isActive={isActive}
      label={monthLabel}
      onClick={onDateSelect}
    />
  )
}

export default MonthSelection
