import { Box, Typography, Tooltip } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { DetectionCoverageOverviewData } from '@models/index'
import { ErrorCard, ComponentErrorType } from '@common/ErrorCard'
import { Loader } from '@common/Loader'

import { MitreOverviewValues } from '../utils'
import MitreAlertChart from './MitreAlertChart'
import TopLogSources from './TopLogSources'

export const MitreOverviewFallback = () => (
  <Box
    sx={{ minWidth: '304px', flex: '1', marginBottom: '93px' }}
    component="aside"
    id="mitre-overview"
  >
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <Typography color="textPrimary" variant="h6">
        MITRE overview
      </Typography>
      <MitreOverviewDataLabel
        isLoading={false}
        label="MITRE Tactics covered"
        value={undefined}
        compareValue={undefined}
        tooltipContent={<></>}
      />
      <MitreOverviewDataLabel
        isLoading={false}
        label="MITRE Techniques covered"
        value={undefined}
        compareValue={undefined}
        tooltipContent={<></>}
      />
      <ErrorCard />
      <TopLogSources title="Top 5 source types by utilization" data={[]} />
      <ErrorCard />
    </Box>
  </Box>
)

interface MitreOverviewProps {
  loading: boolean
  mitreOverviewData: MitreOverviewValues | undefined
  detectionCoverageOverviewData: DetectionCoverageOverviewData
}

export default function MitreOverview({
  mitreOverviewData,
  detectionCoverageOverviewData,
  loading,
}: MitreOverviewProps) {
  const { featureDetectionCoverageUpdates } = useFlags()

  const doesMitreAlertChartHaveData =
    loading ||
    (detectionCoverageOverviewData?.alertsByMitreTactic &&
      detectionCoverageOverviewData?.alertsByMitreTactic.length > 0)
  const doesLogSourceByUtilizationHaveData =
    loading ||
    (mitreOverviewData?.logSourceByUtilization &&
      mitreOverviewData.logSourceByUtilization.length > 0)

  return (
    <Box
      component="aside"
      sx={{ minWidth: '304px', flex: '1', marginBottom: '93px' }}
      id="mitre-overview"
    >
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          gap: '24px',
          flexDirection: 'column',
        }}
        data-testid="detection-coverage-overview-sidebar"
      >
        {/* Header */}
        <Typography color="textPrimary" variant="h6">
          MITRE overview
        </Typography>
        {/* Data Labels */}
        <MitreOverviewDataLabel
          isLoading={!mitreOverviewData}
          label="MITRE Tactics covered"
          value={mitreOverviewData?.customerTacticsData}
          compareValue={mitreOverviewData?.deepWatchTacticsData}
          tooltipContent={
            <>
              You have coverage on {mitreOverviewData?.customerTacticsData}{' '}
              MITRE tactics
            </>
          }
        />
        <MitreOverviewDataLabel
          isLoading={!mitreOverviewData}
          label="MITRE Techniques covered"
          value={mitreOverviewData?.mitreCustomerTechniqueData}
          compareValue={mitreOverviewData?.mitreDeepWatchTechniqueData}
          tooltipContent={
            <>
              You have coverage on{' '}
              {mitreOverviewData?.mitreCustomerTechniqueData} MITRE techniques
            </>
          }
        />
        {/* Charts */}
        {doesMitreAlertChartHaveData ? (
          <MitreAlertChart data={detectionCoverageOverviewData} />
        ) : (
          <ErrorCard errorType={ComponentErrorType.NO_DATA} />
        )}
        {loading ? (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Loader strokeWidth={2} size={50} />
          </Box>
        ) : (
          <>
            <TopLogSources
              title="Top 5 source types by utilization"
              data={mitreOverviewData?.logSourceByUtilization}
            />
            {!doesLogSourceByUtilizationHaveData && (
              <ErrorCard errorType={ComponentErrorType.NO_DATA} />
            )}
            {!featureDetectionCoverageUpdates &&
              mitreOverviewData?.logSourceByAlert &&
              mitreOverviewData.logSourceByAlert.length > 0 && (
                <>
                  <TopLogSources
                    title="Top 5 source types by alerts"
                    titleTooltip="Based on a daily average true-positive count."
                    data={mitreOverviewData.logSourceByAlert}
                  />
                </>
              )}
          </>
        )}
      </Box>
    </Box>
  )
}

interface MitreOverviewDataLabelProps {
  label: string
  value: number | undefined
  compareValue: number | undefined
  isLoading: boolean
  tooltipContent: JSX.Element
}

function MitreOverviewDataLabel({
  label,
  value,
  compareValue,
  isLoading,
  tooltipContent,
}: MitreOverviewDataLabelProps) {
  const component = (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        justifyContent: 'space-between',
      }}
      tabIndex={0}
      role={'group'}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {label}
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        <>
          {isLoading || value === undefined ? '--' : value}/
          <Box
            component="span"
            sx={(theme) => ({ color: theme.palette.text.primary })}
          >
            {isLoading || compareValue === undefined ? '--' : compareValue}
          </Box>
        </>
      </Typography>
    </Box>
  )

  if (isLoading) {
    return component
  }

  return (
    <Tooltip title={tooltipContent}>
      <Box component="span">{component}</Box>
    </Tooltip>
  )
}
