import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnFiltersState } from '@tanstack/react-table'
import { ErrorBoundary } from 'react-error-boundary'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { Paths } from '@components/App/Types'
import { ErrorFallback } from '@components/App/Errors'

import Overview from './Overview/Overview'
import { MitreOverviewContextProvider } from './Overview/context/MitreOverviewContext'
import { TaVersions } from './DetectionCatalog/TaVersions'
import DetectionCatalog from './DetectionCatalog/DetectionCatalog'

const Coverage = ({ activeTab = 0 }) => {
  const DEFAULT_FILTERS_STATE = [
    {
      id: 'status',
      value: [],
    },
    {
      id: 'mitreTactics',
      value: [],
    },
  ]

  const [selectedTab, setSelectedTab] = useState(activeTab)

  useEffect(() => {
    setSelectedTab(activeTab)
  }, [activeTab])

  const [selectedFilters, setFilters] = useState<ColumnFiltersState>(
    DEFAULT_FILTERS_STATE,
  )
  const navigate = useNavigate()

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const handleDetectionCatalogTab = (status: string): void => {
    navigate(
      isDWEmployee
        ? `${Paths.DETECTION_CATALOG}?customer=${customerShortName}`
        : `${Paths.DETECTION_CATALOG}`,
    )

    setFilters([
      {
        id: 'status',
        value: [status],
      },
      {
        id: 'mitreTactics',
        value: [],
      },
    ])
    setSelectedTab(1)
  }

  const CoverageTabsContent: JSX.Element[] = [
    <ErrorBoundary key={0} fallbackRender={() => <ErrorFallback />}>
      <MitreOverviewContextProvider>
        <Overview handleDetectionLibNav={handleDetectionCatalogTab} />
      </MitreOverviewContextProvider>
    </ErrorBoundary>,
    <ErrorBoundary key={1} fallbackRender={() => <ErrorFallback />}>
      <DetectionCatalog selectedFilters={selectedFilters} />
    </ErrorBoundary>,
  ]

  return (
    <Box
      component="article"
      width="100%"
      overflow="auto"
      padding="0"
      data-testid="coverage"
    >
      {selectedTab === 1 && (
        <Box
          data-testid="detection-catalog-ta-version"
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingTop: '0.5rem',
            width: '100%',
          }}
        >
          <TaVersions />
        </Box>
      )}
      {CoverageTabsContent.at(selectedTab)}
    </Box>
  )
}

export default Coverage
