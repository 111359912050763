import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useState,
  MouseEvent,
  FocusEvent,
  createRef,
} from 'react'
import { Button } from '@mui/material'

import { SideSheetProps } from '@common/SideSheet'
import {
  DetectionCoverageOverviewData,
  MitreCoverageTechnique,
  MitreOverviewDetection,
} from '@models/DetectionCoverage'
import Icon from '@common/Icon'
import { Detections } from '@models/Detections'
import { emptyDetection } from '@components/Coverage/DetectionCatalog/DetectionCatalog.utils'

import { DetectionCoverageDisplayValues } from '../utils'
import MitreSideSheetContent from '../MitreSideSheetContent/MitreSideSheetContent'
import AssociatedDetectionCatalogSideSheet from '../AssociatedDetectionSidesheet/AssociatedDetections'

type OverviewSideSheetStateProps = Omit<SideSheetProps, 'open'> & {
  id: string
}
export interface MitreSelection {
  tactic: number | undefined
  technique: number | undefined
  subtechnique: number | undefined
}
type HandleSideSheetFunction = (
  tacticIndex: number,
  techniqueIndex?: number,
  subtechniqueIndex?: number,
  closeSideSheetCallback?: () => void,
  detection?: Detections | MitreOverviewDetection,
  leaveSideSheetOpen?: boolean,
) => void
type UpdateOrCloseSideSheetFunction = (
  newSideSheet: OverviewSideSheetStateProps,
  leaveSideSheetOpen?: boolean,
) => void
type ResetSelectionState = (technique: MitreCoverageTechnique) => void
type HandleTooltipEnter = (
  event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>,
  gap: number,
  innerHTML: string,
) => void
export type Coverage = NonNullable<DetectionCoverageDisplayValues['coverage']>
export interface MitreOverviewContextModel {
  coverage: Coverage
  mitreSelection: MitreSelection
  previousMitreSelection: MitreSelection
  carouselTechnique: string | undefined
  hasUserClosedSideSheet: boolean
  isSideSheetOpen: boolean
  selectedBan: string
  sideSheetData: OverviewSideSheetStateProps
  previousSideSheetData: OverviewSideSheetStateProps
  resetSelectionState: ResetSelectionState
  handleSideSheet: HandleSideSheetFunction
  updateOrCloseSideSheet: UpdateOrCloseSideSheetFunction
  detectionCoverageOverview: DetectionCoverageOverviewData | undefined
  detections: Detections[] | MitreOverviewDetection[]
  focusedDetection: Detections | MitreOverviewDetection
  setCarouselTechnique(value?: string): void
  setHasUserClosedSideSheet(value: boolean): void
  setMitreSelection(mitreSelection: MitreSelection): void
  setPreviousMitreSelection(mitreSelection: MitreSelection): void
  setCoverage(
    value: NonNullable<DetectionCoverageDisplayValues['coverage']>,
  ): void
  setDetectionCoverageOverview(value: DetectionCoverageOverviewData): void
  setIsSideSheetOpen(value: boolean): void
  setSelectedBan(value: string): void
  tooltipRef: RefObject<HTMLDivElement>
  handleTooltipEnter: HandleTooltipEnter
  handleTooltipLeave(): void
  setDetections(detection: Detections[] | MitreOverviewDetection[]): void
  setFocusedDetection(
    focusedDetection: Detections | MitreOverviewDetection,
  ): void
}

export const initialMitreOverviewContext: MitreOverviewContextModel = {
  coverage: {
    dw: [],
    customerByTactic: [],
    customer: [],
  },
  carouselTechnique: undefined,
  hasUserClosedSideSheet: false,
  mitreSelection: {
    tactic: undefined,
    technique: undefined,
    subtechnique: undefined,
  },
  previousMitreSelection: {
    tactic: undefined,
    technique: undefined,
    subtechnique: undefined,
  },
  tooltipRef: {
    current: null,
  },
  detectionCoverageOverview: undefined,
  isSideSheetOpen: false,
  selectedBan: '',
  sideSheetData: {
    id: '',
    children: null,
  },
  previousSideSheetData: {
    id: '',
    children: null,
  },
  detections: [emptyDetection],
  focusedDetection: emptyDetection,
  /* eslint-disable @typescript-eslint/no-empty-function */
  handleSideSheet: () => {},
  setCarouselTechnique: () => {},
  resetSelectionState: () => {},
  setHasUserClosedSideSheet: () => {},
  setMitreSelection: () => {},
  setCoverage: () => {},
  handleTooltipEnter: () => {},
  handleTooltipLeave: () => {},
  setDetectionCoverageOverview: () => {},
  updateOrCloseSideSheet: () => {},
  setIsSideSheetOpen: () => {},
  setSelectedBan: () => {},
  setDetections: () => {},
  setFocusedDetection: () => {},
  setPreviousMitreSelection: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
}

export const MitreOverviewContext = createContext<MitreOverviewContextModel>(
  initialMitreOverviewContext,
)

export const MitreOverviewContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const tooltipRef = createRef<HTMLDivElement>()
  const [coverage, setCoverage] = useState<
    MitreOverviewContextModel['coverage']
  >(initialMitreOverviewContext.coverage)

  /**
   * The carouselTechniqueState is used to determine which MITRE
   * technique has been selected via MITRE technique ID as it is
   * the unique identifier for a given technique; it is used separately
   * from mitreSelectionState
   */
  const [carouselTechnique, setCarouselTechnique] = useState<
    MitreOverviewContextModel['carouselTechnique']
  >(initialMitreOverviewContext.carouselTechnique)

  /**
   * tracks when a user closes a sidesheet;
   * used for application flow logic
   */
  const [hasUserClosedSideSheet, setHasUserClosedSideSheet] = useState<
    MitreOverviewContextModel['hasUserClosedSideSheet']
  >(initialMitreOverviewContext.hasUserClosedSideSheet)

  const [mitreSelection, setMitreSelection] = useState<
    MitreOverviewContextModel['mitreSelection']
  >(initialMitreOverviewContext.mitreSelection)

  const [isSideSheetOpen, setIsSideSheetOpen] = useState(
    initialMitreOverviewContext.isSideSheetOpen,
  )

  const [selectedBan, setSelectedBan] = useState('')

  const [detections, setDetections] = useState<
    MitreOverviewContextModel['detections']
  >(initialMitreOverviewContext.detections)

  const [sideSheetData, setSideSheetData] =
    useState<OverviewSideSheetStateProps>(
      initialMitreOverviewContext.sideSheetData,
    )

  const [previousSideSheetData, setPreviousSideSheetData] =
    useState<OverviewSideSheetStateProps>(
      initialMitreOverviewContext.previousSideSheetData,
    )

  const [detectionCoverageOverview, setDetectionCoverageOverview] =
    useState<MitreOverviewContextModel['detectionCoverageOverview']>()

  const [focusedDetection, setFocusedDetection] = useState<
    MitreOverviewContextModel['focusedDetection']
  >(initialMitreOverviewContext.focusedDetection)

  const [previousMitreSelection, setPreviousMitreSelection] = useState<
    MitreOverviewContextModel['previousMitreSelection']
  >(initialMitreOverviewContext.previousMitreSelection)

  /**
   * This methods gets called when the selection state should be reset to only
   * selecting a given technique
   * @param technique
   */
  const resetSelectionState: ResetSelectionState = (technique) => {
    if (technique.subtechniques.length === 0) {
      setCarouselTechnique(undefined)
    }
    setMitreSelection({
      technique: undefined,
      tactic: undefined,
      subtechnique: mitreSelection.subtechnique,
    })
    setHasUserClosedSideSheet(true)
  }

  /**
   * closes side sheet if the new sideSheet object has the same
   * id prop value to implement closing the sidesheet when the
   * user selects the same ban/tactic/technique/subtechnique
   * @param newSideSheet
   * @param leaveSideSheetOpen
   */
  const updateOrCloseSideSheet: UpdateOrCloseSideSheetFunction = (
    newSideSheet,
    leaveSideSheetOpen,
  ) => {
    let open = true

    //Make sure the side sheet is closed if the user selects the same ban/tactic/technique/subtechnique
    if (
      isSideSheetOpen &&
      newSideSheet.id === sideSheetData.id &&
      !leaveSideSheetOpen
    ) {
      open = false
      //Make sure the side sheet is closed if the user selects the same technique if details sidesheet is open
    } else if (
      isSideSheetOpen &&
      previousSideSheetData.id === newSideSheet.id &&
      !leaveSideSheetOpen
    ) {
      open = false
    }

    if (open === false) {
      setSelectedBan('')
    }

    setSideSheetData(newSideSheet)
    setIsSideSheetOpen(open)
  }

  /**
   * This method will generate the side sheet footer using the given mitre selections
   * @param tacticIndex
   * @param techniqueIndex
   * @param subtechniqueIndex
   */
  const getSideSheetFooter = (
    tacticIndex: number,
    techniqueIndex?: number,
    subtechniqueIndex?: number,
  ): ReactNode => {
    // eslint-disable-next-line security/detect-object-injection
    const tactic = detectionCoverageOverview?.mitreCoverage[tacticIndex]

    let type = 'tactic'
    let baseUrl = `https://attack.mitre.org/${type}s/`
    let href = `${baseUrl}${tactic?.mitreTacticId}/`

    if (techniqueIndex !== undefined) {
      type = 'technique'
      baseUrl = `https://attack.mitre.org/${type}s/`
      // eslint-disable-next-line security/detect-object-injection
      href = `${baseUrl}${tactic?.techniques[techniqueIndex].mitreTechniqueId}/`
      if (subtechniqueIndex !== undefined) {
        type = 'subtechnique'
        // eslint-disable-next-line security/detect-object-injection
        href = `${baseUrl}${tactic?.techniques[techniqueIndex].subtechniques[
          subtechniqueIndex
        ].mitreSubtechniqueId.replace('.', '/')}/`
      }
    }
    return (
      <Button
        href={href}
        sx={(theme) => ({
          color: theme.palette.background.paper,
          gap: 0.5,
          ...theme.applyStyles('dark', {
            color: theme.palette.secondary.darker,
          }),
        })}
        target="_blank"
        variant="contained"
      >
        View {type} details
        <Icon
          sx={(theme) => ({
            color: theme.palette.background.paper,
            ...theme.applyStyles('dark', {
              color: theme.palette.secondary.darker,
            }),
          })}
          variant="openOutline"
        />
      </Button>
    )
  }

  /**
   * This method will construct the side sheet using the given mitre selection. Depending on what values are set
   * it will determine which title to use and call the methods to generate the content for the side sheet
   * @param tacticIndex
   * @param techniqueIndex
   * @param subtechniqueIndex
   * @param closeSideSheetCallback
   * @param detection
   * @param leaveSideSheetOpen
   */
  const handleSideSheet: HandleSideSheetFunction = (
    tacticIndex,
    techniqueIndex,
    subtechniqueIndex,
    closeSideSheetCallback,
    detection,
    leaveSideSheetOpen = false,
  ) => {
    let title = 'Tactic detail'

    if (detection) {
      title = 'Detection detail'
    } else if (subtechniqueIndex !== undefined) {
      title = 'Sub-technique detail'
    } else if (techniqueIndex !== undefined) {
      title = 'Technique detail'
    }

    // eslint-disable-next-line security/detect-object-injection
    const tactic = coverage?.dw[tacticIndex]

    const customerTacticTechniques = tactic
      ? coverage?.customer.filter(
          (customerTechnique) =>
            customerTechnique.mitreTacticId === tactic?.mitreTacticId,
        )
      : []

    const mitreSideSheetProps: OverviewSideSheetStateProps = {
      id: `${tacticIndex}-${techniqueIndex}-${subtechniqueIndex}`,
      footer: getSideSheetFooter(
        tacticIndex,
        techniqueIndex,
        subtechniqueIndex,
      ),
      title: title,
      children: (
        <MitreSideSheetContent
          tactic={tactic}
          customerTacticTechniques={customerTacticTechniques}
          techniqueIndex={techniqueIndex}
          subTechniqueIndex={subtechniqueIndex}
        />
      ),
      closeSideSheet: () => {
        setIsSideSheetOpen(false)
        closeSideSheetCallback?.()
      },
    }

    const associatedDetectionSideSheetProps: OverviewSideSheetStateProps = {
      id: `${tacticIndex}-${techniqueIndex}-${subtechniqueIndex}-${detection?.useCase}`,
      title: title,
      children: (
        <AssociatedDetectionCatalogSideSheet
          isOpen={!!detection}
          focusedDetection={detection}
        />
      ),
      closeSideSheet: () => {
        setIsSideSheetOpen(false)
        closeSideSheetCallback?.()
      },
    }

    if (detection?.useCase !== '' && detection?.useCase !== undefined) {
      updateOrCloseSideSheet(
        associatedDetectionSideSheetProps,
        leaveSideSheetOpen,
      )
    } else {
      setPreviousSideSheetData(mitreSideSheetProps)
      updateOrCloseSideSheet(mitreSideSheetProps, leaveSideSheetOpen)
    }
  }

  /**
   * This function can be called when the tooltip should be opened
   * @param event
   * @param gap
   * @param innerHTML
   */
  const handleTooltipEnter: HandleTooltipEnter = (event, gap, innerHTML) => {
    if (tooltipRef.current && innerHTML) {
      tooltipRef.current.innerHTML = innerHTML
      tooltipRef.current.style.display = 'flex'
      tooltipRef.current.style.top = `${
        event.currentTarget.getBoundingClientRect().y -
        tooltipRef.current.offsetHeight -
        gap
      }px`
      tooltipRef.current.style.left = `${event.currentTarget.offsetLeft}px`
    }
  }

  /**
   * This function can be called when the tooltip should be set to closwed
   */
  const handleTooltipLeave = () => {
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none'
    }
  }

  return (
    <MitreOverviewContext.Provider
      value={{
        coverage,
        carouselTechnique,
        hasUserClosedSideSheet,
        mitreSelection,
        handleSideSheet,
        tooltipRef,
        detectionCoverageOverview,
        isSideSheetOpen,
        selectedBan,
        sideSheetData,
        resetSelectionState,
        setCarouselTechnique,
        setHasUserClosedSideSheet,
        setMitreSelection,
        setCoverage,
        handleTooltipEnter,
        handleTooltipLeave,
        setDetectionCoverageOverview,
        updateOrCloseSideSheet,
        setIsSideSheetOpen,
        setSelectedBan,
        detections,
        setDetections,
        focusedDetection,
        setFocusedDetection,
        previousMitreSelection,
        setPreviousMitreSelection,
        previousSideSheetData,
      }}
    >
      {children}
    </MitreOverviewContext.Provider>
  )
}

export const useMitreOverviewContext = () => useContext(MitreOverviewContext)
