import { useContext } from 'react'

import { ThreatProtectionSummaryContext } from './ThreatProtectionSummaryContext'

export const useThreatProtectionSummaryContext = () => {
  const threatProtectionSummaryContext = useContext(
    ThreatProtectionSummaryContext,
  )

  if (!threatProtectionSummaryContext) {
    throw new Error(
      'ThreatProtectionSummaryContext must be used within ThreatProtectionSummaryContextProvider',
    )
  }

  return threatProtectionSummaryContext
}
