import { Box, Button, Chip, SxProps, Theme, Typography } from '@mui/material'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Icon from '@common/Icon'
import { ADD_CYBER_ASSISTANT_FEEDBACK } from '@mutations/cyberAssistant'
import { Loader } from '@common/Loader'

import SearchBox from '../SearchBox/SearchBox'
import {
  AnswerId,
  customerQuestions,
  CustomerQuestionType,
} from '../CyberAssistantQuestions'
import AnswerDetails from '../AnswerDetails/AnswerDetails'
import QuestionSubmisson from '../QuestionSubmisson/QuestionSubmisson'
import { getAnswerComponent } from '../CyberAssistant.utils'
import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'

type SearchView = 'searchView' | 'answerView' | 'submissionView'

const ChipStyle: SxProps<Theme> = (theme: Theme) => ({
  '&.MuiChip-outlined': {
    padding: '12px 10px',
    color: theme.vars.palette.text.primary,
    borderColor: theme.vars.palette.text.primary,
    ...theme.applyStyles('dark', {
      color: theme.vars.palette.secondary.light,
      borderColor: theme.vars.palette.secondary.light,
    }),
    borderRadius: '5px',
  },
})

interface CyberAssistantSearchProps {
  searchTerm: string
  handleSearchTermChange: (searchTerm: string) => void
}

const CyberAssistantSearch = ({
  searchTerm,
  handleSearchTermChange,
}: CyberAssistantSearchProps) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [searchView, setSearchView] = useState<SearchView>('searchView')
  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string | JSX.Element>('')
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('')

  const { featureCyberAssistantV2 } = useFlags()

  const navigateToAnswerView = (inquiry: CustomerQuestionType) => {
    setSearchView('answerView')
    setQuestion(inquiry.question)
    const answer = getAnswerComponent(inquiry.id as AnswerId, selectedCustomer)
    setAnswer(answer || EmptyDataAnswer)
  }

  const navigateToSubmissonView = () => {
    handleSearchTermChange('')
    setSearchView('submissionView')
  }

  const navigateBack = () => {
    handleSearchTermChange('')
    setQuestion('')
    setSearchView('searchView')
  }

  const onSearchTerm = (searchTerm: string) => {
    handleSearchTermChange(searchTerm)
    setErrorMessage('')
  }

  const [addAssistantFeedback, { loading, error }] = useMutation(
    ADD_CYBER_ASSISTANT_FEEDBACK,
  )

  const handleButtonClick = async () => {
    if (searchTerm.length > 8192) {
      setErrorMessage(
        'The question exceeds the allowed character limit of 8192.',
      )
      return
    }

    const question = customerQuestions.find(
      (question) =>
        question.question.toLowerCase() === searchTerm.toLowerCase(),
    )

    if (!question && searchTerm) {
      await addAssistantFeedback({
        variables: {
          selectedCustomer,
          input: {
            userQuestion: searchTerm,
          },
        },
        onError: () => {
          setErrorMessage('We were unable to submit feedback.')
        },
      })

      if (!error) {
        navigateToSubmissonView()
      }
    }

    if (question && searchTerm) {
      navigateToAnswerView(question)
    }
  }

  const renderQuestionSubmitButton = () => {
    if (loading) {
      return <Loader />
    }

    return (
      <Icon
        variant="arrowUp"
        size={24}
        sx={(theme) => ({
          color: theme.vars.palette.text.secondary,
        })}
      />
    )
  }

  const renderContent = () => {
    if (searchView === 'answerView') {
      return (
        <AnswerDetails
          goBack={navigateBack}
          question={question}
          answer={answer}
        />
      )
    }

    if (searchView === 'submissionView') {
      return <QuestionSubmisson goBack={navigateBack} />
    }

    const questionPrompt = `What ${featureCyberAssistantV2 ? `else` : ''} would you like to know?`

    if (searchView === 'searchView') {
      return (
        <Box
          sx={() => ({
            marginTop: '107px',
          })}
          data-testid="cyber-assistant-search"
        >
          <Typography
            variant="h5"
            sx={(theme) => ({
              justifySelf: 'center',
              paddingBottom: '10px',
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {questionPrompt}
          </Typography>
          <Typography
            variant="body2"
            sx={(theme) => ({
              paddingBottom: '38px',
              justifySelf: 'center',
              textAlign: 'center',
              width: '75%',
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {`Enter your question below and if we don't have an answer yet, we'll
        submit your question to the team for inclusion in a future update.`}
          </Typography>
          <Box
            sx={() => ({
              justifyItems: 'center',
              paddingLeft: '3.5rem',
              paddingRight: '3.5rem',
            })}
          >
            <Box sx={{ display: 'flex', width: '100%' }}>
              <SearchBox
                searchValue={searchTerm}
                questions={customerQuestions}
                onSearch={onSearchTerm}
                onKeyPress={handleButtonClick}
              />
              <Button
                variant="contained"
                sx={() => ({
                  marginLeft: '8px',
                  maxWidth: '40px',
                  maxHeight: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                })}
                onClick={handleButtonClick}
              >
                {renderQuestionSubmitButton()}
              </Button>
            </Box>
          </Box>
          {errorMessage && (
            <Box
              sx={{
                pl: '3.5rem',
                pr: '3.5rem',
                pt: '0.25rem',
              }}
            >
              <Typography fontWeight={600} variant="body1" color="red">
                {errorMessage}
              </Typography>
              {error && (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={{ pt: '0.25rem' }}
                >
                  Please refresh the page and try again.
                </Typography>
              )}
            </Box>
          )}
          {featureCyberAssistantV2 && (
            <Typography
              variant="h6"
              sx={(theme) => ({
                justifySelf: 'center',
                p: '10px',
                pt: '36px',
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              Common Topics
            </Typography>
          )}
          <Box
            sx={() => ({
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              marginTop: featureCyberAssistantV2 ? '16px' : '58px',
            })}
          >
            <Chip
              data-testid="security-index-chip"
              label={<Typography>Security Index</Typography>}
              variant="outlined"
              size="medium"
              sx={ChipStyle}
              onClick={() => navigateToAnswerView(customerQuestions[3])}
            />
            <Chip
              data-testid="mitre-coverage-chip"
              label={<Typography>Mitre Coverage</Typography>}
              variant="outlined"
              size="medium"
              sx={ChipStyle}
              onClick={() => navigateToAnswerView(customerQuestions[2])}
            />
            <Chip
              data-testid="true-positives-chip"
              label={<Typography>True Positives</Typography>}
              variant="outlined"
              size="medium"
              sx={ChipStyle}
              onClick={() => navigateToAnswerView(customerQuestions[0])}
            />
          </Box>
        </Box>
      )
    }

    return (
      <AnswerDetails
        goBack={navigateBack}
        question={question}
        answer={answer}
      />
    )
  }

  return <>{renderContent()}</>
}

export default CyberAssistantSearch
