import { ResponsiveBar } from '@nivo/bar'
import { AxisTickProps } from '@nivo/axes'
import { format as formatDate, parseISO } from 'date-fns/fp'
import { ApolloError } from '@apollo/client'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { useRef, useState } from 'react'
import {
  Box,
  Card,
  IconButton,
  Theme,
  Typography,
  useColorScheme,
  useTheme,
} from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import { DashboardOpenTicketTrend } from '@models/Dashboard'
import ChartDownloadMenu from '@common/ChartDownloadMenu'
import { ChartLegend, ChartLegendItem } from '@common/ChartLegend'
import { TicketPriorityEnum } from '@models/Tickets'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { themeConfig } from '@common/nivo/utils'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

interface CriticalHighOpenTicketsProps {
  data: DashboardOpenTicketTrend[] | null
  loading: boolean
  error?: ApolloError
}

type ValidDateFormats = 'd' | 'M/d' | 'MMM d'

const PriorityBoxStyle = (theme: Theme, priority: string) => {
  let color: string | undefined = theme.vars.palette.text.primary

  if (priority.includes('critical')) {
    color = theme.vars.palette.severity.critical
  } else if (priority.includes('high')) {
    color = theme.vars.palette.severity.high
  } else if (priority.includes('moderate')) {
    color = theme.vars.palette.severity.medium
  } else if (priority.includes('low')) {
    color = theme.vars.palette.severity.low
  }

  return {
    height: '12px',
    width: '12px',
    backgroundColor: color,
    borderRadius: '2px',
  }
}

export const getFormattedDate = (date: string, format: string) => {
  return formatDate(
    format,
    parseISO(date.indexOf('T') > -1 ? date.split('T')[0] : date),
  )
}

export const getDateFormat = (dataLength: number): ValidDateFormats => {
  if (dataLength > 20) {
    return 'd'
  } else if (dataLength > 7) {
    return 'M/d'
  }

  return 'MMM d'
}

export const maxChartValue = (data): number =>
  Math.max(
    ...data.flatMap((ticket: Omit<DashboardOpenTicketTrend, 'date'>) => {
      return Object.values(ticket)
    }),
  )

export const CustomTick = (
  tick: AxisTickProps<string>,
  dataLength: number,
  theme: Theme,
) => {
  const dateFormat = getDateFormat(dataLength)

  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: 12,
          fill: theme.vars.palette.text.primary,
        }}
      >
        {getFormattedDate(tick.value, dateFormat)}
      </text>
    </g>
  )
}

export const formatToolTip = (
  data: DashboardOpenTicketTrend,
  isStacked: boolean,
) => {
  const filteredData: { [key: string]: number } = Object.entries(data)
    .filter(([key]) => key !== '__typename' && key !== 'date')
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  const totalTickets = (data: Record<string, number>) =>
    Object.values(data).reduce((a, b) => a + b, 0)

  const entries = isStacked
    ? Object.entries(filteredData).reverse()
    : Object.entries(filteredData)

  return (
    <Box
      data-testid="open-tickets-tool-tip"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        borderRadius: '5px',
        boxShadow: `0 1px 4px rgb(25 41 46 / 40%)`,
        padding: '4px',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
        }),
      })}
    >
      <Typography variant="caption" fontWeight={600}>
        {totalTickets(filteredData)} total tickets
      </Typography>

      <Box
        component="hr"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.vars.palette.secondary.lighter}`,
        })}
      />

      {entries.map((item, index) => {
        let priority = item[0].toLowerCase()
        const value = item[1]

        if (priority.includes('critical')) {
          priority = 'Critical'
        } else if (priority.includes('high')) {
          priority = 'High'
        } else if (priority.includes('moderate')) {
          priority = 'Moderate'
        } else if (priority.includes('low')) {
          priority = 'Low'
        }

        return (
          <Box
            key={index}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '4px',
            })}
          >
            <Box
              sx={(theme) => PriorityBoxStyle(theme, item[0].toLowerCase())}
            />
            <Typography variant="caption">
              {value} {priority}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

const CriticalHighOpenTickets = ({
  data,
  loading,
  error,
}: CriticalHighOpenTicketsProps): JSX.Element => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(16)],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const chartRef = useRef<HTMLDivElement>(null)

  const isEmpty = !data || data.length === 0 || error !== undefined

  const renderChart = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Loader strokeWidth={2} size={50} />
        </Box>
      )
    } else if (isEmpty) {
      return <ComponentError errorContainerStyles={{ height: '100%' }} />
    }
    const isStacked = data.length > 7
    const keys = [
      'openCriticalCount',
      'openHighCount',
      'openModerateCount',
      'openLowCount',
    ]

    const keyColors = [
      theme.vars.palette.severity.critical,
      theme.vars.palette.severity.high,
      theme.vars.palette.severity.medium,
      theme.vars.palette.severity.low,
    ] as string[]

    const tickets = data.map(
      ({
        openCriticalCount,
        openHighCount,
        openModerateCount,
        openLowCount,
      }) => {
        return {
          openCriticalCount,
          openHighCount,
          openModerateCount,
          openLowCount,
        }
      },
    )

    return (
      <ResponsiveBar
        data={data}
        keys={isStacked ? keys.reverse() : keys}
        indexBy="date"
        isFocusable={true}
        ariaDescribedBy="chot-chart-description"
        barAriaLabel={({ indexValue, id, formattedValue }) =>
          `on ${indexValue} ${id} was ${formattedValue}`
        }
        margin={{
          top: 8,
          right: 0,
          bottom: 48,
          left: 32,
        }}
        padding={isStacked ? 0.6 : 0.35}
        innerPadding={isStacked ? 0 : 4}
        borderWidth={isStacked ? 1 : 0}
        borderRadius={isStacked ? 0 : 2}
        valueScale={{
          type: 'linear',
          max: isStacked ? 'auto' : Math.ceil(maxChartValue(tickets) / 5) * 5,
          min: 0,
        }}
        indexScale={{ type: 'band', round: true }}
        colors={isStacked ? keyColors.reverse() : keyColors}
        colorBy="id"
        enableLabel={false}
        theme={themeConfig(mode, theme)}
        axisBottom={{
          renderTick: (tick) => CustomTick(tick, data.length, theme),
          tickPadding: 8,
        }}
        gridYValues={6}
        axisLeft={{
          format: (tick) => (Math.floor(tick) === tick ? tick : ''),
          tickValues: 6,
        }}
        tooltip={(point) => formatToolTip(point.data, isStacked)}
        groupMode={isStacked ? 'stacked' : 'grouped'}
      />
    )
  }

  const legendItems: ChartLegendItem[] = [
    {
      label: TicketPriorityEnum.Critical,
      color: theme.vars.palette.severity.critical,
    },
    {
      label: TicketPriorityEnum.High,
      color: theme.vars.palette.severity.high,
    },
    {
      label: TicketPriorityEnum.Moderate,
      color: theme.vars.palette.severity.medium,
    },
    {
      label: TicketPriorityEnum.Low,
      color: theme.vars.palette.severity.low,
    },
  ]

  const dateFilterableStyles = useDateFilterableStyles({
    getDefaultBorderColor: (theme) => ({
      dark: theme.vars.palette.secondary.main,
      light: theme.vars.palette.secondary.main,
    }),
  })

  return (
    <Box
      id="critical-high-open-tickets"
      data-testid="critical-high-open-tickets"
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingBottom: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography color="textPrimary" variant="h6">
            Open tickets by priority
          </Typography>

          {((!loading && !data?.length) || error) && (
            <Icon variant="warningOutline" size={20} color="warning" />
          )}
        </Box>

        <Box data-testid="open-tickets-export">
          <IconButton
            disabled={loading || isEmpty}
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <Icon
              sx={(theme) => ({
                color:
                  loading || isEmpty
                    ? theme.vars.palette.text.disabled
                    : theme.vars.palette.text.primary,
              })}
              variant="downloadOutline"
            />
          </IconButton>
          {isOpen && (
            <FloatingFocusManager context={context}>
              <ChartDownloadMenu
                handleOutsideClick={() => setIsOpen(false)}
                data={!data ? {} : data}
                fields={[
                  'date',
                  'openCriticalCount',
                  'openHighCount',
                  'openModerateCount',
                  'openLowCount',
                ]}
                fileName="open-tickets-by-priority"
                floatingStyles={floatingStyles}
                getFloatingProps={getFloatingProps}
                setFloating={refs.setFloating}
                chartRef={chartRef}
                svgOptions={{
                  backgroundColor: 'white',
                  style: {
                    border: 'none',
                    borderInline: 'none',
                    borderRight: 'none',
                  },
                }}
              />
            </FloatingFocusManager>
          )}
        </Box>
      </Box>

      <Card
        sx={[
          (theme) => ({
            backgroundColor: theme.vars.palette.secondary.light,
            borderRadius: '5px',
            height: '340px',
            width: '99%',
            padding: '1rem',
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            boxShadow: 'none',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.dark,
            }),
          }),
          dateFilterableStyles,
        ]}
      >
        <ChartLegend legendItems={legendItems} />
        {renderChart()}
      </Card>
    </Box>
  )
}

export default CriticalHighOpenTickets
