/* eslint-disable @getify/proper-ternary/nested */
import { createLogScale } from '@nivo/scales'

import { DashboardThreatProtection } from '@models/Dashboard'

import { ScaledChartValuesInterface } from './ThreatProtectionSummary.models'

export const determineValue = (value: number): number =>
  value === 0 || value === null ? 1 : value

export interface ChartPartValues {
  label: string
  value: number
  originalValueToDisplay: string | number
  id: string
}

export const scaleValues = (
  {
    alertCount,
    loggedEventCount,
    isLogEventCountAvailable,
    openTicketCount,
    validatedTicketCount,
  }: Pick<
    DashboardThreatProtection,
    | 'alertCount'
    | 'loggedEventCount'
    | 'openTicketCount'
    | 'isLogEventCountAvailable'
    | 'validatedTicketCount'
  >,
  featureNgMdrFlag: boolean,
  showFourthChartPart = false,
): ScaledChartValuesInterface[] => {
  const eventCountLoggedPart: ChartPartValues[] = [
    {
      label: 'Events logged',
      value: determineValue(loggedEventCount),
      originalValueToDisplay:
        loggedEventCount === null || loggedEventCount === 0
          ? 'Not available'
          : loggedEventCount,
      id: 'logged_events_count',
    },
  ]
  const alertCountDetectedPart: ChartPartValues[] = [
    {
      label: 'Alerts detected',
      value: determineValue(alertCount),
      originalValueToDisplay:
        alertCount === null ? 'Not available' : alertCount,
      id: 'alerts_count',
    },
  ]

  const escalatedValidatedAndEmptyParts = [
    {
      label: 'Escalated alerts',
      value: determineValue(openTicketCount),
      originalValueToDisplay: openTicketCount,
      id: 'escalated_alerts',
    },
    featureNgMdrFlag
      ? {
          label: 'Customer validated',
          value: determineValue(validatedTicketCount),
          originalValueToDisplay:
            validatedTicketCount === null
              ? 'Not available'
              : validatedTicketCount,
          id: 'validated_ticket_count',
        }
      : {
          label: 'Tickets opened',
          value: openTicketCount === 0 ? 1 : openTicketCount,
          originalValueToDisplay: openTicketCount,
          id: 'total_ticket_count',
        },
    {
      label: '',
      value: 1,
      id: 'empty_part',
    },
  ]

  let ticketInfo

  if (showFourthChartPart) {
    ticketInfo = [
      ...(isLogEventCountAvailable ? eventCountLoggedPart : []),
      ...alertCountDetectedPart,
      ...escalatedValidatedAndEmptyParts,
    ]
  } else {
    ticketInfo = [
      ...(isLogEventCountAvailable
        ? eventCountLoggedPart
        : alertCountDetectedPart),
      ...escalatedValidatedAndEmptyParts,
    ]
  }

  const nums = ticketInfo.map((d) => d.value)
  const minNum = nums.reduce((a, b) => (a < b ? a : b))
  const maxNum = nums.reduce((a, b) => (a > b ? a : b))

  const logScale = createLogScale(
    { type: 'log', base: 10, min: 'auto', max: 'auto' },
    { all: nums, min: minNum, max: maxNum },
    10000,
    'x',
  )

  return Object.values(ticketInfo as ChartPartValues[]).map(
    ({ label, value, id, originalValueToDisplay }) => {
      return {
        id,
        label,
        value: value === 1 ? 0 : logScale(value),
        originalValueToDisplay,
      }
    },
  )
}

export const threatSummaryHasData = (
  threatSummary: DashboardThreatProtection,
): boolean => {
  const hasUndefData =
    threatSummary.alertCount === undefined ||
    threatSummary.loggedEventCount === undefined ||
    threatSummary.openTicketCount === undefined ||
    threatSummary.loggedEventCount === null ||
    threatSummary.openTicketCount === null

  const hasZeroData =
    threatSummary.alertCount === 0 &&
    threatSummary.openCriticalTicketCount === 0 &&
    threatSummary.validatedTicketCount === 0

  return !hasZeroData && !hasUndefData
}
