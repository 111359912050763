import { Theme } from '@mui/material'

export const themeConfig = (
  mode: 'light' | 'dark' | 'system' | undefined,
  theme: Theme,
) => {
  return {
    grid: {
      line: {
        stroke:
          mode === 'dark'
            ? theme.vars.palette.secondary.lighter
            : theme.vars.palette.secondary.main,
        strokeWidth: 1,
      },
    },
    axis: {
      ticks: {
        line: {
          strokeWidth: 0,
        },
        text: {
          fontSize: 12,
          fill: theme.vars.palette.text.primary,
        },
      },
    },
  }
}
