const PurpleDashedLine = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      y="-12"
      x="-17"
      data-testid="purple-dashed-line-svg"
    >
      <g id="Frame 235">
        <path
          id="Vector 240"
          d="M2.34595 6L4.6859 10.0113C5.12655 10.7668 6.2562 10.6325 6.5075 9.79482L8.16993 4.25338C8.42338 3.40854 9.56691 3.2817 9.99933 4.05047L13.3459 10"
          stroke="#C5A2F6"
          strokeWidth="1.5"
          strokeDasharray="1 2"
        />
      </g>
    </svg>
  )
}
export default PurpleDashedLine
