import { useContext } from 'react'
import { Box, Typography } from '@mui/material'

import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
} from '@models/DetectionCoverage'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'

import { MitreOverviewContext } from '../context/MitreOverviewContext'

interface TacticDetailProps {
  customerTacticTechniques: MitreCoverageCustomer[]
  tactic: MitreCoverageTactic
}

const TacticDetail = ({
  customerTacticTechniques,
  tactic,
}: TacticDetailProps) => {
  const { coverage: customerByTactic } = useContext(MitreOverviewContext)
  const customerTactic = customerByTactic.customerByTactic.find(
    (customerTactic) => customerTactic.mitreTacticId === tactic.mitreTacticId,
  )
  const customerTechniquesCovered = customerTacticTechniques.reduce(
    (prev, curr) => (curr.totalEnabledUseCaseCount > 0 ? prev + 1 : prev),
    0,
  )

  const customerSubTechniquesCovered = customerTacticTechniques.reduce(
    (prev, curr) =>
      prev +
      curr.subtechniques.reduce(
        (prev, curr) => (curr.totalEnabledUseCaseCount > 0 ? prev + 1 : prev),
        0,
      ),
    0,
  )

  const items: SidesheetDualColumnValues[] = [
    {
      key: 'Enabled detections',
      label: 'Enabled detections',
      value: customerTactic?.totalEnabledUseCaseCount ?? 0,
    },
    {
      key: 'Eligible detections',
      label: 'Eligible detections',
      value: customerTactic?.totalEligibleUseCaseCount ?? 0,
    },
    {
      key: 'MITRE tactic',
      label: 'MITRE tactic',
      value: tactic.mitreTacticName,
    },
    { key: 'Techniques', label: 'Techniques', value: tactic.techniques.length },
    {
      key: 'Covered techniques',
      label: 'Covered techniques',
      value: customerTechniquesCovered,
    },
    {
      key: 'Sub-techniques',
      label: 'Sub-techniques',
      value: tactic.techniques.reduce(
        (prev, curr) => prev + curr.subtechniques.length,
        0,
      ),
    },
    {
      key: 'Covered sub-techniques',
      label: 'Covered sub-techniques',
      value: customerSubTechniquesCovered,
    },
    { key: 'ID', label: 'ID', value: tactic.mitreTacticId },
  ]

  const totalRequiredPerms: string[] = []

  tactic.techniques.forEach((technique) => {
    technique.permissionList.forEach((permission) => {
      if (!totalRequiredPerms.includes(permission)) {
        totalRequiredPerms.push(permission)
      }
    })
  })

  items.push({
    key: 'Required permissions',
    label: 'Required permissions',
    value: totalRequiredPerms,
  })

  const totalPlatforms: string[] = []

  tactic.techniques.forEach((technique) => {
    technique.platformList.forEach((platform) => {
      if (!totalPlatforms.includes(platform)) {
        totalPlatforms.push(platform)
      }
    })
  })

  items.push({ key: 'Platform', label: 'Platform', value: totalPlatforms })

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          padding: '1rem',
        }}
        data-testid="mitre-title-description-header"
      >
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.palette.text.secondary,
            }),
          })}
          fontWeight={500}
          variant="body2"
        >
          {tactic.mitreTacticName}
        </Typography>
        <Typography color="textPrimary" variant="body2">
          {tactic.mitreTacticDescription}
        </Typography>
      </Box>
      <CollapsiblePanel title="Details">
        <SidesheetDualColumn items={items} />
      </CollapsiblePanel>
    </>
  )
}
export default TacticDetail
