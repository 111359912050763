import { format } from 'date-fns'
import { SliceTooltipProps } from '@nivo/line'
import { Box, LinearProgress, List, Typography, useTheme } from '@mui/material'
import { BarTooltipProps } from '@nivo/bar'

import { SourceType } from '@models/EnvHealth'
import Icon from '@common/Icon'
import { linearProgressBarColor } from '@utils/linearProgressBarColor'

import { ChartData } from '../SUCharts/NoValueChart'

interface SuToolTipProps {
  toolTipData: ChartData
  licenseCapacity: number
}

const SuTooltip: React.FC<SuToolTipProps> = ({
  toolTipData,
  licenseCapacity,
}) => {
  return (
    <Box
      sx={(theme) => ({
        width: '225px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.secondary.darker,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
        }),
      })}
      data-testid="su-tooltip"
    >
      <Typography color="textPrimary" fontWeight={600} variant="caption">
        {format(toolTipData.date, 'MM/dd')}
      </Typography>
      <SuProgressBar
        toolTipData={toolTipData}
        licenseCapacity={licenseCapacity}
      />
      <Box>
        <Typography color="textPrimary" variant="caption">
          Top 3 source type deviations
        </Typography>
        <SuTopThreeSourceTypeDeviations topSourceTypes={toolTipData} />
      </Box>
    </Box>
  )
}

const SuTopThreeSourceTypeDeviations: React.FC<{
  topSourceTypes: ChartData
}> = ({ topSourceTypes }) => {
  const sortedSourceTypesByDeviation = (
    topSourceTypes.sourceTypes as unknown as SourceType[]
  )
    .filter((data) => data.name !== 'otherSourceTypes')
    .sort((a, b) => b.ingestDeviation.gb - a.ingestDeviation.gb)

  const topThreeSourceTypesForDay = sortedSourceTypesByDeviation
    .map(({ ingestDeviation, name }) => {
      const { delta, gb } = ingestDeviation
      const signedGb = gb * (delta >= 0 ? 1 : -1)
      const roundedGb =
        signedGb < 1 && signedGb > -1 ? signedGb.toFixed(2) : signedGb.toFixed()
      return (
        <Box
          component="li"
          key={name}
          sx={{
            alignItems: 'left',
            display: 'flex',
            gap: '4px',
            listStyle: 'none',
            paddingTop: '4px',
          }}
        >
          <Icon
            sx={{
              display: 'flex',
              justifyContent: 'left',
            }}
            variant={
              delta > 0 ? 'arrowUpCircleOutline' : 'arrowDownCircleOutline'
            }
            size={18}
          />
          <Typography
            fontWeight={600}
            variant="caption"
            sx={(theme) => ({
              color: theme.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.palette.text.secondary,
              }),
            })}
          >
            {roundedGb} GB
          </Typography>
          <Typography
            color="textPrimary"
            variant="caption"
            sx={{
              maxWidth: '110px',
            }}
            noWrap={true}
          >
            {' '}
            {name}
          </Typography>
        </Box>
      )
    })
    .slice(0, 3)

  return <List sx={{ gap: 0 }}>{topThreeSourceTypesForDay}</List>
}

const SuProgressBar: React.FC<SuToolTipProps> = ({
  toolTipData,
  licenseCapacity,
}) => {
  const theme = useTheme()
  const { totalGbForDay } = toolTipData
  const isDayCapacityMoreThanTotal =
    licenseCapacity && totalGbForDay >= licenseCapacity
  // ensure that the progress bar displays correct style if day's GB is
  // greater than license capacity
  const progressValue = isDayCapacityMoreThanTotal
    ? totalGbForDay + 100
    : totalGbForDay

  const breakPoints = [
    {
      threshold: licenseCapacity,
      color: theme.vars.palette.error.light,
    },
  ]

  return (
    <>
      <Box style={{ gap: 4 }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={(theme) => ({
            color: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.palette.text.secondary,
            }),
          })}
        >
          {totalGbForDay} GB
        </Typography>
        <Typography color="textPrimary" variant="caption">
          {' '}
          / {licenseCapacity} GB
        </Typography>
      </Box>
      <Box>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          color={linearProgressBarColor(breakPoints, progressValue, theme)}
          sx={(theme) => ({
            background: `linear-gradient(to right, ${linearProgressBarColor(breakPoints, progressValue, theme)} ${isDayCapacityMoreThanTotal ? totalGbForDay + 100 : totalGbForDay}% ,${theme.vars.palette.secondary.light} ${
              progressValue
            }%)`,
          })}
          data-testid="progress-bar"
        />
      </Box>
    </>
  )
}

export const SuAlertsToolTip: React.FC<Pick<SuToolTipProps, 'toolTipData'>> = ({
  toolTipData,
}) => {
  const paddingLeft = { paddingLeft: '4px' }
  return (
    <Box
      sx={(theme) => ({
        width: '225px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.secondary.darker,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
        }),
      })}
      data-testid="alerts-no-value-tooltip"
    >
      <Typography
        color="textPrimary"
        variant="caption"
        sx={(theme) => ({
          ...paddingLeft,
          borderBottom: `1px solid ${theme.palette.secondary.lighter}`,
          paddingBottom: '4px',
          marginBottom: '4px',
        })}
      >
        {format(toolTipData.date, 'MMM dd')}
      </Typography>
      <Typography
        color="textPrimary"
        variant="caption"
        sx={{
          display: 'inline',
          verticalAlign: 'top',
          ...paddingLeft,
          paddingTop: '4px',
        }}
      >
        Alerts{' '}
        <Typography
          variant="caption"
          sx={(theme) => ({
            display: 'inline',
            color: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.palette.text.secondary,
            }),
          })}
        >
          {toolTipData.totalAlertsForDay}
        </Typography>
      </Typography>
    </Box>
  )
}

export default SuTooltip

interface PastPeriodTooltipProps {
  nivoSlice: Pick<SliceTooltipProps, 'slice'>
  formattedCurrentDataPoints: ChartData[]
}

interface PastPeriodBarChartTooltipProps {
  nivoBar: BarTooltipProps<{
    currentDate: string
    pastDate: string
    currentPeriod: number
    pastPeriod: string | number
    date: string
  }>
  currentPeriodColor: string
  pastPeriodColor: string
}

// TODO: remove once past period bar chart is released
export const PastPeriodChartTooltip: React.FC<PastPeriodTooltipProps> = ({
  nivoSlice,
  formattedCurrentDataPoints,
}) => {
  const currentPeriodValue = nivoSlice.slice.points[0].data.y
  const currentPeriodDate = nivoSlice.slice.points[0].data.x
  const currentPeriodColor = nivoSlice.slice.points[0].serieColor
  const previousPeriodValue = nivoSlice.slice.points[1]?.data?.y
  const previousPeriodColor = nivoSlice.slice.points[1]?.serieColor

  let currentDate
  if (formattedCurrentDataPoints[currentPeriodDate.toString()]?.date) {
    currentDate = format(
      formattedCurrentDataPoints[currentPeriodDate.toString()]?.date,
      'EEE, MMM dd',
    )
  }

  if (currentPeriodValue && previousPeriodValue) {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
          minWidth: '110px',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.secondary.dark,
            boxShadow: theme.shadows[1],
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '5px',
          }),
        })}
      >
        <Typography
          color="textPrimary"
          sx={{ padding: 0.25 }}
          variant="caption"
        >
          {currentDate}
        </Typography>
        <Box
          sx={(theme) => ({
            padding: '4px',
            borderTop: `0.75px solid ${theme.palette.secondary.main}`,
          })}
        >
          <Box>
            <Box
              style={{
                marginBottom: 4,
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  backgroundColor: currentPeriodColor,
                }}
                sx={{ height: '14px', width: '14px', display: 'block' }}
                data-testid="past-period-tooltip-block"
              ></Box>
              <Typography
                color="textPrimary"
                fontWeight={500}
                variant="caption"
              >
                {currentPeriodValue.toString()} GB
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  backgroundColor: previousPeriodColor,
                }}
                sx={{ height: '14px', width: '14px', display: 'block' }}
                data-testid="past-period-tooltip-block"
              ></Box>
              <Typography
                color="textPrimary"
                fontWeight={500}
                variant="caption"
              >
                {previousPeriodValue.toString()} GB
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}

export const PastPeriodBarChartTooltip: React.FC<
  PastPeriodBarChartTooltipProps
> = ({ nivoBar, currentPeriodColor, pastPeriodColor }) => {
  const currentPeriodValue = nivoBar.data.currentPeriod
  const currentPeriodDate = nivoBar.data.currentDate
  const pastPeriodValue = nivoBar.data.pastPeriod

  let currentDate
  if (currentPeriodDate) {
    currentDate = format(currentPeriodDate, 'EEE, MMM dd')
  }

  if (currentPeriodValue && pastPeriodValue) {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
          minWidth: '110px',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.secondary.dark,
            boxShadow: theme.shadows[1],
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '5px',
          }),
        })}
      >
        <Typography
          color="textPrimary"
          sx={{ padding: 0.25 }}
          variant="caption"
        >
          {currentDate}
        </Typography>
        <Box
          sx={(theme) => ({
            padding: '4px',
            borderTop: `0.75px solid ${theme.palette.secondary.main}`,
          })}
        >
          <Box>
            <Box
              style={{
                marginBottom: 4,
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  backgroundColor: currentPeriodColor,
                }}
                sx={{ height: '14px', width: '14px', display: 'block' }}
                data-testid="past-period-tooltip-block"
              ></Box>
              <Typography
                color="textPrimary"
                fontWeight={500}
                variant="caption"
              >
                {currentPeriodValue.toString()} GB
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  backgroundColor: pastPeriodColor,
                }}
                sx={{ height: '14px', width: '14px', display: 'block' }}
                data-testid="past-period-tooltip-block"
              ></Box>
              <Typography
                color="textPrimary"
                fontWeight={500}
                variant="caption"
              >
                {pastPeriodValue.toString()} GB
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}
