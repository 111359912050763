/* eslint-disable security/detect-object-injection */
import { SvgIcon, SvgIconProps, SxProps, Theme } from '@mui/material'
import { forwardRef } from 'react'

import { iconMapping } from './Icons'

export interface IconProps extends SvgIconProps {
  size?: number
  variant: string
  onClick?: VoidFunction
  sx?: SxProps<Theme>
}

const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { size = 24, sx, variant, onClick } = props
  const Component = iconMapping[variant]

  if (!Component) {
    // eslint-disable-next-line no-console
    console.warn(`Icon variant "${variant}" not found`)
    return null
  }

  return (
    <SvgIcon
      {...props}
      data-testid={`icon-${variant}`}
      ref={ref}
      sx={[
        {
          height: size,
          width: size,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <Component />
    </SvgIcon>
  )
})

Icon.displayName = 'Icon'

export default Icon
