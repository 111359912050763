import { useQuery } from '@apollo/client'

import { WidgetList } from '@common/WidgetList'
import { TicketStatsAggregationOptions } from '@models/Tickets'
import {
  TICKET_STATS,
  TicketStatItem,
  TicketStatsResponse,
  TicketStatsVariables,
} from '@queries/ticket'
import { ListItemType } from 'src/interfaces/ListItemType'

interface ByIncidentGroupWidget {
  selectedCustomer: string | null
}

const ByIncidentGroupWidget = ({ selectedCustomer }) => {
  const initialState: TicketStatItem[] = [
    { key: 'Access', count: 0 },
    { key: 'Audit', count: 0 },
    { key: 'Authentication', count: 0 },
    { key: 'Cloud', count: 0 },
    { key: 'Correlation', count: 0 },
    { key: 'Data Loss Prevention', count: 0 },
    { key: 'Demisto', count: 0 },
    { key: 'Dns', count: 0 },
    { key: 'Email', count: 0 },
    { key: 'Endpoint', count: 0 },
    { key: 'Infrastructure', count: 0 },
    { key: 'Interprocess Messaging', count: 0 },
    { key: 'Intrusion Detection', count: 0 },
    { key: 'Malware', count: 0 },
    { key: 'Network', count: 0 },
    { key: 'Observium', count: 0 },
    { key: 'Phishing', count: 0 },
    { key: 'Policy Violation', count: 0 },
    { key: 'Threat Hunt', count: 0 },
    { key: 'Threat Intelligence', count: 0 },
    { key: 'Vulnerability Management', count: 0 },
    { key: 'Web', count: 0 },
  ]

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase())
  }

  const {
    data: { ticketStats: { data: statItems } } = {
      ticketStats: { data: initialState },
    },
    error,
    loading,
  } = useQuery<TicketStatsResponse, TicketStatsVariables>(TICKET_STATS, {
    variables: {
      selectedCustomer,
      input: {
        aggregateBy: TicketStatsAggregationOptions.INCIDENT_GROUP,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 120000, // 2 minutes
  })

  const widgetItems: ListItemType[] = statItems
    .filter((item: TicketStatItem) => item.key !== 'Unclassified')
    .map((item: TicketStatItem, index: number) => {
      return {
        id: index,
        disabled: true,
        loading: loading && statItems.every((item) => item.count === 0), // handles case if Apollo cache has this data
        name: capitalizeWords(item.key),
        rightText: item.count.toString(),
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <WidgetList
      hasError={error !== undefined}
      title="By incident group"
      widgetItems={widgetItems}
    />
  )
}

export default ByIncidentGroupWidget
