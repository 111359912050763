/**
 * Will return a formatted version of a number based on the number of digits provided (default being eight digits in length).
 *
 * Example:
 * Inputting the number 2946558260 with displayType 'long' will result in 2.9 billion
 * Inputting the same number with displayType 'short' will result in 2.9B
 * Inputting a number shorter than eight digits in length will simply return the number with commas in the appropriate places
 * @param number - any number
 * @param displayType - 'long' for the entire word or 'short' for just the first letter
 * @param numberLength - number of digits to compare
 * @returns string - formatted number + displayType
 */
export const formatLargeNumber = (
  number: number,
  displayType: 'short' | 'long',
  numberLength = 8,
): string => {
  if (number) {
    if (number?.toString().length > numberLength) {
      return new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: displayType,
      }).format(number)
    } else {
      return new Intl.NumberFormat().format(number)
    }
  }

  return number?.toString()
}
