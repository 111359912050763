import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import { DETECTION } from '@queries/detection'
import DetectionSideSheetContent from '@components/Coverage/DetectionCatalog/DetectionCatalogSideSheet/DetectionSideSheetContent'
import { SideSheet } from '@common/SideSheet'
import { Loader } from '@common/Loader'

interface ThreatIntelDetectionSideSheetProps {
  closeSidesheet: VoidFunction
  isOpen: boolean
  useCase?: string
}

const ThreatIntelDetectionSideSheet: React.FC<
  ThreatIntelDetectionSideSheetProps
> = ({ closeSidesheet, isOpen, useCase }) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''

  const { data: { detection } = { detection: undefined }, loading } = useQuery(
    DETECTION,
    {
      skip: !useCase,
      variables: { selectedCustomer, useCase },
    },
  )

  return (
    <SideSheet
      open={isOpen}
      title="Detection details"
      closeSideSheet={closeSidesheet}
    >
      {loading || !isOpen ? (
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <DetectionSideSheetContent
          detection={detection}
          hideThreatIntelSection
          isOpen={detection != null}
        />
      )}
    </SideSheet>
  )
}

export default ThreatIntelDetectionSideSheet
