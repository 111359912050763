/**
 * Temporarily track z-index for elements in the application.
 * These values will eventually be moved into MUI theme variables as a part of [this ticket](https://deepwatch.atlassian.net/browse/SON-5677).
 *
 * Current MUI defaults:
 *
 * | Component      | z-index |
 * |:---------------|--------:|
 * | mobile stepper | 1000    |
 * | fab            | 1050    |
 * | speed dial     | 1050    |
 * | app bar        | 1100    |
 * | drawer         | 1200    |
 * | modal          | 1300    |
 * | snackbar       | 1400    |
 * | tooltip        | 1500    |
 *
 * Source: https://mui.com/material-ui/customization/z-index/
 *
 * Also replaces {@link src/components/App/Styles/_zindex.scss} as we migrate away from SCSS
 */
export const zIndex = {
  CELL_HOVER_BUTTONS: 1,
  TREND_SELECTOR_POPOVER: 1,
  DROPDOWN_MENU: 2,
  PERMISSION_WARNING: 11,
  PAGE_BANNER: 21,
  SECURITY_INDEX_TABS: 21,
  /**
   * Must be < top nav in order for main page popovers to scroll under the top nav (top nav popovers will render above top nav because they are children)
   */
  RESIZABLE_SIDESHEET_BORDER: 31,
  CHART_DOWNLOAD_MENU: 40,
  HEADER: 100,
  POPOVER_CONTENT: 900,
  NOTIFICATION_DRAWER: 1000,
  CYBER_ASSISTANT_BUTTON: 1000,
  TOP_NAV: 1300,
  DESIGN_SYSTEM_MENU: 10000,
  MUI_DIALOG: 10000,
  SKIP_LINK: 99999,
  MAX: 9999999999,
} as const
