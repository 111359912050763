import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material'

import CrowdStrikeLogo from '@common/SVGs/CrowdStrikeLogo'
import Icon from '@common/Icon'
import DefenderLogo from '@common/SVGs/DefenderLogo'

interface Props {
  moduleLink: string | undefined
  moduleLinkText: string
  tooltipText: string
  loading?: boolean
  moduleType?: string
}

export default function ModuleLink({
  moduleLink,
  moduleLinkText,
  tooltipText,
  loading,
  moduleType,
}: Props) {
  const openModuleLink = () => {
    if (!moduleLink) {
      return
    }

    window.open(moduleLink, '_blank', 'noopener')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5rem 0',
      }}
    >
      <Button
        onClick={openModuleLink}
        sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}
      >
        {loading ? (
          <Skeleton
            data-testid="skeleton-block"
            height={24}
            sx={{ width: '100%' }}
          />
        ) : (
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Box sx={{ width: '1.5rem', height: '1.5rem' }}>
              {moduleLinkText === 'Falcon' && <CrowdStrikeLogo />}
              {moduleLinkText === 'Defender' && moduleType === 'medr' && (
                <DefenderLogo />
              )}
            </Box>

            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {moduleLinkText}
            </Typography>
            <Icon
              size={14}
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="openOutline"
            />
          </Box>
        )}
      </Button>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          Data refresh cycles
        </Typography>

        <Tooltip title={tooltipText}>
          <Icon variant="informationCircleOutline" size={19} />
        </Tooltip>
      </Box>
    </Box>
  )
}
