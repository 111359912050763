import { useMutation } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { Button, Typography, Box } from '@mui/material'

import {
  DEACTIVATE_USER,
  DeactivateUserData,
  DeactivateUserVariables,
} from '@mutations/user'
import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'
import { AlertSeverity, useToast } from '@hooks/useToast'

export interface DeactivateUserModalProps {
  userId: string
  userName: string
  closeModal: VoidFunction
}

const DeactivateUserModal: React.FC<DeactivateUserModalProps> = ({
  closeModal,
  userId,
  userName,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const { handleShowToast } = useToast()

  const [deactivateUser] = useMutation<
    DeactivateUserData,
    DeactivateUserVariables
  >(DEACTIVATE_USER, {
    refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
  })

  const toggleModal = () => {
    closeModal()
  }

  const handleDeactivateUser = async () => {
    toggleModal()
    handleShowToast(AlertSeverity.Info, 'Processing request to deactivate user')

    await deactivateUser({
      variables: {
        input: {
          userId,
        },
      },
      onCompleted: () =>
        handleShowToast(
          AlertSeverity.Success,
          'User has been deactivated successfully!',
        ),
      onError: () =>
        handleShowToast(
          AlertSeverity.Error,
          'Deactivating user has failed. Please try again in a minute.',
        ),
    })
  }

  // Focus the cancel button when the modal opens
  useEffect(() => {
    cancelButtonRef.current?.focus()
  }, [])

  return (
    <Box
      sx={{ padding: '1.5rem' }}
      id="deactivate-user-modal"
      data-testid="deactivate-user-modal"
    >
      <Typography
        fontWeight={600}
        color="textSecondary"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body1"
      >
        {`Are you sure you want to deactivate ${userName}?`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: 1,
          marginTop: '25px',
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          ref={cancelButtonRef}
          data-testid="user-status-cancel-btn"
          variant="outlined"
          onClick={() => toggleModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid="user-status-button"
          onClick={() => handleDeactivateUser()}
        >
          Deactivate
        </Button>
      </Box>
    </Box>
  )
}

export default DeactivateUserModal
