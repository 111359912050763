import { gql } from '@apollo/client'

import {
  SecurityIndexEvent,
  UnclassifiedLogSource,
  YourIndex,
} from '@models/index'
import {
  SecurityIndexBansMetrics,
  SecurityIndexCalculationDataTypes,
  SecurityIndexChangeHistory,
  SecurityIndexNearestChangeDates,
} from '@models/SecurityIndex'

import { CustomerInfoVariables } from './customer'

export interface GetSecurityIndex {
  getSecurityIndex: SecurityIndexEvent[]
}

export interface GetSecurityIndexChangeHistory {
  getSecurityIndexChangeHistory: SecurityIndexChangeHistory | null
}

export interface SecurityIndexVariables extends CustomerInfoVariables {
  startDate: string
  endDate: string
}

export interface GetSecurityIndexBanMetrics {
  getSecurityIndexBanMetrics: SecurityIndexBansMetrics | null
}

export interface GetYourSecurityIndex {
  getYourSecurityIndex: YourIndex
}

export interface GetUnclassifiedLogSources {
  getUnclassifiedLogSources: UnclassifiedLogSource[]
}

export interface GetSecurityIndexNearestChangeDates {
  getSecurityIndexNearestChangeDates: SecurityIndexNearestChangeDates
}

export interface SecurityIndexCalculation {
  securityIndexCalculation: SecurityIndexCalculationDataTypes
}

export const GET_SECURITY_INDEX_NEAREST_CHANGE_DATES = gql`
  query GetSecurityIndexNearestChangeDates(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getSecurityIndexNearestChangeDates(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      nearestStartDate
      nearestEndDate
    }
  }
`

export const GET_YOUR_SECURITY_INDEX = gql`
  query GetYourSecurityIndex(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getYourSecurityIndex(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      trends {
        yourTrend {
          index
          metricDate
        }
        avgCustomerTrend {
          index
          metricDate
        }
        avgIndustryTrend {
          index
          metricDate
        }
        pastPeriodTrend {
          index
          metricDate
        }
      }
      details {
        lifetimeIncrease
        enabledLogSourcesCount
        enabledDetectionsCount
        missingDataSourcesCount
      }
      preTransformedIndexValues {
        logSources
        detections
        enterpriseCoverage
      }
      unclassifiedLogSourceCount
      latestIndex
    }
  }
`

export const GET_SECURITY_INDEX_CHANGE_HISTORY = gql`
  query GetSecurityIndexChangeHistory(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getSecurityIndexChangeHistory(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      events {
        id
        createdAt
        sourceName
        dataSource
        type
        action
        weight
      }
    }
  }
`

export const GET_SECURITY_INDEX_BAN_METRICS = gql`
  query GetSecurityIndexBanMetrics(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getSecurityIndexBanMetrics(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      yourIndex {
        index
        delta
      }
      avgCustomerIndex {
        index
        delta
      }
      avgIndustryIndex {
        index
        delta
      }
    }
  }
`

export const GET_UNCLASSIFIED_LOG_SOURCES = gql`
  query GetUnclassifiedLogSources(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getUnclassifiedLogSources(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      index
      logSource
      dataModel
      dataType
    }
  }
`

export const SECURITY_INDEX_CALCULATION = gql`
  query SecurityIndexCalculation($selectedCustomer: String) {
    securityIndexCalculation(selectedCustomer: $selectedCustomer) {
      dataTypes {
        dataType
        maturityImpact
        enabledSourceTypes
        detectionCount
      }
    }
  }
`
