import { useRef, useState } from 'react'
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'

import Icon from '@common/Icon'
import { zIndex } from '@components/App/Styles/zIndex'
import useOutsideClick from '@hooks/useOutsideClick'

import { IndexTrendTitles } from '../IndexTrend'

const TrendSelector = ({ setChartTrends }) => {
  const indexComparisonContainerRef = useRef<HTMLDivElement>(null)
  const indexComparisonPopoverRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [toggleState, setToggleState] = useState({
    industry: false,
    deepwatch: false,
    past: false,
  })

  useOutsideClick(
    () => {
      indexComparisonPopoverRef.current?.classList.remove('visible')
      setIsVisible(false)
    },
    isVisible,
    indexComparisonPopoverRef,
    isVisible,
    indexComparisonContainerRef,
  )
  const togglePopover = () => {
    if (indexComparisonPopoverRef.current?.classList.contains('visible')) {
      setIsVisible(false)
      return indexComparisonPopoverRef.current.classList.remove('visible')
    }
    setIsVisible(true)
    return indexComparisonPopoverRef.current?.classList.add('visible')
  }

  const handleToggle = (trendTitle: string, toggleKey: string) => {
    setChartTrends(trendTitle, !toggleState[toggleKey])
    setToggleState({
      ...toggleState,
      [toggleKey]: !toggleState[toggleKey],
    })
  }

  return (
    <Box
      ref={indexComparisonContainerRef}
      id="trend-selector"
      sx={{ position: 'relative' }}
    >
      <IconButton onClick={togglePopover}>
        <Icon variant="ellipsisVertical" />
      </IconButton>
      <Box
        ref={indexComparisonPopoverRef}
        id="index-comparison-popover"
        sx={(theme) => ({
          flexDirection: 'column',
          gap: '16px',
          display: 'none',
          width: '261px',
          position: 'absolute',
          zIndex: zIndex.TREND_SELECTOR_POPOVER,
          padding: '1em',
          border: `1px solid ${theme.vars.palette.text.secondary}`,
          borderRadius: '5px',
          background: 'white',
          ...theme.applyStyles('dark', {
            background: theme.vars.palette.secondary.dark,
          }),
          boxShadow: `
          0 0 0 1px rgb(32 32 32 / 5%),
          0 6px 15px -3px rgb(0 0 0 / 10%)
        `,
          right: 0,

          '&.visible': {
            display: 'flex',
          },
        })}
      >
        <Typography variant="body1" fontWeight={600} color="textPrimary">
          Index comparison
        </Typography>
        <FormControlLabel
          tabIndex={0}
          labelPlacement="start"
          onKeyUp={({ code }: React.KeyboardEvent) =>
            code === 'Enter' &&
            handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
          }
          sx={(theme) => ({
            justifyContent: 'space-between',
            '.MuiFormControlLabel-label': {
              border: 'none',
              padding: 0,
              margin: 0,
            },
            ...theme.applyStyles('dark', {
              '.MuiFormControlLabel-label': {
                color: toggleState.industry
                  ? theme.vars.palette.text.secondary
                  : theme.vars.palette.text.primary,
              },
            }),
          })}
          control={
            <Switch
              onClick={() =>
                handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
              }
              onKeyUp={({ code }: React.KeyboardEvent) =>
                code === 'Enter' &&
                handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
              }
              checked={toggleState.industry}
              tabIndex={-1}
            />
          }
          label="Industry average"
        />
        <FormControlLabel
          tabIndex={0}
          labelPlacement="start"
          onKeyUp={({ code }: React.KeyboardEvent) =>
            code === 'Enter' &&
            handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
          }
          sx={(theme) => ({
            justifyContent: 'space-between',
            '.MuiFormControlLabel-label': {
              border: 'none',
              padding: 0,
              margin: 0,
            },
            ...theme.applyStyles('dark', {
              '.MuiFormControlLabel-label': {
                color: toggleState.industry
                  ? theme.vars.palette.text.secondary
                  : theme.vars.palette.text.primary,
              },
            }),
          })}
          control={
            <Switch
              onClick={() =>
                handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
              }
              onKeyUp={({ code }: React.KeyboardEvent) =>
                code === 'Enter' &&
                handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
              }
              checked={toggleState.deepwatch}
              tabIndex={-1}
            />
          }
          label="Deepwatch average"
        />
        <FormControlLabel
          tabIndex={0}
          labelPlacement="start"
          onKeyUp={({ code }: React.KeyboardEvent) =>
            code === 'Enter' &&
            handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')
          }
          sx={(theme) => ({
            justifyContent: 'space-between',
            '.MuiFormControlLabel-label': {
              border: 'none',
              padding: 0,
              margin: 0,
            },
            ...theme.applyStyles('dark', {
              '.MuiFormControlLabel-label': {
                color: toggleState.industry
                  ? theme.vars.palette.text.secondary
                  : theme.vars.palette.text.primary,
              },
            }),
          })}
          control={
            <Switch
              onClick={() => handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')}
              onKeyUp={({ code }: React.KeyboardEvent) =>
                code === 'Enter' &&
                handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')
              }
              checked={toggleState.past}
              tabIndex={-1}
            />
          }
          label="Past period"
        />
      </Box>
    </Box>
  )
}
export default TrendSelector
