const CalendarQuestionOutlined = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V19.5C2.25 20.7426 3.25736 21.75 4.5 21.75H19.5C20.7426 21.75 21.75 20.7426 21.75 19.5V6C21.75 4.75736 20.7426 3.75 19.5 3.75Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M6 2.25V3.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 2.25V3.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 7.5H2.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 16.24V16.159C10.7557 15.6306 10.8082 15.2101 10.9077 14.8976C11.0099 14.5851 11.1548 14.3323 11.3423 14.1391C11.5298 13.9459 11.7557 13.7698 12.0199 13.6107C12.1903 13.5028 12.3438 13.382 12.4801 13.2485C12.6165 13.115 12.7244 12.9616 12.804 12.7883C12.8835 12.615 12.9233 12.4232 12.9233 12.213C12.9233 11.9601 12.8636 11.7414 12.7443 11.5567C12.625 11.3721 12.4659 11.23 12.267 11.1306C12.071 11.0283 11.8523 10.9772 11.6108 10.9772C11.392 10.9772 11.1832 11.0226 10.9844 11.1135C10.7855 11.2045 10.6207 11.3465 10.4901 11.5397C10.3594 11.73 10.2841 11.9758 10.2642 12.2769H8.96875C8.98864 11.7655 9.1179 11.3337 9.35653 10.9814C9.59517 10.6263 9.91051 10.3579 10.3026 10.176C10.6974 9.99423 11.1335 9.90332 11.6108 9.90332C12.1335 9.90332 12.5909 10.0013 12.983 10.1974C13.375 10.3905 13.679 10.6618 13.8949 11.0113C14.1136 11.3579 14.223 11.7627 14.223 12.2258C14.223 12.5439 14.1733 12.8309 14.0739 13.0866C13.9744 13.3394 13.8324 13.5653 13.6477 13.7641C13.4659 13.963 13.2472 14.1391 12.9915 14.2925C12.75 14.4431 12.554 14.5993 12.4034 14.7613C12.2557 14.9232 12.1477 15.115 12.0795 15.3366C12.0114 15.5582 11.9744 15.8323 11.9688 16.159V16.24H10.75ZM11.3935 18.8309C11.1605 18.8309 10.9602 18.7485 10.7926 18.5837C10.625 18.4161 10.5412 18.2144 10.5412 17.9786C10.5412 17.7456 10.625 17.5468 10.7926 17.382C10.9602 17.2144 11.1605 17.1306 11.3935 17.1306C11.6236 17.1306 11.8224 17.2144 11.9901 17.382C12.1605 17.5468 12.2457 17.7456 12.2457 17.9786C12.2457 18.1349 12.206 18.2783 12.1264 18.409C12.0497 18.5368 11.9474 18.6391 11.8196 18.7158C11.6918 18.7925 11.5497 18.8309 11.3935 18.8309Z"
      fill="currentColor"
    />
  </svg>
)

export default CalendarQuestionOutlined
