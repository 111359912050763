/* eslint-disable security/detect-object-injection */
import { BarDatum, BarLayer, ResponsiveBar } from '@nivo/bar'
import { useTooltip } from '@nivo/tooltip'
import { Box, Typography, useTheme } from '@mui/material'

import { toTimeUnits } from '@utils/DateTimeUtils'
import { MttxBarChartDataPoint } from '@models/MttxDetails'
import { ChartLegend } from '@common/ChartLegend'
import { Loader } from '@common/Loader'

import { chartColors, priorityOrder, nivoTheme } from '../../Mttx.utils'

export const customMTTXBarTooltip = (point) => {
  const { priority, totalTickets, averageDuration, ticketType } =
    point.data.data

  return (
    <Box
      data-testid="mttx-bar-tool-tip"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        borderRadius: '5px',
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
          border: `1px solid ${theme.vars.palette.secondary.light}`,
        }),
      })}
    >
      {ticketType ? null : (
        <Typography
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
            padding: '0 4px',
            ...theme.applyStyles('dark', {
              borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
            }),
          })}
          variant="body2"
        >
          {priority}
        </Typography>
      )}
      <Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', padding: '0 4px' }}>
          <Typography fontWeight={500} variant="body2">
            Tickets
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {totalTickets}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', padding: '0 4px' }}>
          <Typography fontWeight={500} variant="body2">
            MTTN
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {toTimeUnits(averageDuration)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const CustomBarComponent = ({ bar }) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const handleMouseEnter = (event) => {
    const tooltip = customMTTXBarTooltip(bar)
    showTooltipFromEvent(tooltip, event)
  }

  const handleMouseLeave = () => {
    hideTooltip()
  }

  const handleMouseMove = (event) => {
    const tooltipContent = customMTTXBarTooltip(bar)
    showTooltipFromEvent(tooltipContent, event)
  }
  // Calculates the x position to center the bar in its scale band
  const x = bar.x + bar.width / 2 - 8

  return (
    <rect
      data-testid={'mttx-bar'}
      x={x}
      y={bar.y}
      width={16}
      height={bar.height}
      fill={bar.color}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    />
  )
}

interface MttxBarProps {
  data: MttxBarChartDataPoint[]
  loading: boolean
  error?: boolean
  priority: string
}

export const MTTXBar: React.FC<MttxBarProps> = ({
  data,
  error,
  loading,
  priority,
}) => {
  const theme = useTheme()

  const barChartData = loading
    ? [{ priority, averageDuration: 0, totalTickets: 0 }]
    : data
        ?.map((item) => ({
          priority: item.priority,
          totalTickets: item.totalTickets ?? 0,
          averageDuration: item.averageDuration ?? 0,
          ticketType: item.ticketType,
        }))
        .sort(
          (a, b) =>
            priorityOrder.indexOf(a.priority) -
            priorityOrder.indexOf(b.priority),
        )

  const isEmpty = barChartData?.every((item) => item.totalTickets === 0)

  const EmptyStateLayer = () => (
    <Box
      component="text"
      x="40%"
      y="40%"
      textAnchor="middle"
      dominantBaseline="central"
      sx={(theme) => ({
        fill: theme.vars.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
      })}
    >
      Not available
    </Box>
  )

  const LoadingLayer = () => (
    <foreignObject
      id="loading-layer"
      data-testid="loading-layer"
      x="30%"
      y="30%"
      width="100%"
      height="100%"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader strokeWidth={1} size={40} />
    </foreignObject>
  )

  const BarLayer = ({ bars }) => {
    return bars.map((bar) => <CustomBarComponent key={bar.key} bar={bar} />)
  }

  const getChartData = (): BarDatum[] => {
    if (loading || !data.length) {
      return [{ priority, averageDuration: 0, totalTickets: 0, ticketType: '' }]
    }

    return data
      .map((item) => ({
        priority: item.priority,
        totalTickets: item.totalTickets ?? 0,
        averageDuration: item.averageDuration ?? 0,
        ticketType: item.ticketType,
      }))
      .sort(
        (a, b) =>
          priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority),
      )
  }

  const getCustomLayers = (): BarLayer<BarDatum> => {
    if (loading) {
      return LoadingLayer
    } else if (error || (data && isEmpty)) {
      return EmptyStateLayer
    }
    return BarLayer
  }

  const getIndexBy = () => {
    if (data.at(0)?.priority) {
      return 'priority'
    } else if (data.at(0)?.ticketType) {
      return 'ticketType'
    } else {
      return ''
    }
  }

  const legendItems = data
    .map((item) => {
      if (item.ticketType && priority !== 'All') {
        return {
          label: item.ticketType,
          color: chartColors(theme, priority, item.ticketType),
        }
      } else if (item.ticketType) {
        return {
          label: item.ticketType,
          color: chartColors(theme, 'All', item.ticketType),
        }
      }

      return {
        label: item.priority,
        color: chartColors(theme, item.priority),
      }
    })
    .sort(
      (a, b) => priorityOrder.indexOf(a.label) - priorityOrder.indexOf(b.label),
    )

  return (
    <>
      <ChartLegend legendItems={legendItems} />
      <Box
        id="mttx-bar-chart"
        data-testid="mttx-bar-chart"
        sx={{ height: '265px' }}
      >
        <ResponsiveBar
          data={getChartData()}
          keys={['averageDuration']}
          indexBy={getIndexBy()}
          margin={{ top: 36, right: 16, bottom: 26, left: 65 }}
          padding={0.3}
          colors={(bar) =>
            chartColors(
              theme,
              (bar.data.priority as string) ?? priority,
              bar.data.ticketType as string,
            )
          }
          colorBy="id"
          theme={nivoTheme(theme)}
          axisBottom={{
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            format: (value) => toTimeUnits(value),
            tickValues: 5,
          }}
          animate={true}
          enableGridY={false}
          enableLabel={false}
          isInteractive={true}
          layers={['grid', 'axes', getCustomLayers(), 'markers', 'annotations']}
          tooltip={(point) => customMTTXBarTooltip(point)}
        />
      </Box>
    </>
  )
}
