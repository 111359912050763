import { ReactNode, createContext, useState } from 'react'
import { RowSelectionState } from '@tanstack/react-table'

import { useMostRecentValue } from '@hooks/index'

import { ThreatIntelReportsContextModel } from '../types'
import { THREAT_INTEL_REPORTS_TABLE_ROW_ID_PREFIX } from '../components/ThreatIntelReportsTable/ThreatIntelReportsTable'

interface ThreatIntelReportsContextProviderProps {
  children: ReactNode
}

export const ThreatIntelReportsContext =
  createContext<ThreatIntelReportsContextModel | null>(null)

export const ThreatIntelReportsContextProvider: React.FC<
  ThreatIntelReportsContextProviderProps
> = ({ children }) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const openSideSheet = (id: string) =>
    setRowSelection((previousSelection) =>
      Object.keys(previousSelection).includes(id) ? {} : { [id]: true },
    )

  const activeReportId = Object.keys(rowSelection)[0] ?? null
  const mostRecentActiveReportId = useMostRecentValue(activeReportId)

  const closeSideSheet = () => {
    /** When closing the sidesheet, re-focus the row which was selected to improve a11y ux */
    if (mostRecentActiveReportId) {
      document
        .getElementById(
          `${THREAT_INTEL_REPORTS_TABLE_ROW_ID_PREFIX}${mostRecentActiveReportId}`,
        )
        ?.focus()
    }
    setRowSelection({})
  }

  return (
    <ThreatIntelReportsContext.Provider
      value={{
        activeReportId,
        isSideSheetOpen: Object.keys(rowSelection).length > 0,
        openSideSheet,
        closeSideSheet,
        setRowSelection,
        rowSelection,
      }}
    >
      {children}
    </ThreatIntelReportsContext.Provider>
  )
}
