import { useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'

import { WidgetList } from '@common/WidgetList'
import { TicketStateEnum, TicketStatsAggregationOptions } from '@models/Tickets'
import {
  TICKET_STATS,
  TicketStatItem,
  TicketStatsResponse,
  TicketStatsVariables,
} from '@queries/ticket'
import { Paths } from '@components/App/Types'
import { ListItemType } from 'src/interfaces/ListItemType'

interface ByStatusWidget {
  selectedCustomer: string | null
}

const ByStatusWidget = ({ selectedCustomer }: ByStatusWidget) => {
  const navigate = useNavigate()
  const { search: currentCustomer } = useLocation()
  const theme = useTheme()

  const initialState: TicketStatItem[] = [
    { key: TicketStateEnum.New, count: 0 },
    { key: TicketStateEnum.InProgressCustomer, count: 0 },
    { key: TicketStateEnum.InProgressDW, count: 0 },
    { key: TicketStateEnum.InProgressVendor, count: 0 },
    { key: TicketStateEnum.Resolved, count: 0 },
    { key: TicketStateEnum.OnHold, count: 0 },
  ]

  const {
    data: { ticketStats: { data: statItems } } = {
      ticketStats: { data: initialState },
    },
    error,
    loading,
  } = useQuery<TicketStatsResponse, TicketStatsVariables>(TICKET_STATS, {
    variables: {
      selectedCustomer,
      input: {
        aggregateBy: TicketStatsAggregationOptions.TICKET_STATUS,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 120000, // 2 minutes
  })

  const handleWidgetItemClick = (filter: string) => {
    if (currentCustomer) {
      navigate(
        `${Paths.TICKET_LIBRARY}${currentCustomer}&selectedFilters={${filter}}`,
      )
    } else {
      navigate(`${Paths.TICKET_LIBRARY}?selectedFilters={${filter}}`)
    }
  }

  const widgetItems: ListItemType[] = statItems
    .filter(
      (item: TicketStatItem) =>
        item.key !== TicketStateEnum.Closed &&
        item.key !== TicketStateEnum.Cancelled,
    )
    .map((item: TicketStatItem, index: number) => {
      const resultItem: ListItemType = {
        id: index,
        disabled: loading || !item.count,
        loading: loading && statItems.every((item) => item.count === 0), // handles case if Apollo cache has this data
        name: item.key,
        onClick: () => {
          handleWidgetItemClick(`"state":["${item.key}"]`)
        },
        rightText: item.count.toString(),
      }

      switch (item.key) {
        case TicketStateEnum.OnHold:
          resultItem.leftIcon = 'pauseCircleOutline'
          break
        case TicketStateEnum.Resolved:
          resultItem.iconColor = theme.vars.palette.success.main
          resultItem.leftIcon = 'checkmarkCircleOutline'
          break
        case TicketStateEnum.New:
          resultItem.leftIcon = 'statusNew'
          break
        default:
          resultItem.leftIcon = 'statusInProgress'
          resultItem.iconColor = theme.vars.palette.important.main
          break
      }

      return resultItem
    })

  return (
    <WidgetList
      hasError={error !== undefined}
      title="By status"
      widgetItems={widgetItems}
    />
  )
}

export default ByStatusWidget
