import { Avatar } from '@mui/material'

import { useAvatarInitials } from '@hooks/useAvatarInitials'
import { UserInfo } from '@models/User'
import { ListItemType } from 'src/interfaces/ListItemType'

export const buildSelectOptions = (customers: UserInfo[]) => {
  const selectOptions: ListItemType[] = [
    {
      id: '0',
      name: '--',
    },
  ]

  customers.forEach(({ id = 0, firstName, lastName, type }) => {
    selectOptions.push({
      id,
      name: `${firstName} ${lastName}`,
      leftAlternateImage: (
        <Avatar
          sx={{ width: 24, height: 24 }}
          variant={type}
          data-testid="user-avatar"
        >
          {useAvatarInitials({ firstName, lastName })}
        </Avatar>
      ),
    })
  })

  return selectOptions
}

const baseMapSettingToLabel = {
  contactUser: 'Customer contact',
  assignmentGroup: 'Assignment Group',
  category: 'Category',
  subcategory: 'Subcategory',
  channel: 'Channel',
  caseType: 'Case type',
  agentType: 'Agent type',
}

export const mapSettingToLabel = {
  ...baseMapSettingToLabel,
  serviceOffering: 'Service Offering',
}

export const ngMdrMapSettingsToLabel = {
  ...baseMapSettingToLabel,
  module: 'Module',
}
