import React from 'react'
import { Box, Button, FormControlLabel, Switch } from '@mui/material'

import { handleKeyboardAction } from '@utils/index'
import Icon from '@common/Icon'

interface DetectionCatalogControls {
  handleOpenFilterSideSheetClick: () => void
  handleIncludeToggle: (toggle?: boolean) => void
  toggleState: boolean
  loading: boolean
  filterButtonRef?: React.RefObject<HTMLButtonElement>
}

const DetectionCatalogControls: React.FC<DetectionCatalogControls> = ({
  handleOpenFilterSideSheetClick,
  handleIncludeToggle,
  toggleState,
  loading,
  filterButtonRef,
}) => {
  return (
    <Box
      data-testid="detection-catalog-controls"
      sx={{ display: 'flex', alignItems: 'baseline' }}
    >
      <FormControlLabel
        tabIndex={0}
        labelPlacement="start"
        onKeyUp={(event) =>
          handleKeyboardAction(event, () => handleIncludeToggle(toggleState))
        }
        data-testid="include-unavailable-detections-label"
        control={
          <Switch
            onClick={() => handleIncludeToggle(toggleState)}
            sx={{
              margin: '0 8px',
            }}
            checked={toggleState}
            disabled={loading}
            tabIndex={-1}
          />
        }
        label="Include not applicable"
      />

      <Button
        data-testid="detection-catalog-controls-filter-button"
        onClick={!loading ? handleOpenFilterSideSheetClick : undefined}
        ref={filterButtonRef}
        sx={{ gap: 1 }}
        size="large"
      >
        Filter
        <Icon
          variant={'filter'}
          sx={(theme) => ({ color: theme.vars.palette.primary.main })}
        />
      </Button>
    </Box>
  )
}

export default DetectionCatalogControls
