import { useContext, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { captureException } from '@sentry/react'

import { Context } from '@components/App'
import { useTicketEditContext } from '@hooks/index'
import { AddTicketCommentVariables, UPDATE_TICKET } from '@mutations/ticket'
import Icon from '@common/Icon'

import { TicketActivityTypeEnum, TicketCommentActivity } from '../../Types'
import { updateTicketInCache } from '../../utils'
import { convertTicketActivityToTicketComment } from '../TicketActivity/TicketActivity.utils'

type FormValues = {
  comment: string
}

interface TicketCommentData {
  updateTicket: boolean
}

export interface Props {
  ticketId: string
  disabled?: boolean
  onSubmit: (pendingActivityItem: TicketCommentActivity) => void
  onCompleted: (pendingActivityItemId: string) => void
  onError: (pendingActivityItemId: string, error: ApolloError) => void
}

const ActivityFormSchema = Yup.object().shape({
  comment: Yup.string().trim().required('Comment is required'),
})

const TicketCommentForm: React.FC<Props> = ({
  ticketId,
  disabled,
  onSubmit,
  onCompleted,
  onError,
}) => {
  const {
    state: {
      user: { id: userId, firstName, lastName, isDWEmployee },
    },
  } = useContext(Context)

  const { isEditable } = useTicketEditContext()

  const [addTicketComment, { loading }] = useMutation<
    TicketCommentData,
    AddTicketCommentVariables
  >(UPDATE_TICKET)

  const [externalError, setExternalError] = useState<ApolloError | null>(null)

  const initialValues: FormValues = {
    comment: '',
  }

  const handleFormSubmit = async ({ comment }: FormValues) => {
    if (loading) return

    setExternalError(null)

    const pendingActivityItem: TicketCommentActivity = {
      createdDate: new Date().toISOString(),
      id: crypto.randomUUID(),
      status: 'pending',
      text: comment,
      type: TicketActivityTypeEnum.Comment,
      userInfo: {
        id: userId,
        firstName,
        lastName,
        type: isDWEmployee ? 'dw' : 'cust',
      },
    }

    resetForm()

    try {
      onSubmit(pendingActivityItem)

      const response = await addTicketComment({
        variables: {
          input: {
            ticketId,
            ticketData: {
              comment,
            },
          },
        },
      })

      if (response.data?.updateTicket) {
        updateTicketInCache({
          ticketId,
          newFields: {
            comments: [
              convertTicketActivityToTicketComment(pendingActivityItem),
            ],
          },
        })
        onCompleted(pendingActivityItem.id)
      }
    } catch (error) {
      captureException(error)
      onError(pendingActivityItem.id, error as ApolloError)
      setExternalError(error as ApolloError)
    }
  }

  const { handleChange, handleSubmit, resetForm, values } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: ActivityFormSchema,
  })

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        sx={{
          padding: '1rem 0',
          ...(externalError && { outline: 'none' }),
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSubmit()}
                  disabled={!isEditable || disabled}
                >
                  <Icon variant="send" />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        placeholder="Type your comment here&hellip;"
        type="text"
        name="comment"
        onChange={handleChange}
        value={values.comment}
        variant="outlined"
        disabled={loading || disabled}
      />
    </form>
  )
}

export default TicketCommentForm
