import { GET_TICKET_STATUS } from '@queries/ticket'

import { TicketStatusData } from '../../Types'
import TicketEditCard from '../TicketEditCard'
import TicketDataHandler from '../TicketDataHandler'
import TicketStatusForm from './components/TicketStatusForm'

const TicketStatus = () => {
  return (
    <TicketEditCard data-testid="ticket-status">
      <TicketDataHandler<TicketStatusData>
        graphqlQuery={GET_TICKET_STATUS}
        renderContent={(data) => <TicketStatusForm data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketStatus
