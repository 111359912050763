import { ResponsiveBar } from '@nivo/bar'
import { Box, useColorScheme, useTheme } from '@mui/material'

import { colors } from '@design-system/theme'
import { themeConfig } from '@common/nivo/utils'

import { keyColors } from '../SourceUtilization.utils'

const UtilizationByDateByIndexChart = ({ data }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
    >
      <ResponsiveBar
        data={data}
        keys={['value1', 'value2', 'value3']}
        indexBy="ranking"
        margin={{
          top: 50,
          right: 19,
          bottom: 36,
          left: 36,
        }}
        padding={0.6}
        groupMode="grouped"
        indexScale={{ type: 'band', round: true }}
        borderColor={colors.util.navy[400]}
        valueScale={{
          type: 'linear',
          max: 'auto',
          min: 0,
        }}
        colors={keyColors}
        enableLabel={false}
        theme={themeConfig(mode, theme)}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'food',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0,
        }}
      />
    </Box>
  )
}

export default UtilizationByDateByIndexChart
