import { gql } from '@apollo/client'

export interface EDRBanVariables {
  selectedCustomer: string | null
  startDate?: string
  endDate?: string
}

export interface EDRBanItem {
  current: number
  delta: number
  total: number
}

export interface EDRBanResponse {
  alertsToContainment: EDRBanItem
  containedAssets: EDRBanItem
  escalatedAlerts: EDRBanItem
  licenseUtilization: EDRBanItem
  openTickets: EDRBanItem
  rfmSensors: EDRBanItem
}

export interface EDRChartItem {
  hostname: string
  alertCount: number
  criticalCount: number
  highCount: number
  mediumCount: number
  lowCount: number
  informationalCount: number
}

export const EDR_ASSETS = gql`
  query EDRAssets(
    $selectedCustomer: String
    $pagination: PaginationInput
    $sorting: SortingInput
    $filters: [FilterInput]
  ) {
    edrAssets(
      selectedCustomer: $selectedCustomer
      input: { pagination: $pagination, filters: $filters, sorting: $sorting }
    ) {
      data {
        deviceId
        agentVersion
        hostname
        lastReboot
        lastSeen
        online
        operatingSystem
        platform
        rfmStatus
        status
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const EDR_ASSETS_FILTER_OPTIONS = gql`
  query EDRAssetsFilterOptions($selectedCustomer: String) {
    edrAssetsFilterOptions(selectedCustomer: $selectedCustomer) {
      platform
      operatingSystem
      rfmStatus
      status
    }
  }
`

export const EDR_OVERVIEW_BANS = gql`
  query EDROverviewBans($selectedCustomer: String) {
    alertsToContainment: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: ALERTS_TO_CONTAINMENT }
    ) {
      delta
      total
    }
    containedAssets: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: CONTAINED_ASSETS }
    ) {
      delta
      total
    }
    escalatedAlerts: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: ESCALATED_ALERTS }
    ) {
      delta
      total
    }
    licenseUtilization: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: LICENSE_UTILIZATION }
    ) {
      current
      total
    }
    openTickets: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: OPEN_TICKETS }
    ) {
      total
    }
    rfmSensors: edrBan(
      selectedCustomer: $selectedCustomer
      input: { banType: RFM_SENSORS }
    ) {
      delta
      total
    }
  }
`
export const TOP_ASSETS_BY_ALERTS = gql`
  query TopAssetsByAlerts($selectedCustomer: String) {
    edrChart(
      selectedCustomer: $selectedCustomer
      input: { chartType: TOP_ASSETS_BY_ALERT_SEVERITY }
    ) {
      chartType
      dataPoints {
        hostname
        alertCount
        criticalCount
        highCount
        mediumCount
        lowCount
        informationalCount
      }
    }
  }
`
