import { useState, MouseEvent, FocusEvent, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'
import { Box, IconButton, Typography, Card, useTheme } from '@mui/material'
import { SingleValue } from 'react-select'

import { Dropdown, OptionType } from '@common/Dropdown'
import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../../context/MitreOverviewContext'
import MitreTacticsCards from './MitreTacticsCards'

export default function MitreCarousel() {
  const {
    mitreSelection,
    hasUserClosedSideSheet,
    carouselTechnique,
    coverage,
    handleSideSheet,
    resetSelectionState,
    setCarouselTechnique,
    setHasUserClosedSideSheet,
    setMitreSelection,
    handleTooltipEnter,
    handleTooltipLeave,
  } = useMitreOverviewContext()
  const theme = useTheme()
  /**
   * the carouselTactic state below is used to keep track of the tactic
   * in this component separately from its parent as it doesn't handle an
   * "undefined" tactic possibly passed from its parent and should always
   * be a number
   */
  const [carouselTactic, setCarouselTactic] = useState(
    mitreSelection.tactic || 0,
  )

  /**
   * sets the list view/carousel tactic and opens
   * or closes the sidesheet based off of current
   * mitreSelectionState
   * @param tactic
   */
  const setAndPropagateTactic = (tactic: number) => {
    setCarouselTactic(tactic)
    // eslint-disable-next-line security/detect-object-injection
    const newTechnique = coverage?.dw[tactic].techniques.findIndex(
      (technique) => technique.mitreTechniqueId === carouselTechnique,
    )

    /**
     * open sidesheet for newly selected tactic that has the same
     * technique if the user hasn't previous closed the sidesheet
     */
    if (hasUserClosedSideSheet) {
      setCarouselTechnique(undefined)
    } else if (newTechnique !== -1) {
      setMitreSelection({
        tactic,
        technique: newTechnique,
        subtechnique: mitreSelection.subtechnique,
      })
      setHasUserClosedSideSheet(false)
      handleSideSheet(tactic, newTechnique, mitreSelection.subtechnique, () => {
        // eslint-disable-next-line security/detect-object-injection
        resetSelectionState(coverage.dw[tactic].techniques[newTechnique])
      })

      return
    }

    /**
     * close tactic/technique when scrolling through carousel where
     * there is no match for a technique
     */
    if (mitreSelection.tactic !== undefined) {
      handleSideSheet(
        mitreSelection.tactic,
        mitreSelection.technique,
        mitreSelection.subtechnique,
      )
    }

    setMitreSelection({
      tactic: undefined,
      technique: undefined,
      subtechnique: mitreSelection.subtechnique,
    })
  }

  const handleDecrement = () => {
    let newIndex = carouselTactic - 1
    if (newIndex < 0) {
      newIndex = coverage.dw.length - 1
    }

    setAndPropagateTactic(newIndex)
  }
  const handleIncrement = () => {
    let newIndex = carouselTactic + 1

    if (newIndex >= coverage.dw.length) {
      newIndex = 0
    }

    setAndPropagateTactic(newIndex)
  }

  const handleSelectChange = (option: SingleValue<OptionType>) => {
    const newTacticIndex = coverage.dw.findIndex(
      (tactic) => tactic.mitreTacticId === option?.value,
    )

    setAndPropagateTactic(newTacticIndex)
  }

  const handleTacticEnter = (
    event: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>,
    tactic: string,
  ) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <Typography variant="caption" color={theme.palette.text.primary}>
          {tactic}
        </Typography>,
      )
    })

    handleTooltipEnter(event, 2, div.innerHTML)
  }

  const options: OptionType[] = useMemo(
    () =>
      coverage.dw.map((tactic) => ({
        value: tactic.mitreTacticId,
        label: tactic.mitreTacticName,
      })),
    [coverage.dw],
  )

  return (
    <>
      <Box
        data-testid="mitre-list-carousel"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '3px',
          padding: '4px 16px',
          ...theme.applyStyles('dark', {
            background: theme.palette.secondary.light,
          }),
        })}
      >
        {/* Buttons and select list */}
        <Box
          data-testid="mitre-carousel-square-container"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleDecrement}>
            <Icon variant="arrowBackCircle" />
          </IconButton>

          <Box
            data-testid="mitre-tactic-and-chevron"
            sx={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              gap: '8px',
              width: '15rem',
              marginBottom: '1rem',
            }}
          >
            <Dropdown
              controlStyles={{
                minWidth: '200px',
              }}
              id="mitre-tactic-select"
              onChange={handleSelectChange}
              options={options}
              isSearchable={false}
              value={options.find(
                (opt) =>
                  opt.value === coverage.dw[carouselTactic].mitreTacticId,
              )}
            />
          </Box>
          <IconButton onClick={handleIncrement}>
            <Icon variant="arrowForwardCircle" />
          </IconButton>
        </Box>
        {/* Square indicators */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1px',
            gap: '10px',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {coverage.dw.map((tactic, tacticIndex) => (
            <Box
              data-testid="mitre-carousel-indicator"
              key={tactic.mitreTacticId}
              tabIndex={0}
              onMouseOver={(event) =>
                handleTacticEnter(event, tactic.mitreTacticName)
              }
              onFocus={(event) =>
                handleTacticEnter(event, tactic.mitreTacticName)
              }
              onMouseOut={handleTooltipLeave}
              onBlur={handleTooltipLeave}
              onClick={() => setAndPropagateTactic(tacticIndex)}
              onKeyDown={(event) => {
                if (['Enter', 'NumpadEnter'].includes(event.code)) {
                  setAndPropagateTactic(tacticIndex)
                }
              }}
              sx={(theme) => ({
                height: '5px',
                maxWidth: '24px',
                background:
                  carouselTactic === tacticIndex
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled,
                transition: 'all 0.2s ease-in-out',
                cursor: 'pointer',
                width: '100%',
                '&:hover': {
                  background: theme.palette.text.primary,
                },
              })}
              role={'button'}
            />
          ))}
        </Box>
      </Box>
      {/* Tactic Cards */}
      <Card
        elevation={0}
        sx={(theme) => ({
          width: '100%',
          border: 'none',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.secondary.main,
          }),
        })}
      >
        <MitreTacticsCards carouselTactic={carouselTactic} />
      </Card>
    </>
  )
}
