import { createContext, useContext, useState } from 'react'

import { UUID } from 'crypto'

// Info: Enum to match default alert severity from MUI Alert component
export enum AlertSeverity {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export type ToastMessage = {
  id: UUID
  text: string
  messageType: AlertSeverity
  secondsToExpire: number
}

export type ToastMessageContextModel = {
  toastMessages: ToastMessage[]
  handleShowToast: (
    messageType: ToastMessage['messageType'],
    alertText: ToastMessage['text'],
    secondsToExpire?: ToastMessage['secondsToExpire'],
  ) => void
  handleToastClose: (id: ToastMessage['id']) => void
  open: boolean
  secondsToExpire?: number
}
export const ToastMessageContext = createContext<ToastMessageContextModel>({
  toastMessages: [],
  handleShowToast: () => ({}),
  handleToastClose: () => ({}),
  open: false,
})

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [toastMessages, setToastMessages] = useState<ToastMessage[]>([])

  const handleShowToast = (
    messageType: AlertSeverity,
    alertText: string,
    secondsToExpire?: number,
  ) => {
    const newToastMessage: ToastMessage = {
      id: crypto.randomUUID(),
      text: alertText,
      messageType: messageType,
      secondsToExpire: secondsToExpire!,
    }
    setToastMessages((prevToastMessages) => [
      ...prevToastMessages,
      newToastMessage,
    ])
    setOpen(true)
  }

  const handleToastClose = (messageId: string) => {
    setToastMessages((prevToastMessages) =>
      prevToastMessages.filter((message) => message.id !== messageId),
    )
    setOpen(false)
  }

  return (
    <ToastMessageContext.Provider
      value={{
        toastMessages,
        handleShowToast,
        handleToastClose,
        open,
      }}
    >
      {children}
    </ToastMessageContext.Provider>
  )
}
export const useToast = () => {
  const context = useContext(ToastMessageContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
