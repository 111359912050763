import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { RowSelectionState, Updater } from '@tanstack/react-table'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import { Ticket } from '@models/Tickets'

import TicketsWaitingOnCustomer from './TicketsWaitingOnCustomer/TicketsWaitingOnCustomer'
import Tickets14DaysOldTable from './Tickets14DaysOld/Tickets14DaysOldTable'
import ByPriorityWidget from './widgets/ByPriorityWidget'
import ByStatusWidget from './widgets/ByStatusWidget'
import ByModuleWidget from './widgets/ByModuleWidget'
import RecentlyClosedTicketsTable from './RecentlyClosedTicketsTable/RecentlyClosedTicketsTable'
import TicketLibrarySideSheet from '../TicketLibrary/TicketLibrarySideSheet/TicketLibrarySideSheet'
import { emptyTicket } from '../TicketLibrary/TicketLibrary.utils'
import ByIncidentGroupWidget from './widgets/ByIncidentGroupWidget'
import TicketsWaitingOnMe from './TicketsWaitingOnMe/TicketsWaitingOnMe'
import TicketOverviewBans from './TicketOverviewBans/TicketOverviewBans'

const TicketOverview = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''

  const [selectedTicket, setSelectedTicket] = useState<Ticket | undefined>()
  const { featureTicketsWaitingOnMeTable } = useFlags()

  return (
    <Box sx={{ overflowY: 'auto', padding: '1.5rem', width: '100%' }}>
      <TicketOverviewBans selectedCustomer={selectedCustomer} />

      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'minmax(200px, 3fr) 1fr',
        }}
      >
        <Box
          data-testid="ticket-overview-content-tables"
          sx={{ gridColumn: '1' }}
        >
          {featureTicketsWaitingOnMeTable && (
            <TicketsWaitingOnMe
              selectedCustomer={selectedCustomer}
              onTicketSelected={setSelectedTicket}
            />
          )}
          <TicketsWaitingOnCustomer
            selectedCustomer={selectedCustomer}
            onTicketSelected={setSelectedTicket}
          />
          <Tickets14DaysOldTable
            selectedCustomer={selectedCustomer}
            onTicketSelected={setSelectedTicket}
          />
          <RecentlyClosedTicketsTable
            selectedCustomer={selectedCustomer}
            onTicketSelected={setSelectedTicket}
          />
        </Box>

        <Box
          data-testid="ticket-overview-content-side-widgets"
          sx={{ gridColumn: '2' }}
        >
          <ByPriorityWidget selectedCustomer={selectedCustomer} />
          <ByStatusWidget selectedCustomer={selectedCustomer} />
          <ByModuleWidget selectedCustomer={selectedCustomer} />
          <ByIncidentGroupWidget selectedCustomer={selectedCustomer} />
        </Box>
      </Box>
      <TicketLibrarySideSheet
        data-testid="ticketDetails"
        isOpen={!!selectedTicket}
        closeSideSheet={() => setSelectedTicket(undefined)}
        focusedTicket={selectedTicket ?? emptyTicket}
      />
    </Box>
  )
}

export const handleOnRowSelectionChange = (
  valueFn: Updater<RowSelectionState>,
  rowSelection: RowSelectionState,
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>,
  data: Ticket[],
  onTicketSelected?: (ticket: Ticket | undefined) => void,
) => {
  if (typeof valueFn === 'function') {
    const updatedRowSelection = valueFn(rowSelection)
    setRowSelection(updatedRowSelection)

    // Get all selected rows based on the updatedRowSelection
    const selectedRows = Object.keys(updatedRowSelection).reduce((acc, key) => {
      if (updatedRowSelection[`${key}`]) {
        const row = data.find((row) => row.sysId.toString() === key)
        if (row) {
          acc.push(row)
        }
      }
      return acc
    }, [] as Ticket[])

    // Call the onRowSelectionChange function with the selected rows
    onTicketSelected?.(selectedRows.at(0))
  }
}

export default TicketOverview
