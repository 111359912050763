/* eslint-disable security/detect-object-injection */
import {
  CustomLayerProps,
  Layer,
  ResponsiveLine,
  Serie,
  SliceTooltipProps,
} from '@nivo/line'
import { format } from 'date-fns'
import { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import { toTimeUnits } from '@utils/DateTimeUtils'
import {
  MttxDimension,
  MttxLineChartDataPoint,
  MttxSegment,
} from '@models/MttxDetails'
import { TicketTypeEnum } from '@models/Tickets'
import { ChartLegend } from '@common/ChartLegend'
import { Loader } from '@common/Loader'

import { chartColors, nivoTheme } from '../../Mttx.utils'

interface MttxLineProps {
  data?: MttxLineChartDataPoint[]
  securityData?: MttxLineChartDataPoint[]
  engineeringData?: MttxLineChartDataPoint[]
  loading: boolean
  priority: string
  error?: boolean
  metric?: string
  dimension?: string
  segment?: string
  ticketType?: string
}

export const displayToolTipItem = (seriePoint, customLabels) => {
  return (
    <Box key={seriePoint.serieId}>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Typography color="textPrimary" fontWeight={500} variant="body2">
          {customLabels.metric ? 'Tickets' : `${seriePoint.serieId} Tickets`}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {seriePoint.data.totalTickets}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Typography color="textPrimary" fontWeight={500} variant="body2">
          {customLabels.metric
            ? customLabels.metric
            : `${seriePoint.serieId} MTTCAck`}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(seriePoint.data.y)}
        </Typography>
      </Box>
    </Box>
  )
}

export const MTTXLine: React.FC<MttxLineProps> = ({
  data = [],
  securityData = [],
  engineeringData = [],
  loading,
  priority,
  error,
  metric,
  dimension,
  segment,
  ticketType,
}) => {
  const theme = useTheme()
  const [activePoint, setActivePoint] = useState({ x: null, color: null })

  const getChartData = (): Serie[] => {
    if (
      loading ||
      (!data.length && !securityData.length && !engineeringData.length)
    ) {
      return []
    }

    if (ticketType) {
      if (ticketType === TicketTypeEnum.Security) {
        const securityResult = {
          id: 'Security',
          data: securityData.map((item) => ({
            x: new Date(`${item.date}`),
            y: item.averageDuration,
            totalTickets: item.totalTickets,
          })),
        }
        return [securityResult]
      }

      if (ticketType === TicketTypeEnum.Engineering) {
        const engineeringResult = {
          id: 'Engineering',
          data: engineeringData.map((item) => ({
            x: new Date(`${item.date}`),
            y: item.averageDuration,
            totalTickets: item.totalTickets,
          })),
        }
        return [engineeringResult]
      }

      if (ticketType === 'All') {
        const securityResult = {
          id: 'Security',
          data: securityData.map((item) => ({
            x: new Date(`${item.date}`),
            y: item.averageDuration,
            totalTickets: item.totalTickets,
          })),
        }

        const engineeringResult = {
          id: 'Engineering',
          data: engineeringData.map((item) => ({
            x: new Date(`${item.date}`),
            y: item.averageDuration,
            totalTickets: item.totalTickets,
          })),
        }

        return [securityResult, engineeringResult]
      }
    }

    const result = {
      id: priority,
      data: data.map((item) => ({
        x: new Date(`${item.date}`),
        y: item.averageDuration,
        totalTickets: item.totalTickets,
      })),
    }

    return [result]
  }

  const mapAndFlattenSerieDatumSortedByDateAsc = (data: readonly Serie[]) =>
    data
      .flatMap((serie) => serie.data.map((datum) => datum.x as Date))
      .sort((a, b) => a.getTime() - b.getTime())

  const xAxisExtrema = (props: CustomLayerProps) => {
    if (props.points.length) {
      const allTrendDatesSortedAsc = mapAndFlattenSerieDatumSortedByDateAsc(
        props.data,
      )
      return (
        <Box component="g">
          <Box
            component="text"
            x={4}
            y={props.innerHeight + 25}
            sx={(theme) => ({
              fill: theme.vars.palette.text.primary,
              fontSize: theme.typography.caption.fontSize,
            })}
          >
            {format(allTrendDatesSortedAsc[0], 'MMM d, yyyy')}
          </Box>
          <Box
            component="text"
            x={props.innerWidth}
            y={props.innerHeight + 25}
            sx={(theme) => ({
              fill: theme.vars.palette.text.primary,
              fontSize: theme.typography.caption.fontSize,
            })}
            textAnchor="end"
          >
            {format(
              allTrendDatesSortedAsc[allTrendDatesSortedAsc.length - 1],
              'MMM d, yyyy',
            )}
          </Box>
        </Box>
      )
    }
  }

  const isEmpty =
    !data.length && !securityData.length && !engineeringData.length

  const EmptyStateLayer = () => (
    <Box
      component="text"
      x="40%"
      y="40%"
      textAnchor="middle"
      dominantBaseline="central"
      sx={(theme) => ({
        fill: theme.vars.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
      })}
    >
      Not available
    </Box>
  )

  const LoadingLayer = () => (
    <foreignObject
      id="loading-layer"
      data-testid="loading-layer"
      x="30%"
      y="30%"
      width="100%"
      height="100%"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader strokeWidth={1} size={40} />
    </foreignObject>
  )

  const getCustomLayers = (): Layer => {
    if (loading) {
      return LoadingLayer
    } else if (error || (data && isEmpty)) {
      return EmptyStateLayer
    }
    return 'lines'
  }

  const lineTooltip = ({ slice }: SliceTooltipProps) => {
    const customLabels =
      dimension === MttxDimension.CREATED_DATE &&
      segment === MttxSegment.TICKET_TYPE
        ? { tickets: 'Tickets', metric: undefined }
        : { tickets: 'Tickets', metric }

    return (
      <Box
        data-testid="mttx-line-tooltip"
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.common.white,
          borderRadius: '5px',
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
            border: `1px solid ${theme.vars.palette.text.secondary}`,
          }),
        })}
      >
        <Typography sx={{ padding: 0.5 }} variant="body2">
          {format(slice.points[0].data.x as Date, 'MMM d, yyyy')}
        </Typography>
        <Box
          sx={(theme) => ({
            padding: '4px',
            borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
            ...theme.applyStyles('dark', {
              borderTop: `1px solid ${theme.vars.palette.secondary.light}`,
            }),
          })}
        >
          {slice.points.map((p) => displayToolTipItem(p, customLabels))}
        </Box>
      </Box>
    )
  }

  const CustomPoint = (props) => {
    const { x, y, isActive, color } = props
    return isActive ? (
      <circle
        cx={x}
        cy={y}
        r={4}
        fill="transparent"
        stroke={color}
        strokeWidth={1}
      />
    ) : null
  }

  const onMouseEnter = (point) => {
    setActivePoint({
      x: point.points[0].data.xFormatted,
      color: point.points[0].serieColor,
    })
  }

  const onMouseLeave = () => {
    setActivePoint({ x: null, color: null })
  }

  const getLegendItems = () => {
    if (securityData.length && engineeringData.length) {
      return [
        {
          label: 'Security',
          color: chartColors(theme, priority, TicketTypeEnum.Security),
        },
        {
          label: 'Engineering',
          color: chartColors(theme, priority),
        },
      ]
    } else if (securityData.length) {
      return [
        {
          label: 'Security',
          color: chartColors(theme, priority, TicketTypeEnum.Security),
        },
      ]
    } else if (engineeringData.length) {
      return [
        {
          label: 'Engineering',
          color: chartColors(theme, priority),
        },
      ]
    }

    return [
      {
        label: priority,
        color: chartColors(theme, priority),
      },
    ]
  }

  return (
    <>
      {!loading && <ChartLegend legendItems={getLegendItems()} />}
      <Box
        id="mttx-line-chart"
        data-testid="mttx-line-chart"
        sx={{ height: '265px' }}
      >
        <ResponsiveLine
          data={getChartData()}
          curve="linear"
          theme={nivoTheme(theme)}
          isInteractive
          enableGridX={false}
          enableGridY
          xScale={{ type: 'time', precision: 'hour' }}
          sliceTooltip={lineTooltip}
          lineWidth={1.25}
          enableSlices="x"
          colors={(line) => chartColors(theme, priority, line.id)}
          margin={{ top: 36, right: 16, bottom: 26, left: 65 }}
          axisLeft={{
            format: (value) => toTimeUnits(value),
            tickValues: 5,
          }}
          axisBottom={{
            tickValues: 0,
          }}
          onMouseEnter={(point) => onMouseEnter(point)}
          onMouseLeave={() => onMouseLeave()}
          enableCrosshair={false}
          enablePoints={true}
          pointSymbol={(props) => {
            return (
              <CustomPoint
                {...props}
                isActive={activePoint.x === props.datum.xFormatted}
                color={activePoint.color}
              />
            )
          }}
          layers={[
            'axes',
            'areas',
            'crosshair',
            getCustomLayers(),
            'points',
            'slices',
            'mesh',
            xAxisExtrema,
          ]}
        />
      </Box>
    </>
  )
}
