import AddCircle from 'ionicons/dist/svg/add-circle.svg'
import AddCircleOutline from 'ionicons/dist/svg/add-circle-outline.svg'
import AlertCircle from 'ionicons/dist/svg/alert-circle.svg'
import AlertCircleOutline from 'ionicons/dist/svg/alert-circle-outline.svg'
import Analytics from 'ionicons/dist/svg/analytics.svg'
import ArrowBack from 'ionicons/dist/svg/arrow-back.svg'
import ArrowBackCircle from 'ionicons/dist/svg/arrow-back-circle.svg'
import ArrowBackCircleOutline from 'ionicons/dist/svg/arrow-back-circle-outline.svg'
import ArrowBackCircleSharp from 'ionicons/dist/svg/arrow-back-circle-sharp.svg'
import ArrowBackOutline from 'ionicons/dist/svg/arrow-back-outline.svg'
import ArrowBackSharp from 'ionicons/dist/svg/arrow-back-sharp.svg'
import ArrowDown from 'ionicons/dist/svg/arrow-down.svg'
import ArrowDownCircleSharp from 'ionicons/dist/svg/arrow-down-circle-sharp.svg'
import ArrowDownCircleOutline from 'ionicons/dist/svg/arrow-down-circle-outline.svg'
import ArrowForward from 'ionicons/dist/svg/arrow-forward.svg'
import ArrowForwardCircle from 'ionicons/dist/svg/arrow-forward-circle.svg'
import ArrowForwardCircleOutline from 'ionicons/dist/svg/arrow-forward-circle-outline.svg'
import ArrowForwardCircleSharp from 'ionicons/dist/svg/arrow-forward-circle-sharp.svg'
import ArrowForwardOutline from 'ionicons/dist/svg/arrow-forward-outline.svg'
import ArrowForwardSharp from 'ionicons/dist/svg/arrow-forward-sharp.svg'
import ArrowUp from 'ionicons/dist/svg/arrow-up.svg'
import ArrowUpCircleOutline from 'ionicons/dist/svg/arrow-up-circle-outline.svg'
import Ban from 'ionicons/dist/svg/ban.svg'
import BanOutline from 'ionicons/dist/svg/ban-outline.svg'
import BanSharp from 'ionicons/dist/svg/ban-sharp.svg'
import BarChart from 'ionicons/dist/svg/bar-chart.svg'
import BarChartOutline from 'ionicons/dist/svg/bar-chart-outline.svg'
import BarChartSharp from 'ionicons/dist/svg/bar-chart-sharp.svg'
import Barcode from 'ionicons/dist/svg/barcode.svg'
import BarcodeOutline from 'ionicons/dist/svg/barcode-outline.svg'
import BarcodeSharp from 'ionicons/dist/svg/barcode-sharp.svg'
import Book from 'ionicons/dist/svg/book.svg'
import BookOutline from 'ionicons/dist/svg/book-outline.svg'
import Bulb from 'ionicons/dist/svg/bulb.svg'
import BulbOutline from 'ionicons/dist/svg/bulb-outline.svg'
import BulbSharp from 'ionicons/dist/svg/bulb-sharp.svg'
import Bug from 'ionicons/dist/svg/bug.svg'
import BugOutline from 'ionicons/dist/svg/bug-outline.svg'
import Business from 'ionicons/dist/svg/business.svg'
import BusinessOutline from 'ionicons/dist/svg/business-outline.svg'
import CalendarOutline from 'ionicons/dist/svg/calendar-outline.svg'
import CaretDownSharp from 'ionicons/dist/svg/caret-down-sharp.svg'
import CartOutline from 'ionicons/dist/svg/cart-outline.svg'
import ChatBox from 'ionicons/dist/svg/chatbox.svg'
import ChatboxOutline from 'ionicons/dist/svg/chatbox-outline.svg'
import ChatboxEllipsesOutline from 'ionicons/dist/svg/chatbox-ellipses-outline.svg'
import ChatBubbleEllipsesOutline from 'ionicons/dist/svg/chatbubble-ellipses-outline.svg'
import ChatBubblesOutline from 'ionicons/dist/svg/chatbubbles-outline.svg'
import Checkbox from 'ionicons/dist/svg/checkbox.svg'
import CheckboxOutline from 'ionicons/dist/svg/checkbox-outline.svg'
import CheckboxSharp from 'ionicons/dist/svg/checkbox-sharp.svg'
import Checkmark from 'ionicons/dist/svg/checkmark.svg'
import CheckmarkDone from 'ionicons/dist/svg/checkmark-done.svg'
import CheckmarkCircle from 'ionicons/dist/svg/checkmark-circle.svg'
import CheckmarkCircleOutline from 'ionicons/dist/svg/checkmark-circle-outline.svg'
import CheckmarkSharp from 'ionicons/dist/svg/checkmark-sharp.svg'
import ChevronBack from 'ionicons/dist/svg/chevron-back.svg'
import ChevronBackCircleOutline from 'ionicons/dist/svg/chevron-back-circle-outline.svg'
import ChevronDown from 'ionicons/dist/svg/chevron-down.svg'
import ChevronDownCircleOutline from 'ionicons/dist/svg/chevron-down-circle-outline.svg'
import ChevronForward from 'ionicons/dist/svg/chevron-forward.svg'
import ChevronForwardCircleOutline from 'ionicons/dist/svg/chevron-forward-circle-outline.svg'
import ChevronUp from 'ionicons/dist/svg/chevron-up.svg'
import ChevronUpCircleOutline from 'ionicons/dist/svg/chevron-up-circle-outline.svg'
import Close from 'ionicons/dist/svg/close.svg'
import CloseCircle from 'ionicons/dist/svg/close-circle.svg'
import CloseCircleOutline from 'ionicons/dist/svg/close-circle-outline.svg'
import CloseOutline from 'ionicons/dist/svg/close-outline.svg'
import Cloud from 'ionicons/dist/svg/cloud.svg'
import CloudDone from 'ionicons/dist/svg/cloud-done.svg'
import CloudDoneOutline from 'ionicons/dist/svg/cloud-done-outline.svg'
import CloudDoneSharp from 'ionicons/dist/svg/cloud-done-sharp.svg'
import CloudOutline from 'ionicons/dist/svg/cloud-outline.svg'
import CodeSlashOutline from 'ionicons/dist/svg/code-slash-outline.svg'
import CogOutline from 'ionicons/dist/svg/cog-outline.svg'
import CopyOutline from 'ionicons/dist/svg/copy-outline.svg'
import Disc from 'ionicons/dist/svg/disc.svg'
import DocumentText from 'ionicons/dist/svg/document-text.svg'
import DocumentTextOutline from 'ionicons/dist/svg/document-text-outline.svg'
import DownloadOutline from 'ionicons/dist/svg/download-outline.svg'
import Earth from 'ionicons/dist/svg/earth.svg'
import EarthOutline from 'ionicons/dist/svg/earth-outline.svg'
import EarthSharp from 'ionicons/dist/svg/earth-sharp.svg'
import Ellipse from 'ionicons/dist/svg/ellipse.svg'
import EllipseOutline from 'ionicons/dist/svg/ellipse-outline.svg'
import EllipseSharp from 'ionicons/dist/svg/ellipse-sharp.svg'
import EllipseVertical from 'ionicons/dist/svg/ellipsis-vertical.svg'
import EllipsisHorizontal from 'ionicons/dist/svg/ellipsis-horizontal.svg'
import Eye from 'ionicons/dist/svg/eye.svg'
import EyeOutline from 'ionicons/dist/svg/eye-outline.svg'
import EyeOff from 'ionicons/dist/svg/eye-off.svg'
import EyeOffOutline from 'ionicons/dist/svg/eye-off-outline.svg'
import FileTrayStackedOutline from 'ionicons/dist/svg/file-tray-stacked-outline.svg'
import FlameOutline from 'ionicons/dist/svg/flame-outline.svg'
import Flash from 'ionicons/dist/svg/flash.svg'
import FlashOutline from 'ionicons/dist/svg/flash-outline.svg'
import Flask from 'ionicons/dist/svg/flask.svg'
import Filter from 'ionicons/dist/svg/filter.svg'
import Fitness from 'ionicons/dist/svg/fitness.svg'
import FitnessOutline from 'ionicons/dist/svg/fitness-outline.svg'
import HelpBuoyOutline from 'ionicons/dist/svg/help-buoy-outline.svg'
import HelpCircle from 'ionicons/dist/svg/help-circle.svg'
import HelpCircleOutline from 'ionicons/dist/svg/help-circle-outline.svg'
import HelpOutline from 'ionicons/dist/svg/help-outline.svg'
import Home from 'ionicons/dist/svg/home.svg'
import HomeOutline from 'ionicons/dist/svg/home-outline.svg'
import ImageOutline from 'ionicons/dist/svg/image-outline.svg'
import InformationCircle from 'ionicons/dist/svg/information-circle.svg'
import InformationCircleOutline from 'ionicons/dist/svg/information-circle-outline.svg'
import InformationCircleSharp from 'ionicons/dist/svg/information-circle-sharp.svg'
import Key from 'ionicons/dist/svg/key.svg'
import Keypad from 'ionicons/dist/svg/keypad.svg'
import KeypadOutline from 'ionicons/dist/svg/keypad-outline.svg'
import Leaf from 'ionicons/dist/svg/leaf.svg'
import LeafOutline from 'ionicons/dist/svg/leaf-outline.svg'
import LibraryOutline from 'ionicons/dist/svg/library-outline.svg'
import Link from 'ionicons/dist/svg/link.svg'
import LinkOutline from 'ionicons/dist/svg/link-outline.svg'
import List from 'ionicons/dist/svg/list.svg'
import ListOutline from 'ionicons/dist/svg/list-outline.svg'
import ListSharp from 'ionicons/dist/svg/list-sharp.svg'
import Locate from 'ionicons/dist/svg/locate.svg'
import LocationOutline from 'ionicons/dist/svg/location-outline.svg'
import LockClosedOutline from 'ionicons/dist/svg/lock-closed-outline.svg'
import LogoWebComponent from 'ionicons/dist/svg/logo-web-component.svg'
import LogOut from 'ionicons/dist/svg/log-out.svg'
import LogOutOutline from 'ionicons/dist/svg/log-out-outline.svg'
import MailOutline from 'ionicons/dist/svg/mail-outline.svg'
import Menu from 'ionicons/dist/svg/menu.svg'
import Newspaper from 'ionicons/dist/svg/newspaper.svg'
import Notifications from 'ionicons/dist/svg/notifications.svg'
import NotificationsOutline from 'ionicons/dist/svg/notifications-outline.svg'
import OpenOutline from 'ionicons/dist/svg/open-outline.svg'
import PauseCircle from 'ionicons/dist/svg/pause-circle.svg'
import PauseCircleOutline from 'ionicons/dist/svg/pause-circle-outline.svg'
import PencilOutline from 'ionicons/dist/svg/pencil-outline.svg'
import People from 'ionicons/dist/svg/people.svg'
import PeopleCircle from 'ionicons/dist/svg/people-circle.svg'
import PeopleCircleOutline from 'ionicons/dist/svg/people-circle-outline.svg'
import PeopleOutline from 'ionicons/dist/svg/people-outline.svg'
import PersonCircle from 'ionicons/dist/svg/person-circle.svg'
import PersonCircleOutline from 'ionicons/dist/svg/person-circle-outline.svg'
import PersonOutline from 'ionicons/dist/svg/person-outline.svg'
import PersonSharp from 'ionicons/dist/svg/person-sharp.svg'
import PieChart from 'ionicons/dist/svg/pie-chart.svg'
import PieChartOutline from 'ionicons/dist/svg/pie-chart-outline.svg'
import PieChartSharp from 'ionicons/dist/svg/pie-chart-sharp.svg'
import Power from 'ionicons/dist/svg/power.svg'
import PrintOutline from 'ionicons/dist/svg/print-outline.svg'
import Pulse from 'ionicons/dist/svg/pulse.svg'
import PulseOutline from 'ionicons/dist/svg/pulse-outline.svg'
import PulseSharp from 'ionicons/dist/svg/pulse-sharp.svg'
import RadioButtonOffSharp from 'ionicons/dist/svg/radio-button-off-sharp.svg'
import RadioButtonOnOutline from 'ionicons/dist/svg/radio-button-on-outline.svg'
import RadioButtonOnSharp from 'ionicons/dist/svg/radio-button-on-sharp.svg'
import Reader from 'ionicons/dist/svg/reader.svg'
import Receipt from 'ionicons/dist/svg/receipt.svg'
import RemoveCircleOutline from 'ionicons/dist/svg/remove-circle-outline.svg'
import RemoveCircleSharp from 'ionicons/dist/svg/remove-circle-sharp.svg'
import ReorderFour from 'ionicons/dist/svg/reorder-four.svg'
import ReorderFourOutline from 'ionicons/dist/svg/reorder-four-outline.svg'
import ReorderFourSharp from 'ionicons/dist/svg/reorder-four-sharp.svg'
import ReturnDownForwardSharp from 'ionicons/dist/svg/return-down-forward-sharp.svg'
import SearchOutline from 'ionicons/dist/svg/search-outline.svg'
import Send from 'ionicons/dist/svg/send.svg'
import Server from 'ionicons/dist/svg/server.svg'
import ServerOutline from 'ionicons/dist/svg/server-outline.svg'
import SettingsOutline from 'ionicons/dist/svg/settings-outline.svg'
import ShareOutline from 'ionicons/dist/svg/share-outline.svg'
import ShieldCheckmark from 'ionicons/dist/svg/shield-checkmark.svg'
import ShieldCheckmarkOutline from 'ionicons/dist/svg/shield-checkmark-outline.svg'
import ShieldOutline from 'ionicons/dist/svg/shield-outline.svg'
import Shield from 'ionicons/dist/svg/shield.svg'
import Square from 'ionicons/dist/svg/square.svg'
import SquareOutline from 'ionicons/dist/svg/square-outline.svg'
import SquareSharp from 'ionicons/dist/svg/square-sharp.svg'
import Skull from 'ionicons/dist/svg/skull.svg'
import StatsChart from 'ionicons/dist/svg/stats-chart.svg'
import StatsChartOutline from 'ionicons/dist/svg/stats-chart-outline.svg'
import Ticket from 'ionicons/dist/svg/ticket.svg'
import TicketOutline from 'ionicons/dist/svg/ticket-outline.svg'
import Time from 'ionicons/dist/svg/time.svg'
import TimeOutline from 'ionicons/dist/svg/time-outline.svg'
import TimeSharp from 'ionicons/dist/svg/time-sharp.svg'
import TrashOutline from 'ionicons/dist/svg/trash-outline.svg'
import Warning from 'ionicons/dist/svg/warning.svg'
import WarningOutline from 'ionicons/dist/svg/warning-outline.svg'
import NuclearOutline from 'ionicons/dist/svg/nuclear-outline.svg'
import GlobeOutline from 'ionicons/dist/svg/globe-outline.svg'
import FingerPrint from 'ionicons/dist/svg/finger-print.svg'

import CalendarQuestionFilled from '@common/SVGs/CalendarQuestionFilled'
import Clipboard from '@common/SVGs/Clipboard'
import ResendEmail from '@common/SVGs/ResendEmail'
import CustomerAdvisoryUnread from '@common/SVGs/CustomerAdvisoryUnread'
import CustomerAdvisoryRead from '@common/SVGs/CustomerAdvisoryRead'
import SignificantCyberEventUnread from '@common/SVGs/SignificantCyberEventUnread'
import SignificantCyberEventRead from '@common/SVGs/SignificantCyberEventRead'
import ClipboardSuccess from '@common/SVGs/ClipboardSuccess'
import CalendarQuestionOutlined from '@common/SVGs/CalendarQuestionOutlined'
import ReportsEmptyState from '@common/SVGs/ReportsEmptyState'
import MyCustomersEmptyState from '@common/SVGs/MyCustomersEmptyState'
import TicketsEmptyState from '@common/SVGs/TicketsEmptyState'
import NoResults from '@common/SVGs/NoResults'
import ATILogo from '@common/SVGs/ATILogo'
import EDRLogo from '@common/SVGs/EDRLogo'
import FWLogo from '@common/SVGs/FWLogo'
import MDRLogo from '@common/SVGs/MDRLogo'
import ThreatSignalLogo from '@common/SVGs/ThreatSignalLogo'
import MVMLogo from '@common/SVGs/MVMLogo'
import SplunkLogo from '@common/SVGs/SplunkLogo'
import CrowdStrikeLogo from '@common/SVGs/CrowdStrikeLogo'
import AzureLogo from '@common/SVGs/AzureLogo'
import PaloLogo from '@common/SVGs/PaloLogo'
import TenableLogo from '@common/SVGs/TenableLogo'
import MicrosoftLogo from '@common/SVGs/MicrosoftLogo'
import BellImg from '@common/SVGs/BellImg'
import StatusNew from '@common/SVGs/StatusNew'
import StatusInProgress from '@common/SVGs/StatusInProgress'
import Error from '@common/SVGs/Error'

export const iconMapping = {
  addCircle: AddCircle,
  addCircleOutline: AddCircleOutline,
  alertCircle: AlertCircle,
  alertCircleOutline: AlertCircleOutline,
  analytics: Analytics,
  arrowBack: ArrowBack,
  arrowBackCircle: ArrowBackCircle,
  arrowBackCircleOutline: ArrowBackCircleOutline,
  arrowBackCircleSharp: ArrowBackCircleSharp,
  arrowBackOutline: ArrowBackOutline,
  arrowBackSharp: ArrowBackSharp,
  arrowDown: ArrowDown,
  arrowDownCircleSharp: ArrowDownCircleSharp,
  arrowDownCircleOutline: ArrowDownCircleOutline,
  arrowForward: ArrowForward,
  arrowForwardCircle: ArrowForwardCircle,
  arrowForwardCircleOutline: ArrowForwardCircleOutline,
  arrowForwardCircleSharp: ArrowForwardCircleSharp,
  arrowForwardOutline: ArrowForwardOutline,
  arrowForwardSharp: ArrowForwardSharp,
  arrowUp: ArrowUp,
  arrowUpCircleOutline: ArrowUpCircleOutline,
  ban: Ban,
  banOutline: BanOutline,
  banSharp: BanSharp,
  barChart: BarChart,
  barChartOutline: BarChartOutline,
  barChartSharp: BarChartSharp,
  barcode: Barcode,
  barcodeOutline: BarcodeOutline,
  barcodeSharp: BarcodeSharp,
  book: Book,
  bookOutline: BookOutline,
  bulb: Bulb,
  bulbOutline: BulbOutline,
  bulbSharp: BulbSharp,
  bug: Bug,
  bugOutline: BugOutline,
  business: Business,
  businessOutline: BusinessOutline,
  calendarOutline: CalendarOutline,
  caretDownSharp: CaretDownSharp,
  cartOutline: CartOutline,
  chatbox: ChatBox,
  chatboxOutline: ChatboxOutline,
  chatboxEllipsesOutline: ChatboxEllipsesOutline,
  chatbubbleEllipsesOutline: ChatBubbleEllipsesOutline,
  chatbubblesOutline: ChatBubblesOutline,
  checkbox: Checkbox,
  checkboxOutline: CheckboxOutline,
  checkboxSharp: CheckboxSharp,
  checkmark: Checkmark,
  checkmarkDone: CheckmarkDone,
  checkmarkCircle: CheckmarkCircle,
  checkmarkCircleOutline: CheckmarkCircleOutline,
  checkmarkSharp: CheckmarkSharp,
  chevronBack: ChevronBack,
  chevronBackCircleOutline: ChevronBackCircleOutline,
  chevronDown: ChevronDown,
  chevronDownCircleOutline: ChevronDownCircleOutline,
  chevronForward: ChevronForward,
  chevronForwardCircleOutline: ChevronForwardCircleOutline,
  chevronUp: ChevronUp,
  chevronUpCircleOutline: ChevronUpCircleOutline,
  close: Close,
  closeCircle: CloseCircle,
  closeCircleOutline: CloseCircleOutline,
  closeOutline: CloseOutline,
  cloud: Cloud,
  cloudDone: CloudDone,
  cloudDoneOutline: CloudDoneOutline,
  cloudDoneSharp: CloudDoneSharp,
  cloudOutline: CloudOutline,
  codeSlashOutline: CodeSlashOutline,
  cogOutline: CogOutline,
  copyOutline: CopyOutline,
  disc: Disc,
  documentText: DocumentText,
  documentTextOutline: DocumentTextOutline,
  downloadOutline: DownloadOutline,
  earth: Earth,
  earthOutline: EarthOutline,
  earthSharp: EarthSharp,
  ellipse: Ellipse,
  ellipseOutline: EllipseOutline,
  ellipseSharp: EllipseSharp,
  ellipsisVertical: EllipseVertical,
  ellipsisHorizontal: EllipsisHorizontal,
  eye: Eye,
  eyeOutline: EyeOutline,
  eyeOff: EyeOff,
  eyeOffOutline: EyeOffOutline,
  fileTrayStackedOutline: FileTrayStackedOutline,
  flameOutline: FlameOutline,
  flash: Flash,
  flashOutline: FlashOutline,
  flask: Flask,
  filter: Filter,
  fitness: Fitness,
  fitnessOutline: FitnessOutline,
  helpBuoyOutline: HelpBuoyOutline,
  helpCircle: HelpCircle,
  helpCircleOutline: HelpCircleOutline,
  helpOutline: HelpOutline,
  home: Home,
  homeOutline: HomeOutline,
  imageOutline: ImageOutline,
  informationCircle: InformationCircle,
  informationCircleOutline: InformationCircleOutline,
  informationCircleSharp: InformationCircleSharp,
  key: Key,
  keypad: Keypad,
  keypadOutline: KeypadOutline,
  leaf: Leaf,
  leafOutline: LeafOutline,
  libraryOutline: LibraryOutline,
  link: Link,
  linkOutline: LinkOutline,
  list: List,
  listOutline: ListOutline,
  listSharp: ListSharp,
  locate: Locate,
  locationOutline: LocationOutline,
  lockClosedOutline: LockClosedOutline,
  logoWebComponent: LogoWebComponent,
  logOut: LogOut,
  logOutOutline: LogOutOutline,
  mailOutline: MailOutline,
  menu: Menu,
  newspaper: Newspaper,
  notifications: Notifications,
  notificationsOutline: NotificationsOutline,
  openOutline: OpenOutline,
  pauseCircle: PauseCircle,
  pauseCircleOutline: PauseCircleOutline,
  pencilOutline: PencilOutline,
  people: People,
  peopleCircle: PeopleCircle,
  peopleCircleOutline: PeopleCircleOutline,
  peopleOutline: PeopleOutline,
  personCircle: PersonCircle,
  personCircleOutline: PersonCircleOutline,
  personOutline: PersonOutline,
  personSharp: PersonSharp,
  pieChart: PieChart,
  pieChartOutline: PieChartOutline,
  pieChartSharp: PieChartSharp,
  power: Power,
  printOutline: PrintOutline,
  pulse: Pulse,
  pulseOutline: PulseOutline,
  pulseSharp: PulseSharp,
  radioButtonOffSharp: RadioButtonOffSharp,
  radioButtonOnOutline: RadioButtonOnOutline,
  radioButtonOnSharp: RadioButtonOnSharp,
  reader: Reader,
  receipt: Receipt,
  removeCircleOutline: RemoveCircleOutline,
  removeCircleSharp: RemoveCircleSharp,
  reorderFour: ReorderFour,
  reorderFourOutline: ReorderFourOutline,
  reorderFourSharp: ReorderFourSharp,
  returnDownForwardSharp: ReturnDownForwardSharp,
  searchOutline: SearchOutline,
  send: Send,
  server: Server,
  serverOutline: ServerOutline,
  settingsOutline: SettingsOutline,
  shareOutline: ShareOutline,
  shieldCheckmark: ShieldCheckmark,
  shieldCheckmarkOutline: ShieldCheckmarkOutline,
  shieldOutline: ShieldOutline,
  shield: Shield,
  square: Square,
  squareOutline: SquareOutline,
  squareSharp: SquareSharp,
  skull: Skull,
  statsChart: StatsChart,
  statsChartOutline: StatsChartOutline,
  ticket: Ticket,
  ticketOutline: TicketOutline,
  time: Time,
  timeOutline: TimeOutline,
  timeSharp: TimeSharp,
  trashOutline: TrashOutline,
  warning: Warning,
  warningOutline: WarningOutline,
  nuclearOutline: NuclearOutline,
  globeOutline: GlobeOutline,
  fingerPrint: FingerPrint,

  // custom icons
  atiLogo: ATILogo,
  clipboard: Clipboard,
  clipboardSuccess: ClipboardSuccess,
  customerAdvisoryRead: CustomerAdvisoryRead,
  customerAdvisoryUnread: CustomerAdvisoryUnread,
  myCustomersEmptyState: MyCustomersEmptyState,
  reportsEmptyState: ReportsEmptyState,
  resendEmail: ResendEmail,
  significantCyberEventRead: SignificantCyberEventRead,
  significantCyberEventUnread: SignificantCyberEventUnread,
  calendarQuestionFilled: CalendarQuestionFilled,
  calendarQuestionOutlined: CalendarQuestionOutlined,
  ticketsEmptyState: TicketsEmptyState,
  noResults: NoResults,
  mdrLogo: MDRLogo,
  edrLogo: EDRLogo,
  fwLogo: FWLogo,
  threatSignalLogo: ThreatSignalLogo,
  mvmLogo: MVMLogo,
  azure: AzureLogo,
  crowdstrike: CrowdStrikeLogo,
  microsoft: MicrosoftLogo,
  paloAlto: PaloLogo,
  splunk: SplunkLogo,
  tenable: TenableLogo,
  bell: BellImg,
  statusNew: StatusNew,
  statusInProgress: StatusInProgress,
  errorIcon: Error,
}

export type IconVariant = keyof typeof iconMapping
