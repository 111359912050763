import { useCallback, useContext, useEffect } from 'react'
import { subDays } from 'date-fns'

import { DatePicker } from '@common/DatePicker'
import { Context, setDateFilter } from '@components/App'

import { useShouldShowDatePicker } from './useShouldShowDatePicker'

export const DatePickerNavElement: React.FC = () => {
  const {
    state: {
      customer,
      dwExpertsCustomer,
      dateFilter: { startDate, endDate, defaultStartDate, defaultEndDate },
      customerLoading,
    },
    dispatch,
  } = useContext(Context)

  const shouldShowDatePicker = useShouldShowDatePicker()

  useEffect(() => {
    dispatch(
      setDateFilter({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dwExpertsCustomer.customerShortName]) // FIXME: we should really be including all deps; this was copied from <PageBanner /> to avoid regressions, but at some point we should test a fix for this

  const handleDateChange = (newStartDate?: Date, newEndDate?: Date) => {
    newStartDate &&
      newEndDate &&
      dispatch(
        setDateFilter({
          startDate: newStartDate,
          endDate: newEndDate,
        }),
      )
  }

  const maturityDateToUse =
    customer.maturityScoreStartDate ?? dwExpertsCustomer.maturityScoreStartDate

  const updateCalendarIconHover = useCallback(
    (isHovering: boolean) => {
      dispatch(setDateFilter({ isCalendarIconHovering: isHovering }))
    },
    [dispatch],
  )

  return (
    <DatePicker
      shouldShow={shouldShowDatePicker}
      disabledDate={(date) => {
        // Data pipeline is one day behind so we don't let the user select the current day
        const lastDay = subDays(new Date(), 1)

        // Disable the dates after today or before the first available data
        if (date > lastDay) {
          return true
        }

        // Disable dates before maturity score start date
        if (maturityDateToUse && new Date(maturityDateToUse) > date) {
          return true
        }

        return false
      }}
      startDate={startDate}
      endDate={endDate}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      loading={customerLoading}
      onSubmit={handleDateChange}
      onCalendarIconHover={updateCalendarIconHover}
    />
  )
}
