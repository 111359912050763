import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { Report } from '@models/index'
import { handleDownload } from '@utils/index'
import {
  DOWNLOAD_REPORT,
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
  GetReportsData,
  GetReportsVariables,
} from '@queries/report'
import { ReportDetails } from '@components/Reports/ReportDetails'
import { useSelectedRow, useToast } from '@hooks/index'
import { Dialog } from '@common/Dialog'
import { AlertSeverity } from '@hooks/useToast'

import { DeleteReportModal } from './DeleteReportModal'
import ReportTable, { REPORT_TABLE_ID_PREFIX } from './ReportTable/ReportTable'

const Reports = () => {
  const {
    state: {
      user: { username },
    },
  } = useContext(Context)

  const defaultRowSelectionState = {}

  const [reportToDelete, setReportToDelete] = useState<Report | null>(null)
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false)
  const reportIdForModal = useRef<number | null>(null)

  const [searchParams] = useSearchParams()
  const { handleShowToast } = useToast()

  const selectedCustomer = searchParams.get('customer')

  const {
    loading,
    data: { getReports: reports } = { getReports: [] },
    previousData: { getReports: oldReports } = { getReports: [] },
    startPolling,
    stopPolling,
  } = useQuery<GetReportsData, GetReportsVariables>(GET_REPORTS, {
    variables: {
      selectedCustomer,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const result = reports.some((x) => x.status === 'Requested')

      if (result) {
        startPolling(15000)
      } else {
        stopPolling()
      }

      const oldRequestedReports = oldReports.filter(
        (x) => x.status === 'Requested',
      )

      oldRequestedReports.forEach((oldReport) => {
        const match = reports.find(
          (newReport) =>
            oldReport.id === newReport.id &&
            newReport.status !== 'Requested' &&
            newReport.userName === username,
        )

        if (match) {
          handleShowToast(
            AlertSeverity.Success,
            `"${match.name}" Report has successfully been generated!`,
            5000,
          )
        }
      })
    },
  })

  const {
    rowSelection,
    setRowSelection,
    selectedRow: selectedReport,
    previouslySelectedRow: previouslySelectedReport,
  } = useSelectedRow({ data: reports, rowIdKey: 'id' })

  const [downloadReport] = useLazyQuery<
    DownloadReportData,
    DownloadReportVariables
  >(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
    },
  })

  const deleteModal = useCallback(
    (reportToDelete: Report) => {
      reportIdForModal.current = reportToDelete.id // Save the report id to focus on the row after closing the modal
      setReportToDelete(reportToDelete)
      setIsDeleteReportModalOpen(true)
    },
    [setReportToDelete, setIsDeleteReportModalOpen],
  )

  const onReportDownload = useCallback(
    (reportId: number) => {
      downloadReport({
        variables: {
          input: { reportId: reportId },
          selectedCustomer,
        },
      })
    },
    [downloadReport, selectedCustomer],
  )

  const filteredReports = useMemo(
    () => reports.filter((report) => report.status !== 'Failed'),
    [reports],
  )

  const focusReportRow = useCallback((reportId: number | null | undefined) => {
    if (reportId) {
      document.getElementById(`${REPORT_TABLE_ID_PREFIX}${reportId}`)?.focus()
    }
  }, [])

  const closeSidesheetAndFocusPreviousReport = () => {
    /** When closing the sidesheet, re-focus the row which was selected to improve a11y ux */
    focusReportRow(previouslySelectedReport?.id)
    setRowSelection(defaultRowSelectionState)
  }

  /** When closing the delete report modal,re-focus the row which was selected to improve a11y ux  */
  useEffect(() => {
    if (!isDeleteReportModalOpen) {
      focusReportRow(reportIdForModal.current)
    }
  }, [focusReportRow, isDeleteReportModalOpen])

  return (
    <Box
      id="reports-page"
      data-testid="reports"
      sx={{ padding: '1.5rem', width: '100%' }}
    >
      <ReportTable
        loading={loading}
        reports={filteredReports}
        rowSelection={rowSelection}
        handleDeleteReportModal={deleteModal}
        handleDownloadReport={onReportDownload}
        handleRowSelection={setRowSelection}
      />

      <ReportDetails
        data-testid="reportsDetails"
        isOpen={!!selectedReport}
        closeSideSheet={closeSidesheetAndFocusPreviousReport}
        /**
         * This callback will never be called without a `selectedReport` (because the sidesheet open state is managed with the expression `!!selectedReport`).
         * Including handling for it here as a precaution, and to satisfy typescript.
         */
        deleteReportCallback={() =>
          selectedReport ? deleteModal(selectedReport) : () => {}
        }
        selectedReport={selectedReport ?? previouslySelectedReport}
        selectedCustomer={selectedCustomer}
      />
      <Dialog
        title="Delete Report"
        isOpen={isDeleteReportModalOpen}
        onClose={() => setIsDeleteReportModalOpen(false)}
      >
        <DeleteReportModal
          closeModal={() => setIsDeleteReportModalOpen(false)}
          report={reportToDelete!} // Will always be set before opening the modal
          downloadReport={downloadReport}
        />
      </Dialog>
    </Box>
  )
}

export default Reports
