import { alpha, Chip } from '@mui/material'

import {
  MitreCoverageTechnique,
  MitreCoverageCustomer,
} from '@models/DetectionCoverage'

export interface MitreTableItem {
  mitreSubtechniqueId?: string
  mitreSubtechniqueName?: string
  customerSubtechniqueCoverage?: SubtechniqueCoverage
  customerTechniqueSubTechniqueEnabledUseCaseCount?: number
  totalUseCaseCount?: number
  subtechniqueIndex?: number
}

export const IconForCard = (
  isOpen: boolean,
  selectedTechnique?: string,
  techniqueIndex?: string,
) => {
  if (selectedTechnique === techniqueIndex) {
    return isOpen === true ? 'chevronUp' : 'chevronDown'
  }
  return 'chevronDown'
}

export enum SubtechniqueCoverage {
  COVERED = 'covered',
  NOT_COVERED = 'not-covered',
  NOT_AVAILABLE = 'not-available',
}

interface SubtechniqueCoverageTagProps {
  coverage: SubtechniqueCoverage
}

export const SubtechniqueCoverageTag: React.FC<
  SubtechniqueCoverageTagProps
> = ({ coverage }) => {
  switch (coverage) {
    case SubtechniqueCoverage.COVERED:
      return (
        <Chip
          label="COVERED"
          variant="outlined"
          sx={{
            '&.MuiChip-outlined': {
              color: ({ palette }) => palette.success.main,
              borderColor: ({ palette }) => alpha(palette.success.main, 0.54),
            },
          }}
        />
      )
    case SubtechniqueCoverage.NOT_COVERED:
      return (
        <Chip
          sx={{
            '&.MuiChip-outlined': {
              color: ({ palette }) => palette.primary.main,
            },
          }}
          label="AVAILABLE"
          variant="outlined"
        />
      )
    case SubtechniqueCoverage.NOT_AVAILABLE:
      return (
        <Chip
          label="UNAVAILABLE"
          variant="outlined"
          sx={{
            '&.MuiChip-outlined': {
              color: ({ palette }) => palette.text.primary,
              borderColor: ({ palette }) => alpha(palette.text.primary, 0.54),
            },
          }}
        />
      )
  }
}

export const getFormattedTableData = (
  technique: MitreCoverageTechnique,
  customerTechnique: MitreCoverageCustomer | undefined,
) => {
  return technique.subtechniques.map((subtechnique, subtechniqueIndex) => {
    const customerTechniqueSubTechniqueEnabledUseCaseCount =
      customerTechnique?.subtechniques.find(
        (customerSubtechnique) =>
          customerSubtechnique.mitreSubtechniqueId ===
          subtechnique.mitreSubtechniqueId,
      )?.totalEnabledUseCaseCount

    let customerSubtechniqueCoverage = SubtechniqueCoverage.NOT_AVAILABLE

    if (subtechnique.totalUseCaseCount > 0) {
      customerSubtechniqueCoverage = SubtechniqueCoverage.NOT_COVERED
      if (customerTechniqueSubTechniqueEnabledUseCaseCount) {
        customerSubtechniqueCoverage = SubtechniqueCoverage.COVERED
      }
    }

    return {
      mitreSubtechniqueId: subtechnique.mitreSubtechniqueId,
      mitreSubtechniqueName: subtechnique.mitreSubtechniqueName,
      customerSubtechniqueCoverage: customerSubtechniqueCoverage,
      customerTechniqueSubTechniqueEnabledUseCaseCount:
        customerTechniqueSubTechniqueEnabledUseCaseCount
          ? customerTechniqueSubTechniqueEnabledUseCaseCount
          : 0,
      totalUseCaseCount: subtechnique.totalUseCaseCount,
      subtechniqueIndex,
    }
  })
}
