import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import { CustomerHighlight } from '@models/DeepwatchExpert'

export interface CustomerLicenseLimitCellValues {
  usageLicenseGb: number | null
}

interface CustomerLicenseLimitCellProps {
  cellContext: CellContext<CustomerHighlight, CustomerLicenseLimitCellValues>
}

const CustomerLicenseLimitCell: React.FC<CustomerLicenseLimitCellProps> = ({
  cellContext,
}) => {
  const { usageLicenseGb } = cellContext.getValue()

  if (usageLicenseGb === null) {
    return (
      <Typography color="warning" variant="body2">
        Error
      </Typography>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {usageLicenseGb}
      </Typography>
    </Box>
  )
}

export default CustomerLicenseLimitCell
