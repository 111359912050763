/* eslint-disable @getify/proper-ternary/nested */
import { SingleValue } from 'react-select'
import { useRef, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Box, IconButton, Theme, Typography } from '@mui/material'

import { OptionType } from '@common/Dropdown'
import { ComponentError } from '@common/ComponentError'
import {
  Dimension,
  EnvironmentHealthUtilAlertsNoValue,
  Metric,
  Segment,
} from '@models/EnvHealth'
import { UtilizationInput } from '@queries/environmentHealth'
import ChartDownloadMenu from '@common/ChartDownloadMenu'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { useDateFilterableStyles } from '@hooks/index'

import {
  utilizationByDate,
  utilizationByDateBySourcetype,
  utilizationByDateBySourcetypeDeviation,
} from '../../../../../mockData/EnvHealthMockData'
import {
  chunkPastPeriodUtilization,
  chunkSourceTypesByDate,
  dimensionOptions,
  metricOptions,
  segmentOptions,
  utilizationChartTitle,
} from './SourceUtilization.utils'
import UtilizationByDateBySourcetypeChart from './SUCharts/UtilizationByDateBySourceTypeChart'
import UtilizationByDateByIndexChart from './SUCharts/UtilizationByDateByIndexChart'
import UtilizationBySourcetypeAverageChart from './SUCharts/UtilizationBySourcetypeAverageChart'
import UtilizationByIndexAverageChart from './SUCharts/UtilizationByIndexAverageChat'
import UtilizationByDateBySourcetypeDeviationChart from './SUCharts/UtilizationByDateBySourcetypeDeviationChart'
import PastPeriodChart from './SUCharts/PastPeriodChart'
import NoValueChart from './SUCharts/NoValueChart'
import { SuDropdowns } from './SuDropdowns'
import { SuToggle } from './SuToggle'
import PastPeriodBarChart from './SUCharts/PastPeriodBarChart'
import SuPopoverToggle from './SuToggle/SuPopoverToggle'

interface SourceUtilizationProps {
  data: EnvironmentHealthUtilAlertsNoValue | null
  loading: boolean
  inputs: (
    selectedInputs: Omit<UtilizationInput, 'startDate' | 'endDate'>,
  ) => void
  error?: ApolloError | undefined
}

const SourceUtilization: React.FC<SourceUtilizationProps> = ({
  data,
  loading,
  inputs,
  error,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const [pastPeriodChart, setPastPeriodChart] = useState(false)
  const [selectedMetric, setSelectedMetric] = useState<SingleValue<OptionType>>(
    metricOptions[0],
  )
  const [selectedDimension, setSelectedDimension] = useState<
    SingleValue<OptionType>
  >(dimensionOptions[0])
  const [selectedSegment, setSelectedSegment] = useState<
    SingleValue<OptionType>
  >(segmentOptions[0])

  const chartRef = useRef<HTMLDivElement>(null)

  const { featureEnvHealthV2, featureRemoveTogglePopovers } = useFlags()

  const handleMetricChange = (newValue: SingleValue<OptionType>) => {
    setSelectedMetric(newValue)
    if (newValue?.value === Metric.ALERTS) {
      setSelectedDimension(
        dimensionOptions.filter(
          (dimension) => dimension.value === Dimension.DATE,
        )[0],
      )
      setSelectedSegment(
        segmentOptions.filter(
          (segment) => segment.value === Segment.NO_VALUE,
        )[0],
      )
    }
  }

  const handleDimensionChange = (newValue: SingleValue<OptionType>) => {
    setSelectedDimension(newValue)
    if (
      newValue?.value === Dimension.SOURCETYPE ||
      newValue?.value === Dimension.INDEX
    ) {
      setSelectedSegment(
        segmentOptions.filter(
          (segment) => segment.value === Segment.AVERAGE,
        )[0],
      )
    }
  }

  const handleSegmentChange = (newValue: SingleValue<OptionType>) => {
    setSelectedSegment(newValue)
    if (newValue?.value === Segment.NO_VALUE) {
      // segment NO_VALUE can only be in combination with dimension DATE and either metric UTILIZATION or ALERTS
      // so we can manually set dimension here
      inputs({
        metric: selectedMetric?.value as Metric.UTILIZATION | Metric.ALERTS,
        dimension: Dimension.DATE,
        segment: Segment.NO_VALUE,
      })
    }
  }

  const sourceUtilizationMap = {
    [Metric.UTILIZATION]: {
      [Dimension.DATE]: {
        [Segment.NO_VALUE]: pastPeriodChart ? (
          featureRemoveTogglePopovers ? (
            <PastPeriodBarChart data={data} />
          ) : (
            // TODO: remove once past period bar chart is released
            <PastPeriodChart data={data} />
          )
        ) : (
          <NoValueChart data={data} metric={selectedMetric?.value as Metric} />
        ),
        [Segment.SOURCETYPE]: (
          <UtilizationByDateBySourcetypeChart
            data={utilizationByDateBySourcetype}
          />
        ),
        [Segment.INDEX]: (
          <UtilizationByDateByIndexChart data={utilizationByDateBySourcetype} />
        ),
        [Segment.DEVIATION]: (
          <UtilizationByDateBySourcetypeDeviationChart
            data={utilizationByDateBySourcetypeDeviation}
          />
        ),
        [Segment.AVERAGE]: (
          <UtilizationBySourcetypeAverageChart data={utilizationByDate} />
        ),
      },
      [Dimension.SOURCETYPE]: {
        [Segment.AVERAGE]: (
          <UtilizationBySourcetypeAverageChart data={utilizationByDate} />
        ),
      },
      [Dimension.INDEX]: {
        [Segment.AVERAGE]: (
          <UtilizationByIndexAverageChart data={utilizationByDate} />
        ),
      },
    },
    [Metric.ALERTS]: {
      [Dimension.DATE]: {
        [Segment.NO_VALUE]: (
          <NoValueChart data={data} metric={selectedMetric?.value as Metric} />
        ),
        [Segment.SOURCETYPE]: (
          <UtilizationByDateBySourcetypeChart
            data={utilizationByDateBySourcetype}
          />
        ),
      },
    },
  }

  const renderComponent = (): JSX.Element => {
    if (loading) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Loader strokeWidth={2} size={50} />
        </Box>
      )
    } else if (error || data === null) {
      return <ComponentError />
    }

    return (
      sourceUtilizationMap[selectedMetric?.value ?? '']?.[
        selectedDimension?.value ?? ''
      ]?.[selectedSegment?.value ?? ''] || null
    )
  }

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(8)],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left',
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const disablePopover =
    selectedMetric?.value !== Metric.UTILIZATION ||
    selectedSegment?.value !== Segment.NO_VALUE

  const chartTitle = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography color="textPrimary" variant="h6">
        {utilizationChartTitle(
          (selectedMetric?.value as string) ?? Metric.UTILIZATION,
          selectedDimension?.value ?? Dimension.SOURCETYPE,
          selectedSegment?.value ?? Segment.NO_VALUE,
        )}
      </Typography>
    </Box>
  )

  // since data is nested, will have to select sub-items to export
  // this will likely have to be refactored when additional charts are available
  // additionally, current period utilization is calculated FE - should be BE
  const selectedData = pastPeriodChart
    ? [...chunkSourceTypesByDate(data), ...chunkPastPeriodUtilization(data)]
    : data?.currentPeriod.topSourceTypes

  const selectedDataFields = pastPeriodChart
    ? ['date', 'totalGbForDay', 'sourceTypes', 'totalAlertsForDay']
    : ['date', 'name', 'gb', 'alerts', 'avgIngestOverAllTime']

  const isExportDisabled = loading || error !== undefined

  const highlightStyles = useDateFilterableStyles({
    getDefaultBorderColor: (theme) => ({
      light: theme.vars.palette.secondary.main,
      dark: theme.vars.palette.secondary.lighter,
    }),
  })

  const getDownloadIconColor = (theme: Theme): string => {
    if (isExportDisabled) {
      return theme.vars.palette.text.disabled
    }
    return theme.palette.mode === 'dark'
      ? theme.vars.palette.text.secondary
      : theme.vars.palette.text.primary
  }

  return (
    <Box
      id="sourcetype-utilization"
      data-testid="sourcetype-utilization"
      sx={{
        width: '100%',
        marginBottom: '26px',
        '&:nth-of-type(2)': {
          '> div:last-child': {
            position: 'relative',
            boxSizing: 'border-box',
            padding: '24px',
          },
        },
      }}
    >
      {featureEnvHealthV2 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
            flex: '1 0 50%',
          }}
        >
          {chartTitle}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        {featureEnvHealthV2 ? (
          <SuDropdowns
            loading={loading}
            selectedMetric={selectedMetric}
            selectedDimension={selectedDimension}
            selectedSegment={selectedSegment}
            metricOptions={metricOptions}
            dimensionOptions={dimensionOptions}
            segmentOptions={segmentOptions}
            handleMetricChange={handleMetricChange}
            handleDimensionChange={handleDimensionChange}
            handleSegmentChange={handleSegmentChange}
          />
        ) : (
          chartTitle
        )}

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {featureRemoveTogglePopovers ? (
            <SuToggle
              disablePopover={disablePopover}
              pastPeriodChart={pastPeriodChart}
              setPastPeriodChart={setPastPeriodChart}
            />
          ) : (
            // TODO: remove once past period bar chart is released
            <SuPopoverToggle
              disablePopover={disablePopover}
              pastPeriodChart={pastPeriodChart}
              setPastPeriodChart={setPastPeriodChart}
            />
          )}

          <Box data-testid="source-utilization-export">
            <IconButton
              ref={refs.setReference}
              disabled={isExportDisabled}
              {...getReferenceProps()}
            >
              <Icon
                sx={(theme) => ({
                  color: getDownloadIconColor(theme),
                })}
                variant="downloadOutline"
              />
            </IconButton>
          </Box>

          {isOpen && (
            <FloatingFocusManager context={context}>
              <ChartDownloadMenu
                chartRef={chartRef}
                data={selectedData ?? {}}
                fields={selectedDataFields}
                floatingStyles={floatingStyles}
                getFloatingProps={getFloatingProps}
                handleOutsideClick={() => setIsOpen(false)}
                setFloating={refs.setFloating}
                svgOptions={{
                  backgroundColor: 'white',
                  style: {
                    borderInline: 'none',
                    borderBlock: 'none',
                  },
                }}
              />
            </FloatingFocusManager>
          )}
        </Box>
      </Box>
      <Box
        data-testid="source-utilization-card"
        sx={[
          (theme) => ({
            borderRadius: '5px',
            backgroundColor: theme.vars.palette.secondary.light,
            height: '21rem',
            width: '100%',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.light,
            }),
          }),
          highlightStyles,
        ]}
        ref={chartRef}
      >
        {renderComponent()}
      </Box>
    </Box>
  )
}

export default SourceUtilization
