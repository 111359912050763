/* eslint-disable security/detect-object-injection */
import {
  OnChangeFn,
  SortingState,
  Updater,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { ApolloError } from '@apollo/client'
import { Box, Typography } from '@mui/material'

import {
  ChangeHistoryEventTableItem,
  SecurityIndexEvent,
} from '@models/SecurityIndex'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { TableToolbar } from '@common/TableToolbar'
import TableHeader from '@common/Table/utils/TableHeader'
import { ComponentError } from '@common/ComponentError'
import { getEventText } from '@components/Home/SecurityIndex/SecurityIndex.utils'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

import ActionCell from './cells/ActionCell'
import CreatedAtCell from './cells/CreatedAtCell'

interface ChangeHistoryTableProps {
  events: SecurityIndexEvent[] | null | undefined
  loading: boolean
  error?: ApolloError
}

const ChangeHistoryTable: React.FC<ChangeHistoryTableProps> = ({
  events,
  loading,
  error,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ])

  /** Applies a secondary `createdAt` sort when any other column is sorted */
  const setSortingWithCreatedAtSecondarySort: OnChangeFn<SortingState> =
    useCallback(
      (updater: Updater<SortingState>) => {
        const defaultCreatedAtSort = {
          id: 'createdAt',
          desc: true,
        }
        const result =
          typeof updater === 'function' ? updater(sorting) : updater
        if (!result.find((sort) => sort.id === 'createdAt')) {
          /** If we are sorting by a non-date column, include a secondary descending sort on `createdAt` */
          setSorting([...result, defaultCreatedAtSort])
        } else if (result.length > 1) {
          /** Edge case: if the previous sort included a secondary `createdAt`, we want to prevent it from toggling the sort direction */
          setSorting([defaultCreatedAtSort])
        } else {
          /** Toggling the date sort  */
          setSorting(result)
        }
      },
      [setSorting, sorting],
    )

  const ref = useRef<HTMLDivElement>(null)

  const formattedEvents: ChangeHistoryEventTableItem[] | null | undefined =
    useMemo(() => {
      if (!events) return []

      return events?.map((event) => {
        const formattedEvent = {
          id: event.id,
          createdAt: event.createdAt,
          sourceName: event.sourceName,
          type: event.type,
          action: event.action,
          weight: event.weight,
          dataSource: event.dataSource && event.dataSource[0],
        }

        // only apply subrows if more than one data type
        if (event.dataSource?.length > 1) {
          const subRows: ChangeHistoryEventTableItem[] = []

          // skip first item; parent row will display first data source
          for (let i = 1; i < event.dataSource.length; i++) {
            subRows.push({
              ...formattedEvent,
              dataSource: event.dataSource[i],
            })
          }

          return {
            ...formattedEvent,
            subRows,
          }
        }

        return formattedEvent
      })
    }, [events])

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<ChangeHistoryEventTableItem>()

    return [
      columnHelper.accessor((row) => getEventText(row.action, row.type), {
        id: 'action',
        cell: ActionCell,
        header: () => buildGenericHeader('action'),
        sortingFn: 'text',
        sortDescFirst: false,
        minSize: 100,
        size: 250,
      }),
      columnHelper.accessor('sourceName', {
        id: 'sourceName',
        cell: (props) => {
          if (props.row.depth > 0) {
            return null
          }
          return buildGenericCell(props)
        },
        header: () => buildGenericHeader('name'),
        sortDescFirst: false,
      }),
      columnHelper.accessor('dataSource', {
        id: 'dataSource',
        cell: (props) => buildGenericCell(props),
        enableSorting: false,
        header: () => buildGenericHeader('data source'),
        size: 150,
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        cell: CreatedAtCell,
        header: () => buildGenericHeader('date'),
        sortDescFirst: true,
        size: 120,
      }),
    ]
  }, [])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable({
    columns: columnDef,
    data: formattedEvents,
    enableExpanding: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    onSortingChange: setSortingWithCreatedAtSecondarySort,
    state: {
      expanded: true,
      sorting,
      columnSizing,
    },
    onColumnSizingChange,
    columnResizeMode: 'onChange',
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => ref.current,
    estimateSize: () => 45,
    overscan: 75,
  })

  const renderContent = () => {
    if (error || !events) {
      return (
        <tr>
          <StyledTableCell
            align="center"
            colSpan={4}
            sx={{ border: 'none', padding: '50px' }}
          >
            <ComponentError />
          </StyledTableCell>
        </tr>
      )
    }

    if (loading) {
      return buildSkeletonRows(table.getAllColumns())
    } else if (formattedEvents?.length === 0) {
      return (
        <tr>
          <StyledTableCell
            align="center"
            colSpan={4}
            sx={{ border: 'none', padding: '50px' }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                marginTop: '4em',
                width: '100%',
              }}
            >
              <Typography
                color="textPrimary"
                sx={{ width: '100%', marginBottom: 1 }}
                variant="h5"
              >
                No changes have been made
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
                sx={{ width: '100%' }}
              >
                There have been no enabled / disabled source types or detections
                during the selected time period.
              </Typography>
            </Box>
          </StyledTableCell>
        </tr>
      )
    } else {
      return rowVirtualizer.getVirtualItems().map((item) => {
        const row = rows[item.index]

        return (
          <MemoizedTanStackTableRow
            disableCursor
            testId="change-history-table-row"
            key={row.id}
            visibleCells={row.getVisibleCells()}
            isSelected={false}
            rowId={`change-history-table-row-${row.index}`}
          />
        )
      })
    }
  }

  const dateFilterableStyles = useDateFilterableStyles()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 155px)',
        width: '100%',
        overflow: 'auto',
      }}
      data-testid="change-history-table-container"
    >
      <TableToolbar
        loading={loading}
        dataLength={events ? events?.length : 0}
        singularEntityName={'change'}
        pluralEntityName={'changes'}
      >
        <></>
      </TableToolbar>

      <Box ref={ref}>
        <CommonTableContainer
          sx={[
            {
              minHeight: `${rowVirtualizer.getTotalSize()}px`,
              borderRadius: '5px',
            },
            dateFilterableStyles,
          ]}
          ref={tableContainerRef}
        >
          <CommonTable data-testid="change-history-table">
            <thead data-testid="change-history-table-header">
              <tr>
                {table.getLeafHeaders().map((header, index) => (
                  <TableHeader<ChangeHistoryEventTableItem>
                    key={header.id}
                    header={header}
                    width={columnSizing[header.id]}
                    table={table}
                    // eslint-disable-next-line security/detect-object-injection
                    thRef={tableHeaderRefs[index]}
                    onResizeHandleMouseUp={handleMouseUp}
                    onResizeHandleMouseDown={handleMouseDown}
                    onResetColumnSize={resetColumnSize}
                  />
                ))}
              </tr>
            </thead>

            <tbody data-testid="change-history-table-body">
              {renderContent()}
            </tbody>
          </CommonTable>
        </CommonTableContainer>
      </Box>
    </Box>
  )
}

export default ChangeHistoryTable
