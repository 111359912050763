import { gql } from '@apollo/client'

/**
 * @category Mutations
 * @description Mutation to create a threat intel read record
 */
export interface ReportFeedbackInput {
  reportId: string
  isReportValuable: boolean
  accurateInfo: boolean
  relevantIndustry: boolean
  actionable: boolean
  shareable: boolean
  upToDate: boolean
  moreInfo?: string
}

export interface ReportFeedback extends ReportFeedbackInput {
  id: string
  userId: string
}

export interface CreateThreatIntelReadRecordData {
  createThreatIntelReadRecord: boolean
}

export interface CreateThreatIntelReadRecordVariables {
  selectedCustomer: string | null
  input: {
    threatIntelReportId: string | undefined
  }
}

export interface AddThreatIntelReportFeedbackData {
  addThreatIntelReportFeedback: ReportFeedback
}

export interface AddThreatIntelReportFeedbackVariables {
  selectedCustomer: string | null
  input: { threatIntelReportFeedback: ReportFeedbackInput }
}

export const CREATE_THREAT_INTEL_READ_RECORD = gql`
  mutation CreateThreatIntelReadRecord(
    $selectedCustomer: String
    $input: CreateThreatIntelReadRecordInput!
  ) {
    createThreatIntelReadRecord(
      selectedCustomer: $selectedCustomer
      input: $input
    )
  }
`

export const ADD_THREAT_INTEL_REPORT_FEEDBACK = gql`
  mutation AddThreatIntelReportFeedback(
    $selectedCustomer: String
    $input: ThreatIntelReportFeedbackInput
  ) {
    addThreatIntelReportFeedback(
      selectedCustomer: $selectedCustomer
      input: $input
    ) {
      userId
    }
  }
`
