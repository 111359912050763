const ResendEmail = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 7.5L11.25 12.75L18 7.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 6.375C2.25 5.75368 2.75368 5.25 3.375 5.25H19.125C19.7463 5.25 20.25 5.75368 20.25 6.375V10.415C20.7751 10.5108 21.2778 10.6704 21.75 10.8856V6.375C21.75 4.92525 20.5747 3.75 19.125 3.75H3.375C1.92525 3.75 0.75 4.92525 0.75 6.375V17.625C0.75 19.0747 1.92525 20.25 3.375 20.25H13.7151C13.4122 19.788 13.1672 19.2845 12.9906 18.75H3.375C2.75368 18.75 2.25 18.2463 2.25 17.625V6.375Z"
    />
    <g>
      <path d="M15.3664 16.5441C15.0062 16.5022 14.675 16.7613 14.6877 17.1237C14.7135 17.8569 14.9245 18.5755 15.3059 19.2106C15.8005 20.0343 16.554 20.671 17.4486 21.0215C18.3432 21.372 19.3287 21.4164 20.2512 21.1479C21.1737 20.8794 21.9814 20.3131 22.5482 19.5373C23.1151 18.7615 23.4091 17.8198 23.3845 16.8593C23.3598 15.8988 23.0179 14.9735 22.4121 14.2278C21.8063 13.482 20.9706 12.9578 20.0355 12.7369C19.3146 12.5666 18.5659 12.5841 17.8595 12.7824C17.5104 12.8804 17.3642 13.2747 17.5136 13.6051C17.663 13.9356 18.0522 14.0745 18.407 13.9994C18.8423 13.9074 19.2954 13.9115 19.7336 14.015C20.3865 14.1692 20.9698 14.5352 21.3928 15.0558C21.8157 15.5764 22.0544 16.2224 22.0716 16.893C22.0888 17.5635 21.8835 18.2209 21.4878 18.7625C21.0921 19.3042 20.5282 19.6995 19.8842 19.887C19.2402 20.0744 18.5522 20.0434 17.9276 19.7987C17.3031 19.554 16.7771 19.1095 16.4318 18.5344C16.1999 18.1484 16.0582 17.718 16.0136 17.2753C15.9773 16.9145 15.7267 16.586 15.3664 16.5441Z" />
      <path
        d="M19.0515 11.5294L17.1323 13.4243L19.0515 15.3192"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3553_137069">
        <rect width="12" height="12" transform="matrix(-1 0 0 1 25 10.125)" />
      </clipPath>
    </defs>
  </svg>
)

export default ResendEmail
