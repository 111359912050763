import { gql } from '@apollo/client'

import { CustomerHighlight, DeepwatchExpert } from '@models/index'

import {
  DW_EXPERT_CUSTOMER_FRAGMENT,
  DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES,
} from '../fragments/dwExperts'

export interface GetDeepwatchExpertsResponse {
  getSquadDashboard: Array<CustomerHighlight>
}

export interface MySquadData {
  mySquad: Array<DeepwatchExpert>
}

export interface GetMyDeepwtachExpertsVariables {
  selectedCustomer: string | null
}

export const GET_DEEPWATCH_EXPERTS_DASHBOARD = gql`
  ${DW_EXPERT_CUSTOMER_FRAGMENT}
  query GetDeepwatchExpertsDashboard {
    getSquadDashboard {
      ...DwExpertCustomerFields
    }
  }
`

export const GET_DEEPWATCH_EXPERTS_DASHBOARD_WITH_MODULES = gql`
  ${DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES}
  query GetDeepwatchExpertsDashboard {
    getSquadDashboard {
      ...DwExpertCustomerFieldsWithModules
    }
  }
`

/**
 * @deprecated Use MY_SQUAD once the [corresponding BE changes](https://github.com/Deepwatchinc/sonar-backend/pull/888)
 * are deployed to add the optional selectedCustomer parameter
 */
export const MY_SQUAD_OLD = gql`
  query MySquad {
    mySquad {
      email
      jobTitle
      name
    }
  }
`

export const MY_SQUAD = gql`
  query MySquad($selectedCustomer: String) {
    mySquad(selectedCustomer: $selectedCustomer) {
      email
      jobTitle
      name
    }
  }
`
