import { SingleValue } from 'react-select'
import { Box, Typography, useTheme } from '@mui/material'

import { Dropdown, OptionType } from '@common/Dropdown'
import { Dimension, Metric } from '@models/EnvHealth'

export interface SuDropdownsProps {
  loading: boolean
  selectedMetric: SingleValue<OptionType>
  selectedDimension: SingleValue<OptionType>
  selectedSegment: SingleValue<OptionType>
  metricOptions: OptionType[]
  dimensionOptions: OptionType[]
  segmentOptions: OptionType[]
  handleMetricChange: (newValue: SingleValue<OptionType>) => void
  handleDimensionChange: (newValue: SingleValue<OptionType>) => void
  handleSegmentChange: (newValue: SingleValue<OptionType>) => void
}

const SuDropdowns: React.FC<SuDropdownsProps> = ({
  loading,
  selectedMetric,
  selectedDimension,
  selectedSegment,
  metricOptions,
  dimensionOptions,
  segmentOptions,
  handleMetricChange,
  handleDimensionChange,
  handleSegmentChange,
}) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          width: '159px',
        }}
        data-testid="overview-metric-column"
      >
        <Typography color="textPrimary" variant="body2">
          Metric
        </Typography>
        <Dropdown
          defaultValue={metricOptions[0]}
          isDisabled={loading}
          options={metricOptions}
          onChange={handleMetricChange}
          value={selectedMetric}
          isSearchable={false}
          id="metric-dropdown"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          width: '159px',
        }}
        data-testid="overview-dimension-column"
      >
        <Typography color="textPrimary" variant="body2">
          Dimension
        </Typography>
        <Dropdown
          defaultValue={dimensionOptions[0]}
          isDisabled={loading}
          options={dimensionOptions}
          onChange={handleDimensionChange}
          value={selectedDimension}
          isSearchable={false}
          id="dimension-dropdown"
          isOptionDisabled={(option) => {
            if (selectedMetric?.value === Metric.ALERTS) {
              switch (option.label) {
                case 'Source type':
                case 'Index':
                  return true
              }
            }
            return false
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          width: '159px',
        }}
        data-testid="overview-segment-column"
      >
        <Typography color="textPrimary" variant="body2">
          Segment
        </Typography>
        <Dropdown
          styles={{
            singleValue: (base) => ({
              ...base,
              color: theme.palette.text.primary,
            }),
          }}
          defaultValue={segmentOptions[0]}
          isDisabled={loading}
          onChange={handleSegmentChange}
          options={segmentOptions}
          value={selectedSegment}
          isSearchable={false}
          id="segment-dropdown"
          isOptionDisabled={(option) => {
            if (selectedMetric?.value === Metric.ALERTS) {
              switch (option.label) {
                case 'Index':
                case 'Deviation':
                case 'Average':
                  return true
              }
            }
            if (
              selectedDimension?.value === Dimension.SOURCETYPE ||
              selectedDimension?.value === Dimension.INDEX
            ) {
              switch (option.label) {
                case 'No value':
                case 'Source type':
                case 'Index':
                case 'Deviation':
                  return true
              }
            }
            return false
          }}
        />
      </Box>
    </Box>
  )
}

export default SuDropdowns
