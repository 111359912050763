import { gql } from '@apollo/client'
import { ColumnFiltersState, ColumnSort } from '@tanstack/react-table'

import { Pagination, PaginationInput } from '@models/Tickets'
import { Detections } from '@models/Detections'

export interface PaginatedDetectionsData {
  detections: Detections[]
  pagination: Pagination
}

export interface DetectionsData {
  detections: PaginatedDetectionsData
  mitreTactics: string[]
}

export interface DetectionsVariables {
  filters: ColumnFiltersState
  keywordSearch: string
  pagination: PaginationInput
  selectedCustomer: string | null
  sorting: ColumnSort
}

export const DETECTION = gql`
  query Detection($selectedCustomer: String, $useCase: String) {
    detection(
      selectedCustomer: $selectedCustomer
      input: { useCase: $useCase }
    ) {
      useCase
      title
      customerVersion
      releaseVersion
      description
      mitreTactics
      mitreTechniques
      dataTypes
      dataAvailable
      availableInVersion
      status
      statusDetails
      reviewer
      reviewNotes
      reviewedOn
      logSource
      relatedThreatIntelReports {
        title
        type
        createdDate
        id
      }
      relatedTickets {
        sysId
        state
        priority
        ticketNumber
      }
      daysInTrialMode
    }
  }
`

export const DETECTIONS = gql`
  query Detections(
    $filters: [FilterInput]
    $keywordSearch: String
    $selectedCustomer: String
    $pagination: PaginationInput
    $sorting: SortingInput
  ) {
    detections(
      filters: $filters
      keywordSearch: $keywordSearch
      selectedCustomer: $selectedCustomer
      pagination: $pagination
      sorting: $sorting
    ) {
      pagination {
        limit
        offset
        total
      }
      detections {
        useCase
        title
        customerVersion
        releaseVersion
        description
        mitreTactics
        mitreTechniques
        dataTypes
        dataAvailable
        availableInVersion
        status
        statusDetails
        reviewer
        reviewNotes
        reviewedOn
        logSource
        relatedThreatIntelReports {
          title
          type
          createdDate
          id
        }
        relatedTickets {
          sysId
          state
          priority
          ticketNumber
        }
        daysInTrialMode
      }
    }
    mitreTactics(selectedCustomer: $selectedCustomer)
  }
`

export const GET_DETECTION_COVERAGE_OVERVIEW = gql`
  query GetDetectionCoverageOverview($selectedCustomer: String) {
    getDetectionCoverageOverview(selectedCustomer: $selectedCustomer) {
      splunkUtilization {
        license
        largestLogSource
        splunkUsage {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      splunkUtilizationWeekTrend {
        previousWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
        currentWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      mitreCoverageCustomerByTactic {
        mitreTacticId
        totalEnabledUseCaseCount
        totalEligibleUseCaseCount
      }
      mitreCoverageCustomer {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        totalUseCaseCount
        totalEligibleUseCaseCount
        totalEnabledUseCaseCount
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreTechniqueId
          mitreTechniqueName
          totalUseCaseCount
          totalEnabledUseCaseCount
          totalEligibleUseCaseCount
        }
      }
      enabledDetections
      availableDetections
      eligibleDetections
      mitreCoverage {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        platformList
        permissionList
        totalUseCaseCount
        isCovered
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreSubtechniqueDescription
          mitreTechniqueId
          totalUseCaseCount
          isCovered
        }
      }
      mitreCoverageByTactic {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        totalUseCaseCount
        version
      }
      alertsByMitreTactic {
        tactic
        incidentCount
      }
      logSourceByUtilization {
        source
        totalGb
        utilizationPercentage
      }
      logSourceByAlert {
        source
        incidentCount
        alertsPercentage
      }
      maturityTrends {
        deepwatch {
          date
          score
        }
        customer {
          date
          score
        }
        customerIndustriesAverage {
          date
          score
        }
      }
    }
  }
`

export const GET_DETECTION_COVERAGE_OVERVIEW_WITH_DETECTIONS = gql`
  query GetDetectionCoverageOverview($selectedCustomer: String) {
    getDetectionCoverageOverview(selectedCustomer: $selectedCustomer) {
      splunkUtilization {
        license
        largestLogSource
        splunkUsage {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      splunkUtilizationWeekTrend {
        previousWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
        currentWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      mitreCoverageCustomerByTactic {
        mitreTacticId
        totalEnabledUseCaseCount
        totalEligibleUseCaseCount
      }
      mitreCoverageCustomer {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        totalUseCaseCount
        totalEligibleUseCaseCount
        totalEnabledUseCaseCount
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreTechniqueId
          mitreTechniqueName
          totalUseCaseCount
          totalEnabledUseCaseCount
          totalEligibleUseCaseCount
        }
      }
      enabledDetections
      availableDetections
      eligibleDetections
      mitreCoverage {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        platformList
        permissionList
        totalUseCaseCount
        isCovered
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreSubtechniqueDescription
          mitreTechniqueId
          totalUseCaseCount
          isCovered
        }
        associatedDetections {
          useCase
          title
          description
          status
        }
      }
      mitreCoverageByTactic {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        totalUseCaseCount
        version
      }
      alertsByMitreTactic {
        tactic
        incidentCount
      }
      logSourceByUtilization {
        source
        totalGb
        utilizationPercentage
      }
      logSourceByAlert {
        source
        incidentCount
        alertsPercentage
      }
      maturityTrends {
        deepwatch {
          date
          score
        }
        customer {
          date
          score
        }
        customerIndustriesAverage {
          date
          score
        }
      }
    }
  }
`
