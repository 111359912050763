import { useContext, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { Ticket, TicketStateEnum, TicketingRelatedCase } from '@models/index'
import { useTicketsContext } from '@hooks/index'
import usePendingActivitySubmission, {
  PendingActivitySortOrder,
} from '@ticketsHooks/usePendingActivitySubmission'
import apolloClient from '@config/apolloClient'
import {
  GET_TICKET_ACTIVITY,
  GetTicketDetailsVariables,
  TicketActivityResponse,
} from '@queries/ticket'

import { TicketCommentForm } from '../../TicketEdit/components/TicketCommentForm'
import {
  TicketActivityType,
  TicketActivityTypeEnum,
} from '../../TicketEdit/Types'
import TicketActivityItem from '../../TicketEdit/components/TicketActivity/TicketActivityItem'
import { convertTicketCommentToTicketActivity } from '../../TicketEdit/components/TicketActivity/TicketActivity.utils'
import { TicketEditContextProvider } from '../../TicketEdit/context/TicketEditContext'
import { formatDateAndTime } from '../TicketLibrary.utils'

interface TicketDetailsProps {
  focusedTicket: Ticket | TicketingRelatedCase
}

const ActivityTab = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const {
    state: { dwExpertsCustomer, customer },
  } = useContext(Context)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const { isEditable } = useTicketsContext()
  const topOfCommentsList = useRef<HTMLDivElement>(null)
  const { pendingActivityItems, setItemError, setItemCompleted, addItem } =
    usePendingActivitySubmission(PendingActivitySortOrder.DESCENDING)

  const data = apolloClient.readQuery<
    { getTicketDetails: TicketActivityResponse },
    GetTicketDetailsVariables
  >({
    query: GET_TICKET_ACTIVITY,
    variables: {
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
      ticketId: focusedTicket.sysId,
    },
  })

  const ticketComments = useMemo<TicketActivityType[]>(() => {
    const parsedComments = data?.getTicketDetails.comments
      ? data?.getTicketDetails.comments.map(
          convertTicketCommentToTicketActivity,
        )
      : []
    const placeholderComments: TicketActivityType[] = []

    // If no comments show created comment
    if (
      !data?.getTicketDetails.comments?.length &&
      (data?.getTicketDetails.openedBy || data?.getTicketDetails.createdDate)
    ) {
      const [firstName, lastName] =
        data.getTicketDetails.openedBy?.split(' ') ||
        `MDR ${dwExpertsCustomer || customer}`

      placeholderComments.push({
        type: TicketActivityTypeEnum.Placeholder,
        id: '',
        createdDate: formatDateAndTime(data?.getTicketDetails?.sysCreatedOn),
        text: `${data?.getTicketDetails.ticketNumber} Created.`,
        userInfo: {
          id: 'createdBy',
          firstName: firstName,
          lastName: lastName || '', // Account for users with no last name
          type: 'system',
        },
      })
    }

    // Sort in descending order
    const result = [
      ...pendingActivityItems,
      ...parsedComments,
      ...placeholderComments,
    ].sort(
      ({ createdDate: aCreatedDate }, { createdDate: bCreatedDate }) =>
        new Date(bCreatedDate).getTime() - new Date(aCreatedDate).getTime(),
    )

    return result
  }, [
    customer,
    dwExpertsCustomer,
    data?.getTicketDetails,
    pendingActivityItems,
  ])

  // Scroll to the top once a user adds an activity item
  useEffect(() => {
    if (pendingActivityItems.length > 0) {
      topOfCommentsList.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [pendingActivityItems, topOfCommentsList])

  return (
    <Box id="new-activity-tab-details">
      <Box sx={{ padding: '1rem' }}>
        <Box ref={topOfCommentsList} />
        {ticketComments?.map((comment, index) => {
          return (
            <TicketActivityItem
              key={index}
              activity={comment}
              shouldShowVerticalLine={
                ticketComments.length > 1 && index !== ticketComments.length - 1
              }
            />
          )
        })}
      </Box>

      <Box sx={{ padding: '0 1rem' }}>
        <TicketEditContextProvider
          sysId={focusedTicket.sysId}
          isEditable={isEditable}
        >
          <TicketCommentForm
            disabled={
              focusedTicket.state === TicketStateEnum.Closed || !isEditable
                ? true
                : false
            }
            ticketId={focusedTicket.sysId}
            onCompleted={setItemCompleted}
            onError={setItemError}
            onSubmit={addItem}
          />
        </TicketEditContextProvider>
      </Box>
    </Box>
  )
}

export default ActivityTab
