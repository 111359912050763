import { Box, Typography } from '@mui/material'

import { ChartData } from './UtilizationDetailsChartTypes'

import type { BarTooltipProps } from '@nivo/bar'

export const UtilizationDetailsChartTooltip: React.FC<
  BarTooltipProps<ChartData>
> = ({ value }) => (
  <Box
    sx={(theme) => ({
      padding: '0.25rem',
      background: theme.palette.secondary.main,
      border: `0.75px solid ${theme.palette.secondary.light}`,
      borderRadius: '3px',
      ...theme.applyStyles('dark', {
        background: theme.palette.secondary.darker,
        border: `0.75px solid ${theme.palette.secondary.lighter}`,
      }),
    })}
  >
    <Typography
      sx={(theme) => ({
        color: theme.palette.text.primary,
        ...theme.applyStyles('dark', { color: theme.palette.text.secondary }),
      })}
      variant="caption"
    >
      {value} GB
    </Typography>
  </Box>
)
