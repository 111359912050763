import { useMemo } from 'react'
import { format } from 'date-fns'
import { Typography, Box, Tooltip } from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import { useSecurityIndexContext } from '@hooks/useSecurityIndexContext'
import { Loader } from '@common/Loader'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

const IndexDetails = () => {
  const {
    yourIndex: { trends, details, unclassifiedLogSourceCount },
    yourIndexError,
    yourIndexLoading,
  } = useSecurityIndexContext()

  const sortedYourTrendsData = useMemo(
    () => trends.yourTrend?.sort((obj1, obj2) => obj1.index - obj2.index),
    [trends.yourTrend],
  )

  const dateFilterableStyles = useDateFilterableStyles({
    getDefaultBorderColor: (theme) => ({
      light: theme.vars.palette.secondary.main,
      dark: theme.vars.palette.secondary.lighter,
    }),
  })

  if (yourIndexError) {
    return (
      <Box sx={{ flex: '1 0 25%', textAlign: 'center' }}>
        <ComponentError errorContainerStyles={{ height: '100%' }} />
      </Box>
    )
  }

  let pastPeriodTooltip: string = ''
  if (trends.pastPeriodTrend.length) {
    const currentYear = new Date().getFullYear()
    let pastPeriodStartDateFormat = 'MMM do'
    let pastPeriodEndDateFormat = 'MMM do'
    const pastPeriodStartDate = new Date(
      `${trends.pastPeriodTrend[0].metricDate}T00:00`,
    )
    const pastPeriodEndDate = new Date(
      `${
        trends.pastPeriodTrend[trends.pastPeriodTrend.length - 1].metricDate
      }T00:00`,
    )
    if (pastPeriodStartDate.getFullYear() < currentYear) {
      pastPeriodStartDateFormat = pastPeriodStartDateFormat.concat(', yyyy')
    }
    if (pastPeriodEndDate.getFullYear() < currentYear) {
      pastPeriodEndDateFormat = pastPeriodEndDateFormat.concat(', yyyy')
    }
    pastPeriodTooltip = `Index for ${format(
      pastPeriodStartDate,
      pastPeriodStartDateFormat,
    )}`
    if (pastPeriodStartDate < pastPeriodEndDate) {
      pastPeriodTooltip = pastPeriodTooltip.concat(
        ` - ${format(pastPeriodEndDate, pastPeriodEndDateFormat)}`,
      )
    }
  }

  const validatePastPeriod = (): string | number => {
    if (trends.pastPeriodTrend.length > 0) {
      return trends.pastPeriodTrend[trends.pastPeriodTrend.length - 1].index
    } else {
      return 'No data'
    }
  }

  const PastPeriod = (
    <Box>
      <Typography fontWeight={500} variant="body2">
        Past period
      </Typography>
      <Typography
        data-testid="past-period-index"
        variant="h6"
        sx={(theme) => ({
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {yourIndexLoading ? (
          <Loader centered size={10} />
        ) : (
          validatePastPeriod()
        )}
      </Typography>
    </Box>
  )

  return (
    <Box
      data-testid={'index-details'}
      sx={[
        (theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          flex: '1 0 25%',
          padding: '16px 16px 0',
          borderRadius: '5px',
          ...theme.applyStyles('dark', {
            backgroundColor: 'transparent',
            flex: '1 0 25%',
            borderRadius: '5px',
            padding: '16px 16px 0',
          }),
        }),
        dateFilterableStyles,
      ]}
    >
      <Box sx={{ lineHeight: '120%' }}>
        <Typography fontWeight={600} variant="body2">
          Index details
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 0 16px',
          lineHeight: '120%',
        }}
      >
        {false && ( //? hidden intentionally for now https://deepwatch.atlassian.net/browse/SON-3322
          <Box>
            <Tooltip
              title="How much your Index has increased since onboarding"
              placement="top-start"
              data-testid="tooltip-content"
            >
              <Box>
                <Typography color="textPrimary" variant="body2">
                  Lifetime increase
                </Typography>
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                >
                  {yourIndexLoading ? (
                    <Loader centered size={10} />
                  ) : (
                    details.lifetimeIncrease
                  )}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box>
          {!yourIndexLoading && trends.pastPeriodTrend.length ? (
            <Tooltip
              data-testid="tooltip-content"
              title={pastPeriodTooltip}
              placement="top-start"
            >
              {PastPeriod}
            </Tooltip>
          ) : (
            PastPeriod
          )}
        </Box>
      </Box>

      {!!unclassifiedLogSourceCount && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '14px',
            paddingBottom: '13px',
            borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
            ...theme.applyStyles('dark', {
              borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
            }),
            fontSize: '14px',
            lineHeight: '150%',
          })}
        >
          <Tooltip
            data-testid="tooltip-content"
            title="Source types that have not been mapped to a data source"
          >
            <Box>
              <Typography color="textPrimary" variant="body2">
                Unclassified source types
              </Typography>
              <Typography
                data-testid="unclassified-source-types"
                variant="body2"
                sx={(theme) => ({
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              >
                {yourIndexLoading ? (
                  <Loader centered size={10} />
                ) : (
                  unclassifiedLogSourceCount
                )}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      )}

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 0',
          borderTop: `1px solid ${theme.vars.palette.secondary.dark}`,
          ...theme.applyStyles('dark', {
            borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
          }),
        })}
      >
        <Typography variant="body2">Enabled source types</Typography>
        <Typography
          fontWeight={500}
          variant="body2"
          sx={(theme) => ({
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.enabledLogSourcesCount
          )}
        </Typography>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 0',
          borderTop: `1px solid ${theme.vars.palette.secondary.dark}`,
          ...theme.applyStyles('dark', {
            borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
          }),
        })}
      >
        <Typography variant="body2">Enabled detections</Typography>
        <Typography
          fontWeight={500}
          variant="body2"
          sx={(theme) => ({
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.enabledDetectionsCount
          )}
        </Typography>
      </Box>

      {!!details.missingDataSourcesCount && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem 0',
            borderTop: `1px solid ${theme.vars.palette.secondary.dark}`,
            ...theme.applyStyles('dark', {
              borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
            }),
          })}
        >
          <Typography variant="body2">Missing data sources</Typography>
          <Typography
            fontWeight={500}
            variant="body2"
            sx={(theme) => ({
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {yourIndexLoading ? (
              <Loader centered size={10} />
            ) : (
              details.missingDataSourcesCount
            )}
          </Typography>
        </Box>
      )}

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 0',
          borderTop: `1px solid ${theme.vars.palette.secondary.dark}`,
          ...theme.applyStyles('dark', {
            borderTop: `1px solid ${theme.vars.palette.secondary.lighter}`,
          }),
          fontSize: '14px',
          lineHeight: '150%',
        })}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip
            title="The low and high score during the selected time period"
            placement="top-start"
            data-testid="tooltip-content"
            sx={{ maxWidth: 'auto' }}
          >
            <Typography color="textPrimary" variant="body2">
              Range
            </Typography>
          </Tooltip>
          <Typography
            variant="body2"
            sx={(theme) => ({
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {yourIndexLoading ? (
              <Loader centered size={10} />
            ) : (
              `${
                sortedYourTrendsData.length && sortedYourTrendsData[0].index
              } - ${
                sortedYourTrendsData.length &&
                sortedYourTrendsData[sortedYourTrendsData.length - 1].index
              }`
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default IndexDetails
