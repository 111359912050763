import { useLazyQuery } from '@apollo/client'
import { IconButton } from '@mui/material'
import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import Icon from '@common/Icon'
import { Forwarder } from '@models/Forwarders'
import { Forwarders, ENVIRONMENT_HEALTH_FORWARDERS } from '@queries/forwarders'
import ChartDownloadMenu, { DownloadType } from '@common/ChartDownloadMenu'

const forwarderFields = [
  'hostname',
  'lastIndexConnection',
  'os',
  'status',
  'type',
  'version',
]
const ForwardersDownload = ({ total }: { total: number }) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const [downloadAllForwarderData, { loading }] = useLazyQuery<Forwarders>(
    ENVIRONMENT_HEALTH_FORWARDERS,
  )

  const [isOpen, setIsOpen] = useState(false)
  const chartRef = useRef<HTMLDivElement>(null)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(16)],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  /**
   * makes multiple queries for paginated data
   * If we anticipate that forwarder data will increase significantly then we may
   * want to try another approach, but this should be a stable solution for the time being
   * @returns Forwarder[]
   */
  const requestPaginatedData = async (): Promise<Forwarder[]> => {
    let allForwarderData: Forwarder[] = []
    /**
     * The AppSync resolver limit is around 34,400 forwarder data points, or about 2.6MB
     * 34,000 is chosen here for allDataLimit to limit the number of requests needed.
     */
    const allDataLimit = 34000
    let allDataOffset = 0
    let allDataTotal = total

    while (allDataOffset <= allDataTotal) {
      const {
        data: {
          environmentHealthForwarders: {
            forwarders: allForwarders,
            pagination: { total: totalRequested },
          },
        } = {
          environmentHealthForwarders: {
            forwarders: [],
            pagination: { offset: 0, limit: 0 },
          },
        },
      } = await downloadAllForwarderData({
        variables: {
          selectedCustomer,
          pagination: {
            limit: allDataLimit,
            offset: allDataOffset,
          },
        },
      })
      allDataTotal = totalRequested ?? 0
      allForwarderData = [...allForwarderData, ...allForwarders]
      allDataOffset += allDataLimit
    }

    return allForwarderData
  }

  const downloadTypes = {
    CSV: DownloadType.CSV,
    JSON: DownloadType.JSON,
  }

  return (
    <IconButton
      disabled={loading}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <Icon
        sx={(theme) => ({
          color: loading
            ? theme.vars.palette.text.disabled
            : theme.vars.palette.text.primary,
        })}
        variant="downloadOutline"
      />
      {isOpen && (
        <FloatingFocusManager context={context}>
          <ChartDownloadMenu
            handleOutsideClick={() => setIsOpen(false)}
            data={{}} // async request handled in triggerAsyncDownload
            fields={forwarderFields}
            fileName="all-forwarder-data"
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            setFloating={refs.setFloating}
            chartRef={chartRef}
            svgOptions={{
              backgroundColor: 'white',
              style: {
                border: 'none',
                borderInline: 'none',
                borderRight: 'none',
              },
            }}
            downloadType={downloadTypes}
            triggerAsyncDownload={requestPaginatedData}
          />
        </FloatingFocusManager>
      )}
    </IconButton>
  )
}

export default ForwardersDownload
