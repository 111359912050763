import { Box } from '@mui/material'

import { Loader } from '@common/Loader'

import MitreCarousel from './MitreCarousel'
import { useMitreOverviewContext } from '../../context/MitreOverviewContext'

export default function MitreCoverageList() {
  const { coverage } = useMitreOverviewContext()

  if (coverage.dw.length < 1) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Loader strokeWidth={1} size={25} />
      </Box>
    )
  }

  return (
    <>
      <MitreCarousel />
    </>
  )
}
