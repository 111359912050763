/* eslint-disable security/detect-object-injection */
import {
  ResponsiveScatterPlot,
  ScatterPlotCustomSvgLayer,
} from '@nivo/scatterplot'
import { format } from 'date-fns'
import { Box, Typography, useTheme } from '@mui/material'

import { getTimezoneRemovedDate, toTimeUnits } from '@utils/DateTimeUtils'
import { MttxScatterChartDataPoint } from '@models/MttxDetails'
import { ChartLegend } from '@common/ChartLegend'
import { Loader } from '@common/Loader'

import { chartColors, nivoTheme, priorityOrder } from '../../Mttx.utils'

interface MttxScatterProps {
  data: MttxScatterChartDataPoint[]
  loading: boolean
  priority: string
  startDate: string
  endDate: string
}

export const customMTTXScatterTooltip = ({ node }) => {
  return (
    <Box
      data-testid="mttx-bar-tool-tip"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        borderRadius: '5px',
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
          border: `1px solid ${theme.vars.palette.text.secondary}`,
        }),
      })}
    >
      <Typography
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
          padding: '0 4px',
        })}
        variant="body2"
      >
        {node.data.priority}
      </Typography>
      <Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', padding: '0 4px' }}>
          <Typography fontWeight={500} variant="body2">
            {node.data.ticketId}
          </Typography>{' '}
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {toTimeUnits(node.data.duration)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const MTTXScatter: React.FC<MttxScatterProps> = ({
  data,
  loading,
  startDate,
  endDate,
}) => {
  const theme = useTheme()

  interface DataItem {
    x: string
    y: number
    ticketId: string
    priority: string
    duration: number
    date: string
  }

  interface DataGroup {
    id: string
    data: DataItem[]
  }

  const transformedData = data
    .reduce<DataGroup[]>((acc, item) => {
      // Check if there already exists an entry for this priority
      const existingGroup = acc.find((group) => group.id === item.priority)
      if (existingGroup) {
        // If the group exists, just push the new item to the group's data array
        existingGroup.data.push({
          x: item.date,
          y: item.duration,
          ticketId: item.ticketId,
          priority: item.priority,
          duration: item.duration,
          date: item.date,
        })
      } else {
        // If the group does not exist, create a new group with the priority as the id
        acc.push({
          id: item.priority,
          data: [
            {
              x: item.date,
              y: item.duration,
              ticketId: item.ticketId,
              priority: item.priority,
              duration: item.duration,
              date: item.date,
            },
          ],
        })
      }
      return acc
    }, [])
    .sort((a, b) => priorityOrder.indexOf(a.id) - priorityOrder.indexOf(b.id))

  const LoadingLayer = () => (
    <foreignObject
      id="loading-layer"
      data-testid="loading-layer"
      x="30%"
      y="30%"
      width="100%"
      height="100%"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader strokeWidth={1} size={40} />
    </foreignObject>
  )

  const isEmpty = data.length === 0

  const EmptyStateLayer = () => (
    <Box
      component="text"
      x="40%"
      y="40%"
      textAnchor="middle"
      dominantBaseline="central"
      sx={(theme) => ({
        fill: theme.vars.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
      })}
    >
      Not available
    </Box>
  )

  const xAxisExtrema: ScatterPlotCustomSvgLayer<{
    x: string
    y: number
    ticketId: string
    priority: string
    duration: number
    date: string
  }> = ({ innerWidth, innerHeight, nodes }) => {
    if (!loading && nodes.length) {
      // Parse and format the original dates from the date filter
      const minDate = format(
        new Date(getTimezoneRemovedDate(startDate)),
        'MMM dd, yyyy',
      )
      const maxDate = format(
        new Date(getTimezoneRemovedDate(endDate)),
        'MMM dd, yyyy',
      )
      return (
        <g>
          <Box
            component="text"
            x={0}
            y={innerHeight + 18}
            textAnchor="start"
            sx={(theme) => ({
              fill: theme.vars.palette.text.primary,
              fontSize: theme.typography.caption.fontSize,
            })}
          >
            {minDate}
          </Box>
          <Box
            component="text"
            x={innerWidth}
            y={innerHeight + 18}
            textAnchor="end"
            sx={(theme) => ({
              fill: theme.vars.palette.text.primary,
              fontSize: theme.typography.caption.fontSize,
            })}
          >
            {maxDate}
          </Box>
        </g>
      )
    }
    return null
  }

  const getCustomLayers = () => {
    if (loading) {
      return LoadingLayer
    } else if (data && isEmpty) {
      return EmptyStateLayer
    }
    return 'nodes'
  }

  const legendItems = data
    .reduce((accumulator: string[], item) => {
      if (!accumulator.includes(item.priority)) {
        accumulator.push(item.priority)
      }

      return accumulator
    }, [])
    .map((item) => ({
      label: item,
      color: chartColors(theme, item),
    }))
    .sort(
      (a, b) => priorityOrder.indexOf(a.label) - priorityOrder.indexOf(b.label),
    )

  return (
    <>
      <ChartLegend legendItems={legendItems} />
      <Box
        id="mttx-scatter-chart"
        data-testid="mttx-scatter-chart"
        sx={{ height: '265px' }}
      >
        <ResponsiveScatterPlot
          data={transformedData}
          margin={{ top: 36, right: 16, bottom: 26, left: 65 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
          }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          axisTop={null}
          key={data.length}
          axisRight={null}
          theme={nivoTheme(theme)}
          axisBottom={{
            tickValues: 0,
          }}
          axisLeft={{
            format: (value) => toTimeUnits(value),
            tickValues: 5,
          }}
          nodeSize={6}
          colors={({ serieId }) => chartColors(theme, serieId as string)}
          layers={[
            'grid',
            'axes',
            getCustomLayers(),
            xAxisExtrema,
            'markers',
            'annotations',
          ]}
          useMesh={false}
          tooltip={(node) => customMTTXScatterTooltip(node)}
          isInteractive={true}
          animate={true}
        />
      </Box>
    </>
  )
}

export default MTTXScatter
