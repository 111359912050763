import { useQuery } from '@apollo/client'
import { Avatar, Box, Link, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAvatarInitials } from '@hooks/useAvatarInitials'
import { getAccessGroups } from '@utils/index'
import { MY_TEAM, MyTeamData } from '@queries/user'
import { Paths } from '@components/App/Types'
import { Loader } from '@common/Loader'

const MyTeam = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { loading, data: { myTeam: teammates } = { myTeam: [] } } =
    useQuery<MyTeamData>(MY_TEAM, {
      variables: {
        selectedCustomer: selectedCustomer,
      },
    })

  const avatarInitials = useAvatarInitials
  const navigate = useNavigate()

  const isAdmin =
    getAccessGroups().filter((item) => {
      return (item as string).includes('-portal-admins')
    }).length > 0

  const handleLinkClick = () => {
    navigate(Paths.USER_MANAGEMENT)
  }

  return (
    <>
      <Box
        data-testid="my-team"
        sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Box
          sx={(theme) => ({
            display: 'inline-flex',
            gap: '8px',
            marginBottom: '14px',
            alignItems: 'center',
            '& p + p': {
              marginTop: 0,
            },
            '& a': {
              textDecoration: 'none',
              verticalAlign: 'middle',
            },
            '& a:hover': {
              color: `var(${theme.vars.palette.text.secondary})`,
            },
          })}
        >
          <Typography fontWeight={600} color="textPrimary" variant="body1">
            My team
          </Typography>
          {isAdmin && (
            <Link
              component="button"
              onClick={handleLinkClick}
              variant="caption"
            >
              Manage
            </Link>
          )}
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '1.5rem 2rem',
            '@media screen and (max-width: 425px)': {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          {loading ? (
            <>
              {[...Array(3)].map((_, index) => (
                <Box key={index} sx={{ minHeight: '180px' }}>
                  <Loader />
                </Box>
              ))}
            </>
          ) : (
            <>
              {teammates.map((user) => (
                <Box
                  key={user.email}
                  sx={(theme) => ({
                    display: 'flex',
                    padding: '1.5rem',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0.5rem',
                    border: `1px solid ${theme.vars.palette.secondary.main}`,
                    borderRadius: '0.3125rem',
                    background: theme.vars.palette.secondary.light,
                    textAlign: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    ...theme.applyStyles('dark', {
                      background: theme.vars.palette.secondary.main,
                      border: 'none',
                    }),
                  })}
                >
                  <Avatar
                    sx={{ fontSize: 28, width: 80, height: 80 }}
                    variant="dw"
                  >
                    {avatarInitials({
                      firstName: user.firstName,
                      lastName: user.lastName,
                    })}
                  </Avatar>

                  <Typography
                    variant="h6"
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="body2"
                  >
                    {user.title}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default MyTeam
