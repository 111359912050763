const ATILogo = ({ fill }) => {
  return (
    <svg viewBox={`0 0 29 29`} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="28" height="28" rx="5" fill="currentColor" />
      <path
        d="M6.45954 18.7441H4.51953L9.71739 8.71094H11.9611L13.8326 18.7441H11.8926L10.5453 10.7685H10.4669L6.45954 18.7441ZM7.1748 14.8102H10.4706L10.763 16.2701H6.92985L7.1748 14.8102Z"
        fill={fill}
      />
      <path
        d="M12.897 10.2345L12.6495 8.71094H21.1567L20.902 10.2345H17.8009L16.39 18.7441H14.5872L15.9981 10.2345H12.897Z"
        fill={fill}
      />
      <path
        d="M23.4998 8.71094L21.8341 18.7441H20.0166L21.6823 8.71094H23.4998Z"
        fill={fill}
      />
    </svg>
  )
}

export default ATILogo
