import { gql } from '@apollo/client'

export const THREAT_INTEL_REPORT_SECTION_FRAGMENT = gql`
  fragment ThreatIntelReportSection on ThreatIntelReportContent {
    heading
    level
    content
    originalMarkdown
  }
`

export const THREAT_INTEL_REPORT_DATA_FRAGMENT = gql`
  fragment ThreatIntelReportData on ThreatIntelReport {
    id
    title
    type
    labels {
      type
      name
    }
    source
    createdDate
    overview
    relatedTickets {
      sysId
      ticketNumber
      state
    }
    markAsRead
    sourceMaterial {
      name
      link
    }
    targetedIndustries
    associatedDetections {
      title
      mitreTechniques
      status
      useCase
    }
  }
`
