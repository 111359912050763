import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tabs, Tab } from '@mui/material'

import { Paths } from '@components/App/Types'
import { useCustomer } from '@hooks/useCustomer'

enum DeepwatchExpertsTab {
  MY_HOME = 0,
  CUSTOMER = 1,
}

export const DeepwatchExpertsTabs = () => {
  const {
    customer: { customerName, customerShortName },
  } = useCustomer()
  const { featureNgMdr } = useFlags()
  const navigate = useNavigate()
  const location = useLocation()

  const navigateToTab = (_event: React.SyntheticEvent, tabIndex: number) => {
    if (tabIndex === DeepwatchExpertsTab.MY_HOME) {
      navigate(Paths.DEEPWATCH_EXPERTS)
    } else {
      navigate(
        `${featureNgMdr ? Paths.MDR : Paths.DASHBOARD}?customer=${customerShortName}`,
      )
    }
  }

  return (
    <Tabs
      data-testid="deepwatch-experts-tabs"
      value={location.pathname === Paths.DEEPWATCH_EXPERTS ? 0 : 1}
      onChange={navigateToTab}
      sx={(theme) => ({
        alignSelf: 'end',
        height: '100%',
        '.MuiTabs-fixed': { borderBottom: 0 },
        '.MuiTabs-flexContainer': { height: '100%' },
        '.MuiTabs-indicator': {
          backgroundColor: theme.vars.palette.primary.light,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.primary.main,
          }),
        },
        '.MuiTab-root': {
          color: `${theme.vars.palette.text.primary} !important`,
          ':hover': {
            color: theme.vars.palette.text.secondary,
          },
          ...theme.applyStyles('dark', {
            color: `${theme.vars.palette.text.secondary} !important`,
            ':hover': {
              color: theme.vars.palette.text.secondary,
            },
          }),
        },
        '.Mui-selected': {
          fontWeight: 600,
        },
      })}
    >
      <Tab label="My Home" />
      {customerName ? <Tab label={customerName} /> : null}
    </Tabs>
  )
}
