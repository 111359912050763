import { Box, Typography } from '@mui/material'

import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
} from '@models/DetectionCoverage'

import TacticDetail from './TacticDetail'
import TechniqueDetail from './TechniqueDetail'
import SubTechniqueDetail from './SubTechniqueDetail'

import './MitreSideSheet.scss'

interface MitreSideSheetContentProps {
  tactic?: MitreCoverageTactic
  customerTacticTechniques: MitreCoverageCustomer[]
  techniqueIndex?: number
  subTechniqueIndex?: number
}

const MitreSideSheetContent = ({
  customerTacticTechniques,
  tactic,
  techniqueIndex,
  subTechniqueIndex,
}: MitreSideSheetContentProps) => {
  if (!tactic) {
    return (
      <Box
        sx={{ display: 'flex', flexFlow: 'column nowrap', gap: '24px' }}
        id="mitre-side-sheet"
      >
        <Box>
          <Typography color="textPrimary">
            Unable to fetch content. Please try another technique.
          </Typography>
        </Box>
      </Box>
    )
  }

  let detailComponent: JSX.Element | null = null

  if (subTechniqueIndex !== undefined && techniqueIndex !== undefined) {
    // eslint-disable-next-line security/detect-object-injection
    const technique = tactic.techniques[techniqueIndex]
    const subTechnique =
      // eslint-disable-next-line security/detect-object-injection
      technique.subtechniques[subTechniqueIndex]

    detailComponent = (
      <SubTechniqueDetail
        customerTacticTechniques={customerTacticTechniques}
        tacticName={tactic.mitreTacticName}
        technique={technique}
        subTechnique={subTechnique}
      />
    )
  } else if (techniqueIndex !== undefined) {
    // eslint-disable-next-line security/detect-object-injection
    const technique = tactic.techniques[techniqueIndex]

    detailComponent = (
      <TechniqueDetail
        tacticName={tactic.mitreTacticName}
        technique={technique}
        customerTacticTechniques={customerTacticTechniques}
      />
    )
  } else {
    detailComponent = (
      <TacticDetail
        customerTacticTechniques={customerTacticTechniques}
        tactic={tactic}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
      }}
      id="mitre-side-sheet"
    >
      {detailComponent}
    </Box>
  )
}

export default MitreSideSheetContent
