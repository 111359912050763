import type { ColumnSort, SortingState } from '@tanstack/react-table'

/**
 * Inverts the sort direction for specified columns, typically used for date/time fields
 * which are displayed as relative time or timestamps (e.g. "Last Updated", "Age", etc.).
 * @param {SortingState} sorting Current sorting state containing column sort information
 * @param {Array<keyof object>} columnIds Array of column IDs whose sort direction should be inverted
 * @returns {ColumnSort[]} Updated array of column sort configurations with inverted directions for the  specified columns
 * @example
 * const [sorting, setSorting] = useState<SortingState>( ... )
 * const sortingWithInvertedTimeAgoFields = invertColumnSortDirection<TicketRelatedUnion>(
 *   sorting,
 *   ['sysCreatedOn', 'sysUpdatedOn'],
 * )
 */
export const invertColumnSortDirection = <T extends object>(
  sorting: SortingState,
  columnIds: Array<keyof T>,
): ColumnSort[] =>
  sorting.map((sortInfo) =>
    columnIds.includes(sortInfo.id as keyof T)
      ? { ...sortInfo, desc: !sortInfo.desc }
      : sortInfo,
  )
