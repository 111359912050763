import { gql } from '@apollo/client'

export const DW_EXPERT_CUSTOMER_FRAGMENT = gql`
  fragment DwExpertCustomerFields on SquadHomeCustomerData {
    customerShortName
    name
    usageGb
    usageLicenseGb
    openCriticalCount
    openWaitingDeepwatchCount
    isBookmarkedCustomer
  }
`

export const DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES = gql`
  fragment DwExpertCustomerFieldsWithModules on SquadHomeCustomerData {
    customerShortName
    name
    usageGb
    usageLicenseGb
    openCriticalCount
    openWaitingDeepwatchCount
    isBookmarkedCustomer
    activeModules {
      title
      renewalDate
      status
    }
  }
`
