import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { User, OktaGroup } from '@models/index'
import { AlertSeverity, useToast } from '@hooks/useToast'
import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'
import { UPDATE_USER, UpdateUserVariables } from '@mutations/user'

import AddEditUserForm, {
  AddEditUserFormValues,
} from '../AddEditUserForm/AddEditUserForm'
import { ModalTitles } from '../UserManagementSettings'
import {
  filterAdminGroup,
  findGroupsToAdd,
  findGroupsToRemove,
  mapToListItems,
} from '../UserManagementSettings.util'

interface AddEditUserModalProps {
  user?: User
  isLastAdmin: boolean
  customerGroups: OktaGroup[]
  title: ModalTitles
  // clearUser: () => void
  userGroups?: Array<OktaGroup>
  closeModal: VoidFunction
}

const EditUserModal = ({
  user,
  isLastAdmin,
  customerGroups,
  title,
  closeModal,
  userGroups,
}: AddEditUserModalProps) => {
  const { state } = useContext(Context)
  const { featureNgMdr } = useFlags()
  const { handleShowToast } = useToast()

  const isEditingSelf = state.user.id === user?.id
  const isFederatedCustomer = state.customer.settings?.isOktaFederated

  const adminGroupID = customerGroups.find((group) =>
    group.groupName.includes('admins'),
  )?.groupId as string

  const initialGroups = userGroups ? mapToListItems(userGroups) : []

  const refetchQueries = [GET_USER_MANAGEMENT_SETTINGS]

  const navigate = useNavigate()

  const [updateUser] = useMutation<UpdateUserVariables>(UPDATE_USER, {
    refetchQueries,
  })

  const handleEditUser = async (values: AddEditUserFormValues) => {
    closeModal()
    handleShowToast(AlertSeverity.Info, 'Processing request to edit user')

    const filteredGroups = filterAdminGroup(initialGroups)

    const removeFromGroupIds = findGroupsToRemove(
      filteredGroups,
      values.groupIDs,
    )
    const addToGroupIds = findGroupsToAdd(filteredGroups, values.groupIDs)

    // check if user changed admin status
    // if not, don't update remove/add from group ids
    if (user?.isAdmin !== values.isAdmin) {
      values.isAdmin
        ? addToGroupIds.push(adminGroupID)
        : removeFromGroupIds.push(adminGroupID)
    }

    await updateUser({
      variables: {
        input: {
          userIdToUpdate: user?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          removeFromGroupIds,
          addToGroupIds,
        },
      },
      onCompleted: () => {
        handleShowToast(AlertSeverity.Success, 'User updated', 6000)

        // check if user demoted self, if so, navigate out of user managment
        // user would no longer have access to page
        if (isEditingSelf && user.isAdmin && !values.isAdmin) {
          navigate(featureNgMdr ? '/mdr' : '/dashboard')
        }
      },
      onError: (e) => {
        handleShowToast(AlertSeverity.Error, e.toString())
      },
    })
  }
  return (
    <Box
      sx={{
        padding: '24px 22px',
        maxWidth: '500px',
      }}
      id="add-edit-user-modal"
      data-testid="add-edit-user-modal"
    >
      <AddEditUserForm
        customerGroups={customerGroups}
        initialGroups={initialGroups}
        isEditingSelf={isEditingSelf}
        isFederatedCustomer={isFederatedCustomer ? isFederatedCustomer : false}
        isLastAdmin={isLastAdmin}
        modalState={title}
        onCancelButtonClick={() => closeModal()}
        onSubmit={handleEditUser}
        user={user}
      />
    </Box>
  )
}

export default EditUserModal
