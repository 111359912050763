const SignificantCyberEventRead = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45992 3.46937C5.07658 3.46937 4.70894 3.62165 4.43788 3.89271C4.16681 4.16377 4.01453 4.53141 4.01453 4.91475V18.6079C4.01453 18.9912 4.16681 19.3589 4.43788 19.6299C4.70894 19.901 5.07658 20.0533 5.45992 20.0533H9.7103C9.99324 20.4994 10.3299 20.9082 10.7111 21.2704H5.45992C4.75376 21.2704 4.07653 20.9899 3.57721 20.4906C3.07788 19.9913 2.79736 19.314 2.79736 18.6079V4.91475C2.79736 4.2086 3.07788 3.53137 3.57721 3.03204C4.07653 2.53272 4.75376 2.2522 5.45992 2.2522H9.68553H9.68563C10.21 2.25228 10.7129 2.46059 11.0838 2.83135L11.0838 2.8314L17.1285 8.87607L17.1285 8.87611C17.4993 9.24696 17.7076 9.74985 17.7077 10.2742V10.2743V10.7743C17.3206 10.603 16.9134 10.4689 16.4905 10.3766V10.3159H16.1744C15.8312 10.259 15.4788 10.2294 15.1195 10.2294C14.7602 10.2294 14.4078 10.259 14.0646 10.3159H11.6218C11.0973 10.3159 10.5942 10.1075 10.2232 9.73662C9.85232 9.36569 9.64393 8.86261 9.64393 8.33804V3.46937H5.45992ZM10.2109 12.5221H6.82923C6.49312 12.5221 6.22065 12.7945 6.22065 13.1306C6.22065 13.4667 6.49312 13.7392 6.82923 13.7392H9.40736C9.62893 13.3023 9.89916 12.8942 10.2109 12.5221ZM8.7551 15.9453H6.82923C6.49312 15.9453 6.22065 16.2178 6.22065 16.5539C6.22065 16.89 6.49312 17.1625 6.82923 17.1625H8.74074C8.72628 16.9869 8.7189 16.8093 8.7189 16.63C8.7189 16.3987 8.73117 16.1703 8.7551 15.9453ZM10.8611 4.33003L15.6298 9.09877H11.6218C11.4201 9.09877 11.2266 9.01862 11.0839 8.87595C10.9412 8.73329 10.8611 8.53979 10.8611 8.33804V4.33003Z"
      fill="currentcolor"
    />
    <path
      d="M18.4052 15.2405C18.1107 14.4586 17.6813 13.7344 17.1364 13.101C16.1936 12.0096 14.9539 11.3047 13.978 11.3047C13.92 11.3047 13.8627 11.318 13.8106 11.3436C13.7585 11.3692 13.713 11.4064 13.6775 11.4522C13.642 11.4981 13.6174 11.5515 13.6057 11.6084C13.594 11.6652 13.5955 11.724 13.61 11.7801C13.9397 13.0401 13.2562 14.0878 12.5328 15.1968C11.9335 16.1154 11.3154 17.0641 11.3154 18.1513C11.3154 20.2485 13.0218 21.9549 15.1191 21.9549C17.2163 21.9549 18.9227 20.2485 18.9227 18.1513C18.9227 17.1219 18.7487 16.1427 18.4052 15.2405ZM15.8877 20.4974C15.6421 20.7589 15.3342 20.8138 15.1191 20.8138C14.9039 20.8138 14.5961 20.7589 14.3505 20.4974C14.1049 20.2359 13.978 19.8211 13.978 19.2924C13.978 18.6947 14.1877 18.2397 14.3902 17.7999C14.5079 17.5446 14.6279 17.2847 14.6993 17.0066C14.7068 16.9763 14.7217 16.9484 14.7426 16.9253C14.7635 16.9021 14.7898 16.8845 14.8192 16.8739C14.8485 16.8633 14.88 16.8601 14.9108 16.8645C14.9417 16.869 14.971 16.8809 14.9962 16.8994C15.2136 17.0676 15.4069 17.2649 15.5708 17.4856C16.0027 18.0562 16.2602 18.7318 16.2602 19.2924C16.2602 19.8211 16.1313 20.2378 15.8877 20.4974Z"
      fill="currentcolor"
    />
  </svg>
)

export default SignificantCyberEventRead
