import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, useNavigate } from 'react-router-dom'
import { format, formatDistanceToNowStrict } from 'date-fns'
import { Box, Button, Link, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { PluggableList } from 'react-markdown/lib'

import { Ticket, TicketingRelatedCase } from '@models/index'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'
import { DEEP_LINK_URL_BASE } from '@constants/constants'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import Icon from '@common/Icon'
import { sideSheetFooterCss } from '@common/SideSheet/SideSheet'

import {
  formatCreatedDate,
  isValidSIEMLink,
  isValidSIEMSearch,
} from '../TicketLibrary.utils'
import {
  formatAssignedUserAvatar,
  formatEmptyField,
  formatLastUpdatedByField,
  formatTicketDetailsPriority,
} from './TicketDetails.utils'
import { formatBulletedList } from './BulletedList'

interface TicketDetailsProps {
  focusedTicket: Ticket | TicketingRelatedCase
}

const TicketDetails = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const [clipboardMessage, setClipboardMessage] = useState(false)
  const { featureEditTicketPage, featureNgMdr } = useFlags()
  const navigate = useNavigate()
  const { search } = useLocation()

  const handleTicketNavigation = () => {
    featureEditTicketPage
      ? navigate(`/tickets/library/${focusedTicket.sysId}/edit${search}`)
      : window.open(`${DEEP_LINK_URL_BASE}${focusedTicket.sysId}`, '_blank')
  }

  const handleSplunkSearchCopyToClipboard = () => {
    navigator.clipboard.writeText(focusedTicket.splunkSearch)
    displayClipboardMessage()
  }

  const displayClipboardMessage = () => {
    if (!clipboardMessage) {
      setClipboardMessage(true)
      setTimeout(() => {
        setClipboardMessage(false)
      }, 3000)
    }
  }

  const renderLastUpdatedOn = () => {
    const date = focusedTicket.sysUpdatedOn

    if (date) {
      const transformedDate = new Date(date)

      const formattedDate = format(transformedDate, 'MMM d, yyyy')
      const distanceToNow = formatDistanceToNowStrict(transformedDate, {
        addSuffix: true,
      })

      return (
        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
          <Typography
            fontWeight={500}
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {formattedDate}
          </Typography>
          <Typography variant="body2">{`(${distanceToNow})`}</Typography>
        </Box>
      )
    }

    return '--'
  }

  const detailColumnItems: SidesheetDualColumnValues[] = [
    {
      key: 'Status',
      label: 'Status',
      value: focusedTicket.state,
    },
    {
      key: 'Priority',
      label: 'Priority',
      value: formatTicketDetailsPriority(focusedTicket.priority),
    },
    {
      key: 'Last updated by',
      label: 'Last updated by',
      value: formatLastUpdatedByField(focusedTicket.lastUpdateUser),
    },
    {
      key: 'Last updated on',
      label: 'Last updated on',
      value: renderLastUpdatedOn(),
    },
    {
      key: 'Assigned to',
      label: 'Assigned to',
      value: focusedTicket.assignedUser
        ? formatAssignedUserAvatar(
            focusedTicket.assignedUser || '',
            focusedTicket.assignedUserEmail || '',
          )
        : formatEmptyField(focusedTicket.assignedUser || ''),
    },
    {
      key: 'Date created',
      label: 'Date created',
      value: formatEmptyField(formatCreatedDate(focusedTicket.sysCreatedOn)),
    },
    {
      key: 'Customer validation',
      label: 'Customer validation',
      value: formatEmptyField(focusedTicket.customerValidateState),
    },
    {
      key: featureNgMdr ? 'Module' : 'Service offering',
      label: featureNgMdr ? 'Module' : 'Service offering',
      value: formatEmptyField(
        featureNgMdr ? focusedTicket.module : focusedTicket.serviceOffering,
      ),
    },
    {
      key: 'Category',
      label: 'Category',
      value: formatEmptyField(focusedTicket.category),
    },
    {
      key: 'Subcategory',
      label: 'Subcategory',
      value: formatEmptyField(focusedTicket.subcategory),
    },
    {
      key: 'Agent type',
      label: 'Agent type',
      value: formatEmptyField(focusedTicket.agentType),
    },
  ]

  const additionalInfoColumnItems: SidesheetDualColumnValues[] = [
    {
      key: 'Search name',
      label: 'Search name',
      value: formatEmptyField(focusedTicket.useCaseTitle),
    },
    {
      key: 'Search ID',
      label: 'Search ID',
      value: formatEmptyField(focusedTicket.useCase),
    },
    {
      key: 'Incident type',
      label: 'Incident type',
      value: formatEmptyField(focusedTicket.incidentType),
    },
    {
      key: 'CIS Control',
      label: 'CIS Control',
      value: formatEmptyField(focusedTicket.cisControl),
    },
    {
      key: 'MITRE Tactic',
      label: 'MITRE Tactic',
      value: formatBulletedList(focusedTicket.mitreTactics),
    },
    {
      key: 'MITRE Technique',
      label: 'MITRE Technique',
      value: formatBulletedList(focusedTicket.mitreTechniques),
    },
    {
      key: 'SIEM link',
      label: 'SIEM link',
      value: isValidSIEMLink(focusedTicket.splunkLink) ? (
        <Link
          href={focusedTicket.splunkLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open in new tab
        </Link>
      ) : (
        '--'
      ),
    },
    {
      key: 'SIEM search',
      label: 'SIEM search',
      value: isValidSIEMSearch(focusedTicket.splunkSearch) ? (
        <Link component="button" onClick={handleSplunkSearchCopyToClipboard}>
          Copy to clipboard
        </Link>
      ) : (
        '--'
      ),
    },
    {
      key: '',
      label: '',
      value: clipboardMessage ? (
        <Typography
          sx={(theme) => ({
            background: theme.vars.palette.common.white,
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            borderRadius: '5px',
            boxShadow: '0 1px 4px rgb(25 41 46 / 40%)',
            padding: '0.5rem',
            ...theme.applyStyles('dark', {
              background: theme.vars.palette.secondary.dark,
              border: `1px solid ${theme.vars.palette.secondary.light}`,
            }),
          })}
          variant="body2"
        >
          Copied to clipboard!
        </Typography>
      ) : (
        ''
      ),
    },
  ]

  return (
    <>
      <Box id="tickets-details" data-testid="ticket-library-tickets-details">
        <Box sx={{ padding: '1rem' }}>
          <Typography
            fontWeight={600}
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              paddingBottom: '0.5rem',
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {focusedTicket.ticketNumber}
          </Typography>
          <Typography variant="body2">
            {focusedTicket.shortDescription}
          </Typography>
        </Box>

        <CollapsiblePanel title={'Details'}>
          <SidesheetDualColumn items={detailColumnItems} />
        </CollapsiblePanel>

        <CollapsiblePanel title={'Description'} defaultClosed>
          <Box data-testid="ticket-library-tickets-details-description">
            <ReactMarkdown
              className="ticket-details-markdown"
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw] as PluggableList}
              components={{
                a(props) {
                  const { href, children } = props

                  return (
                    <Link href={href} rel="noopener noreferrer" target="_blank">
                      {children}
                    </Link>
                  )
                },
                li(props) {
                  return (
                    <Box
                      sx={{
                        wordBreak: 'break-word',
                        padding: '0',
                        marginLeft: -2,
                      }}
                    >
                      <Typography
                        component={'li'}
                        sx={(theme) => ({
                          color: theme.vars.palette.text.primary,
                          ...theme.applyStyles('dark', {
                            color: theme.vars.palette.text.secondary,
                          }),
                        })}
                        variant="body2"
                      >
                        {props.children}
                      </Typography>
                    </Box>
                  )
                },
                p(props) {
                  return (
                    <Typography
                      sx={(theme) => ({
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                        color: theme.vars.palette.text.primary,
                        padding: '0.5rem 0',
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      })}
                      variant="body2"
                    >
                      {props.children}
                    </Typography>
                  )
                },
              }}
            >
              {focusedTicket.description}
            </ReactMarkdown>
          </Box>
        </CollapsiblePanel>

        <CollapsiblePanel title={'Additional Information'} defaultClosed>
          <SidesheetDualColumn items={additionalInfoColumnItems} />
        </CollapsiblePanel>
      </Box>

      <Box sx={sideSheetFooterCss} component="footer">
        <Button
          data-testid="openInSnowButton"
          onClick={handleTicketNavigation}
          sx={{ gap: 0.5 }}
          variant="contained"
        >
          {featureEditTicketPage ? 'View ticket' : 'Open in SNOW'}
          <Icon
            variant={
              featureEditTicketPage
                ? 'arrowForwardCircleOutline'
                : 'openOutline'
            }
          />
        </Button>
      </Box>
    </>
  )
}

export default TicketDetails
