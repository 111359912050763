/* eslint-disable @getify/proper-ternary/nested */
import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { DashboardBanKeys } from '@queries/threatIntel'
import { MttxMetric } from '@models/MttxDetails'
import { toTimeUnits } from '@utils/DateTimeUtils'
import { MTTN_BAN } from '@queries/mttx'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import { Delta } from '@common/Delta'
import { trackAnalyticEvent } from '@utils/analytics'

import MTTCAckContent from '../MTTCAckSidesheet/MTTCAckContent'

interface MTTCAckBanProps {
  endDate: string
  openSideSheet: (content: ReactNode, key: string) => void
  startDate: string
}

const MTTCAckBan: React.FC<MTTCAckBanProps> = ({
  endDate,
  openSideSheet,
  startDate,
}) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    data: { mttnBan } = { mttnBan: { averageTime: null } },
    error,
    loading,
  } = useQuery(MTTN_BAN, {
    variables: {
      selectedCustomer,
      input: {
        startDate,
        endDate,
        metric: MttxMetric.MTTCAck,
      },
    },
  })

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      openSideSheet(<MTTCAckContent />, DashboardBanKeys.MTTCAck)
    }
  }

  return (
    <Ban
      data-testid="mttcack-ban"
      iconVariant="flashOutline"
      onClick={() => {
        openSideSheet(<MTTCAckContent />, DashboardBanKeys.MTTCAck)
        trackAnalyticEvent('ban_clicked', { ban: 'MTTCAck' })
      }}
      onKeyDown={handleKeyDown}
    >
      <BanLayoutCommon
        caption="The mean time for a customer to acknowledge a security ticket."
        error={error != null}
        loading={loading}
        title="MTTCACK"
        value={
          mttnBan.averageTime != null
            ? toTimeUnits(mttnBan.averageTime)
            : 'Not applicable'
        }
      >
        {mttnBan.averageTime != null && (
          <Delta
            delta={Math.round(mttnBan.delta.percentage * 100)}
            suffix="%"
          />
        )}
      </BanLayoutCommon>
    </Ban>
  )
}

export default MTTCAckBan
