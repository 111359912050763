import { Avatar, Box, Typography } from '@mui/material'

import { useAvatarInitials } from '@hooks/useAvatarInitials'

type ModulePrimaryEngineerFieldProps = {
  primaryEngineerName: string | null
}

const ModulePrimaryEngineerField: React.FC<ModulePrimaryEngineerFieldProps> = ({
  primaryEngineerName,
}) => {
  const parsePrimaryEngineerFullName = (fullName: string) => {
    const spaceSeparatedName = fullName.trim().split(' ')
    const [firstName, ...rest] = spaceSeparatedName
    const lastName = rest.length > 0 ? rest.pop() : ''

    return { firstName, lastName }
  }
  const avatarInitials = useAvatarInitials({
    firstName:
      parsePrimaryEngineerFullName(primaryEngineerName ?? '').firstName ?? '',
    lastName:
      parsePrimaryEngineerFullName(primaryEngineerName ?? '')?.lastName ?? '',
  })

  if (!primaryEngineerName) {
    return '--'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
      }}
    >
      {primaryEngineerName && (
        <Avatar sx={{ fontSize: 9, width: 20, height: 20 }} variant="dw">
          {avatarInitials}
        </Avatar>
      )}
      <Typography variant="body2">{primaryEngineerName}</Typography>
    </Box>
  )
}

export default ModulePrimaryEngineerField
