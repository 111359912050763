/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery, useMutation } from '@apollo/client'
import { useContext } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
  useColorScheme,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Context } from '@components/App'
import { GET_USER, GetUserData, GetUserVariables } from '@queries/user'
import { UPDATE_USER, UpdateUserVariables } from '@mutations/user'
import { ComponentError } from '@common/ComponentError'
import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { useAvatarInitials } from '@hooks/useAvatarInitials'
import { useToast } from '@hooks/index'
import { AlertSeverity } from '@hooks/useToast'
import { AwaitCustomer } from '@hooks/useCustomer'
import { betaCustomerShortNames } from '@components/BetaAgreementModal/betaAgreementModalConstants'

import { BetaFeaturesSection } from './BetaFeaturesSection'
import { ProfileHorizontalSectionDivider } from './ProfileHorizontalSectionDivider'

interface ProfileFormValues {
  firstName: string
  lastName: string
  email: string
  title: string
}

const ProfileOverview = () => {
  const { state } = useContext(Context)

  const { mode, setMode } = useColorScheme()
  const { featureLightThemeToggle, featureBetaAgreement } = useFlags()

  const isFederatedCustomer = state.customer.settings?.isOktaFederated
  const { isDWEmployee } = state.user

  const {
    loading,
    data: { getUser: user } = { getUser: undefined },
    error: getUserError,
  } = useQuery<GetUserData, GetUserVariables>(GET_USER, {
    variables: {
      input: {
        userId: state.user.id,
      },
    },
  })

  const isReadOnly = isFederatedCustomer || isDWEmployee || loading

  const shouldShowBetaFeatures =
    featureBetaAgreement &&
    !isDWEmployee &&
    betaCustomerShortNames.includes(state.customer.customerShortName)

  const [updateUser] = useMutation<UpdateUserVariables>(UPDATE_USER, {
    refetchQueries: [GET_USER],
  })

  const avatarInitials = useAvatarInitials({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
  })

  const { handleShowToast } = useToast()

  const EditUserProfileSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(256, 'First name must be between 1 and 256 characters.')
      .required('First name is required.'),
    lastName: Yup.string()
      .max(256, 'Last name must be between 1 and 256 characters.')
      .required('Last name is required.'),
    title: Yup.string().max(256, 'Title must be between 1 and 256 characters.'),
  })

  const onSubmit = async (values: ProfileFormValues) => {
    await updateUser({
      variables: {
        input: {
          userIdToUpdate: state.user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
        },
      },
      onCompleted: () => {
        handleShowToast(AlertSeverity.Success, 'User updated')
      },
      onError: (e) => {
        // eslint-disable-next-line no-console
        console.error(e)
        handleShowToast(AlertSeverity.Error, e.toString())
      },
    })
  }

  if (!mode) {
    setMode('dark')
  }

  if (getUserError) {
    return (
      <ComponentError
        includeReloadButton
        errorIcon={fetchErrorIcon}
        errorSubText="Check your connection and refresh the page. If the problem persists, contact our support team for assistance."
      />
    )
  }

  return (
    <Box
      id="profile-overview-page"
      data-testid="profile-overview"
      sx={(theme) => ({
        alignItems: 'center',
        backgroundColor: theme.vars.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '1rem 0',
        width: '100%',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
        }),
      })}
    >
      <Box sx={{ maxWidth: '500px', width: '100%' }}>
        <Formik
          initialValues={{
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email ?? '',
            title: user?.title ?? '',
          }}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={EditUserProfileSchema}
        >
          {({
            handleBlur,
            handleChange,
            submitForm,
            errors,
            touched,
            values,
            dirty,
            isValid,
          }) => {
            return (
              <>
                <Box
                  id="profile-header-info"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'center',
                  }}
                >
                  {loading ? (
                    <Skeleton
                      sx={{
                        minHeight: '80px',
                        width: '80px',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{ fontSize: 28, width: 80, height: 80 }}
                      variant="dw"
                    >
                      {avatarInitials}
                    </Avatar>
                  )}

                  <Box
                    data-testid="user-name-title"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {loading ? (
                      <Skeleton
                        sx={{
                          minHeight: '34px',
                          width: '50%',
                          marginRight: '4px',
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h4"
                        fontWeight={600}
                        sx={(theme) => ({
                          color: theme.vars.palette.text.primary,
                          ...theme.applyStyles('dark', {
                            color: theme.vars.palette.text.secondary,
                          }),
                        })}
                      >
                        {user?.firstName} {user?.lastName}
                      </Typography>
                    )}

                    {loading ? (
                      <Skeleton
                        sx={{ minHeight: '16px', width: '30%' }}
                        data-testid="skeleton-block"
                      />
                    ) : (
                      <Typography variant="body1" color="textPrimary">
                        {user?.title}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <TextField
                  sx={{ marginBottom: '1.5rem' }} // Needed to make space for the absolutely-positioned error message
                  required
                  error={errors.firstName !== undefined && touched.firstName}
                  helperText={errors.firstName}
                  label={'First'}
                  placeholder={'Enter First Name'}
                  value={values.firstName}
                  name="firstName"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                />
                <TextField
                  sx={{ marginBottom: '1.5rem' }}
                  required
                  error={errors.lastName !== undefined && touched.lastName}
                  helperText={errors.lastName}
                  label={'Last'}
                  placeholder={'Enter Last Name'}
                  value={values.lastName}
                  name="lastName"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                />
                <TextField
                  sx={{ marginBottom: '1.5rem' }}
                  label={'Title'}
                  placeholder={'Enter Job Title'}
                  error={errors.title !== undefined && touched.title}
                  helperText={errors.title && touched.title}
                  value={values.title}
                  name="title"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                />
                <TextField
                  sx={{ marginBottom: '1.5rem' }}
                  label={'Email'}
                  value={values.email}
                  name="email"
                  type="text"
                  disabled={true}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '0.5rem',
                  }}
                >
                  <Button
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={!isValid || !dirty || isReadOnly}
                    variant="contained"
                  >
                    Update
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>

        <ProfileHorizontalSectionDivider />
      </Box>

      {featureLightThemeToggle && (
        <Box
          data-testid="theme-toggle"
          sx={{ width: '100%', maxWidth: '500px' }}
        >
          <Typography
            fontWeight={600}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            Theme
          </Typography>
          <RadioGroup
            onChange={(event) =>
              setMode(event.target.value as 'system' | 'light' | 'dark')
            }
            value={mode}
          >
            <FormControlLabel
              value="system"
              control={<Radio />}
              label="System"
            />
            <FormControlLabel value="light" control={<Radio />} label="Light" />
            <FormControlLabel value="dark" control={<Radio />} label="Dark" />
          </RadioGroup>
        </Box>
      )}

      {shouldShowBetaFeatures && (
        <AwaitCustomer>
          <Box sx={{ width: '100%', maxWidth: '500px' }}>
            <ProfileHorizontalSectionDivider />
            <BetaFeaturesSection />
          </Box>
        </AwaitCustomer>
      )}
    </Box>
  )
}

export default ProfileOverview
