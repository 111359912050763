import { CellContext } from '@tanstack/react-table'
import { Box, IconButton, IconButtonProps, Typography } from '@mui/material'

import { CustomerHighlight } from '@models/DeepwatchExpert'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'
import { zIndex } from '@components/App/Styles/zIndex'

interface CustomerTicketsInProgressCellProps {
  cellContext: CellContext<CustomerHighlight, any>
  handleAddCustomerButtonClick: (customer: CustomerHighlight) => void
  handleRemoveCustomerButtonClick: (customer: CustomerHighlight) => void
}

interface RowHoverIconProps extends IconButtonProps {
  'data-testid': string
  variant: IconVariant
}

const CustomerTicketsInProgressCell: React.FC<
  CustomerTicketsInProgressCellProps
> = ({
  cellContext,
  handleAddCustomerButtonClick,
  handleRemoveCustomerButtonClick,
}) => {
  const { openWaitingDeepwatchCount, isBookmarkedCustomer } =
    cellContext.getValue()
  const rowHoverElements: RowHoverIconProps[] = []

  if (!isBookmarkedCustomer) {
    rowHoverElements.push({
      'data-testid': 'add-customer-btn',
      variant: 'addCircleOutline',
      onClick: (e: { stopPropagation: () => void }) => {
        e.stopPropagation()
        handleAddCustomerButtonClick(cellContext.row.original)
      },
    })
  } else {
    rowHoverElements.push({
      'data-testid': 'remove-customer-btn',
      variant: 'removeCircleSharp',
      onClick: (e: { stopPropagation: () => void }) => {
        e.stopPropagation()
        handleRemoveCustomerButtonClick(cellContext.row.original)
      },
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Typography
        noWrap
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {openWaitingDeepwatchCount}
      </Typography>

      <Box
        id="cell-hover-buttons"
        sx={{
          display: 'flex',
          flex: '1',
          position: 'absolute',
          inset: '0',
          alignItems: 'center',
          opacity: '0',
          zIndex: zIndex.CELL_HOVER_BUTTONS,
        }}
      >
        <Box
          sx={(theme) => ({
            background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.lighter} 90%)`,
            width: '100%',
            height: '100%',
            ...theme.applyStyles('dark', {
              background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.light} 90%)`,
            }),
          })}
        />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.lighter,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.light,
            }),
            display: 'flex',
          })}
        >
          {rowHoverElements.map((buttonProps, index) => (
            <IconButton
              key={index}
              data-testid={buttonProps['data-testid']}
              onClick={buttonProps.onClick}
            >
              <Icon variant={buttonProps.variant} />
            </IconButton>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerTicketsInProgressCell
