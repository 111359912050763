import { Box, Typography } from '@mui/material'

const Leadership = () => {
  const dwLeadership = [
    {
      name: 'John DiLullo',
      Title: 'CEO',
    },
    {
      name: 'Anand Ramanathan',
      Title: 'CPO',
    },
    {
      name: 'Bobby Christian',
      Title: 'CCO',
    },
    {
      name: 'Chad Cragle',
      Title: 'CISO',
    },
    {
      name: 'Mel Wesley',
      Title: 'CFO',
    },
    {
      name: 'Sammie Walker',
      Title: 'CMO',
    },
    {
      name: 'Elizabeth Powell',
      Title: 'VP Renewals',
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
      }}
    >
      <Box data-testid="leadership">
        <Typography fontWeight={600} color="textPrimary" variant="body1">
          Deepwatch leadership
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1.5rem 2rem',
        }}
      >
        {dwLeadership.map((companyData, index) => {
          return (
            <Box
              key={index}
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}
            >
              <Typography
                fontWeight={600}
                color="textPrimary"
                variant="body2"
                data-testid="leadership-title"
              >
                {companyData.Title}
              </Typography>
              <Typography
                data-testid="leadership-name"
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="body2"
              >
                {companyData.name}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Leadership
