/* eslint-disable jsdoc/check-tag-names */
import { gql } from '@apollo/client'

import {
  MTTXDetails,
  MttxDimension,
  MttxFilterOptions,
  MttxMetric,
  MttxSegment,
  TicketPriorityEnum,
  TicketTypeEnum,
} from '@models/index'

/**
 * @category Queries
 * @description Get MTTX queries
 */

export interface GetMttxDetailsData {
  mttxDetails: MTTXDetails
}

export interface MttnBanData {
  mttnBan: Pick<MTTXDetails, 'averageTime' | 'delta'>
}

export interface MttxDataVisualization {
  data: MttxData[]
  dimension: MttxDimension
  segment: MttxSegment
}

export interface MttxTableData {
  mttxTable: MttxDataVisualization
}

export interface MttxTableVariables {
  selectedCustomer: string | null
  input: MttxTableInput
}

export interface MttxDetailsInput {
  metric: MttxMetric
  endDate: string
  startDate: string
  ticketPriority: TicketPriorityEnum[]
  ticketType: TicketTypeEnum[]
}

export interface MttxFilterOptionsInput {
  metric: MttxMetric
  endDate: string
  startDate: string
}

export interface MttxFilterOptionsData {
  mttxFilterOptions: MttxFilterOptions
}

export interface MttxFilterOptionsVariables {
  selectedCustomer: string | null
  input: MttxFilterOptionsInput
}

export interface MttxTableInput {
  dimension: string
  metric: MttxMetric
  segment?: string
  startDate?: string
  endDate?: string
  ticketPriority?: string[]
  ticketType?: string[]
}

export interface MttxData {
  averageDuration: number
  date: string
  duration: number
  priority: TicketPriorityEnum
  ticketId: string
  totalTickets: number
  ticketType: string
  sysId: string
}

export interface GetMttxDetailsVariables {
  selectedCustomer: string | null
  input: MttxDetailsInput
}

export interface GetMttxChartData {
  mttxChart: MttxDataVisualization
  securityTickets: MttxDataVisualization
  engineeringTickets: MttxDataVisualization
}

export interface MttxChartInput {
  dimension: string
  metric: string
  segment?: string
  startDate?: string
  endDate?: string
  ticketPriority?: TicketPriorityEnum[]
  ticketType?: TicketTypeEnum[]
}

export interface MttxChartVariables {
  selectedCustomer: string | null
  input?: MttxChartInput
  securityInput?: MttxChartInput
  engineeringInput?: MttxChartInput
}

export const MTTX_CHART = gql`
  query MttxChart($selectedCustomer: String, $input: MttxChartInput!) {
    mttxChart(selectedCustomer: $selectedCustomer, input: $input) {
      data {
        averageDuration
        date
        duration
        priority
        ticketId
        totalTickets
        ticketType
        sysId
      }
      dimension
      segment
    }
  }
`

export const MTTX_DETAILS = gql`
  query MttxDetails($selectedCustomer: String, $input: MttxDetailsInput!) {
    mttxDetails(selectedCustomer: $selectedCustomer, input: $input) {
      averageTime
      delta {
        changeAmount
        percentage
      }
      endDate
      maxTime
      minTime
      previousPeriod {
        endDate
        startDate
      }
      startDate
      totalTickets
      metric
      ticketPriority
      ticketType
    }
  }
`

export const MTTX_FILTER_OPTIONS = gql`
  query MttxFilterOptions(
    $selectedCustomer: String
    $input: MttxFilterOptionsInput!
  ) {
    mttxFilterOptions(selectedCustomer: $selectedCustomer, input: $input) {
      ticketPriorities {
        key
        available
      }
      ticketTypes {
        key
        available
      }
      dimensions
      segments
    }
  }
`

export const MTTN_BAN = gql`
  query MTTNBan($selectedCustomer: String, $input: MttxDetailsInput!) {
    mttnBan: mttxDetails(selectedCustomer: $selectedCustomer, input: $input) {
      averageTime
      delta {
        percentage
      }
    }
  }
`

export const MTTX_TABLE = gql`
  query MTTXTable($selectedCustomer: String, $input: MttxTableInput!) {
    mttxTable(selectedCustomer: $selectedCustomer, input: $input) {
      dimension
      metric
      segment
      data {
        averageDuration
        date
        duration
        priority
        ticketId
        totalTickets
        ticketType
        sysId
      }
    }
  }
`

export const GET_TICKET_DATA = gql`
  query GetTicketData(
    $selectedCustomer: String
    $securityInput: MttxChartInput!
    $engineeringInput: MttxChartInput!
  ) {
    securityTickets: mttxChart(
      selectedCustomer: $selectedCustomer
      input: $securityInput
    ) {
      data {
        averageDuration
        date
        duration
        priority
        ticketId
        totalTickets
        ticketType
      }
      dimension
      segment
    }
    engineeringTickets: mttxChart(
      selectedCustomer: $selectedCustomer
      input: $engineeringInput
    ) {
      data {
        averageDuration
        date
        duration
        priority
        ticketId
        totalTickets
        ticketType
      }
      dimension
      segment
    }
  }
`
