/* eslint-disable @getify/proper-ternary/nested */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  Row,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table'
import { useMutation } from '@apollo/client'
import { debounce } from 'throttle-debounce'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'

import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import TableHeader from '@common/Table/utils/TableHeader'
import TableCell from '@common/Table/utils/TableCell'
import {
  Context,
  setCustomerLoading,
  setCustomerNames,
  setDwExpertsActiveTab,
} from '@components/App'
import { Paths } from '@components/App/Types'
import { NoResults } from '@common/NoResults'
import { CustomerHighlight } from '@models/index'
import {
  ADD_BOOKMARKED_CUSTOMER,
  REMOVE_BOOKMARKED_CUSTOMER,
} from '@mutations/customer'
import {
  DW_EXPERT_CUSTOMER_FRAGMENT,
  DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES,
} from '@fragments/dwExperts'
import { TableToolbar } from '@common/TableToolbar'
import Icon from '@common/Icon'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableRow from '@common/Table/components/CommonTableRow'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import { AlertSeverity, useToast } from '@hooks/useToast'

import CustomerTicketsInProgressCell from './cells/CustomerTicketsInProgressCell'
import CustomerNameCell from './cells/CustomerNameCell'
import CustomerLicenseUtilCell from './cells/CustomerLicenseUtilCell'
import CustomerLicenseLimitCell from './cells/CustomerLicenseLimitCell'
import CustomerModuleRenewalDatesCell from './cells/CustomerModuleRenewalDatesCell'

interface DeepwatchExpertCustomerTableProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
  disableAddedTags: boolean
  disableRowClick?: boolean
}

const DeepwatchExpertCustomerTable: React.FC<
  DeepwatchExpertCustomerTableProps
> = ({
  customerHighlights,
  loading = true,
  disableRowClick = false,
  disableAddedTags,
}) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])

  const [searchResults, setSearchResults] =
    useState<CustomerHighlight[]>(customerHighlights)
  const ref = useRef<HTMLDivElement>(null)

  const [addBookmarkedCustomer] = useMutation(ADD_BOOKMARKED_CUSTOMER, {
    update: (cache, { data: { addBookmarkedCustomer } }) => {
      addBookmarkedCustomer.forEach((cust) => {
        cache.writeFragment({
          id: cache.identify(cust),
          fragment: DW_EXPERT_CUSTOMER_FRAGMENT,
          data: cust,
        })
      })
    },
  })

  const [removeBookmarkedCustomer] = useMutation(REMOVE_BOOKMARKED_CUSTOMER, {
    update: (cache, { data: { removeBookmarkedCustomer } }) => {
      removeBookmarkedCustomer.forEach((cust) => {
        cache.writeFragment({
          id: cache.identify(cust),
          fragment: featureModulesRenewalDatesColumns
            ? DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES
            : DW_EXPERT_CUSTOMER_FRAGMENT,
          data: cust,
        })
      })
    },
  })

  const { dispatch } = useContext(Context)
  const navigate = useNavigate()
  const { handleShowToast } = useToast()
  const { featureNgMdr, featureModulesRenewalDatesColumns } = useFlags()

  const handleAddCustomerButtonClick = useCallback(
    async (customer: CustomerHighlight) => {
      handleShowToast(
        AlertSeverity.Info,
        `Making request to add ${customer.name} to "My Customers"`,
      )
      await addBookmarkedCustomer({
        variables: {
          customers: [customer.customerShortName],
        },
        optimisticResponse: {
          addBookmarkedCustomer: [
            {
              ...customerHighlights.find(
                (highlight) =>
                  highlight.customerShortName === customer.customerShortName,
              ),
              isBookmarkedCustomer: true,
              __typename: 'SquadHomeCustomerData',
            },
          ],
        },
        onCompleted: () => {
          handleShowToast(
            AlertSeverity.Success,
            `${customer.name} added to "My Customers"`,
          )
        },
        onError: () => {
          handleShowToast(
            AlertSeverity.Error,
            `Could not add ${customer.name} to "My Customers"`,
          )
        },
      })
    },
    [addBookmarkedCustomer, customerHighlights, handleShowToast],
  )

  const handleRemoveCustomerButtonClick = useCallback(
    async (customer: CustomerHighlight) => {
      handleShowToast(
        AlertSeverity.Info,
        `Making request to remove ${customer.name} from "My Customers"`,
      )

      await removeBookmarkedCustomer({
        variables: {
          customers: [customer.customerShortName],
        },
        optimisticResponse: {
          removeBookmarkedCustomer: [
            {
              ...customerHighlights.find(
                (highlight) =>
                  highlight.customerShortName === customer.customerShortName,
              ),
              isBookmarkedCustomer: false,
              __typename: 'SquadHomeCustomerData',
            },
          ],
        },
        onCompleted: () => {
          handleShowToast(
            AlertSeverity.Success,
            `${customer.name} removed from "My Customers"`,
          )
        },
        onError: () => {
          handleShowToast(
            AlertSeverity.Error,
            `Could not remove ${customer.name} from "My Customers"`,
          )
        },
      })
    },
    [removeBookmarkedCustomer, customerHighlights, handleShowToast],
  )

  useEffect(() => {
    setSearchResults(customerHighlights)
  }, [customerHighlights])

  const handleRowClick = (customer: CustomerHighlight) => {
    if (!customer) {
      handleShowToast(AlertSeverity.Error, 'Customer not found')
      return
    }

    dispatch(setDwExpertsActiveTab(1))
    dispatch(
      setCustomerNames({
        customerName: customer.name,
        customerShortName: customer.customerShortName,
      }),
    )
    dispatch(setCustomerLoading(false))

    const path = featureNgMdr ? Paths.MDR : Paths.DASHBOARD

    navigate(`${path}?customer=${customer.customerShortName}`)
  }

  const [searchInput, setSearchInput] = useState('')

  const debouncedHandleInputChange = useCallback(
    debounce(500, (value: string) => {
      setColumnFilters([
        {
          id: 'name',
          value,
        },
      ])
    }),
    [setColumnFilters],
  )

  useEffect(() => {
    debouncedHandleInputChange(searchInput)
  }, [searchInput, debouncedHandleInputChange])

  const columnDef: ColumnDef<CustomerHighlight, any>[] = useMemo(() => {
    if (featureModulesRenewalDatesColumns) {
      return [
        {
          id: 'name',
          cell: (props) => (
            <CustomerNameCell
              cellContext={props}
              disableAddedTags={disableAddedTags}
            />
          ),
          header: () => buildGenericHeader('CUSTOMER NAME'),
          accessorFn: (row) => ({
            name: row.name,
            isBookmarkedCustomer: row.isBookmarkedCustomer,
          }),
          filterFn: (row, _, value) => {
            const name = row.original.name.toLowerCase()
            const customerShortName =
              row.original.customerShortName.toLowerCase()

            return (
              name.includes(value.toLowerCase()) ||
              customerShortName.includes(value.toLowerCase())
            )
          },
          sortingFn: (
            rowA: Row<CustomerHighlight>,
            rowB: Row<CustomerHighlight>,
          ) => {
            const rowAName = rowA.original.name.toLowerCase()
            const rowBName = rowB.original.name.toLowerCase()

            if (rowAName < rowBName) {
              return -1
            }

            if (rowBName < rowAName) {
              return 1
            }

            return 0
          },
          size: 140,
        },
        {
          id: 'usageGb',
          cell: (props) => <CustomerLicenseUtilCell cellContext={props} />,
          header: () => buildGenericHeader('LICENSE UTIL (GB/day)'),
          accessorFn: (row) => ({
            usageGb: row.usageGb,
            usageLicenseGb: row.usageLicenseGb,
          }),
          sortingFn: (
            rowA: Row<CustomerHighlight>,
            rowB: Row<CustomerHighlight>,
          ) => {
            const rowAUsageGb = rowA.original.usageGb
            const rowBUsageGb = rowB.original.usageGb

            if (rowAUsageGb < rowBUsageGb) {
              return -1
            }

            if (rowBUsageGb < rowAUsageGb) {
              return 1
            }

            return 0
          },
          size: 140,
        },
        {
          id: 'activeModules',
          cell: (props) => {
            const { activeModules } = props.getValue()
            return <CustomerModuleRenewalDatesCell data={activeModules} />
          },
          header: () => buildGenericHeader('ACTIVE MODULES'),
          accessorFn: (row) => ({ activeModules: row?.activeModules }),
          enableSorting: false,
          size: 150,
        },
        {
          id: 'usageLicenseGb',
          cell: (props) => <CustomerLicenseLimitCell cellContext={props} />,
          header: () => buildGenericHeader('LICENSE LIMIT (GB/day)'),
          accessorFn: (row) => ({ usageLicenseGb: row.usageLicenseGb }),
          sortingFn: (
            rowA: Row<CustomerHighlight>,
            rowB: Row<CustomerHighlight>,
          ) => {
            const rowAUsageLicenseGb = rowA.original.usageLicenseGb
            const rowBUsageLicenseGb = rowB.original.usageLicenseGb

            if (rowAUsageLicenseGb < rowBUsageLicenseGb) {
              return -1
            }

            if (rowBUsageLicenseGb < rowAUsageLicenseGb) {
              return 1
            }

            return 0
          },
          size: 180,
        },
        {
          id: 'openCriticalCount',
          cell: (props) => buildGenericCell(props),
          header: () => buildGenericHeader('OPEN CRITICAL TICKETS'),
          accessorFn: (row) => `${row.openCriticalCount}`,
          sortingFn: (
            rowA: Row<CustomerHighlight>,
            rowB: Row<CustomerHighlight>,
          ) => {
            const rowAOpenCriticalCount = rowA.original.openCriticalCount
            const rowBOpenCriticalCount = rowB.original.openCriticalCount

            if (rowAOpenCriticalCount < rowBOpenCriticalCount) {
              return -1
            }

            if (rowBOpenCriticalCount < rowAOpenCriticalCount) {
              return 1
            }

            return 0
          },
          size: 150,
        },
        {
          id: 'openWaitingDeepwatchCount',
          accessorFn: (row: CustomerHighlight) => ({
            openWaitingDeepwatchCount: row.openWaitingDeepwatchCount,
            isBookmarkedCustomer: row.isBookmarkedCustomer,
          }),
          cell: (props) => (
            <CustomerTicketsInProgressCell
              cellContext={props}
              handleRemoveCustomerButtonClick={handleRemoveCustomerButtonClick}
              handleAddCustomerButtonClick={handleAddCustomerButtonClick}
            />
          ),
          header: () => buildGenericHeader('TICKETS IN PROGRESS WITH DW'),
          sortingFn: (
            rowA: Row<CustomerHighlight>,
            rowB: Row<CustomerHighlight>,
          ) => {
            const rowAOpenWaitingDeepwatchCount =
              rowA.original.openWaitingDeepwatchCount
            const rowBOpenWaitingDeepwatchCount =
              rowB.original.openWaitingDeepwatchCount

            if (rowAOpenWaitingDeepwatchCount < rowBOpenWaitingDeepwatchCount) {
              return -1
            }

            if (rowBOpenWaitingDeepwatchCount < rowAOpenWaitingDeepwatchCount) {
              return 1
            }

            return 0
          },
          size: 180,
        },
      ]
    }

    return [
      {
        id: 'name',
        cell: (props) => (
          <CustomerNameCell
            cellContext={props}
            disableAddedTags={disableAddedTags}
          />
        ),
        header: () => buildGenericHeader('CUSTOMER NAME'),
        accessorFn: (row) => ({
          name: row.name,
          isBookmarkedCustomer: row.isBookmarkedCustomer,
        }),
        filterFn: (row, _, value) => {
          const name = row.original.name.toLowerCase()
          const customerShortName = row.original.customerShortName.toLowerCase()

          return (
            name.includes(value.toLowerCase()) ||
            customerShortName.includes(value.toLowerCase())
          )
        },
        sortingFn: (
          rowA: Row<CustomerHighlight>,
          rowB: Row<CustomerHighlight>,
        ) => {
          const rowAName = rowA.original.name.toLowerCase()
          const rowBName = rowB.original.name.toLowerCase()

          if (rowAName < rowBName) {
            return -1
          }

          if (rowBName < rowAName) {
            return 1
          }

          return 0
        },
        minSize: 100,
      },
      {
        id: 'usageGb',
        cell: (props) => <CustomerLicenseUtilCell cellContext={props} />,
        header: () => buildGenericHeader('LICENSE UTIL (GB/day)'),
        accessorFn: (row) => ({
          usageGb: row.usageGb,
          usageLicenseGb: row.usageLicenseGb,
        }),
        sortingFn: (
          rowA: Row<CustomerHighlight>,
          rowB: Row<CustomerHighlight>,
        ) => {
          const rowAUsageGb = rowA.original.usageGb
          const rowBUsageGb = rowB.original.usageGb

          if (rowAUsageGb < rowBUsageGb) {
            return -1
          }

          if (rowBUsageGb < rowAUsageGb) {
            return 1
          }

          return 0
        },
        size: 180,
        minSize: 100,
      },
      {
        id: 'usageLicenseGb',
        cell: (props) => <CustomerLicenseLimitCell cellContext={props} />,
        header: () => buildGenericHeader('LICENSE LIMIT (GB/day)'),
        accessorFn: (row) => ({ usageLicenseGb: row.usageLicenseGb }),
        sortingFn: (
          rowA: Row<CustomerHighlight>,
          rowB: Row<CustomerHighlight>,
        ) => {
          const rowAUsageLicenseGb = rowA.original.usageLicenseGb
          const rowBUsageLicenseGb = rowB.original.usageLicenseGb

          if (rowAUsageLicenseGb < rowBUsageLicenseGb) {
            return -1
          }

          if (rowBUsageLicenseGb < rowAUsageLicenseGb) {
            return 1
          }

          return 0
        },
        size: 180,
      },
      {
        id: 'openCriticalCount',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('OPEN CRITICAL TICKETS'),
        accessorFn: (row) => `${row.openCriticalCount}`,
        sortingFn: (
          rowA: Row<CustomerHighlight>,
          rowB: Row<CustomerHighlight>,
        ) => {
          const rowAOpenCriticalCount = rowA.original.openCriticalCount
          const rowBOpenCriticalCount = rowB.original.openCriticalCount

          if (rowAOpenCriticalCount < rowBOpenCriticalCount) {
            return -1
          }

          if (rowBOpenCriticalCount < rowAOpenCriticalCount) {
            return 1
          }

          return 0
        },
        size: 190,
      },
      {
        id: 'openWaitingDeepwatchCount',
        accessorFn: (row: CustomerHighlight) => ({
          openWaitingDeepwatchCount: row.openWaitingDeepwatchCount,
          isBookmarkedCustomer: row.isBookmarkedCustomer,
        }),
        cell: (props) => (
          <CustomerTicketsInProgressCell
            cellContext={props}
            handleRemoveCustomerButtonClick={handleRemoveCustomerButtonClick}
            handleAddCustomerButtonClick={handleAddCustomerButtonClick}
          />
        ),
        header: () => buildGenericHeader('TICKETS IN PROGRESS WITH DW'),
        sortingFn: (
          rowA: Row<CustomerHighlight>,
          rowB: Row<CustomerHighlight>,
        ) => {
          const rowAOpenWaitingDeepwatchCount =
            rowA.original.openWaitingDeepwatchCount
          const rowBOpenWaitingDeepwatchCount =
            rowB.original.openWaitingDeepwatchCount

          if (rowAOpenWaitingDeepwatchCount < rowBOpenWaitingDeepwatchCount) {
            return -1
          }

          if (rowBOpenWaitingDeepwatchCount < rowAOpenWaitingDeepwatchCount) {
            return 1
          }

          return 0
        },
        size: 230,
      },
    ]
  }, [
    disableAddedTags,
    handleAddCustomerButtonClick,
    handleRemoveCustomerButtonClick,
    featureModulesRenewalDatesColumns,
  ])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<CustomerHighlight>({
    columns: columnDef,
    data: searchResults,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: { sorting, columnFilters, columnSizing },
    onColumnSizingChange,
    columnResizeMode: 'onChange',
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => ref.current,
    estimateSize: () => 45,
    overscan: 50,
  })

  return (
    <Box>
      <TableToolbar
        loading={loading}
        dataLength={table.getFilteredRowModel().rows.length}
        singularEntityName={'customer'}
        pluralEntityName={'customers'}
      >
        <Box data-testid="squad-filter">
          <TextField
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon variant="searchOutline" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchInput}
                      onClick={() => setSearchInput('')}
                      name={'clearSearch'}
                      aria-label={'clearSearch'}
                    >
                      <Icon variant="close" />
                    </IconButton>
                  </InputAdornment>
                ),
              },
              htmlInput: {
                'aria-label': 'customerName',
              },
            }}
            role="search"
            name={'customerName'}
            type={'text'}
            onChange={({ target }) => setSearchInput(target.value)}
            placeholder={'Find customer by name'}
            value={searchInput}
            disabled={loading}
          />
        </Box>
      </TableToolbar>

      <CommonTableContainer ref={mergeRefs(tableContainerRef, ref)}>
        <CommonTable data-testid="dw-expert-customer-table">
          <thead data-testid="dw-expert-customer-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<CustomerHighlight>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          <tbody data-testid="dw-expert-customer-table-body">
            {loading ? (
              buildSkeletonRows(table.getAllColumns())
            ) : table.getFilteredRowModel().rows.length === 0 ? (
              <Box
                component="tr"
                sx={(theme) => ({
                  backgroundColor: theme.vars.palette.secondary.light,
                })}
              >
                <td colSpan={table.getAllColumns().length}>
                  <NoResults />
                </td>
              </Box>
            ) : (
              rowVirtualizer.getVirtualItems().map((item) => {
                const row = rows[item.index]

                return (
                  <CommonTableRow
                    key={row.id}
                    data-testid="dw-expert-customer-table-row"
                    isSelected={false}
                    onClick={() =>
                      disableRowClick ? undefined : handleRowClick(row.original)
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell<CustomerHighlight> key={cell.id} cell={cell} />
                    ))}
                  </CommonTableRow>
                )
              })
            )}
          </tbody>
        </CommonTable>
      </CommonTableContainer>
    </Box>
  )
}

export default DeepwatchExpertCustomerTable
