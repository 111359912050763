import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import apolloClient from '@config/apolloClient'
import { useTicketsContext, useTicketEditContext } from '@hooks/index'
import {
  GET_TICKET_RESOLUTION,
  GetTicketDetailsVariables,
} from '@queries/ticket'
import { TicketStateEnum } from '@models/Tickets'
import { Context } from '@components/App'
import { Loader } from '@common/Loader'

import { TicketResolutionData } from '../../Types'
import TicketEditCard from '../TicketEditCard'
import { TicketResolutionForm } from './TicketResolutionForm'
import TicketDataHandler from '../TicketDataHandler'

export const TicketResolution: React.FC = () => {
  const { resolutionCodeEnum, ticketSettingsDataLoading } = useTicketsContext()
  const {
    saveDraftTicketFields,
    sysId,
    isFetchLoading,
    isEditable,
    draftTicketFields,
  } = useTicketEditContext()

  const {
    state: { dwExpertsCustomer },
  } = useContext(Context)

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const data = apolloClient.readQuery<
    {
      getTicketDetails: {
        resolutionCode: string
        resolutionNotes: string
        state: TicketStateEnum
      }
    },
    GetTicketDetailsVariables
  >({
    query: GET_TICKET_RESOLUTION,
    variables: {
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
      ticketId: sysId,
    },
  })

  const handleTicketResolutionChange = (
    values: Partial<TicketResolutionData>,
  ) => {
    const existingResolutionNotes = data?.getTicketDetails.resolutionNotes
    const existingResolutionCode = data?.getTicketDetails.resolutionCode

    const val = {
      resolutionNotes:
        draftTicketFields.resolutionNotes || existingResolutionNotes,
      resolutionCode:
        draftTicketFields.resolutionCode || existingResolutionCode,
      ...values,
    }

    // This function can get called multiple times, so we need to merge the new values with the existing draft values
    // Resolution notes and resolution code are both required when updating so making sure both are set
    saveDraftTicketFields(val)
  }

  const showResolutionDetails =
    data?.getTicketDetails.state &&
    [TicketStateEnum.Closed, TicketStateEnum.Resolved].includes(
      data.getTicketDetails.state,
    )

  if (!data && (isFetchLoading || ticketSettingsDataLoading)) {
    return (
      <TicketEditCard>
        <div className="flex center align-center w-full h-full">
          <Loader strokeWidth={1} size={50} />
        </div>
      </TicketEditCard>
    )
  }

  return showResolutionDetails ? (
    <TicketEditCard data-testid="resolution-details" title="Resolution Details">
      <TicketDataHandler<TicketResolutionData>
        graphqlQuery={GET_TICKET_RESOLUTION}
        renderContent={(data) => (
          <TicketResolutionForm
            data={data}
            resolutionCodeEnum={resolutionCodeEnum}
            onChange={handleTicketResolutionChange}
            sysId={sysId}
            inModal={false}
            readonly={!isEditable || data.state === TicketStateEnum.Closed}
          />
        )}
      />
    </TicketEditCard>
  ) : null
}
