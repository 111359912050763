import classNames from 'classnames'
import { Box } from '@mui/material'

export type IndicatorProps = {
  x: number
  y: number
  gap?: number
  polygonHeight?: number
  polygonWidth?: number
  rectHeight?: number
  rectWidth?: number
  rectPadding?: number
  groupClassNames?: {
    pink?: boolean
    active?: boolean
    inactive?: boolean
  }
  innerHTML: string | TrustedHTML
  onClick: () => void
}

const gStyles = (theme) => ({
  cursor: 'pointer',
  '& g': {
    '& ellipse, & circle, & path': {
      fill: 'none',
      stroke: theme.palette.text.primary,
      strokeWidth: '32px',
    },
    '& text': {
      fill: theme.palette.text.primary,
    },
  },

  '&.inactive': {
    '& g': {
      '& ellipse, & circle, & path': {
        strokeOpacity: 0.54,
      },
      '& text': {
        fillOpacity: 0.54,
      },
    },
  },

  '&:focus': {
    outline: 'unset',
  },

  '&:focus-visible': {
    outline: '-webkit-focus-ring-color auto 1px',
  },

  '&:hover, &:focus, &.active': {
    '& g': {
      '& ellipse, & circle, & path': {
        stroke: theme.palette.text.darker,
        strokeOpacity: 1,
      },
      '& text': {
        fill: theme.palette.text.darker,
        fillOpacity: 1,
      },
    },
  },

  '&.pink': {
    '& g': {
      '& ellipse, & circle, & path': {
        stroke: theme.palette.error.light,
      },
      '& text': {
        fill: theme.palette.error.light,
      },
    },
    '&:hover, &:focus, &.active': {
      '& g': {
        '& ellipse, & circle, & path': {
          stroke: theme.palette.error.lighter,
        },
        '& text': {
          fill: theme.palette.error.lighter,
        },
      },
    },
  },
})

const Indicator: React.FC<IndicatorProps> = ({
  x,
  y,
  gap = 2,
  polygonHeight = 7,
  polygonWidth = 6,
  rectHeight = 14,
  rectWidth = 14,
  rectPadding = 4,
  groupClassNames,
  innerHTML,
  onClick,
}) => {
  const rectHeightWithPadding = rectHeight + rectPadding
  const rectWidthWithPadding = rectWidth + rectPadding
  return (
    <>
      <Box
        component={'polygon'}
        points={`${x},${y - gap} ${x - polygonWidth - gap},${
          y - gap - polygonHeight
        } ${x + polygonWidth + gap},${y - gap - polygonHeight}`}
        sx={(theme) => ({
          fill: theme.palette.secondary.light,
          stroke: 'none',
          ...theme.applyStyles('dark', {
            fill: theme.vars.palette.secondary.darker,
            stroke: 'none',
          }),
        })}
        fillOpacity={0.54}
      />
      {/* Add lines for left and right borders */}
      <Box
        component={'line'}
        x1={x}
        y1={y - gap}
        x2={x - polygonWidth - gap}
        y2={y - gap - polygonHeight}
        sx={(theme) => ({
          stroke: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            fill: theme.vars.palette.secondary.darker,
            stroke: 'none',
          }),
        })}
        strokeWidth={0.54}
        strokeOpacity={0.54}
      />
      <Box
        component={'line'}
        x1={x}
        y1={y - gap}
        x2={x + polygonWidth + gap}
        y2={y - gap - polygonHeight}
        sx={(theme) => ({
          stroke: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            fill: theme.vars.palette.secondary.darker,
            stroke: 'none',
          }),
        })}
        strokeWidth={0.54}
        strokeOpacity={0.54}
      />

      <Box
        component={'g'}
        sx={(theme) => gStyles(theme)}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick()
          }
        }}
        className={classNames('indicator', groupClassNames)}
        data-testid={`indicator`}
        tabIndex={0}
        role={'button'}
      >
        <Box
          component={'rect'}
          sx={(theme) => ({
            fill: theme.palette.secondary.light,

            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.secondary.darker,
              stroke: 'none',
            }),
          })}
          width={rectWidthWithPadding}
          height={rectHeightWithPadding}
          x={x - rectWidthWithPadding / 2}
          y={y - gap - polygonHeight - rectHeightWithPadding}
          rx={3}
          ry={3}
        />
        <Box
          component={'line'}
          x1={x - rectWidthWithPadding / 2}
          y1={y - gap - polygonHeight - rectHeightWithPadding}
          x2={x + rectWidthWithPadding / 2}
          y2={y - gap - polygonHeight - rectHeightWithPadding}
          sx={(theme) => ({
            stroke: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.secondary.darker,
              stroke: 'none',
            }),
          })}
          strokeWidth={0.54}
          strokeOpacity={0.54}
        />
        <Box
          component={'line'}
          x1={x - rectWidthWithPadding / 2}
          y1={y - gap - polygonHeight - rectHeightWithPadding}
          x2={x - rectWidthWithPadding / 2}
          y2={y - gap - polygonHeight}
          sx={(theme) => ({
            stroke: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.secondary.darker,
              stroke: 'none',
            }),
          })}
          strokeWidth={0.54}
          strokeOpacity={0.54}
        />
        <Box
          component={'line'}
          x1={x + rectWidthWithPadding / 2}
          y1={y - gap - polygonHeight - rectHeightWithPadding}
          x2={x + rectWidthWithPadding / 2}
          y2={y - gap - polygonHeight}
          sx={(theme) => ({
            stroke: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.secondary.darker,
              stroke: 'none',
            }),
          })}
          strokeWidth={0.54}
          strokeOpacity={0.54}
        />
        <Box
          component={'g'}
          sx={(theme) => gStyles(theme)}
          dangerouslySetInnerHTML={{ __html: innerHTML }}
        />
      </Box>
    </>
  )
}

export default Indicator
