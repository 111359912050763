import { gql } from '@apollo/client'
import { ColumnFiltersState, ColumnSort } from '@tanstack/react-table'

import { Forwarder } from '@models/Forwarders'
import { Pagination, PaginationInput } from '@models/Tickets'

export interface ForwarderVariablesInput {
  filters?: ColumnFiltersState
  pagination: PaginationInput
  selectedCustomer: string | null
  sorting: ColumnSort
}

export interface PaginatedForwardersData {
  forwarders: Forwarder[]
  pagination: Pagination
}
export interface Forwarders {
  environmentHealthForwarders: PaginatedForwardersData
}

export const ENVIRONMENT_HEALTH_FORWARDERS = gql`
  query EnvironmentHealthForwarders(
    $selectedCustomer: String
    $pagination: PaginationInput
    $sorting: SortingInput
    $filters: [FilterInput]
  ) {
    environmentHealthForwarders(
      selectedCustomer: $selectedCustomer
      pagination: $pagination
      sorting: $sorting
      filters: $filters
    ) {
      forwarders {
        hostname
        version
        os
        type
        status
        lastIndexConnection
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`
