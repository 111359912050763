import { CustomSVGProps } from './types'

const EDRLogo: React.FC<CustomSVGProps> = () => (
  <svg
    data-testid="edr-logo"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="paint0_linear_24227_35205"
        x1="22.5728"
        y1="2.00244"
        x2="3.04306"
        y2="19.1815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4D85" />
        <stop offset="1" stopColor="#7829EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24227_35205"
        x1="22.1691"
        y1="2.00244"
        x2="2.52996"
        y2="23.1161"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4D85" />
        <stop offset="1" stopColor="#7829EA" />
      </linearGradient>
    </defs>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.985 20.7349L13.4126 21.5591C13.722 21.7377 14.1032 21.7377 14.4126 21.5591L22.0728 17.1364C22.3822 16.9578 22.5728 16.6277 22.5728 16.2704V7.42509C22.5728 7.06783 22.3822 6.7377 22.0728 6.55907L14.4126 2.13642C14.1032 1.95778 13.722 1.95778 13.4126 2.13642L11.985 2.96061L18.0838 6.48172L18.5838 6.77039V7.34774V16.3477V16.9251L18.0838 17.2138L11.985 20.7349ZM10.985 20.1575L5.75232 17.1364C5.44292 16.9578 5.25232 16.6277 5.25232 16.2704V7.42509C5.25232 7.06783 5.44292 6.7377 5.75232 6.55907L10.985 3.53796L17.5838 7.34774V16.3477L10.985 20.1575Z"
      fill="url(#paint0_linear_24227_35205)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.985 2.95789L10.7942 2.27039C10.1754 1.91312 9.41303 1.91313 8.79423 2.27039L2 6.19304C1.3812 6.55031 1 7.21056 1 7.92509V15.7704C1 16.4849 1.3812 17.1452 2 17.5024L8.79423 21.4251C9.41303 21.7824 10.1754 21.7824 10.7942 21.4251L11.9888 20.7354L12.1562 20.8321L15.4139 20.8749L22.1691 16.3488L21.9024 6.83603L12.418 2.70712L11.985 2.95789ZM10.9866 3.53617L11.985 2.95789L17.5885 6.19304C18.2073 6.55031 18.5885 7.21056 18.5885 7.92509V15.7704C18.5885 16.4849 18.2073 17.1452 17.5885 17.5024L11.9888 20.7354L10.989 20.1579L17.0885 16.6364C17.3979 16.4578 17.5885 16.1277 17.5885 15.7704V7.92509C17.5885 7.56783 17.3979 7.2377 17.0885 7.05907L10.9866 3.53617ZM10.9866 3.53617L10.8398 3.62118L10.7969 20.047L10.989 20.1579L10.2942 20.5591C9.98483 20.7377 9.60363 20.7377 9.29423 20.5591L2.5 16.6364C2.1906 16.4578 2 16.1277 2 15.7704V7.92509C2 7.56783 2.1906 7.2377 2.5 7.05907L9.29423 3.13642C9.60363 2.95778 9.98483 2.95778 10.2942 3.13642L10.9866 3.53617Z"
      fill="url(#paint1_linear_24227_35205)"
    />
  </svg>
)

export default EDRLogo
