import { useEffect, RefObject, useState, useRef } from 'react'

interface UseScrollBottomReturn {
  scrollableElementRef: RefObject<HTMLDivElement>
  hasReachedBottom: boolean
}

interface UseScrollBottomParams {
  /** Boolean that triggers resetting hasReachedBottom state when changed */
  resetTrigger?: boolean
  /** Distance from bottom to trigger "reached bottom" state (in pixels) */
  buffer?: number
}

const defaultOptions: Required<UseScrollBottomParams> = {
  resetTrigger: false,
  buffer: 10,
}

/**
 * Hook to detect when a user has scrolled to the bottom of an element
 * @param {UseScrollBottomParams} options Optional parameters to configure the hook
 * @returns {UseScrollBottomReturn} a ref to attach to scrollable element, and a boolean indicating if scrolled to bottom
 */
export const useScrollBottom = (
  options?: UseScrollBottomParams,
): UseScrollBottomReturn => {
  const { resetTrigger, buffer } = { ...defaultOptions, ...options }
  const scrollableElementRef = useRef<HTMLDivElement>(null)
  const [hasReachedBottom, setHasReachedBottom] = useState(false)

  useEffect(() => {
    setHasReachedBottom(false)
    const targetNode = document.body
    const config = { childList: true, subtree: true }

    const handleScroll = (element: HTMLElement) => {
      const bottom =
        Math.ceil(element.clientHeight + element.scrollTop + buffer) >=
        element.scrollHeight

      if (bottom) {
        setHasReachedBottom(true)
      }
    }

    let currentElement: HTMLElement | null = null
    const scrollHandler = () => currentElement && handleScroll(currentElement)

    // Create an observer to watch for when the ref's element becomes available
    const observer = new MutationObserver(() => {
      const element = scrollableElementRef.current
      if (element) {
        observer.disconnect()

        // Store the current element for cleanup function
        currentElement = element

        // Check initial scroll position
        handleScroll(element)

        // Add scroll listener
        element.addEventListener('scroll', scrollHandler, {
          passive: true,
        })
      }
    })

    // Start observing
    observer.observe(targetNode, config)

    return () => {
      observer.disconnect()
      if (currentElement) {
        currentElement.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [buffer, resetTrigger])

  return {
    scrollableElementRef,
    hasReachedBottom,
  }
}
