import {
  BarCustomLayerProps,
  BarDatum,
  BarTooltipProps,
  ComputedDatum,
  ResponsiveBar,
} from '@nivo/bar'
import { Box, useColorScheme, useTheme } from '@mui/material'
import { ApolloError } from '@apollo/client'
import { useState } from 'react'
import { format } from 'date-fns'

import { themeConfig } from '@common/nivo/utils'
import { useEnvironmentHealthChartDetailsSideSheet } from '@components/EnvironmentHealth/Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import {
  EnvironmentHealthUtilAlertsNoValue,
  SourceType,
} from '@models/EnvHealth'
import { useXAxisDateFormatting } from '@hooks/index'
import { ComponentError } from '@common/ComponentError'

import {
  chunkPastPeriodUtilization,
  chunkSourceTypesByDate,
  compareByDateAscending,
  hoveredGroupedBarColors,
  modifyMaxValueScale,
} from '../SourceUtilization.utils'
import { PastPeriodBarChartTooltip } from '../SuToolTip/SuToolTip'

interface PastPeriodChartProps {
  data: EnvironmentHealthUtilAlertsNoValue | null
  error?: ApolloError
}

export type ChartData = {
  date: string
  totalGbForDay: number
  sourceTypes: SourceType[]
  totalAlertsForDay: number
}

export type PastPeriodChartData = {
  currentDate: string
  pastDate: string
  currentPeriod: number
  pastPeriod: number
  date: string
}

const PastPeriodBarChart: React.FC<PastPeriodChartProps> = ({ data }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const [hoveredBar, setHoveredBar] = useState('')

  const formattedCurrentDataPoints = chunkSourceTypesByDate(data)
    .sort(compareByDateAscending)
    .map((d) => ({
      currentPeriod: d.totalGbForDay,
      currentDate: d.date,
    }))

  const formattedPreviousDataPoints = chunkPastPeriodUtilization(data)
    .sort(compareByDateAscending)
    .map((d) => ({
      pastPeriod: d.totalGbForDay,
      pastDate: d.date,
    }))

  const mergeCurrentPreviousData: PastPeriodChartData[] =
    formattedCurrentDataPoints.map((currentItem, index) => {
      const pastItem = formattedPreviousDataPoints[index]
      return {
        currentDate: currentItem.currentDate,
        pastDate: pastItem ? pastItem.pastDate : '',
        currentPeriod: currentItem.currentPeriod,
        pastPeriod: pastItem ? pastItem.pastPeriod : 0,
        date: currentItem.currentDate, // Set the combined date as the currentDate
      }
    })

  const { licenseCapacity } = useEnvironmentHealthChartDetailsSideSheet()

  const largestGbDataPoint = mergeCurrentPreviousData
    .slice()
    .sort((a, b) => b.currentPeriod - a.currentPeriod)[0]?.currentPeriod

  const formatXAxis = useXAxisDateFormatting(mergeCurrentPreviousData)

  const pastPeriodColor =
    mode === 'dark'
      ? theme.vars.palette.text.secondary
      : theme.vars.palette.secondary.main

  const chartData = chunkSourceTypesByDate(data).sort(compareByDateAscending)
  /**
   * @description Look up the data for a specific date; used when a bar is clicked or hovered
   * @param {string | number} indexValue The date of the data to look up
   * @returns {ChartData} The chart data for the date
   */
  const lookUpDataByDate = (indexValue: string | number): ChartData => {
    const data = chartData.find(({ date }) => date === indexValue)
    if (!data) {
      // This error should never be thrown, but it's here for type safety
      throw new Error('Data not found')
    }
    return data
  }

  /**
   * Get current period bar color for the bar in the chart itself as well as the block in the tooltip
   * The color depends on the current period overage and light vs dark mode
   * @param bar The bar data
   * @returns string for the correct color
   */
  const getCurrentPeriodColor = (
    bar:
      | BarTooltipProps<PastPeriodChartData>
      | ComputedDatum<PastPeriodChartData>,
  ): string => {
    const currentPeriodValue = (bar && bar.data.currentPeriod) ?? 0
    const overCapacity = licenseCapacity
      ? currentPeriodValue > licenseCapacity
      : 0

    return overCapacity
      ? (theme.vars.palette.severity.medium ?? '')
      : (theme.vars.palette.severity.low ?? '')
  }

  const pastPeriodStartDate = format(
    new Date(formattedPreviousDataPoints[0].pastDate),
    'LLL d',
  )
  const pastPeriodEndDate = format(
    new Date(
      formattedPreviousDataPoints[
        formattedPreviousDataPoints.length - 1
      ].pastDate,
    ),
    'LLL d',
  )

  const formattedPastPeriodDateLegend = `${pastPeriodStartDate} - ${pastPeriodEndDate}`
  if (mergeCurrentPreviousData.length === 0) {
    return <ComponentError errorContainerStyles={{ height: '100%' }} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
      id="su-chart-container"
      data-testid="su-chart-container"
    >
      <ResponsiveBar
        data={mergeCurrentPreviousData}
        keys={['pastPeriod', 'currentPeriod']}
        indexBy="currentDate"
        innerPadding={4}
        margin={{
          top: 50,
          right: 2,
          bottom: 45,
          left: 36,
        }}
        padding={0.6}
        groupMode="grouped"
        indexScale={{ type: 'band', round: true }}
        borderColor={theme.vars.palette.secondary.lighter}
        valueScale={{
          type: 'linear',
          max: modifyMaxValueScale(largestGbDataPoint, licenseCapacity),
          min: 0,
        }}
        colors={(bar) => {
          if (bar.id === 'pastPeriod') {
            return pastPeriodColor
          }

          if (hoveredBar) {
            const barByDate = lookUpDataByDate(bar.indexValue)
            return hoveredGroupedBarColors(
              bar,
              barByDate,
              hoveredBar,
              licenseCapacity,
              theme,
            )
          }

          return getCurrentPeriodColor(bar)
        }}
        enableLabel={false}
        theme={themeConfig(mode, theme)}
        axisTop={null}
        axisRight={null}
        gridYValues={6}
        axisLeft={{
          tickPadding: 0,
          tickValues: 5,
          format: (value) => `${Math.ceil(value)}`,
        }}
        axisBottom={{ format: formatXAxis }}
        onMouseEnter={({ indexValue }) => setHoveredBar(indexValue as string)}
        onMouseLeave={() => setHoveredBar('')}
        layers={[
          'grid',
          'axes',
          'bars',
          LicenseCapacityLineWrapper(licenseCapacity),
          LicenseCapacityLegendWrapper(
            formattedPastPeriodDateLegend,
            pastPeriodColor,
          ),
        ]}
        tooltip={(bar) => {
          return (
            <PastPeriodBarChartTooltip
              nivoBar={bar}
              pastPeriodColor={pastPeriodColor}
              currentPeriodColor={getCurrentPeriodColor(bar)}
            />
          )
        }}
      />
    </Box>
  )
}

// eslint-disable-next-line jsdoc/require-param
/**
 * Dashed SVG line element with proper positioning for license capacity across the chart
 * @param props.yScale Nivo method to scale y-axis values
 * @param props.width Nivo property that tells us the chart width
 * @param props.licenseCapacity the license capacity to help determine the line position
 * @returns dashed SVG line element
 */
const LicenseCapacityLine: React.FC<
  Pick<BarCustomLayerProps<BarDatum>, 'yScale' | 'width'> & {
    licenseCapacity: number | undefined
  }
> = ({ yScale, width, licenseCapacity }) => {
  const linePosition = licenseCapacity ? yScale(licenseCapacity) : undefined
  return (
    <Box
      component="line"
      sx={(theme) => ({
        stroke: theme.palette.text.primary,
        ...theme.applyStyles('dark', { stroke: theme.palette.text.secondary }),
      })}
      x1="10"
      y1={linePosition}
      x2={width - 55}
      y2={linePosition}
      strokeDasharray="8"
      data-testid="license-capacity-dashed-line"
    />
  )
}

/**
 * Wrapper component for custom Nivo layer
 * We need this wrapper so we can get both the Nivo props as well as
 * any custom props we want to use
 * @param capacity number
 * @returns LicenseCapacityLine which is a dashed line SVG going across the NoValueChart
 */
const LicenseCapacityLineWrapper = (capacity: number | undefined) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <LicenseCapacityLine {...props} licenseCapacity={capacity} />
  )
}

/**
 * Nivo custom layer for the license capacity legend found on NoValueChart
 * @param width.width
 * @param width width of the chart so we can position the legend
 * @param width.pastPeriodDates
 * @param width.pastPeriodColor
 * @returns SVG element
 */
const LicenseCapacityLegend: React.FC<
  Pick<BarCustomLayerProps<BarDatum>, 'width'> & {
    pastPeriodDates: string
    pastPeriodColor: string
  }
> = ({ width, pastPeriodDates, pastPeriodColor }) => {
  return (
    <>
      <Box component="g" transform={`translate(${width - 700}, -20)`}>
        <Box
          component="line"
          x1="-31"
          x2="-10"
          y1="-4"
          y2="-4"
          sx={(theme) => ({
            stroke: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              stroke: theme.palette.text.secondary,
            }),
          })}
          strokeDasharray={4}
          strokeWidth={7}
          strokeDashoffset={3.5}
        />
        <Box
          component="text"
          sx={(theme) => ({
            fontSize: theme.typography.body2.fontSize,
            fill: theme.palette.text.primary,
          })}
          fontWeight={400}
        >
          License capacity
        </Box>
      </Box>
      <Box component="g" transform={`translate(${width - 560}, -20)`}>
        <Box
          component="rect"
          y="-10"
          x="-15"
          sx={(theme) => ({
            stroke: theme.palette.severity.low,
            fill: theme.palette.severity.low,
            height: 10,
            width: 10,
          })}
        />
        <Box
          component="text"
          sx={(theme) => ({
            fontSize: theme.typography.body2.fontSize,
            fill: theme.palette.text.primary,
          })}
          fontWeight={400}
        >
          Current period
        </Box>
      </Box>
      <Box component="g" transform={`translate(${width - 435}, -20)`}>
        <Box
          component="rect"
          y="-10"
          x="-15"
          sx={(theme) => ({
            stroke: theme.palette.severity.medium,
            fill: theme.palette.severity.medium,
            height: 10,
            width: 10,
          })}
        />
        <Box
          component="text"
          sx={(theme) => ({
            fontSize: theme.typography.body2.fontSize,
            fill: theme.palette.text.primary,
          })}
          fontWeight={400}
        >
          Current period overage
        </Box>
      </Box>
      <Box component="g" transform={`translate(${width - 250}, -20)`}>
        <Box
          component="rect"
          y="-10"
          x="-15"
          sx={{
            stroke: pastPeriodColor,
            fill: pastPeriodColor,
            height: 10,
            width: 10,
          }}
        />
        <Box
          component="text"
          sx={(theme) => ({
            fontSize: theme.typography.body2.fontSize,
            fill: theme.palette.text.primary,
          })}
          fontWeight={400}
        >
          Previous period {pastPeriodDates}
        </Box>
      </Box>
    </>
  )
}

const LicenseCapacityLegendWrapper = (pastPeriodDates, pastPeriodColor) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <LicenseCapacityLegend
      {...props}
      pastPeriodDates={pastPeriodDates}
      pastPeriodColor={pastPeriodColor}
    />
  )
}

export default PastPeriodBarChart
