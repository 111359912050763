import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import { DetectionCoverageOverviewResponse } from '@models/DetectionCoverage'
import { colors } from '@design-system/index'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'

import { DetectionCoverageDisplayValues } from './utils'
import { useMitreOverviewContext } from './context/MitreOverviewContext'
import SplunkUtilization from './SplunkUtilization'
import {
  DeprecatedDetectionCatalogStatus,
  DetectionStatusType,
} from '../Coverage.utils'

interface BansProps {
  handleDetectionLibNav: (status: string) => void
  isLoading: boolean
  detectionCoverageData?: Pick<
    DetectionCoverageDisplayValues,
    | 'custAvg'
    | 'scoreDiff'
    | 'currentSplunkUsage'
    | 'availableUseCases'
    | 'enabledUseCases'
    | 'eligibleUseCases'
    | 'dwAvg'
    | 'industryAvg'
    | 'maxValCustTrend'
    | 'formattedData'
  > &
    Partial<Pick<DetectionCoverageOverviewResponse, 'splunkUtilization'>>
}

const Bans: React.FC<BansProps> = ({
  handleDetectionLibNav,
  isLoading,
  detectionCoverageData,
}) => {
  const { featureEnvHealth } = useFlags()
  const {
    detectionCoverageOverview,
    updateOrCloseSideSheet,
    setIsSideSheetOpen,
  } = useMitreOverviewContext()

  const {
    eligibleUseCases,
    currentSplunkUsage,
    enabledUseCases,
    splunkUtilization,
  } = detectionCoverageData || {
    custAvg: 0,
    dwAvg: 0,
    industryAvg: 0,
    maxValCustTrend: 0,
    scoreDiff: '0',
    availableUseCases: 0,
    eligibleUseCases: 0,
    currentSplunkUsage: 0,
    enabledUseCases: 0,
    splunkUtilization: undefined,
    formattedData: 0,
  }

  return (
    <Box sx={{ display: 'flex', gap: '1rem', width: '100%' }}>
      {!featureEnvHealth && (
        <Ban
          data-testid="license-utilization-ban"
          iconVariant="flashOutline"
          onClick={() => {
            updateOrCloseSideSheet({
              id: 'splunk',
              title: 'License utilization',
              children: (
                <SplunkUtilization
                  detectionCoverageOverview={detectionCoverageOverview}
                />
              ),

              closeSideSheet: () => {
                setIsSideSheetOpen(false)
              },
            })
          }}
        >
          <BanLayoutCommon
            error={currentSplunkUsage === 0 || splunkUtilization == null}
            loading={isLoading}
            title="LICENSE UTILIZATION"
            value={currentSplunkUsage ? `${currentSplunkUsage} GB` : ''}
          />

          {splunkUtilization?.license && (
            <Box
              sx={{
                backgroundColor: colors.util.navy[200],
                height: '3px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.util.three.light,
                  height: '3px',
                  width: `${(currentSplunkUsage / splunkUtilization.license) * 100}px`,
                }}
              />
            </Box>
          )}
        </Ban>
      )}

      <Ban
        data-testid="eligible-detections-ban"
        iconVariant="pulse"
        onClick={() => {
          handleDetectionLibNav(DetectionStatusType.ELIGIBLE)
        }}
      >
        <BanLayoutCommon
          caption="Detections that can be enabled based on the latest Deepwatch DWA version"
          error={eligibleUseCases == null}
          loading={isLoading}
          title="ELIGIBLE DETECTIONS"
          value={eligibleUseCases ? eligibleUseCases.toString() : '0'}
        />
      </Ban>

      <Ban
        data-testid="enabled-detections-ban"
        iconVariant="cloudDoneOutline"
        onClick={() => {
          handleDetectionLibNav(DeprecatedDetectionCatalogStatus.Enabled)
        }}
      >
        <BanLayoutCommon
          error={enabledUseCases == null}
          loading={isLoading}
          title="ENABLED DETECTIONS"
          value={enabledUseCases ? enabledUseCases.toString() : '0'}
        />
      </Ban>
    </Box>
  )
}

export default Bans
