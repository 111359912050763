const SignificantCyberEventUnread = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63923 1.38916H15.3978C16.137 1.38997 16.8457 1.68398 17.3684 2.20668C17.8911 2.72939 18.1851 3.4381 18.1859 4.17732V11.3969C17.2348 10.8365 16.126 10.5151 14.9422 10.5151C13.4074 10.5151 11.9989 11.0553 10.8962 11.956C10.7845 11.8839 10.6535 11.8448 10.5185 11.8448H7.03331C6.84845 11.8448 6.67115 11.9182 6.54043 12.0489C6.40971 12.1796 6.33627 12.3569 6.33627 12.5418C6.33627 12.7267 6.40971 12.904 6.54043 13.0347C6.67115 13.1654 6.84845 13.2388 7.03331 13.2388H9.70271C8.97146 14.2791 8.54216 15.547 8.54216 16.9151C8.54216 18.4246 9.06471 19.812 9.93879 20.9063H5.63923C4.90001 20.9055 4.1913 20.6115 3.6686 20.0887C3.14589 19.566 2.85188 18.8573 2.85107 18.1181V4.17732C2.85188 3.4381 3.14589 2.72939 3.6686 2.20668C4.1913 1.68398 4.90001 1.38997 5.63923 1.38916ZM7.03331 9.75364H14.0037C14.1886 9.75364 14.3659 9.6802 14.4966 9.54948C14.6273 9.41876 14.7007 9.24146 14.7007 9.0566C14.7007 8.87173 14.6273 8.69444 14.4966 8.56371C14.3659 8.43299 14.1886 8.35956 14.0037 8.35956H7.03331C6.84845 8.35956 6.67115 8.43299 6.54043 8.56371C6.40971 8.69444 6.33627 8.87173 6.33627 9.0566C6.33627 9.24146 6.40971 9.41876 6.54043 9.54948C6.67115 9.6802 6.84845 9.75364 7.03331 9.75364ZM7.03331 6.26844H14.0037C14.1886 6.26844 14.3659 6.195 14.4966 6.06428C14.6273 5.93356 14.7007 5.75626 14.7007 5.5714C14.7007 5.38653 14.6273 5.20924 14.4966 5.07852C14.3659 4.9478 14.1886 4.87436 14.0037 4.87436H7.03331C6.84845 4.87436 6.67115 4.9478 6.54043 5.07852C6.40971 5.20924 6.33627 5.38653 6.33627 5.5714C6.33627 5.75626 6.40971 5.93356 6.54043 6.06428C6.67115 6.195 6.84845 6.26844 7.03331 6.26844Z"
      fill="currentcolor"
    />
    <g clipPath="url(#clip0_4154_89340)">
      <path
        d="M18.5006 15.2821C18.2016 14.4885 17.7658 13.7535 17.2128 13.1105C16.2559 12.0028 14.9976 11.2874 14.0071 11.2874C13.9482 11.2874 13.8901 11.3009 13.8372 11.3269C13.7844 11.3528 13.7381 11.3905 13.7021 11.4371C13.666 11.4837 13.6411 11.5379 13.6293 11.5956C13.6174 11.6533 13.6189 11.7129 13.6336 11.7699C13.9682 13.0487 13.2745 14.1121 12.5403 15.2377C11.932 16.17 11.3047 17.133 11.3047 18.2364C11.3047 20.365 13.0366 22.097 15.1653 22.097C17.2939 22.097 19.0258 20.365 19.0258 18.2364C19.0258 17.1916 18.8492 16.1978 18.5006 15.2821ZM15.9453 20.6177C15.6961 20.8831 15.3836 20.9388 15.1653 20.9388C14.9469 20.9388 14.6344 20.8831 14.3852 20.6177C14.1359 20.3522 14.0071 19.9312 14.0071 19.3946C14.0071 18.788 14.2199 18.3262 14.4255 17.8798C14.5449 17.6206 14.6668 17.3569 14.7392 17.0746C14.7468 17.0439 14.762 17.0156 14.7832 16.9921C14.8044 16.9686 14.8311 16.9507 14.8609 16.9399C14.8906 16.9292 14.9226 16.9259 14.9539 16.9304C14.9852 16.9349 15.015 16.9471 15.0405 16.9658C15.2612 17.1365 15.4574 17.3367 15.6237 17.5608C16.0621 18.1399 16.3234 18.8256 16.3234 19.3946C16.3234 19.9312 16.1927 20.3542 15.9453 20.6177Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4154_89340">
        <rect
          width="12.3539"
          height="12.3539"
          fill="currentcolor"
          transform="translate(8.98828 10.5151)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default SignificantCyberEventUnread
