import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import { Box, Tooltip, Typography } from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import TableHeader from '@common/Table/utils/TableHeader'
import Icon from '@common/Icon'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { useDateFilterableStyles } from '@hooks/index'

export type TopLogSourcesByUsageData = {
  source: string
  usageGb: number | null
  alertCount: number
}

export interface TopLogSourcesByUsageProps {
  data: TopLogSourcesByUsageData[] | null
  loading: boolean
  error?: ApolloError | undefined
}

const TopLogSourcesByUsageTable: React.FC<TopLogSourcesByUsageProps> = ({
  data,
  loading,
  error,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'usageGb',
      desc: true,
    },
  ])

  const generateTitle = () => {
    if (!loading && data && data.length > 0) {
      return `Top ${data.length} source types by usage`
    }

    return `Top source types by usage`
  }

  const columnHelper = createColumnHelper<TopLogSourcesByUsageData>()

  const columnDef = [
    columnHelper.accessor('source', {
      cell: (props) => buildGenericCell(props),
      header: () => buildGenericHeader('SOURCE TYPE'),
      sortingFn: 'text',
    }),
    columnHelper.accessor('usageGb', {
      cell: (props) => {
        const value = props.getValue()
        const formatted = value !== null ? value.toFixed(2) + ' GB' : '<1GB'

        return (
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {formatted}
          </Typography>
        )
      },
      header: () => buildGenericHeader('AVG. LICENSE USAGE'),
    }),
    columnHelper.accessor('alertCount', {
      cell: (props) => buildGenericCell(props),
      header: () => buildGenericHeader('# ALERTS'),
      sortingFn: 'alphanumeric',
    }),
  ]

  const table = useReactTable({
    columns: columnDef,
    data: data ? data : [],
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  })

  const renderContent = () => {
    if ((!loading && !data) || error) {
      return (
        <tbody data-testid="top-source-types-by-usage-table-body">
          <tr>
            <StyledTableCell
              align="center"
              colSpan={4}
              sx={{ border: 'none', padding: '50px' }}
            >
              <ComponentError />
            </StyledTableCell>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody data-testid="top-source-types-by-usage-table-body">
        {loading
          ? buildSkeletonRows(table.getAllColumns(), 5)
          : table
              .getRowModel()
              .rows.map((row) => (
                <MemoizedTanStackTableRow
                  disableCursor
                  key={row.id}
                  rowId={`top-source-types-by-usage-table-body-row-${row.id}`}
                  testId="top-source-types-by-usage-table-body-row"
                  isSelected={false}
                  visibleCells={row.getVisibleCells()}
                />
              ))}
      </tbody>
    )
  }

  const dateFilterableStyles = useDateFilterableStyles()

  return (
    <Box id="top-source-types-by-usage" data-testid="top-source-types-by-usage">
      <Box sx={{ padding: '1rem 0' }}>
        <Tooltip
          title="The daily average usage per top source types."
          placement="top-start"
        >
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              display: 'inline-block',
              verticalAlign: 'text-bottom',
              mr: 1,
            }}
          >
            {generateTitle()}
          </Typography>
        </Tooltip>

        {((!loading && !data) || error) && (
          <Icon variant="warningOutline" size={20} color="warning" />
        )}
      </Box>

      <CommonTableContainer
        sx={[dateFilterableStyles, { borderRadius: '5px' }]}
      >
        <CommonTable data-testid="top-source-types-by-usage-table">
          <thead data-testid="top-source-types-by-usage-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                data-testid="top-source-types-by-usage-table-header-row"
              >
                {headerGroup.headers.map((header) => (
                  <TableHeader<TopLogSourcesByUsageData>
                    key={header.id}
                    header={header}
                  />
                ))}
              </tr>
            ))}
          </thead>
          {renderContent()}
        </CommonTable>
      </CommonTableContainer>
    </Box>
  )
}

export default TopLogSourcesByUsageTable
