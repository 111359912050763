const CustomerAdvisoryUnread = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63826 1.38916H15.3968C16.136 1.38997 16.8447 1.68398 17.3674 2.20668C17.8902 2.72939 18.1842 3.4381 18.185 4.17732V11.0986C17.2275 10.4925 16.0924 10.1416 14.8754 10.1416C13.2192 10.1416 11.7148 10.7914 10.6032 11.85C10.5749 11.8465 10.5463 11.8448 10.5175 11.8448H7.03234C6.84747 11.8448 6.67017 11.9182 6.53945 12.0489C6.40873 12.1796 6.3353 12.3569 6.3353 12.5418C6.3353 12.7267 6.40873 12.904 6.53945 13.0347C6.67017 13.1654 6.84747 13.2388 7.03234 13.2388H9.50883C8.98139 14.1503 8.6795 15.2087 8.6795 16.3375C8.6795 18.146 9.45434 19.7736 10.6902 20.9063H5.63826C4.89904 20.9055 4.19033 20.6115 3.66762 20.0887C3.14492 19.566 2.8509 18.8573 2.8501 18.1181V4.17732C2.8509 3.4381 3.14492 2.72939 3.66762 2.20668C4.19033 1.68398 4.89904 1.38997 5.63826 1.38916ZM7.03234 9.75364H14.0027C14.1876 9.75364 14.3649 9.6802 14.4956 9.54948C14.6263 9.41876 14.6998 9.24146 14.6998 9.0566C14.6998 8.87173 14.6263 8.69444 14.4956 8.56371C14.3649 8.43299 14.1876 8.35956 14.0027 8.35956H7.03234C6.84747 8.35956 6.67017 8.43299 6.53945 8.56371C6.40873 8.69444 6.3353 8.87173 6.3353 9.0566C6.3353 9.24146 6.40873 9.41876 6.53945 9.54948C6.67017 9.6802 6.84747 9.75364 7.03234 9.75364ZM7.03234 6.26844H14.0027C14.1876 6.26844 14.3649 6.195 14.4956 6.06428C14.6263 5.93356 14.6998 5.75626 14.6998 5.5714C14.6998 5.38653 14.6263 5.20924 14.4956 5.07852C14.3649 4.9478 14.1876 4.87436 14.0027 4.87436H7.03234C6.84747 4.87436 6.67017 4.9478 6.53945 5.07852C6.40873 5.20924 6.3353 5.38653 6.3353 5.5714C6.3353 5.75626 6.40873 5.93356 6.53945 6.06428C6.67017 6.195 6.84747 6.26844 7.03234 6.26844Z"
      fill="currentcolor"
    />
    <path
      d="M19.5224 16.3376C19.5224 13.7721 17.4409 11.6907 14.8754 11.6907C12.31 11.6907 10.2285 13.7721 10.2285 16.3376C10.2285 18.9031 12.31 20.9845 14.8754 20.9845C17.4409 20.9845 19.5224 18.9031 19.5224 16.3376Z"
      stroke="currentcolor"
      strokeMiterlimit="10"
    />
    <path
      d="M14.7365 14.1606L14.8754 17.1121L15.0141 14.1606C15.0149 14.1417 15.0119 14.1229 15.0053 14.1052C14.9986 14.0876 14.9884 14.0714 14.9753 14.0578C14.9621 14.0442 14.9464 14.0335 14.929 14.0262C14.9116 14.0189 14.8928 14.0152 14.8739 14.0154V14.0154C14.8553 14.0156 14.8368 14.0195 14.8197 14.027C14.8026 14.0344 14.7872 14.0452 14.7744 14.0588C14.7615 14.0724 14.7516 14.0884 14.7451 14.1058C14.7385 14.1233 14.7356 14.142 14.7365 14.1606V14.1606Z"
      stroke="currentcolor"
      strokeWidth="0.774489"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8757 19.046C14.7799 19.046 14.6863 19.0176 14.6067 18.9644C14.5271 18.9112 14.4651 18.8356 14.4284 18.7472C14.3918 18.6587 14.3822 18.5614 14.4009 18.4675C14.4196 18.3736 14.4657 18.2874 14.5334 18.2197C14.6011 18.152 14.6873 18.1059 14.7812 18.0872C14.8751 18.0685 14.9724 18.0781 15.0609 18.1147C15.1493 18.1514 15.2249 18.2134 15.2781 18.293C15.3313 18.3726 15.3597 18.4662 15.3597 18.5619C15.3597 18.6903 15.3087 18.8134 15.2179 18.9042C15.1272 18.995 15.004 19.046 14.8757 19.046Z"
      fill="currentcolor"
    />
  </svg>
)

export default CustomerAdvisoryUnread
