import { CustomSVGProps } from './types'

const DefenderLogo: React.FC<CustomSVGProps> = () => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="microsoft-defender-logo"
  >
    <path
      d="M8.91001 15.4C8.73889 15.4 8.60001 15.2611 8.60001 15.09C8.60001 14.9188 8.73889 14.78 8.91001 14.78V15.4Z"
      fill="#0370C8"
    />
    <path
      d="M9.21997 15.09C9.21997 15.2611 9.08109 15.4 8.90997 15.4V14.78C9.08109 14.78 9.21997 14.9188 9.21997 15.09Z"
      fill="#0F5094"
    />
    <path
      d="M14.49 5.47999C14.6612 5.47999 14.8 5.34119 14.8 5.16999C14.8 4.99878 14.6612 4.85999 14.49 4.85999C14.3188 4.85999 14.18 4.99878 14.18 5.16999C14.18 5.34119 14.3188 5.47999 14.49 5.47999Z"
      fill="#0883D9"
    />
    <path
      d="M3.32999 5.47999C3.5012 5.47999 3.63999 5.34119 3.63999 5.16999C3.63999 4.99878 3.5012 4.85999 3.32999 4.85999C3.15878 4.85999 3.01999 4.99878 3.01999 5.16999C3.01999 5.34119 3.15878 5.47999 3.32999 5.47999Z"
      fill="#33BFF0"
    />
    <path
      d="M8.90997 15.09L9.04234 15.3712C9.04234 15.3712 13.737 12.9222 14.6471 9.20001H8.90997V15.09Z"
      fill="#0F5094"
    />
    <path
      d="M14.8 5.17L14.49 4.86C11.0422 4.86 10.9938 3 8.90997 3V9.2H14.6471C14.7448 8.80072 14.8 8.38718 14.8 7.96C14.8 6.98536 14.8 5.17 14.8 5.17Z"
      fill="#0883D9"
    />
    <path
      d="M8.90999 15.09L8.77762 15.3712C8.77762 15.3712 4.08298 12.9222 3.17282 9.20001H8.90999V15.09Z"
      fill="#0370C8"
    />
    <path
      d="M3.01999 5.17L3.32999 4.86C6.77781 4.86 6.82617 3 8.90999 3V9.2H3.17282C3.07517 8.80072 3.01999 8.38718 3.01999 7.96C3.01999 6.98536 3.01999 5.17 3.01999 5.17Z"
      fill="#33BFF0"
    />
  </svg>
)

export default DefenderLogo
