import { Box, BoxProps, styled } from '@mui/material'

interface CommonTableRowProps extends BoxProps {
  disableCursor?: boolean
  isDisabled?: boolean
  isSelected: boolean
}

const CommonTableRow = styled(
  ({
    isSelected,
    isDisabled,
    disableCursor,
    ...props
  }: CommonTableRowProps) => (
    <Box
      component="tr"
      {...props}
      data-is-selected={isSelected} //cant be passed directly to DOM elements
      data-is-disabled={isDisabled} //cant be passed directly to DOM elements
      data-disable-cursor={disableCursor} //cant be passed directly to DOM elements
    />
  ),
)<CommonTableRowProps>(
  ({ disableCursor = false, isDisabled, isSelected, theme }) => ({
    backgroundColor: isSelected
      ? theme.vars.palette.secondary.lighter
      : theme.vars.palette.secondary.light,
    ...theme.applyStyles('dark', {
      backgroundColor: isSelected
        ? theme.vars.palette.secondary.light
        : theme.vars.palette.secondary.dark,
    }),
    cursor: disableCursor ? 'default' : 'pointer',
    ...(isDisabled && { cursor: 'default', pointerEvents: 'none' }),

    ':hover': {
      backgroundColor: theme.vars.palette.secondary.lighter,
      ...theme.applyStyles('dark', {
        backgroundColor: theme.vars.palette.secondary.light,
      }),

      '#cell-hover-buttons': {
        opacity: 1,
        transition: 'opacity 0.25s linear',
      },
    },

    ':last-child': {
      td: {
        ':first-of-type': {
          borderBottomLeftRadius: '5px',
        },
        ':last-child': {
          borderBottomRightRadius: '5px',
        },
      },
    },
  }),
)

export default CommonTableRow
