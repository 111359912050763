import { Box, SxProps, Theme } from '@mui/material'

import { zIndex } from '@components/App/Styles/zIndex'

import { PopoverArrow, PopoverArrowProps } from './PopoverArrow'

const PopoverContentStyles = (theme: Theme) => ({
  backgroundColor: theme.vars.palette.common.white,
  border: `1px solid ${theme.vars.palette.secondary.main}`,
  borderRadius: '5px',
  boxShadow: 'rgb(0 0 0 / 35%) 0 5px 15px',
  minWidth: '200px',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.dark,
    border: `1px solid ${theme.vars.palette.text.secondary}`,
  }),
})

interface PopoverContentProps {
  children: React.ReactNode
  floatingStyles: React.CSSProperties
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>,
  ) => Record<string, unknown>
  setFloating: (node: HTMLElement | null) => void
  sx?: SxProps<Theme>
  /**
   * @description Optionally pass arrow props to render an arrow on the popover
   * @example
   * ### Import the arrow function
   * ```tsx
   * import { arrow } from '@floating-ui/react'
   * ```
   *
   * ### Set the floating ui config in the component which will render the popover
   * ```tsx
   * const arrowRef = useRef<SVGSVGElement>(null)
   * const { context } = useFloating({
   *   // ...other options
   *   middleware: [arrow({ element: arrowRef, padding: 8 })],  // Padding avoids the corners if the popover has a border-radius
   * })
   * ```
   *
   * ### Render the `PopoverContent` component with the arrow props
   * ```tsx
   * <PopoverContent
   *   // ...other props
   *   arrowProps={{ arrowRef, context }}
   * >
   *   { ... }
   * </PopoverContent>
   * ```
   */
  arrowProps?: PopoverArrowProps
}

const PopoverContent: React.FC<PopoverContentProps> = ({
  children,
  floatingStyles,
  getFloatingProps,
  setFloating,
  sx,
  arrowProps,
}) => {
  return (
    <Box
      data-testid="popover-content"
      style={floatingStyles}
      sx={{ zIndex: zIndex.POPOVER_CONTENT }}
      {...getFloatingProps()}
      ref={setFloating}
    >
      {arrowProps && <PopoverArrow {...arrowProps} />}
      <Box sx={[PopoverContentStyles, ...(Array.isArray(sx) ? sx : [sx])]}>
        {children}
      </Box>
    </Box>
  )
}

export default PopoverContent
