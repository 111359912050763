import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material'

import Icon from '@common/Icon'

interface AnswerDetailsProps {
  goBack: () => void
  question: string
  answer: string | JSX.Element
}

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
    whiteSpace: 'pre-line',
    textWrap: 'wrap',
  }),
})

const AnswerDetails = ({ goBack, question, answer }: AnswerDetailsProps) => {
  return (
    <Box
      sx={{
        overflowY: 'auto',
        padding: '16px',
      }}
      data-testid="cyber-answer-view"
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box>
          <IconButton
            onClick={goBack}
            sx={{ padding: '0px', paddingTop: '2px' }}
          >
            <Icon variant="arrowBack" size={20} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              ...typographyContent(theme),
              paddingLeft: '6px',
            })}
          >
            {question}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingTop: '28px' }}>
        {
          <Typography
            variant="body1"
            sx={(theme) => ({
              ...typographyContent(theme),
            })}
          >
            {answer}
          </Typography>
        }
      </Box>
    </Box>
  )
}

export default AnswerDetails
