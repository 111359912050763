import { MouseEvent, FocusEvent } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'
import { Box, Typography, Button, useTheme, Theme } from '@mui/material'

import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
  MitreCoverageTechnique,
} from '@models/DetectionCoverage'
import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../context/MitreOverviewContext'

export default function MitreCoverageTable() {
  return (
    <Box
      data-testid="mitre-coverage-table"
      sx={{
        height: '1035px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        minWidth: 'min-content',
      }}
    >
      <Tactics />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
        }}
      >
        <Techniques />
      </Box>
    </Box>
  )
}

// TODO - This component is still using `flushSync`. MUI components do not work well with this
// Update this to not use flushSync
function Tactics() {
  const {
    mitreSelection,
    setMitreSelection,
    setCarouselTechnique,
    coverage,
    handleTooltipEnter,
    handleTooltipLeave,
    handleSideSheet,
  } = useMitreOverviewContext()
  //need this because mui doesnt work with flushSync
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'
  const color = isLightMode
    ? theme.palette.text.primary
    : theme.palette.text.secondary

  const handleTacticEnter = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    mitreTacticName: string,
  ) => {
    const div = document.createElement('div')
    const root = createRoot(div)
    flushSync(() => {
      root.render(
        <Typography
          sx={{
            color,
          }}
          variant="caption"
        >
          {mitreTacticName}
        </Typography>,
      )
    })

    handleTooltipEnter(event, 4, div.innerHTML)
  }
  const handleTacticLeave = () => {
    handleTooltipLeave()
  }

  const handleTacticSelect = (tacticIndex: number) => {
    if (
      mitreSelection.tactic === tacticIndex &&
      mitreSelection.technique === undefined
    ) {
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: undefined,
      })

      return
    }
    setMitreSelection({
      tactic: tacticIndex,
      subtechnique: undefined,
      technique: undefined,
    })
    setCarouselTechnique(undefined)
  }

  const handleButtonClick = (tacticIndex: number) => {
    handleSideSheet(tacticIndex, undefined, undefined, () => {
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: mitreSelection.subtechnique,
      })
    })
    handleTacticSelect(tacticIndex)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        paddingBottom: '8px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        marginBottom: '8px',
        gap: '4px',
        ...theme.applyStyles('dark', {
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
        }),
      })}
    >
      {coverage.dw.map((tactic, tacticIndex) => (
        <Button
          sx={(theme) => ({
            all: 'unset',
            cursor: 'pointer',
            '&:focus-visible': {
              outline: '-webkit-focus-ring-color auto 1px',
            },
            flex: '1 1 0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.vars.palette.text.primary,
            fontSize: '11px',
            fontWeight: 400,
            width: 0,
            '&:hover, &:focus': {
              color: theme.vars.palette.secondary.dark,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            },
            ...(mitreSelection.tactic === tacticIndex &&
              mitreSelection.technique === undefined && {
                color: theme.vars.palette.secondary.dark,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              }),
          })}
          key={tactic.mitreTacticId}
          onMouseOver={(event) =>
            handleTacticEnter(event, tactic.mitreTacticName)
          }
          onFocus={(event) => handleTacticEnter(event, tactic.mitreTacticName)}
          onMouseOut={() => handleTacticLeave()}
          onBlur={() => handleTacticLeave()}
          onClick={() => handleButtonClick(tacticIndex)}
        >
          {tactic.mitreTacticName}
        </Button>
      ))}
    </Box>
  )
}

function Techniques() {
  const {
    setHasUserClosedSideSheet,
    mitreSelection,
    setCarouselTechnique,
    setMitreSelection,
    coverage,
    handleTooltipEnter,
    handleTooltipLeave,
    handleSideSheet,
    resetSelectionState,
    previousMitreSelection,
    setPreviousMitreSelection,
  } = useMitreOverviewContext()
  //need this because mui doesnt work with flushSync
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'

  const color = isLightMode
    ? theme.palette.text.primary
    : theme.palette.text.secondary
  const toolTipEnhanced = (
    technique: MitreCoverageTechnique,
    customer?: MitreCoverageCustomer,
  ) => {
    return (
      <>
        <Box
          data-testid="mitre-tooltip-divider"
          sx={(theme) => ({
            borderTop: `0.75px solid ${theme.palette.text.primary}`,
            marginTop: '3px',
            marginBottom: '2px',
          })}
        />
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              height: 'min-content',
              minWidth: 'min-content',
              alignItems: 'center',
              gap: '16px',
              flexGrow: '0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                height: 'min-content',
                minWidth: 'min-content',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Icon
                variant="pulse"
                size={15}
                sx={(theme) => ({ color: theme.palette.primary.light })}
              />
              <Typography sx={{ color }} variant="caption">
                {customer?.totalEligibleUseCaseCount ?? 0}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                height: 'min-content',
                minWidth: 'min-content',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Icon
                variant="cloudDoneOutline"
                size={15}
                sx={(theme) => ({ color: theme.palette.success.main })}
              />
              <Typography sx={{ color }} variant="caption">
                {customer?.totalEnabledUseCaseCount ?? 0}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                height: 'min-content',
                minWidth: 'min-content',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Icon
                variant="returnDownForwardSharp"
                size={15}
                sx={(theme) => ({ color: theme.palette.text.primary })}
              />
              <Typography sx={{ color }} variant="caption">
                {technique?.subtechniques.length ?? 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  const showTechniqueTooltip = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    tactic: MitreCoverageTactic,
    technique: MitreCoverageTechnique,
    customer: MitreCoverageCustomer | undefined,
    theme: Theme,
  ) => {
    //need this because mui doesnt work with flushSync
    const isLightMode = theme.palette.mode === 'light'
    const color = isLightMode
      ? theme.palette.text.primary
      : theme.palette.text.secondary
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Typography sx={{ color }} variant="caption">
              {tactic.mitreTacticName}
            </Typography>
            <Typography sx={{ color }} variant="caption">
              {tactic.mitreTacticId}
            </Typography>
          </Box>
          <Typography sx={{ color }} fontWeight={600} variant="caption">
            {technique.mitreTechniqueName}
          </Typography>
          {toolTipEnhanced(technique, customer)}
        </>,
      )
    })

    handleTooltipEnter(event, 2, div.innerHTML)
  }

  const handleTechniqueClick = (
    tacticIndex: number,
    techniqueIndex: number,
    technique: MitreCoverageTechnique,
  ) => {
    setHasUserClosedSideSheet(false)

    handleSideSheet(tacticIndex, techniqueIndex, undefined, () => {
      resetSelectionState(technique)
    })

    if (
      mitreSelection.tactic === tacticIndex &&
      mitreSelection.technique === techniqueIndex
    ) {
      // reset the carousel technique to undefined
      setCarouselTechnique(undefined)
      // reset the selection to undefined
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: mitreSelection.subtechnique,
      })
      // set the previous selection to the undefined
      setPreviousMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: mitreSelection.subtechnique,
      })

      return
    }

    // set the carousel technique to the selected technique
    setCarouselTechnique(technique.mitreTechniqueId)
    setMitreSelection({
      tactic: tacticIndex,
      technique: techniqueIndex,
      subtechnique: undefined,
    })
    // set the previous selection to the current selection
    setPreviousMitreSelection({
      tactic: tacticIndex,
      technique: techniqueIndex,
      subtechnique: undefined,
    })
  }

  return (
    <>
      {coverage.dw.map((tactic, tacticIndex) => (
        <Box
          key={tactic.mitreTacticId}
          sx={(theme) => ({
            padding: '2px',
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            flexDirection: 'column',
            gap: '8px',
            ...(mitreSelection.tactic === tacticIndex &&
              mitreSelection.technique === undefined && {
                border: `1px solid ${theme.palette.text.primary}`,
                padding: '1px',
                borderRadius: '2px',
                borderColor: theme.palette.primary.main,
              }),
          })}
        >
          {tactic.techniques.map((technique, techniqueIndex) => {
            const matchingCoverage = coverage.customer.find(
              (customerTechnique) =>
                customerTechnique.mitreTechniqueId ===
                technique.mitreTechniqueId,
            )

            const backgroundColor = isLightMode
              ? theme.palette.text.primary
              : theme.palette.text.secondary

            const coverageClass =
              matchingCoverage !== undefined &&
              matchingCoverage.totalEnabledUseCaseCount
                ? { background: backgroundColor }
                : { background: theme.palette.secondary.lighter }

            const customer = coverage.customer.find(
              (coverageCustomer) =>
                coverageCustomer.mitreTechniqueId ===
                technique.mitreTechniqueId,
            )

            return (
              <Button
                data-testid="mitre-coverage-technique"
                key={technique.mitreTechniqueId}
                aria-label={`${technique.mitreTechniqueName}`}
                onMouseOver={(event) =>
                  showTechniqueTooltip(
                    event,
                    tactic,
                    technique,
                    customer,
                    theme,
                  )
                }
                onFocus={(event) =>
                  showTechniqueTooltip(
                    event,
                    tactic,
                    technique,
                    customer,
                    theme,
                  )
                }
                onMouseOut={handleTooltipLeave}
                onBlur={handleTooltipLeave}
                sx={(theme) => ({
                  all: 'unset',
                  cursor: 'pointer',
                  '&:focus-visible': {
                    outline: '-webkit-focus-ring-color auto 1px',
                  },
                  height: '16px',
                  boxSizing: 'border-box',
                  ...(technique.isCovered
                    ? coverageClass
                    : {
                        border: `1px solid ${theme.palette.secondary.lighter}`,
                      }),

                  ...(previousMitreSelection.tactic === tacticIndex &&
                    previousMitreSelection.technique === techniqueIndex && {
                      boxShadow: `inset 0 0 0 3px ${theme.palette.primary.main}`,
                    }),
                  '&:hover, &:focus-visible': {
                    background: theme.palette.primary.main,
                  },
                  ...(mitreSelection.tactic === tacticIndex &&
                    mitreSelection.technique === undefined && {
                      color: theme.palette.text.secondary,
                    }),
                })}
                onClick={() =>
                  handleTechniqueClick(tacticIndex, techniqueIndex, technique)
                }
              />
            )
          })}
        </Box>
      ))}
    </>
  )
}
