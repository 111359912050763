import {
  Row,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useContext, useMemo, useState } from 'react'
import { Box, Button } from '@mui/material'

import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import TableHeader from '@common/Table/utils/TableHeader'
import { User } from '@models/User'
import { TableToolbar } from '@common/TableToolbar'
import { Context } from '@components/App'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'

import UserNameCell from './cells/UserNameCell'
import UserLoggedInCell from './cells/UserLoggedInCell'
import UserStatusCell from './cells/UserStatusCell'

interface UserManagementTableProps {
  handleActivateUser: (user: User) => void
  handleDeactivateUser: (user: User) => void
  handleReactivateUser: (user: User) => void
  handleDeleteUser: (user: User) => void
  handleEditUser: (user: User) => void
  handleAddUser: () => void
  loading: boolean
  users: User[]
}

export const USER_MANAGEMENT_TABLE_ID_PREFIX = 'user-management-table-row-'

const UserManagementSettingsTable: React.FC<UserManagementTableProps> = ({
  handleActivateUser,
  handleDeactivateUser,
  handleReactivateUser,
  handleDeleteUser,
  handleEditUser,
  handleAddUser,
  loading,
  users,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'userName',
      desc: false,
    },
  ])

  const {
    state: { customer },
  } = useContext(Context)

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<User>()
    return [
      {
        id: 'userName',
        accessorFn: (user: User) => ({
          firstName: user.firstName,
          lastName: user.lastName,
          userIsAdmin: user.isAdmin,
        }),
        cell: UserNameCell,
        header: () => buildGenericHeader('Name'),
        sortingFn: (rowA: Row<User>, rowB: Row<User>) => {
          const rowAName = rowA.original.firstName.toLowerCase()
          const rowBName = rowB.original.firstName.toLowerCase()

          if (rowAName < rowBName) {
            return -1
          }

          if (rowBName < rowAName) {
            return 1
          }

          return 0
        },
      },
      columnHelper.accessor('email', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('Email'),
      }),
      columnHelper.accessor('oktaStatus', {
        cell: UserStatusCell,
        header: () => buildGenericHeader('Status'),
      }),
      {
        id: 'lastLogin',
        accessorFn: (user: User) => ({
          userLoggedIn: user.lastLogin,
          userOktaStatus: user.oktaStatus,
        }),
        cell: (props) => (
          <UserLoggedInCell
            cellContext={props}
            handleActivateUser={handleActivateUser}
            handleDeactivateUser={handleDeactivateUser}
            handleReactivateUser={handleReactivateUser}
            handleDeleteUser={handleDeleteUser}
            handleEditUser={handleEditUser}
          />
        ),
        header: () => buildGenericHeader('Last Logged In'),
        sortingFn: (rowA: Row<User>, rowB: Row<User>) => {
          const rowADate = new Date(rowA.original.lastLogin!).valueOf()
          const rowBDate = new Date(rowB.original.lastLogin!).valueOf()

          return rowADate - rowBDate
        },
      },
    ]
  }, [
    handleActivateUser,
    handleDeactivateUser,
    handleDeleteUser,
    handleEditUser,
    handleReactivateUser,
  ])

  const table = useReactTable({
    columns: columnDef,
    data: users,
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    state: {
      sorting,
    },
    getRowId: (row) => String(row.id),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <Box sx={{ width: '100%' }}>
      <TableToolbar
        loading={loading}
        dataLength={users.length}
        singularEntityName={'user'}
        pluralEntityName={'users'}
      >
        <Box>
          {customer.settings !== undefined &&
            !customer.settings?.isOktaFederated && (
              <Button onClick={handleAddUser} variant="contained">
                Add User
              </Button>
            )}
        </Box>
      </TableToolbar>

      <CommonTableContainer>
        <CommonTable cellSpacing={0} data-testid="user-management-table">
          <thead data-testid="user-management-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                data-testid="user-management-table-header"
              >
                {headerGroup.headers.map((header) => (
                  <TableHeader<User> key={header.id} header={header} />
                ))}
              </tr>
            ))}
          </thead>

          <tbody data-testid="user-management-table-body">
            {loading
              ? buildSkeletonRows(table.getAllColumns())
              : table
                  .getRowModel()
                  .rows.map((row) => (
                    <MemoizedTanStackTableRow
                      key={row.id}
                      rowId={`${USER_MANAGEMENT_TABLE_ID_PREFIX}${row.id}`}
                      testId="user-management-table-row"
                      isSelected={row.getIsSelected()}
                      visibleCells={row.getVisibleCells()}
                    />
                  ))}
          </tbody>
        </CommonTable>
      </CommonTableContainer>
    </Box>
  )
}

export default UserManagementSettingsTable
