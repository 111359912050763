import { UserInfo } from '@models/User'

/**
 * simple hook to return correctly forammtted initials for the Avatar component
 * @param UserInfo
 * @param UserInfo.firstName
 * @param UserInfo.lastName
 * @returns string
 */
export const useAvatarInitials = ({
  firstName,
  lastName,
}: UserInfo): string => {
  const initials = `${firstName.charAt(0).toUpperCase() ?? ''}${
    lastName?.charAt(0).toUpperCase() ?? ''
  }`

  return initials.length > 0 ? initials : '--'
}
