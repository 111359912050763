import { Box, Typography } from '@mui/material'

import { ThreatIntelReport } from '@models/ThreatIntel'

import ThreatIntelReportsAdvisoryCard from '../ThreatIntelReportsAdvisoryCard'
import { useThreatIntelReportsContext } from '../../context'

interface ThreatIntelReportsHeaderProps {
  advisories: ThreatIntelReport[]
}

export const ThreatIntelReportsHeader: React.FC<
  ThreatIntelReportsHeaderProps
> = ({ advisories }) => {
  const { activeReportId, openSideSheet } = useThreatIntelReportsContext()

  if (!advisories) {
    return (
      <Typography
        color="textPrimary"
        fontWeight={500}
        variant="body1"
        sx={{ margin: '40px auto' }}
      >
        We were unable to fetch data, try reloading the page.
      </Typography>
    )
  }

  return (
    <Box
      id="threat-intel-card-container"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1rem',
      }}
    >
      {advisories.length ? (
        advisories.map((advisory) => (
          <ThreatIntelReportsAdvisoryCard
            key={advisory.id}
            advisory={advisory}
            onClick={openSideSheet}
            isActive={activeReportId === advisory.id}
          />
        ))
      ) : (
        <Typography
          color="textSecondary"
          fontWeight={600}
          variant="body2"
          sx={{ margin: '40px auto' }}
        >
          No recent Significant Cyber Events or Customer Advisories
        </Typography>
      )}
    </Box>
  )
}
