import { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Tooltip, Typography } from '@mui/material'

import {
  GET_CUSTOMER_INFO_WITH_TA_VERSIONS,
  CustomerInfoData,
  CustomerInfoVariables,
} from '@queries/customer'
import { Context } from '@components/App'
import { Loader } from '@common/Loader'

const initialTaVersionState = {
  customerDwaAppVersion: '-',
  deepwatchLatestDwaAppVersion: '-',
}

export const versionColor = (customerVersion?: string, dwVersion?: string) => {
  if (customerVersion && dwVersion) {
    if (customerVersion >= dwVersion) {
      return 'textPrimary'
    } else {
      return 'warning'
    }
  }
}

const TaVersions = (): JSX.Element => {
  const {
    state: {
      customer: { customerShortName },
      dwExpertsCustomer: { customerShortName: dwExpertCustomerShortName },
    },
  } = useContext(Context)

  const {
    data: { customerInfo } = { initialTaVersionState },
    error,
    loading,
  } = useQuery<CustomerInfoData, CustomerInfoVariables>(
    GET_CUSTOMER_INFO_WITH_TA_VERSIONS,
    {
      variables: {
        selectedCustomer:
          customerShortName === '' ? dwExpertCustomerShortName : null,
      },
    },
  )

  if (loading) {
    return (
      <Box
        data-testid="taversions-container"
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '1rem',
          lineHeight: '1rem',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.main,
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            padding: '0 5px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          })}
          data-testid="taversions-loading"
        >
          <Loader strokeWidth={1} />
        </Box>
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        data-testid="taversions-container"
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '1rem',
          lineHeight: '1rem',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.main,
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            padding: '0 5px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <Tooltip title={'Error fetching DWA versions'} placement="top-start">
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.vars.palette.warning.dark,
              })}
            >
              Error
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      data-testid="taversions-container"
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem',
        lineHeight: '1rem',
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.secondary.main,
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          padding: '0 5px',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <Tooltip
          title={
            "DWA is Deepwatch's content version. If you'd like to update, contact your Deepwatch experts."
          }
          placement="top-start"
        >
          <Typography variant="body2" color="textPrimary">
            DWA:
          </Typography>
        </Tooltip>
        <Tooltip title={'Your DWA version'} placement="top-start">
          <Typography variant="body2" color="textPrimary">
            {customerInfo && customerInfo.customerDwaAppVersion
              ? `v${customerInfo.customerDwaAppVersion}`
              : 'n/a'}
          </Typography>
        </Tooltip>
        <Typography variant="body2" color="textPrimary">
          •
        </Typography>
        <Tooltip title={'Latest DWA version'} placement="top-start">
          <Typography variant="body2" color="textPrimary">
            {customerInfo && `v${customerInfo.deepwatchLatestDwaAppVersion}`}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default TaVersions
