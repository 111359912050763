import { Box, IconButton, Typography } from '@mui/material'

import Icon from '@common/Icon'
import { BetaTag } from '@common/BetaTag'

import { CyberAssistantView } from '../CyberAssistant'

interface HeaderProps {
  onClose: () => void
  viewType: CyberAssistantView
  onToggleView: () => void
}

const CyberAssistantHeader: React.FC<HeaderProps> = ({
  onClose,
  viewType,
  onToggleView,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
        ...theme.applyStyles('dark', {
          borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
        }),
      })}
      data-testid="cyber-assistant-header"
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          variant="h6"
          sx={(theme) => ({
            paddingRight: '10px',
            paddingLeft: '8px',
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          Cyber Assistant
        </Typography>
        <BetaTag />
      </Box>
      <Box>
        <IconButton
          aria-label={`${viewType === 'knowledgeBase' ? 'chatbox' : 'knowledge base'}`}
          onClick={() => onToggleView()}
        >
          {viewType === 'search' ? (
            <Icon
              variant="book"
              size={24}
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
              })}
            />
          ) : (
            <Icon
              variant="chatbox"
              size={24}
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
              })}
            />
          )}
        </IconButton>
        <IconButton aria-label="close" onClick={onClose}>
          <Icon
            variant="close"
            size={24}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
          />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CyberAssistantHeader
