import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  DetailedTicketType,
  TicketStatsAggregationOptions,
} from '@models/Tickets'
import {
  TICKET_STATS,
  TicketStatItem,
  TicketStatsResponse,
  TicketStatsVariables,
} from '@queries/ticket'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import { useCustomer } from '@hooks/useCustomer'
import { Paths } from '@components/App/Types'

interface TicketOverviewBansProps {
  selectedCustomer: string
}

const TicketOverviewBans = ({ selectedCustomer }: TicketOverviewBansProps) => {
  const initialState: TicketStatItem[] = [
    { key: DetailedTicketType.SECURITY, count: 0 },
    { key: DetailedTicketType.DETECTION_ENGINEERING, count: 0 },
    { key: DetailedTicketType.OPS_ENGINEERING, count: 0 },
    { key: DetailedTicketType.THREAT_HUNT, count: 0 },
  ]

  const {
    data: { ticketStats: { data: statItems } } = {
      ticketStats: { data: initialState },
    },
    loading,
  } = useQuery<TicketStatsResponse, TicketStatsVariables>(TICKET_STATS, {
    variables: {
      selectedCustomer: selectedCustomer,
      input: {
        aggregateBy: TicketStatsAggregationOptions.ASSIGNMENT_GROUP,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 120000, // 2 minutes
  })

  const { featureTicketOverviewBans } = useFlags()
  const { search: currentCustomer } = useLocation()
  const { isDWEmployee } = useCustomer()
  const navigate = useNavigate()
  const isLoading = loading && statItems.every((item) => item.count === 0)

  const openSecurityItem = statItems.find(
    (item) => item.key === DetailedTicketType.SECURITY,
  )

  const openDetectionEngItem = statItems.find(
    (item) => item.key === DetailedTicketType.DETECTION_ENGINEERING,
  )

  const openOpsEngItem = statItems.find(
    (item) => item.key === DetailedTicketType.OPS_ENGINEERING,
  )

  const threatHuntItem = statItems.find(
    (item) => item.key === DetailedTicketType.THREAT_HUNT,
  )

  const handleTicketLibraryNav = (
    ticketUrlPath: string,
    newParamFilters: string,
  ) => {
    if (featureTicketOverviewBans) {
      const currParams = new URLSearchParams(currentCustomer)
      const newUrlPath: string = isDWEmployee
        ? `${ticketUrlPath}?${currParams}&selectedFilters={${newParamFilters}}`
        : `${ticketUrlPath}?selectedFilters={${newParamFilters}}`
      navigate(newUrlPath)
    }
  }

  return (
    <Box
      data-testid="ticket-overview-bans"
      sx={{
        display: 'flex',
        gap: '1rem',
        gridColumn: '1 / 3',
      }}
    >
      <Ban
        data-testid="open-security-ban"
        iconVariant="ticketOutline"
        onClick={() =>
          handleTicketLibraryNav(
            `${Paths.TICKET_LIBRARY}`,
            `"assignmentGroup":["${DetailedTicketType.SECURITY}"]`,
          )
        }
        sx={
          featureTicketOverviewBans
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <BanLayoutCommon
          error={openSecurityItem == null}
          loading={isLoading}
          title="Open Security"
          value={openSecurityItem?.count.toString() ?? 'Not applicable'}
        />
      </Ban>

      <Ban
        data-testid="open-detection-ban"
        iconVariant="ticket"
        onClick={() =>
          handleTicketLibraryNav(
            `${Paths.TICKET_LIBRARY}`,
            `"assignmentGroup":["${DetailedTicketType.DETECTION_ENGINEERING}"]`,
          )
        }
        sx={
          featureTicketOverviewBans
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <BanLayoutCommon
          error={openDetectionEngItem == null}
          loading={isLoading}
          title="open detection engineering"
          value={openDetectionEngItem?.count.toString() ?? 'Not applicable'}
        />
      </Ban>

      <Ban
        data-testid="ops-engineering-ban"
        iconVariant="shieldOutline"
        onClick={() =>
          handleTicketLibraryNav(
            `${Paths.TICKET_LIBRARY}`,
            `"assignmentGroup":["${DetailedTicketType.OPS_ENGINEERING}"]`,
          )
        }
        sx={
          featureTicketOverviewBans
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <BanLayoutCommon
          error={openOpsEngItem == null}
          loading={isLoading}
          title="Open Ops Engineering"
          value={openOpsEngItem?.count.toString() ?? 'Not applicable'}
        />
      </Ban>

      <Ban
        data-testid="threat-hunts-ban"
        iconVariant="locate"
        onClick={() =>
          handleTicketLibraryNav(
            `${Paths.TICKET_LIBRARY}`,
            `"assignmentGroup":["${DetailedTicketType.THREAT_HUNT}"]`,
          )
        }
        sx={
          featureTicketOverviewBans
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <BanLayoutCommon
          error={threatHuntItem == null}
          loading={isLoading}
          title="Threat Hunts"
          value={threatHuntItem?.count.toString() ?? 'Not applicable'}
        />
      </Ban>
    </Box>
  )
}

export default TicketOverviewBans
