import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table'
import { RefObject, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { formatDistanceToNowStrict } from 'date-fns'
import { Box, Button, Typography, useTheme } from '@mui/material'

import { useOutsideElementClick } from '@hooks/useOutsideElementClick'
import { formatPriority } from '@utils/Common'
import { ErrorLabel } from '@common/ErrorLabel'
import { TICKETS_OLDER_14_DAYS } from '@queries/ticket'
import { Paths } from '@components/App/Types'
import TicketIdCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketIdCell'
import TicketPriorityCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketPriorityCell'
import TicketLastUpdatedCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketLastUpdatedCell'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import CommonTableRow from '@common/Table/components/CommonTableRow'
import { invertColumnSortDirection } from '@utils/tableUtils'

import {
  Ticket,
  TicketRelatedUnion,
  TicketSortingInput,
} from '../../../../models'
import {
  buildGenericHeader,
  buildSkeletonRows,
} from '../../../../common/Table/utils/table.utils'
import TableHeader from '../../../../common/Table/utils/TableHeader'
import { handleOnRowSelectionChange } from '../TicketOverview'

interface Tickets14DaysOldTableProps {
  selectedCustomer?: string
  onTicketSelected?: (ticket: Ticket | undefined) => void
}

const Tickets14DaysOldTable: React.FC<Tickets14DaysOldTableProps> = ({
  onTicketSelected,
  selectedCustomer,
}) => {
  const theme = useTheme()

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'sysCreatedOn',
      desc: true,
    },
  ])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const formattedSorting = invertColumnSortDirection<TicketRelatedUnion>(
    sorting,
    ['sysCreatedOn', 'sysUpdatedOn'],
  ).reduce<TicketSortingInput>(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur.desc ? 'DESC' : 'ASC',
    }),
    {},
  )

  const {
    data: { getTickets: { pagination, tickets } } = {
      getTickets: { pagination: { total: 0 }, tickets: [] },
    },
    error,
    loading,
  } = useQuery(TICKETS_OLDER_14_DAYS, {
    variables: {
      selectedCustomer: selectedCustomer,
      selectedSortings: formattedSorting,
      pagination: {
        limit: 5,
        offset: 0,
      },
    },
    pollInterval: 120000, // two minutes
  })

  const navigate = useNavigate()

  const columnDef: ColumnDef<TicketRelatedUnion, any>[] = useMemo(() => {
    const columnHelper = createColumnHelper<TicketRelatedUnion>()
    return [
      {
        id: 'ticketNumber',
        accessorFn: (row) => ({
          ticketNumber: row.ticketNumber,
          shortDescription: row.shortDescription,
        }),
        cell: TicketIdCell,
        header: () => buildGenericHeader('Ticket ID'),
        minSize: 100,
      },
      columnHelper.accessor('sysCreatedOn', {
        id: 'sysCreatedOn',
        cell: (props) =>
          props.getValue() ? (
            <Typography
              noWrap
              variant="body2"
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {formatDistanceToNowStrict(new Date(props.getValue()))}
            </Typography>
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Age'),
        size: 90,
      }),
      {
        id: 'priority',
        accessorFn: (row) => formatPriority(row.priority, theme),
        cell: (props) => <TicketPriorityCell {...props} />,
        header: () => buildGenericHeader('Priority'),
        size: 140,
      },
      columnHelper.accessor('state', {
        id: 'state',
        cell: (props) =>
          props.getValue() ? (
            <div className="ticket-status-cell">
              <Typography
                noWrap
                variant="body2"
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              >
                {props.getValue()}
              </Typography>
            </div>
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Status'),
        size: 120,
      }),
      columnHelper.accessor('sysUpdatedOn', {
        id: 'sysUpdatedOn',
        cell: (props) =>
          props.getValue() ? (
            <TicketLastUpdatedCell {...props} />
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Last Updated'),
        size: 130,
      }),
    ]
  }, [])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<TicketRelatedUnion>({
    columns: columnDef,
    data: tickets,
    getRowId: (row) => row.sysId,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
    manualSorting: true,
    manualFiltering: true,
    state: {
      rowSelection,
      sorting,
      columnSizing,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: (valueFn) =>
      handleOnRowSelectionChange(
        valueFn,
        rowSelection,
        setRowSelection,
        tickets,
        onTicketSelected,
      ),
    onColumnSizingChange,
    columnResizeMode: 'onChange',
    enableSortingRemoval: false,
  })

  const tbodyRef = useOutsideElementClick(() =>
    setRowSelection({}),
  ) as RefObject<HTMLTableSectionElement>

  const handleViewAllTicketsClick = () => {
    if (selectedCustomer) {
      navigate(
        `${Paths.TICKET_LIBRARY}?customer=${selectedCustomer}&selectedFilters={${'"sysCreatedOn":["2 weeks"]'}}`,
      )
    } else {
      navigate(
        `${Paths.TICKET_LIBRARY}?selectedFilters={${'"sysCreatedOn":["2 weeks"]'}}`,
      )
    }
  }

  const renderContent = () => {
    if (error) {
      return (
        <CommonTableRow tabIndex={0} isSelected={false}>
          <StyledTableCell
            align="center"
            colSpan={columnDef.length}
            style={{ border: 0, padding: '2rem 0' }}
          >
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{ paddingBottom: '1rem' }}
            >
              No data.
            </Typography>

            <Typography color="textPrimary" variant="body1">
              There are too many data points that are incomplete or missing.
              Please check again later.
            </Typography>
          </StyledTableCell>
        </CommonTableRow>
      )
    }

    // tickets length check is if Apollo cache has values already
    if (loading && !tickets.length) {
      return buildSkeletonRows(table.getAllColumns(), 5)
    }

    if (!loading && !tickets.length) {
      return (
        <CommonTableRow key="empty-state" tabIndex={0} isSelected={false}>
          <StyledTableCell align="center" colSpan={columnDef.length}>
            <Typography color="textPrimary" variant="body2">
              You have no tickets older than 14 days.
            </Typography>
          </StyledTableCell>
        </CommonTableRow>
      )
    }

    return table
      .getRowModel()
      .rows.map((row) => (
        <MemoizedTanStackTableRow
          key={row.id}
          rowId={`tickets-older-than-14-days-table-row-${row.id}`}
          testId="tickets-older-than-14-days-table-row"
          isSelected={row.getIsSelected()}
          toggleSelected={row.toggleSelected}
          visibleCells={row.getVisibleCells()}
          linkTo={`/tickets/library/${row.original.sysId}/edit`}
        />
      ))
  }

  return (
    <>
      <Typography fontWeight={600} variant="body1" sx={{ padding: '1rem 0' }}>
        Tickets older than 14 days (
        {loading && !tickets.length ? '--' : pagination.total})
      </Typography>

      <CommonTableContainer ref={tableContainerRef}>
        <CommonTable cellSpacing={0}>
          <thead data-testid="tickets-14-days-old-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<TicketRelatedUnion>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          <tbody ref={tbodyRef} data-testid="tickets-14-days-old-table-body">
            {renderContent()}
          </tbody>
        </CommonTable>
      </CommonTableContainer>

      {!error && pagination.total > 5 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            onClick={handleViewAllTicketsClick}
            sx={{ marginTop: '1rem' }}
          >
            View all tickets
          </Button>
        </Box>
      )}
    </>
  )
}

export default Tickets14DaysOldTable
