import { gql } from '@apollo/client'

import { OktaGroup, Teammate, User } from '@models/index'

export interface GetAcceptedTermsData {
  getAcceptedTerms: boolean
}

export interface GetCustomerGroupsData {
  getCustomerGroups: Array<OktaGroup>
}

export interface GetUserGroupsData {
  getUsersGroupMembership: Array<OktaGroup>
}

export interface GetUserData {
  getUser: User
}

export interface GetUsersData {
  getUsers: Array<User>
}

export interface MyTeamData {
  myTeam: Array<Teammate>
}

export interface GetAcceptedTermsVariables {
  acceptedVersion: string
}

export interface GetUserGroupsVariables {
  input: {
    userId: string
  }
}

export interface GetUserVariables {
  input: {
    userId: string
  }
}

export interface GetUsersVariables {
  filter: {
    oktaStatus: string
  }
}

export const GET_USER_GROUPS = gql`
  query GetUserGroups($input: GetGroupMembershipInput!) {
    getUsersGroupMembership(input: $input) {
      groupId
      groupName
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($filter: GetUsersFilterInput = null) {
    getUsers(input: $filter) {
      email
      firstName
      id
      isAdmin
      lastLogin
      lastName
      oktaStatus
      title
    }
  }
`

export const GET_USER = gql`
  query GetUser($input: GetGroupMembershipInput!) {
    getUser(input: $input) {
      email
      firstName
      id
      isAdmin
      lastLogin
      lastName
      oktaStatus
      title
    }
  }
`

export const MY_TEAM = gql`
  query MyTeam($selectedCustomer: String) {
    myTeam(selectedCustomer: $selectedCustomer) {
      email
      firstName
      lastName
      title
    }
  }
`

export const GET_USER_MANAGEMENT_SETTINGS = gql`
  query GetUserManagementSettings {
    getUsers {
      email
      firstName
      id
      isAdmin
      lastLogin
      lastName
      oktaStatus
      title
    }
    getCustomerGroups {
      groupId
      groupName
    }
  }
`

export const GET_ACCEPTED_TERMS = gql`
  query GetAcceptedTerms($acceptedVersion: String!) {
    getAcceptedTerms(input: { acceptedVersion: $acceptedVersion })
  }
`
