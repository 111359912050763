import { isValidElement } from 'react'
import { Box, List, ListItemText, Typography } from '@mui/material'

import { SidesheetDualColumnValues } from './SidesheetDualColumn'

interface ColumnElementProps extends SidesheetDualColumnValues {}

const ColumnElement: React.FC<ColumnElementProps> = ({ label, value }) => {
  let valueElement = isValidElement(value) ? (
    value
  ) : (
    <Typography
      sx={(theme) => ({
        color: theme.palette.text.primary,
        ...theme.applyStyles('dark', { color: theme.palette.text.secondary }),
      })}
      variant="body2"
    >
      {value}
    </Typography>
  )

  if (Array.isArray(value) && value.every((val) => typeof val === 'string')) {
    valueElement = (
      <List sx={{ listStyleType: 'disc', gap: 0, padding: 0 }}>
        {value.map((val) => (
          <ListItemText
            key={val}
            sx={{
              display: 'list-item',
              listStylePosition: 'inside',
            }}
          >
            <Typography
              sx={(theme) => ({
                display: 'inline',
                marginLeft: -1,
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {val.replace(/:/g, ':\u200B').replace(/_/g, '_\u200B')}
            </Typography>
          </ListItemText>
        ))}
      </List>
    )
  }

  return (
    <Box sx={{ display: 'flex', gap: '1.5rem' }} data-testid="column-element">
      <Box sx={{ width: '165px', wordBreak: 'break-word' }}>
        <Typography fontWeight={500} variant="body2">
          {label}
        </Typography>
      </Box>
      <Box>{valueElement}</Box>
    </Box>
  )
}

export default ColumnElement
