import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { EDR_OVERVIEW_BANS, EDRBanResponse } from '@queries/medr'
import { Paths } from '@components/App/Types'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import LicenseTotal from '@common/LicenseTotal'
import { Delta } from '@common/Delta'

const EDRBans = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { search: currentCustomer } = useLocation()
  const navigate = useNavigate()

  const {
    data: {
      alertsToContainment,
      containedAssets,
      escalatedAlerts,
      licenseUtilization,
      openTickets,
      rfmSensors,
    } = {
      alertsToContainment: undefined,
      containedAssets: undefined,
      licenseUtilization: undefined,
      openTickets: undefined,
      rfmSensors: undefined,
    },
    error,
    loading,
  } = useQuery<EDRBanResponse>(EDR_OVERVIEW_BANS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer,
    },
  })

  const onOpenTicketsClick = () => {
    const filters = JSON.stringify({
      module: ['NG-MEDR'],
    })

    const value = currentCustomer
      ? `${Paths.TICKET_LIBRARY}${currentCustomer}&selectedFilters=${filters}`
      : `${Paths.TICKET_LIBRARY}?selectedFilters=${filters}`

    return navigate(value)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gap: '0.5rem',
      }}
    >
      <Ban
        data-testid="edr-license-utilization-ban"
        iconVariant="fileTrayStackedOutline"
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && licenseUtilization === undefined}
          title="ENDPOINTS"
          value={
            <LicenseTotal
              current={licenseUtilization?.current}
              total={licenseUtilization?.total}
            />
          }
        />
      </Ban>

      <Ban
        data-testid="open-tickets-ban"
        iconVariant="ticketOutline"
        onClick={onOpenTicketsClick}
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && openTickets === undefined}
          title="Current open tickets"
          value={openTickets?.total?.toString() ?? '0'}
        />
      </Ban>

      <Ban
        data-testid="contained-assets-ban"
        iconVariant="eyeOutline"
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && containedAssets === undefined}
          title="Contained Assets"
          value={containedAssets?.total?.toString() ?? 'Not applicable'}
        >
          {containedAssets?.delta != null && (
            <Delta delta={Math.round(containedAssets.delta * 100)} suffix="%" />
          )}
        </BanLayoutCommon>
      </Ban>

      <Ban
        data-testid="escalated-alerts-ban"
        iconVariant="ticketOutline"
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && escalatedAlerts === undefined}
          title="Escalated alerts"
          value={
            escalatedAlerts?.total != null
              ? `${Math.round(escalatedAlerts?.total * 100)}%`
              : 'No alerts'
          }
        >
          {escalatedAlerts?.delta != null && (
            <Delta delta={Math.round(escalatedAlerts.delta * 100)} suffix="%" />
          )}
        </BanLayoutCommon>
      </Ban>

      <Ban
        data-testid="alerts-to-containment-ban"
        iconVariant="banOutline"
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && alertsToContainment === undefined}
          title="Alerts led to containment"
          value={
            alertsToContainment?.total != null
              ? `${Math.round(alertsToContainment?.total * 100)}%`
              : 'No alerts'
          }
        >
          {alertsToContainment?.delta != null && (
            <Delta
              delta={Math.round(alertsToContainment.delta * 100)}
              suffix="%"
            />
          )}
        </BanLayoutCommon>
      </Ban>

      <Ban
        data-testid="rfm-sensors-ban"
        iconVariant="barChartOutline"
        sx={{ flex: '0 0 33.3333%' }}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading && rfmSensors === undefined}
          title="RFM Sensors"
          value={rfmSensors?.total?.toString() ?? 'Not applicable'}
        >
          {rfmSensors?.delta != null && (
            <Delta delta={Math.round(rfmSensors.delta * 100)} suffix="%" />
          )}
        </BanLayoutCommon>
      </Ban>
    </Box>
  )
}

export default EDRBans
