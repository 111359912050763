import { CellContext } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Typography } from '@mui/material'

import { ChangeHistoryEventTableItem } from '@models/SecurityIndex'

const CreatedAtCell = (
  props: CellContext<ChangeHistoryEventTableItem, string>,
) => {
  const createdAt = new Date(`${props.getValue()}T00:00`)

  const formattedDate =
    createdAt.getFullYear() < new Date().getFullYear()
      ? format(createdAt, 'MMM do, yyyy')
      : format(createdAt, 'MMM do')

  return (
    <Typography
      variant="body2"
      sx={(theme) => ({
        color: theme.vars.palette.text.primary,
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.text.secondary,
        }),
      })}
    >
      {formattedDate}
    </Typography>
  )
}

export default CreatedAtCell
