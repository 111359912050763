import { useMostRecentValue } from './useMostRecentValue'
import {
  UseRowSelectionArgs,
  UseRowSelectionReturn,
  useRowSelection,
} from './useRowSelection'

interface UseSelectedRowReturn<TRow>
  extends Pick<
    UseRowSelectionReturn<TRow>,
    'rowSelection' | 'setRowSelection'
  > {
  /** The currently selected row, or `undefined` if no row is selected */
  selectedRow: TRow | undefined
  /** The most recently selected row, or `undefined` if no row has been selected, useful for maintaining prior data during transition states (like opening/closing a sidesheet) */
  previouslySelectedRow: TRow | undefined
}

/**
 * Hook to help manage TanStack table row selection state, especially for a single-row selection (like in a table/sidesheet pattern)
 * @param root0
 * @param root0.data
 * @param root0.rowIdKey
 */
export function useSelectedRow<TRow>({
  data,
  rowIdKey,
}: UseRowSelectionArgs<TRow>): UseSelectedRowReturn<TRow> {
  const { rowSelection, setRowSelection, selectedRows } = useRowSelection({
    data,
    rowIdKey,
  })
  const selectedRow = selectedRows.length > 0 ? selectedRows[0] : undefined // Explicitly check that the selectedRows array is not empty to provide a more accurate type for selectedRow
  const previouslySelectedRow = useMostRecentValue(selectedRow)
  return { rowSelection, setRowSelection, selectedRow, previouslySelectedRow }
}
