/* eslint-disable security/detect-object-injection */
import { BarDatum, BarTooltipProps } from '@nivo/bar'
import { Box, Typography, useTheme } from '@mui/material'

import { CrowdStrikeTicketPriorityEnum } from '@models/Tickets'
import Icon from '@common/Icon'

interface EDRChartItem {
  hostname: string
  criticalCount: number
  highCount: number
  mediumCount: number
  lowCount: number
  informationalCount: number
}

const TopAssetsByAlertsTooltip: React.FC<BarTooltipProps<BarDatum>> = (
  point,
) => {
  const theme = useTheme()

  const data = point.data as unknown as EDRChartItem

  const priorityColorMapping = {
    [CrowdStrikeTicketPriorityEnum.Critical]:
      theme.vars.palette.severity.critical,
    [CrowdStrikeTicketPriorityEnum.High]: theme.vars.palette.severity.high,
    [CrowdStrikeTicketPriorityEnum.Medium]: theme.vars.palette.severity.medium,
    [CrowdStrikeTicketPriorityEnum.Low]: theme.vars.palette.severity.low,
    [CrowdStrikeTicketPriorityEnum.Informational]:
      theme.vars.palette.severity.info,
  }

  const getDataPoint = (status: string, count: number) => (
    <Box
      data-testid="data-point"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        padding: '0.25rem',
      }}
    >
      <Icon
        size={12}
        variant="square"
        sx={{
          color: priorityColorMapping[status],
        }}
      />

      <Typography
        color="textPrimary"
        sx={{
          display: 'flex',
          gap: 0.5,
        }}
        variant="caption"
      >
        <Typography fontWeight={600} variant="caption">
          {count}
        </Typography>{' '}
        {status}
      </Typography>
    </Box>
  )

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        borderRadius: '5px',
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.darker,
          border: `1px solid ${theme.vars.palette.secondary.main}`,
        }),
      })}
    >
      <Typography sx={{ padding: '0.25rem' }} variant="caption">
        {data.hostname}
      </Typography>
      <Box
        component="hr"
        sx={{
          height: '1px',
          border: 0,
          borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
          width: '100%',
        }}
      />
      {data.criticalCount > 0 &&
        getDataPoint(
          CrowdStrikeTicketPriorityEnum.Critical,
          data.criticalCount,
        )}
      {data.highCount > 0 &&
        getDataPoint(CrowdStrikeTicketPriorityEnum.High, data.highCount)}
      {data.mediumCount > 0 &&
        getDataPoint(CrowdStrikeTicketPriorityEnum.Medium, data.mediumCount)}
      {data.lowCount > 0 &&
        getDataPoint(CrowdStrikeTicketPriorityEnum.Low, data.lowCount)}
      {data.informationalCount > 0 &&
        getDataPoint(
          CrowdStrikeTicketPriorityEnum.Informational,
          data.informationalCount,
        )}
    </Box>
  )
}

export default TopAssetsByAlertsTooltip
