import { ErrorCard } from '@common/ErrorCard'
import { SideSheet, SideSheetProps } from '@common/SideSheet'

export const TicketLibrarySideSheetFallback: React.FC<
  Omit<SideSheetProps, 'children'>
> = (props) => {
  return (
    <SideSheet {...props}>
      <ErrorCard />
    </SideSheet>
  )
}
