/* eslint-disable security/detect-object-injection */
import React, { useRef, useState } from 'react'
import { SingleValue } from 'react-select'
import { useQuery } from '@apollo/client'
import {
  autoPlacement,
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Box, IconButton, Typography } from '@mui/material'

import { colors } from '@design-system/theme'
import { Dropdown, OptionType } from '@common/Dropdown'
import { MttxDimension, MttxMetric, MttxSegment } from '@models/MttxDetails'
import {
  GET_TICKET_DATA,
  GetMttxChartData,
  MTTX_CHART,
  MttxChartVariables,
} from '@queries/mttx'
import { TicketPriorityEnum, TicketTypeEnum } from '@models/Tickets'
import ChartDownloadMenu from '@common/ChartDownloadMenu'
import Icon from '@common/Icon'

import { MTTXBar } from './MTTXBar/MTTXBar'
import MTTXScatter from './MTTXScatter/MTTXScatter'
import { MTTXLine } from './MTTXLine/MTTXLine'

interface MTTXDataVisualizationProps {
  availableDimensions: MttxDimension[]
  availableSegments: MttxSegment[]
  selectedDimension?: SingleValue<OptionType>
  selectedSegment?: SingleValue<OptionType>
  setSelectedDimension: React.Dispatch<
    React.SetStateAction<SingleValue<OptionType>>
  >
  setSelectedSegment: React.Dispatch<
    React.SetStateAction<SingleValue<OptionType> | undefined>
  >
  priority: string
  selectedCustomer: string | null
  startDate: string
  endDate: string
  metric: MttxMetric
  ticketType?: string
}

const MTTXDataVisualization: React.FC<MTTXDataVisualizationProps> = ({
  availableDimensions,
  availableSegments,
  selectedDimension,
  selectedSegment,
  setSelectedDimension,
  setSelectedSegment,
  priority,
  selectedCustomer,
  startDate,
  endDate,
  metric,
  ticketType,
}) => {
  const DIMENSION_LABEL_MAPPING = {
    [MttxDimension.CREATED_DATE]: 'Created date',
    [MttxDimension.TICKET_PRIORITY]: 'Ticket priority',
    [MttxDimension.TICKET_TYPE]: 'Ticket type',
  }

  const SEGMENT_LABEL_MAPPING = {
    [MttxSegment.TICKET_PRIORITY]: 'Ticket priority',
    [MttxSegment.TICKET_TYPE]: 'Ticket type',
  }

  const isCreatedDateAndTicketType =
    selectedDimension?.value === MttxDimension.CREATED_DATE &&
    selectedSegment?.value === MttxSegment.TICKET_TYPE

  const {
    data: { mttxChart } = { mttxChart: { data: [] } },
    loading,
    error,
  } = useQuery<GetMttxChartData, MttxChartVariables>(MTTX_CHART, {
    variables: {
      selectedCustomer,
      input: {
        dimension: selectedDimension!.value,
        metric,
        segment:
          selectedSegment && selectedSegment.value
            ? selectedSegment.value
            : undefined,
        startDate,
        endDate,
        ticketPriority: TicketPriorityEnum[priority],
        ticketType: ticketType && TicketTypeEnum[ticketType],
      },
    },
    skip: isCreatedDateAndTicketType,
    fetchPolicy: 'cache-and-network',
  })

  const chartRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(8), autoPlacement()],
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const securityInput = {
    dimension: selectedDimension!.value,
    metric,
    segment: selectedSegment?.value,
    startDate,
    endDate,
    ticketPriority: TicketPriorityEnum[priority],
    ticketType: [TicketTypeEnum.Security],
  }

  const engineeringInput = {
    dimension: selectedDimension!.value,
    metric,
    segment: selectedSegment?.value,
    startDate,
    endDate,
    ticketPriority: TicketPriorityEnum[priority],
    ticketType: [TicketTypeEnum.Engineering],
  }

  const {
    data: newData,
    loading: newLoading,
    error: newError,
  } = useQuery<GetMttxChartData, MttxChartVariables>(GET_TICKET_DATA, {
    variables: {
      selectedCustomer,
      securityInput,
      engineeringInput,
    },
    skip: !isCreatedDateAndTicketType,
    fetchPolicy: 'cache-and-network',
  })

  const isEmpty =
    mttxChart.data.length === 0 &&
    (isCreatedDateAndTicketType
      ? newData?.securityTickets.data.length === 0 &&
        newData?.engineeringTickets.data.length === 0
      : true)

  const dimensionOptions: OptionType[] = availableDimensions.map(
    (dimension) => ({
      value: dimension,
      label: DIMENSION_LABEL_MAPPING[dimension],
    }),
  )

  const segmentOptions: OptionType[] = [
    {
      value: '',
      label: 'No value',
      color: colors.util.one.light,
    },
  ]

  availableSegments.forEach((segment) => {
    segmentOptions.push({
      value: segment,
      label: SEGMENT_LABEL_MAPPING[segment],
    })
  })

  const handleDimensionChange = (newValue: SingleValue<OptionType>) => {
    // Reset segment to 'No value' when dimension is changed to 'Ticket priority'
    if (
      newValue?.value === MttxDimension.TICKET_PRIORITY ||
      newValue?.value === MttxDimension.TICKET_TYPE
    ) {
      setSelectedSegment(segmentOptions[0])
    }

    setSelectedDimension(newValue)
  }

  const handleSegmentChange = (newValue: SingleValue<OptionType>) => {
    setSelectedSegment(newValue)
  }

  const renderChart = () => {
    if (selectedDimension?.value === MttxDimension.CREATED_DATE) {
      if (selectedSegment?.value === MttxSegment.TICKET_PRIORITY) {
        return (
          <MTTXScatter
            data={mttxChart.data}
            loading={loading && !mttxChart.data.length}
            priority={priority}
            startDate={startDate}
            endDate={endDate}
          />
        )
      }
      if (selectedSegment?.value === MttxSegment.TICKET_TYPE) {
        return (
          <MTTXLine
            securityData={
              ticketType === 'All' || ticketType === TicketTypeEnum.Security
                ? newData?.securityTickets.data
                : []
            }
            engineeringData={
              ticketType === 'All' || ticketType === TicketTypeEnum.Engineering
                ? newData?.engineeringTickets.data
                : []
            }
            loading={newLoading}
            priority={priority}
            error={newError !== undefined}
            metric={metric}
            dimension={selectedDimension.value}
            segment={selectedSegment.value}
            ticketType={ticketType}
          />
        )
      }
      return (
        <MTTXLine
          data={mttxChart.data}
          loading={loading && !mttxChart.data.length}
          priority={priority}
          metric={metric}
          dimension={selectedDimension.value}
        />
      )
    }

    if (selectedDimension?.value === MttxDimension.TICKET_TYPE) {
      return (
        <MTTXBar
          data={mttxChart.data}
          loading={loading && !mttxChart.data.length}
          error={error !== undefined}
          priority={priority}
        />
      )
    }

    if (selectedDimension?.value === MttxDimension.TICKET_PRIORITY) {
      return (
        <MTTXBar
          data={mttxChart.data}
          loading={loading && !mttxChart.data.length}
          error={error !== undefined}
          priority={priority}
        />
      )
    }
  }

  const metricTextMapping = {
    [MttxMetric.MTTN]: 'Time to notify',
    [MttxMetric.MTTCAck]: 'Customer acknowledgement',
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '0.5rem',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', gap: '1.5rem' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0,
              gap: '0.5rem',
              maxWidth: '100px',
              width: '100%',
            }}
            data-testid="metric-column"
          >
            <Typography fontWeight={500} variant="body2">
              Metric
            </Typography>
            <Typography noWrap variant="body2">
              {metricTextMapping[metric]}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              width: '100%',
            }}
            data-testid="dimension-column"
          >
            <Typography fontWeight={500} variant="body2">
              Dimension
            </Typography>
            <Dropdown
              defaultValue={dimensionOptions[0]}
              isDisabled={
                (loading && !dimensionOptions.length) ||
                error !== undefined ||
                isEmpty
              }
              options={dimensionOptions}
              onChange={handleDimensionChange}
              value={selectedDimension}
              id="dimension-dropdown"
            />
          </Box>

          <Box
            data-testid="segment-column"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <Typography fontWeight={500} variant="body2">
              Segment
            </Typography>
            <Dropdown
              defaultValue={segmentOptions[0]}
              isDisabled={
                (loading && !segmentOptions.length) ||
                error !== undefined ||
                selectedDimension?.value === MttxDimension.TICKET_PRIORITY ||
                selectedDimension?.value === MttxDimension.TICKET_TYPE ||
                isEmpty
              }
              onChange={handleSegmentChange}
              options={segmentOptions}
              value={selectedSegment}
              id="segment-dropdown"
            />
          </Box>
        </Box>
        <Box
          data-testid="export-mttx-chart"
          sx={{
            display: 'flex',
            alignItems: 'end',
            paddingLeft: '0.5rem',
          }}
        >
          <IconButton
            disabled={loading || isEmpty}
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <Icon
              sx={(theme) => ({
                color:
                  (loading && !mttxChart.data.length) || isEmpty
                    ? theme.vars.palette.text.disabled
                    : theme.vars.palette.text.primary,
              })}
              variant="downloadOutline"
            />
          </IconButton>

          {isOpen && (
            <FloatingFocusManager context={context}>
              <ChartDownloadMenu
                handleOutsideClick={() => setIsOpen(false)}
                data={mttxChart.data}
                fields={[
                  'averageDuration',
                  'date',
                  'duration',
                  'priority',
                  'ticketId',
                  'totalTickets',
                  'ticketType',
                  'sysId',
                ]}
                floatingStyles={floatingStyles}
                getFloatingProps={getFloatingProps}
                setFloating={refs.setFloating}
                chartRef={chartRef}
              />
            </FloatingFocusManager>
          )}
        </Box>
      </Box>
      <Box ref={chartRef}>{renderChart()}</Box>
    </>
  )
}

export default MTTXDataVisualization
