/* eslint-disable security/detect-object-injection */
import { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Box, Chip, useTheme } from '@mui/material'

import { SideSheet } from '@common/SideSheet'
import { formatChipLabel } from '@common/FilterChips'
import Icon from '@common/Icon'

import EDRAssetTable from './EDRAssetTable'
import EDRAssetFilterSideSheet, {
  EDRAssetFiltersForm,
} from './EDRAssetFilterSideSheet'

const EDRAssetLibrary = () => {
  const theme = useTheme()
  const [filters, setFilters] = useState<EDRAssetFiltersForm>({
    hostname: [],
    lastReboot: [],
    lastSeen: [],
    operatingSystem: [],
    platform: [],
    rfmStatus: [],
    status: [],
  })

  const [showFilterSideSheet, setShowFilterSideSheet] = useState(false)

  const toggleFilterSideSheet = () => {
    setShowFilterSideSheet(!showFilterSideSheet)
  }

  const handleFilterChange = (values: EDRAssetFiltersForm) => {
    setFilters(values)
  }

  const deleteChip = (id: string, val: string) => {
    const filterCopy = cloneDeep(filters)
    filterCopy[id] = filterCopy[id].filter((v: string) => v !== val)

    setFilters(filterCopy)
  }

  const renderChips = () => {
    const chips: JSX.Element[] = []

    Object.keys(filters).forEach((key) => {
      if (filters[key].length > 0) {
        const filterValues = filters[key]

        filterValues.forEach((val: string) => {
          chips.push(
            <Chip
              data-testid={`filter-chip-edr-asset`}
              label={formatChipLabel(key, val)}
              onDelete={() => deleteChip(key, val)}
              deleteIcon={
                <div>
                  <Icon
                    variant="closeCircle"
                    size={20}
                    sx={{ color: theme.palette.secondary.dark }}
                  />
                </div>
              }
            />,
          )
        })
      }
    })

    return chips
  }

  return (
    <Box
      data-testid="edr-asset-library"
      sx={{ height: 'calc(100% - 118px)', padding: '1rem', width: '100%' }}
    >
      <SideSheet
        open={showFilterSideSheet}
        title="Filtering options"
        closeSideSheet={toggleFilterSideSheet}
      >
        <EDRAssetFilterSideSheet
          selectedFilters={filters}
          handleFilterChange={handleFilterChange}
          toggleSidesheet={toggleFilterSideSheet}
        />
      </SideSheet>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        {renderChips()}
      </Box>

      <EDRAssetTable
        filters={filters}
        showFilterSidesheet={toggleFilterSideSheet}
      />
    </Box>
  )
}

export default EDRAssetLibrary
