import ReactMarkdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import { List, ListItem, ListItemText, Typography, Box } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

import {
  MitreCoverageCustomer,
  MitreCoverageTechnique,
  MitreOverviewDetection,
} from '@models/DetectionCoverage'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'
import { DetectionCatalogStatusTag } from '@components/Coverage/DetectionCatalog/DetectionCatalogStatusTag'
import {
  formatSideSheetDetectionStatus,
  formatStatus,
} from '@components/Coverage/DetectionCatalog/DetectionCatalog.utils'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import {
  DETECTIONS,
  DetectionsData,
  DetectionsVariables,
} from '@queries/detection'
import { Detections } from '@models/Detections'
import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../context/MitreOverviewContext'

interface TechniqueDetailProps {
  customerTacticTechniques: MitreCoverageCustomer[]
  tacticName: string
  technique: MitreCoverageTechnique
}

const TechniqueDetail = ({
  customerTacticTechniques,
  tacticName,
  technique,
}: TechniqueDetailProps) => {
  const {
    handleSideSheet,
    setDetections,
    setFocusedDetection,
    resetSelectionState,
  } = useMitreOverviewContext()
  const { featureMitreCoverageSidesheetDetections } = useFlags()
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const customerTechnique = customerTacticTechniques.find(
    (x) => x.mitreTechniqueId === technique.mitreTechniqueId,
  )

  const techniqueDetails = {
    title: technique.mitreTechniqueName,
    description: technique.mitreTechniqueDescription,
    totalEligibleUseCaseCount: customerTechnique
      ? customerTechnique.totalEligibleUseCaseCount
      : 0,
    totalEnabledUseCaseCount: customerTechnique
      ? customerTechnique.totalEnabledUseCaseCount
      : 0,
    totalUseCaseCount: technique.totalUseCaseCount,
    id: technique.mitreTechniqueId,
  }

  const {
    data: { detections: { detections } } = {
      detections: {
        detections: [],
      },
    },
    loading,
    error,
  } = useQuery<DetectionsData, DetectionsVariables>(DETECTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: [
        {
          id: 'mitreTechniques',
          value: [customerTechnique?.mitreTechniqueName],
        },
      ],
      keywordSearch: '',
      pagination: {
        limit: 100,
        offset: 0,
      },
      selectedCustomer,
      sorting: {
        id: 'releaseVersion',
        desc: true,
      },
    },
  })

  const dualColumnItems: SidesheetDualColumnValues[] = [
    {
      key: 'Eligible detections',
      label: (
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            variant="pulse"
            size={18}
            sx={(theme) => ({
              color: theme.vars.palette.primary.light,
              paddingRight: '5px',
            })}
          />
          <Typography color="textPrimary" variant="body2" component="span">
            Eligible detections
          </Typography>
        </Box>
      ),
      value: techniqueDetails.totalEligibleUseCaseCount,
    },
    {
      key: 'Enabled detections',
      label: (
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            size={18}
            sx={(theme) => ({
              color: theme.palette.success.main,
              paddingRight: '5px',
            })}
            variant="cloudDoneOutline"
          />
          <Typography color="textPrimary" variant="body2" component="span">
            Enabled detections
          </Typography>
        </Box>
      ),
      value: techniqueDetails.totalEnabledUseCaseCount,
    },
    {
      key: 'Sub-techniques',
      label: (
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            size={18}
            sx={(theme) => ({
              color: theme.palette.text.primary,
              paddingRight: '5px',
            })}
            variant="returnDownForwardSharp"
          />
          <Typography variant="body2" color="textPrimary" component="span">
            Sub-techniques
          </Typography>
        </Box>
      ),
      value:
        technique.subtechniques.length > 0
          ? technique.subtechniques.map((val) => val.mitreSubtechniqueName)
          : 'None',
    },
    {
      key: 'MITRE tactic',
      label: 'MITRE tactic',
      value: tacticName,
    },
    {
      key: 'ID',
      label: 'ID',
      value: techniqueDetails.id,
    },
    {
      key: 'Required permissions',
      label: 'Required permissions',
      value: technique.permissionList,
    },
    {
      key: 'Platforms',
      label: 'Platforms',
      value: technique.platformList,
    },
  ]

  const sortDetectionByStatus = (
    detections: Detections[] | MitreOverviewDetection[],
  ) => {
    //sort detections by name and status
    return detections.sort((a, b) => {
      const status1 = formatSideSheetDetectionStatus(a.status)
      const status2 = formatSideSheetDetectionStatus(b.status)
      //sort by status
      if (status1.sortValue < status2.sortValue) {
        return -1
      }
      if (status1.sortValue > status2.sortValue) {
        return 1
      }
      //sort by title
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    })
  }

  const handleDetectionClick = (selectedDetection) => {
    setFocusedDetection(selectedDetection)
    handleSideSheet(
      0,
      undefined,
      undefined,
      () => {
        resetSelectionState(technique)
      },
      selectedDetection,
    )
  }

  useEffect(() => {
    if (detections.length > 0 && !loading) {
      const sortedDetections = sortDetectionByStatus([...detections])
      setDetections(sortedDetections)
    }
  }, [detections, loading, setDetections])

  const renderDetections = (): JSX.Element => {
    if (technique.associatedDetections.length > 0) {
      sortDetectionByStatus(technique.associatedDetections)

      return (
        <>
          <List>
            {technique.associatedDetections.map((detection) => {
              const status = formatStatus(detection.status)
              const selectedDetection =
                detections.find((item) => item.title === detection.title) ??
                ({} as Detections)
              return (
                <ListItem
                  key={detection.title}
                  {...(detections &&
                    !loading &&
                    !error && {
                      onClick() {
                        handleDetectionClick(selectedDetection)
                      },
                    })}
                >
                  <ListItemText
                    primary={detection.title}
                    sx={{
                      width: '70%',
                      textWrap: 'nowrap',
                    }}
                  />
                  <ListItemText
                    sx={{
                      textAlign: 'right',
                      margin: 'auto 10px auto auto',
                      '.tag-new > .MuiTypography-h5': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    <DetectionCatalogStatusTag status={status.status} />
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
        </>
      )
    }
    return (
      <Typography variant="body2" color="textPrimary">
        {'This technique has no associated detections'}
      </Typography>
    )
  }

  return (
    <>
      <Box
        data-testid="technique-description"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          padding: '1rem',
        }}
      >
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          fontWeight={500}
          variant="body2"
          component="span"
        >
          {techniqueDetails.title}
        </Typography>
        <Typography
          sx={(theme) => ({ '> p': { color: theme.palette.text.primary } })} //override App.scss
          variant="body2"
          component="span"
        >
          <ReactMarkdown
            rehypePlugins={[
              [
                rehypeExternalLinks,
                {
                  target: '_blank',
                  rel: ['noopener', 'noreferrer'],
                },
              ],
            ]}
          >
            {techniqueDetails.description}
          </ReactMarkdown>
        </Typography>
      </Box>
      <Box sx={{ padding: '0px' }}>
        <CollapsiblePanel title={'Details'}>
          <SidesheetDualColumn items={dualColumnItems} />
        </CollapsiblePanel>

        {featureMitreCoverageSidesheetDetections && (
          <CollapsiblePanel
            title="Detections"
            count={technique.associatedDetections.length}
          >
            {renderDetections()}
          </CollapsiblePanel>
        )}
      </Box>
    </>
  )
}

export default TechniqueDetail
