/* eslint-disable security/detect-object-injection */
/* eslint-disable @getify/proper-ternary/nested */
import { format } from 'date-fns'
import { lighten, Box, Theme, Typography, useTheme } from '@mui/material'

import { ReportType, ThreatIntelReport } from '@models/ThreatIntel'
import { RelatedThreat } from '@models/Detections'
import { handleKeyboardAction } from '@utils/index'
import Icon from '@common/Icon'

export interface ThreatIntelReportsAdvisoryCardProps {
  advisory: ThreatIntelReport | RelatedThreat
  onClick: (id: string) => void
  isActive?: boolean
  disabled?: boolean
}

export const ThreatIntelReportsAdvisoryCard: React.FC<
  ThreatIntelReportsAdvisoryCardProps
> = ({ advisory, onClick, isActive, disabled }) => {
  const theme = useTheme()

  const { createdDate, markAsRead, title, type } = advisory

  const AdivsoryCardIcon = {
    [ReportType.CYBER_INTEL_BRIEF]: {
      icon: markAsRead ? 'documentTextOutline' : 'reader',
    },
  }

  const matchingType = AdivsoryCardIcon[type] ?? {
    icon: markAsRead ? 'customerAdvisoryRead' : 'customerAdvisoryUnread',
  }

  const IconStyles = (theme: Theme) => {
    if (type === ReportType.CUSTOMER_ADVISORY) {
      return {
        color: theme.vars.palette.severity.medium,
        ...theme.applyStyles('dark', {
          color: theme.palette.severity.medium
            ? lighten(theme.palette.severity.medium, 0.45)
            : undefined,
        }),
      }
    }

    return {
      color: theme.vars.palette.text.primary,
      ...theme.applyStyles('dark', {
        color: theme.vars.palette.text.secondary,
      }),
    }
  }

  return (
    <Box
      data-testid="advisory-card"
      onClick={() => onClick(advisory.id)}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(keyboardEvent) => {
        handleKeyboardAction(keyboardEvent, () => onClick(advisory.id))
      }}
      sx={{
        backgroundColor: isActive
          ? theme.vars.palette.secondary.main
          : theme.vars.palette.secondary.light,
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        padding: '0.5rem',
        minWidth: 0,

        ':hover': {
          backgroundColor: theme.vars.palette.secondary.lighter,
        },

        ...theme.applyStyles('dark', {
          backgroundColor: isActive
            ? theme.vars.palette.secondary.lighter
            : theme.vars.palette.secondary.main,
          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          flexWrap: 'none',
        }}
      >
        <Icon size={18} variant={matchingType.icon} sx={IconStyles} />
        <Typography
          fontWeight={markAsRead ? 400 : 600}
          variant="body1"
          sx={{
            color: theme.vars.palette.text.primary,
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box>
        <div data-testid="report-meta">
          {createdDate && (
            <Typography color="textPrimary" variant="caption">
              {format(new Date(createdDate), 'MMM do')}&nbsp;&bull;&nbsp;
            </Typography>
          )}
          <Typography variant="caption" color="textPrimary">
            {type.valueOf()}
          </Typography>
        </div>
        <div className="report-icons" data-testid="report-icons">
          {!!advisory.relatedTickets?.length && (
            <span
              className="flex align-center gap-4"
              data-testid="related-tickets-count"
            >
              <Icon size={18} variant="ticketOutline" />
              <Typography color="textSecondary" variant="body2">
                {advisory.relatedTickets?.length}
              </Typography>
            </span>
          )}
        </div>
      </Box>
    </Box>
  )
}
