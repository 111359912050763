import {
  Box,
  Typography,
  useTheme,
  Tooltip,
  LinearProgress,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Icon from '@common/Icon'
import { linearProgressBarColor } from '@utils/linearProgressBarColor'

export type topLogSourcesProps = {
  title: string
  titleTooltip?: string
  data?: {
    label: string
    value: number | string
    progress: number
  }[]
}

export const getTextColor = (
  progressNum: number,
  isLightMode: boolean,
): string => {
  if (progressNum >= 90) return 'error'
  else if (progressNum >= 50) return 'warning'
  else return isLightMode ? 'textPrimary' : 'textSecondary'
}

const TopLogSources = ({
  title,
  titleTooltip,
  data,
}: topLogSourcesProps): JSX.Element => {
  const theme = useTheme()
  const { featureDwLicense } = useFlags()

  const isLightMode = theme.palette.mode === 'light'
  const topLogSources = data?.map((datum, index) => {
    const getTooltipMessage = () => {
      if (title === 'Top 5 source types by utilization') {
        return (
          <span>
            {datum.label} accounts for {datum.progress.toFixed(2)}% of your{' '}
            {featureDwLicense ? 'Deepwatch license' : 'SIEM usage'}.
          </span>
        )
      } else {
        return (
          <span>
            {datum.label} accounts for {datum.progress.toFixed(2)}% of your
            alerts.
          </span>
        )
      }
    }

    const breakPoints = [
      {
        threshold: 50,
        color: theme.vars.palette.warning.main,
      },
      {
        threshold: 90,
        color: theme.vars.palette.error.light,
      },
    ]

    return (
      <Tooltip
        title={getTooltipMessage()}
        key={datum.label}
        placement="top-start"
        followCursor
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            gap: '8px',
            flexDirection: 'column',
          }}
          data-testid="detection-coverage-top-source-types"
          key={index}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              height: 'min-content',
              minWidth: 'min-content',
              justifyContent: 'space-between',
            }}
            tabIndex={0}
            role={'group'}
          >
            <Typography
              color={getTextColor(datum.progress, isLightMode)}
              noWrap={true}
              sx={{
                display: 'block',
                textOverflow: 'ellipsis',
                maxWidth: 200,
              }}
              variant="body1"
            >
              {datum.label}
            </Typography>
            <Typography
              color={getTextColor(datum.progress, isLightMode)}
              variant="body1"
            >
              {datum.value}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={datum.progress}
            color={linearProgressBarColor(breakPoints, datum.progress, theme)}
            sx={(theme) => ({
              background: `linear-gradient(to right, ${linearProgressBarColor(breakPoints, datum.progress, theme)} ${datum.progress}% ,${theme.vars.palette.secondary.light} ${
                datum.progress - 1
              }%)`,
            })}
          />
        </Box>
      </Tooltip>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        gap: '16px',
        flexDirection: 'column',
      }}
      data-testid="top-source-types"
    >
      {titleTooltip ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color="textPrimary" variant="h6">
            {title}
          </Typography>
          <Tooltip title={titleTooltip} placement="top-start" followCursor>
            <span>
              <Icon
                sx={{ marginLeft: '.5rem' }}
                size={18}
                variant="informationCircleOutline"
              />
            </span>
          </Tooltip>
        </Box>
      ) : (
        <Typography color="textPrimary" variant="h6">
          {title}
        </Typography>
      )}
      {topLogSources}
    </Box>
  )
}

export default TopLogSources
