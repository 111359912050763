import { lighten, Theme } from '@mui/material'

import { TicketPriorityEnum, TicketTypeEnum } from '@models/Tickets'

export const chartColors = (
  theme: Theme,
  priority: string,
  ticketType?: string,
) => {
  const ticketPriorityColors = {
    [TicketPriorityEnum.Critical]: theme.palette.severity.critical,
    [TicketPriorityEnum.High]: theme.palette.severity.high,
    [TicketPriorityEnum.Moderate]: theme.palette.severity.medium,
    [TicketPriorityEnum.Low]: theme.palette.severity.low,
    [TicketPriorityEnum.Informational]: theme.palette.severity.info,
    All: theme.palette.primary.main,
  }

  const valueMatch =
    ticketPriorityColors[priority] ?? theme.palette.primary.main

  if (ticketType && ticketType === TicketTypeEnum.Security) {
    return lighten(valueMatch, 0.5)
  }

  return valueMatch
}

export const nivoTheme = (theme: Theme) => ({
  axis: {
    ticks: {
      text: {
        fill: theme.vars.palette.text.primary,
      },
      line: {
        strokeWidth: 0,
      },
    },

    domain: {
      line: {
        stroke: theme.vars.palette.secondary.main,
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 0,
    },
  },
})

export const priorityOrder = [
  TicketPriorityEnum.Critical.valueOf(),
  TicketPriorityEnum.High.valueOf(),
  TicketPriorityEnum.Moderate.valueOf(),
  TicketPriorityEnum.Low.valueOf(),
  TicketPriorityEnum.Informational.valueOf(),
  TicketTypeEnum.Security.valueOf(),
  TicketTypeEnum.Engineering.valueOf(),
]
