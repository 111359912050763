import { SideSheet } from '@common/SideSheet'
import { Detections } from '@models/Detections'

import DetectionSideSheetContent from './DetectionSideSheetContent'

interface DetectionSideSheetProps {
  isOpen: boolean
  closeSideSheet: VoidFunction
  focusedDetection: Detections
}

const DetectionCatalogSideSheet = ({
  isOpen,
  closeSideSheet,
  focusedDetection,
}: DetectionSideSheetProps) => {
  return (
    <SideSheet
      data-testid={`detection-catalog-sidesheet-${isOpen ? 'open' : 'closed'}`}
      title="Detection Details"
      open={isOpen}
      closeSideSheet={closeSideSheet}
    >
      <DetectionSideSheetContent
        detection={focusedDetection}
        isOpen={isOpen}
        data-testid="detection-catalog-content"
      />
    </SideSheet>
  )
}

export default DetectionCatalogSideSheet
