import { ColumnDef, ColumnSizingState, Header } from '@tanstack/react-table'
import { ForwardedRef, MutableRefObject } from 'react'

const DEFAULT_MIN_COLUMN_WIDTH = 70

export class ResizableColumnsUtils<T> {
  /** The index of the column being resized, in the array of columns from left to right; defaults to `0` */
  private columnBeingResizedIndex: number
  private debugSteps: Array<Record<string, Record<string, unknown>>> = []
  /**
   * @param {ColumnDef<any, unknown>[]} columnDef The column definitions for the TanStack table
   * @param {ColumnSizingState} columnSizing The column sizing state to be modified, after the drag event has been applied; will be mutated by the class methods
   * @param {ColumnSizingState} columnSizingBeforeResize The initial sizing state of the columns before the resize started
   * @param {number} tableWidth The full width of the table before resizing columns
   * @param {Header<any, unknown>} [headerBeingResized] The header being resized
   */
  constructor(
    private readonly columnDef: ColumnDef<T, unknown>[],
    private columnSizing: ColumnSizingState,
    private readonly columnSizingBeforeResize: ColumnSizingState,
    private readonly tableWidth: number,
    private readonly headerBeingResized?: Header<T, unknown>,
  ) {
    const headerBeingResizedId = this.headerBeingResized?.id
    this.columnBeingResizedIndex = headerBeingResizedId
      ? this.columnDef.findIndex((column) => column.id === headerBeingResizedId)
      : 0
    this.debugSteps.push({ initialState: this.columnSizing })
  }

  /**
   * @description Gets the total of all current column widths
   * @returns {number} The total width of all columns
   */
  private get totalColumnWidth(): number {
    return Object.values(this.columnSizing).reduce(
      (acc, width) => acc + width,
      0,
    )
  }

  /**
   * @description Updates the internal column sizing state, records the step in the debug history, and returns the updated column sizing state
   * @param {ColumnSizingState} columnSizing The new column sizing state
   * @param {string} label The label to use in the debug history
   * @returns {ColumnSizingState} The updated column sizing state
   */
  private setColumnSizing(
    columnSizing: ColumnSizingState,
    label: string,
  ): ColumnSizingState {
    this.columnSizing = columnSizing
    this.debugSteps.push({ [label]: this.columnSizing })
    return this.columnSizing
  }

  public get modifiedColumnSizing() {
    return this.columnSizing
  }

  /**
   * @description Ensure all columns are at or above their minimum width; used to ensure min-width on the left most columns when narrowing a column
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public ensureMinWidth(): ColumnSizingState {
    const newColumnSizing = Object.entries(this.columnSizing).reduce(
      (acc, [columnId, width]) => {
        const column = this.columnDef.find((column) => column.id === columnId)
        return {
          ...acc,
          [columnId]: Math.max(
            width,
            column?.minSize ?? DEFAULT_MIN_COLUMN_WIDTH,
          ),
        }
      },
      {},
    )
    return this.setColumnSizing(newColumnSizing, 'ensureMinWidth')
  }

  /**
   * @description Shrink columns to the right when the column width is being increased
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public subtractWidthFromSubsequentColumns(): ColumnSizingState {
    let amountToSubtract = this.totalColumnWidth - this.tableWidth

    if (amountToSubtract <= 0) {
      return this.columnSizing
    }

    const newColumnSizing = Object.entries(this.columnSizing).reduce(
      (acc, [columnId, width]) => {
        const columnIndex = this.columnDef.findIndex(
          (column) => column.id === columnId,
        )
        const columnDefinition = this.columnDef.at(columnIndex)
        const columnIsToRightOfResizedColumn =
          columnIndex > this.columnBeingResizedIndex
        const minWidth = columnDefinition?.minSize ?? DEFAULT_MIN_COLUMN_WIDTH
        const columnIsAboveMinWidth = width > minWidth
        // Subtract from the column if it's not at min width, and all prior columns are at min width
        if (
          columnIsToRightOfResizedColumn &&
          amountToSubtract > 0 &&
          columnIsAboveMinWidth
        ) {
          const amountAvailableToSubtract = width - minWidth
          const amountToSubtractFromThisColumn = Math.min(
            amountAvailableToSubtract,
            amountToSubtract,
          )
          amountToSubtract -= amountToSubtractFromThisColumn
          return {
            ...acc,
            [columnId]: width - amountToSubtractFromThisColumn,
          }
        }
        // Default to the same width
        return {
          ...acc,
          [columnId]: width,
        }
      },
      {},
    )
    return this.setColumnSizing(
      newColumnSizing,
      'subtractWidthFromSubsequentColumns',
    )
  }

  /**
   * @description Allow columns to the right to grow when the column width is being decreased
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public addWidthToSubsequentColumns(): ColumnSizingState {
    const underflow = this.tableWidth - this.totalColumnWidth

    if (underflow <= 0) {
      return this.columnSizing
    }

    let amountToAdd = underflow

    const updatedSizing = Object.entries(this.columnSizing).reduceRight(
      (acc, [columnId, width]) => {
        const columnIndex = this.columnDef.findIndex(
          (column) => column.id === columnId,
        )
        const columnIsToRightOfResizedColumn =
          columnIndex > this.columnBeingResizedIndex
        const maxWidth =
          columnIndex === this.columnBeingResizedIndex + 1
            ? Number.MAX_SAFE_INTEGER // Allow the adjacent column to grow past its initial width
            : this.columnSizingBeforeResize[
                columnId as keyof typeof this.columnSizingBeforeResize
              ]
        const columnIsBelowMaxWidth = width < maxWidth
        // Add to the column if it's not at max width, and all prior columns are at max width
        if (
          columnIsToRightOfResizedColumn &&
          amountToAdd > 0 &&
          columnIsBelowMaxWidth
        ) {
          const amountAvailableToAdd = maxWidth - width
          const amountToAddToThisColumn = Math.min(
            amountAvailableToAdd,
            amountToAdd,
          )
          amountToAdd -= amountToAddToThisColumn
          return {
            ...acc,
            [columnId]: width + amountToAddToThisColumn,
          }
        }
        // Default to the same width
        return {
          ...acc,
          [columnId]: width,
        }
      },
      {},
    )

    const newColumnSizing = Object.entries(updatedSizing).reduceRight(
      (acc, [columnId, width]) => {
        return {
          ...acc,
          [columnId]: width,
        }
      },
      {},
    )

    return this.setColumnSizing(newColumnSizing, 'addWidthToSubsequentColumns')
  }

  /**
   * @description Adds width the the prior column if the table is smaller than the sum of the column widths.
   * Currently only used to ensure the final column can shrink if necessary when its size is reset.
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public addWidthToPriorColumn(): ColumnSizingState {
    const underflow = this.tableWidth - this.totalColumnWidth

    if (underflow <= 0) {
      return this.columnSizing
    }

    const updatedSizing = Object.entries(this.columnSizing).reduce(
      (acc, [columnId, width]) => {
        const columnIndex = this.columnDef.findIndex(
          (column) => column.id === columnId,
        )
        const columnIsToLeftOfResizedColumn =
          columnIndex === this.columnBeingResizedIndex - 1
        return {
          ...acc,
          [columnId]: width + (columnIsToLeftOfResizedColumn ? underflow : 0),
        }
      },
      {},
    )

    return this.setColumnSizing(updatedSizing, 'addWidthToPriorColumn')
  }

  /**
   * @description If the size of the table would overflow its original width, subtract the extra width from the resized column
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public preventTableOverflow(): ColumnSizingState {
    const overflow = this.totalColumnWidth - this.tableWidth

    if (overflow <= 0) {
      return this.columnSizing
    }

    let remainingOverflowToSubtract = overflow

    const newColumnSizing = Object.entries(this.columnSizing).reduce(
      (acc, [columnId, width]) => {
        const columnIndex = this.columnDef.findIndex(
          (column) => column.id === columnId,
        )
        if (
          remainingOverflowToSubtract > 0 &&
          columnIndex >= this.columnBeingResizedIndex
        ) {
          const column = this.columnDef.find((column) => column.id === columnId)
          const amountToSubtract = Math.min(
            remainingOverflowToSubtract,
            width - (column?.minSize ?? DEFAULT_MIN_COLUMN_WIDTH),
          )
          remainingOverflowToSubtract -= amountToSubtract
          return {
            ...acc,
            [columnId]: width - amountToSubtract,
          }
        }
        return {
          ...acc,
          [columnId]: width,
        }
      },
      {},
    )

    return this.setColumnSizing(newColumnSizing, 'preventTableOverflow')
  }

  /**
   * @description Grow all columns proportionally
   * @returns {ColumnSizingState} The updated column sizing state
   */
  public growColumnsProportionally(): ColumnSizingState {
    const amountToAddToEachColumn =
      (this.tableWidth - this.totalColumnWidth) / this.columnDef.length

    if (amountToAddToEachColumn <= 0) {
      return this.columnSizing
    }

    const newColumnSizing = Object.entries(this.columnSizing).reduce(
      (acc, [columnId, width]) => {
        return {
          ...acc,
          [columnId]: width + amountToAddToEachColumn,
        }
      },
      {},
    )

    return this.setColumnSizing(newColumnSizing, 'growColumnsProportionally')
  }

  /**
   * @description Debug the table resize behavior through the console
   */
  public debug() {
    const itemsWithLabels = this.debugSteps.map((item) => {
      const itemKey = Object.keys(item)[0]
      return {
        label: itemKey,
        // eslint-disable-next-line security/detect-object-injection
        ...item[itemKey],
      }
    })
    // eslint-disable-next-line no-console
    console.table(
      itemsWithLabels.filter((step, index) => {
        return (
          index === 0 ||
          Object.entries(step).some(
            ([key, value]) =>
              // eslint-disable-next-line security/detect-object-injection
              key !== 'label' && value !== itemsWithLabels[index - 1][key],
          )
        )
      }),
    )
  }
}

/**
 * @description Merge multiple refs into a single ref
 * @param {Array<MutableRefObject<unknown> | ForwardedRef<unknown>>} refs An array of refs to merge
 * @returns {(element: unknown) => void} A function to merge the refs
 */
export function mergeRefs<T>(
  ...refs: Array<MutableRefObject<T> | ForwardedRef<T>>
): (element: T) => void {
  return (element: T) => {
    refs.forEach((ref) => {
      if (ref && 'current' in ref) {
        ref.current = element
      }
    })
  }
}
