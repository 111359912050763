import { Box, Tooltip, Typography } from '@mui/material'

import Icon from '@common/Icon'

import FirewallBans from '../FirewallBans/FirewallBans'
import { FirewallTable } from '../FirewallTable'

const FirewallOverview = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        padding: '1rem 1.5rem',
      }}
      data-testid="firewall-page"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '0.5rem',
          padding: '0 0.5rem 1rem 0',
          alignItems: 'center',
        }}
        data-testid="data-refresh-cycles"
      >
        <Typography color="textPrimary" variant="caption">
          Data refresh cycles
        </Typography>
        <Tooltip
          title={
            <Typography variant="caption" color="textSecondary">
              Data updated every 24 hours.
            </Typography>
          }
          followCursor
          placement="top-start"
        >
          <Box>
            <Icon variant={'informationCircleOutline'} size={19} />
          </Box>
        </Tooltip>
      </Box>
      <Box
        data-testid="firewall-bans-container"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          gap: '1.5rem',
        }}
      >
        <FirewallBans />
      </Box>
      <Box>
        <FirewallTable />
      </Box>
    </Box>
  )
}

export default FirewallOverview
