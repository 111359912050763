import { Box, Card, SxProps, Theme } from '@mui/material'
import React from 'react'

import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

interface BanProps {
  children: React.ReactNode
  iconVariant?: IconVariant
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  showHighlight?: boolean
  iconSx?: SxProps<Theme>
  sx?: SxProps<Theme>
  'data-testid'?: string
}

const Ban: React.FC<BanProps> = ({
  children,
  iconVariant,
  onClick,
  onKeyDown,
  showHighlight = true,
  iconSx,
  sx,
  'data-testid': dataTestId,
}) => {
  const dateFilterableStyles = useDateFilterableStyles({
    getDefaultBorderColor: (theme) => ({
      light: theme.vars.palette.secondary.main,
      dark: theme.vars.palette.secondary.main,
    }),
  })
  return (
    <Card
      data-testid={dataTestId}
      onClick={onClick}
      onKeyDown={onKeyDown}
      raised={false}
      sx={[
        (theme) => {
          const backgroundColor = {
            light: theme.vars.palette.secondary.light,
            dark: theme.vars.palette.secondary.main,
          }
          const hoverBackgroundColor = {
            light: theme.vars.palette.secondary.lighter,
            dark: theme.vars.palette.secondary.light,
          }
          const borderColor = theme.vars.palette.secondary.main
          return {
            backgroundColor: backgroundColor.light,
            ...theme.applyStyles('dark', {
              backgroundColor: backgroundColor.dark,
            }),
            backgroundImage: 'none',
            boxShadow: 'none',
            cursor: onClick ? 'pointer' : 'default',
            display: 'flex',
            gap: '0.5rem',
            padding: '1rem',
            '&:hover': onClick
              ? {
                  backgroundColor: hoverBackgroundColor.light,
                  ...theme.applyStyles('dark', {
                    backgroundColor: hoverBackgroundColor.dark,
                  }),
                }
              : undefined,
            width: '100%',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor,
          }
        },
        showHighlight && dateFilterableStyles,
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {iconVariant && (
        <Box
          sx={[
            (theme) => ({
              alignItems: 'center',
              backgroundColor: theme.vars.palette.primary.main,
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.text.secondary,
              }),
              borderRadius: '8px',
              display: 'flex',
              maxHeight: '54px',
              padding: '0 13px',
            }),
            ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
          ]}
        >
          <Icon
            size={28}
            sx={(theme) => ({
              color: theme.vars.palette.primary.contrastText,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.common.black,
              }),
            })}
            variant={iconVariant}
          />
        </Box>
      )}

      <Box sx={{ width: '100%' }}>{children}</Box>
    </Card>
  )
}

export default Ban
