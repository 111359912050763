import { ReactNode, createContext, useContext } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { format } from 'date-fns'

import {
  GET_SECURITY_INDEX_BAN_METRICS,
  GET_YOUR_SECURITY_INDEX,
  GetSecurityIndexBanMetrics,
  GetYourSecurityIndex,
  SecurityIndexVariables,
} from '@queries/securityIndex'
import { Context } from '@components/App'
import { YourIndex, SecurityIndexBansMetrics } from '@models/index'
import { PreTransformedIndexValues } from '@models/SecurityIndex'

type SecurityIndexContext = {
  bansLoading: boolean
  yourIndexLoading: boolean
  bansError: ApolloError | undefined
  yourIndexError: ApolloError | undefined
  bans: SecurityIndexBansMetrics | null
  yourIndex: YourIndex
  preTransformedIndexValues: PreTransformedIndexValues
}

export const SecurityIndexContext = createContext<SecurityIndexContext | null>(
  null,
)

const emptyStateBanState: SecurityIndexBansMetrics = {
  yourIndex: {
    index: '0',
    delta: '0',
  },
  avgCustomerIndex: {
    index: '0',
    delta: '0',
  },
  avgIndustryIndex: {
    index: '0',
    delta: '0',
  },
}

const emptyYourIndexState: YourIndex = {
  trends: {
    yourTrend: [],
    avgCustomerTrend: [],
    avgIndustryTrend: [],
    pastPeriodTrend: [],
  },
  details: {
    lifetimeIncrease: '',
    enabledLogSourcesCount: 0,
    enabledDetectionsCount: 0,
    missingDataSourcesCount: 0,
  },
  preTransformedIndexValues: {
    logSources: 0,
    detections: 0,
    enterpriseCoverage: 0,
  },
  unclassifiedLogSourceCount: 0,
  latestIndex: 0,
}

export const SecurityIndexContextProvider = ({
  children,
  selectedCustomer,
}: {
  children: ReactNode
  selectedCustomer: string | null
}) => {
  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)
  const formattedStartDate = format(startDate, 'yyyy-MM-dd')
  const formattedEndDate = format(endDate, 'yyyy-MM-dd')
  const {
    data: { getSecurityIndexBanMetrics: banMetrics } = {
      getSecurityIndexBanMetrics: emptyStateBanState,
    },
    loading: bansLoading,
    error: bansError,
  } = useQuery<GetSecurityIndexBanMetrics, SecurityIndexVariables>(
    GET_SECURITY_INDEX_BAN_METRICS,
    {
      variables: {
        selectedCustomer,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    },
  )

  const {
    data: { getYourSecurityIndex } = {
      getYourSecurityIndex: emptyYourIndexState,
    },
    loading: yourIndexLoading,
    error: yourIndexError,
  } = useQuery<GetYourSecurityIndex, SecurityIndexVariables>(
    GET_YOUR_SECURITY_INDEX,
    {
      variables: {
        selectedCustomer,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    },
  )

  return (
    <SecurityIndexContext.Provider
      value={{
        bansLoading,
        yourIndexLoading,
        bansError,
        yourIndexError,
        bans: banMetrics || null,
        yourIndex: getYourSecurityIndex,
        preTransformedIndexValues:
          getYourSecurityIndex?.preTransformedIndexValues,
      }}
    >
      {children}
    </SecurityIndexContext.Provider>
  )
}
