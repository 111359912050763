/* eslint-disable security/detect-object-injection */
import { formatDuration, intervalToDuration } from 'date-fns'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'

import { FormatStringDateRange, toTimeUnits } from '@utils/DateTimeUtils'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'
import {
  MTTXDetails,
  MttxMetric,
  TicketPriorityEnum,
  TicketTypeEnum,
} from '@models/index'
import {
  GetMttxDetailsData,
  GetMttxDetailsVariables,
  MTTX_DETAILS,
} from '@queries/mttx'
import { Delta, DeltaProps } from '@common/Delta'

export const emptyMttxDetailsState: MTTXDetails = {
  averageTime: 0,
  delta: {
    changeAmount: 0,
    percentage: 0,
  },
  endDate: '--',
  maxTime: 0,
  minTime: 0,
  previousPeriod: {
    endDate: '--',
    startDate: '--',
  },
  startDate: '--',
  totalTickets: 0,
}

interface MttxDetailsContentProps {
  selectedCustomer: string | null
  startDate: string
  endDate: string
  ticketPriority: string
  metric: MttxMetric
  ticketType?: string
}

const MTTXDetailsContent = ({
  selectedCustomer,
  startDate,
  endDate,
  ticketPriority,
  metric,
  ticketType,
}: MttxDetailsContentProps): JSX.Element => {
  const {
    data: { mttxDetails: mttxDetails } = { mttxDetails: emptyMttxDetailsState },
    loading,
    error,
  } = useQuery<GetMttxDetailsData, GetMttxDetailsVariables>(MTTX_DETAILS, {
    variables: {
      selectedCustomer,
      input: {
        metric,
        startDate,
        endDate,
        ticketPriority: TicketPriorityEnum[ticketPriority],
        ticketType: ticketType && TicketTypeEnum[ticketType],
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const isEmpty = mttxDetails.totalTickets === 0

  const formatValue = ({
    value,
    helperFunc,
  }: {
    value: string | number | DeltaProps
    helperFunc?: (num: number) => string
  }) => {
    if (loading) return '--'
    if (isEmpty || error) return 'Not available'
    if (typeof value === 'number') {
      if (helperFunc !== undefined) {
        return helperFunc(value)
      }
      return value
    }
    if (typeof value === 'string') {
      return value
    }
    return <Delta {...(value as DeltaProps)} skipAbbreviaion />
  }

  const items: SidesheetDualColumnValues[] = [
    {
      key: 'Total tickets',
      label: 'Total tickets',
      value: formatValue({ value: mttxDetails.totalTickets }),
    },
    {
      key: 'Average time',
      label: 'Average time',
      value: formatValue({
        value: mttxDetails.averageTime,
        helperFunc: toTimeUnits,
      }),
    },
    {
      key: 'Max time',
      label: 'Max time',
      value: formatValue({
        value: mttxDetails.maxTime,
        helperFunc: toTimeUnits,
      }),
    },
    {
      key: 'Min time',
      label: 'Min time',
      value: formatValue({
        value: mttxDetails.minTime,
        helperFunc: toTimeUnits,
      }),
    },
    {
      key: 'Delta',
      label: 'Delta',
      value: formatValue({
        value: {
          delta: Math.round(mttxDetails.delta.percentage * 100),
          suffix: '%',
        },
      }),
    },
  ]

  const timePeriod = () => {
    const timeInterval = intervalToDuration({
      start: 0,
      end: mttxDetails.delta.changeAmount * 1000,
    })
    if (mttxDetails.delta.percentage > 0) {
      return `increased by ${formatDuration(timeInterval, {
        format: ['hours', 'minutes', 'seconds'],
      })}`
    } else if (mttxDetails.delta.percentage < 0) {
      return `decreased by ${formatDuration(timeInterval, {
        format: ['hours', 'minutes', 'seconds'],
      })}`
    } else {
      return `remained constant`
    }
  }

  const CHANGE_TEXT_MAPPING = {
    [MttxMetric.MTTN]: 'Your Mean Time to Notify has ',
    [MttxMetric.MTTCAck]: 'Your Mean Time to Acknowledge has ',
  }

  return (
    <Box data-testid="mttn-details">
      <SidesheetDualColumn items={items} />
      {!isEmpty && !error && (
        <div style={{ minHeight: '46px', paddingTop: '1rem' }}>
          <Typography
            variant="caption"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {CHANGE_TEXT_MAPPING[metric]}
            <Typography
              fontWeight={500}
              sx={(theme) => ({
                color:
                  mttxDetails.delta.percentage < 0
                    ? theme.vars.palette.success.main
                    : theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color:
                    mttxDetails.delta.percentage < 0
                      ? theme.vars.palette.success.main
                      : theme.vars.palette.text.secondary,
                }),
              })}
              variant="caption"
            >
              {timePeriod()}
            </Typography>
            {` compared to ${FormatStringDateRange(mttxDetails.previousPeriod.startDate, mttxDetails.previousPeriod.endDate, 'MMM dd, yyyy')}.`}
          </Typography>
        </div>
      )}
    </Box>
  )
}

export default MTTXDetailsContent
