import { gql } from '@apollo/client'

export const DATA_POINT_FRAGMENT = gql`
  fragment DataPointFields on DataPoint {
    date
    gb
  }
`

export const SOURCE_TYPE_FRAGMENT = gql`
  fragment SourceTypeFields on SourceType {
    date
    name
    gb
    alerts
    avgIngestOverAllTime
  }
`
