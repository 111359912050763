import { Box, Button, FormControlLabel, Switch } from '@mui/material'

import Icon from '@common/Icon'

interface TicketLibraryControls {
  handleOpenFilterSideSheetClick: () => void
  handleIncludeToggle: () => void
  toggleState: boolean
}

const TicketLibraryControls: React.FC<TicketLibraryControls> = ({
  handleOpenFilterSideSheetClick,
  handleIncludeToggle,
  toggleState,
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControlLabel
        className="switch-label"
        tabIndex={0}
        labelPlacement="start"
        onKeyDown={(e) => {
          e.stopPropagation()
          if (e.key === 'Enter' || e.key === ' ') {
            handleIncludeToggle()
          }
        }}
        sx={(theme) => ({
          '.MuiFormControlLabel-label': {
            color: theme.vars.palette.text.primary,
          },
        })}
        data-testid="include-resolved-closed-cancelled-tickets-label"
        control={
          <Switch
            onClick={handleIncludeToggle}
            checked={!toggleState}
            tabIndex={-1}
          />
        }
        label="Include closed/cancelled tickets"
      />

      <Button
        data-testid="ticket-library-controls-filter-button"
        onClick={handleOpenFilterSideSheetClick}
        size="large"
        sx={{ gap: '0.5rem' }}
      >
        Filter
        <Icon variant="filter" />
      </Button>
    </Box>
  )
}

export default TicketLibraryControls
