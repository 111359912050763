import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { SidesheetDualColumnValues } from '@common/SidesheetDualColumn'
import { SourceType } from '@models/EnvHealth'

import { useEnvironmentHealthBanSidesheetContext } from '../EnvironmentHealthBanSidesheet/EnvironmentHealthBanSidesheetContext'

type SideSheetDataFields =
  | 'sourceTypes'
  | 'details'
  | 'chartTitle'
  | 'chartType'
  | 'chartValue'

type SideSheetData = Pick<
  EnvironmentHealthChartDetailsSideSheetContextValue,
  SideSheetDataFields
>

export enum ChartType {
  SINGLE_DAY,
  MULTI_DAY,
}

export type SupportedChartValue = 'alerts' | 'gb'

export type EnvironmentHealthChartDetailsSideSheetContextValue = {
  isOpen: boolean
  onClose: () => void
  setIsOpen: (isOpen: boolean) => void
  setSideSheetData: (data: SideSheetData) => void
  licenseCapacity?: number
  sourceTypes: SourceType[]
  details: SidesheetDualColumnValues[]
  chartTitle: string
  chartType: ChartType
  chartValue?: SupportedChartValue
}

export const initialContext: EnvironmentHealthChartDetailsSideSheetContextValue =
  {
    isOpen: false,
    onClose: () => {},
    setIsOpen: () => {},
    setSideSheetData: () => {},
    sourceTypes: [],
    details: [],
    chartTitle: '',
    licenseCapacity: undefined,
    chartType: ChartType.MULTI_DAY,
    chartValue: 'alerts',
  }

export const EnvironmentHealthChartDetailsSideSheetContext =
  createContext<EnvironmentHealthChartDetailsSideSheetContextValue>(
    initialContext,
  )

export interface EnvironmentHealthChartDetailsSideSheetContextProviderProps {
  children: ReactNode
  licenseCapacity?: number
}

export const EnvironmentHealthChartDetailsSideSheetContextProvider: React.FC<
  EnvironmentHealthChartDetailsSideSheetContextProviderProps
> = ({ children, licenseCapacity }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [sideSheetData, setSideSheetData] = useState<SideSheetData>({
    sourceTypes: [],
    details: [],
    chartTitle: '',
    chartType: ChartType.MULTI_DAY,
    chartValue: 'alerts',
  })

  /**
   * In order to use this context, the <EnvironmentHealthBanSidesheetContextProvider /> must be
   * a parent of this <EnvironmentHealthChartDetailsSideSheetContextProvider /> component.
   */
  const { setIsOpen: setIsBanSidesheetOpen } =
    useEnvironmentHealthBanSidesheetContext()

  const handleSetIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen)
      if (newIsOpen) {
        // Close the ban sidesheet when the chart details sidesheet is opened
        setIsBanSidesheetOpen(false)
      }
    },
    [setIsBanSidesheetOpen, setIsOpen],
  )

  const value: EnvironmentHealthChartDetailsSideSheetContextValue = {
    isOpen,
    licenseCapacity,
    onClose: () => handleSetIsOpen(false),
    setIsOpen: handleSetIsOpen,
    setSideSheetData,
    ...sideSheetData,
  }

  return (
    <EnvironmentHealthChartDetailsSideSheetContext.Provider value={value}>
      {children}
    </EnvironmentHealthChartDetailsSideSheetContext.Provider>
  )
}

export const useEnvironmentHealthChartDetailsSideSheet = () =>
  useContext(EnvironmentHealthChartDetailsSideSheetContext)
