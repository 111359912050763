import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Tab, Tabs } from '@mui/material'

import { Context } from '@components/App'
import { Paths } from '@components/App/Types'
import { navigateUserType } from '@utils/index'
import { zIndex } from '@components/App/Styles/zIndex'

import { ChangeHistory } from './ChangeHistory'
import { YourIndex } from './YourIndex'

interface SecurityIndexTabs {
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const SecurityIndexTabs: React.FC<SecurityIndexTabs> = ({
  selectedTrendEventState,
}) => {
  const { pathname } = useLocation()

  let initSelectedTab = 0

  if (pathname.includes('change-history')) {
    initSelectedTab = 1
  }

  const [selectedTab, setSelectedTab] = useState<number>(initSelectedTab)
  const navigate = useNavigate()
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)
  const { featureNgMdr } = useFlags()

  const securityIndexPath = featureNgMdr
    ? Paths.MDR_SECURITY_INDEX
    : Paths.SECURITY_INDEX

  const [selectedTrendEvent, setSelectedTrendEvent] = selectedTrendEventState

  useEffect(() => {
    if (pathname.includes('your-index')) {
      setSelectedTab(0)
    } else if (pathname.includes('change-history')) {
      setSelectedTab(1)
    }
  }, [pathname])

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setSelectedTab(value)

    if (value === 0) {
      navigateUserType(
        isDWEmployee,
        `${securityIndexPath}${Paths.YOUR_INDEX}`,
        navigate,
        customerShortName,
      )
    } else if (value === 1) {
      navigateUserType(
        isDWEmployee,
        `${securityIndexPath}${Paths.CHANGE_HISTORY}`,
        navigate,
        customerShortName,
      )
    }
  }

  const SecurityIndexTabsContent: JSX.Element[] = [
    <YourIndex
      selectedTrendEventState={[selectedTrendEvent, setSelectedTrendEvent]}
      key={0}
    />,
    <ChangeHistory key={0} />,
  ]

  return (
    <Box
      data-testid="security-index-tabs"
      sx={(theme) => ({
        flexWrap: 'wrap',
        '& > ul': {
          position: 'sticky',
          top: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
          }),
          zIndex: zIndex.SECURITY_INDEX_TABS,
          '& li': {
            '& div': {
              '&:nth-of-type(1)': {
                margin: '0 !important',
                '& span': {
                  color: `${theme.vars.palette.text.primary} !important`,
                },
              },
            },
          },
        },
      })}
    >
      <Tabs onChange={handleTabChange} value={selectedTab}>
        <Tab label="Your Index" />
        <Tab label="Change History" />
      </Tabs>

      {SecurityIndexTabsContent.at(selectedTab)}
    </Box>
  )
}

export default SecurityIndexTabs
