import { useQuery } from '@apollo/client'
import { Link, Skeleton } from '@mui/material'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { CYBER_ASSISTANT_TPFP_QUARTERLY_RATE } from '@queries/cyberAssistant'
import { formatLargeNumber } from '@utils/formatLargeNumber'
import { getFormattedDate } from '@utils/DateTimeUtils'

import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import { AnswerId } from '../CyberAssistantQuestions'

const QuarterlyTruePositiveAnswer: React.FC<{
  selectedCustomer: string | null
}> = ({ selectedCustomer }) => {
  const {
    data: {
      cyberAssistantTPFPQuarterlyRate: {
        truePositiveRate,
        totalSecurityTickets,
        falsePositiveRate,
        maliciousPenTestRate,
        totalLogs,
        totalAlerts,
        pastQuarterStartDate,
        pastQuarterEndDate,
      },
      cyberAssistantCustomerAndDWTP: { dwQuarterlyTP },
    } = {
      cyberAssistantTPFPQuarterlyRate: {
        truePositiveRate: 0.0,
        falsePositiveRate: 0.0,
        totalSecurityTickets: 0,
        maliciousPenTestRate: 0.0,
        totalLogs: 0,
        totalAlerts: 0,
        pastQuarterStartDate: '',
        pastQuarterEndDate: '',
      },
      cyberAssistantCustomerAndDWTP: {
        dwQuarterlyTP: 0,
      },
    },
    loading,
    error,
  } = useQuery(CYBER_ASSISTANT_TPFP_QUARTERLY_RATE, {
    variables: {
      selectedCustomer,
    },
  })

  const { featureCyberAssistantV2 } = useFlags()

  const anyDataIsNullOrUndefined = [
    truePositiveRate,
    totalSecurityTickets,
    falsePositiveRate,
    maliciousPenTestRate,
    totalLogs,
    totalAlerts,
    pastQuarterEndDate,
    pastQuarterEndDate,
    dwQuarterlyTP,
  ].some((dataPoint) => dataPoint === null || undefined)

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  if (error || anyDataIsNullOrUndefined) {
    return <EmptyDataAnswer />
  }

  const formattedCustomerTP = (truePositiveRate * 100).toFixed(1)
  const formattedDWTP = (dwQuarterlyTP * 100).toFixed(1)

  const customerTPRateComparison = () => {
    if (formattedCustomerTP === formattedDWTP) {
      return 'equal to'
    } else if (formattedCustomerTP > formattedDWTP) {
      return 'higher than'
    } else if (formattedCustomerTP < formattedDWTP) {
      return 'lower than'
    }
    return null
  }

  const formattedDateOpener = featureCyberAssistantV2
    ? `Between ${getFormattedDate(pastQuarterStartDate, 'MMMM dd, yyyy')} and ${getFormattedDate(pastQuarterEndDate, 'MMMM dd, yyyy')}`
    : 'During this time frame'

  return (
    <>
      {formattedDateOpener}, {formatLargeNumber(totalAlerts, 'long')} alerts
      were triaged from {formatLargeNumber(totalLogs, 'long')} logs of which{' '}
      {totalSecurityTickets} tickets were escalated for further actions.
      <br />
      <br />
      Of the {totalSecurityTickets} escalated alerts, {formattedCustomerTP}%
      were validated to be a true positive, which is{' '}
      {customerTPRateComparison()} the Deepwatch customer average of{' '}
      {formattedDWTP}%.
      <br />
      <br />
      Of your true positives, {(maliciousPenTestRate * 100).toFixed(1)}% were
      validated to be malicious and/or a pen test.
      <br />
      <br />
      To ensure your alerts are properly configured, you can{' '}
      <Link
        href={`https://deepwatch.service-now.com/csm?id=sc_category&sys_id=f60aecac1b122410b05ffd9f034bcbbf`}
        variant="body1"
        rel="noopener noreferrer"
        target="_blank"
      >
        create a ticket
      </Link>{' '}
      to schedule a detection assessment. Your Deepwatch Experts will review and
      provide recommendations based on their findings.
      <FeedbackForm answerId={AnswerId.TP_FP} />
    </>
  )
}

export default QuarterlyTruePositiveAnswer
