import { Typography, Box } from '@mui/material'

import Icon from '@common/Icon'

import { StatusDetailsCardTooltipProps } from './StatusDetailsTypes'

export const StatusDetailsCardTooltip: React.FC<
  StatusDetailsCardTooltipProps
> = ({ label, value, color, id }) => (
  <Box
    sx={(theme) => ({
      background: theme.vars.palette.common.white,
      padding: '0.25rem',
      border: `0.75px solid${theme.vars.palette.common.white}`,
      borderRadius: '3px',
      opacity: 1,
      transform: 'scale(1)',
      ...theme.applyStyles('dark', {
        border: `0.75px solid ${theme.vars.palette.secondary.dark}`,
        background: theme.vars.palette.secondary.darker,
      }),
    })}
  >
    <Typography color="textPrimary" variant="caption">
      {label}
    </Typography>
    <Box
      component="hr"
      sx={{
        border: 'none',
        borderTop: '0.75px solid #576569', //navy 800
        width: '100%',
        marginTop: '0.25rem',
        marginBottom: '0.25rem',
      }}
    />
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <Icon size={14} variant="square" sx={{ color, paddingRight: '.25rem' }} />
      <Typography color="textPrimary" variant="caption">
        {value}
      </Typography>
      <Typography
        color="textPrimary"
        variant="caption"
        sx={{ marginLeft: 0.25 }}
      >
        {id}
      </Typography>
    </Box>
  </Box>
)
