/* eslint-disable no-console */
import React, { useEffect } from 'react'

import { AppEventEmitter } from '@utils/EventEmitter'
import { useToast } from '@hooks/index'
import { AlertSeverity } from '@hooks/useToast'

const AppWrapper: React.FC<{
  children?: unknown
}> = ({ children }) => {
  const { handleShowToast } = useToast()

  useEffect(() => {
    AppEventEmitter.on('appSyncClientError', (e) => {
      console.log(e)
      handleShowToast(AlertSeverity.Error, e)
    })

    AppEventEmitter.on('graphQlError', (e) => {
      console.log(e)
      handleShowToast(AlertSeverity.Error, e)
    })

    AppEventEmitter.on('oktaError', (e) => {
      console.log(e)
      handleShowToast(AlertSeverity.Error, e)
    })

    return () => {
      AppEventEmitter.removeAllListeners('appSyncClientError')
      AppEventEmitter.removeAllListeners('graphQlError')
      AppEventEmitter.removeAllListeners('oktaError')
    }
  }, [])

  return <>{children}</>
}

export default AppWrapper
