import { Box } from '@mui/material'

import ModuleHeader from '@common/ModuleHeader'
import { useCustomer } from '@hooks/useCustomer'

import EDRBans from './EDRBans'
import TopAssetsByAlerts from './TopAssetsByAlerts'

const EDROverview: React.FC = () => {
  const { customer } = useCustomer()

  const linkInfo = () => {
    const isMicrosoftDefender =
      customer.serviceNgMedrTechnology?.toLowerCase() === 'microsoft_defender'

    return {
      link: isMicrosoftDefender
        ? 'https://security.microsoft.com/'
        : customer.crowdstrikeUrl,
      name: isMicrosoftDefender ? 'Defender' : 'Falcon',
    }
  }

  const moduleLink = linkInfo()

  return (
    <Box
      id="edr-overview-page"
      data-testid="edr-overview"
      sx={{ overflowY: 'auto', padding: '1.5rem', width: '100%' }}
    >
      <ModuleHeader
        moduleLink={moduleLink.link}
        moduleLinkText={moduleLink.name}
        moduleType="medr"
        tooltipText="Data updated every 24 hours"
        loading={customer.customerShortName === ''}
      />

      <EDRBans />
      <TopAssetsByAlerts />
    </Box>
  )
}
export default EDROverview
