import { useMutation } from '@apollo/client'
import UAParser from 'ua-parser-js'
import { Formik, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material'

import { CREATE_JIRA_ISSUE } from '@mutations/bugReporting'
import { ComponentError } from '@common/ComponentError'
import {
  ComponentErrorType,
  componentErrorContent,
} from '@common/ErrorCard/ErrorCard'
import { AlertSeverity, useToast } from '@hooks/useToast'

const parser = new UAParser()
const metadata = {
  screenResolution: `${window.screen.width}x${window.screen.height}`,
  browser: {
    name: parser.getBrowser().name,
    vendor: parser.getBrowser().vendor,
    version: parser.getBrowser().version,
  },
  viewportSize: `${window.innerWidth}x${window.innerHeight}`,
  operatingSystem: `${parser.getOS().name} ${parser.getOS().version}`,
}

const metadataString = [
  `Screen Resolution: ${metadata.screenResolution}`,
  `Browser Name: ${metadata.browser.name}`,
  `Browser Vendor: ${metadata.browser.vendor}`,
  `Browser Version: ${metadata.browser.version}`,
  `Viewport Size: ${metadata.viewportSize}`,
  `Operating System: ${metadata.operatingSystem}`,
].join('\n')

const locationMax = 32
const summaryMax = 150
const descriptionCharacterMax = 2000 - metadataString.length

const BugFormSchema = Yup.object().shape({
  location: Yup.string()
    .max(
      locationMax,
      `The Location must be no more than ${locationMax} characters`,
    )
    .required('Please enter the location where the bug takes place'),
  summary: Yup.string()
    .max(
      summaryMax,
      `The Summary must be no more than ${summaryMax} characters`,
    )
    .required('Please enter a bug summary'),
  description: Yup.string()
    .max(
      descriptionCharacterMax,
      `The Description must be no more than ${descriptionCharacterMax} characters`,
    )
    .required('Please enter a description of the bug that takes place'),
  severity: Yup.string().required('Please select the severity of the bug'),
})

const BugFormFallback: React.FC = () => (
  <ComponentError {...componentErrorContent[ComponentErrorType.GENERIC]} />
)

interface BugForm {
  closeModal: VoidFunction
}

const BugForm: React.FC<BugForm> = ({ closeModal }) => {
  const { handleShowToast } = useToast()
  const toggleForm = () => {
    closeModal()
  }
  const [createIssueMutation] = useMutation(CREATE_JIRA_ISSUE)

  const handleSubmit = async ({ location, summary, description, severity }) => {
    const finalSummary = `${summary} - ${severity}`
    const finalDescription = `${description}\nLocation: ${location}\nMetadata: ${metadataString}`

    handleShowToast(AlertSeverity.Info, `Processing request...`)

    createIssueMutation({
      variables: { summary: finalSummary, description: finalDescription },
      onCompleted: () => {
        handleShowToast(
          AlertSeverity.Success,
          'Bug submitted succesfully',
          5000,
        )
        toggleForm()
      },
      onError: () => {
        handleShowToast(AlertSeverity.Error, 'Bug failed to submit')
      },
    })
  }

  const severityOptions = [
    {
      id: 'severity-low',
      name: 'severity',
      value: 'low',
      label: 'Low',
      subtitle: "Minor issues that don't affect functionality",
    },
    {
      id: 'severity-medium',
      name: 'severity',
      value: 'medium',
      label: 'Medium',
      subtitle: 'Issues that affect some functionality',
    },
    {
      id: 'severity-high',
      name: 'severity',
      value: 'high',
      label: 'High',
      subtitle: 'Critical issues that affect all functionality',
    },
  ]

  const inputStyles = (
    theme: Theme,
    errors: FormikErrors<{
      location: string
      summary: string
      description: string
      severity: string
    }>,
  ) => ({
    marginBottom: '1.5rem',
    label: {
      color: `${theme.vars.palette.text.primary} !important`,
      ...theme.applyStyles('dark', {
        color: `${theme.vars.palette.text.secondary} !important`,
      }),
    },
    '.MuiInputBase-root': {
      '&.Mui-error': {
        border: errors
          ? `1px solid ${theme.vars.palette.error.main}`
          : `1px solid ${theme.vars.palette.text.primary}`,
      },
    },
  })

  return (
    <Box
      id="bug-form-modal"
      data-testid="bug-form-modal"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'spaceBetween',
        position: 'relative',
        padding: '16px',
        flexWrap: 'wrap',
      }}
    >
      <ErrorBoundary fallbackRender={() => <BugFormFallback />}>
        <Formik
          initialValues={{
            location: '',
            summary: '',
            description: '',
            severity: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={BugFormSchema}
        >
          {({
            handleBlur,
            handleChange,
            submitForm,
            errors,
            touched,
            values,
            dirty,
            isValid,
          }) => {
            return (
              <>
                <Box sx={{ flex: 3, marginRight: '20px' }}>
                  <TextField
                    sx={(theme) => inputStyles(theme, errors)}
                    error={
                      errors.location !== undefined &&
                      touched.location !== undefined
                    }
                    helperText={
                      errors.location && touched.location ? errors.location : ''
                    }
                    label="Location"
                    name="location"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter where the bug takes place"
                    required={true}
                    type="text"
                    value={values.location}
                  />

                  <TextField
                    sx={(theme) => inputStyles(theme, errors)}
                    error={
                      errors.summary !== undefined &&
                      touched.summary !== undefined
                    }
                    helperText={
                      errors.summary && touched.summary ? errors.summary : ''
                    }
                    label="Summary"
                    name="summary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter the bug summary here"
                    required={true}
                    type="text"
                    value={values.summary}
                  />

                  <Box>
                    <TextField
                      error={
                        errors.description !== undefined &&
                        touched.description !== undefined
                      }
                      helperText={
                        errors.description && touched.description
                          ? errors.description
                          : ''
                      }
                      label="Description"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter the bug description"
                      required={true}
                      type="text"
                      value={values.description}
                      multiline
                      rows={8}
                      sx={(theme) => inputStyles(theme, errors)}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <FormControl>
                    <FormLabel>Severity</FormLabel>
                    <RadioGroup>
                      {severityOptions.map((severity) => {
                        return (
                          <Box
                            key={severity.id}
                            sx={(theme) => {
                              const isSelected =
                                values.severity === severity.value
                              const outlineWidth = isSelected ? '2px' : '1px'
                              const outlineColor = isSelected
                                ? `${theme.vars.palette.primary.main} !important`
                                : `${theme.vars.palette.text.primary} !important`
                              const darkModeOutlineColor = isSelected
                                ? `${theme.vars.palette.text.secondary} !important`
                                : `${theme.vars.palette.secondary.lighter} !important`

                              return {
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginBottom: '10px',
                                outline: `${outlineWidth} solid`,
                                borderRadius: '5px',
                                outlineColor,
                                ...theme.applyStyles('dark', {
                                  outlineColor: darkModeOutlineColor,
                                  outline: `${outlineWidth} solid`,
                                }),
                                padding: '10px',
                              }
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  disableRipple
                                  name={severity.name}
                                  value={severity.value}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  sx={{
                                    padding: '0px',
                                    position: 'relative',
                                    bottom: '14px',
                                  }}
                                />
                              }
                              label={
                                <Box sx={{ paddingLeft: '5px' }}>
                                  <Typography
                                    sx={(theme) => ({
                                      ...theme.applyStyles('dark', {
                                        color:
                                          theme.vars.palette.text.secondary,
                                      }),
                                    })}
                                  >
                                    {severity.label}
                                  </Typography>
                                  {severity.subtitle && (
                                    <Typography
                                      fontSize={12}
                                      fontWeight={400}
                                      sx={(theme) => ({
                                        fontFamily: 'Inter, sanse-serif',
                                        color: theme.vars.palette.text.primary,
                                        lineHeight: '14px',
                                        letterSpacing: 0,
                                      })}
                                    >
                                      {severity.subtitle}
                                    </Typography>
                                  )}
                                </Box>
                              }
                            />
                          </Box>
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  data-testid="submit-bug-button"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'flex-end',
                    width: '100%',
                    marginTop: '14px',
                    gap: '1rem',
                  }}
                >
                  <Button
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={!isValid || !dirty}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button onClick={toggleForm} variant="outlined">
                    Cancel
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>
      </ErrorBoundary>
    </Box>
  )
}

export default BugForm
