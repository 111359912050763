import { DetectionStatus } from './DetectionCatalogStatusTag'

/**
 * Provides the `Tag` component's `textColor` for a given `TagStatus`
 * @param {string} status - The status of the detection
 * @param theme
 * @returns {string} The text color for the `Tag` component
 */
export function getColorsForStatus(status: string, theme: any): string {
  const unavailableColor: string = theme.palette.text.primary

  const detectionStatusToColor: Record<DetectionStatus, string> = {
    enabled: theme.palette.success.main,
    eligible: theme.palette.primary.light,
    available: theme.palette.primary.light,
    'trial mode': theme.palette.severity?.medium,
    blocked: theme.palette.warning.main,
    unavailable: unavailableColor,
    'not applicable': unavailableColor,
    'pending review': unavailableColor,
    'update required': unavailableColor,
  }
  return (
    detectionStatusToColor[status as DetectionStatus] ??
    detectionStatusToColor['not applicable']
  )
}

export const detectionStatuses: Partial<Record<DetectionStatus, string>> = {
  'update required':
    'This detection requires the latest TA version to be eligible for a DE review',
}

export function getDetectionStatusTooltipContent(
  status: DetectionStatus,
): string | null {
  return detectionStatuses[status as DetectionStatus] ?? null
}
