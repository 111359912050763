import { Box, Link, Typography, useTheme } from '@mui/material'

import { useMitreOverviewContext } from '../context/MitreOverviewContext'

interface MitreHeaderProps {
  isTableVisible: boolean
  setIsTableVisible(value: boolean): void
}
export default function MitreHeader({
  isTableVisible,
  setIsTableVisible,
}: MitreHeaderProps) {
  const {
    mitreSelection,
    setMitreSelection,
    setCarouselTechnique,
    handleSideSheet,
    setHasUserClosedSideSheet,
    coverage,
  } = useMitreOverviewContext()
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'
  const handleViewToggle = () => {
    /**
     * when switching to the table view from the list view,
     * while having a subtechnique selected, unset the
     * subtechnique and display the sidesheet for its
     * related technique since the table view does not
     * include a state where users can view subtechniques
     */
    const newTableVisible = !isTableVisible

    setIsTableVisible(newTableVisible)

    if (newTableVisible) {
      return
    }

    const { tactic, technique, subtechnique } = mitreSelection

    setMitreSelection({
      tactic,
      technique,
      subtechnique: undefined,
    })

    if (technique === undefined) {
      setCarouselTechnique(undefined)
    }

    if (tactic !== undefined && subtechnique !== undefined) {
      handleSideSheet(tactic, technique, undefined, () => {
        if (
          technique &&
          // eslint-disable-next-line security/detect-object-injection
          coverage[tactic].techniques[technique].subtechniques.length === 0
        ) {
          setCarouselTechnique(undefined)
        }
        setMitreSelection({
          tactic: undefined,
          subtechnique: undefined,
          technique: undefined,
        })

        setHasUserClosedSideSheet(true)
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography color="textPrimary" variant="h6">
          MITRE Coverage
        </Typography>
        <Link
          data-testid="mitre-overview-toggle"
          component="button"
          onClick={handleViewToggle}
          sx={{ paddingLeft: 1 }}
          variant="caption"
        >
          View as {isTableVisible ? 'list' : 'table'}
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          flexGrow: 0,
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <MitreCoverageKey
          label="Unavailable"
          borderColor={theme.palette.secondary.lighter}
        />
        <MitreCoverageKey
          label="Available"
          boxColor={theme.palette.secondary.lighter}
        />
        <MitreCoverageKey
          label="Covered"
          boxColor={
            isLightMode
              ? theme.palette.text.primary
              : theme.palette.text.secondary
          }
        />
      </Box>
    </Box>
  )
}

interface MitreCoverageKeyProps {
  label: string
  borderColor?: string
  boxColor?: string
}
function MitreCoverageKey({
  label,
  borderColor,
  boxColor = 'transparent',
}: MitreCoverageKeyProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <Box
        data-testid="mitre-coverage-key-color"
        sx={{
          border: borderColor ? `1px solid ${borderColor}` : '',
          backgroundColor: boxColor,
          width: 12,
          height: 12,
        }}
      />
      <Typography color="textPrimary" variant="caption">
        {label}
      </Typography>
    </Box>
  )
}
