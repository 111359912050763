import { useContext, useEffect, useState } from 'react'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import { Box, Tooltip, Typography } from '@mui/material'
import { captureException } from '@sentry/react'

import {
  ENVIRONMENT_HEALTH_BAN,
  ENVIRONMENT_HEALTH_UTIL_ALERTS_NO_VALUE,
} from '@queries/environmentHealth'
import { Context } from '@components/App'
import {
  Dimension,
  EnvironmentHealthUtilAlertsNoValue,
  Metric,
  Segment,
} from '@models/EnvHealth'
import Icon from '@common/Icon'

import EnvironmentHealthBans from '../EnvHealthBans/EnvHealthBans'
import SourceUtilization from './SourceUtilization/SourceUtilization'
import StatusDetails from './StatusDetails/StatusDetails'
import { EnvHealthBanSideSheet } from '../Sidesheets/EnvironmentHealthBanSidesheet'
import { EnvironmentHealthChartDetailsSideSheet } from '../Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheet'
import { EnvironmentHealthChartDetailsSideSheetContextProvider } from '../Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import { handleQueryCall } from './Overview.utils'
import { EnvironmentHealthBanSidesheetContextProvider } from '../Sidesheets/EnvironmentHealthBanSidesheet/EnvironmentHealthBanSidesheetContext'

const DATE_FORMAT = 'yyyy-MM-dd'

export interface EnvHealthState {
  data: EnvironmentHealthUtilAlertsNoValue | null
  loading: boolean
  error: ApolloError | undefined
}

export interface inputs {
  metric: Metric
  dimension: Dimension
  segment: Segment
}

const Overview: React.FC = () => {
  const [searchParams] = useSearchParams()
  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const [envHealthState, setEnvHealthState] = useState<EnvHealthState>({
    data: null,
    loading: true,
    error: undefined,
  })

  const {
    data: { environmentHealthBan } = { environmentHealthBan: null },
    loading,
    error: errorBan,
  } = useQuery(ENVIRONMENT_HEALTH_BAN, {
    variables: {
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(endDate, DATE_FORMAT),
      selectedCustomer: searchParams.get('customer'),
    },
  })

  const [getUtilAlertsNoValue] = useLazyQuery(
    ENVIRONMENT_HEALTH_UTIL_ALERTS_NO_VALUE,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  useEffect(() => {
    //Callback to setState in useEffect as described in React's documentation.
    //This needed to be done to avoid state not by the date picker
    setEnvHealthState((prevState) => ({
      ...prevState,
      loading: true,
      error: undefined,
    }))
    getUtilAlertsNoValue({
      variables: {
        selectedCustomer: searchParams.get('customer'),
        input: {
          startDate: format(startDate, DATE_FORMAT),
          endDate: format(endDate, DATE_FORMAT),
          metric: Metric.UTILIZATION,
          dimension: Dimension.DATE,
          segment: Segment.NO_VALUE,
        },
      },
    })
      .then((result) => {
        setEnvHealthState({
          data: result.data.environmentHealthUtilAndAlerts,
          loading: false,
          error: result.error || undefined,
        })
      })
      .catch((err) => {
        captureException(err)
        setEnvHealthState({
          data: null,
          loading: false,
          error: err,
        })
      })
  }, [startDate, endDate])

  const handleInputChange = (selectedInputs: inputs) => {
    setEnvHealthState((prevState) => ({
      ...prevState,
      loading: true,
      error: undefined,
    }))

    handleQueryCall(
      selectedInputs,
      setEnvHealthState,
      envHealthState,
      searchParams,
      startDate,
      endDate,
      DATE_FORMAT,
      getUtilAlertsNoValue,
    )
  }

  return (
    <Box data-testid="environment" sx={{ overflowY: 'auto', width: '100%' }}>
      <Box
        component="article"
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          padding: '1rem 1.5rem',
          paddingBottom: '5rem',
        }}
        id="env-health-overview-page"
        data-testid="env-health-overview"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '0.5rem',
            padding: '0 0.5rem 1rem 0',
            alignItems: 'flex-start',
          }}
          data-testid="data-refresh-cycles"
        >
          <Typography
            sx={{ alignContent: 'center' }}
            color="textPrimary"
            variant="caption"
          >
            Data refresh cycles
          </Typography>

          <Tooltip
            data-testid="tooltip-content"
            title={
              <Box sx={{ maxWidth: '215px' }}>
                <Typography
                  variant="caption"
                  noWrap={true}
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === 'light'
                        ? theme.vars.palette.text.primary
                        : theme.vars.palette.text.secondary,
                  })}
                >
                  Source types updated every day
                </Typography>
                <Typography
                  variant="caption"
                  noWrap={true}
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === 'light'
                        ? theme.vars.palette.text.primary
                        : theme.vars.palette.text.secondary,
                  })}
                >
                  Forwarders updated every 15 minutes
                </Typography>
              </Box>
            }
            placement="top-start"
            followCursor
          >
            <Box>
              <Icon variant="informationCircleOutline" size={19} />
            </Box>
          </Tooltip>
        </Box>
        <EnvironmentHealthBanSidesheetContextProvider>
          <EnvironmentHealthChartDetailsSideSheetContextProvider
            licenseCapacity={environmentHealthBan?.licenseCapacity}
          >
            <EnvironmentHealthBans
              data={environmentHealthBan}
              loading={loading}
              error={errorBan}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                width: '100%',

                '& section': {
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '2 1 0%',
                  gap: '1rem',

                  '& > *': {
                    minWidth: 0,
                    width: '100% !important',
                  },
                },

                '& aside:not(.appsidesheet)': {
                  flex: '0 1 0%',

                  '& > *': {
                    minWidth: '300px',
                    maxWidth: '100%',
                  },
                },
              }}
            >
              <Box component="section">
                <SourceUtilization
                  data={envHealthState.data}
                  error={envHealthState.error}
                  loading={envHealthState.loading}
                  inputs={handleInputChange}
                />
                <StatusDetails />
                <EnvHealthBanSideSheet data={environmentHealthBan} />
                <EnvironmentHealthChartDetailsSideSheet />
              </Box>
            </Box>
          </EnvironmentHealthChartDetailsSideSheetContextProvider>
        </EnvironmentHealthBanSidesheetContextProvider>
      </Box>
    </Box>
  )
}
export default Overview
