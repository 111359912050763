/* eslint-disable @getify/proper-ternary/nested */
import {
  NavigateOptions,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { format } from 'date-fns'
import { useContext } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Avatar,
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { SidesheetDualColumn } from '@common/SidesheetDualColumn'
import { formatEmptyField, formatPriority } from '@utils/Common'
import { navigateUserType } from '@utils/navigateUserType'
import ThreatIntelReportsAdvisoryCard from '@components/ThreatIntel/ThreatIntelReports/components/ThreatIntelReportsAdvisoryCard'
import { colors } from '@design-system/theme'
import { Paths } from '@components/App/Types'
import { Context } from '@components/App'
import { DEEP_LINK_URL_BASE } from '@constants/constants'
import { TICKET_STATE_OPEN } from '@models/Tickets'
import { DetectionStatusType } from '@components/Coverage/Coverage.utils'
import { useAvatarInitials } from '@hooks/useAvatarInitials'
import Icon from '@common/Icon'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import { IconVariant } from '@common/Icon/Icons'
import { ListItemType } from 'src/interfaces/ListItemType'

import { DetectionCatalogStatusTag } from '../DetectionCatalogStatusTag'
import { formatDetectionStatus } from '../DetectionCatalog.utils'

enum Reviewer {
  AUTO_ENRICHED = 'Auto-enriched',
}

const DetectionSideSheetContent = ({
  detection,
  hideThreatIntelSection = false,
  isOpen,
}) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const { search: currentCustomer } = useLocation()

  const theme = useTheme()

  const { featureEditTicketPage } = useFlags()

  const avatarInitials = useAvatarInitials({
    firstName: detection?.reviewer?.split(' ')[0] ?? '',
    lastName: detection?.reviewer?.split(' ')[1] ?? '',
  })

  const isThreatIntelReportsPath =
    location.pathname === Paths.THREAT_INTEL_FULL_REPORT

  const relatedOpenTickets = detection.relatedTickets.filter((ticket) =>
    TICKET_STATE_OPEN.includes(ticket.state),
  )

  const detectionTag = formatDetectionStatus(detection.status)

  const columnValueContent = (
    children?: string | string[] | null,
  ): string | string[] | JSX.Element => {
    if (!children) {
      return (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          Not Applicable
        </Typography>
      )
    } else if (typeof children === 'string' || children.length === 1) {
      return (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {children}
        </Typography>
      )
    } else {
      return children
    }
  }

  const getDaysInTrialMode = () => {
    return detection.daysInTrialMode !== null
      ? `${detection.daysInTrialMode} days`
      : '--'
  }

  const getTotalActivityDetailValue = () => {
    if (detection.relatedTickets.length) {
      return (
        <Link
          component="button"
          onClick={() => handleTicketsNavigationClick(false)}
        >
          {detection.relatedTickets.length +
            (detection.relatedTickets.length > 1 ? ' tickets' : ' ticket')}
        </Link>
      )
    }
    return detection.relatedTickets.length
  }

  const handleThreatIntelClick = (reportId: string) => {
    const options: NavigateOptions | undefined =
      isThreatIntelReportsPath && !!searchParams.size
        ? {
            state: {
              searchParams: searchParams.toString(),
            },
          }
        : undefined

    navigateUserType(
      isDWEmployee,
      `/threat-intel/${reportId}`,
      navigate,
      customerShortName,
      options,
    )
  }

  const handleTicketNavigation = (sysId: string) => {
    if (featureEditTicketPage) {
      const value = currentCustomer
        ? `${Paths.TICKETS}/library/${sysId}/edit${currentCustomer}`
        : `${Paths.TICKETS}/library/${sysId}/edit`

      return navigate(value)
    }

    return window.open(`${DEEP_LINK_URL_BASE}${sysId}`, '_blank')
  }

  const handleTicketsNavigationClick = (showOpenTicketOnly: boolean = true) => {
    const searchParams = new URLSearchParams()
    searchParams.set(
      'selectedFilters',
      JSON.stringify({
        useCase: [detection.useCase],
      }),
    )
    searchParams.set(
      'filterValueLabelMap',
      JSON.stringify({
        [detection.useCase]: detection.title,
      }),
    )
    if (!showOpenTicketOnly) {
      searchParams.set('showOpenTicketOnly', 'false')
    }

    navigateUserType(
      isDWEmployee,
      `${Paths.TICKET_LIBRARY}?${searchParams.toString()}`,
      navigate,
      customerShortName,
    )
  }

  const handleViewAllReportsClick = () => {
    const searchParams = new URLSearchParams()
    searchParams.set('useCaseId', detection.useCase)
    searchParams.set('detection', detection.title)

    navigateUserType(
      isDWEmployee,
      `${Paths.THREAT_INTEL_REPORTS}?${searchParams.toString()}`,
      navigate,
      customerShortName,
    )
  }

  const panelCount = (length: number): number | string | undefined => {
    if (length >= 0 && length <= 5) {
      return length
    } else if (length > 5) {
      return `5+`
    } else {
      return 0
    }
  }

  const reviewer = (): JSX.Element => {
    if (detection.reviewer === Reviewer.AUTO_ENRICHED) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {Reviewer.AUTO_ENRICHED}
          </Typography>
          <Tooltip
            title={`The ${Reviewer.AUTO_ENRICHED} assesses detection status before a
            Detection Engineer reviews it.`}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                variant="informationCircleOutline"
                size={18}
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              />
            </Box>
          </Tooltip>
        </Box>
      )
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        {typeof detection.reviewer === 'string' && detection.reviewer ? (
          <>
            <Avatar
              sx={{ fontSize: 8, width: 18, height: 18, marginRight: '4px' }}
              variant="dw"
              data-testid="user-avatar"
            >
              {avatarInitials}
            </Avatar>
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {detection.reviewer}
            </Typography>
          </>
        ) : (
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            Not Applicable
          </Typography>
        )}
      </Box>
    )
  }

  const reviewNote = (): string | string[] | JSX.Element => {
    return detection.reviewNotes && detection.reviewNotes[0] !== '' ? (
      columnValueContent(detection.reviewNotes)
    ) : (
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        No review note
      </Typography>
    )
  }

  const threatIntelReports = (): JSX.Element => {
    if (detection.relatedThreatIntelReports.length > 0) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          {detection.relatedThreatIntelReports.slice(0, 5).map((advisory) => (
            <ThreatIntelReportsAdvisoryCard
              key={advisory.id}
              advisory={advisory}
              onClick={() => handleThreatIntelClick(advisory.id)}
              disabled={!isOpen}
            />
          ))}
          {detection.relatedThreatIntelReports.length > 5 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="text"
                onClick={handleViewAllReportsClick}
                disabled={!isOpen}
                sx={{ gap: 0.5, justifyContent: 'center' }}
              >
                View all threats
                <Icon
                  sx={{ color: colors.brand.secondary.main }}
                  variant="arrowForwardCircleOutline"
                />
              </Button>
            </Box>
          )}
        </Box>
      )
    }
    return (
      <Typography variant="body2">
        This detection has no related threat intelligence reports
      </Typography>
    )
  }

  const tickets = (): JSX.Element => {
    if (relatedOpenTickets.length > 0) {
      const options = relatedOpenTickets.slice(0, 5).map((ticket) => {
        const detectionIcon = formatPriority(ticket.priority, theme)
        return {
          id: ticket.sysId,
          leftIcon: detectionIcon.icon,
          name: ticket.ticketNumber,
          rightText: ticket.state,
          iconColor: detectionIcon.color,
          onClick: () => handleTicketNavigation(ticket.sysId),
          shouldAllowFocus: isOpen,
        }
      }) as ListItemType[]
      return (
        <>
          <List>
            {options.map((option) => {
              return (
                <ListItem
                  key={option.id}
                  onClick={option.onClick}
                  onKeyUp={(event) =>
                    handleKeyboardAction(
                      event,
                      option.onClick ? option.onClick : () => null,
                    )
                  }
                >
                  <ListItemIcon>
                    <Icon
                      variant={option.leftIcon as IconVariant}
                      size={20}
                      sx={{ color: option.iconColor }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name}
                    sx={{ marginLeft: '8px' }}
                  />
                  <ListItemText secondary={option.rightText} />
                </ListItem>
              )
            })}
          </List>
          {relatedOpenTickets.length > 5 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => handleTicketsNavigationClick(true)}
                variant="text"
                disabled={!isOpen}
              >
                View all open tickets
                <Icon variant="arrowForwardCircleOutline" />
              </Button>
            </Box>
          )}
        </>
      )
    } else {
      return (
        <Typography variant="body2">
          This detection has no related open tickets
        </Typography>
      )
    }
  }

  const detailsPanelContent = [
    {
      key: 'Total activity',
      label: 'Total activity',
      value: getTotalActivityDetailValue(),
    },
    {
      key: 'Source type',
      label: 'Source type',
      value: columnValueContent(detection.logSource),
    },
    {
      key: 'MITRE Tactic',
      label: 'MITRE Tactic',
      value: columnValueContent(detection.mitreTactics),
    },
    {
      key: 'MITRE Technique',
      label: 'MITRE Technique',
      value: columnValueContent(detection.mitreTechniques),
    },
    {
      key: 'Technology type',
      label: 'Technology type',
      value: columnValueContent(detection.dataTypes),
    },
    {
      key: 'Detection ID',
      label: 'Detection ID',
      value: columnValueContent(detection.useCase),
    },
    {
      key: 'Min DWA version',
      label: 'Min DWA version',
      value: columnValueContent(`v${detection.releaseVersion}`),
    },
  ]

  const enablementDetails = [
    {
      key: 'Status',
      label: 'Status',
      value: <DetectionCatalogStatusTag status={detectionTag.status} />,
    },
    ...(detection.statusDetails
      ? [
          {
            key: 'Status details',
            label: 'Status details',
            value: detection.statusDetails,
          },
        ]
      : []),

    {
      key: 'Review note',
      label: 'Review note',
      value: reviewNote(),
    },
    {
      key: 'Reviewer',
      label: 'Reviewer',
      value: reviewer(),
    },
    {
      key: 'Reviewed on',
      label: 'Reviewed on',
      value: columnValueContent(
        detection.reviewedOn
          ? String(format(new Date(detection.reviewedOn), 'MMM d, yyyy'))
          : '',
      ),
    },
    ...(detection.status === DetectionStatusType.TRIAL
      ? [
          {
            key: 'In Trial Mode',
            label: 'In Trial Mode',
            value: getDaysInTrialMode(),
          },
        ]
      : []),
  ]

  return (
    <Box
      id="detections-catalog-sidesheet"
      data-testid="detections-catalog-sidesheet-test-id"
    >
      <Box data-testid="detection-description-info" sx={{ padding: '1rem' }}>
        <Typography
          fontWeight={600}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {formatEmptyField(detection.title)}
        </Typography>
        <Typography variant="body2">
          {formatEmptyField(detection.description)}
        </Typography>
      </Box>

      <CollapsiblePanel title="Enablement" shouldAllowFocus={isOpen}>
        <SidesheetDualColumn items={enablementDetails} />
      </CollapsiblePanel>

      <CollapsiblePanel
        title="Open Tickets"
        count={panelCount(relatedOpenTickets.length)}
        shouldAllowFocus={isOpen}
      >
        {tickets()}
      </CollapsiblePanel>

      {!hideThreatIntelSection && (
        <CollapsiblePanel
          title="Threat intel"
          count={panelCount(detection.relatedThreatIntelReports.length)}
          shouldAllowFocus={isOpen}
        >
          {threatIntelReports()}
        </CollapsiblePanel>
      )}

      <CollapsiblePanel title="Details" shouldAllowFocus={isOpen}>
        <SidesheetDualColumn items={detailsPanelContent} />
      </CollapsiblePanel>
    </Box>
  )
}

export default DetectionSideSheetContent
