import { Box, useTheme } from '@mui/material'

interface LoaderProps {
  centered?: boolean
  color?: string
  strokeWidth?: number
  size?: number
}

const Loader: React.FC<LoaderProps> = ({
  centered = false,
  color,
  strokeWidth = 4,
  size = 14,
}) => {
  const theme = useTheme()
  const pulseKeyFrame = `#ripple-loader-${size} {
    position: relative;
    width: ${size}px;
    height: ${size}px;
    ${centered ? 'margin-right:auto;margin-left:auto;' : ''}
  }

  #ripple-loader-${size} div {
    position: absolute;
    border: ${strokeWidth}px solid ${color ? color : theme.vars.palette.card.main};
    border-radius: 50%;
    animation: ripple-loader-${size} 1.5s ease-out infinite;
  }

  #ripple-loader-${size} div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes ripple-loader-${size} {
    0% {
      top: ${size / 2}px;
      left: ${size / 2}px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: ${size}px;
      height: ${size}px;
      opacity: 0;
    }
  }`

  return (
    <>
      <style>{pulseKeyFrame}</style>
      <Box id={`ripple-loader-${size}`} data-testid="ripple-loader">
        <Box></Box>
        <Box></Box>
      </Box>
    </>
  )
}

export default Loader
