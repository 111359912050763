import { Typography } from '@mui/material'

export const BetaTag = () => (
  <Typography
    component={'span'}
    data-testid="tag-new-beta"
    sx={(theme) => ({
      letterSpacing: '1px',
      marginLeft: '4px',
      textTransform: 'uppercase',
      padding: ' .125rem .25rem',
      borderRadius: '.125rem',
      height: '1.1rem',
      alignItems: 'center',
      alignSelf: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
      minWidth: 'unset',
      fontSize: '.75rem',
      borderColor: theme.vars.palette.card.main,

      '@supports (-webkit-text-fill-color: transparent) and (background-clip: text) and (mask: linear-gradient(#fff 0 0)) and (mask-composite: exclude)':
        {
          border: 0,
          background: `linear-gradient(225deg, ${theme.vars.palette.important.main}  0%, ${theme.vars.palette.primary.dark} 100%)`,
          backgroundClip: 'text',
          position: 'relative',
          '-webkit-text-fill-color': 'rgba(0,0,0,0)',
          '&:before': {
            background: `linear-gradient(225deg, ${theme.vars.palette.important.main} 0%, ${theme.vars.palette.primary.dark} 100%) border-box`,
            border: '1px solid transparent',
            borderRadius: '0.125rem',
            content: '""',
            inset: 0,
            mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            position: 'absolute',
          },
        },
    })}
  >
    Beta
  </Typography>
)
