import { CustomSVGProps } from './types'

const StatusNew: React.FC<CustomSVGProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="1 5"
    />
  </svg>
)

export default StatusNew
