import { useCustomer } from './useCustomer'

export const addCustomerParamToPath = (
  pathname: string,
  shortName?: string | false,
) => {
  const { searchParams, pathname: parsedPathname } = new URL(
    pathname,
    window.location.origin,
  )
  if (shortName) {
    searchParams.set('customer', shortName)
  }
  return `${parsedPathname}?${searchParams.toString()}`
}

export const useDWExpertEnabledUrl = (pathname?: string): string | null => {
  const {
    isDWEmployee,
    customer: { customerShortName },
  } = useCustomer()

  if (!pathname) {
    return null
  }

  return isDWEmployee
    ? addCustomerParamToPath(pathname, customerShortName)
    : pathname
}
