import { Box, Button, SxProps, Theme, Typography } from '@mui/material'

export interface ComponentErrorProps {
  errorText?: string
  errorSubText?: string
  includeReloadButton?: boolean
  errorIcon?: string
  errorContainerStyles?: SxProps<Theme> | undefined
  errorIconStyles?: SxProps<Theme> | undefined
}

const ComponentError: React.FC<ComponentErrorProps> = ({
  errorText,
  errorSubText,
  includeReloadButton,
  errorIcon,
  errorContainerStyles,
  errorIconStyles,
}) => {
  const refreshClick = () => {
    window.location.reload()
  }
  return (
    <Box
      id="component-error"
      sx={{
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '1rem',
        textAlign: 'center',
        ...errorContainerStyles,
      }}
    >
      {errorIcon && (
        <Box
          component="img"
          color="error"
          src={errorIcon}
          alt="Error"
          id="error-icon"
          className="image"
          data-testid="error-image"
          sx={{ width: '25%', margin: '0 auto', ...errorIconStyles }}
        />
      )}
      <Typography variant="h5" color="textPrimary">
        {errorText || 'We were unable to fetch data.'}
      </Typography>

      <Typography color="textPrimary" variant="body1">
        {errorSubText ||
          'If the issue persists and you think this is an error, please reach out to your customer success manager to discuss further.'}
      </Typography>

      {includeReloadButton && (
        <Button onClick={refreshClick}>Reload page</Button>
      )}
    </Box>
  )
}

export default ComponentError
