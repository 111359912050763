import { intervalToDuration } from 'date-fns'

import { MetricType } from '@models/CyberAssistant'

function formatUnit(
  value: number,
  singular: string,
  capitalized: boolean = false,
): string {
  const roundedValue = Number.isInteger(value)
    ? value.toString()
    : value.toFixed(1)
  const unit = value === 1 ? singular : `${singular}s`
  const formattedUnit = capitalized
    ? unit[0].toUpperCase() + unit.slice(1)
    : unit
  return `${roundedValue} ${formattedUnit}`
}

function calculateDuration(seconds: number): { [key: string]: number } {
  const now = new Date()
  const targetDate = new Date(now.getTime() + seconds * 1000)
  const duration = intervalToDuration({ start: now, end: targetDate })

  return {
    years: duration.years || 0,
    months: duration.months || 0,
    days: duration.days || 0,
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
    milliseconds: Math.floor((seconds % 1) * 1000),
  }
}

export function formatSeconds(seconds: number): string {
  const duration = calculateDuration(seconds)

  if (seconds < 60) {
    return `${formatUnit(duration.seconds, 'second')} ${formatUnit(duration.milliseconds, 'millisecond')}`
  } else if (seconds < 3600) {
    return `${formatUnit(duration.minutes, 'minute')} ${formatUnit(duration.seconds, 'second')}`
  } else if (seconds < 86400) {
    return `${formatUnit(duration.hours, 'hour')} ${formatUnit(duration.minutes, 'minute')}`
  } else if (seconds < 2592000) {
    return `${formatUnit(duration.days, 'day')} ${formatUnit(duration.hours, 'hour')}`
  } else if (seconds < 31536000) {
    return `${formatUnit(duration.months, 'month')} ${formatUnit(duration.days, 'day')}`
  } else {
    return `${formatUnit(duration.years, 'year')} ${formatUnit(duration.months, 'month')}`
  }
}

export function formatYAxisTime(seconds: number): string {
  if (seconds < 60) {
    return formatUnit(seconds, 'Sec', true)
  } else if (seconds < 3600) {
    const minutes = seconds / 60
    return formatUnit(minutes, 'Min', true)
  } else if (seconds < 86400) {
    const hours = seconds / 3600
    return formatUnit(hours, 'Hr', true)
  } else if (seconds < 2592000) {
    const days = seconds / 86400
    return formatUnit(days, 'Day', true)
  } else if (seconds < 31536000) {
    const months = seconds / 2592000
    return formatUnit(months, 'Mo', true)
  } else {
    const years = seconds / 31536000
    return formatUnit(years, 'Yr', true)
  }
}

export const metricDescriptions: Record<MetricType, string> = {
  [MetricType.MTTD]: `MTTD is the mean time taken to detect a security event of interest with either technology-based detection logic or human observation.

          MTTD is measured from the time the event occurs to the time the alert triggers. Security notables could be in the form of a ticket (human observation) or in a security appliance.

          Note: If there are multiple triggering events that trigger the alert then we capture the timestamp of the first.`,
  [MetricType.MTTR]: `MTTR is the mean time it takes for Deepwatch and/or your team to take action on a threat by remediation or mitigation efforts.

  MTTR is measured in our ticket management system using the original ticket (where Deepwatch or the customer reported the activity) and the supporting children tickets to respond to the threat (using tools that have the ability to mitigate or remediate).`,
  [MetricType.MTTCACK]:
    'MTTCAck is the mean time for a customer to acknowledge a ticket in the ticket management system. It is captured the first time a customer updates a ticket (by updating a field or adding a comment).',
}
