import { MetricType } from '@models/CyberAssistant'

import { AnswerId } from './CyberAssistantQuestions'
import { EvaluateMitreCoverage } from './MitreCoverage'
import { MTTContainer } from './MTTContainer'
import { SecurityIndexAnswer } from './SecurityIndexAnswer'
import { TimeSaved } from './TimeSaved'
import { VendorUsageTable } from './VendorUsageTable'
import { QuarterlyTruePositiveAnswer } from './QuarterlyTruePositiveAnswer'

export const getAnswerComponent = (
  answerId: AnswerId,
  selectedCustomer: string | null,
) => {
  switch (answerId) {
    case AnswerId.TIME_SAVED:
      return <TimeSaved selectedCustomer={selectedCustomer} />
    case AnswerId.VENDOR_USAGE:
      return <VendorUsageTable />
    case AnswerId.SI_SCORE:
      return <SecurityIndexAnswer selectedCustomer={selectedCustomer ?? ''} />
    case AnswerId.MTTD:
      return (
        <MTTContainer
          selectedCustomer={selectedCustomer}
          metric={MetricType.MTTD}
        />
      )
    case AnswerId.MTTR:
      return (
        <MTTContainer
          selectedCustomer={selectedCustomer}
          metric={MetricType.MTTR}
        />
      )
    case AnswerId.MTTCACK:
      return (
        <MTTContainer
          selectedCustomer={selectedCustomer}
          metric={MetricType.MTTCACK}
        />
      )
    case AnswerId.TP_FP:
      return <QuarterlyTruePositiveAnswer selectedCustomer={selectedCustomer} />
    case AnswerId.MITRE:
      return <EvaluateMitreCoverage selectedCustomer={selectedCustomer} />
    default:
      return null
  }
}
