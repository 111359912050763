import { ColorSystemOptions } from '@mui/material'

export default {
  palette: {
    mode: 'dark',
    background: {
      default: '#27373c',
    },
    primary: {
      lighter: '#398fad',
      light: '#a7dff1',
      main: '#3ab6e0',
      dark: '#1c8bb0',
    },
    secondary: {
      lighter: '#455358',
      light: '#36474c',
      main: '#2e3d42',
      dark: '#27373c',
      darker: '#1c2628',
      contrastText: '#eef6f9',
    },
    text: {
      primary: '#a2bbc3',
      secondary: '#eef6f9',
      disabled: '#51646a',
    },
    success: {
      light: '#e5fff2',
      main: '#9fc',
      dark: '#0c6',
    },
    warning: {
      light: '#fff1dd',
      main: '#ffb470',
      dark: '#ff9431',
    },
    error: {
      lighter: '#ffe5ee',
      light: '#ff99b9',
      main: '#ff4d85',
      dark: '#cc0041',
      darker: '#990030',
    },
    severity: {
      info: '#eef6f9',
      low: '#29b6f6',
      medium: '#ffd046',
      high: '#fa9500',
      critical: '#ff4d85',
    },
    important: {
      main: '#c5a2f6',
    },
    brand: {
      main: '#0c6',
    },
    avatar: {
      cust: 'linear-gradient(rgb(255 153 185 / 100%), rgb(204 0 65 / 100%))',
      dw: 'linear-gradient(rgb(0 204 102 / 100%), rgb(0 102 51 / 100%))',
    },
    card: {
      light: '#b9af71',
      main: '#a2bbc3',
    },
  },
} as ColorSystemOptions
