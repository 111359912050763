import { gql } from '@apollo/client'

import {
  ReportType,
  ThreatIntelReport,
  ThreatIntelReportDetail,
  ThreatIntelReportLabel,
} from '@models/ThreatIntel'
import { Pagination, PaginationInput } from '@models/index'

import {
  THREAT_INTEL_REPORT_DATA_FRAGMENT,
  THREAT_INTEL_REPORT_SECTION_FRAGMENT,
} from '../fragments/threatIntel'

export enum ThreatIntelReportSortOptions {
  Title = 'TITLE',
  Type = 'TYPE',
  Date = 'DATE',
}

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum DashboardBanKeys {
  MTTN = 'mean-time-to-notify-sec',
  MTTCAck = 'mean-time-to-customer-ack-sec',
  TECHNIQUE_COVERED = 'technique-covered',
  POSITIVES = 'positives',
}

export interface ThreatIntelReportsData {
  threatIntelReports: ThreatIntelReport[]
  pagination: Pagination
}

export interface GetThreatIntelReportsData {
  getThreatIntelReports: ThreatIntelReportsData
}

export interface GetThreatIntelReportData {
  getThreatIntelReport: ThreatIntelReportDetail
}

export interface GetDashboardThreatIntelReportsData {
  getDashboardThreatIntelReports: ThreatIntelReport[]
}

export interface ThreatIntelReportByUseCaseId {
  id: string
  name: string
}

export interface GetThreatIntelReportsInput {
  filters?: {
    labels: ThreatIntelReportLabel[]
    detection?: ThreatIntelReportByUseCaseId[]
  }
  keywordSearch?: string
  sortBy?: ThreatIntelReportSortOptions
  sortDirection?: SortDirection
  pagination: PaginationInput
  reportTypes?: ReportType[]
}

export interface GetThreatIntelReportsVariables {
  selectedCustomer: string | null
  input: GetThreatIntelReportsInput
}

export interface GetThreatIntelReportVariables {
  id: string
}
export interface GetDashboardThreatIntelReportsVariables {
  selectedCustomer: string | null
}

export const GET_THREAT_INTEL_REPORTS_LIST_QUERY = gql`
  ${THREAT_INTEL_REPORT_DATA_FRAGMENT}
  query GetThreatIntelReports(
    $selectedCustomer: String
    $input: ThreatIntelReportsInput
  ) {
    getThreatIntelReports(selectedCustomer: $selectedCustomer, input: $input) {
      threatIntelReports {
        ...ThreatIntelReportData
      }
      pagination {
        offset
        limit
        total
      }
    }
  }
`

export const GET_THREAT_INTEL_REPORT_QUERY = gql`
  ${THREAT_INTEL_REPORT_DATA_FRAGMENT}
  ${THREAT_INTEL_REPORT_SECTION_FRAGMENT}
  query GetThreatIntelReport($reportId: String, $selectedCustomer: String) {
    getThreatIntelReport(
      reportId: $reportId
      selectedCustomer: $selectedCustomer
    ) {
      ...ThreatIntelReportData
      sections {
        ...ThreatIntelReportSection
      }
    }
  }
`

export const GET_DASHBOARD_THREAT_INTEL_REPORTS_LIST_QUERY = gql`
  ${THREAT_INTEL_REPORT_DATA_FRAGMENT}
  query GetDashboardThreatIntelReports($selectedCustomer: String) {
    getDashboardThreatIntelReports(selectedCustomer: $selectedCustomer) {
      ...ThreatIntelReportData
    }
  }
`
