import { useRef } from 'react'
import { Box, Tooltip } from '@mui/material'

import { zIndex } from '@components/App/Styles/zIndex'

import type { SxStyle } from '@mui-theme/types'

const sideSheetResizeCss: Record<string, SxStyle> = {
  handleBorder: (theme) => ({
    position: 'absolute',
    width: '1px',
    left: 0,
    height: '100%',
    backgroundColor: theme.vars.palette.text.primary,
    transition: 'width 200ms',
    zIndex: zIndex.RESIZABLE_SIDESHEET_BORDER,
    '&.active': {
      width: '3px',
    },
    ...theme.applyStyles('dark', {
      backgroundColor: theme.vars.palette.text.secondary,
    }),
  }),
  draggableContainer: {
    '&:hover': {
      '#appsidesheet-handle-border': {
        width: '3px',
      },
    },
  },
  handle: {
    position: 'absolute',
    width: '15px',
    left: '-7px',
    height: '100%',
    cursor: 'ew-resize',
    opacity: 0,
    zIndex: zIndex.RESIZABLE_SIDESHEET_BORDER,
    '&:focus-visible': {
      opacity: 1,
    },
  },
}

interface ResizableHandleProps {
  open: boolean
  sideSheetRef: React.RefObject<HTMLElement>
}

const updateWidth = (
  startingWidth: number,
  originClientX: number,
  eClientX: number,
) => startingWidth + originClientX - eClientX

export const ResizableHandle: React.FC<ResizableHandleProps> = ({
  open,
  sideSheetRef: ref0,
}) => {
  const sideSheetBorderRef = useRef<HTMLDivElement>(null)
  const startingMouseX = useRef<number>(0)
  const startingSideSheetWidth = useRef<number>(0)

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    // remove accessibility focus styling for drag handle
    if (
      document.activeElement &&
      document.activeElement.id === 'appsidesheet-handle'
    ) {
      const handle = document.activeElement as HTMLElement
      handle.blur()
    }
    // maintain hover style while dragging
    if (sideSheetBorderRef.current) {
      sideSheetBorderRef.current.classList.add('active')
    }
    // store starting cursor position for sidesheet width updates during onDrag
    startingMouseX.current = e.clientX
    // store sidesheet container width for sidesheet width updates during onDrag
    if (ref0.current) {
      startingSideSheetWidth.current = ref0.current.offsetWidth
    }
  }

  return (
    <Tooltip
      title={
        <>
          <b>Drag</b> to resize, <b>Click</b> to reset
        </>
      }
      placement="left"
      followCursor
    >
      <Box
        sx={sideSheetResizeCss.draggableContainer}
        data-testid="appsidesheet-draggable-container"
      >
        <Box
          ref={sideSheetBorderRef}
          sx={sideSheetResizeCss.handleBorder}
          id="appsidesheet-handle-border"
          data-testid="appsidesheet-handle-border"
        />
        <Box
          sx={sideSheetResizeCss.handle}
          data-testid="appsidesheet-handle"
          draggable="true"
          role="button"
          tabIndex={open ? 0 : -1}
          onDragStart={handleDragStart}
          onDrag={(e) => {
            /**
             * the drag event's final clientX update will always be 0 without dropping the dragged
             * element over a valid drop target element; this will not be dropped on a valid drop
             * target so this will prevent erroneous update
             */
            if (ref0.current && e.clientX !== 0) {
              /**
               * every update from the drag event should modify the sidesheet's width to be the
               * starting sidesheet width + the starting x coordinate of the cursor - the current
               * x coordinate of the cursor
               */
              ref0.current.style.width = `${updateWidth(
                startingSideSheetWidth.current,
                startingMouseX.current,
                e.clientX,
              )}px`
            }
          }}
          onDragEnd={() => {
            // remove "active" style
            if (sideSheetBorderRef.current) {
              sideSheetBorderRef.current.classList.remove('active')
            }
          }}
          onClick={() => {
            // reset the sidesheet width
            if (ref0.current) {
              ref0.current.style.width = '450px'
            }
          }}
          onKeyDown={(e) => {
            // accessibility for reseting the sidesheet width
            if (ref0.current && e.key === 'Enter') {
              ref0.current.style.width = '450px'
            }
          }}
        />
      </Box>
    </Tooltip>
  )
}
