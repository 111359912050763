import { Link, SxProps, Theme, Typography } from '@mui/material'

export const SERVICE_NOW_CUSTOMER_SERVICE_URL =
  'https://deepwatch.service-now.com/csm?id=sc_category&sys_id=f60aecac1b122410b05ffd9f034bcbbf'

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
    whiteSpace: 'pre-line',
    textWrap: 'wrap',
  }),
})

export const EmptyDataAnswer = () => {
  return (
    <Typography
      variant="body1"
      sx={(theme) => ({
        ...typographyContent(theme),
      })}
    >
      We couldn’t load the data to answer this question right now.
      <br /> <br />
      If this request is repeatedly failing, you may want to{' '}
      <Link
        href={SERVICE_NOW_CUSTOMER_SERVICE_URL}
        rel="noopener noreferrer"
        target="_blank"
        variant="body1"
      >
        create a ticket
      </Link>{' '}
      so your team of Deepwatch Experts can investigate the issue further.
    </Typography>
  )
}
