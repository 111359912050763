import { CellContext } from '@tanstack/react-table'
import { Chip, Typography } from '@mui/material'

import { Report } from '@models/Report'

interface ReportNameCellValues {
  name: Report['name']
  numberDownloadAttempt: Report['numberDownloadAttempt']
  status: Report['status']
}

const ReportNameCell = (props: CellContext<Report, ReportNameCellValues>) => {
  const { name, numberDownloadAttempt, status } = props.getValue()

  const isNew = numberDownloadAttempt === 0 && status === 'Generated'

  return (
    <div style={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
      <Typography
        fontWeight={status !== 'Generated' ? 400 : 600}
        noWrap
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            color:
              status !== 'Generated'
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {name}
      </Typography>

      {isNew && (
        <Chip
          sx={[
            (theme) => ({
              border: 0,
              '&.MuiChip-outlined': {
                backgroundColor: theme.vars.palette.primary.main,
                borderColor: theme.vars.palette.secondary.main,
              },
              backgroundColor: theme.vars.palette.primary.light,
              '&.MuiChip-root': {
                height: '100%',
              },
              ...theme.applyStyles('dark', {
                '&.MuiChip-outlined': {
                  color: theme.vars.palette.text.primary,
                  backgroundColor: theme.vars.palette.secondary.darker,
                },
              }),
            }),
          ]}
          variant="outlined"
          label="NEW"
        />
      )}
    </div>
  )
}

export default ReportNameCell
