import { Box, Skeleton, Tooltip, Typography } from '@mui/material'
import React from 'react'

import Icon from '@common/Icon'

interface BanLayoutCommon {
  caption?: string
  children?: React.ReactNode
  error?: boolean
  loading: boolean
  title: string
  value: string | JSX.Element
  delta?: number
  deltaSuffix?: string
  externalLink?: boolean
}

const BanLayoutCommon: React.FC<BanLayoutCommon> = ({
  caption,
  children,
  error = false,
  loading,
  title,
  value,
  externalLink = false,
}) => {
  const renderContent = () => {
    if (loading) {
      return (
        <Skeleton
          data-testid="skeleton-block"
          height={28}
          sx={{ width: '100%' }}
        />
      )
    }

    if (error) {
      return (
        <Typography color="error" fontWeight={600} variant="h5">
          Error
        </Typography>
      )
    }

    return (
      <>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          fontWeight={600}
          variant="h5"
        >
          {value}
        </Typography>

        {children}
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <Typography
            fontWeight={500}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
            variant="caption"
          >
            {title.toUpperCase()}
          </Typography>

          {externalLink && (
            <Icon
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
              })}
              size={14}
              variant="openOutline"
            />
          )}
        </Box>

        {caption && (
          <Tooltip
            title={caption}
            placement="top-start"
            followCursor
            sx={{ cursor: 'default' }}
          >
            <Box>
              <Icon
                size={18}
                sx={(theme) => ({
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.primary,
                  }),
                  color: theme.vars.palette.secondary.contrastText,
                })}
                variant="informationCircleOutline"
              />
            </Box>
          </Tooltip>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {renderContent()}
        </Box>
      </Box>
    </>
  )
}

export default BanLayoutCommon
