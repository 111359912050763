import { useContext, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Navigate, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import rehypeExternalLinks from 'rehype-external-links'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button, Typography } from '@mui/material'

import { Paths, Terms } from '@components/App/Types'
import { ErrorFallback } from '@components/App/Errors/ErrorFallback'
import { Loading } from '@components/Loading'
import { tosVersion } from '@components/Routes/userInitConstants'
import { oktaAuthConfig, SessionStatus } from '@config/OktaAuthConfig'
import { deleteCookie, setCookie } from '@utils/Cookies'
import { AlertSeverity, useToast } from '@hooks/useToast'
import { CREATE_ACCEPTED_TERMS } from '@mutations/user'
import {
  Context,
  setSession,
  setTermsAccepted,
  setUser,
} from '@components/App/index'
import tosContent from '@app-assets/termsofservice.md'
import { Dialog } from '@common/Dialog'

import './TermsOfService.scss'

//? To test that the text would render in the component.
type TermsOfServiceProps = {
  bodyContent?: string
}
const title = 'Terms of Service'

const TermsOfService = ({ bodyContent }: TermsOfServiceProps): JSX.Element => {
  const modalChildrenContainerRef = useRef<HTMLElement | null>(null)
  const [userScrolled, setUserScrolled] = useState(false)
  const navigate = useNavigate()
  const {
    state: {
      homePath,
      user: { isDWEmployee },
    },
    dispatch,
  } = useContext(Context)
  const { featureNgMdr } = useFlags()
  const { handleShowToast } = useToast()

  const [createAcceptedTerms, { loading, error }] = useMutation(
    CREATE_ACCEPTED_TERMS,
    {
      variables: {
        acceptedVersion: tosVersion,
      },
      onError: () => {
        dispatch(setTermsAccepted(Terms.DECLINED))
        handleShowToast(
          AlertSeverity.Error,
          'There was an error accepting terms of service',
          5000,
        )
      },
      onCompleted: (data) => {
        if (data) {
          dispatch(setTermsAccepted(Terms.ACCEPTED))
          deleteCookie('ct') // remove cancelled terms cookie in case of sign out and the login shows incorrect inline message about cancelling terms
          const path = featureNgMdr ? Paths.MDR : Paths.DASHBOARD
          navigate(path)
        }
      },
    },
  )

  /**
   * This effect observes the body for the modal children container to be added to the DOM.
   * Once it is added, it attaches a scroll event listener to the container to enable the
   * "Agree to terms" button once the user has scrolled to the bottom of the terms.
   *
   * This is necessary because `Dialog` does not immediately render its children.
   */
  useEffect(() => {
    const targetNode = document.body
    const config = { childList: true, subtree: true }

    const checkForModalChildren = (
      mutationsList: MutationRecord[],
      observer: MutationObserver,
    ) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          modalChildrenContainerRef.current = document.getElementById(
            'modalchildrencontainer',
          )
          if (modalChildrenContainerRef.current) {
            observer.disconnect()
            modalChildrenContainerRef.current.addEventListener(
              'scroll',
              handleScroll,
              {
                passive: true,
              },
            )
            break
          }
        }
      }
    }
    const observer = new MutationObserver(checkForModalChildren)
    observer.observe(targetNode, config)
    return () => {
      observer.disconnect()
      if (modalChildrenContainerRef.current) {
        modalChildrenContainerRef.current.removeEventListener(
          'scroll',
          handleScroll,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = (e: Event) => {
    const targetElement = e.target as HTMLElement
    const buffer = 10
    const bottom =
      Math.ceil(
        targetElement.clientHeight + targetElement.scrollTop + buffer,
      ) >= targetElement.scrollHeight
    if (bottom) {
      setUserScrolled(true)
    }
  }

  const handleCancelTerms = () => {
    dispatch(setTermsAccepted(Terms.DECLINED))
    dispatch(setSession(SessionStatus.INACTIVE))
    dispatch(
      setUser({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        isDWEmployee: false,
        isAdmin: false,
      }),
    )
    navigate(Paths.LOGIN)
    oktaAuthConfig.closeSession()
    deleteCookie('iav')
    setCookie('ct', '1', new Date(Date.now() + 10 * 1000))
  }

  if (isDWEmployee) {
    return <Navigate to={homePath} />
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorFallback />
  }

  return (
    <>
      <Dialog
        title={title}
        renderTitle={({ title }) => (
          <div id="tos-header-container">
            <Typography
              color="textPrimary"
              sx={{
                marginInline: 0,
                marginBlock: 0,
              }}
              noWrap={true}
              variant="h6"
            >
              {title}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                color="textPrimary"
                sx={{ marginBlock: 0 }}
                variant="caption"
              >
                {`VERSION ${tosVersion}`}
              </Typography>
            </div>
          </div>
        )}
        isOpen={true}
        onClose={() => {}}
        slotProps={{
          backdrop: {
            style: {
              backgroundImage: `url(${require('@app-assets/frostedDeepwatch.svg')})`,
              backgroundSize: 'cover',
              transition: '225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              backdropFilter: 'blur(12px)',
              backgroundColor: 'rgb(28 28 28 / 54%)',
            },
          },
        }}
      >
        <article data-testid="tos-modal" id="dw-terms-of-service">
          <div id="terms-of-service-container" tabIndex={-1}>
            <div id="modalchildrencontainer" className="content">
              <Typography
                component="div"
                sx={(theme) => ({
                  '> p': { color: theme.palette.text.primary },
                })}
              >
                {bodyContent === undefined && (
                  <ReactMarkdown
                    className="termsofservicemarkdown"
                    rehypePlugins={[
                      [
                        rehypeExternalLinks,
                        {
                          target: '_blank',
                          rel: ['noopener', 'noreferrer'],
                        },
                      ],
                    ]}
                  >
                    {tosContent}
                  </ReactMarkdown>
                )}
                {bodyContent && bodyContent}
              </Typography>
            </div>
            <div className="footer-btn">
              <Button variant="outlined" onClick={handleCancelTerms}>
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={() => createAcceptedTerms()}
                disabled={userScrolled === false}
              >
                Agree to terms
              </Button>
            </div>
          </div>
        </article>
      </Dialog>
    </>
  )
}

export default TermsOfService
