import { useState } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'

import { IndexTrendTitles } from '../IndexTrend'

const TrendSelector = ({ setChartTrends }) => {
  const [toggleState, setToggleState] = useState({
    industry: false,
    deepwatch: false,
    past: false,
  })

  const handleToggle = (trendTitle: string, toggleKey: string) => {
    setChartTrends(trendTitle, !toggleState[toggleKey])
    setToggleState({
      ...toggleState,
      [toggleKey]: !toggleState[toggleKey],
    })
  }

  return (
    <Box id="trend-selector" sx={{ position: 'relative' }}>
      <FormControlLabel
        tabIndex={0}
        labelPlacement="start"
        onKeyUp={({ code }: React.KeyboardEvent) =>
          code === 'Enter' &&
          handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
        }
        sx={(theme) => ({
          justifyContent: 'space-between',
          '.MuiFormControlLabel-label': {
            border: 'none',
            padding: 0,
            margin: 0,
          },
          ...theme.applyStyles('dark', {
            '.MuiFormControlLabel-label': {
              color: toggleState.industry
                ? theme.vars.palette.text.secondary
                : theme.vars.palette.text.primary,
            },
          }),
        })}
        control={
          <Switch
            onClick={() =>
              handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
            }
            onKeyUp={({ code }: React.KeyboardEvent) =>
              code === 'Enter' &&
              handleToggle(IndexTrendTitles.AVG_INDUSTRY, 'industry')
            }
            checked={toggleState.industry}
            tabIndex={-1}
          />
        }
        label="Industry average"
      />
      <FormControlLabel
        tabIndex={0}
        labelPlacement="start"
        onKeyUp={({ code }: React.KeyboardEvent) =>
          code === 'Enter' &&
          handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
        }
        sx={(theme) => ({
          justifyContent: 'space-between',
          '.MuiFormControlLabel-label': {
            border: 'none',
            padding: 0,
            margin: 0,
          },
          ...theme.applyStyles('dark', {
            '.MuiFormControlLabel-label': {
              color: toggleState.industry
                ? theme.vars.palette.text.secondary
                : theme.vars.palette.text.primary,
            },
          }),
        })}
        control={
          <Switch
            onClick={() =>
              handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
            }
            onKeyUp={({ code }: React.KeyboardEvent) =>
              code === 'Enter' &&
              handleToggle(IndexTrendTitles.AVG_DW_CUSTOMER, 'deepwatch')
            }
            checked={toggleState.deepwatch}
            tabIndex={-1}
          />
        }
        label="Deepwatch average"
      />
      <FormControlLabel
        tabIndex={0}
        labelPlacement="start"
        onKeyUp={({ code }: React.KeyboardEvent) =>
          code === 'Enter' && handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')
        }
        sx={(theme) => ({
          justifyContent: 'space-between',
          '.MuiFormControlLabel-label': {
            border: 'none',
            padding: 0,
            margin: 0,
          },
          ...theme.applyStyles('dark', {
            '.MuiFormControlLabel-label': {
              color: toggleState.industry
                ? theme.vars.palette.text.secondary
                : theme.vars.palette.text.primary,
            },
          }),
        })}
        control={
          <Switch
            onClick={() => handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')}
            onKeyUp={({ code }: React.KeyboardEvent) =>
              code === 'Enter' &&
              handleToggle(IndexTrendTitles.PAST_PERIOD, 'past')
            }
            checked={toggleState.past}
            tabIndex={-1}
          />
        }
        label="Past period"
      />
    </Box>
  )
}
export default TrendSelector
