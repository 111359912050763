import { gql } from '@apollo/client'

import { Customer } from '@models/index'

export interface CustomerNamesResponse {
  customerInfo: Customer
}

export interface CustomerInfoData {
  customerInfo: Customer
}

export interface CustomerInfoVariables {
  selectedCustomer: string | null
}

export const GET_CUSTOMER_INFO_WITH_TA_VERSIONS = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      customerDwaAppVersion
      deepwatchLatestDwaAppVersion
    }
  }
`

/**
 * Until we remove the featureEnvHealth and featureBetaAgreement flags,
 * any update to this query should be examined for compatibility with
 * the other queries which share this comment.
 *
 * See `selectCustomerInfoQueryByFeatureFlags` in [UserInit.tsx](../../components/Routes/UserInit.tsx)
 */
export const GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      industry
      settings {
        isOktaFederated
      }
      splunkLink
      ngMdrEnabled
      ngMedrEnabled
      ngMfwEnabled
      threatSignalEnabled
      mdrEnabled
      fwEnabled
      vmEnabled
      edrEnabled
      crowdstrikeUrl
      sentinelEnabled
      isLimitedMdrExperience
    }
  }
`

/**
 * Until we remove the featureEnvHealth and featureBetaAgreement flags,
 * any update to this query should be examined for compatibility with
 * the other queries which share this comment.
 *
 * See `selectCustomerInfoQueryByFeatureFlags` in [UserInit.tsx](../../components/Routes/UserInit.tsx)
 */
export const GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_BETA_AGREEMENT = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      industry
      settings {
        isOktaFederated
      }
      splunkLink
      ngMdrEnabled
      ngMedrEnabled
      ngMfwEnabled
      threatSignalEnabled
      mdrEnabled
      fwEnabled
      vmEnabled
      edrEnabled
      crowdstrikeUrl
      sentinelEnabled
      isLimitedMdrExperience
      betaAgreementStatus
      serviceNgMedrTechnology
    }
  }
`

/**
 * Until we remove the featureEnvHealth and featureBetaAgreement flags,
 * any update to this query should be examined for compatibility with
 * the other queries which share this comment.
 *
 * See `selectCustomerInfoQueryByFeatureFlags` in [UserInit.tsx](../../components/Routes/UserInit.tsx)
 */
export const GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      industry
      settings {
        isOktaFederated
      }
      splunkLink
      splunkLicenseType
      splunkDeploymentModel
      splunkVersion
      ngMdrEnabled
      ngMedrEnabled
      ngMfwEnabled
      threatSignalEnabled
      mdrEnabled
      fwEnabled
      vmEnabled
      edrEnabled
      crowdstrikeUrl
      sentinelEnabled
      isLimitedMdrExperience
    }
  }
`

/**
 * Until we remove the featureEnvHealth and featureBetaAgreement flags,
 * any update to this query should be examined for compatibility with
 * the other queries which share this comment.
 *
 * See `selectCustomerInfoQueryByFeatureFlags` in [UserInit.tsx](../../components/Routes/UserInit.tsx)
 */
export const GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA_AND_BETA_AGREEMENT = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      industry
      settings {
        isOktaFederated
      }
      splunkLink
      splunkLicenseType
      splunkDeploymentModel
      splunkVersion
      ngMdrEnabled
      ngMedrEnabled
      ngMfwEnabled
      mdrEnabled
      fwEnabled
      threatSignalEnabled
      vmEnabled
      edrEnabled
      crowdstrikeUrl
      sentinelEnabled
      isLimitedMdrExperience
      betaAgreementStatus
      serviceNgMedrTechnology
    }
  }
`

export const GET_CUSTOMER_INFO_WITH_CROWDSTRIKE_URL = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      industry
      settings {
        isOktaFederated
      }
      splunkLink
      splunkLicenseType
      splunkDeploymentModel
      splunkVersion
      ngMdrEnabled
      ngMedrEnabled
      ngMfwEnabled
      threatSignalEnabled
      mdrEnabled
      fwEnabled
      vmEnabled
      edrEnabled
      crowdstrikeUrl
      sentinelEnabled
      isLimitedMdrExperience
      serviceNgMedrTechnology
    }
  }
`

export const GET_CUSTOMER_INFO_WITH_FULL_DETAILS = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      customerShortName
      customerName
      maturityScoreStartDate
      settings {
        isOktaFederated
      }
      numOfEmployees
      industry
      address {
        street
        city
        state
        postalCode
      }
      splunkLink
    }
  }
`
