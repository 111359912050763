/* eslint-disable @getify/proper-ternary/nested */
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  ColumnFiltersState,
  SortingState,
  ColumnDef,
  RowSelectionState,
} from '@tanstack/react-table'
import { forwardRef, useCallback, useMemo } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Typography, useTheme } from '@mui/material'

import { NoResults } from '@common/NoResults'
import { formatPriority } from '@utils/Common'
import { ErrorLabel } from '@common/ErrorLabel'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import Icon from '@common/Icon'

import { TicketData, TicketRelatedUnion } from '../../../../models'
import TicketIdCell from './cells/TicketIdCell'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '../../../../common/Table/utils/table.utils'
import TicketPriorityCell from './cells/TicketPriorityCell'
import TicketLastUpdatedCell from './cells/TicketLastUpdatedCell'
import TableHeader from '../../../../common/Table/utils/TableHeader'
import PaginationLoadingRow from '../../../../common/Table/components/PaginationLoadingRow'
import { TicketLibraryGlobalFilters } from '../ticketLibraryTypes'

interface TicketLibraryTableProps {
  data: TicketData
  handleScrollToTop: () => void
  loading: boolean
  onBottomReached: () => void
  onColumnFiltersChange?: React.Dispatch<
    React.SetStateAction<ColumnFiltersState>
  >
  onGlobalFilterChange: React.Dispatch<
    React.SetStateAction<TicketLibraryGlobalFilters>
  >
  onRowSelectionChange: React.Dispatch<React.SetStateAction<RowSelectionState>>
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  rowSelection: RowSelectionState
  sorting: SortingState
}

export const TICKET_LIBRARY_TABLE_ID_PREFIX = 'ticket-library-table-row-'

const TicketLibraryTable = forwardRef<HTMLDivElement, TicketLibraryTableProps>(
  (
    {
      data,
      handleScrollToTop,
      loading,
      onBottomReached,
      onColumnFiltersChange,
      onGlobalFilterChange,
      onRowSelectionChange,
      onSortingChange,
      rowSelection,
      sorting,
    },
    tableRef,
  ) => {
    const { featureNgMdr } = useFlags()
    const theme = useTheme()

    const columnDef: ColumnDef<TicketRelatedUnion, any>[] = useMemo(() => {
      const columnHelper = createColumnHelper<TicketRelatedUnion>()
      return [
        {
          id: 'ticketNumber',
          accessorFn: (row) => ({
            ticketNumber: row.ticketNumber,
            shortDescription: row.shortDescription,
          }),
          cell: TicketIdCell,
          header: () => buildGenericHeader('Ticket ID'),
          minSize: 100,
        },
        {
          id: 'priority',
          accessorFn: (row) => formatPriority(row.priority, theme),
          cell: (props) =>
            props.getValue()?.text ? (
              <TicketPriorityCell {...props} />
            ) : (
              <ErrorLabel />
            ),
          header: () => buildGenericHeader('Priority'),
          size: 140,
        },
        columnHelper.accessor('state', {
          id: 'state',
          cell: (props) =>
            props.getValue() ? (
              <div className="ticket-status-cell">
                <Typography
                  color="textSecondary"
                  noWrap
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                  variant="body2"
                >
                  {props.getValue()}
                </Typography>
              </div>
            ) : (
              <ErrorLabel />
            ),
          header: () => buildGenericHeader('Status'),
          size: 200,
        }),
        columnHelper.accessor(featureNgMdr ? 'module' : 'type', {
          id: featureNgMdr ? 'module' : 'type',
          cell: (props) =>
            props.getValue() ? buildGenericCell(props) : <ErrorLabel />,
          header: () => buildGenericHeader(featureNgMdr ? 'Module' : 'Type'),
          size: 100,
        }),
        columnHelper.accessor('contactUser', {
          id: 'contactUser',
          cell: (props) =>
            props.getValue() ? (
              <Typography
                noWrap
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="body2"
              >
                {props.getValue()}
              </Typography>
            ) : (
              <Typography
                noWrap
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="body2"
              >
                --
              </Typography>
            ),
          header: () => buildGenericHeader('Customer Contact'),
          size: 170,
        }),
        columnHelper.accessor('sysCreatedOn', {
          id: 'sysCreatedOn',
          cell: (props) =>
            props.getValue() ? (
              <Typography
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                noWrap
                variant="body2"
              >
                {formatDistanceToNowStrict(new Date(props.getValue()))}
              </Typography>
            ) : (
              <ErrorLabel />
            ),
          header: () => buildGenericHeader('Age'),
          size: 100,
        }),
        columnHelper.accessor('sysUpdatedOn', {
          id: 'sysUpdatedOn',
          cell: (props) =>
            props.getValue() ? (
              <TicketLastUpdatedCell {...props} />
            ) : (
              <ErrorLabel />
            ),
          header: () => buildGenericHeader('Last Updated'),
          size: 130,
        }),
      ]
    }, [featureNgMdr])

    const {
      columnSizing,
      onColumnSizingChange,
      tableHeaderRefs,
      tableContainerRef,
      handleMouseUp,
      handleMouseDown,
      resetColumnSize,
    } = useResizableColumns({
      columnDef,
    })

    const table = useReactTable<TicketRelatedUnion>({
      columns: columnDef,
      data: data.tickets,
      enableMultiRowSelection: false,
      enableSortingRemoval: false,
      state: {
        rowSelection,
        sorting,
        columnSizing,
      },
      onColumnSizingChange,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.sysId,
      manualFiltering: true,
      manualSorting: true,
      onSortingChange: onSortingChange,
      onGlobalFilterChange: onGlobalFilterChange,
      onColumnFiltersChange: onColumnFiltersChange,
      onRowSelectionChange: onRowSelectionChange,
    })

    const handleOnBottomReached = useCallback(
      (containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement

          if (scrollHeight - scrollTop - clientHeight < 300) {
            onBottomReached()
          }
        }
      },
      [onBottomReached],
    )

    if (!loading && data.tickets.length === 0) {
      if (
        data.filterOptions.filters.length > 0 ||
        data.filterOptions.keywordSearch !== ''
      ) {
        return <NoResults />
      }

      return (
        <Box
          data-testid="ticket-library"
          id="ticket-library-no-tickets"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Icon
            size={200}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              fill: theme.vars.palette.secondary.light,
            })}
            variant="ticketsEmptyState"
          />

          <Typography fontWeight={600} sx={{ paddingTop: '1rem' }} variant="h5">
            No open tickets
          </Typography>
          <Typography variant="body1">
            You currently have no open tickets yet! That&apos;s a good thing!
          </Typography>
        </Box>
      )
    }

    return (
      <CommonTableContainer
        data-testid="ticket-library-table-container"
        onScroll={(e) => {
          handleOnBottomReached(e.target as HTMLDivElement)
        }}
        ref={mergeRefs(tableContainerRef, tableRef)}
      >
        <CommonTable>
          <thead data-testid="ticket-library-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<TicketRelatedUnion>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          <tbody data-testid="ticket-library-body">
            {loading
              ? buildSkeletonRows(table.getAllColumns())
              : table
                  .getRowModel()
                  .rows.map((row) => (
                    <MemoizedTanStackTableRow
                      key={row.id}
                      rowId={`${TICKET_LIBRARY_TABLE_ID_PREFIX}${row.id}`}
                      testId="ticket-library-table-row"
                      isSelected={row.getIsSelected()}
                      toggleSelected={row.toggleSelected}
                      visibleCells={row.getVisibleCells()}
                      linkTo={`/tickets/library/${row.original.sysId}/edit`}
                    />
                  ))}

            <PaginationLoadingRow
              colSpan={7}
              currentLength={data.tickets.length}
              pageSize={data.pagination.limit}
              total={data.pagination.total}
              onScrollToTop={handleScrollToTop}
            />
          </tbody>
        </CommonTable>
      </CommonTableContainer>
    )
  },
)

TicketLibraryTable.displayName = 'TicketLibraryTable'

export default TicketLibraryTable
