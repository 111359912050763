/* eslint-disable jsdoc/check-tag-names */
import {
  CellContext,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { forwardRef, useCallback, useMemo } from 'react'
import { ApolloError } from '@apollo/client'

import noDetectionsIcon from '@app-assets/no-detections.svg'
import TableHeader from '@common/Table/utils/TableHeader'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { Detections } from '@models/Detections'
import PaginationLoadingRow from '@common/Table/components/PaginationLoadingRow'
import { NoResults } from '@common/NoResults'
import { Pagination } from '@models/Tickets'
import { ComponentError } from '@common/ComponentError'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'

import {
  formatDetectionTitle,
  formatNoMappingDetection,
  formatDetectionStatus,
} from '../DetectionCatalog.utils'
import { DetectionCatalogStatusTag } from '../DetectionCatalogStatusTag/DetectionCatalogStatusTag'

interface DetectionCatalogTableProps {
  detections: Detections[]
  filtersApplied: boolean
  handleScrollToTop: () => void
  pagination: Pagination
  loading: boolean
  onBottomReached: () => void
  onColumnFiltersChange: React.Dispatch<
    React.SetStateAction<ColumnFiltersState>
  >
  onRowSelectionChange: React.Dispatch<React.SetStateAction<RowSelectionState>>
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  rowSelection: RowSelectionState
  sorting: SortingState
  error: ApolloError | undefined
}

const columnHelper = createColumnHelper<Detections>()

export const DETECTION_CATALOG_TABLE_ROW_ID_PREFIX =
  'detection-catalog-table-row-'

const DetectionCatalogTable = forwardRef<
  HTMLDivElement,
  DetectionCatalogTableProps
>(
  (
    {
      detections,
      filtersApplied,
      handleScrollToTop,
      loading,
      onBottomReached,
      onColumnFiltersChange,
      onRowSelectionChange,
      onSortingChange,
      pagination,
      rowSelection,
      sorting,
      error,
    },
    ref,
  ) => {
    const columnDef = useMemo(
      () => [
        {
          id: 'title',
          accessorFn: (props) => formatDetectionTitle(props.title),
          cell: (props) => buildGenericCell(props),
          header: () => buildGenericHeader('Detections'),
        },
        {
          id: 'status',
          accessorFn: (props) => formatDetectionStatus(props.status),
          cell: (props) => {
            const { status } = props.getValue()

            return <DetectionCatalogStatusTag status={status} />
          },
          header: () => buildGenericHeader('Status'),
          size: 150,
          minSize: 130,
        },
        {
          id: 'dataTypes',
          accessorFn: (props) => formatNoMappingDetection(props.dataTypes),
          cell: (props) => buildGenericCell(props),
          header: () => buildGenericHeader('Tech Type'),
        },
        {
          id: 'mitreTactics',
          accessorFn: (props) => formatNoMappingDetection(props.mitreTactics),
          cell: (props) => buildGenericCell(props),
          header: () => buildGenericHeader('Mitre Tactics'),
        },
        columnHelper.accessor('releaseVersion', {
          id: 'releaseVersion',
          cell: (props) =>
            buildGenericCell(props as CellContext<Detections, never>),
          header: () => buildGenericHeader('DWA'),
          size: 70,
          minSize: 70,
        }),
      ],
      [],
    )

    const {
      columnSizing,
      tableHeaderRefs,
      handleMouseUp,
      handleMouseDown,
      resetColumnSize,
      onColumnSizingChange,
      tableContainerRef,
    } = useResizableColumns({
      columnDef,
    })

    const table = useReactTable<Detections>({
      columns: columnDef,
      data: detections,
      enableMultiRowSelection: false,
      enableSortingRemoval: false,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.useCase,
      manualFiltering: true,
      manualSorting: true,
      onRowSelectionChange: onRowSelectionChange,
      onSortingChange: onSortingChange,
      onColumnFiltersChange: onColumnFiltersChange,
      state: {
        rowSelection,
        sorting,
        columnSizing,
      },
      columnResizeMode: 'onChange',
      onColumnSizingChange,
    })

    const handleOnBottomReached = useCallback(
      (containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement

          if (scrollHeight - scrollTop - clientHeight < 300) {
            onBottomReached()
          }
        }
      },
      [onBottomReached],
    )

    const render = () => {
      const COL_SPAN = 5
      let element: JSX.Element | null = null
      // error state
      if (
        error ||
        (!error && !loading && !filtersApplied && detections.length < 1)
      ) {
        element = (
          <ComponentError
            errorIcon={noDetectionsIcon}
            errorText="No Detections Available"
            errorSubText="There are no detections currently available for active scanning."
          />
        )
      }
      // no results found
      if (!error && !loading && filtersApplied && detections.length < 1) {
        element = <NoResults />
      }

      if (element) {
        return (
          <tbody>
            <tr>
              <StyledTableCell
                align="center"
                colSpan={COL_SPAN}
                sx={{ border: 'none', padding: '50px' }}
              >
                {element}
              </StyledTableCell>
            </tr>
          </tbody>
        )
      }

      return (
        <tbody data-testid="detection-catalog-table-body">
          {loading
            ? buildSkeletonRows(table.getAllColumns())
            : table
                .getRowModel()
                .rows.map((row) => (
                  <MemoizedTanStackTableRow
                    key={row.id}
                    rowId={`${DETECTION_CATALOG_TABLE_ROW_ID_PREFIX}${row.id}`}
                    testId="detection-catalog-table-row"
                    isSelected={row.getIsSelected()}
                    toggleSelected={row.toggleSelected}
                    visibleCells={row.getVisibleCells()}
                  />
                ))}

          <PaginationLoadingRow
            currentLength={detections.length}
            pageSize={pagination.limit}
            total={pagination.total}
            onScrollToTop={handleScrollToTop}
          />
        </tbody>
      )
    }

    return (
      <CommonTableContainer
        data-testid="detection-catalog-table-container"
        onScroll={(e) => handleOnBottomReached(e.target as HTMLDivElement)}
        ref={mergeRefs(tableContainerRef, ref)}
      >
        <CommonTable>
          <thead data-testid="detection-catalog-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<Detections>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>
          {render()}
        </CommonTable>
      </CommonTableContainer>
    )
  },
)

DetectionCatalogTable.displayName = 'DetectionCatalogTable'

export default DetectionCatalogTable
