import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import { FilterChips } from '@common/FilterChips'
import { SideSheet, sideSheetHeaderCss } from '@common/SideSheet'
import { FilterOptionValues, FilterOptions } from '@models/index'
import { ComponentError } from '@common/ComponentError'
import Icon from '@common/Icon'

import { DetectionCatalogFilterOptions } from './DetectionCatalogFiltersValues'
import { DetectionStatusType } from '../../Coverage.utils'

interface DetectionCatalogFilterProps {
  isOpen: boolean
  setIsOpen: (boolean: boolean) => void
  filterOptionsResponse: string[]
  selectedFilters: FilterOptions<DetectionCatalogFilterOptions>
  clearAll: () => void
  applyFilter: (vars: FilterOptions<DetectionCatalogFilterOptions>) => void
}

const DetectionCatalogFilters: React.FC<DetectionCatalogFilterProps> = ({
  isOpen = true,
  setIsOpen,
  filterOptionsResponse, // currently only MITRE Tactics are returned in the response from BE
  selectedFilters,
  clearAll,
  applyFilter,
}): JSX.Element => {
  const filterOptions: FilterOptions<FilterOptionValues[]> = {
    keywordSearch: '',
    filters: [
      {
        label: 'MITRE Tactic',
        key: 'mitreTactics',
        values: filterOptionsResponse.map((tactic) => ({
          value: tactic,
          label: tactic,
          selected: false,
        })),
      },
      {
        label: 'Status',
        key: 'status',
        values: Object.values(DetectionStatusType).map((status) => ({
          value: status,
          label: status,
          selected: false,
        })),
      },
    ],
  }
  const [selectedFiltersSideSheet, setSelectedFiltersSideSheet] =
    useState<FilterOptions<DetectionCatalogFilterOptions>>(selectedFilters)

  useEffect(() => {
    setSelectedFiltersSideSheet(selectedFilters)
  }, [selectedFilters])

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilter(selectedFiltersSideSheet)
    }
  }
  return (
    <SideSheet
      data-testid="detection-catalog-filters-sidesheet-test-id"
      open={isOpen}
      renderTitle={() => (
        <Box
          sx={sideSheetHeaderCss.header}
          data-testid="detection-catalog-filters-sidesheet-header"
        >
          <Box sx={sideSheetHeaderCss.headerContent}>
            <Box sx={sideSheetHeaderCss.titleContent}>Filtering options</Box>
            <IconButton disabled={!isOpen} onClick={() => setIsOpen(false)}>
              <Icon variant="closeOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
      footer={
        filterOptionsResponse.length !== 0 ? (
          <Box
            sx={{
              display: 'flex',
              gap: '0.5em',
              padding: '1em',
            }}
            data-testid="detection-catalog-filters-buttons"
            id="dw-filters-buttons"
          >
            <Button
              variant="outlined"
              onClick={() => clearAll()}
              disabled={!isOpen}
            >
              Clear all
            </Button>
            <Button
              variant="contained"
              onClick={() => applyFilter(selectedFiltersSideSheet)}
              disabled={!isOpen}
            >
              Apply
            </Button>
          </Box>
        ) : null
      }
    >
      <Box
        id="filters-sidesheet"
        sx={{ overflowY: 'auto', flexDirection: 'column' }}
      >
        <Box
          sx={{
            padding: '1.5em 1em 0',
            ...(filterOptionsResponse.length !== 0 ? {} : { height: '100%' }),
          }}
        >
          {filterOptionsResponse.length !== 0 ? (
            <>
              <TextField
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon variant="searchOutline" />
                      </InputAdornment>
                    ),
                  },
                }}
                placeholder="Search by detection name, ID, or technology type"
                type="text"
                name="ticket filter search text"
                onChange={({ target }) => {
                  setSelectedFiltersSideSheet({
                    ...selectedFiltersSideSheet,
                    keywordSearch: target.value,
                  })
                }}
                value={selectedFiltersSideSheet.keywordSearch}
                onKeyDown={handleOnKeyDown}
                disabled={!isOpen}
              />
              <>
                {filterOptions.filters.map(
                  ({ label, key, values }): JSX.Element => {
                    return (
                      <Box
                        data-testid="dw-filters-group"
                        component="fieldset"
                        sx={{ border: 'none', margin: '1.5em 0' }}
                        key={key}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ paddingBottom: 0.5 }}
                          variant="body1"
                        >
                          {label}
                        </Typography>
                        <FilterChips
                          key={key}
                          keyValue={key}
                          filterGroup={label}
                          filterLabel={label}
                          filterValues={values}
                          selectedFilters={selectedFiltersSideSheet}
                          setSelectedFilters={
                            setSelectedFiltersSideSheet as VoidFunction
                          }
                          disabled={!isOpen}
                        />
                      </Box>
                    )
                  },
                )}
              </>
            </>
          ) : (
            <ComponentError />
          )}
        </Box>
      </Box>
    </SideSheet>
  )
}

export default DetectionCatalogFilters
