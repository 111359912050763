import { gql } from '@apollo/client'

import {
  Customer,
  FilterOptions,
  PaginationInput,
  Ticket,
  TicketData,
  TicketEditSettingsData,
  TicketFilterInput,
  TicketSortingInput,
  TicketStatsAggregationOptions,
} from '@models/index'

import {
  TICKET_ACTIVITY_FRAGMENT,
  TICKET_ADDITIONAL_DETAILS_FRAGMENT,
  TICKET_CONTACT_FRAGMENT,
  TICKET_DETAILS_FRAGMENT,
  TICKET_HEADER_FRAGMENT,
  TICKET_MTTX_FRAGMENT,
  TICKET_RESOLUTION_FRAGMENT,
  TICKET_STATUS_FRAGMENT,
  TICKET_RELATED_CASES_FRAGMENT,
} from '../fragments/ticket'

export interface GetTicketsData {
  getTickets: TicketData
}

export interface GetTicketsVariables {
  pagination: PaginationInput
  selectedCustomer: string | null
  selectedFilters: FilterOptions<TicketFilterInput>
  selectedSortings: TicketSortingInput
}

export interface GetTicketDetailsVariables {
  selectedCustomer: string
  ticketId: string
}
export interface GetTicketEditSettingsData {
  getTicketEditSettings: TicketEditSettingsData
}
export interface GetTicketEditSettingsVariables {
  selectedCustomer: string | null
}

export interface TicketStatsInput {
  aggregateBy: TicketStatsAggregationOptions
}

export interface TicketStatsVariables {
  selectedCustomer: string | null
  input: TicketStatsInput
}

export interface TicketStatsData {
  aggregateBy: TicketStatsAggregationOptions
  data: TicketStatItem[]
}

export interface TicketStatItem {
  key: string
  count: number
}

export interface TicketStatsResponse {
  ticketStats: TicketStatsData
  customerInfo: Customer
}

export type TicketDetailsResponse = Pick<
  Ticket,
  'shortDescription' | 'description'
>

export type TicketStatusResponse = Pick<
  Ticket,
  | 'assignedUser'
  | 'impact'
  | 'priority'
  | 'state'
  | 'customerValidateState'
  | 'lastUpdateUser'
  | 'sysCreatedOn'
>

export type TicketActivityResponse = Pick<
  Ticket,
  | 'comments'
  | 'openedBy'
  | 'ticketNumber'
  | 'sysId'
  | 'state'
  | 'sysCreatedOn'
  | 'createdDate'
>

export type TicketAdditionalDetailsResponse = Pick<
  Ticket,
  | 'mitreTactics'
  | 'mitreTechniques'
  | 'searchName'
  | 'splunkLink'
  | 'splunkSearch'
  | 'useCaseTitle'
>

export type TicketRelatedCasesResponse = Pick<Ticket, 'relatedCases'>

export type TicketMttxResponse = Pick<
  Ticket,
  | 'customerAssignmentTime'
  | 'customerAcknowledgeTime'
  | 'customerValidationTime'
  | 'timeToCloseSec'
  | 'timeToCustomerAcknowledgeSec'
  | 'timeToCustomerAssignSec'
  | 'timeToCustomerValidateSec'
  | 'timeToDetectionSec'
  | 'timeToDeclaredIncidentSec'
  | 'timeToDetectResolveAlertSec'
  | 'timeToInvestigateSec'
  | 'timeToNotifySec'
  | 'timeToRespondSec'
  | 'timeToResolveTicketSec'
  | 'timeToUpdateSec'
  | 'totalTimeDeepwatchWaitVendorSec'
  | 'totalTimeDeepwatchWaitCustomerSec'
  | '__typename'
>

export type TicketHeaderResponse = Pick<
  Ticket,
  | 'sysUpdatedOn'
  | 'state'
  | 'ticketNumber'
  | 'resolutionCode'
  | 'resolutionNotes'
>

export const GET_TICKETS = gql`
  query GetTickets(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        assignedUserEmail
        assignmentGroup
        module
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
          internalUser
        }
        state
      }
      sortingOptions {
        key
        order
      }
      filterOptions {
        filters {
          label
          key
          values {
            label
            value
            selected
          }
        }
        keywordSearch
        showOpenTicketOnly
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const TICKETS_OLDER_14_DAYS = gql`
  query TicketsOlder14Days(
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        pagination: $pagination
        selectedSortings: $selectedSortings
        selectedFilters: { filters: { sysCreatedOn: "2 weeks" } }
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        assignedUserEmail
        module
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
          internalUser
        }
        state
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const GET_TICKET_METRICS = gql`
  query GetTicketMetrics($selectedCustomer: String, $input: GetDashboardInput) {
    getTicketMetrics(selectedCustomer: $selectedCustomer, input: $input) {
      openCriticalCount
      openHighCount
      openWaitingCustomerCount
      openWaitingDeepwatchCount
    }
  }
`

export const GET_TICKET_DETAILS = gql`
  ${TICKET_DETAILS_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketDetailsFields
    }
  }
`

export const GET_TICKET_STATUS = gql`
  ${TICKET_STATUS_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketStatusFields
    }
  }
`

export const GET_TICKET_CONTACT = gql`
  ${TICKET_CONTACT_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketContactFields
    }
  }
`

export const GET_TICKET_RESOLUTION = gql`
  ${TICKET_RESOLUTION_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketResolutionFields
      state
    }
  }
`

export const GET_TICKET_ACTIVITY = gql`
  ${TICKET_ACTIVITY_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketActivityFields
    }
  }
`

export const GET_TICKET_RELATED_CASES = gql`
  ${TICKET_RELATED_CASES_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketRelatedCasesFields
    }
  }
`

export const GET_TICKET_ADDITIONAL_DETAILS = gql`
  ${TICKET_ADDITIONAL_DETAILS_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketAdditionalDetailsFields
    }
  }
`

export const GET_TICKET_MTTX = gql`
  ${TICKET_MTTX_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketMttxFields
    }
  }
`

export const GET_TICKET_HEADER = gql`
  ${TICKET_HEADER_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketHeaderFields
    }
  }
`

export const GET_TICKET_EDIT_SETTINGS = gql`
  query GetTicketEditSettings($selectedCustomer: String!) {
    getTicketEditSettings(selectedCustomer: $selectedCustomer) {
      enabled
      enumValues {
        urgency {
          label
          value
          sequence
        }
        priority {
          label
          value
          sequence
        }
        state {
          label
          value
          sequence
        }
        resolutionCode {
          label
          value
          sequence
        }
        customerValidationState {
          label
          value
          sequence
        }
      }
    }
  }
`

export const GET_TICKETS_WITH_CACHE_FIELDS = gql`
  ${TICKET_DETAILS_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_CONTACT_FRAGMENT}
  ${TICKET_ACTIVITY_FRAGMENT}
  ${TICKET_RESOLUTION_FRAGMENT}
  ${TICKET_HEADER_FRAGMENT}
  query GetTickets(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        ...TicketDetailsFields
        ...TicketStatusFields
        ...TicketContactFields
        ...TicketActivityFields
        ...TicketResolutionFields
        ...TicketHeaderFields
        sysId
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        lastUpdateUser
        openedBy
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
          internalUser
        }
        relatedCases {
          ticketNumber
          description
          priority
          state
          sysCreatedOn
          type
          sysUpdatedOn
        }
      }
      sortingOptions {
        key
        order
      }
      filterOptions {
        filters {
          label
          key
          values {
            label
            value
            selected
          }
        }
        keywordSearch
        showOpenTicketOnly
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const GET_TOP_LEVEL_TICKET_DATA = gql`
  ${TICKET_DETAILS_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_CONTACT_FRAGMENT}
  ${TICKET_ACTIVITY_FRAGMENT}
  ${TICKET_RESOLUTION_FRAGMENT}
  ${TICKET_RELATED_CASES_FRAGMENT}
  ${TICKET_ADDITIONAL_DETAILS_FRAGMENT}
  ${TICKET_MTTX_FRAGMENT}
  ${TICKET_HEADER_FRAGMENT}
  query GetTicketDetails($selectedCustomer: String!, $ticketId: String!) {
    getTicketDetails(
      selectedCustomer: $selectedCustomer
      input: { ticketId: $ticketId }
    ) {
      ...TicketDetailsFields
      ...TicketStatusFields
      ...TicketContactFields
      ...TicketActivityFields
      ...TicketResolutionFields
      ...TicketAdditionalDetailsFields
      ...TicketRelatedCasesFields
      ...TicketMttxFields
      ...TicketHeaderFields
      sysId
    }
  }
`

export const GET_TICKETS_WAITING_ON_CUSTOMER = gql`
  query GetTicketsWaitingOnCustomer(
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        pagination: $pagination
        selectedSortings: $selectedSortings
        selectedFilters: { filters: { state: ["In Progress - Customer"] } }
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        assignedUserEmail
        module
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        contactUser
        comments {
          time
          type
          user
          text
          internalUser
        }
        state
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const GET_TICKETS_WAITING_ON_ME = gql`
  query GetTicketsWaitingOnMe(
    $selectedCustomer: String
    $pagination: PaginationInput
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        pagination: $pagination
        selectedFilters: { filterByUser: true }
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        assignedUserEmail
        module
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        contactUser
        comments {
          time
          type
          user
          text
          internalUser
        }
        state
        contactUserEmail
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const TICKET_STATS = gql`
  query TicketStats($selectedCustomer: String!, $input: TicketStatsInput!) {
    ticketStats(selectedCustomer: $selectedCustomer, input: $input) {
      aggregateBy
      data {
        key
        count
      }
    }
  }
`

export const TICKET_STATS_WITH_CUSTOMER_INFO = gql`
  query TicketStatsWithCustomerInfo(
    $selectedCustomer: String!
    $input: TicketStatsInput!
  ) {
    ticketStats(selectedCustomer: $selectedCustomer, input: $input) {
      aggregateBy
      data {
        key
        count
      }
    }
    customerInfo(selectedCustomer: $selectedCustomer) {
      edrEnabled
      fwEnabled
      mdrEnabled
      threatSignalEnabled
      vmEnabled
    }
  }
`

export const RECENTLY_CLOSED_TICKETS = gql`
  query RecentlyClosedTickets(
    $pagination: PaginationInput
    $selectedCustomer: String
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        pagination: $pagination
        selectedSortings: $selectedSortings
        selectedFilters: {
          filters: { state: "Closed" }
          showOpenTicketOnly: false
        }
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysCreatedOn
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        assignedUserEmail
        module
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
          internalUser
        }
        state
        closedAt
      }
      sortingOptions {
        key
        order
      }

      pagination {
        limit
        offset
        total
      }
    }
  }
`
