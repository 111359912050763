import React, { useContext, useEffect, useState } from 'react'
import { OktaAuth } from '@okta/okta-auth-js'
import { useIdleTimer } from 'react-idle-timer'
import { captureException } from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Context, setGlobalLoading, setSession, setUser } from '@components/App'
import { SessionStatus } from '@config/OktaAuthConfig'
import { getCookie } from '@utils/index'
import SessionTimeoutModal from '@common/Modal/SessionTimeoutModal/SessionTimeoutModal'
import { Dialog } from '@common/Dialog'
import { OktaUser } from '@models/OktaAuthSession'
import useSignOut from '@hooks/useSignOut'

import {
  parseGroups,
  setAdminStatus,
  setEmployeeStatus,
  signUserOut,
} from './SecurityUtils'

/**
 * This function gets the active user and groups from the Okta session. It will throw if the tokens are invalid or missing.
 * @param {OktaAuth} oktaAuth The OktaAuth instance
 * @returns {Promise<OktaUser>} User object with user and group information
 */
const getActiveUserAndGroups = async (
  oktaAuth: OktaAuth,
): Promise<OktaUser> => {
  const { tokens } = await oktaAuth.token.getWithoutPrompt() // If the tokens are invalid, this will be empty
  oktaAuth.tokenManager.setTokens(tokens) // Always store the tokens in the token manager, in case we already have invalid tokens stored
  const { email, family_name, given_name, name, sub } = await oktaAuth.getUser() // getUser will throw if the tokens are invalid or missing
  const oktaGroups = parseGroups(tokens)
  return {
    id: sub,
    email: email as string,
    username: name as string,
    firstName: given_name as string,
    lastName: family_name as string,
    isDWEmployee: setEmployeeStatus(oktaGroups),
    isAdmin: setAdminStatus(oktaGroups),
  }
}

const emptyUser: Readonly<OktaUser> = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  isDWEmployee: false,
  isAdmin: false,
}

const SecuritySession: React.FC<
  {
    oktaAuth: OktaAuth
    children?: unknown
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ oktaAuth, children }) => {
  const { dispatch } = useContext(Context)
  const { fixSentryBug } = useFlags()
  const [isSessionTimeoutModalOpen, setIsSessionTimeoutModalOpen] =
    useState(false)
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const signOut = useSignOut()

  // the user will be shown the session timeout modal 20 minutes before the session expires
  // aka since timeout is 1 hour, the user will be shown the modal after 40 minutes of inactivity
  const { getRemainingTime, isPrompted, pause, start } = useIdleTimer({
    promptBeforeIdle: 1200000, // 20 minutes //1200000
    startManually: true,
    startOnMount: false,
    stopOnIdle: true,
    timeout: 3600000, // 1 hour //3600000
    onIdle: async () => {
      const tokens = await oktaAuth.tokenManager.getTokens()
      !fixSentryBug ? signOut() : await signUserOut(tokens.accessToken!, true)
    },
    onPrompt: () => {
      const remainingSeconds = Math.trunc(getRemainingTime() / 1000)
      setRemainingSeconds(remainingSeconds)
      setIsSessionTimeoutModalOpen(true)
    },
  })

  /**
   * This effect initializes user state from the Okta session. It will:
   *
   * 1. Check if the Okta session exists
   * 2. If the session exists
   *    1. Get the user and groups from the Okta session
   *    2. Set the user state
   * 3. If the session does not exist
   *    1. Stop the idle timer
   *    2. Sign the user out
   * 4. If there is an error while getting user tokens/data/etc.
   *    1. Log the error
   *    2. Sign the user out, attempting to revoke the token in the token manager if it exists
   * 5. Finally, set the global loading state to false
   */
  useEffect(() => {
    const initializeUserStateFromOktaSession = async () => {
      const doesSessionExist = await oktaAuth.session.exists()

      try {
        if (doesSessionExist) {
          const user = await getActiveUserAndGroups(oktaAuth)
          if (isPrompted() === false) {
            start()
          }
          dispatch(setSession(SessionStatus.ACTIVE))
          dispatch(setUser(user))
        } else {
          pause()
          if (getCookie('iav')) {
            const tokens = oktaAuth.tokenManager.getTokensSync()
            !fixSentryBug
              ? signOut()
              : await signUserOut(tokens.accessToken, true)
          }
        }
      } catch (error) {
        captureException(error)
        if (!fixSentryBug) {
          oktaAuth.tokenManager.clear()
          signOut()
        } else {
          oktaAuth.tokenManager.clear() // Clear the token manager in case the tokens are invalid
          await signUserOut(undefined, true)
        }
        dispatch(setUser(emptyUser))
        dispatch(setSession(SessionStatus.INACTIVE))
      } finally {
        dispatch(setGlobalLoading(false))
      }
    }

    initializeUserStateFromOktaSession()
  }, [dispatch, isPrompted, oktaAuth, pause, start])

  return (
    <>
      {children}
      <Dialog
        title="Session Timeout"
        isOpen={isSessionTimeoutModalOpen}
        onClose={() => setIsSessionTimeoutModalOpen(false)}
        isCloseButtonVisible={false}
        disableEscapeKeyDown
      >
        <SessionTimeoutModal
          closeModal={() => setIsSessionTimeoutModalOpen(false)}
          resetIdleTimer={start}
          secondsLeft={remainingSeconds}
        />
      </Dialog>
    </>
  )
}

export default SecuritySession
