import Select, { GroupBase, Props, components } from 'react-select'
import { useColorScheme, useTheme } from '@mui/material'

import { zIndex } from '@components/App/Styles/zIndex'

export type OptionType = {
  value: string
  label: string
  color?: string
}

const Dropdown = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group>,
) => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  const isLightMode = mode === 'light'

  return (
    <Select
      {...props}
      components={{
        ...props.components,
        DropdownIndicator: (props) => {
          return props.isDisabled ? null : (
            <components.DropdownIndicator {...props} />
          )
        },
        IndicatorSeparator: () => null,
      }}
      theme={(reactSelectTheme) => ({
        ...reactSelectTheme,
        borderRadius: 5,
      })}
      styles={{
        control: (base, state) => ({
          ...base,
          backgroundColor: isLightMode
            ? theme.vars.palette.secondary.light
            : theme.vars.palette.secondary.main,
          cursor: 'pointer',
          borderColor: isLightMode
            ? theme.vars.palette.secondary.main
            : theme.vars.palette.secondary.light,
          boxShadow: 'none',
          width: '100%',
          '&:hover': {
            backgroundColor: theme.vars.palette.secondary.light,
            borderColor: isLightMode
              ? theme.vars.palette.primary.light
              : theme.vars.palette.secondary.lighter,
          },
          ...(state.isDisabled && {
            border: 0,
          }),
          ...props.controlStyles,
        }),

        dropdownIndicator: (base) => ({
          ...base,
          color: theme.vars.palette.text.primary,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          '&:hover': {
            color: theme.vars.palette.text.primary,
          },
          ...props.dropdownIndicatorStyles,
        }),

        menu: (base) => ({
          ...base,
          backgroundColor: isLightMode
            ? theme.vars.palette.secondary.light
            : theme.vars.palette.secondary.main,
          border: isLightMode
            ? `1px solid ${theme.vars.palette.secondary.main}`
            : `1px solid ${theme.vars.palette.secondary.light}`,
          width: '100%',
          zIndex: zIndex.DROPDOWN_MENU,
          ...props.menuStyles,
        }),

        multiValue: (base) => ({
          ...base,
          backgroundColor: isLightMode
            ? theme.vars.palette.secondary.main
            : theme.vars.palette.secondary.darker,
          color: isLightMode
            ? theme.vars.palette.text.primary
            : theme.vars.palette.text.secondary,
          ...props.multiValueStyles,
        }),

        multiValueLabel: (base) => ({
          ...base,
          color: isLightMode
            ? theme.vars.palette.text.primary
            : theme.vars.palette.text.secondary,
          ...props.multiValueLabelStyles,
        }),

        option: (base, state) => ({
          ...base,
          backgroundColor: isLightMode
            ? theme.vars.palette.secondary.light
            : theme.vars.palette.secondary.main,
          cursor: 'pointer',
          fontSize: theme.typography.body2.fontSize,
          '&:hover': {
            backgroundColor: isLightMode
              ? theme.vars.palette.secondary.lighter
              : theme.vars.palette.secondary.light,
          },
          ...(state.isSelected && {
            color: isLightMode
              ? theme.vars.palette.text.primary
              : theme.vars.palette.text.secondary,
            backgroundColor: isLightMode
              ? theme.vars.palette.secondary.dark
              : theme.vars.palette.secondary.light,
            '&:hover': {
              backgroundColor: isLightMode
                ? theme.vars.palette.secondary.dark
                : theme.vars.palette.secondary.light,
            },
          }),
          ...(state.isDisabled && {
            color: theme.vars.palette.text.disabled,
          }),
          ...props.optionStyles,
        }),

        placeholder: (base) => ({
          ...base,
          fontSize: theme.typography.body2.fontSize,
          ...props.placeholderStyles,
        }),

        singleValue: (base, { isDisabled }) => ({
          ...base,
          color: isDisabled
            ? theme.vars.palette.text.disabled
            : theme.vars.palette.text.primary,
          fontSize: theme.typography.body2.fontSize,
          ...props.singleValueStyles,
        }),
        input: (base, { isDisabled }) => ({
          ...base,
          color: isDisabled
            ? theme.vars.palette.text.disabled
            : theme.vars.palette.text.primary,
          fontSize: theme.typography.body2.fontSize,
          ...props.singleValueStyles,
        }),
      }}
    />
  )
}

export default Dropdown
