import { useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { debounce } from 'throttle-debounce'
import * as Yup from 'yup'
import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { captureException } from '@sentry/react'

import { AlertSeverity, useToast } from '@hooks/useToast'
import { UPDATE_TICKET, UpdateTicketVariables } from '@mutations/ticket'
import { Dropdown, OptionType } from '@common/Dropdown'
import { InlineMessage, InlineMessageType } from '@common/InlineMessage'

import {
  TicketResolutionFormValues,
  TicketResolutionFormProps,
} from '../../Types'
import { updateTicketInCache } from '../../utils'
import { sortSequenceEnums } from '../TicketStatus/TicketStatusUtils'
import ProgressButton from '../ProgressButton/ProgressButton'

const TicketResolutionFormSchema = Yup.object().shape({
  resolutionCode: Yup.string().required('Please select the resolution code'),
  resolutionNotes: Yup.string()
    .trim()
    .required('Please enter resolution notes'),
})

export const TicketResolutionForm: React.FC<TicketResolutionFormProps> = ({
  resolutionCodeEnum,
  data,
  inModal = true,
  closeModal,
  onChange,
  sysId,
  ticketNumber,
  draftTicketFields,
  resetDraftTicketFields,
  readonly,
}) => {
  const theme = useTheme()
  const [isMutationSuccessful, setIsMutationSuccessful] = useState(false)
  const { handleShowToast } = useToast()

  const isMounted = useRef<boolean | null>(null)

  const [
    updateTicket,
    { loading: isSubmissionLoading, error: submissionError },
  ] = useMutation<{ updateTicket: boolean }, UpdateTicketVariables>(
    UPDATE_TICKET,
    {
      onCompleted: () => {
        if (isMounted.current) {
          // Only do the following if component is still mounted when mutation completes

          setIsMutationSuccessful(true)

          setTimeout(() => {
            closeModal?.()
          }, 2000)
        }

        updateTicketInCache({
          ticketId: sysId,
          newFields: draftTicketFields || {},
        })
        resetDraftTicketFields?.()

        setTimeout(() => {
          handleShowToast(
            AlertSeverity.Success,
            `${ticketNumber} has been saved successfully!`,
            3000,
          )
        })
      },
    },
  )

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  const resolutionCodeOptions: OptionType[] = [...resolutionCodeEnum]
    .sort(sortSequenceEnums)
    .map(({ label, value }) => ({
      label,
      value: value as unknown as string,
    }))

  const selectedOption = resolutionCodeOptions.find(
    (option) => option.label === data?.resolutionCode,
  )

  const initialValues: TicketResolutionFormValues = {
    resolutionCode: data?.resolutionCode ?? '',
    resolutionNotes: data?.resolutionNotes ?? '',
  }

  const debouncedTextareaChange = debounce(200, (resolutionNotes: string) =>
    onChange?.({
      resolutionNotes,
    }),
  )

  const notesFieldId = `resolutionNotes-${inModal ? 'in-modal' : 'on-page'}`

  const handleSubmit = async (values: TicketResolutionFormValues) => {
    // We don't want to submit the form if it's not in the modal
    if (!inModal) return

    try {
      const ticketData = {
        ...draftTicketFields,
        ...values,
        state: 'Resolved',
      }

      await updateTicket({
        variables: {
          input: {
            ticketId: sysId,
            ticketData,
          },
        },
      })

      if (draftTicketFields) {
        updateTicketInCache({
          ticketId: sysId,
          newFields: ticketData,
        })
      }
      /**
       * Note: Try/catch block necessary for when Apollo throws errors.
       * Catch block is required with try block, but empty since
       * `useMutation` hook handles error.
       */
      // eslint-disable-next-line no-empty
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TicketResolutionFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
        setFieldTouched,
        submitForm,
      }) => {
        return (
          <Box
            component="form"
            data-testid="ticket-resolution-form"
            sx={{ padding: inModal ? '1rem' : '', overflow: 'hidden' }}
          >
            <>
              <Typography
                fontWeight={600}
                variant="body2"
                color="textPrimary"
                sx={{ paddingBottom: '0.5rem' }}
              >
                Resolution code
              </Typography>

              {readonly ? (
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                >
                  {data?.resolutionCode ?? '--'}
                </Typography>
              ) : (
                <Dropdown
                  defaultValue={selectedOption}
                  id="resolution-code-dropdown"
                  isSearchable={false}
                  name="resolution-code-dropdown"
                  options={resolutionCodeOptions}
                  onChange={(val) => {
                    setFieldValue('resolutionCode', val?.label)
                    setFieldTouched('resolutionCode', true)
                  }}
                  placeholder="--"
                  controlStyles={{
                    // eslint-disable-next-line @getify/proper-ternary/nested
                    borderColor: errors.resolutionCode
                      ? theme.vars.palette.error.main
                      : theme.vars.palette.secondary.light,
                  }}
                />
              )}
            </>

            {errors.resolutionCode ? (
              <Typography variant="caption" color="error">
                {errors.resolutionCode}
              </Typography>
            ) : null}

            <Typography
              fontWeight={600}
              variant="body2"
              color="textPrimary"
              sx={{ padding: '0.5rem 0' }}
            >
              Resolution notes
            </Typography>

            {readonly ? (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              >
                {data?.resolutionNotes ?? '--'}
              </Typography>
            ) : (
              <TextField
                data-testid="resolutionNotes"
                id={notesFieldId}
                multiline
                name="resolutionNotes"
                onChange={(e) => {
                  handleChange(e)
                  debouncedTextareaChange(e.target.value.trim() || null)
                }}
                rows={4}
                sx={{ paddingRight: 0 }}
                value={values.resolutionNotes}
                error={Boolean(errors.resolutionNotes)}
                helperText={errors.resolutionNotes}
                variant="outlined"
              />
            )}

            {inModal && (
              <>
                {submissionError && (
                  <Box sx={{ marginTop: '1.5rem', maxWidth: '452px' }}>
                    <InlineMessage
                      title="Error saving"
                      message={
                        'Internal server error. Ticket is unable to be resolved. Please contact your system administrator for assistance.'
                      }
                      variant={InlineMessageType.ERROR}
                    />
                  </Box>
                )}
                <Box
                  data-testid="resolutionFooter"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    marginTop: '1.5rem',
                  }}
                >
                  <Button
                    onClick={() => {
                      closeModal?.()
                    }}
                    variant="outlined"
                    disabled={isSubmissionLoading}
                  >
                    Cancel
                  </Button>
                  <ProgressButton
                    defaultText="Save & resolve case"
                    successText="Resolved"
                    disabled={!values.resolutionCode || !values.resolutionNotes}
                    loading={isSubmissionLoading}
                    error={submissionError}
                    success={isMutationSuccessful}
                    onClick={submitForm}
                    showErrorState
                  />
                </Box>
              </>
            )}
          </Box>
        )
      }}
    </Formik>
  )
}
