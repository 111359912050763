import { Box, Typography } from '@mui/material'

import Icon from '@common/Icon'

const NotificationsEmptyState = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingBottom: '36px',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 0 0',
      }}
      data-testid="no-notifications"
    >
      <Box data-testid="no-notifcations-text-container">
        <Icon
          size={150}
          variant="bell"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            fill: theme.vars.palette.common.white,
            ...theme.applyStyles('dark', {
              fill: theme.vars.palette.secondary.dark,
              color: theme.vars.palette.text.primary,
            }),
          })}
        />
        <Typography
          variant="h5"
          color="textSecondary"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {`You're all caught up!`}
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ paddingTop: 0.5 }}
        >
          {`You don't have any notifications at the moment!`}
        </Typography>
      </Box>
    </Box>
  )
}

export default NotificationsEmptyState
