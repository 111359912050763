import { gql } from '@apollo/client'

export interface ToggleNotificationReadInput {
  notificationId: string
}

export const TOGGLE_NOTIFICATION_READ = gql`
  mutation ToggleNotificationRead($input: ToggleNotificationReadInput!) {
    toggleNotificationRead(input: $input) {
      id
    }
  }
`
