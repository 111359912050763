import { ResponsiveBar, ResponsiveBarSvgProps } from '@nivo/bar'
import { format } from 'date-fns'
import { Box, useTheme } from '@mui/material'

import { SourceType } from '@models/EnvHealth'

import { AverageLineLegend } from './AverageLineLegend'

type ChartData = Record<string, number | string>

const barColors: string[] = ['#00CC66']

const dateFormat = 'M/d' as const

interface UtilizationDetailsChart {
  sourceTypes: SourceType[]
  chartHeight?: number
}

/**
 * @description This chart is rendered as a single grouped bar segmented by sourcetype, rather than rendering an inBoxidual datum for each sourcetype
 * @param {UtilizationDetailsChart} props The component props
 * @param {SourceType[]} props.sourceTypes The source types to display in the chart
 * @param {number} [props.chartHeight] The height of the chart, in pixels
 * @returns {import('react').ReactNode} A grouped bar chart showing the utilization of each sourcetype
 */
export const UtilizationAverageChart: React.FC<UtilizationDetailsChart> = ({
  sourceTypes,
  chartHeight = 230,
}) => {
  const muitheme = useTheme()
  const theme: Readonly<ResponsiveBarSvgProps<ChartData>['theme']> = {
    axis: {
      ticks: {
        line: {
          strokeWidth: 0,
        },
        text: {
          fontSize: 12,
          fill: muitheme.palette.text.primary,
        },
      },
    },
    tooltip: {
      container: {
        color: muitheme.palette.secondary.main,
      },
    },
    grid: {
      line: {
        stroke: muitheme.palette.text.secondary,
        strokeWidth: 1,
        strokeDasharray: '8',
      },
    },
  }

  if (sourceTypes.length === 0) {
    return null
  }

  const data = sourceTypes
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    .map((sourceType) => ({
      date: format(sourceType.date, dateFormat),
      [sourceType.name]: sourceType.gb,
    }))

  const firstSourceType = sourceTypes[0]
  const keys = [firstSourceType.name]
  const averageLines = [firstSourceType.avgIngestOverAllTime]
  const dateTicks = [data.at(0)?.date, data.at(-1)?.date]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <AverageLineLegend />
      </Box>
      <Box style={{ height: chartHeight }}>
        <ResponsiveBar
          indexBy="date"
          enableGridY={true}
          gridYValues={averageLines}
          enableLabel={false}
          padding={0.6}
          margin={{
            bottom: 30,
            left: 30,
          }}
          axisLeft={{
            tickPadding: 10,
            tickValues: 5,
          }}
          axisBottom={{
            tickPadding: 10,
            tickValues: dateTicks,
          }}
          colors={barColors}
          theme={theme}
          data={data}
          keys={keys}
          layers={[
            'axes',
            'bars',
            'grid', // Allow the average line to render over the bars
            'totals',
            'markers',
            'legends',
            'annotations',
          ]}
        />
      </Box>
    </Box>
  )
}
