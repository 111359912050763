import { Box, Link, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { PluggableList } from 'react-markdown/lib'

import { GET_TICKET_DETAILS, TicketDetailsResponse } from '@queries/ticket'

import TicketDataHandler from './TicketDataHandler'
import TicketEditCard from './TicketEditCard'

const TicketDetailsContent: React.FC<{ data: TicketDetailsResponse }> = ({
  data,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Typography color="textPrimary" fontWeight={600} variant="body2">
        Short description
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {data.shortDescription || '--'}
      </Typography>
    </Box>
    <Box sx={{ overflow: 'hidden' }}>
      <Typography color="textPrimary" fontWeight={600} variant="body2">
        Description
      </Typography>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw] as PluggableList}
        components={{
          a(props) {
            const { href, children } = props

            return (
              <Link href={href} rel="noopener noreferrer" target="_blank">
                {children}
              </Link>
            )
          },
          li(props) {
            return (
              <Box
                sx={{
                  wordBreak: 'break-word',
                  padding: '0',
                  marginLeft: -2,
                }}
              >
                <Typography
                  component={'li'}
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                  variant="body2"
                >
                  {props.children}
                </Typography>
              </Box>
            )
          },
          p(props) {
            return (
              <Typography
                sx={(theme) => ({
                  whiteSpace: 'pre-wrap',
                  padding: '0.5rem 0',
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="body2"
              >
                {props.children}
              </Typography>
            )
          },
        }}
      >
        {data.description || '--'}
      </ReactMarkdown>
    </Box>
  </Box>
)

const TicketDetails: React.FC = () => {
  return (
    <TicketEditCard data-testid="ticket-details" title="Details">
      <TicketDataHandler<TicketDetailsResponse>
        graphqlQuery={GET_TICKET_DETAILS}
        renderContent={(data) => <TicketDetailsContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketDetails
