import { Dispatch, ReactNode, useContext, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useLazyQuery } from '@apollo/client'
import { differenceInDays, subDays } from 'date-fns'
import { captureException } from '@sentry/react'

import {
  Context,
  setCustomer,
  setCustomerLoading,
  setCustomerNames,
  setDateFilter,
  setHomePath,
  setTermsAccepted,
} from '@components/App'
import { AppAction, AppState, Paths, Terms } from '@components/App/Types'
import { Customer } from '@models/index'
import { SessionStatus } from '@config/OktaAuthConfig'
import {
  GET_ACCEPTED_TERMS,
  GetAcceptedTermsData,
  GetAcceptedTermsVariables,
} from '@queries/user'
import {
  GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE,
  GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA,
  CustomerInfoData,
  CustomerInfoVariables,
  GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA_AND_BETA_AGREEMENT,
  GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_BETA_AGREEMENT,
} from '@queries/customer'
import { useCustomer } from '@hooks/useCustomer'
import { AlertSeverity, useToast } from '@hooks/useToast'

export const tosVersion = '2022-10-18'
export const DATE_RANGE_AMOUNT_OF_DAYS = 29

export const updateDateFilterFromMaturityDate = (
  maturityScoreStartDate: NonNullable<Customer['maturityScoreStartDate']>,
  dispatch: Dispatch<AppAction>,
  dateFilter: AppState['dateFilter'],
) => {
  const maturityDate = new Date(maturityScoreStartDate)
  const timeBetweenNowAndStart = Math.abs(
    differenceInDays(maturityDate, dateFilter.defaultEndDate),
  )

  if (timeBetweenNowAndStart < DATE_RANGE_AMOUNT_OF_DAYS) {
    const newStartDate = subDays(
      dateFilter.defaultEndDate,
      timeBetweenNowAndStart,
    )

    // this is to prevent setDateFilter from constantly setting itself,
    // which was causing an loop of network requests when using the nearest
    // change button for security index
    if (
      dateFilter.defaultStartDate.toISOString() === newStartDate.toISOString()
    ) {
      return
    } else {
      dispatch(
        setDateFilter({
          ...dateFilter,
          startDate: newStartDate,
          defaultStartDate: new Date(newStartDate),
        }),
      )
    }
  }
}

export const handleSession = async ({
  email,
  customerShortName,
  handleShowToast,
  dispatch,
  getCustomerInfo,
  isDWEmployee,
  ldClient,
  shortName,
  signal,
}: {
  email: string
  isDWEmployee: boolean
  shortName: string | null
  customerShortName: string
  handleShowToast
  dispatch: Dispatch<AppAction>
  ldClient: import('launchdarkly-js-client-sdk').LDClient | undefined
  getCustomerInfo(args: { variables: CustomerInfoVariables }): Promise<{
    data: CustomerInfoData | undefined
  }>
  signal: AbortSignal
}) => {
  /**
   * I took the approach of wrapping the queries in try/catch instead of using
   * onError and onCompleted so that the promises could be properly awaited since
   * some of the promises depend on others
   */

  dispatch(setCustomerLoading(true))

  await ldClient?.identify({
    key: email,
    email,
    kind: 'user',
    customerShortName,
    betaAgreementStatus: null,
  })

  const selectedCustomer = customerShortName || shortName

  if (isDWEmployee && !selectedCustomer) {
    return
  }

  let customerInfo: Customer | undefined
  try {
    const { data: customerData } = await getCustomerInfo({
      variables: {
        selectedCustomer,
      },
    })
    customerInfo = customerData?.customerInfo
  } catch (error) {
    captureException(error)
    handleShowToast(AlertSeverity.Error, error!.toString())
    dispatch(setCustomerLoading(false))
    return
  }
  if (!customerInfo) {
    handleShowToast(AlertSeverity.Error, 'Failed to get customer data')
    dispatch(setCustomerLoading(false))
    return
  }

  /**
   * If a newer request for customer data has been made, abort this one before updating the state.
   * This protects against a race condition where an older request could update the state after a newer request
   * if the newer request resolves first.
   */
  if (signal.aborted) {
    return
  }

  if (isDWEmployee) {
    // If user is a DW employee and the customer name is set we fetch the customer data
    dispatch(setCustomerNames(customerInfo))
    dispatch(setHomePath(Paths.DEEPWATCH_EXPERTS))
  } else {
    dispatch(setCustomer(customerInfo))
  }

  dispatch(setCustomerLoading(false))

  await ldClient?.identify({
    key: email,
    email,
    kind: 'user',
    customerShortName: customerInfo.customerShortName,
    betaAgreementStatus: customerInfo.betaAgreementStatus,
  })
  pendo.updateOptions({
    visitor: { id: email, custshortname: customerInfo.customerShortName },
  })
}

export const initializeTermsAccepted = async (args: {
  isDWEmployee: boolean | undefined
  dispatch: Dispatch<AppAction>
  handleShowToast
  getAcceptedTerms: (args: {
    variables: GetAcceptedTermsVariables
  }) => Promise<{
    data: GetAcceptedTermsData | undefined
  }>
  featureNgMdr: boolean
}) => {
  const {
    isDWEmployee,
    dispatch,
    getAcceptedTerms,
    featureNgMdr,
    handleShowToast,
  } = args

  const mainPath = featureNgMdr ? Paths.MDR : Paths.DASHBOARD

  if (isDWEmployee) {
    dispatch(setTermsAccepted(Terms.ACCEPTED)) // Bypass the acceptance of terms as this is not applicable to internal users
  } else {
    // If the user is not a DW employee and the terms are loading we fetch the terms status
    try {
      const { data: termsData } = await getAcceptedTerms({
        variables: {
          acceptedVersion: tosVersion,
        },
      })

      if (termsData?.getAcceptedTerms) {
        dispatch(setTermsAccepted(Terms.ACCEPTED))
        dispatch(setHomePath(mainPath))
      } else {
        dispatch(setTermsAccepted(Terms.DECLINED))
        dispatch(setHomePath(Paths.TERMS))
      }
    } catch (error) {
      captureException(error)
      dispatch(setHomePath(Paths.TERMS))
      handleShowToast(AlertSeverity.Error, error)
      dispatch(setCustomerLoading(false))

      return
    }
  }
}

const selectCustomerInfoQueryByFeatureFlags = (
  featureEnvHealth: boolean,
  featureBetaAgreement: boolean,
) => {
  if (featureBetaAgreement) {
    return featureEnvHealth
      ? GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA_AND_BETA_AGREEMENT
      : GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_BETA_AGREEMENT
  }
  return featureEnvHealth
    ? GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE_AND_SPLUNK_METADATA
    : GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE
}

const UserInit = ({ children }: { children: ReactNode }) => {
  const {
    dispatch,
    state: {
      termsAccepted,
      oktaSession,
      dwExpertsCustomer: { customerShortName },
      user: { isDWEmployee, email },
      dateFilter,
    },
  } = useContext(Context)
  const abortController = useRef<AbortController | null>(null)

  const { customer, isLoading: isCustomerLoading } = useCustomer()
  const ldClient = useLDClient()
  const { featureEnvHealth, featureNgMdr, featureBetaAgreement } = useFlags()
  const [searchParams] = useSearchParams()
  const shortName = searchParams.get('customer')
  const { handleShowToast } = useToast()

  const [getAcceptedTerms] = useLazyQuery<
    GetAcceptedTermsData,
    GetAcceptedTermsVariables
  >(GET_ACCEPTED_TERMS)

  const query = selectCustomerInfoQueryByFeatureFlags(
    featureEnvHealth,
    featureBetaAgreement,
  )
  const [getCustomerInfo] = useLazyQuery<
    CustomerInfoData,
    CustomerInfoVariables
  >(query)

  // If the user is not logged in then don't do anything
  const isUserLoggedIn = !(
    oktaSession === SessionStatus.INACTIVE || isDWEmployee === undefined
  )

  /**
   * If the user is logged in and the terms status has not been fetched, we fetch the terms status
   */
  useEffect(() => {
    if (isUserLoggedIn && termsAccepted === Terms.LOADING) {
      initializeTermsAccepted({
        isDWEmployee,
        dispatch,
        getAcceptedTerms,
        handleShowToast,
        featureNgMdr,
      })
    }
  }, [
    isDWEmployee,
    termsAccepted,
    isUserLoggedIn,
    dispatch,
    getAcceptedTerms,
    featureNgMdr,
  ])

  /**
   * If the user is logged in and the terms status has been fetched, we fetch the customer data
   */
  useEffect(() => {
    if (isUserLoggedIn && termsAccepted !== Terms.LOADING) {
      // If there is an in-progress handleSession, abort updates to the customer info
      if (abortController.current) {
        abortController.current.abort()
      }

      abortController.current = new AbortController()
      handleSession({
        getCustomerInfo,
        customerShortName,
        email,
        ldClient,
        dispatch,
        handleShowToast,
        isDWEmployee,
        shortName,
        signal: abortController.current.signal,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCustomerInfo,
    isDWEmployee,
    termsAccepted,
    shortName,
    dispatch,
    email,
    ldClient,
    isUserLoggedIn,
    featureEnvHealth,
    featureBetaAgreement,
  ])

  /**
   * Once the customer data has been loaded, we update the date filter based on the maturity score start date
   *
   * If the range between the maturity score start date and the default end date is less than the default date range (DATE_RANGE_AMOUNT_OF_DAYS),
   * we update the start date to be DATE_RANGE_AMOUNT_OF_DAYS before the default end date
   */
  useEffect(() => {
    if (customer.maturityScoreStartDate && !isCustomerLoading) {
      updateDateFilterFromMaturityDate(
        customer.maturityScoreStartDate,
        dispatch,
        dateFilter,
      )
    }
  }, [customer.maturityScoreStartDate, isCustomerLoading, dateFilter, dispatch])

  return <>{children}</>
}

export default UserInit
