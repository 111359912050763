import { lighten, Typography } from '@mui/material'

import { ReportType } from '@models/ThreatIntel'

export const getSideSheetHeaderText = (type: ReportType | undefined) => {
  switch (type) {
    case ReportType.CUSTOMER_ADVISORY:
      return (
        <Typography
          sx={(theme) => ({
            color: theme.palette.severity.medium,
            ...theme.applyStyles('dark', {
              color: theme.palette.severity.medium
                ? lighten(theme.palette.severity.medium, 0.45)
                : undefined,
            }),
          })}
          fontWeight={600}
          variant="h6"
        >
          {type.valueOf()}
        </Typography>
      )
    default:
      return (
        <Typography color="textPrimary" fontWeight={600} variant="h6">
          Report Details
        </Typography>
      )
  }
}
