import { CellContext } from '@tanstack/react-table'
import { Box, IconButton, Typography } from '@mui/material'

import { getFormattedDate } from '@utils/DateTimeUtils'
import { Report } from '@models/Report'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { zIndex } from '@components/App/Styles/zIndex'

export interface ReportCreatedAtCellValues {
  createdAt: Report['createdAt']
  status: Report['status']
}

interface ReportCreatedAtCellProps {
  cellContext: CellContext<Report, ReportCreatedAtCellValues>
  handleDeleteReportModal: (reportToDelete: Report) => void
  handleDownloadReport: (reportId: number) => void
}

const ReportCreatedAtCell: React.FC<ReportCreatedAtCellProps> = ({
  cellContext,
  handleDeleteReportModal,
  handleDownloadReport,
}) => {
  const { createdAt, status } = cellContext.getValue()

  if (status === 'Requested') {
    return <Loader strokeWidth={2} size={14} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            color:
              status !== 'Generated'
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
          }),
        })}
      >
        {getFormattedDate(createdAt, 'P')}
      </Typography>

      <Box
        id="cell-hover-buttons"
        sx={{
          display: 'flex',
          flex: '1',
          position: 'absolute',
          inset: '0',
          alignItems: 'center',
          opacity: '0',
          zIndex: zIndex.CELL_HOVER_BUTTONS,
        }}
      >
        <Box
          sx={(theme) => ({
            background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.lighter} 90%)`,
            width: '100%',
            height: '100%',
            ...theme.applyStyles('dark', {
              background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.light} 90%)`,
            }),
          })}
        />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.lighter,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.light,
            }),
            display: 'flex',
          })}
        >
          <IconButton
            data-testid="delete-report-button"
            onKeyDown={(keyboardEvent) => {
              keyboardEvent.stopPropagation()
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteReportModal(cellContext.row.original)
            }}
          >
            <Icon variant="trashOutline" />
          </IconButton>
          <IconButton
            data-testid="download-report-button"
            onKeyDown={(keyboardEvent) => {
              keyboardEvent.stopPropagation()
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleDownloadReport(cellContext.row.original.id)
            }}
          >
            <Icon variant="downloadOutline" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ReportCreatedAtCell
