import cloneDeep from 'lodash/cloneDeep'

import { DashboardLink } from '@components/Home/Dashboard/MyLinksWidget/MyLinksWidget'
import { DashboardLink as DashboardLinksData } from '@models/Dashboard'
import { IconVariant } from '@common/Icon/Icons'
import { Customer } from '@models/Customer'

function getTypeSafeKeys<T extends object>(obj: T): Extract<keyof T, string>[] {
  return Object.keys(obj) as Extract<keyof T, string>[]
}

export const getLinkTitle = (key: keyof DashboardLinksData): string => {
  switch (key) {
    case 'splunk':
    case 'crowdstrikeNgMdr':
    case 'microsoftSentinel':
      return 'SIEM'
    case 'servicenow':
      return 'ServiceNow'
    // case 'crowdstrike':
    //   return 'CrowdStrike'
    case 'threatLabs':
      return 'Deepwatch Malware Portal'
    default:
      return ''
  }
}

export const getLinkUrl = (
  key: keyof DashboardLinksData,
  data: DashboardLinksData,
): string => {
  switch (key) {
    case 'crowdstrikeNgMdr':
      return data.crowdstrikeNgMdr ?? ''
    case 'splunk':
      return data.splunk ?? ''
    case 'microsoftSentinel':
      return data.microsoftSentinel ?? ''
    case 'servicenow':
      return data.servicenow
    // case 'crowdstrike':
    //   return data.crowdstrike
    case 'threatLabs':
      return data.threatLabs
    default:
      return ''
  }
}

export const getLinkIcon = (key: keyof DashboardLinksData): IconVariant => {
  switch (key) {
    case 'threatLabs':
      return 'nuclearOutline'
    default:
      return 'logOutOutline'
  }
}

export const shapeMyLinksData = (
  data: DashboardLinksData,
  {
    ngMdrEnabled,
    sentinelEnabled,
  }: Pick<Customer, 'ngMdrEnabled' | 'sentinelEnabled'>,
): DashboardLink[] => {
  if (!data) {
    return []
  }

  // copy data to make function pure
  const dataCopy = cloneDeep(data)

  // Delete links for SIEMs which are not enabled
  if (!ngMdrEnabled) {
    delete dataCopy.crowdstrikeNgMdr
  }
  if (!sentinelEnabled) {
    delete dataCopy.microsoftSentinel
  }
  if (ngMdrEnabled || sentinelEnabled) {
    delete dataCopy.splunk
  }

  return getTypeSafeKeys(dataCopy)
    .filter((key) => key !== '__typename')
    .map((key) => {
      const title = getLinkTitle(key)
      const url = getLinkUrl(key, dataCopy)
      const icon = getLinkIcon(key)

      return {
        title,
        url,
        icon,
      }
    })
    .filter((link) => link.title !== '' && link.url !== '')
}
