import { Box, BoxProps, styled } from '@mui/material'

const StyledTableCell = styled((props) => <Box component="td" {...props} />)<
  BoxProps<'td'>
>(({ theme }) => ({
  padding: '0.75rem 0.5rem',
  borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,

  ':first-of-type': {
    borderLeft: `1px solid ${theme.vars.palette.secondary.main}`,
  },
  ':last-child': {
    borderRight: `1px solid ${theme.vars.palette.secondary.main}`,
  },
  ...theme.applyStyles('dark', {
    borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
    ':first-of-type': {
      borderLeft: `1px solid ${theme.vars.palette.secondary.light}`,
    },
    ':last-child': {
      borderRight: `1px solid ${theme.vars.palette.secondary.light}`,
    },
  }),
}))

export default StyledTableCell
