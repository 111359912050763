import { Box, SxProps, Theme, Typography } from '@mui/material'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import PopoverListItem from '@common/PopoverListItem'
import Icon from '@common/Icon'

import SearchBox from '../SearchBox/SearchBox'
import {
  AnswerId,
  customerQuestions,
  CustomerQuestionType,
} from '../CyberAssistantQuestions'
import AnswerDetails from '../AnswerDetails/AnswerDetails'
import { CyberAssistantView } from '../CyberAssistant'
import { getAnswerComponent } from '../CyberAssistant.utils'

const typographyContainer: SxProps = {
  height: '45px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  alignContent: 'center',
  paddingLeft: '8px',
  width: '90%',
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
  }),
})

const highLightedTypographyStyle: SxProps<Theme> = (theme: Theme) => ({
  display: 'inline',
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
  }),
})

type KnowledgeBaseView = 'questionView' | 'answerView'

interface KnowledgeBaseProps {
  handleViewTypeChange: (viewType: CyberAssistantView) => void
  handleSearchTermChange: (searchTerm: string) => void
}

const KnowledgeBase = ({
  handleSearchTermChange,
  handleViewTypeChange,
}: KnowledgeBaseProps) => {
  const [localSearch, setLocalSearch] = useState<string>('')

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [results, setResults] =
    useState<CustomerQuestionType[]>(customerQuestions)
  const [knowledgeBaseView, setKnowledgeBaseView] =
    useState<KnowledgeBaseView>('questionView')
  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string | JSX.Element>('')

  const navigateToAnswerView = async (inquiry: CustomerQuestionType) => {
    setKnowledgeBaseView('answerView')
    setQuestion(inquiry.question)
    const answer = getAnswerComponent(inquiry.id as AnswerId, selectedCustomer)
    setAnswer(answer || 'Content will go here')
  }

  const navigateToChatView = () => {
    handleViewTypeChange('search')
  }

  const navigateBack = () => {
    setLocalSearch('')
    setKnowledgeBaseView('questionView')
  }

  const onSearchTerm = (term: string) => {
    setLocalSearch(term)
    handleSearchTermChange(term)
  }

  const updateResults = (results: CustomerQuestionType[]) => {
    setResults(results)
  }

  const renderHighlightedText = (text: string, searchTerm: string) => {
    searchTerm = searchTerm.trim()
    if (!searchTerm) return text
    const terms = searchTerm
      .split(' ')
      .map((term) => term.toLowerCase())
      .filter(Boolean)

    const lowerCaseText = text.toLowerCase()
    const textSegments: JSX.Element[] = []
    let currentIndex = 0
    // Find all matches in the text
    while (currentIndex < text.length) {
      const nextMatchIndex = Math.min(
        ...terms
          .map((term) => lowerCaseText.indexOf(term, currentIndex))
          .filter((index) => index !== -1),
        text.length,
      )
      // If there are no more matches, add the remaining text
      if (nextMatchIndex === text.length) {
        textSegments.push(
          <Typography
            variant="body2"
            key={currentIndex}
            sx={highLightedTypographyStyle}
          >
            {text.substring(currentIndex)}
          </Typography>,
        )
        break
      }
      // Add the text between the current and next match
      if (nextMatchIndex > currentIndex) {
        textSegments.push(
          <Typography
            variant="body2"
            key={currentIndex}
            sx={highLightedTypographyStyle}
          >
            {text.substring(currentIndex, nextMatchIndex)}
          </Typography>,
        )
      }
      // Find the matched term
      const matchedTerm = terms.find((term) =>
        lowerCaseText.startsWith(term, nextMatchIndex),
      )
      // Check if the match is valid (at the start of a word)
      if (matchedTerm) {
        const matchedTermLength = matchedTerm.length
        const precedingChar =
          nextMatchIndex > 0 ? text[nextMatchIndex - 1] : ' '
        // Ensure the preceding character is a space or punctuation
        if (precedingChar === ' ' || /\W/.test(precedingChar)) {
          textSegments.push(
            <Typography
              variant="body2"
              key={nextMatchIndex}
              fontWeight={'bold'}
              sx={highLightedTypographyStyle}
            >
              {text.substring(
                nextMatchIndex,
                nextMatchIndex + matchedTermLength,
              )}
            </Typography>,
          )
        } else {
          // If the match is not a valid word start, include it as normal text
          textSegments.push(
            <Typography
              variant="body2"
              key={nextMatchIndex}
              sx={highLightedTypographyStyle}
            >
              {text.substring(
                nextMatchIndex,
                nextMatchIndex + matchedTermLength,
              )}
            </Typography>,
          )
        }

        currentIndex = nextMatchIndex + matchedTermLength
      } else {
        currentIndex = nextMatchIndex + 1
      }
    }

    return <>{textSegments}</>
  }

  const renderKnowledgeBaseContent = () => {
    if (localSearch) {
      return (
        <>
          <PopoverListItem
            key="top-item"
            onClick={() => {}}
            onKeyDown={() => {}}
          >
            <Box data-testid="question-not-found-row" sx={typographyContainer}>
              <Typography
                color="textSecondary"
                variant="body2"
                fontWeight={400}
                sx={(theme) => ({
                  ...typographyContent(theme),
                  color: theme.vars.palette.primary.dark,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.primary.main,
                  }),
                })}
                onClick={() => {
                  navigateToChatView()
                }}
              >
                Not seeing your question? Let us know!
              </Typography>
            </Box>
          </PopoverListItem>
          {results.map((inquiry, index) => {
            return (
              <PopoverListItem
                key={index}
                onClick={() => {
                  navigateToAnswerView(inquiry)
                }}
                onKeyDown={() => {
                  navigateToAnswerView(inquiry)
                }}
              >
                <Box data-testid="customer-question" sx={typographyContainer}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    fontWeight={400}
                    sx={typographyContent}
                  >
                    {renderHighlightedText(inquiry.question, localSearch)}
                  </Typography>
                  <Icon
                    variant="chevronForward"
                    size={22}
                    sx={(theme) => ({
                      alignSelf: 'center',
                      color: theme.vars.palette.text.primary,
                    })}
                  />
                </Box>
              </PopoverListItem>
            )
          })}
        </>
      )
    } else {
      return customerQuestions.map((inquiry, index) => {
        return (
          <PopoverListItem
            key={index}
            onClick={() => {
              navigateToAnswerView(inquiry)
            }}
            onKeyDown={() => {
              navigateToAnswerView(inquiry)
            }}
            sx={(theme) => ({
              borderTopLeftRadius: '0 !important',
              borderTopRightRadius: '0 !important',
              ':hover': {
                backgroundColor: theme.vars.palette.secondary.lighter,
              },
            })}
          >
            <Box data-testid="customer-question" sx={typographyContainer}>
              <Typography
                color="textSecondary"
                variant="body2"
                fontWeight={400}
                sx={typographyContent}
              >
                {inquiry.question}
              </Typography>
              <Icon
                variant="chevronForward"
                size={22}
                sx={(theme) => ({
                  alignSelf: 'center',
                  color: theme.vars.palette.text.primary,
                })}
              />
            </Box>
          </PopoverListItem>
        )
      })
    }
  }

  const renderContent = () => {
    if (knowledgeBaseView === 'answerView') {
      return (
        <AnswerDetails
          goBack={navigateBack}
          question={question}
          answer={answer}
        />
      )
    }

    return (
      <>
        <Box
          sx={(theme) => ({
            paddingLeft: '1rem',
            paddingRight: '1rem',
            justifyItems: 'center',
            textAlign: 'center',
            paddingTop: '0.5rem',
            borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
            ...theme.applyStyles('dark', {
              borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
            }),
          })}
        >
          <SearchBox
            searchValue={localSearch}
            questions={customerQuestions}
            onSearch={onSearchTerm}
            results={updateResults}
          />
        </Box>
        <Box
          sx={() => ({
            height: '100%',
            overflowY: 'auto',
          })}
        >
          {renderKnowledgeBaseContent()}
          <Box />
        </Box>
      </>
    )
  }

  return (
    <Box
      data-testid="cyber-assistant-knowledge-base"
      sx={(theme) => ({
        display: 'contents',
        borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
        ...theme.applyStyles('dark', {
          borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
        }),
      })}
    >
      {renderContent()}
    </Box>
  )
}

export default KnowledgeBase
