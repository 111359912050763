import { SideSheet } from '@common/SideSheet'
import { SidesheetDualColumn } from '@common/SidesheetDualColumn'
import { CollapsiblePanel } from '@common/CollapsiblePanel'

import { UtilizationDetailsChart } from './UtilizationDetailsChart'
import { TopSourceTypeUtilizationDeviationTable } from '../TopSourceTypeUtilizationDeviationTable'
import {
  ChartType,
  useEnvironmentHealthChartDetailsSideSheet,
} from './EnvironmentHealthChartDetailsSideSheetContext'
import { UtilizationAverageChart } from './UtilizationAverageChart'

export const EnvironmentHealthChartDetailsSideSheet: React.FC = () => {
  const {
    isOpen,
    onClose,
    sourceTypes,
    details,
    chartTitle,
    chartType,
    chartValue,
  } = useEnvironmentHealthChartDetailsSideSheet()

  const topThreeSourceTypeDeviations = sourceTypes
    .map(({ name, ingestDeviation }) => ({
      logSource: name,
      deviationAmount: ingestDeviation,
    }))
    .filter((data) => data.logSource !== 'otherSourceTypes')
    .sort((a, b) => b.deviationAmount.gb - a.deviationAmount.gb)
    .slice(0, 3)

  return (
    <SideSheet
      data-testid="environment-health-chart-details-side-sheet"
      open={isOpen}
      closeSideSheet={onClose}
      title="Chart details"
    >
      {/*
       * Negative margin due to padding which is normally added to the sidesheet content;
       * we don't want it since we're using a collapsible panel as the first child
       */}
      <div style={{ marginTop: '-1rem' }} />
      <CollapsiblePanel title={chartTitle}>
        {chartType === ChartType.SINGLE_DAY ? (
          <UtilizationDetailsChart
            sourceTypes={sourceTypes}
            // eslint-disable-next-line @getify/proper-ternary/nested
            chartValue={chartValue}
          />
        ) : (
          <UtilizationAverageChart sourceTypes={sourceTypes} />
        )}
      </CollapsiblePanel>
      {chartValue === 'gb' ? (
        <CollapsiblePanel title="Top source type deviation from average">
          <TopSourceTypeUtilizationDeviationTable
            data={topThreeSourceTypeDeviations}
          />
        </CollapsiblePanel>
      ) : null}
      <CollapsiblePanel title={'Details'} shouldAllowFocus={isOpen}>
        <SidesheetDualColumn items={details} />
      </CollapsiblePanel>
    </SideSheet>
  )
}
