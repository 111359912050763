/* eslint-disable security/detect-object-injection */
import { CellContext } from '@tanstack/react-table'
import { Chip, Theme } from '@mui/material'

import { EDRAsset } from '..'

const RFMStatusCellStyles = (theme: Theme, value) => {
  return {
    '&.MuiChip-outlined': {
      color:
        value === 'no'
          ? theme.vars.palette.text.primary
          : theme.vars.palette.warning.main,
      borderColor:
        value === 'no'
          ? theme.vars.palette.text.primary
          : theme.vars.palette.warning.main,
    },
  }
}

const RFMStatusCell = (props: CellContext<EDRAsset, string>) => {
  const value = props.getValue()

  return (
    <Chip
      data-testid={`chip-${value}`}
      variant="outlined"
      label={value.toUpperCase()}
      sx={(theme) => RFMStatusCellStyles(theme, value)}
    />
  )
}

export default RFMStatusCell
