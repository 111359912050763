import { Badge, Box } from '@mui/material'

import { useNotificationsContext } from '@components/Layout/NotificationsContext'
import Icon from '@common/Icon'

export interface NotificationsIconBadgeProps {
  isActive: boolean
}

export const NotificationsIconBadge: React.FC<NotificationsIconBadgeProps> = ({
  isActive,
}) => {
  const { unreadNotificationsCount, loadingNotifications } =
    useNotificationsContext()

  const variant = isActive ? 'notifications' : 'notificationsOutline'
  const showActiveBadge = !loadingNotifications && unreadNotificationsCount > 0

  return (
    <Box
      data-testid="notifications-icon-badge"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        'ion-icon': {
          position: 'unset !important',
        },
      }}
    >
      <Badge
        badgeContent={unreadNotificationsCount}
        max={99}
        sx={(theme) => {
          return showActiveBadge
            ? {
                color: theme.vars.palette.common.white,
                '.MuiBadge-badge': {
                  animation: 'burst 1s ease-in-out 0ms forwards',
                  '@keyframes burst': {
                    '0%': {
                      transform: 'scale(0) translate(50%, -50%)',
                    },
                    '20%': {
                      transform: 'scale(1.2222) translate(50%, -50%)',
                    },
                    '70%': {
                      transform: 'scale(1.2222) translate(50%, -50%)',
                    },
                    '100%': {
                      backgroundColor: theme.vars.palette.error.dark,
                      borderWidth: '1px',
                      minWidth: '18px',
                      transform: 'scale(1) translate(50%, -50%)',
                    },
                  },
                  '&:after': {
                    content: '""',
                    animation: 'sonar 1s ease-in-out 200ms forwards',
                    '@keyframes sonar': {
                      '0%': {
                        opacity: 1,
                        transform: 'scale(0.9)',
                      },
                      '100%': {
                        opacity: 0,
                        transform: 'scale(2)',
                      },
                    },
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    border: '2px solid rgba(204,0,65,.5)',
                    pointerEvents: 'none', // Prevent the pseudo element from intercepting pointer events
                  },
                },
              }
            : {
                color: theme.vars.palette.common.white,
                '.MuiBadge-badge': {
                  animation: 'fade-out 400ms linear 0ms forwards',
                  '@keyframes fadeOut': {
                    '0%': {
                      backgroundColor: theme.vars.palette.text.primary,
                      opacity: 1,
                      transform: 'scale(0.4444) translate(50%, -50%)',
                    },
                    '20%': {
                      backgroundColor: theme.vars.palette.text.disabled,
                      transform: 'scale(0.4444) translate(50%, -50%)',
                    },
                    '70%': {
                      backgroundColor: theme.vars.palette.text.disabled,
                      transform: 'scale(0.4444) translate(50%, -50%)',
                    },
                    '100%': {
                      backgroundColor: theme.vars.palette.text.primary,
                      opacity: 0,
                      transform: 'scale(0) translate(50%, -50%)',
                    },
                  },
                },
              }
        }}
      >
        <Icon
          size={24}
          sx={{ color: 'text.primary', flex: 'none' }}
          variant={variant}
        />
      </Badge>
    </Box>
  )
}
