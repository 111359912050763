import { gql } from '@apollo/client'

export const CYBER_ASSISTANT_TIME_SAVED = gql`
  query cyberAssistantTimeSaved($selectedCustomer: String) {
    cyberAssistantTimeSaved(selectedCustomer: $selectedCustomer) {
      totalLoggedEvents
      totalDaysSaved
      totalEscalatedTickets
      totalAlerts
    }
  }
`
export const CYBER_ASSISTANT_TIME_SAVED_V2 = gql`
  query cyberAssistantTimeSavedV2($selectedCustomer: String) {
    cyberAssistantTimeSaved(selectedCustomer: $selectedCustomer) {
      totalLoggedEvents
      totalDaysSaved
      totalEscalatedTickets
    }
    cyberAssistantTPFPQuarterlyRate(selectedCustomer: $selectedCustomer) {
      totalAlerts
    }
  }
`
export interface MetricAveragesData {
  cyberAssistantMetricAverages: {
    pastQuarterAverage: number
    monthlyAverages: {
      month: string
      average: number
    }[]
    comparison: number
    percentageDifference: number
  }
}

export interface MetricAveragesVariables {
  selectedCustomer: string | null
  input: {
    reportId: number
  }
}

// TPFP stands for True Positive False Positive.
export const CYBER_ASSISTANT_TPFP_QUARTERLY_RATE = gql`
  query cyberAssistantTPFPQuarterlyRate($selectedCustomer: String) {
    cyberAssistantTPFPQuarterlyRate(selectedCustomer: $selectedCustomer) {
      truePositiveRate
      falsePositiveRate
      totalSecurityTickets
      maliciousPenTestRate
      totalLogs
      totalAlerts
      pastQuarterStartDate
      pastQuarterEndDate
    }
    cyberAssistantCustomerAndDWTP(selectedCustomer: $selectedCustomer) {
      dwQuarterlyTP
    }
  }
`

export const CYBER_ASSITANT_METRIC_AVERAGES = gql`
  query CyberAssistantMetricAverages(
    $selectedCustomer: String
    $input: CyberAssistantMetricInput
  ) {
    cyberAssistantMetricAverages(
      selectedCustomer: $selectedCustomer
      input: $input
    ) {
      pastQuarterAverage
      monthlyAverages {
        month
        average
      }
      metricType
      comparison
      percentageDifference
    }
  }
`

export const CYBER_ASSISTANT_VENDOR_USAGE = gql`
  query cyberAssistantVendorUsage($selectedCustomer: String) {
    cyberAssistantVendorUsage(selectedCustomer: $selectedCustomer) {
      vendor
      date
      dailyIngestPercent
    }
  }
`
