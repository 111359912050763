import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { ListItemType } from 'src/interfaces/ListItemType'

interface WidgetItemProps {
  hasError?: boolean
  title: string
  widgetItems: ListItemType[]
}

const WidgetList: React.FC<WidgetItemProps> = ({
  hasError = false,
  title,
  widgetItems,
}) => {
  return (
    <>
      <Typography fontWeight={600} variant="body1" sx={{ padding: '1rem 0' }}>
        {title}
      </Typography>
      {hasError ? (
        <ComponentError />
      ) : (
        <List>
          {widgetItems.map((item, index) => {
            return (
              <ListItem
                key={item.id}
                id={`list-item--${index}`}
                sx={{ pointerEvents: item.disabled ? 'none' : 'auto' }}
                onClick={item.onClick}
                onKeyUp={(event) => {
                  if (!item.disabled) {
                    handleKeyboardAction(
                      event,
                      item.onClick ? item.onClick : () => null,
                    )
                  }
                }}
              >
                {item.leftIcon ? (
                  <ListItemIcon>
                    <Icon
                      variant={item.leftIcon}
                      size={20}
                      sx={{ color: item.iconColor }}
                    />
                  </ListItemIcon>
                ) : (
                  item.leftAlternateImage
                )}
                <ListItemText
                  primary={item.name}
                  sx={{ marginLeft: item.leftAlternateImage ? '8px' : '' }}
                />
                {item.loading ? (
                  <Loader />
                ) : (
                  <ListItemText
                    secondary={item.rightText}
                    id={`widget-list-item--${item.id}`}
                  />
                )}
              </ListItem>
            )
          })}
        </List>
      )}
    </>
  )
}

export default WidgetList
