import { Typography, Button, Box, ButtonProps, Divider } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'

import { Dialog } from '@common/Dialog'

import { useScrollBottom } from './useScrollBottom'

type ActionConfig = {
  onClick: () => void
  label?: string
  variant?: ButtonProps['variant']
}

const gradientBackdropStyle = {
  backdrop: {
    style: {
      backgroundImage: `url(${require('@app-assets/frostedDeepwatch.svg')})`,
      backgroundSize: 'cover',
      transition: '225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backdropFilter: 'blur(12px)',
      backgroundColor: 'rgb(28 28 28 / 54%)',
    },
  },
}

type AgreementModalProps = {
  isOpen: boolean
  title: string
  version: string
  markdownContent: string
  acceptConfig: ActionConfig
  declineConfig: ActionConfig
  includeGradientBackdrop?: boolean
  children?: React.ReactNode
}

const AgreementModal = ({
  isOpen,
  title,
  version,
  markdownContent,
  acceptConfig,
  declineConfig,
  includeGradientBackdrop,
  children,
}: AgreementModalProps): JSX.Element => {
  const { scrollableElementRef, hasReachedBottom } = useScrollBottom({
    resetTrigger: isOpen,
  })

  return (
    <Dialog
      title={title}
      renderTitle={({ title }) => (
        <Box
          id="agreement-header-container"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography color="textPrimary" sx={{ m: 0 }} noWrap variant="h6">
            {title}
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="textPrimary" sx={{ m: 0 }} variant="caption">
              {`Version ${version}`}
            </Typography>
          </Box>
        </Box>
      )}
      isOpen={isOpen}
      onClose={declineConfig.onClick}
      disableEscapeKeyDown
      isCloseButtonVisible={false}
      slotProps={includeGradientBackdrop ? gradientBackdropStyle : {}}
    >
      <Box data-testid="agreement-modal">
        <Box
          tabIndex={-1}
          sx={{
            maxWidth: '55vw',
            maxHeight: '68vh',
          }}
        >
          {children ? (
            <>
              <Box sx={{ px: 3, py: 2 }}>{children}</Box>
              <Divider sx={{ mx: 3 }} />
            </>
          ) : null}

          <Box
            ref={scrollableElementRef}
            sx={{
              boxSizing: 'border-box',
              mx: 3,
              py: 2.25,
              overflow: 'auto',
              maxHeight: '40vh',
            }}
          >
            <Typography
              component="div"
              sx={(theme) => ({
                fontSize: theme.typography.body2.fontSize,
                '& p': {
                  color: 'text.primary',
                  mb: 2,
                  '& a': {
                    borderRadius: '5px',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap',
                    textDecoration: 'none',
                  },
                },
                '& ul, & ol': {
                  pl: 3,
                  pb: 2,
                },
              })}
            >
              <ReactMarkdown
                components={{
                  // Markdown doesn't support underline, so we're coopting the code tag to do it
                  code(props) {
                    return (
                      <span style={{ textDecoration: 'underline' }}>
                        {props.children}
                      </span>
                    )
                  },
                }}
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    {
                      target: '_blank',
                      rel: ['noopener', 'noreferrer'],
                    },
                  ],
                ]}
              >
                {markdownContent}
              </ReactMarkdown>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              m: 2,
            }}
          >
            <Button
              variant={declineConfig.variant}
              onClick={declineConfig.onClick}
            >
              {declineConfig.label ?? 'Decline'}
            </Button>
            <Button
              variant={acceptConfig.variant}
              onClick={acceptConfig.onClick}
              disabled={!hasReachedBottom}
            >
              {acceptConfig.label ?? 'Agree'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AgreementModal
