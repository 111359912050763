import { Parser } from '@json2csv/plainjs'
import { toSvg } from 'html-to-image'
import { Options } from 'html-to-image/lib/types'

export const handleDownload = (path: string): void => {
  const link = document.createElement('a')
  link.href = path
  link.click()
  link.remove()
}

export const csvDownload = (
  data: object | object[],
  fileName: string,
  fields?: string[],
): void => {
  const opts = {
    fields: fields ? fields : undefined,
    header: true,
  }
  const parser = new Parser(opts)
  const csv = parser.parse(data)

  const blob = new Blob([csv], { type: 'text/csv' })

  const link = document.createElement('a')
  link.download = `${fileName}.csv`
  link.href = URL.createObjectURL(blob)
  link.click()
  link.remove()
}

export const jsonDownload = (
  data: object | object[],
  fileName: string,
): void => {
  const blob = new Blob([JSON.stringify(data)], { type: 'text/json' })

  const link = document.createElement('a')
  link.download = `${fileName}.json`
  link.href = URL.createObjectURL(blob)
  link.click()
  link.remove()
}

export const svgDownload = async (
  chartRef: React.RefObject<HTMLElement>,
  fileName: string,
  overrideOptions: Options = {},
): Promise<void> => {
  if (!chartRef.current) throw new Error('No chart ref found')

  const img = await toSvg(chartRef.current, overrideOptions)

  const link = document.createElement('a')
  link.download = `${fileName}.svg`
  link.href = img
  link.click()
  link.remove()
}
