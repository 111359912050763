import { Cell } from '@tanstack/react-table'
import { CSSProperties, memo } from 'react'
import { SxProps, Theme } from '@mui/material'

import { TableCell } from '@common/Table'
import { handleKeyboardAction } from '@utils/index'

// These imports are needed to @link to type declarations in jsdoc comments
import CommonTableRow from './CommonTableRow'

import type {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RowSelectionRow,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CoreOptions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useReactTable,
} from '@tanstack/react-table'

export const getColumnWidth = (size: number) =>
  size === Number.MAX_SAFE_INTEGER ? undefined : size

interface IdentityFunction {
  <T>(fn: T): T
}

const typedMemo: IdentityFunction = memo // A typed version of memo to ensure generics work as expected; see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-568218789

interface MemoizedTanStackTableRowProps<T> {
  /** Tell the cursor to not turn into a pointer  */
  disableCursor?: boolean
  /** Should be passed the value of {@link RowSelectionRow.getVisibleCells|`Row.getVisibleCells()`} */
  visibleCells: Cell<T, unknown>[]
  /** Should be passed the value of {@link RowSelectionRow.getIsSelected|`Row.getIsSelected()`} */
  isSelected: boolean
  /** Applies the `.disabled` class to the <tr> element if `true` */
  isDisabled?: boolean
  /**
   * A unique value to identify the row,  usually incorporating the value extracted via
   * {@link CoreOptions.getRowId|`getRowId`} in {@link useReactTable|`useReactTable`}
   */
  rowId: string
  /** SX prop to pass into the Material Box used */
  sx?: SxProps<Theme>
  /** Applied as `data-testid` on the `<tr />` element */
  testId?: string
  /** Must be cached (i.e. wrapped with `useCallback`) for memoization to work */
  getTableCellMaxWidth?: (
    cell: Cell<T, unknown>,
    index: number,
  ) => CSSProperties['maxWidth']
  /**
   * Should be passed the {@link RowSelectionRow.toggleSelected|`Row.toggleSelected`} function
   *
   * If not provided, the row will not be selectable, and the `tabIndex` will be set to `-1`
   */
  toggleSelected?: () => void
  /**
   * React Router path that enables "open in new tab" functionality for every cell in the row.
   * Regular clicks will be intercepted, but right-click and middle-click will work normally.
   * Do not include the `customer` query parameter; it will be automatically added by internal routing if necessary.
   * @example
   * linkTo="/tickets/123"
   */
  linkTo?: string
}

export const MemoizedTableCell = typedMemo(TableCell)

const defaultGetTableCellMaxWidth = <T extends object>(
  cell: Cell<T, unknown>,
) => {
  return getColumnWidth(cell.column.getSize())
}

/**
 * @description A component for easily memoizing rows/cells of a TanStack table
 * @example
 * ### Usage within a TanStack table component definition
 *
 * ```tsx
 * {table
 *   .getRowModel()
 *   .rows.map((row) => (
 *     <MemoizedTanStackTableRow
 *       key={row.id}
 *       rowId={row.id}
 *       testId={`some-table-row-${row.id}`}
 *       isSelected={row.getIsSelected()}
 *       toggleSelected={row.toggleSelected}
 *       visibleCells={row.getVisibleCells()}
 *     />
 *   ))
 * }
 * ```
 * @param {MemoizedTanStackTableRowProps} props The props to pass to the component
 * @returns {import('react').ReactNode} The memoized table row
 */
const TanStackTableRow = <T extends object>({
  disableCursor = false,
  visibleCells,
  isSelected,
  isDisabled,
  rowId,
  testId,
  getTableCellMaxWidth = defaultGetTableCellMaxWidth,
  sx,
  toggleSelected,
  linkTo,
}: MemoizedTanStackTableRowProps<T>) => (
  <CommonTableRow
    id={rowId}
    disableCursor={disableCursor}
    isDisabled={isDisabled}
    isSelected={isSelected}
    data-testid={testId}
    tabIndex={toggleSelected && !isDisabled ? 0 : -1}
    onClick={toggleSelected && !isDisabled ? () => toggleSelected() : undefined}
    onKeyDown={(keyboardEvent) => {
      if (toggleSelected && !isDisabled) {
        handleKeyboardAction(keyboardEvent, toggleSelected)
      }
    }}
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
  >
    {visibleCells.map((cell, index) => (
      <MemoizedTableCell
        key={cell.id}
        cell={cell}
        sx={{ maxWidth: getTableCellMaxWidth(cell, index) }}
        linkTo={linkTo}
      />
    ))}
  </CommonTableRow>
)

export const MemoizedTanStackTableRow = typedMemo(TanStackTableRow)
