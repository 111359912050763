import { CellContext } from '@tanstack/react-table'
import { Box, Chip, Typography } from '@mui/material'

import { CustomerHighlight } from '@models/DeepwatchExpert'

interface CustomerNameCellValues {
  name: CustomerHighlight['name']
  isBookmarkedCustomer: CustomerHighlight['isBookmarkedCustomer']
}

interface CustomerNameCellProps {
  cellContext: CellContext<CustomerHighlight, CustomerNameCellValues>
  disableAddedTags: boolean
}

const CustomerNameCell: React.FC<CustomerNameCellProps> = ({
  cellContext,
  disableAddedTags,
}) => {
  const { name, isBookmarkedCustomer } = cellContext.row.original

  const showAdded = isBookmarkedCustomer && !disableAddedTags

  return (
    <Box
      data-testid="customer-name-cell"
      sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}
    >
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {name}
      </Typography>

      {showAdded && (
        <Chip
          sx={[
            (theme) => ({
              border: 0,
              '&.MuiChip-outlined': {
                backgroundColor: theme.vars.palette.primary.main,
                borderColor: theme.vars.palette.secondary.main,
              },
              backgroundColor: theme.vars.palette.primary.light,
              '&.MuiChip-root': {
                height: '100%',
              },
              ...theme.applyStyles('dark', {
                '&.MuiChip-outlined': {
                  color: theme.vars.palette.text.primary,
                  backgroundColor: theme.vars.palette.secondary.darker,
                },
              }),
            }),
          ]}
          data-testid="chip-added"
          variant="outlined"
          label="ADDED"
        />
      )}
    </Box>
  )
}

export default CustomerNameCell
