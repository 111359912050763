// Module
var code = `# BETA PARTICIPATION AGREEMENT

**PLEASE READ THIS DOCUMENT CAREFULLY.** This Beta Participation Agreement ("**Agreement**") governs the relationship between Deepwatch, Inc. ("**Deepwatch**," "**we**," "**us**," or "**our**") and the company ("**you**" or "**your**") on whose behalf these terms are being agreed to as a beta testing participant for use of any Deepwatch Beta Products. In addition, the person accessing and/or using any Beta Product, hereby represents and warrants that such person has full authority to agree to these Terms on your behalf. You shall be responsible for your compliance with this Agreement.

1.  **DEFINITIONS**

    A. "**Beta Product**" means the pre-release beta versions of certain Deepwatch products that have not been released to the general public, including without limitation any software, API or service, and all related Documentation, materials, and Updates.

    B. "**Documentation**" means all reference material furnished or made accessible to you in conjunction with your access or use of the Beta Product.

    C. "**Update**"  means a modification, error correction, bug fix, or new release to or for any Beta Product, existing Deepwatch software, or third-party software.

2.  **PARTICIPATION**. Participation is free of charge for the duration of the beta testing program. You agree participation is voluntary, on an "AS-IS" and as available basis, and AT YOUR OWN RISK. Participation does not create a legal partnership, agency, or employment relationship between you and Deepwatch. You acknowledge that (i) Deepwatch may use third-party software in connection with the Beta Product, including components subject to the terms and conditions of open-source software licenses and/or (ii) Deepwatch may abandon development of the Beta Product(s) at any time and without any obligation or liability to you or any third party.

3.  **LICENSE GRANT, USE AND OWNERSHIP**

    A. \`Limited License\`. During the designated period, Deepwatch grants you a revocable, non-exclusive, non-transferable, right and license to use the Beta Product(s) in accordance with the supporting Documentation solely for internal testing. without modification and the provision of feedback to Deepwatch.

    B. \`Your Data\`. You own all right, title, and interest in and to all your data which may be provided to Deepwatch as a part of your participation. During the beta period you grant Deepwatch, its affiliates and employees a worldwide, limited, royalty-free, non-exclusive, non-transferable license and grant right to (i) access, use, copy, transmit, and display your data provided under this Agreement (including the ability to address service or technical problems, and maintain and monitor usage of the Beta Product) solely for purposes of facilitating your evaluation of those products and Deepwatch’s; and (ii) de-identify and aggregate such data ("Aggregated Data").

    C. \`Evaluation Feedback\`. You will grant Deepwatch a worldwide, perpetual, irrevocable, royalty-free license to use and incorporate into Deepwatch’s products and services any suggestion, enhancement request, recommendation, correction, or any other feedback provided by you concerning the functionality and performance of the Beta Product(s). All feedback will be deemed the exclusive property of Deepwatch and you irrevocably assign to Deepwatch all right, title and interest to all feedback including intellectual property rights.

    D. \`Restrictions\`. You agree that you will not (or allow any person, affiliate, and/or entity to):  (i) sublicense, distribute, rent, copy, modify, create derivative works of, translate, reverse assemble or engineer, decompile, disassemble, or otherwise reduce to human-perceivable form any functionality of the Beta Product(s); (ii) publish or disclose to any third-party any data or Confidential Information relating to the Beta Product; or (iii). use any Beta Product, Documentation, or any other Confidential Information to develop, manufacture, market, sell, or distribute any product or service or any other commercial purpose.

    E. \`Ownership\`. Deepwatch shall own and retain all rights, title and interest in and to the intellectual property rights (including without limitation patent, copyright, trademark, and trade secret rights) in the Beta Product(s), Documentation, feedback and any derivative works thereof. ALL RIGHTS NOT EXPRESSLY GRANTED UNDER THIS AGREEMENT ARE RESERVED FOR THE BENEFIT OF DEEPWATCH.

    F. \`No Services\`. Deepwatch is under no obligation to support the Beta Product(s), or to provide any Updates deemed part of the Beta Product.

4.  **TERM AND TERMINATION**. These Terms apply as of the date anyone accesses the Beta Product(s) and remains in upon the earlier of: (i) the date we provide a commercially available release of the Beta Product; (ii) upon our release of the next generally commercially available version of the Beta Product (unless you choose to continue participation), (iii) expiration of the beta testing period as stated in the Beta Product communication; or (iv) until terminated by either party upon written notice. Upon termination, your rights and access to the Beta Product(s) will immediately cease and you shall stop using the Beta Products, Documentation, and all other items in your possession or control. Deepwatch shall have no obligation to maintain any data provided by you, and may delete such data without further obligation or any liability to you or any third party for such deletion.

5.  **CONFIDENTIALITY**. "**Confidential Information**" shall include the Beta Product(s), all performance data, test results,  materials, software, Documentation provided or made accessible by us to you, whether disclosed orally, in writing, or by examination or inspection and your feedback, other than information which you can demonstrate (i) was already known to you at the time of disclosure; (ii) was generally available in the public domain at the time of disclosure; (iii) became generally available in the public domain after disclosure other than through any act, error, or omission by you, your employee, agent, or contractor; (iv) was subsequently lawfully disclosed to you by a third party without any obligation of confidentiality; or (v) was independently developed by you without use of or reference to any information or materials disclosed by Deepwatch or any of its suppliers. You shall not disclose any Confidential Information to any third party or use any Confidential Information for any purpose other than as expressly authorized under this Agreement. I.  You shall use at least the same degree of care that you use to prevent the disclosure of your own confidential information of like importance, but in no event less than reasonable care, to prevent the disclosure and unauthorized access to any Confidential Information.

6.  **LIMITATION OF LIABILITY**. DEEPWATCH’S LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT  WILL NOT EXCEED ONE HUNDRED UNITED STATES DOLLARS (\$100.00). IN NO EVENT SHALL DEEPWATCH OR ITS SUPPLIERS HAVE ANY LIABILITY FOR INDIRECT, SPECIAL, INCIDENTAL, COVER, RELIANCE, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY KIND, HOWEVER CAUSED, OR FOR DELIVERY OF TECHNICAL SUPPORT SERVICES, LOSS OF BUSINESS, REVENUE, ANTICIPATED SAVINGS, PROFIT, USE, AND/OR LOSS OR CORRUPTION OF ANY DATA AND/OR COST OF DATA RECONSTRUCTION OR PROCUREMENT OF SUBSTITUTE OR REPLACEMENT GOODS, SERVICES, INVENTORY, OR EQUIPMENT, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR UNDER ANY OTHER THEORY OF LIABILITY, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS AGREEMENT, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES. THESE LIMITATIONS OF LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.

7.  **WARRANTY DISCLAIMER**. THE BETA PRODUCT(S), DOCUMENTATION, AND ANY UPDATES ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY OF ANY KIND, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DEEPWATCH DISCLAIMS ANY AND ALL LIABILITY FOR, AND SHALL NOT BE RESPONSIBLE IN ANY WAY REGARDING, ANY THIRD-PARTY SOFTWARE (INCLUDING, WITHOUT LIMITATION, ANY OPEN SOURCE SOFTWARE), CONTENT, INFORMATION, MATERIALS, LINKS, FILES, DATA, AND/OR SEARCH RESULTS THAT MAY BE ACCESSIBLE THROUGH THE  DOCUMENTATION, AND/OR ANY BETA PRODUCT.

8.  **NOTICES**. All notices (except for routine business communications) shall be in writing and sent via certified or registered mail, return receipt requested, or by overnight courier service. All notices to Deepwatch shall be addressed to the Legal Affairs, at 4030 Boy Scout Blvd., Suite 550 Tampa, FL 33607 or sent to Legal@deepwatch.com with a clear subject line.

9.  **GENERAL**

    A. \`Governing Law\`. Any legal action arising from this Agreement shall be governed by the laws of the State of Delaware, without regard to conflicts of laws principles. The parties irrevocably consent to the exclusive jurisdiction and venue of the state and federal courts in Wilmington, Delaware. The parties waive any right to a jury trial in connection with any action arising out of or related to the Beta Product.

    B. \`Survival\`. All terms that either expressly or by their nature should survive, shall survive any expiration or termination of this Agreement for any reason, and shall continue in full force and effect

    C. \`Assignment\`. This Agreement may not be assigned without Deepwatch’s prior written consent, however, upon notice, this Agreement may be assigned to any entity which acquires all or substantially all of your business or assets. Subject to the foregoing, this Agreement shall inure to the benefit of and be binding upon the parties and their respective successors and permitted assigns.

    D. \`Modification and Waiver\`. This is the entire Agreement between the parties. Deepwatch may modify the terms. No delay in exercising any right or remedy shall operate as a waiver of such right or remedy or any other right or remedy. A waiver on one occasion shall not be construed as a waiver of any right or remedy on any future occasion.

    E. \`Acceptance\`. By clicking the "Accept" button or similar action you agree to be bound by these terms.
`;
// Exports
export default code;