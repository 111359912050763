import { CustomSVGProps } from './types'

const PaloLogo: React.FC<CustomSVGProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 11" fill="none">
    <path
      d="M6.96393 7.5766L12.1313 2.39043L10.0699 0.321777L8.00844 2.39043L9.03455 3.4199L3.86951 8.607L5.92862 10.677L7.99004 8.607L6.96393 7.5766Z"
      fill="currentColor"
    />
    <path
      d="M5.95083 0.321151L0.784058 5.50952L2.84426 7.57834L8.01102 2.38997L5.95083 0.321151Z"
      fill="currentColor"
    />
    <path
      d="M13.1558 3.42198L7.98901 8.61035L10.0492 10.6792L15.216 5.4908L13.1558 3.42198Z"
      fill="currentColor"
    />
  </svg>
)

export default PaloLogo
