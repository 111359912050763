import { RowSelectionState } from '@tanstack/react-table'
import { useState, useMemo } from 'react'

export interface UseRowSelectionArgs<TRow> {
  /** The array of data also passed to `useReactTable` */
  data: TRow[]
  /** The name of the key of the row object which will be used as the unique identifier; the value at this key should match the value returned by the `useReactTable` `getRowId` function */
  rowIdKey: keyof TRow
}

export interface UseRowSelectionReturn<TRow> {
  /** The current state of the row selection, should be passed to `useReactTable` `state.rowSelection` */
  rowSelection: RowSelectionState
  /** The function to update the row selection, should be passed to `useReactTable` `onRowSelectionChange` */
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  /** The currently selected rows */
  selectedRows: TRow[]
}

/**
 * Hook to help manage TanStack table row selection state
 * @param root0
 * @param root0.data
 * @param root0.rowIdKey
 */
export function useRowSelection<TRow>({
  data,
  rowIdKey,
}: UseRowSelectionArgs<TRow>): UseRowSelectionReturn<TRow> {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const selectedRows = useMemo(() => {
    const selectedIds = Object.keys(rowSelection)
    return data.filter((row) =>
      selectedIds.includes(String(row[rowIdKey as keyof TRow])),
    )
  }, [data, rowIdKey, rowSelection])

  return {
    rowSelection,
    setRowSelection,
    selectedRows,
  }
}
