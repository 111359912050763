import { gql } from '@apollo/client'

import { FilterOptions, FilterOptionValues } from '@models/Filters'
import {
  TICKET_DETAILS_FRAGMENT,
  TICKET_STATUS_FRAGMENT,
  TICKET_CONTACT_FRAGMENT,
  TICKET_ACTIVITY_FRAGMENT,
  TICKET_RESOLUTION_FRAGMENT,
  TICKET_RELATED_CASES_FRAGMENT,
  TICKET_ADDITIONAL_DETAILS_FRAGMENT,
  TICKET_MTTX_FRAGMENT,
  TICKET_HEADER_FRAGMENT,
} from '@fragments/ticket'
import {
  Ticket,
  TicketData,
  Pagination,
  SortingOptions,
  PaginationInput,
  TicketFilterInput,
  TicketSortingInput,
} from '@models/Tickets'

// Basic ticket fields needed for table view
type TableViewTicket = Pick<
  Ticket,
  | 'sysId'
  | 'ticketNumber'
  | 'shortDescription'
  | 'priority'
  | 'state'
  | 'type'
  | 'module'
  | 'contactUser'
  | 'sysCreatedOn'
  | 'sysUpdatedOn'
>

// Structure matching the original TicketData but with minimal ticket fields
export interface GetTicketsTableViewData {
  getTickets: {
    tickets: TableViewTicket[]
    pagination: Pagination
    filterOptions: FilterOptions<FilterOptionValues[]>
    sortingOptions: SortingOptions[]
  }
}

// The additional fields query returns the same structure but with additional ticket data
export interface GetTicketsAdditionalFieldsData {
  getTicketsDetails: TicketData // Reuse existing TicketData type
}

// Reuse existing variables interface for both queries
export interface GetTicketsVariables {
  pagination: PaginationInput
  selectedCustomer: string | null
  selectedFilters: {
    filters: TicketFilterInput
    keywordSearch: string
    showOpenTicketOnly: boolean
  }
  selectedSortings: TicketSortingInput
}

// Fragment for the fields needed to render the table
export const TICKET_TABLE_VIEW_FIELDS = gql`
  fragment TicketTableViewFields on Ticket {
    sysId
    ticketNumber
    shortDescription
    priority
    state
    type
    module
    contactUser
    sysCreatedOn
    sysUpdatedOn
  }
`

/**
 * Fragment for the fields needed to render the sidesheet
 * Dependent on:
 * - TICKET_ADDITIONAL_DETAILS_FRAGMENT
 * - TICKET_RELATED_CASES_FRAGMENT
 * - TICKET_MTTX_FRAGMENT
 */
export const TICKET_DETAILS_VIEW_FIELDS = gql`
  fragment TicketDetailsViewFields on Ticket {
    description
    assignedUser
    assignedUserEmail
    impact
    customerValidateState
    lastUpdateUser
    assignedToDeepwatch
    serviceOffering
    assignmentGroup
    category
    subcategory
    channel
    caseType
    agentType
    comments {
      text
      time
      type
      user
      internalUser
    }
    openedBy
    createdDate
    resolutionCode
    resolutionNotes
    useCase
    incidentType
    cisControl
    servicenowLink
    ...TicketAdditionalDetailsFields
    ...TicketRelatedCasesFields
    ...TicketMttxFields
  }
`

// Fast query for table view
export const GET_TICKETS_TABLE_VIEW = gql`
  ${TICKET_TABLE_VIEW_FIELDS}
  query GetTicketsTableView(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        ...TicketTableViewFields
      }
      sortingOptions {
        key
        order
      }
      filterOptions {
        filters {
          label
          key
          values {
            label
            value
            selected
          }
        }
        keywordSearch
        showOpenTicketOnly
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

// Additional fields query that runs in parallel
export const GET_TICKETS_ADDITIONAL_FIELDS = gql`
  ${TICKET_ADDITIONAL_DETAILS_FRAGMENT}
  ${TICKET_RELATED_CASES_FRAGMENT}
  ${TICKET_MTTX_FRAGMENT}
  ${TICKET_DETAILS_VIEW_FIELDS}
  query GetTicketsAdditionalFields(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    # The getTicketsDetails query is identical to getTickets on the backend, but given a different name to avoid
    # cache normalization issues in Apollo.
    getTicketsDetails(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        sysId # Include ID for merging
        ...TicketDetailsViewFields
      }
    }
  }
`

/** Fragment to get the merged properties from both tickets queries; this version is used when featureEditTicketPage is false */
export const FULL_TICKET_FRAGMENT = gql`
  fragment FullTicketFields on Ticket {
    sysId
    priority
    type
    createdDate
    sysCreatedOn
    sysUpdatedOn
    ticketNumber
    lastUpdateUser
    shortDescription
    openedBy
    assignedUser
    assignmentGroup
    module
    serviceOffering
    customerValidateState
    category
    subcategory
    description
    useCaseTitle
    useCase
    incidentType
    cisControl
    mitreTactics
    mitreTechniques
    splunkLink
    splunkSearch
    servicenowLink
    comments {
      time
      type
      user
      text
      internalUser
    }
    state
  }
`

/**
 * Fragment to get the merged properties from both tickets queries
 * This version is used when featureEditTicketPage is true
 */
export const FULL_TICKET_FRAGMENT_WITH_CACHE_FIELDS = gql`
  ${TICKET_DETAILS_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_CONTACT_FRAGMENT}
  ${TICKET_ACTIVITY_FRAGMENT}
  ${TICKET_RESOLUTION_FRAGMENT}
  ${TICKET_HEADER_FRAGMENT}
  fragment FullTicketFieldsWithCache on Ticket {
    ...TicketDetailsFields
    ...TicketStatusFields
    ...TicketContactFields
    ...TicketActivityFields
    ...TicketResolutionFields
    ...TicketHeaderFields
    sysId
    type
    createdDate
    sysCreatedOn
    sysUpdatedOn
    lastUpdateUser
    openedBy
    useCaseTitle
    useCase
    incidentType
    cisControl
    mitreTactics
    mitreTechniques
    splunkLink
    splunkSearch
    servicenowLink
    comments {
      time
      type
      user
      text
      internalUser
    }
    relatedCases {
      ticketNumber
      description
      priority
      state
      sysCreatedOn
      type
      sysUpdatedOn
    }
  }
`
