/* eslint-disable security/detect-object-injection */
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import { SingleValue } from 'react-select'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'

import { CollapsiblePanel } from '@common/CollapsiblePanel'
import { MttxDimension, MttxMetric } from '@models/index'
import { Context } from '@components/App'
import { OptionType } from '@common/Dropdown/Dropdown'
import {
  MTTX_FILTER_OPTIONS,
  MttxFilterOptionsData,
  MttxFilterOptionsVariables,
} from '@queries/mttx'

import MttxFilter from './MttxFilter/MttxFilter'
import MTTXDetailsContent from './MTTXDetails/MttxDetails'
import MTTXDataVisualization from './MTTXDataVisualization/MTTXDataVisualization'
import MTTXTable from './MTTXTable/MTTXTable'

const MTTNContent = (): JSX.Element => {
  const metric = MttxMetric.MTTN
  const [selectedPriority, setSelectedPriority] = useState<string>('All')

  const [selectedDimension, setSelectedDimension] = useState<
    SingleValue<OptionType>
  >({
    label: 'Created date',
    value: MttxDimension.CREATED_DATE,
  })

  const [selectedSegment, setSelectedSegment] =
    useState<SingleValue<OptionType>>()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''

  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const formattedStartDate = format(startDate, 'yyyy-MM-dd')
  const formattedEndDate = format(endDate, 'yyyy-MM-dd')

  const {
    data: { mttxFilterOptions: { dimensions, segments, ticketPriorities } } = {
      mttxFilterOptions: {
        dimensions: [],
        segments: [],
        ticketPriorities: undefined,
        ticketTypes: undefined,
      },
    },
    loading,
    error,
  } = useQuery<MttxFilterOptionsData, MttxFilterOptionsVariables>(
    MTTX_FILTER_OPTIONS,
    {
      variables: {
        selectedCustomer,
        input: {
          metric,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  return (
    <Box
      id="mttn-side-sheet"
      data-testid="mttn-side-sheet"
      sx={{ display: 'flex', flexFlow: 'column nowrap' }}
    >
      <MttxFilter
        loading={loading}
        title="Your mean time to notify"
        description="The data below is only applicable to your security tickets"
        selectedValue={selectedPriority}
        setSelectedValue={setSelectedPriority}
        ticketPriorities={ticketPriorities}
        metric={metric}
        error={error}
      />

      <CollapsiblePanel title={'Details'}>
        <MTTXDetailsContent
          metric={metric}
          selectedCustomer={selectedCustomer}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          ticketPriority={selectedPriority}
        />
      </CollapsiblePanel>

      <CollapsiblePanel title={'Trend'}>
        <MTTXDataVisualization
          availableDimensions={dimensions}
          availableSegments={segments}
          selectedDimension={selectedDimension}
          selectedSegment={selectedSegment}
          setSelectedDimension={setSelectedDimension}
          setSelectedSegment={setSelectedSegment}
          priority={selectedPriority}
          selectedCustomer={selectedCustomer}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          metric={metric}
        />

        <MTTXTable
          metric={metric}
          selectedCustomer={selectedCustomer}
          selectedDimension={selectedDimension!.value}
          selectedPriority={selectedPriority}
          selectedSegment={selectedSegment ? selectedSegment.value : undefined}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
        />
      </CollapsiblePanel>

      <Box data-testid="mttn-side-sheet-bottom-content">
        <CollapsiblePanel title="What is MTTN?">
          <Typography variant="body2">
            The <strong>Mean Time to Notify</strong> a customer of an actionable
            security alert. An actionable alert is reported to the customer
            using the case management system. These are actionable alerts that
            need to be reviewed by the customer to determine if it is a true
            threat, false threat, inconclusive or a declared incident as it
            applies to the customer&apos;s policies.
          </Typography>
        </CollapsiblePanel>
        <CollapsiblePanel title="Calculating MTTN">
          <Typography
            fontFamily="Roboto Mono"
            fontWeight={500}
            color="warning"
            variant="caption"
          >
            Case Created Time (ServiceNow) - Alert Time (SOAR)
          </Typography>
        </CollapsiblePanel>
      </Box>

      <Typography sx={{ padding: '1rem' }} variant="caption">
        Using a non-deepwatch SNOW instance has a chance of impacting the
        accuracy of the reporting.
      </Typography>
    </Box>
  )
}

export default MTTNContent
