import { useRef } from 'react'

/**
 * Custom hook which will store and return the most recently supplied non-nullish value.
 *
 * Useful if you want to keep track of the previous value of a state variable which has been set to null or undefined.
 *
 * For example:
 *
 * ```tsx
 * const [count, setCount] = useState<number | null>(null)
 * const mostRecentCount = useMostRecentValue(count)
 *
 * // mostRecentCount is initialized to the first value of count, null
 * console.log(count, mostRecentCount) // null, null
 *
 * // mostRecentCount is updated to the new value of count, 1
 * setCount(1)
 * console.log(count, mostRecentCount) // 1, 1
 *
 * // mostRecentCount remains the same as the previous value of count when called with a nullish value
 * setCount(null) // or setCount(undefined)
 * console.log(count, mostRecentCount) // null, 1
 *
 * ```
 */

export function useMostRecentValue<T>(value: T) {
  const previousValue = useRef<T>(value)
  previousValue.current = value ?? previousValue.current
  return previousValue.current
}
