import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { Paths } from '@components/App/Types'
import { FormattedSecurityIndexEvent } from '@models/SecurityIndex'
import { navigateUserType } from '@utils/index'
import Icon from '@common/Icon'

const CarouselHeader = ({
  data,
}: {
  data: FormattedSecurityIndexEvent[] | null | undefined
}) => {
  const visibility = useContext(VisibilityContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''
  const isFirstItemVisible = visibility.isItemVisible('first')
  const isLastItemVisible = visibility.isItemVisible('last')
  const { featureNgMdr } = useFlags()

  const securityIndexPath = featureNgMdr
    ? Paths.MDR_SECURITY_INDEX
    : Paths.SECURITY_INDEX

  return (
    <Box
      sx={{
        marginBottom: '0.88rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        data-testid="carousel-header-info"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography color="textPrimary" variant="body2">
          {data?.length === 0 ? 'No' : data?.length} changes this period
        </Typography>

        <Button
          variant="text"
          data-testid="see-all"
          onClick={() => {
            navigateUserType(
              selectedCustomer ? true : false,
              `${securityIndexPath}${Paths.CHANGE_HISTORY}`,
              navigate,
              selectedCustomer,
            )
          }}
          sx={{ marginLeft: 1 }}
        >
          see all
        </Button>
      </Box>
      <Box
        data-testid="carousel-arrows"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2, // This equals 16px
        }}
      >
        <IconButton
          data-testid="arrow-back"
          onClick={() => visibility.scrollPrev('smooth', 'nearest')}
          sx={{
            opacity: isFirstItemVisible ? 0.4 : 1,
            cursor: isFirstItemVisible ? 'initial' : 'pointer',
          }}
        >
          <Icon variant="chevronBack" />
        </IconButton>
        <IconButton
          data-testid="arrow-next"
          onClick={() => visibility.scrollNext('smooth', 'nearest')}
          sx={{
            opacity: isLastItemVisible ? 0.4 : 1,
            cursor: isLastItemVisible ? 'initial' : 'pointer',
          }}
        >
          <Icon variant="chevronForward" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CarouselHeader
