import React, {
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react'
import { Button, ButtonProps, Typography, useTheme } from '@mui/material'
import { ApolloError } from '@apollo/client'

import Icon from '@common/Icon'
import { Loader } from '@common/Loader'

enum ProgressButtonState {
  DEFAULT = 'default',
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface ProgressButtonProps {
  defaultText: string
  onClick: MouseEventHandler<HTMLButtonElement>
  loading: boolean
  error: ApolloError | undefined
  success: boolean
  variant?: ButtonProps['variant']
  disabled?: boolean
  progressText?: string
  errorText?: string
  successText?: string
  showErrorState?: boolean
}

const ProgressButton: React.FC<ProgressButtonProps> = ({
  defaultText,
  loading,
  error,
  success,
  disabled = false,
  progressText = 'Saving',
  errorText = 'Error',
  successText = 'Success',
  variant = 'contained',
  showErrorState = false,
  onClick,
}) => {
  const [buttonState, setButtonState] = useState<ProgressButtonState>(
    ProgressButtonState.DEFAULT,
  )
  const theme = useTheme()
  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!loading && !error && success) {
      setButtonState(ProgressButtonState.SUCCESS)

      timeoutId = setTimeout(() => {
        setButtonState(ProgressButtonState.DEFAULT)
      }, 2000)
    } else if (error) {
      if (showErrorState) {
        setButtonState(ProgressButtonState.ERROR)

        timeoutId = setTimeout(() => {
          setButtonState(ProgressButtonState.DEFAULT)
        }, 2000)
      } else {
        setButtonState(ProgressButtonState.DEFAULT)
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [loading, error, success, showErrorState])

  const hasDefaultState = buttonState === ProgressButtonState.DEFAULT

  const renderButtonContent = () => {
    switch (buttonState) {
      case ProgressButtonState.DEFAULT: {
        return <>{defaultText}</>
      }
      case ProgressButtonState.IN_PROGRESS: {
        return (
          <>
            <Typography
              sx={{ color: theme.vars.palette.primary.main }}
              variant="body2"
            >
              {progressText}
            </Typography>
            <Loader size={24} strokeWidth={1} />
          </>
        )
      }
      case ProgressButtonState.ERROR: {
        return (
          <>
            <Typography
              sx={{ color: theme.vars.palette.error.light }}
              variant="body2"
            >
              {errorText}
            </Typography>
            <Icon
              sx={{ color: theme.vars.palette.error.light }}
              variant="closeCircle"
            />
          </>
        )
      }
      case ProgressButtonState.SUCCESS: {
        return (
          <>
            <Typography
              sx={{ color: theme.vars.palette.success.main }}
              variant="body2"
            >
              {successText}
            </Typography>
            <Icon
              sx={{ color: theme.vars.palette.success.main }}
              variant="checkmarkCircle"
            />
          </>
        )
      }
      default:
        throw new Error('Invalid progress button state')
    }
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setButtonState(ProgressButtonState.IN_PROGRESS)
    onClick?.(e)
  }

  return (
    <Button
      onClick={handleClick}
      disabled={disabled || !hasDefaultState}
      sx={{ gap: '0.5rem' }}
      variant={variant}
    >
      {renderButtonContent()}
    </Button>
  )
}

export default ProgressButton
