import { format } from 'date-fns'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import { SingleValue } from 'react-select'
import { Box, Typography } from '@mui/material'

import {
  MTTX_FILTER_OPTIONS,
  MttxFilterOptionsData,
  MttxFilterOptionsVariables,
} from '@queries/mttx'
import { Context } from '@components/App'
import { MttxDimension, MttxMetric } from '@models/index'
import { OptionType } from '@common/Dropdown/Dropdown'
import { CollapsiblePanel } from '@common/CollapsiblePanel'

import MttxFilter from '../MTTXSidesheet/MttxFilter/MttxFilter'
import MTTXDetailsContent from '../MTTXSidesheet/MTTXDetails/MttxDetails'
import MTTXDataVisualization from '../MTTXSidesheet/MTTXDataVisualization/MTTXDataVisualization'
import MTTXTable from '../MTTXSidesheet/MTTXTable/MTTXTable'

const MTTCAckContent = (): JSX.Element => {
  const metric = MttxMetric.MTTCAck
  const [selectedPriority, setSelectedPriority] = useState<string>('All')
  const [selectedTicketType, setSelectedTicketType] = useState<string>('All')
  const [selectedDimension, setSelectedDimension] = useState<
    SingleValue<OptionType>
  >({
    label: 'Created date',
    value: MttxDimension.CREATED_DATE,
  })

  const [selectedSegment, setSelectedSegment] =
    useState<SingleValue<OptionType>>()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''

  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const formattedStartDate = format(startDate, 'yyyy-MM-dd')
  const formattedEndDate = format(endDate, 'yyyy-MM-dd')

  const {
    data: {
      mttxFilterOptions: {
        dimensions,
        segments,
        ticketPriorities,
        ticketTypes,
      },
    } = {
      mttxFilterOptions: {
        dimensions: [],
        segments: [],
        ticketPriorities: undefined,
        ticketTypes: undefined,
      },
    },
    loading,
  } = useQuery<MttxFilterOptionsData, MttxFilterOptionsVariables>(
    MTTX_FILTER_OPTIONS,
    {
      variables: {
        selectedCustomer,
        input: {
          metric: MttxMetric.MTTCAck,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      },
    },
  )

  return (
    <Box
      id="mttcack-side-sheet"
      data-testid="mttcack-side-sheet"
      sx={{ display: 'flex', flexFlow: 'column nowrap' }}
    >
      <MttxFilter
        metric={MttxMetric.MTTCAck}
        loading={loading}
        title="Mean Time for Customer Acknowledgement"
        selectedValue={selectedPriority}
        selectedTicketTypeValue={selectedTicketType}
        setSelectedTicketTypeValue={setSelectedTicketType}
        setSelectedValue={setSelectedPriority}
        ticketPriorities={ticketPriorities}
        ticketType={ticketTypes}
      />

      <CollapsiblePanel title={'Details'}>
        <MTTXDetailsContent
          metric={MttxMetric.MTTCAck}
          selectedCustomer={selectedCustomer}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          ticketPriority={selectedPriority}
          ticketType={selectedTicketType}
        />
      </CollapsiblePanel>

      <CollapsiblePanel title={'Trend'}>
        <MTTXDataVisualization
          availableDimensions={dimensions}
          availableSegments={segments}
          selectedDimension={selectedDimension}
          selectedSegment={selectedSegment}
          setSelectedDimension={setSelectedDimension}
          setSelectedSegment={setSelectedSegment}
          priority={selectedPriority}
          selectedCustomer={selectedCustomer}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          metric={metric}
          ticketType={selectedTicketType}
        />

        <MTTXTable
          metric={metric}
          selectedCustomer={selectedCustomer}
          selectedDimension={selectedDimension!.value}
          selectedPriority={selectedPriority}
          selectedSegment={selectedSegment ? selectedSegment.value : undefined}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          selectedTicketType={selectedTicketType}
        />
      </CollapsiblePanel>

      <Box data-testid="mttcack-side-sheet-bottom-content">
        <CollapsiblePanel title="What is MTTCAck?">
          <Typography variant="body2">
            The <strong>Mean Time for a customer to acknowledge</strong> a case
            via the case management system. The Customer Acknowledgement Time is
            captured the first time a customer updates (field update or comment
            added) a case.
          </Typography>
        </CollapsiblePanel>
        <CollapsiblePanel title="How is MTTCAck Calculated?">
          <Typography
            color="warning"
            fontFamily="Roboto Mono"
            fontWeight={500}
            variant="caption"
          >
            Customer Ack. time (ServiceNow) - Case Created time (ServiceNow)
          </Typography>
        </CollapsiblePanel>
      </Box>

      <Typography sx={{ padding: '1rem' }} variant="caption">
        Using a non-deepwatch SNOW instance has a chance of impacting the
        accuracy of the reporting.
      </Typography>
    </Box>
  )
}

export default MTTCAckContent
