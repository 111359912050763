import React from 'react'

import { ComponentErrorType, ErrorCard } from '@common/ErrorCard'

import MitreHeader from './MitreHeader'

export const MitreCoverageFallback: React.FC = () => (
  <>
    <MitreHeader isTableVisible={true} setIsTableVisible={() => {}} />
    <ErrorCard errorType={ComponentErrorType.GENERIC} />
  </>
)
