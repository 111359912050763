import { BarDatum, BarLayer, ResponsiveBar } from '@nivo/bar'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { Box, Card, Typography, useColorScheme, useTheme } from '@mui/material'

import { themeConfig } from '@common/nivo/utils'
import { TOP_ASSETS_BY_ALERTS } from '@queries/medr'
import { ChartLegend } from '@common/ChartLegend'
import { CrowdStrikeTicketPriorityEnum } from '@models/Tickets'
import { Loader } from '@common/Loader'

import TopAssetsByAlertsTooltip from './TopAssetsByAlertsTooltip'

const TopAssetsByAlerts = () => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    data: { edrChart: { dataPoints } } = { edrChart: { dataPoints: [] } },
    error,
    loading,
  } = useQuery(TOP_ASSETS_BY_ALERTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer,
    },
  })

  const keys = [
    'informationalCount',
    'lowCount',
    'mediumCount',
    'highCount',
    'criticalCount',
  ]

  const keyColors = [
    theme.palette.severity.info!,
    theme.palette.severity.low!,
    theme.palette.severity.medium!,
    theme.palette.severity.high!,
    theme.palette.severity.critical!,
  ]

  const legendItems = [
    {
      label: CrowdStrikeTicketPriorityEnum.Critical,
      color: theme.palette.severity.critical,
    },
    {
      label: CrowdStrikeTicketPriorityEnum.High,
      color: theme.palette.severity.high,
    },
    {
      label: CrowdStrikeTicketPriorityEnum.Medium,
      color: theme.palette.severity.medium,
    },
    {
      label: CrowdStrikeTicketPriorityEnum.Low,
      color: theme.palette.severity.low,
    },
    {
      label: CrowdStrikeTicketPriorityEnum.Informational,
      color: theme.palette.severity.info,
    },
  ]

  const EmptyStateLayer = () => (
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontFamily: 'Inter',
        fontSize: '18px',
        fill: theme.vars.palette.text.primary,
      }}
    >
      No data for the given timeframe
    </text>
  )

  const ErrorStateLayer = () => (
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontFamily: 'Inter',
        fontSize: '18px',
        fill: theme.vars.palette.text.primary,
      }}
    >
      Not enough data points. Please try again later.
    </text>
  )

  const LoadingLayer = () => (
    <foreignObject
      id="loading-layer"
      data-testid="loading-layer"
      x="50%"
      y="50%"
      width="100%"
      height="100%"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader strokeWidth={1} size={40} />
    </foreignObject>
  )

  const getCustomLayers = (): Array<BarLayer<BarDatum>> => {
    if (loading && dataPoints && dataPoints.length === 0) {
      return [LoadingLayer]
    } else if (error) {
      return ['axes', ErrorStateLayer]
    } else if (dataPoints && dataPoints.length === 0) {
      return ['axes', EmptyStateLayer]
    }

    return ['axes', 'grid', 'bars']
  }

  return (
    <Box data-testid="top-assets-chart-container">
      <Typography
        fontWeight={600}
        variant="body1"
        sx={{ paddingTop: 1.5, paddingBottom: 1 }}
      >
        Top 10 assets by alert severity
      </Typography>
      <Card
        sx={{
          backgroundColor: theme.palette.secondary.light,
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          height: '340px',
          width: '100%',
          padding: '1rem',
          boxShadow: 'none',
        }}
      >
        <ChartLegend legendItems={legendItems} />
        <ResponsiveBar
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          borderColor={theme.palette.secondary.light}
          colors={keyColors}
          enableLabel={false}
          data={dataPoints}
          keys={keys}
          margin={{
            top: 24,
            bottom: 48,
            left: 42,
          }}
          padding={0.6}
          groupMode="stacked"
          indexBy="hostname"
          theme={themeConfig(mode, theme)}
          tooltip={TopAssetsByAlertsTooltip}
          valueScale={{
            type: 'linear',
            max: dataPoints.length === 0 ? 50 : 'auto',
            min: 0,
          }}
          layers={getCustomLayers()}
        />
      </Card>
    </Box>
  )
}

export default TopAssetsByAlerts
