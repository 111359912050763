import { AccessToken, Tokens } from '@okta/okta-auth-js'

import { handleOktaLogout } from '@hooks/useSignOut'
import { deleteCookie, getCookie, setCookie } from '@utils/Cookies'

export enum OktaGroups {
  EMPLOYEE_GROUP = 'dw-app-portal-users',
  ADMINS_GROUP = '-portal-admins',
}

export const parseGroups = (tokens: Tokens): string[] => {
  if (tokens.idToken) {
    return tokens.idToken.claims.groups as string[]
  }
  return []
}
export const setEmployeeStatus = (groups: string[]): boolean => {
  return groups.some((item) => {
    return (item as string).includes(OktaGroups.EMPLOYEE_GROUP)
  })
}
export const setAdminStatus = (groups: string[]): boolean => {
  return groups.some((group) => {
    return group.includes(OktaGroups.ADMINS_GROUP)
  })
}

export const signUserOut = async (
  accessToken: AccessToken | undefined,
  isSessionExpired: boolean,
): Promise<void> => {
  if (getCookie('iav')) {
    deleteCookie('iav')
  }
  if (isSessionExpired) {
    setCookie('se', '1', new Date(Date.now() + 10000))
  }
  await handleOktaLogout(accessToken)
}
