import { Box } from '@mui/material'

import { Ticket, TicketingRelatedCase } from '@models/index'

import { CommentProps } from './ActivityTab.utils'
import ActivityTabMessage from './ActivityTabMessage/ActivityTabMessage'

interface TicketDetailsProps {
  focusedTicket: Ticket | TicketingRelatedCase
}

/**
 * @deprecated Use NewActivityTab instead
 * @param param0.focusedTicket
 * @param param0
 * @returns
 */
const ActivityTab = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const mappedComments = (): CommentProps[] => {
    if (focusedTicket?.comments?.length) {
      return focusedTicket?.comments?.map((comment): CommentProps => {
        return {
          ticketNumber: focusedTicket.ticketNumber,
          fullName: comment.user,
          initials:
            `${comment.user.split(' ')[0]?.charAt(0)}` +
            `${
              comment.user.split(' ')[1]?.charAt(0)
                ? comment.user.split(' ')[1]?.charAt(0)
                : ''
            }`,
          time: comment.time,
          type: comment.type,
          text: comment.text,
          emptyState: false,
        }
      })
    } else if (focusedTicket?.openedBy || focusedTicket?.createdDate) {
      return [
        {
          ticketNumber: focusedTicket.ticketNumber,
          fullName: '',
          initials: '',
          time: '',
          type: '',
          text: '',
          createdDate: focusedTicket?.createdDate,
          openedBy: focusedTicket?.openedBy,
          emptyState: false,
        },
      ]
    } else {
      return [
        {
          fullName: '',
          initials: '',
          time: '',
          type: '',
          text: '',
          createdDate: '',
          openedBy: '',
          emptyState: true,
        },
      ]
    }
  }

  return (
    <Box
      data-testid="activity-tab-details"
      id="activity-tab-details"
      sx={{
        flexDirection: 'column',
        textAlign: 'start',
        padding: '1rem 1rem 0',
        overflowY: 'auto',
      }}
    >
      {mappedComments()?.map((comment, index) => {
        return <ActivityTabMessage key={index} comment={comment} />
      })}
    </Box>
  )
}

export default ActivityTab
