import { Alert } from '@mui/material'

import { useTicketEditContext } from '@hooks/index'
import Icon from '@common/Icon'

export const INFO_TEXT =
  'This page is currently read-only. Please contact your administrator for edit access.'

export const PermissionWarning: React.FC = () => {
  const { isEditable } = useTicketEditContext()

  if (isEditable === false) {
    return (
      <Alert
        icon={<Icon variant="informationCircle" />}
        severity="info"
        variant="filled"
      >
        {INFO_TEXT}
      </Alert>
    )
  }

  return null
}
