import { CellContext } from '@tanstack/react-table'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import capitalize from 'lodash/capitalize'
import { Box, IconButton, Typography } from '@mui/material'

import { Ticket, TicketRelatedUnion } from '@models/Tickets'
import { navigateUserType } from '@utils/navigateUserType'
import { Context } from '@components/App'
import Icon from '@common/Icon'
import { zIndex } from '@components/App/Styles/zIndex'

const CustomerValidateStateCell = (
  props: CellContext<TicketRelatedUnion, Ticket['customerValidateState']>,
) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const { featureEditTicketPage } = useFlags()

  const navigate = useNavigate()

  const navigateToTicketEdit = () => {
    navigateUserType(
      isDWEmployee,
      `/tickets/library/${props.row.original.sysId}/edit`,
      navigate,
      customerShortName,
    )
  }

  const customerValidateState = props.getValue()

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Typography
        noWrap
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {customerValidateState ? capitalize(customerValidateState) : 'None'}
      </Typography>

      {featureEditTicketPage && (
        <Box
          id="cell-hover-buttons"
          data-testid="last-updated-cell-hover-buttons"
          sx={{
            display: 'flex',
            flex: '1',
            position: 'absolute',
            inset: '0',
            alignItems: 'center',
            opacity: '0',
            zIndex: zIndex.CELL_HOVER_BUTTONS,
          }}
        >
          <Box
            sx={(theme) => ({
              background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.lighter} 90%)`,
              width: '100%',
              height: '100%',
              ...theme.applyStyles('dark', {
                background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.light} 90%)`,
              }),
            })}
          />
          <Box
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.secondary.lighter,
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.light,
              }),
              display: 'flex',
            })}
          >
            <IconButton
              onKeyDown={(keyboardEvent) => {
                keyboardEvent.stopPropagation()
              }}
              onClick={(event) => {
                event.stopPropagation()
                navigateToTicketEdit()
              }}
            >
              <Icon variant="arrowForwardCircleOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CustomerValidateStateCell
