import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { oktaAuthConfig } from '@config/OktaAuthConfig'
import { setCookie } from '@utils/index'

export interface SessionTimeoutModalProps {
  secondsLeft: number
  resetIdleTimer: () => boolean
  closeModal: VoidFunction
}

const SessionTimeoutModal = ({
  closeModal,
  resetIdleTimer,
  secondsLeft,
}: SessionTimeoutModalProps) => {
  const [timeLeft, setTimeLeft] = useState(secondsLeft)

  useEffect(() => {
    if (timeLeft === 0) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const signOut = (sessionExpired: boolean) => {
    setCookie('se', sessionExpired ? '1' : '0')
    oktaAuthConfig.signOut()
  }

  const minutes = Math.trunc(timeLeft / 60)
  const seconds = timeLeft % 60
  let formatText = ''
  if (minutes > 0) {
    formatText = `${minutes} Minutes ${seconds} Seconds`
  } else {
    formatText = `${seconds} Seconds`
  }

  return (
    <Box
      id="session-timeout-modal"
      sx={{ padding: '1rem' }}
      data-testid="session-timeout-modal"
    >
      <Typography color="textPrimary" variant="body1">
        Your session will expire in
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="h4"
      >
        {formatText}
      </Typography>
      <Typography color="textPrimary" variant="body2">
        You will automatically be logged out after session time expires
      </Typography>
      <Box
        component="span"
        key={'B'}
        sx={{
          display: 'flex',
          marginTop: 25,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: '1rem',
        }}
        data-testid="session-timeout-modal-buttons"
      >
        <Button
          data-testid="session-timeout-log-out-btn"
          variant="outlined"
          onClick={() => signOut(false)}
        >
          Log out
        </Button>
        <Button
          variant="contained"
          data-testid="session-timeout-continue-btn"
          onClick={() => {
            closeModal()
            // reset tokens
            oktaAuthConfig.session.exists().then((data) => {
              if (data) {
                oktaAuthConfig.token.getWithoutPrompt().then((data) => {
                  oktaAuthConfig.tokenManager.setTokens(data.tokens)
                  resetIdleTimer()
                })
              } else {
                signOut(true)
              }
            })
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default SessionTimeoutModal
