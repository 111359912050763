import { OktaUser } from '@models/index'
import { SessionStatus } from '@config/OktaAuthConfig'

import { AppAction, ActionTypes } from '../Types'

export const setSession = (status: SessionStatus): AppAction => ({
  type: ActionTypes.SET_SESSION,
  status,
})

export const setUser = (user: OktaUser): AppAction => ({
  type: ActionTypes.SET_USER,
  user,
})
