import { Chip } from '@mui/material'

import { getFormattedDate } from '@utils/DateTimeUtils'
import {
  MFWModule,
  MDRModule,
  MEDRModule,
  Module,
  ModuleStatus,
  ModuleType,
  ModuleUnion,
  VMModule,
} from '@queries/modules'
import { formatNumberWithCommas } from '@utils/Common'
import { IconVariant } from '@common/Icon/Icons'

import ModulePrimaryEngineerField from './ModulePrimaryEngineerField'
import ModuleTechnologyField from './ModuleTechnologyField'

const isModuleOfType = <T extends Module>(
  module: Module,
  type: ModuleType,
): module is T => {
  return module.type === type
}

const formatServiceRenewalDate = (renewalDate: string | null): string =>
  renewalDate ? getFormattedDate(renewalDate, 'MMM dd, yyyy') : '--'

const formatEnabledStatus = (isEnabled: boolean): string =>
  isEnabled ? 'Enabled' : 'Disabled'

export const formatNullableField = (
  fieldValue: string | JSX.Element | null,
): string | JSX.Element => {
  if (
    !fieldValue ||
    (typeof fieldValue === 'string' &&
      (fieldValue.trim() === '' || fieldValue.toLowerCase().trim() === 'n/a'))
  ) {
    return '--'
  }
  return fieldValue
}

export const formatActiveModuleProperties = (
  module: ModuleUnion,
  featureDwLicense = false,
): {
  title: string
  value: string | JSX.Element | null
}[] => {
  if (isModuleOfType<MDRModule>(module, ModuleType.MDR)) {
    return [
      {
        title: 'Service renewal date',
        value: formatServiceRenewalDate(module.serviceRenewalDate),
      },
      {
        title: 'Deployment type',
        value: module.deploymentType,
      },
      {
        title: 'SIEM',
        value: (
          <ModuleTechnologyField
            technology={module.technology}
            technologyUrl={module.technologyUrl}
          />
        ),
      },
      {
        title: featureDwLicense ? 'Deepwatch license' : 'SIEM license volume',
        value: module.SIEMLicenseVolume
          ? `${formatNumberWithCommas(Number(module.SIEMLicenseVolume))} GB`
          : '',
      },
    ]
  } else if (isModuleOfType<MEDRModule>(module, ModuleType.MEDR)) {
    return [
      {
        title: 'Service renewal date',
        value: formatServiceRenewalDate(module.serviceRenewalDate),
      },
      {
        title: 'Technology',
        value: (
          <ModuleTechnologyField
            technology={module.technology}
            technologyUrl={module.technologyUrl}
          />
        ),
      },
      {
        title: 'Endpoints',
        value: formatNumberWithCommas(Number(module.endpoints)),
      },
      {
        title: 'Primary engineer',
        value: (
          <ModulePrimaryEngineerField
            primaryEngineerName={module.primaryEngineerName}
          />
        ),
      },
    ]
  } else if (isModuleOfType<MFWModule>(module, ModuleType.MFW)) {
    return [
      {
        title: 'Service renewal date',
        value: formatServiceRenewalDate(module.serviceRenewalDate),
      },
      {
        title: 'Technology',
        value: (
          <ModuleTechnologyField
            technology={module.technology}
            technologyUrl={module.technologyUrl}
          />
        ),
      },
      {
        title: 'Primary engineer',
        value: (
          <ModulePrimaryEngineerField
            primaryEngineerName={module.primaryEngineerName}
          />
        ),
      },
      {
        title: 'Policy Gov.',
        value: formatEnabledStatus(module.isPolicyGovEnabled),
      },
      {
        title: 'Perf. Monitoring',
        value: formatEnabledStatus(module.isPerfMonitoringEnabled),
      },
    ]
  } else if (isModuleOfType<VMModule>(module, ModuleType.VM)) {
    return [
      {
        title: 'Service renewal date',
        value: formatServiceRenewalDate(module.serviceRenewalDate),
      },
      {
        title: 'Technology',
        value: (
          <ModuleTechnologyField
            technology={module.technology}
            technologyUrl={module.technologyUrl}
          />
        ),
      },
      {
        title: 'Primary engineer',
        value: (
          <ModulePrimaryEngineerField
            primaryEngineerName={module.primaryEngineerName}
          />
        ),
      },
      {
        title: 'VM Volume',
        value: module.volume
          ? `${formatNumberWithCommas(Number(module.volume))} (IPs)`
          : null,
      },
      {
        title: 'Service Tier',
        value: module.serviceTier,
      },
    ]
  } else {
    return [
      {
        title: 'Service renewal date',
        value: formatServiceRenewalDate(module.serviceRenewalDate),
      },
    ]
  }
}

export const getModuleIcon = ({
  type,
}: Pick<Module, 'type' | 'status'>): IconVariant | undefined => {
  switch (type) {
    case ModuleType.MDR:
      return 'mdrLogo'
    case ModuleType.MEDR:
      return 'edrLogo'
    case ModuleType.MFW:
      return 'fwLogo'
    case ModuleType.TS:
      return 'threatSignalLogo'
    case ModuleType.VM:
      return 'mvmLogo'
    default:
      return undefined
  }
}

export const getModuleTag = ({
  status,
}: Pick<Module, 'status'>): React.ReactNode => {
  if (status === ModuleStatus.AVAILABLE) {
    return null
  }

  return (
    <Chip
      data-testid="chip-module-status"
      variant="outlined"
      label={status}
      sx={(theme) => {
        const darkTextColorOptions = {
          [ModuleStatus.ACTIVE]: theme.vars.palette.success.main,
          [ModuleStatus.ONBOARDING]: theme.vars.palette.warning.main,
        }

        const lightTextColorOptions = {
          [ModuleStatus.ACTIVE]: theme.vars.palette.success.light,
          [ModuleStatus.ONBOARDING]: theme.vars.palette.warning.light,
        }

        return {
          '&.MuiChip-outlined': {
            color:
              lightTextColorOptions[status] ?? theme.vars.palette.text.primary,
            borderColor:
              lightTextColorOptions[status] ?? theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color:
                darkTextColorOptions[status] ?? theme.vars.palette.text.primary,
              borderColor:
                darkTextColorOptions[status] ?? theme.vars.palette.text.primary,
            }),
          },
        }
      }}
    />
  )
}
