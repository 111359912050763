const NoResults = ({ fill }) => (
  <svg
    viewBox="0 0 251 148"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="no-results-found-icon"
  >
    <path
      d="M184.5 107.625C214.263 90.4414 214.263 62.5586 184.5 45.375C154.737 28.1914 106.443 28.1914 76.6798 45.375C46.9169 62.5586 46.9169 90.4414 76.6798 107.625C106.443 124.809 154.737 124.809 184.5 107.625Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.5 93.375C214.263 76.1914 214.263 48.3086 184.5 31.125C154.737 13.9414 106.443 13.9414 76.6798 31.125C46.9169 48.3086 46.9169 76.1914 76.6798 93.375C106.443 110.559 154.737 110.559 184.5 93.375Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M172.628 86.5209C195.837 73.1213 195.837 51.3787 172.628 37.9791C149.42 24.5796 111.76 24.5796 88.5514 37.9791C65.3427 51.3787 65.3427 73.1213 88.5514 86.5209C111.76 99.9204 149.42 99.9204 172.628 86.5209Z"
      fill="currentColor"
    />
    <path d="M206.77 76L206.77 61" stroke="currentColor" strokeWidth="3" />
    <path d="M54.2695 76L54.2695 61" stroke="currentColor" strokeWidth="3" />
    <path
      d="M70.8657 105L84.7221 98.0001L84.7221 113L29.7021 144.766C27.201 146.21 23.1072 146.134 20.7722 144.6L15.2943 141.001C14.312 140.356 13.7703 139.526 13.7703 138.667L13.7703 125L70.8657 105Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.43"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.8652 91L84.7216 99L29.4134 130.932C27.0219 132.313 23.1446 132.313 20.7532 130.932L15.557 127.932C13.1655 126.552 13.1655 124.313 15.557 122.932L70.8652 91Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default NoResults
