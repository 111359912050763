import { Card, CardContent, Theme } from '@mui/material'

import { ModuleUnion } from '@queries/modules'

import ModuleCardHeader from './ModuleCardHeader'
import ModuleCardBody from './ModuleCardBody'

type ModuleCardProps = {
  module: ModuleUnion
}

const moduleCardCss = {
  container: (theme: Theme) => ({
    border: `1px solid ${theme.vars.palette.secondary.main} !important`,
    borderRadius: '5px',
    ...theme.applyStyles('dark', {
      border: `1px solid ${theme.vars.palette.secondary.lighter} !important`,
    }),
  }),
}

const ModuleCard: React.FC<ModuleCardProps> = ({ module }) => {
  return (
    <Card
      sx={moduleCardCss.container}
      variant="outlined"
      data-testid="module-card"
    >
      <ModuleCardHeader module={module} />
      <CardContent
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
          }),
        })}
      >
        <ModuleCardBody module={module} />
      </CardContent>
    </Card>
  )
}

export default ModuleCard
