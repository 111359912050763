import { useLocation } from 'react-router-dom'

/**
 * @description List of exact pathnames that should show the date picker
 */
const PATHNAME_EXACT_WHITELIST: ReadonlyArray<string> = [
  '/dashboard/overview',
  '/mdr/overview',
  '/environment',
]

/**
 * @description List of pathnames for which all sub-paths should show the date picker
 */
const PATHNAME_STARTS_WITH_WHITELIST: ReadonlyArray<string> = [
  '/dashboard/security-index/',
  '/mdr/security-index/',
] as const

/**
 * @description Hook to determine if the date picker should be shown
 * @returns {boolean} shouldShowDatePicker
 */
export const useShouldShowDatePicker = (): boolean => {
  const { pathname } = useLocation()

  return (
    PATHNAME_EXACT_WHITELIST.includes(pathname) ||
    PATHNAME_STARTS_WITH_WHITELIST.some((pattern) =>
      pathname.startsWith(pattern),
    )
  )
}
