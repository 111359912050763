import { format } from 'date-fns'
import { Avatar, Box, Typography } from '@mui/material'

import { useAvatarInitials } from '@hooks/useAvatarInitials'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'

import { mapFieldToLabel } from './TicketActivity'
import {
  FieldValues,
  TicketActivityType,
  TicketActivityTypeEnum,
} from '../../Types'
import {
  TICKET_ACTIVITY_FOOTER_TEXT,
  TICKET_COMMENT_TIME_FORMAT,
} from './TicketActivity.utils'

interface Props {
  activity: TicketActivityType
  shouldShowVerticalLine?: boolean
}

const displayFieldValues = ({ previousValue, value }: FieldValues) => {
  if (previousValue && previousValue !== value) {
    return (
      <Box sx={{ display: 'flex', alignItems: '0.5rem', gap: '0.5rem' }}>
        <Typography color="textSecondary" variant="body2">
          {previousValue}
          <Icon variant="arrowForwardSharp" size={21} />
          {value}
        </Typography>
      </Box>
    )
  }

  return (
    <Typography color="textSecondary" variant="body2">
      {value}
    </Typography>
  )
}

const TicketActivityItem: React.FC<Props> = ({
  activity,
  shouldShowVerticalLine,
}) => {
  const { createdDate, userInfo, type } = activity

  const avatarInitials = useAvatarInitials({
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
  })

  const displayActivity = () => {
    switch (type) {
      case TicketActivityTypeEnum.Placeholder: {
        return (
          <Typography color="textPrimary" variant="body2">
            {activity.text}
          </Typography>
        )
      }
      case TicketActivityTypeEnum.FieldChanges: {
        return activity.fieldChanges.map((fieldChange) =>
          Object.entries(fieldChange).map(
            ([key, { previousValue, value }], index) => (
              <Box key={index}>
                <Typography
                  color="textPrimary"
                  fontWeight={600}
                  variant="body2"
                >
                  {/* eslint-disable-next-line security/detect-object-injection */}
                  {mapFieldToLabel[key]}:
                </Typography>
                {displayFieldValues({ previousValue, value })}
              </Box>
            ),
          ),
        )
      }
      case TicketActivityTypeEnum.Comment: {
        const { status = 'created' } = activity

        return (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.secondary.lighter,
              borderRadius: '0 5px 5px',
              padding: '0.5rem',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
              ...(status === 'error'
                ? {
                    border: `1px solid ${theme.vars.palette.error.main}`,
                  }
                : {}),
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.light,
              }),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {activity.text}
            </Typography>
          </Box>
        )
      }
    }
  }

  const displayFooter = () => {
    const formattedDate = format(
      new Date(createdDate),
      TICKET_COMMENT_TIME_FORMAT,
    )

    if (type === TicketActivityTypeEnum.Comment) {
      switch (activity.status) {
        case 'pending':
          return (
            <Box sx={{ gap: '0.25rem' }}>
              <Loader size={16} strokeWidth={1} />

              <Typography color="textPrimary" variant="caption">
                Posting&hellip;
              </Typography>
            </Box>
          )
        case 'error':
          return (
            <Typography color="red" variant="caption">
              {activity.error?.message ?? 'An error occurred'}
            </Typography>
          )
        default:
          return (
            <Typography color="textPrimary" variant="caption">
              {formattedDate} &bull; {TICKET_ACTIVITY_FOOTER_TEXT[type]}
            </Typography>
          )
      }
    }

    return (
      <Typography color="textPrimary" variant="caption">
        {formattedDate} &bull; {TICKET_ACTIVITY_FOOTER_TEXT[type]}
      </Typography>
    )
  }

  return (
    <Box
      data-testid="ticket-activity-item"
      sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}
    >
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Avatar data-testid="user-avatar" variant={userInfo.type}>
          {userInfo.type === 'system' ? (
            <Icon variant="ticketOutline" size={16} />
          ) : (
            avatarInitials
          )}
        </Avatar>
        <Typography
          fontWeight={600}
          variant="body1"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {`${userInfo.firstName} ${userInfo.lastName}`}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {shouldShowVerticalLine && (
            <Box
              data-testid="ticket-activity-item-vertical-line"
              sx={(theme) => ({
                borderLeft: `1px solid ${theme.vars.palette.secondary.main}`,
                height: '100%',
                marginLeft: '14px',
                marginTop: '1rem',
                paddingRight: '22px',
                ...theme.applyStyles('dark', {
                  borderLeft: `1px solid ${theme.vars.palette.text.disabled}`,
                }),
              })}
            />
          )}
        </Box>

        <Box
          sx={{
            ...(!shouldShowVerticalLine
              ? {
                  paddingLeft: '36px',
                }
              : {}),
          }}
        >
          <Box>{displayActivity()}</Box>
          <Box>{displayFooter()}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketActivityItem
