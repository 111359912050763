import { useState } from 'react'
import { useFragment } from '@apollo/client'
import { Box } from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import ThreatIntelReportsAdvisoryCard from '@components/ThreatIntel/ThreatIntelReports/components/ThreatIntelReportsAdvisoryCard'
import ThreatIntelSideSheet, {
  ThreatIntelSideSheetArrowDirection,
} from '@components/ThreatIntel/ThreatIntelReports/components/ThreatIntelSideSheet/ThreatIntelSideSheet'
import { useThreatIntelReportsContext } from '@components/ThreatIntel/ThreatIntelReports/context'
import { ThreatIntelReport } from '@models/ThreatIntel'
import { THREAT_INTEL_REPORT_DATA_FRAGMENT } from '@fragments/threatIntel'
import { Loader } from '@common/Loader'
import { trackAnalyticEvent } from '@utils/analytics'

export interface ThreatIntelWidgetProps {
  data: ThreatIntelReport[] | null
  loading: boolean
}

const ThreatIntelWidget: React.FC<ThreatIntelWidgetProps> = ({
  data,
  loading,
}) => {
  const {
    activeReportId,
    openSideSheet,
    isSideSheetOpen,
    closeSideSheet,
    setRowSelection,
  } = useThreatIntelReportsContext()

  const [animationDirection, setAnimationDirection] =
    useState<ThreatIntelSideSheetArrowDirection>()

  const { data: activeReport } = useFragment({
    fragment: THREAT_INTEL_REPORT_DATA_FRAGMENT,
    fragmentName: 'ThreatIntelReportData',
    from: {
      __typename: 'ThreatIntelReport',
      id: activeReportId,
    },
  })

  const handleArrowClick = (direction: ThreatIntelSideSheetArrowDirection) => {
    if (!data) {
      return
    }

    let newReport: ThreatIntelReport | undefined
    let newAnimationDirection: ThreatIntelSideSheetArrowDirection | undefined
    const currentReportIndex = data.findIndex(({ id }) => id === activeReportId)
    const dataLastIndex = data.length - 1

    /**
     * The up arrow goes back in the array while the down arrow goes forward in the array.
     * This is so it looks like the user is navigating down in the list
     */
    switch (direction) {
      case ThreatIntelSideSheetArrowDirection.UP:
        /**
         * If going backwards in the array go to the end of the array once reaching the start
         */
        newReport =
          currentReportIndex === 0
            ? (newReport = data.at(-1))
            : (newReport = data.at(currentReportIndex - 1))
        newAnimationDirection =
          currentReportIndex === 0
            ? ThreatIntelSideSheetArrowDirection.UP
            : ThreatIntelSideSheetArrowDirection.DOWN
        break
      case ThreatIntelSideSheetArrowDirection.DOWN:
        /**
         * If going forwards in the array go to the start of the array once reaching the end
         */
        newReport =
          currentReportIndex === dataLastIndex
            ? data.at(0)
            : (newReport = data.at(currentReportIndex + 1))
        newAnimationDirection =
          currentReportIndex === dataLastIndex
            ? ThreatIntelSideSheetArrowDirection.DOWN
            : ThreatIntelSideSheetArrowDirection.UP
        break
    }

    if (newReport) {
      setRowSelection({ [newReport.id]: true })
    }

    if (newAnimationDirection) {
      setAnimationDirection(newAnimationDirection)
    }
  }

  const handleCardClick = (reportId: string) => {
    isSideSheetOpen && activeReportId === reportId
      ? closeSideSheet()
      : openSideSheet(reportId)
  }

  const renderContent = () => {
    if (loading) {
      return <Loader strokeWidth={2} size={50} />
    }

    if (!data) {
      return <ComponentError />
    }

    return data.map((advisory) => (
      <ThreatIntelReportsAdvisoryCard
        key={advisory.id}
        advisory={advisory}
        onClick={() => {
          handleCardClick(advisory.id)
          trackAnalyticEvent('featured_threat_report_clicked', {
            threat_report_name: advisory.title,
          })
        }}
        isActive={activeReportId === advisory.id}
      />
    ))
  }

  if (data && !data.length) {
    return null
  }

  return (
    <>
      <Box
        id="threat-intel-widget"
        data-testid="threat-intel-widget"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {renderContent()}
      </Box>
      <ThreatIntelSideSheet
        isOpen={isSideSheetOpen}
        closeSideSheet={closeSideSheet}
        threatIntelReport={
          Object.keys(activeReport).length ? activeReport : null
        }
        animationEnabled
        onArrowClick={handleArrowClick}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      />
    </>
  )
}

export default ThreatIntelWidget
