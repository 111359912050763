import { useContext, useRef, useState } from 'react'
import {
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  autoUpdate,
  arrow,
} from '@floating-ui/react'
import { Avatar, Box } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Context } from '@components/App'
import PopoverContent from '@common/PopoverContent'
import { useSignOut } from '@hooks/index'
import { useAvatarInitials } from '@hooks/useAvatarInitials'
import { Dialog } from '@common/Dialog'
import { Paths } from '@components/App/Types'
import BugForm from '@components/Layout/Sidebar/BugForm/BugForm'

import { UserAvatarMenuItem } from './UserAvatarMenuItem'

export const UserAvatarNavElement = () => {
  const {
    state: {
      user: { firstName, lastName, isDWEmployee },
    },
  } = useContext(Context)
  const avatarInitials = useAvatarInitials({ firstName, lastName })
  const signOut = useSignOut()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const [menuOpen, setMenuOpen] = useState(false)
  const [isBugModalOpen, setIsBugModalOpen] = useState(false)
  const arrowRef = useRef<SVGSVGElement>(null)

  /** Floating UI Config */
  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      offset(8),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 12 }), // Padding for border-radius
    ],
    open: menuOpen,
    onOpenChange: setMenuOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const openLegalUrl = () => {
    window.open('https://legal.deepwatch.com/', '_blank')
  }

  const openSubmitIdeaUrl = () => {
    window.open('https://dwportal.ideas.aha.io/portal_session/new', '_blank')
  }

  return (
    <>
      <Box
        data-testid="user-avatar-nav"
        role="option"
        tabIndex={0}
        aria-selected={menuOpen}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Avatar
          sx={{ fontSize: 11, flex: 'none', cursor: 'pointer' }}
          variant={isDWEmployee ? 'dw' : 'cust'}
          data-testid="user-avatar"
        >
          {avatarInitials}
        </Avatar>

        {menuOpen && (
          <FloatingFocusManager context={context}>
            <PopoverContent
              floatingStyles={floatingStyles}
              getFloatingProps={getFloatingProps}
              setFloating={refs.setFloating}
              sx={{
                borderRadius: '12px',
                overflow: 'hidden',
              }}
              arrowProps={{ arrowRef, context }}
            >
              <UserAvatarMenuItem
                text="My profile"
                icon="personSharp"
                onClick={() =>
                  navigate(
                    Paths.PROFILE +
                      (selectedCustomer ? `?customer=${selectedCustomer}` : ''),
                  )
                }
              />
              <UserAvatarMenuItem
                text="Submit an idea"
                icon="bulb"
                onClick={openSubmitIdeaUrl}
                isExternal
              />
              <UserAvatarMenuItem
                text="Submit a bug"
                icon="bug"
                onClick={() => setIsBugModalOpen(true)}
              />
              <UserAvatarMenuItem
                text="Legal"
                icon="documentText"
                onClick={openLegalUrl}
                isExternal
              />
              <UserAvatarMenuItem
                text="Sign out"
                icon="power"
                onClick={signOut}
              />
            </PopoverContent>
          </FloatingFocusManager>
        )}
      </Box>
      <Dialog
        title="Submit a bug"
        isOpen={isBugModalOpen}
        onClose={() => setIsBugModalOpen(false)}
      >
        <BugForm closeModal={() => setIsBugModalOpen(false)} />
      </Dialog>
    </>
  )
}
