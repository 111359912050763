/* eslint-disable security/detect-object-injection */
import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { Link, Typography } from '@mui/material'

import {
  buildGenericCell,
  buildGenericHeader,
} from '@common/Table/utils/table.utils'
import { MttxData } from '@queries/mttx'
import { MttxDimension, MttxMetric, MttxSegment } from '@models/MttxDetails'
import { toTimeUnits } from '@utils/DateTimeUtils'
import {
  formatTicketDetailsPriority,
  FormattedTicketType,
} from '@components/Tickets/TicketLibrary/TicketDetails/TicketDetails.utils'
import { TicketTypeEnum } from '@models/Tickets'

class MTTXColumnDefFactory {
  private columnName: string

  private readonly METRIC_COLUMN_MAPPING = {
    [MttxMetric.MTTN]: 'Time to notify',
    [MttxMetric.MTTCAck]: 'Time to acknowledge',
  }

  private columnHelper = createColumnHelper<MttxData>()
  private featureEditTicketPage = false
  private selectedCustomer: string | null

  constructor(
    metric: MttxMetric,
    featureEditTicketPage: boolean,
    selectedCustomer: string | null,
  ) {
    this.columnName = this.METRIC_COLUMN_MAPPING[metric]
    this.featureEditTicketPage = featureEditTicketPage
    this.selectedCustomer = selectedCustomer
  }

  private createTicketIdCell = (props: CellContext<MttxData, string>) => {
    const ticketId = props.getValue()
    const sysId = props.row.original.sysId
    let targetHref = `/tickets/library/${sysId}/edit`

    if (this.selectedCustomer) {
      targetHref += `?customer=${this.selectedCustomer}`
    }
    if (this.featureEditTicketPage) {
      return (
        <Link href={targetHref} rel="noreferrer">
          {ticketId}
        </Link>
      )
    }

    return buildGenericCell(props)
  }

  private readonly createdDateDimensionColDef = [
    {
      id: 'priority',
      cell: () => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          All
        </Typography>
      ),
      header: () => buildGenericHeader('Priority'),
    },
    this.columnHelper.accessor('averageDuration', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(props.getValue())}
        </Typography>
      ),
      header: () => buildGenericHeader(this.columnName),
    }),
  ]

  private readonly ticketPriorityDimensionColDef = [
    this.columnHelper.accessor('priority', {
      cell: (props) => (
        <div style={{ display: 'flex' }}>
          {formatTicketDetailsPriority(props.getValue())}
        </div>
      ),
      header: () => buildGenericHeader('Priority'),
    }),
    this.columnHelper.accessor('averageDuration', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(props.getValue())}
        </Typography>
      ),
      header: () => buildGenericHeader(this.columnName),
    }),
    this.columnHelper.accessor('totalTickets', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {props.getValue()}
        </Typography>
      ),
      header: () => buildGenericHeader('Total tickets'),
    }),
  ]

  private readonly ticketIdPriorityColumnDef = [
    this.columnHelper.accessor('ticketId', {
      cell: this.createTicketIdCell,
      header: () => buildGenericHeader('Ticket'),
    }),
    this.columnHelper.accessor('priority', {
      cell: (props) => (
        <div style={{ display: 'flex' }}>
          {formatTicketDetailsPriority(props.getValue())}
        </div>
      ),
      header: () => buildGenericHeader('Priority'),
    }),
    this.columnHelper.accessor('duration', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(props.getValue())}
        </Typography>
      ),
      header: () => buildGenericHeader(this.columnName),
    }),
  ]

  private readonly ticketTypeColumnDef = [
    this.columnHelper.accessor('ticketType', {
      cell: (props) => (
        <FormattedTicketType
          ticketType={props.getValue() as TicketTypeEnum}
          priority={props.row.original.priority}
        />
      ),
      header: () => buildGenericHeader('ticket type'),
    }),
    this.columnHelper.accessor('averageDuration', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(props.getValue())}
        </Typography>
      ),
      header: () => buildGenericHeader(this.columnName),
    }),
    this.columnHelper.accessor('totalTickets', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {props.getValue()}
        </Typography>
      ),
      header: () => buildGenericHeader('ticket count'),
    }),
  ]

  private readonly ticketIdTicketTypeColumnDef = [
    this.columnHelper.accessor('ticketId', {
      cell: this.createTicketIdCell,
      header: () => buildGenericHeader('Ticket'),
    }),
    this.columnHelper.accessor('ticketType', {
      cell: (props) => (
        <div style={{ display: 'flex' }}>
          <FormattedTicketType
            ticketType={props.getValue() as TicketTypeEnum}
            priority={props.row.original.priority}
          />
        </div>
      ),
      header: () => buildGenericHeader('ticket type'),
    }),
    this.columnHelper.accessor('duration', {
      cell: (props) => (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {toTimeUnits(props.getValue())}
        </Typography>
      ),
      header: () => buildGenericHeader(this.columnName),
    }),
  ]

  public build(priority: string, dimension?: string, segment?: string) {
    if (
      dimension === MttxDimension.TICKET_TYPE ||
      segment === MttxSegment.TICKET_TYPE
    ) {
      if (priority !== 'All') {
        return this.ticketIdTicketTypeColumnDef
      }
      return this.ticketTypeColumnDef
    }

    if (priority !== 'All') {
      return this.ticketIdPriorityColumnDef
    }

    if (dimension === MttxDimension.CREATED_DATE) {
      if (segment === MttxSegment.TICKET_PRIORITY) {
        return this.ticketIdPriorityColumnDef
      }
      return this.createdDateDimensionColDef
    }

    if (dimension === MttxDimension.TICKET_PRIORITY) {
      return this.ticketPriorityDimensionColDef
    }

    return []
  }
}

export default MTTXColumnDefFactory
