import {
  Row,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { Report } from '@models/index'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import TableHeader from '@common/Table/utils/TableHeader'
import { FormatStringDateRange } from '@utils/DateTimeUtils'
import { TableToolbar } from '@common/TableToolbar'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { Dialog } from '@common/Dialog'
import Icon from '@common/Icon'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'

import ReportNameCell from './cells/ReportNameCell'
import ReportCreatedAtCell from './cells/ReportCreatedAtCell'
import { GenerateReportModal } from '../GenerateReportModal'

interface ReportTableProps {
  handleDeleteReportModal: (reportToDelete: Report) => void
  handleDownloadReport: (reportId: number) => void
  handleRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  loading: boolean
  reports: Report[]
  rowSelection: RowSelectionState
}

export const REPORT_TABLE_ID_PREFIX = 'report-table-row-'

interface GenerateReportDialogProps {
  isOpen: boolean
  onClose: VoidFunction
}
const GenerateReportDialog: React.FC<GenerateReportDialogProps> = ({
  isOpen,
  onClose,
}) => (
  <Dialog title="Monthly Report" isOpen={isOpen} onClose={onClose}>
    <GenerateReportModal closeModal={onClose} />
  </Dialog>
)

const ReportTable: React.FC<ReportTableProps> = ({
  handleDeleteReportModal,
  handleDownloadReport,
  handleRowSelection,
  loading,
  reports,
  rowSelection,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ])

  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false)

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<Report>()
    return [
      {
        id: 'reportName',
        accessorFn: (row: Report) => ({
          name: row.name,
          numberDownloadAttempt: row.numberDownloadAttempt,
          status: row.status,
        }),
        cell: ReportNameCell,
        header: () => buildGenericHeader('Report Name'),
        sortingFn: (rowA: Row<Report>, rowB: Row<Report>) => {
          const rowAName = rowA.original.name.toLowerCase()
          const rowBName = rowB.original.name.toLowerCase()

          if (rowAName < rowBName) {
            return -1
          }

          if (rowBName < rowAName) {
            return 1
          }

          return 0
        },
      },
      {
        id: 'dateRange',
        accessorFn: (row: Report) => {
          return FormatStringDateRange(
            row.startDate,
            row.endDate,
            'MMM dd, yyyy',
          )
        },
        cell: (props) =>
          buildGenericCell(props, props.row.original.status === 'Requested'),
        header: () => buildGenericHeader('Date Range'),
        size: 250,
      },
      columnHelper.accessor('userName', {
        id: 'userName',
        cell: (props) =>
          buildGenericCell(props, props.row.original.status === 'Requested'),
        header: () => buildGenericHeader('User'),
        sortingFn: 'textCaseSensitive',
        size: 200,
      }),
      {
        id: 'createdAt',
        accessorFn: (row: Report) => ({
          createdAt: row.createdAt,
          status: row.status,
        }),
        cell: (props) => (
          <ReportCreatedAtCell
            cellContext={props}
            handleDeleteReportModal={handleDeleteReportModal}
            handleDownloadReport={handleDownloadReport}
          />
        ),
        header: () => buildGenericHeader('Date Created'),
        sortingFn: (rowA: Row<Report>, rowB: Row<Report>) => {
          const rowADate = new Date(rowA.original.createdAt).valueOf()
          const rowBDate = new Date(rowB.original.createdAt).valueOf()

          return rowADate - rowBDate
        },
        size: 130,
      },
    ]
  }, [handleDeleteReportModal, handleDownloadReport])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable({
    columns: columnDef,
    data: reports,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: handleRowSelection,
    onSortingChange: setSorting,
    getRowId: (row) => String(row.id),
    state: {
      rowSelection,
      sorting,
      columnSizing,
    },
    onColumnSizingChange,
    columnResizeMode: 'onChange',
  })

  const openGenerateReportModal = () => {
    setIsGenerateReportModalOpen(true)
  }

  if (!loading && reports.length === 0) {
    return (
      <Box
        data-testid="report-empty-state"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Icon
          size={150}
          variant="reportsEmptyState"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
          })}
        />

        <Typography sx={{ paddingTop: '1rem' }} variant="h5">
          No generated reports
        </Typography>

        <Button
          onClick={openGenerateReportModal}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              openGenerateReportModal()
            }
          }}
          sx={{ marginTop: '1rem' }}
          variant="text"
        >
          Generate Report
        </Button>

        <GenerateReportDialog
          isOpen={isGenerateReportModalOpen}
          onClose={() => setIsGenerateReportModalOpen(false)}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TableToolbar
        loading={loading}
        dataLength={reports.length}
        singularEntityName={'report'}
        pluralEntityName={'reports'}
      >
        <Button
          onClick={openGenerateReportModal}
          tabIndex={0}
          variant="contained"
        >
          Generate Report
        </Button>
      </TableToolbar>

      <CommonTableContainer ref={tableContainerRef}>
        <CommonTable data-testid="report-table">
          <thead data-testid="report-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<Report>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          <tbody data-testid="report-table-body">
            {loading
              ? buildSkeletonRows(table.getAllColumns())
              : table
                  .getRowModel()
                  .rows.map((row) => (
                    <MemoizedTanStackTableRow
                      key={row.id}
                      rowId={`${REPORT_TABLE_ID_PREFIX}${row.id}`}
                      testId="report-table-row"
                      isSelected={row.getIsSelected()}
                      isDisabled={row.original.status !== 'Generated'}
                      toggleSelected={row.toggleSelected}
                      visibleCells={row.getVisibleCells()}
                    />
                  ))}
          </tbody>
        </CommonTable>
      </CommonTableContainer>

      <GenerateReportDialog
        isOpen={isGenerateReportModalOpen}
        onClose={() => setIsGenerateReportModalOpen(false)}
      />
    </Box>
  )
}

export default ReportTable
