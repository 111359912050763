import DetectionSideSheetContent from '@components/Coverage/DetectionCatalog/DetectionCatalogSideSheet/DetectionSideSheetContent'
import { MitreOverviewDetection } from '@models/DetectionCoverage'
import { Detections } from '@models/Detections'

interface DetectionSideSheetProps {
  isOpen: boolean
  focusedDetection: Detections | MitreOverviewDetection | undefined
}

const AssociatedDetectionCatalogSideSheet = ({
  isOpen,
  focusedDetection,
}: DetectionSideSheetProps) => {
  return (
    <DetectionSideSheetContent detection={focusedDetection} isOpen={isOpen} />
  )
}

export default AssociatedDetectionCatalogSideSheet
