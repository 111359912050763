import { useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'

import { WidgetList } from '@common/WidgetList'
import {
  TicketPriorityEnum,
  TicketStatsAggregationOptions,
} from '@models/Tickets'
import {
  TICKET_STATS,
  TicketStatItem,
  TicketStatsResponse,
  TicketStatsVariables,
} from '@queries/ticket'
import { formatPriority } from '@utils/Common'
import { Paths } from '@components/App/Types'
import { ListItemType } from 'src/interfaces/ListItemType'

interface ByPriorityWidget {
  selectedCustomer: string | null
}

const ByPriorityWidget = ({ selectedCustomer }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { search: currentCustomer } = useLocation()

  const initialState: TicketStatItem[] = [
    { key: TicketPriorityEnum.Critical, count: 0 },
    { key: TicketPriorityEnum.High, count: 0 },
    { key: TicketPriorityEnum.Moderate, count: 0 },
    { key: TicketPriorityEnum.Low, count: 0 },
    { key: TicketPriorityEnum.Informational, count: 0 },
  ]

  const {
    data: { ticketStats: { data: statItems } } = {
      ticketStats: { data: initialState },
    },
    error,
    loading,
  } = useQuery<TicketStatsResponse, TicketStatsVariables>(TICKET_STATS, {
    variables: {
      selectedCustomer,
      input: {
        aggregateBy: TicketStatsAggregationOptions.TICKET_PRIORITY,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 120000, // 2 minutes
  })

  const handleWidgetItemClick = (filter: string) => {
    if (currentCustomer) {
      navigate(
        `${Paths.TICKET_LIBRARY}${currentCustomer}&selectedFilters={${filter}}`,
      )
    } else {
      navigate(`${Paths.TICKET_LIBRARY}?selectedFilters={${filter}}`)
    }
  }

  const widgetItems: ListItemType[] = statItems
    .filter((item: TicketStatItem) => item.key !== 'Unclassified')
    .map((item: TicketStatItem, index: number) => {
      const { color, icon } = formatPriority(item.key, theme)
      return {
        id: index,
        disabled: loading || !item.count,
        iconColor: color,
        leftIcon: icon,
        loading: loading && statItems.every((item) => item.count === 0), // handles case if Apollo cache has this data
        name: item.key,
        onClick: () => {
          handleWidgetItemClick(`"priority":["${item.key}"]`)
        },
        rightText: item.count.toString(),
      }
    })

  return (
    <WidgetList
      hasError={error !== undefined}
      title="By priority"
      widgetItems={widgetItems}
    />
  )
}

export default ByPriorityWidget
