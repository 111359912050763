import { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  children: ReactNode
  'data-testid'?: string
  title?: string
}

const TicketEditCard: React.FC<Props> = ({
  children,
  'data-testid': dataTestId,
  title,
}) => (
  <Box
    data-testid={dataTestId}
    sx={(theme) => ({
      backgroundColor: theme.vars.palette.secondary.light,
      border: `1px solid ${theme.vars.palette.secondary.main}`,
      borderRadius: '5px',
      padding: '1rem',
      ...theme.applyStyles('dark', {
        backgroundColor: theme.vars.palette.secondary.main,
        border: `1px solid ${theme.vars.palette.secondary.lighter}`,
      }),
    })}
  >
    {title && (
      <Typography
        data-testid={dataTestId}
        fontWeight={600}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          paddingBottom: '1rem',
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body1"
      >
        {title}
      </Typography>
    )}
    {children}
  </Box>
)

export default TicketEditCard
