import { useMutation } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'
import {
  ACTIVATE_USER,
  ActivateUserData,
  ActivateUserVariables,
} from '@mutations/user'
import { AlertSeverity, useToast } from '@hooks/useToast'

export interface ActivateUserModalProps {
  userId: string
  userName: string
  closeModal: VoidFunction
}

const ActivateUserModal: React.FC<ActivateUserModalProps> = ({
  closeModal,
  userId,
  userName,
}) => {
  const { handleShowToast } = useToast()
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const [activateUser] = useMutation<ActivateUserData, ActivateUserVariables>(
    ACTIVATE_USER,
    {
      refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
    },
  )

  const displaySuccessToast = () => {
    handleShowToast(
      AlertSeverity.Success,
      'User has been activated successfully!',
    )
  }

  const displayProcessingToast = () => {
    handleShowToast(AlertSeverity.Info, 'User has been activated successfully!')
  }

  const displayErrorToast = () => {
    handleShowToast(
      AlertSeverity.Error,
      'Activating user has failed. Please try again in a minute.',
    )
  }

  const handleActivateUser = async () => {
    closeModal()
    displayProcessingToast()

    await activateUser({
      variables: {
        input: {
          userId,
        },
      },
      onCompleted: displaySuccessToast,
      onError: displayErrorToast,
    })
  }

  // Focus the cancel button when the modal opens
  useEffect(() => {
    cancelButtonRef.current?.focus()
  }, [])

  return (
    <Box
      sx={{ padding: '1.5rem' }}
      id="activate-user-modal"
      data-testid="activate-user-modal"
    >
      <Typography
        fontWeight={600}
        color="textSecondary"
        variant="body1"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {`Are you sure you want to activate ${userName}? Their access to previously added application groups will be restored immediately.`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: 1,
          marginTop: '25px',
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          ref={cancelButtonRef}
          data-testid="user-status-cancel-btn"
          variant="outlined"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid="user-status-button"
          onClick={() => handleActivateUser()}
        >
          Activate
        </Button>
      </Box>
    </Box>
  )
}

export default ActivateUserModal
