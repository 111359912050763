import { OktaGroup } from '@models/index'
import { OptionType } from '@common/Dropdown'

export const filterAdminGroup = (groupItems: OptionType[]): OptionType[] =>
  groupItems?.filter((item) => !item.label.includes('-portal-admins'))

// A group has been removed if it exists in the initial list, but not in the selected list.
export const findGroupsToRemove = (
  initialGroupOptions: OptionType[],
  selectedGroupIDs: string[],
): string[] => {
  return initialGroupOptions
    .map((option) => {
      if (!selectedGroupIDs.includes(option.value as string)) {
        return option.value
      }
    })
    .filter(Boolean) as string[]
}

// A group has been added if it exists in the selected list, but not in the initial list.
export const findGroupsToAdd = (
  initialGroupOptions: OptionType[],
  selectedGroupIDs: string[],
): string[] => {
  return selectedGroupIDs
    .map((id) => {
      if (!initialGroupOptions.map((group) => group.value).includes(id)) {
        return id
      }
    })
    .filter(Boolean) as string[]
}

export const mapToListItems = (oktaGroups: OktaGroup[]): OptionType[] => {
  return oktaGroups
    .filter(
      (group) =>
        group.groupName.substring(0, 5) === 'cust-' &&
        !group.groupName.includes('-base'),
    )
    .map((group) => ({
      value: group.groupId,
      label: group.groupName,
    }))
}

export const toggleAdminGroup = ({
  isAdmin,
  selectedGroupIDs,
  adminGroupID,
}: {
  isAdmin: boolean
  selectedGroupIDs: string[]
  adminGroupID: string
}): string[] => {
  if (isAdmin) {
    return [...selectedGroupIDs, adminGroupID]
  } else {
    return selectedGroupIDs.filter((id) => id !== adminGroupID)
  }
}
