import { MetricType } from '@models/CyberAssistant'

export type CustomerQuestionType = {
  question: string
  answer?: string | JSX.Element
  id?: string
}

export enum AnswerId {
  TP_FP = 'TP_FP',
  TIME_SAVED = 'TIME_SAVED',
  MITRE = 'MITRE_COVERAGE',
  SI_SCORE = 'SECURITY_INDEX_SCORE',
  VENDOR_USAGE = 'VENDOR_DATA',
  MTTD = MetricType.MTTD,
  MTTR = MetricType.MTTR,
  MTTCACK = MetricType.MTTCACK,
}

export const customerQuestions: CustomerQuestionType[] = [
  {
    question:
      'What was my validated true positive rate this past quarter, and how does it compare to the average across all Deepwatch customers?',
    id: AnswerId.TP_FP,
  },
  {
    question: 'How much time did Deepwatch save me last quarter?',
    id: AnswerId.TIME_SAVED,
  },
  {
    question: 'How should I evaluate my MITRE coverage?',
    id: AnswerId.MITRE,
  },
  {
    question: 'What is my current Security Index score?',
    id: AnswerId.SI_SCORE,
  },
  {
    question:
      'Which sets of vendor data are being ingested in the Deepwatch platform?',
    id: AnswerId.VENDOR_USAGE,
  },
  {
    question:
      'What was the mean time to detect (MTTD) for high/critical security events this past quarter compared to the previous quarter?',
    id: AnswerId.MTTD,
  },
  {
    question:
      'What was the mean time to respond (MTTR) for high/critical security events this past quarter compared to the previous quarter?',
    id: AnswerId.MTTR,
  },
  {
    question:
      'What was the mean time to customer acknowledgement (MTTCAck) for high/critical security events this past quarter compared to the previous quarter?',
    id: AnswerId.MTTCACK,
  },
]
