import { Box, BoxProps, styled } from '@mui/material'

const CommonTable = styled((props) => (
  <Box cellSpacing={0} component="table" {...props} />
))<BoxProps<'table'>>(() => ({
  tableLayout: 'fixed',
  width: '100%',
}))

export default CommonTable
