import { Box, Chip, Tooltip, Typography } from '@mui/material'

import { getFormattedDate } from '@utils/DateTimeUtils'

interface ModuleRenewalData {
  title: string
  renewalDate: string | null
  status: string | null
}

export interface CustomerModuleRenewalDatesCellValues {
  activeModules: ReadonlyArray<ModuleRenewalData>
}

interface CustomerModuleRenewalDatesCellProps {
  data: ReadonlyArray<ModuleRenewalData>
}

const CustomerModuleRenewalDatesCell: React.FC<
  CustomerModuleRenewalDatesCellProps
> = ({ data }) => {
  if (!data || !data.length) {
    return (
      <Typography color="warning" variant="body2">
        Error
      </Typography>
    )
  }

  if (
    data.some((item) => item.title === 'MDR' && item.status === 'Terminating')
  ) {
    return (
      <Typography color="warning" variant="body2">
        Terminating
      </Typography>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        flexWrap: 'wrap',
      }}
    >
      {data.map((item) => {
        return (
          <Tooltip
            title={
              item.renewalDate
                ? `Renewal Date: ${getFormattedDate(item.renewalDate, 'MMM dd, yyyy')}`
                : 'No renewal date'
            }
            placement="top"
            key={item.title}
            data-testid="renewal-date-tooltip"
          >
            <Chip
              variant="outlined"
              label={item?.title ?? '--'}
              sx={[
                (theme) => ({
                  border: 0,
                  '&.MuiChip-outlined': {
                    backgroundColor: theme.vars.palette.primary.main,
                    borderColor: theme.vars.palette.secondary.main,
                  },
                  backgroundColor: theme.vars.palette.primary.light,
                  '&.MuiChip-root': {
                    height: '100%',
                  },
                  ...theme.applyStyles('dark', {
                    '&.MuiChip-outlined': {
                      color: theme.vars.palette.text.primary,
                      backgroundColor: theme.vars.palette.secondary.darker,
                    },
                  }),
                }),
              ]}
            />
          </Tooltip>
        )
      })}
    </Box>
  )
}

export default CustomerModuleRenewalDatesCell
