/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { Box, Typography } from '@mui/material'

interface SiMetricsCardProps {
  setActivePart: (id: string) => void
}

const SiMetricsCards = ({ setActivePart }: SiMetricsCardProps) => {
  return (
    <Box
      sx={{
        flex: '0 1 68%',
        textAlign: 'center',
        gap: '32px',
        display: 'flex',
        justifyContent: 'space-evenly',
        '@media screen and (max-width: 768px)': {
          flexDirection: 'column',
          gap: '1rem',
        },
        '@media screen and (min-width: 1700px)': {
          flex: '0 1 61%',
        },
      }}
      data-testid="si-cards"
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.info.main}`,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexBasis: '230px',
          maxWidth: '230px',
          padding: '1rem',
          gap: '1rem',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.darker,
          }),
          '@media screen and (max-width: 768px)': {
            flexBasis: 'auto',
            flexDirection: 'row',
            maxWidth: '100%',
          },
        })}
        role="contentinfo"
        tabIndex={0}
        id="si-source-types"
        data-testid="si-source-types"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography
          fontWeight={600}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          Enabled source types
        </Typography>
        <Typography variant="body2">
          We base your index on the types of data sources you send us. High
          value data sources increase this index most but are also seen as the
          most critical by Deepwatch.
        </Typography>
        <Typography
          fontWeight={500}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          50% weight
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.important.main}`,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexBasis: '230px',
          maxWidth: '230px',
          padding: '1rem',
          gap: '1rem',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.darker,
          }),
          '@media screen and (max-width: 768px)': {
            flexBasis: 'auto',
            flexDirection: 'row',
            maxWidth: '100%',
          },
        })}
        role="contentinfo"
        tabIndex={0}
        id="si-detections"
        data-testid="si-card-detections"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography
          fontWeight={600}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          Enabled detections
        </Typography>
        <Typography variant="body2">
          Each source type provided has associated detections. Working with
          Deepwatch experts can identify the most effective detections to
          enable.
        </Typography>
        <Typography
          fontWeight={500}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          30% weight
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.success.light}`,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexBasis: '230px',
          maxWidth: '230px',
          padding: '1rem',
          gap: '1rem',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.darker,
            borderColor: theme.vars.palette.success.main,
          }),
          '@media screen and (max-width: 768px)': {
            flexBasis: 'auto',
            flexDirection: 'row',
            maxWidth: '100%',
          },
        })}
        role="contentinfo"
        tabIndex={0}
        id="si-enterprise-coverage"
        data-testid="si-card-enterprise"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography
          fontWeight={600}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          Enterprise coverage
        </Typography>
        <Typography variant="body2">
          The amount of coverage Deepwatch can see. When we see active assets in
          your environment your index will reflect this.
        </Typography>
        <Typography
          fontWeight={500}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          20% weight
        </Typography>
      </Box>
    </Box>
  )
}
export default SiMetricsCards
