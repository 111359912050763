import { gql } from '@apollo/client'

export enum ModuleStatus {
  ONBOARDING = 'ONBOARDING',
  ACTIVE = 'ACTIVE',
  AVAILABLE = 'AVAILABLE',
}

export enum ModuleType {
  MDR = 'MDR',
  MEDR = 'MEDR',
  MFW = 'MFW',
  VM = 'VM',
  TS = 'TS',
}

export interface Module {
  status: ModuleStatus
  title: string
  type: ModuleType
  technology: string | null
  technologyUrl: string | null
  serviceRenewalDate: string | null
  activationDate: string | null
}

export interface MDRModule extends Module {
  type: ModuleType.MDR
  deploymentType: string
  SIEMLicenseVolume: string
}

export interface MEDRModule extends Module {
  type: ModuleType.MEDR
  endpoints: string
  primaryEngineerName: string | null
}

export interface MFWModule extends Module {
  type: ModuleType.MFW
  primaryEngineerName: string | null
  isPolicyGovEnabled: boolean
  isPerfMonitoringEnabled: boolean
}

export interface VMModule extends Module {
  type: ModuleType.VM
  primaryEngineerName: string | null
  volume: string
  serviceTier: string
}

export interface TSModule extends Module {
  type: ModuleType.TS
}

export interface Modules {
  MDR: MDRModule
  MEDR: MEDRModule
  MFW: MFWModule
  VM: VMModule
  TS: TSModule
}

export type ModuleUnion =
  | MDRModule
  | MEDRModule
  | MFWModule
  | VMModule
  | TSModule

export interface ModulesData {
  modules: Modules
}

export interface ModulesVariables {
  selectedCustomer: string | null
}

export const MODULES = gql`
  query Modules($selectedCustomer: String) {
    modules(selectedCustomer: $selectedCustomer) {
      MDR {
        SIEMLicenseVolume
        activationDate
        deploymentType
        serviceRenewalDate
        status
        technology
        technologyUrl
        title
        type
      }
      MEDR {
        activationDate
        endpoints
        primaryEngineerName
        serviceRenewalDate
        status
        technology
        technologyUrl
        title
        type
      }
      MFW {
        activationDate
        isPerfMonitoringEnabled
        isPolicyGovEnabled
        primaryEngineerName
        serviceRenewalDate
        status
        technology
        technologyUrl
        title
        type
      }

      TS {
        activationDate
        serviceRenewalDate
        status
        technology
        technologyUrl
        title
        type
      }
      VM {
        activationDate
        primaryEngineerName
        serviceRenewalDate
        serviceTier
        status
        technology
        technologyUrl
        title
        type
        volume
      }
    }
  }
`
