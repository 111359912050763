import cloneDeep from 'lodash/cloneDeep'
import { Box } from '@mui/material'

import { CustomerHighlight } from '@models/index'
import { DeepwatchExpertCustomerTable } from '@components/DeepwatchExperts/DeepwatchExpertCustomerTable'

interface MyCustomersProps {
  bookmarkedCustomers: CustomerHighlight[]
  loading: boolean
}

const MyCustomers: React.FC<MyCustomersProps> = ({
  bookmarkedCustomers,
  loading,
}) => {
  return (
    <Box data-testid="my-customers" id="my-customers">
      <DeepwatchExpertCustomerTable
        customerHighlights={cloneDeep(bookmarkedCustomers)}
        disableRowClick={false}
        loading={loading}
        disableAddedTags={true}
      />
    </Box>
  )
}

export default MyCustomers
