import { Box, IconButton, SxProps, Theme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Icon from '@common/Icon'
import { useCustomer } from '@hooks/useCustomer'
import { navigateUserType } from '@utils/navigateUserType'

export interface BackButtonProps {
  iconStyles?: SxProps<Theme>
  containerStyles?: SxProps<Theme>
  previousPath: string
}

const BackButton: React.FC<BackButtonProps> = ({
  iconStyles,
  containerStyles,
  previousPath,
}) => {
  const {
    customer: { customerShortName },
    isDWEmployee,
  } = useCustomer()
  const navigate = useNavigate()
  const handleBackNavigation = () => {
    navigateUserType(isDWEmployee, previousPath, navigate, customerShortName)
  }
  return (
    <Box
      sx={[
        {
          marginBottom: 'auto',
          marginRight: 'auto',
        },
        ...(Array.isArray(containerStyles)
          ? containerStyles
          : [containerStyles]),
      ]}
    >
      <IconButton
        data-testid="back-navigation"
        onClick={handleBackNavigation}
        sx={[
          {
            margin: 1,
          },
          ...(Array.isArray(iconStyles) ? iconStyles : [iconStyles]),
        ]}
      >
        <Icon variant="arrowBackCircleOutline" />
      </IconButton>
    </Box>
  )
}

export default BackButton
