import { useMemo } from 'react'
import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Typography } from '@mui/material'

import { TableCell, TableHeader } from '@common/Table'
import {
  buildGenericCell,
  buildGenericHeader,
} from '@common/Table/utils/table.utils'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableRow from '@common/Table/components/CommonTableRow'

import { MitreTableItem, SubtechniqueCoverageTag } from '../MitreList/utils'

interface MitreTableProps {
  data: MitreTableItem[]
  rowSelection: RowSelectionState
  handleRowClick: (subtechniqueIndex: number) => void
  hasUserClosedSideSheet: boolean
}

const MitreTable: React.FC<MitreTableProps> = ({
  data,
  handleRowClick,
  rowSelection,
  hasUserClosedSideSheet,
}) => {
  const columnDef: ColumnDef<MitreTableItem, any>[] = useMemo(() => {
    const columnHelper = createColumnHelper<MitreTableItem>()

    return [
      columnHelper.accessor('mitreSubtechniqueName', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('Sub technique'),
      }),
      {
        id: 'customerSubtechniqueCoverage',
        accessorFn: (row) => row.customerSubtechniqueCoverage,
        cell: (props) => {
          const val = props.getValue()

          return <SubtechniqueCoverageTag coverage={val} />
        },
        header: () => buildGenericHeader('Coverage'),
      },
      {
        id: 'useCases',
        accessorFn: (row) => ({
          customerTechniqueSubTechniqueEnabledUseCaseCount:
            row.customerTechniqueSubTechniqueEnabledUseCaseCount,
          totalUseCaseCount: row.totalUseCaseCount,
        }),
        cell: (props) => {
          const {
            customerTechniqueSubTechniqueEnabledUseCaseCount,
            totalUseCaseCount,
          } = props.getValue()

          return (
            <Typography color="textPrimary">{`${customerTechniqueSubTechniqueEnabledUseCaseCount}/${totalUseCaseCount}`}</Typography>
          )
        },
        header: () => buildGenericHeader('Detections'),
      },
    ]
  }, [])

  const table = useReactTable<MitreTableItem>({
    columns: columnDef,
    data,
    enableMultiRowSelection: false,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <CommonTableContainer data-testid="mitre-table-container">
      <CommonTable>
        <thead data-testid="mitre-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader<MitreTableItem> key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>

        <tbody data-testid="mitre-table-body">
          {table.getRowModel().rows.map((row) => {
            const isSelected = row.getIsSelected() && !hasUserClosedSideSheet

            return (
              <CommonTableRow
                key={row.id}
                isSelected={isSelected}
                data-testid="mitre-table-row"
                onClick={(e) => {
                  e.stopPropagation()
                  handleRowClick(row.original.subtechniqueIndex!)
                }}
                onKeyDown={(e) => {
                  handleKeyboardAction(e, () =>
                    handleRowClick(row.original.subtechniqueIndex!),
                  )
                }}
                tabIndex={0}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell<MitreTableItem> key={cell.id} cell={cell} />
                ))}
              </CommonTableRow>
            )
          })}
        </tbody>
      </CommonTable>
    </CommonTableContainer>
  )
}

export default MitreTable
