import { AppBar, Box, Toolbar, useColorScheme } from '@mui/material'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useCustomer } from '@hooks/useCustomer'
import { zIndex } from '@components/App/Styles/zIndex'
import { DeepWatchLogo } from '@common/SVGs/DeepwatchLogo'
import { betaCustomerShortNames } from '@components/BetaAgreementModal/betaAgreementModalConstants'

import { DatePickerNavElement } from './DatePickerNavElement'
import { DeepwatchExpertsTabs } from './DeepwatchExpertsTabs'
import { UserAvatarNavElement } from './UserAvatarNavElement'
import NotificationsNavItem from './NotificationsNavItem'
import CyberAssistant from '../CyberAssistant/CyberAssistant'

const SIDEBAR_WIDTH = 260

interface TopNavProps {
  /**
   * If set to true, the logo will render with styles which make it appear to be part of the sidebar.
   * Has no effect in dark mode.
   */
  renderLogoAsSidebar?: boolean
}

export const TopNav: React.FC<TopNavProps> = ({ renderLogoAsSidebar }) => {
  const { featureCyberAssistant } = useFlags()
  const { isDWEmployee, customer } = useCustomer()
  const { mode, systemMode } = useColorScheme()
  const currentThemeMode = systemMode ?? mode

  const shouldShowCyberAssistant =
    featureCyberAssistant &&
    (betaCustomerShortNames.includes(customer.customerShortName) ||
      process.env.ENV !== 'production')

  return (
    <AppBar
      position="static"
      component="nav"
      data-testid="top-nav"
      sx={() => ({ zIndex: zIndex.TOP_NAV })}
    >
      <Toolbar
        sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}
      >
        <Box
          data-testid="top-nav-logo-container"
          display="flex"
          alignItems="center"
          height="100%"
          sx={(theme) => ({
            width: `${SIDEBAR_WIDTH - 1}px`, // Subtract 1px to account for the border on the right
            pl: `calc(3 * ${theme.vars.spacing})`,
            pr: `calc(3 * ${theme.vars.spacing})`,
            backgroundColor: renderLogoAsSidebar
              ? theme.vars.palette.primary.dark
              : undefined,
            borderBottom: renderLogoAsSidebar
              ? undefined
              : `1px solid ${theme.vars.palette.secondary.lighter}`,
            ...theme.applyStyles('dark', {
              backgroundColor: `${theme.vars.palette.secondary.dark} !important`,
              borderBottom: `1px solid ${theme.vars.palette.secondary.lighter}`,
              width: `${SIDEBAR_WIDTH}px`,
            }),
          })}
        >
          <DeepWatchLogo
            textColor={
              !renderLogoAsSidebar && currentThemeMode === 'light'
                ? 'black'
                : 'white'
            }
          />
        </Box>
        <Box
          display="flex"
          flex={1}
          height="100%"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.vars.palette.secondary.lighter}`,
          })}
        >
          <Box
            display="flex"
            flex={1}
            height="100%"
            sx={({ vars }) => ({ pl: `calc(3 * ${vars.spacing})` })}
          >
            {isDWEmployee ? <DeepwatchExpertsTabs /> : null}
          </Box>
          <Box
            display="flex"
            gap="1.5rem"
            alignItems="center"
            sx={({ vars }) => ({ pr: `calc(3 * ${vars.spacing})` })}
          >
            <DatePickerNavElement />
            {shouldShowCyberAssistant ? <CyberAssistant /> : null}
            {isDWEmployee ? null : <NotificationsNavItem />}
            <UserAvatarNavElement />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
