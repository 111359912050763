import { Box, IconButton, Theme } from '@mui/material'
import { ReactNode } from 'react'

import Icon from '@common/Icon'

import type { SxStyle } from '@mui-theme/types'

export const sideSheetHeaderCss: Record<string, SxStyle> = {
  header: (theme: Theme) => ({
    alignItems: 'center',
    borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
    // background: alpha(palette.secondary.dark, 0.92), // Do we need this? It reduces the transparency of the header more than the rest of the side sheet
    display: 'flex',
    height: '73px',
    justifyContent: 'space-between',
    ...theme.applyStyles('dark', {
      borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
    }),
  }),
  headerContent: ({ vars }) => ({
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '0.5rem',
    color: vars.palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '120%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }),
  titleContent: ({ vars }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',

    'div:nth-child(1)': {
      paddingRight: '12px',

      'ion-icon.md': {
        color: `${vars.palette.text.primary} !important`,
      },
    },
  }),
}

interface SideSheetHeaderProps {
  title: string
  icon?: ReactNode
  closeSideSheet?: VoidFunction
  testId?: string
}

export const SideSheetHeader: React.FC<SideSheetHeaderProps> = ({
  title,
  icon,
  closeSideSheet,
}) => {
  return (
    <Box sx={sideSheetHeaderCss.header} data-testid="sidesheet-header">
      <Box sx={sideSheetHeaderCss.headerContent}>
        <Box
          sx={sideSheetHeaderCss.titleContent}
          data-testid="sidesheet-title-content"
        >
          {icon && <div>{icon}</div>}
          <div>{title}</div>
        </Box>
        <IconButton
          sx={{ padding: '5px' }}
          onClick={closeSideSheet}
          disabled={!open}
          data-testid="sideSheetCloseButton"
        >
          <Icon variant="closeOutline" />
        </IconButton>
      </Box>
    </Box>
  )
}
