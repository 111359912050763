import { Avatar, Box, Typography } from '@mui/material'

import { TicketPriorityEnum, TicketTypeEnum } from '@models/Tickets'
import { useAvatarInitials } from '@hooks/useAvatarInitials'
import { IconVariant } from '@common/Icon/Icons'
import Icon from '@common/Icon'
import { chartColors } from '@components/Home/Dashboard/MTTXSidesheet/Mttx.utils'
import { avatarVariant } from '@components/Tickets/utils'

export const formatTicketDetailsPriority = (priority: string): JSX.Element => {
  let priorityIcon: IconVariant

  switch (priority) {
    case 'Critical':
      priorityIcon = 'skull' as IconVariant
      break
    case 'High':
      priorityIcon = 'alertCircle' as IconVariant
      break
    case 'Moderate':
      priorityIcon = 'disc' as IconVariant
      break
    case 'Low':
      priorityIcon = 'arrowDownCircleSharp' as IconVariant
      break
    case 'Informational':
      priorityIcon = 'informationCircleSharp' as IconVariant
      break
    default:
      priorityIcon = 'skull' as IconVariant
      break
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
      <Icon
        sx={(theme) => ({ color: chartColors(theme, priority) })}
        variant={priorityIcon}
        size={16}
      />
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {priority}
      </Typography>
    </Box>
  )
}

export const formatEmptyField = (value: string | null) => {
  if (value === null || value === '') {
    return '--'
  }

  return value
}

export const formatLastUpdatedByField = (value: string | null) => {
  if (!value) {
    return '--'
  }

  if (value.length < 25) {
    return value
  }

  let lastUpdated: JSX.Element
  const emailMatch = value.match(/^([^@\s]+)@([^@\s]+\.[^@\s]+)/)
  if (emailMatch) {
    // If email - break on the domain
    const [, username, domain] = emailMatch
    lastUpdated = (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {username}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >{`@${domain}`}</Typography>
      </Box>
    )
  } else {
    // If name - break on the next name
    const names = value.split(' ')
    const midpoint = Math.ceil(names.length / 2)
    const firstLine = names.slice(0, midpoint).join(' ')
    const secondLine = names.slice(midpoint).join(' ')
    lastUpdated = (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {firstLine}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body2"
        >
          {secondLine}
        </Typography>
      </Box>
    )
  }

  return (
    <Typography
      sx={(theme) => ({
        color: theme.vars.palette.text.primary,
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.text.secondary,
        }),
      })}
      variant="body2"
    >
      {lastUpdated}
    </Typography>
  )
}

export const formatAssignedUserAvatar = (user: string, email: string) => {
  const [firstName, ...lastName] = user.split(' ')
  const avatarInitials = useAvatarInitials
  const avatarType = avatarVariant(email)

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
      <Avatar
        sx={{ fontSize: 10, width: 24, height: 22 }}
        variant={avatarType}
        data-testid={`${avatarType}-avatar`}
      >
        {avatarInitials({ firstName, lastName: lastName.join(' ') })}
      </Avatar>
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {user}
      </Typography>
    </Box>
  )
}

interface FormattedTicketTypeProps {
  ticketType: TicketTypeEnum
  priority?: TicketPriorityEnum | 'All'
}

export const FormattedTicketType: React.FC<FormattedTicketTypeProps> = ({
  ticketType,
  priority = 'All',
}) => {
  if (!priority) {
    priority = 'All'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Icon
        sx={(theme) => ({ color: chartColors(theme, priority, ticketType) })}
        variant="ellipse"
        size={16}
      />
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {ticketType}
      </Typography>
    </Box>
  )
}
