/* eslint-disable @getify/proper-ternary/nested */
import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import { format } from 'date-fns-tz'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { captureException } from '@sentry/react'

import { Context } from '@components/App'
import { useTicketEditContext, useTicketsContext } from '@hooks/index'
import InlineMessage, {
  InlineMessageType,
} from '@common/InlineMessage/InlineMessage'
import { GET_TICKET_HEADER, TicketHeaderResponse } from '@queries/ticket'
import { TicketStateEnum } from '@models/Tickets'
import { UPDATE_TICKET, UpdateTicketVariables } from '@mutations/ticket'
import Icon from '@common/Icon'
import { Dialog } from '@common/Dialog'

import TicketDataHandler from './TicketDataHandler'
import { TicketResolutionForm } from './TicketResolution'
import { updateTicketInCache } from '../utils'
import { TICKET_COMMENT_TIME_FORMAT } from './TicketActivity/TicketActivity.utils'
import ProgressButton from './ProgressButton/ProgressButton'

enum ButtonType {
  Accept = 'Accept',
  Reject = 'Reject',
}

const HeaderContent: React.FC<{
  data: TicketHeaderResponse
}> = ({
  data: { ticketNumber, state, resolutionCode, resolutionNotes, sysUpdatedOn },
}) => {
  const [isAcceptMutationSuccessful, setIsAcceptMutationSuccessful] =
    useState(false)
  const [isRejectMutationSuccessful, setIsRejectMutationSuccessful] =
    useState(false)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer,
    },
  } = useContext(Context)

  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false)
  const { resolutionCodeEnum, stateEnum } = useTicketsContext()

  const {
    sysId,
    draftTicketFields,
    resetDraftTicketFields,
    isSubmissionLoading,
    submitDraftTicketFields,
    hasValidChangesToSave,
    isEditable,
    submissionError,
    isMutationSuccessful,
  } = useTicketEditContext()

  const showCustomerAcceptRejectOptions =
    state === TicketStateEnum.Resolved && !isDWEmployee

  const resolvedStates: string[] = [
    TicketStateEnum.Closed,
    TicketStateEnum.Resolved,
    TicketStateEnum.Cancelled,
  ]

  const showResolveButtonStates = stateEnum
    .filter((stateEnumVal) => !resolvedStates.includes(stateEnumVal.label))
    .map((enumVal) => enumVal.label)

  const [
    updateTicket,
    { loading: isStatusUpdateLoading, error: statusUpdateError },
  ] = useMutation<{ updateTicket: boolean }, UpdateTicketVariables>(
    UPDATE_TICKET,
    {
      fetchPolicy: 'network-only',
      refetchQueries: [
        {
          query: GET_TICKET_HEADER,
          variables: {
            ticketId: sysId,
            selectedCustomer:
              selectedCustomer || dwExpertsCustomer.customerShortName,
          },
        },
      ],
    },
  )

  const handleSave = async () => {
    try {
      await submitDraftTicketFields()
    } catch (error) {
      captureException(error)
      // TODO: Figure out how we'll handle errors in the UI
    }
  }

  const handleResolutionClick = () => {
    setIsResolutionModalOpen(true)
  }

  const handleStatusClick = async (
    state: TicketStateEnum,
    type?: ButtonType,
  ) => {
    await updateTicket({
      variables: {
        input: {
          ticketId: sysId,
          ticketData: {
            state,
            ...(state === TicketStateEnum.Closed && {
              resolutionCode,
              resolutionNotes,
            }),
          },
        },
      },
      onCompleted: () => {
        if (type === ButtonType.Accept) {
          setIsAcceptMutationSuccessful(true)
        } else if (type === ButtonType.Reject) {
          setIsRejectMutationSuccessful(true)
        }

        setTimeout(() => {
          updateTicketInCache({
            ticketId: sysId,
            newFields: {
              state,
            },
          })
        }, 2000)
      },
    })
  }

  const handleClick = showResolveButtonStates.includes(state)
    ? handleResolutionClick
    : handleStatusClick

  const getButtons = () => {
    let buttonLabel: string | null = null

    if (showResolveButtonStates.includes(state)) {
      buttonLabel = 'Resolve case'
    } else if (state === TicketStateEnum.Resolved && isDWEmployee) {
      buttonLabel = 'Close case'
    }

    if (showCustomerAcceptRejectOptions) {
      return (
        <>
          <ProgressButton
            disabled={!isEditable}
            defaultText="Accept resolution & close"
            progressText="Accepting"
            successText="Accepted"
            loading={isStatusUpdateLoading}
            error={statusUpdateError}
            success={isAcceptMutationSuccessful}
            onClick={() =>
              handleClick(TicketStateEnum.Closed, ButtonType.Accept)
            }
          />
          <ProgressButton
            disabled={!isEditable}
            defaultText="Reject resolution"
            progressText="Rejecting"
            successText="Rejected"
            loading={isStatusUpdateLoading}
            error={statusUpdateError}
            success={isRejectMutationSuccessful}
            onClick={() =>
              handleClick(TicketStateEnum.InProgressDW, ButtonType.Reject)
            }
          />
        </>
      )
    } else if (buttonLabel) {
      return (
        <Button
          disabled={!isEditable}
          onClick={() => handleClick(TicketStateEnum.Closed)}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      )
    }
  }

  const areButtonsDisabled =
    !hasValidChangesToSave || isSubmissionLoading || !isEditable

  return (
    <Box
      data-testid="ticket-edit-header"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.secondary.light,
        borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
        width: '100%',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
        }),
      })}
    >
      <Box>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
          <Icon
            variant="skull"
            size={20}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          />
          <Typography
            color="textSecondary"
            fontWeight={500}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body1"
          >
            {ticketNumber}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="caption"
          >
            Last updated:&nbsp;
          </Typography>
          <Typography
            fontWeight={600}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="caption"
          >
            {format(new Date(sysUpdatedOn), TICKET_COMMENT_TIME_FORMAT)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ alignItems: 'center', display: 'flex', gap: '1rem' }}>
        {getButtons()}
        {state !== TicketStateEnum.Closed && (
          <ProgressButton
            defaultText="Save"
            successText="Saved"
            disabled={areButtonsDisabled}
            onClick={handleSave}
            variant="outlined"
            loading={isSubmissionLoading}
            error={submissionError}
            success={isMutationSuccessful}
          />
        )}
      </Box>

      {submissionError && (
        <InlineMessage
          variant={InlineMessageType.ERROR}
          title="Internal server error"
          message="Ticket is unable to be updated. Please contact your system administrator for assistance."
        />
      )}
      <Dialog
        title="Resolution details"
        isOpen={isResolutionModalOpen}
        onClose={() => setIsResolutionModalOpen(false)}
      >
        <TicketResolutionForm
          closeModal={() => setIsResolutionModalOpen(false)}
          resolutionCodeEnum={resolutionCodeEnum}
          sysId={sysId}
          ticketNumber={ticketNumber}
          draftTicketFields={draftTicketFields}
          resetDraftTicketFields={resetDraftTicketFields}
        />
      </Dialog>
    </Box>
  )
}

const Header: React.FC = () => {
  return (
    <TicketDataHandler<TicketHeaderResponse>
      graphqlQuery={GET_TICKET_HEADER}
      renderContent={(data) => <HeaderContent data={data} />}
    />
  )
}

export default Header
