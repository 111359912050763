import { format } from 'date-fns'
import { Avatar, Box, Typography } from '@mui/material'

import { TicketEditEnum } from '@models/index'
import { useAvatarInitials } from '@hooks/useAvatarInitials'

export const setPTagsAndLabels = (key: string): string => {
  if (!key) {
    return ''
  } else if (key.toLowerCase() === 'state') {
    return 'Status'
  } else {
    const lowerCaseString = key
      .split(/(?=[A-Z])/)
      .map((s) => s.toLowerCase())
      .join(' ')

    const formattedPTag =
      lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)

    return formattedPTag
  }
}

export const formatCreatedDate = (date: Date): string => {
  return format(date, 'MMM dd, yyyy hh:mm aaa')
}

export const findEnumLabelAndNumber = (
  label: string | boolean | null,
  enumValues: TicketEditEnum[],
): TicketEditEnum | null => {
  return (
    enumValues.find((enumValue: TicketEditEnum) => enumValue.label === label) ||
    null
  )
}

export const returnUserAvatarOrString = (key: string, val: string, user?) => {
  const avatarInitials = useAvatarInitials
  if (key === 'assignedUser') {
    return (
      <Box
        sx={{
          paddingTop: '0.5rem',
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        {user && (
          <Avatar variant={user.type}>
            {avatarInitials({
              firstName: user.firstName,
              lastName: user.lastName,
            })}
          </Avatar>
        )}
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
        >
          {user ? [user.firstName, user.lastName].join(' ') : '--'}
        </Typography>
      </Box>
    )
  } else {
    let text = val || '--'

    if (key === 'sysCreatedOn') {
      text = formatCreatedDate(new Date(val))
    }

    return (
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {text}
      </Typography>
    )
  }
}

// Use this function in `.sort` to match the order of a given array

export const sortKeysToMatch = (
  aKey: string,
  bKey: string,
  order: string[],
): number => {
  const aIndex = order.indexOf(aKey)
  const bIndex = order.indexOf(bKey)

  return aIndex - bIndex
}

// This function sorts ticket edit enums by their sequence number.

export const sortSequenceEnums = (
  a: TicketEditEnum,
  b: TicketEditEnum,
): number => {
  if (!a.sequence || !b.sequence) {
    return a.label.localeCompare(b.label)
  }

  return a.sequence - b.sequence
}
