import { useContext, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import { PluggableList } from 'react-markdown/lib'
import {
  Box,
  IconButton,
  keyframes,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'

import fetchErrorIcon from '@app-assets/fetch-error.svg'
import {
  CREATE_THREAT_INTEL_READ_RECORD,
  CreateThreatIntelReadRecordData,
  CreateThreatIntelReadRecordVariables,
} from '@mutations/threatIntel'
import {
  GET_THREAT_INTEL_REPORT_QUERY,
  GetThreatIntelReportData,
} from '@queries/threatIntel'
import { toKebabCase } from '@utils/toKebabCase'
import { ThreatIntelReportLabel } from '@models/ThreatIntel'
import { Paths } from '@components/App/Types'
import { Context } from '@components/App'
import { ComponentError } from '@common/ComponentError'
import { formatStatus } from '@components/Coverage/DetectionCatalog/DetectionCatalog.utils'
import { DetectionCatalogStatusTag } from '@components/Coverage/DetectionCatalog/DetectionCatalogStatusTag'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import CommonTableRow from '@common/Table/components/CommonTableRow'
import { StyledTableHeader } from '@common/Table/styled/StyledTableHeader'

import TIFRTableOfContents from './TOC/TIFRTableOfContents'
import FullReportHeader from './Header/FullReportHeader'
import { SectionHeadingBuilder } from './SectionHeadingBuilder'
import { ThreatIntelFeedback } from './Feedback/ThreatIntelFeedback'
import ThreatIntelFullReportCodeBlock from './Content/ThreatIntelFullReportCodeBlock'
import { ThreatIntelTag } from '../ThreatIntelTag'
import ThreatIntelDetectionSideSheet from './ThreatIntelDetectionSideSheet'
import TIFooterBackground from './TIFooterBackground'

const ReportContentFadeIn = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(1rem)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

const FeedbackFadeIn = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(2rem)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

export const getActiveSection = (
  container: Element,
  anchors: NodeListOf<Element>,
  topOffset?: number,
) => {
  const scrollTop = container.scrollTop
  const headerOffset = topOffset ?? 0

  for (let i = 0; i <= anchors.length - 1; i++) {
    const anchorItem: HTMLDivElement = anchors.item(i) as HTMLDivElement

    if (
      scrollTop + headerOffset <
      anchorItem.offsetTop + anchorItem.offsetHeight * 0.9
    ) {
      return anchorItem.id
    }
  }

  return anchors.item(anchors.length - 1).id
}

const ThreatIntelFullReport = () => {
  const {
    state: {
      customer: { customerShortName },
      dwExpertsCustomer: { customerShortName: dwCustomerShortName }, // will only populate if DW user
    },
  } = useContext(Context)

  const { reportId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [clipboardMessage, setClipboardMessage] = useState(false)
  const [selectedUseCase, setSelectedUseCase] = useState<string | undefined>(
    undefined,
  )
  const [showDetectionSidesheet, setShowDetectionSidesheet] = useState(false)

  const sectionContainerRef = useRef<HTMLDivElement | null>(null)

  const [createReadStatus] = useMutation<
    CreateThreatIntelReadRecordData,
    CreateThreatIntelReadRecordVariables
  >(CREATE_THREAT_INTEL_READ_RECORD)

  const {
    data: { getThreatIntelReport: threatIntelReport } = {
      getThreatIntelReport: undefined,
    },
    loading,
    error,
  } = useQuery<GetThreatIntelReportData>(GET_THREAT_INTEL_REPORT_QUERY, {
    variables: {
      fetchPolicy: 'cache-and-network',
      reportId,
      selectedCustomer: dwCustomerShortName,
    },
    onCompleted: async (data) => {
      if (!data?.getThreatIntelReport.markAsRead) {
        await createReadStatus({
          variables: {
            selectedCustomer: dwCustomerShortName,
            input: { threatIntelReportId: data.getThreatIntelReport.id },
          },
        })
      }
    },
    skip: !dwCustomerShortName && !customerShortName,
  })

  const filteredSections = threatIntelReport?.sections.filter(
    (section) => section.heading !== 'Relevant Detections',
  )

  const displayClipboardMessage = () => {
    if (!clipboardMessage) {
      setClipboardMessage(true)
      setTimeout(() => {
        setClipboardMessage(false)
      }, 3000)
    }
  }

  const onLabelClick = (label: ThreatIntelReportLabel) => {
    const nextParams = new URLSearchParams(location.state?.searchParams)
    const labelType = `${label.type}_label`

    if (!nextParams.has(labelType, label.name)) {
      nextParams.append(labelType, label.name)
    }

    if (dwCustomerShortName) {
      nextParams.set('customer', dwCustomerShortName)
    }

    return navigate(`${Paths.THREAT_INTEL_REPORTS}?${nextParams.toString()}`)
  }

  const getToCSectionHeadings = () => {
    const sectionHeadings = cloneDeep(filteredSections)

    if ((threatIntelReport?.associatedDetections.length ?? 0) > 0) {
      sectionHeadings?.push({
        heading: 'Relevant Detections',
        level: 2,
        content: '',
        originalMarkdown: '',
      })
    }

    return sectionHeadings
  }

  if (
    (loading && !threatIntelReport) ||
    (!dwCustomerShortName && !customerShortName)
  ) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Loader size={80} strokeWidth={1} />
      </Box>
    )
  }

  if (error) {
    return (
      <ComponentError
        errorSubText="Check your connection and reload the page. If the problem persists, contact our support team for assistance."
        includeReloadButton={true}
        errorIcon={fetchErrorIcon}
      />
    )
  }

  return (
    <Box sx={{ overflow: 'auto', width: '100%' }}>
      <ThreatIntelDetectionSideSheet
        closeSidesheet={() => setShowDetectionSidesheet(false)}
        isOpen={showDetectionSidesheet}
        useCase={selectedUseCase}
      />

      <Box
        data-testid="threat-intel-full-reports"
        sx={{
          display: 'grid',
          gridTemplateColumns: '75% 25%',
        }}
      >
        {threatIntelReport && (
          <FullReportHeader
            type={threatIntelReport?.type}
            title={threatIntelReport?.title}
            source={threatIntelReport?.source}
            createdDate={threatIntelReport?.createdDate}
            sourceMaterial={threatIntelReport?.sourceMaterial}
          />
        )}
        <>
          <Box
            data-testid="report-content"
            ref={sectionContainerRef}
            sx={{
              animation: `1s ${ReportContentFadeIn}`,
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              gridRow: 2,
              padding: '1.5rem',
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
              <Box
                data-testid="report-labels"
                sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem' }}
              >
                {threatIntelReport?.labels.map((label) => {
                  return (
                    <ThreatIntelTag
                      key={`${label.type}-${label.name}`}
                      text={label.name.toUpperCase()}
                      onClickCallback={() => onLabelClick(label)}
                    />
                  )
                })}
              </Box>

              <Box sx={{ position: 'relative' }}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href)
                    displayClipboardMessage()
                  }}
                  data-testid="share-link-button"
                >
                  <Icon variant="link" />
                </IconButton>

                {clipboardMessage && (
                  <Box
                    sx={(theme) => ({
                      alignItems: 'center',
                      border: `1px solid ${theme.vars.palette.secondary.main}`,
                      borderRadius: '5px',
                      display: 'flex',
                      padding: '0.25rem',
                      position: 'absolute',
                      left: '-6px',
                      backgroundColor: theme.vars.palette.text.secondary,
                      ...theme.applyStyles('dark', {
                        backgroundColor: theme.vars.palette.secondary.lighter,
                      }),
                    })}
                  >
                    <Typography variant="caption">Copied!</Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {filteredSections?.map((section, index) => {
              const sectionId = `section-${toKebabCase(section.heading)}`
              return (
                <Box
                  key={`${section.heading}-${section.level}`}
                  data-testid={`section-${index}`}
                  id={sectionId}
                >
                  {SectionHeadingBuilder(section.heading, section.level)}

                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw] as PluggableList} // https://github.com/orgs/rehypejs/discussions/63
                    components={{
                      a(props) {
                        const { href, children } = props

                        return (
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={href}
                            variant="body2"
                            sx={{ wordWrap: 'break-word' }}
                          >
                            {children}
                          </Link>
                        )
                      },
                      code(props) {
                        const { className, children } = props

                        const match = /language-(\w+)/.exec(className || '')
                        const language = match?.at(1)

                        return (
                          <ThreatIntelFullReportCodeBlock language={language}>
                            {children}
                          </ThreatIntelFullReportCodeBlock>
                        )
                      },
                      li(props) {
                        return (
                          <Typography
                            sx={(theme) => ({
                              color: theme.vars.palette.text.primary,
                              display: 'list-item',
                              padding: '0 0.5rem',
                              ...theme.applyStyles('dark', {
                                color: theme.vars.palette.text.secondary,
                              }),
                            })}
                            variant="body2"
                          >
                            {props.children}
                          </Typography>
                        )
                      },
                      ol(props) {
                        return (
                          <List component="ul" sx={{ listStyle: 'decimal' }}>
                            {props.children}
                          </List>
                        )
                      },
                      p(props) {
                        return (
                          <Typography
                            sx={(theme) => ({
                              color: theme.vars.palette.text.primary,
                              padding: '0.25rem 0',
                              ...theme.applyStyles('dark', {
                                color: theme.vars.palette.text.secondary,
                              }),
                            })}
                            variant="body2"
                          >
                            {props.children}
                          </Typography>
                        )
                      },
                      table(props) {
                        return <CommonTable>{props.children}</CommonTable>
                      },
                      td(props) {
                        return (
                          <StyledTableCell>{props.children}</StyledTableCell>
                        )
                      },
                      th(props) {
                        return (
                          <StyledTableHeader>
                            <Typography
                              color="textSecondary"
                              fontWeight={400}
                              variant="caption"
                            >
                              {props.children}
                            </Typography>
                          </StyledTableHeader>
                        )
                      },
                      tr(props) {
                        return (
                          <CommonTableRow disableCursor isSelected={false}>
                            {props.children}
                          </CommonTableRow>
                        )
                      },
                      ul(props) {
                        return (
                          <List component="ul" sx={{ listStyle: 'inside' }}>
                            {props.children}
                          </List>
                        )
                      },
                    }}
                  >
                    {section.content}
                  </ReactMarkdown>
                </Box>
              )
            })}

            {(threatIntelReport?.associatedDetections.length ?? 0) > 0 && (
              <Box
                data-testid="relevant-detections-section"
                id="section-relevant-detections"
              >
                <Typography
                  color="textPrimary"
                  variant="h6"
                  sx={{ paddingBottom: 1.5 }}
                >
                  Relevant Detections
                </Typography>
                <List>
                  {threatIntelReport?.associatedDetections.map((detection) => {
                    const status = formatStatus(detection.status)
                    return (
                      <ListItem
                        key={detection.title}
                        onClick={() => {
                          setSelectedUseCase(detection.useCase)
                          setShowDetectionSidesheet(true)
                        }}
                        onKeyUp={(event) =>
                          handleKeyboardAction(event, () => {
                            setSelectedUseCase(detection.useCase)
                            setShowDetectionSidesheet(true)
                          })
                        }
                      >
                        <ListItemText primary={detection.title} />
                        <ListItemText
                          sx={{
                            textAlign: 'right',
                            margin: 'auto 10px auto auto',
                            '.MuiTypography-h5': {
                              fontSize: '0.75rem',
                            },
                          }}
                        >
                          <DetectionCatalogStatusTag status={status.status} />
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              animation: `700ms 250ms both ${ReportContentFadeIn}`,
            }}
          >
            <TIFRTableOfContents
              threatIntelReportSections={getToCSectionHeadings()}
            />

            <Box
              sx={{
                animation: `2s 1.5s both ${FeedbackFadeIn}`,
              }}
            >
              <ThreatIntelFeedback
                customerShortName={dwCustomerShortName}
                reportId={reportId as string}
              />
            </Box>
          </Box>
        </>

        {threatIntelReport && (
          <Box
            sx={{
              gridColumn: '1 / 3',
              minHeight: '230px',
              position: 'relative',
            }}
          >
            <TIFooterBackground
              data-testid="report-footer"
              sx={(theme) => ({
                color: theme.vars.palette.primary.dark,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              })}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ThreatIntelFullReport
