import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { User, OktaGroup } from '@models/index'
import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'
import { useToast, AlertSeverity } from '@hooks/useToast'
import { CREATE_USER, CreateUserVariables } from '@mutations/user'

import AddEditUserForm, {
  AddEditUserFormValues,
} from '../AddEditUserForm/AddEditUserForm'
import { ModalTitles } from '../UserManagementSettings'
import { mapToListItems } from '../UserManagementSettings.util'

interface AddEditUserModalProps {
  user?: User
  isLastAdmin: boolean
  customerGroups: OktaGroup[]
  title: ModalTitles
  clearUser: () => void
  userGroups?: Array<OktaGroup>
  closeModal: VoidFunction
}

const AddUserModal = ({
  user,
  isLastAdmin,
  customerGroups,
  title,
  clearUser,
  closeModal,
  userGroups,
}: AddEditUserModalProps) => {
  const { state } = useContext(Context)
  const { handleShowToast } = useToast()

  const isEditingSelf = state.user.id === user?.id
  const isFederatedCustomer = state.customer.settings?.isOktaFederated

  const adminGroupID = customerGroups.find((group) =>
    group.groupName.includes('admins'),
  )?.groupId as string

  const initialGroups = userGroups ? mapToListItems(userGroups) : []

  const refetchQueries = [GET_USER_MANAGEMENT_SETTINGS]

  const [createUser] = useMutation<CreateUserVariables>(CREATE_USER, {
    refetchQueries,
  })
  const handleAddUser = async (
    values: AddEditUserFormValues,
  ): Promise<void> => {
    closeModal()
    clearUser()
    handleShowToast(AlertSeverity.Info, 'Processing request to create user')

    if (values.isAdmin) {
      values.groupIDs = [...values.groupIDs, adminGroupID]
    }

    await createUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          title: values.title,
          initialGroupIds: values.groupIDs,
        },
      },
      onCompleted: () => {
        handleShowToast(AlertSeverity.Success, 'User created')
      },
      onError: (e) => {
        handleShowToast(AlertSeverity.Error, e.toString())
      },
    })
  }
  return (
    <Box
      sx={{
        padding: '24px 22px',
        maxWidth: '500px',
      }}
      id="add-edit-user-modal"
      data-testid="add-edit-user-modal"
    >
      <AddEditUserForm
        customerGroups={customerGroups}
        initialGroups={initialGroups}
        isEditingSelf={isEditingSelf}
        isFederatedCustomer={isFederatedCustomer ? isFederatedCustomer : false}
        isLastAdmin={isLastAdmin}
        modalState={title}
        onCancelButtonClick={() => closeModal()}
        onSubmit={handleAddUser}
        user={user}
      />
    </Box>
  )
}

export default AddUserModal
