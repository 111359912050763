export const ModuleCardBodyAvailableText = {
  MDR: {
    description:
      'Enhance your cyber resilience with the Deepwatch comprehensive MDR solution for Splunk. Our proactive approach to threat detection and incident support helps your organization stay one step ahead of cyber adversaries, safeguarding your critical assets around the clock.',
    supportedTechnologies: [
      'Splunk® Enterprise',
      'Splunk® Cloud, hosted by Splunk®',
      'Microsoft® Sentinel with Microsoft® Lighthouse, hosted by Microsoft® Azure',
    ],
    keyFeatures: [
      '24/7 Threat Monitoring: Continuous surveillance of your environment to detect and mitigate threats in real-time.',
      'Incident Support: Swift action to contain and neutralize threats, minimizing potential damage and downtime.',
      'Threat Intelligence: Leverages global threat intelligence to stay ahead of emerging cyber threats and vulnerabilities.',
      'Threat Hunting: Platform-wide hypothesis based intel driven threat hunts on a recurring basis. These hunts are designed to find the attack methodologies adversaries are currently utilizing to breach environments.',
      'Security & Detection Engineering: Advanced monitoring of data flow and availability notifications.',
      'Data Visibility & Selection: Integration of 1st and 3rd party data selection and ingestion.',
    ],
    dependencies: [],
  },
  NG_MDR: {
    description:
      'Deepwatch NG-MDR is the foundation of a holistic modern security operations center, enhancing cybersecurity and ensuring robust protection and operational efficiency.',
    supportedTechnologies: [
      'CrowdStrike® Next-Gen SIEM, hosted by CrowdStrike®',
    ],
    keyFeatures: [
      'Continuous Global Operations: Deepwatch Experts provide 24x7x365 analysis and response.',
      'Security & Detection Engineering: Advanced monitoring of data flow and availability notifications.',
      'Data Visibility & Selection: Integration of 1st and 3rd party data selection and ingestion.',
      'Migration & Operationalization: Seamless migration from legacy SIEMs to modern solutions.',
      'Expansive Automation and Enrichment: Extensive automation and data enrichment techniques.',
    ],
    dependencies: [],
  },
  MEDR: {
    description:
      'The Deepwatch MEDR solution is designed to enhance and advise on endpoint security practices and technology deployment.',
    supportedTechnologies: ['SentinelOne®, hosted by SentinelOne®'],
    keyFeatures: [
      'Data Protection: Protect the most sensitive data on the endpoint and in the cloud.',
      'Comprehensive Visibility: Extensive visibility with best-in-class endpoint management.',
      'Prioritized Threats & Early Threat Detection: Endpoint event prioritization that uses correlated threat intelligence and early detection with alert enrichment.',
      'Quick Recovery: Fast, easy recovery from unauthorized changes.',
      'Remote Workforce Security: Remote workforce protection across multiple workstations and mobile devices.',
    ],
    dependencies: ['Active Deepwatch managed detection and response solution.'],
  },
  NG_MEDR: {
    description:
      'Safeguard your endpoints with Deepwatch NG-MEDR, designed to provide robust protection, incident handling, and an evolved Endpoint Detection and Response program, ensuring your endpoint devices remain secure and resilient against evolving cyber threats.',
    supportedTechnologies: [
      'CrowdStrike Falcon® Defend with Threat Graph, hosted by CrowdStrike®',
    ],
    keyFeatures: [
      'Enhanced Endpoint Insights: Provides deep insights through  EDR agents.',
      'Higher Fidelity Signals: Uses high-fidelity EDR signals for precise threat detection.',
      'Augmented Data Telemetry: Enhances EDR data with log telemetry from various sources.',
      'Contextual Enrichment: Enriches detections with log data, reducing triage time, false positives, and accelerating MTTR.',
      'Active Response: Active / Automated Response for endpoint-based threats.',
    ],
    dependencies: ['Active Deepwatch MDR or NG-MDR solution.'],
  },
  MFW: {
    description:
      "Fortify your network with the Deepwatch MFW solution, which protects your organization's critical assets against evolving cyber threats, allowing you to focus on your core business operations.",
    supportedTechnologies: [
      'Fortinet FortiGate®, hosted by Fortinet®',
      'Palo Alto® NGFW, hosted by Palo Alto®',
    ],
    keyFeatures: [
      'Governance: Collaborate to establish governance rules, schedule maintenance, and integrate into your change management process.',
      'Device Monitoring: 24/7 monitoring to ensure device health and quick response to critical issues.',
      'Configuration & Rule Set Changes: Manage software updates, configuration changes, and emergency rule adjustments with prioritized requests.',
      'Troubleshooting & Vendor Support: Deepwatch handles connectivity issues and vendor interactions.',
      'Environment Reviews & Assessments: Initial and ongoing reviews to optimize performance, policies, and security, with regular assessments.',
    ],
    dependencies: ['Active Deepwatch MDR or NG-MDR solution.'],
  },
  NG_MFW: {
    description:
      'The Deepwatch NG-MFW solution offers specialized expertise in detecting and responding to network threats and provides continuous threat monitoring, policy management, and rapid response to cyber threats on your network.',
    supportedTechnologies: [
      'Fortinet FortiGate®, hosted by Fortinet®',
      'Palo Alto® NGFW, hosted by Palo Alto®',
    ],
    keyFeatures: [
      'Detection Management: Leverages first-party alerts and continuously updates detection capabilities.',
      'Console Management: Handles policy creation, network object management, threat prevention features, and more, with routine health checks.',
      'User Account Management: Assists in managing user accounts within the firewall platform.',
      'Network Policy & Threat Profile Management: Configures policies based on best practices, with flexibility to implement changes during maintenance windows.',
      'Procedural Governance & Maintenance Coordination: Develops governance procedures and coordinates maintenance, ensuring alignment with change management processes.',
    ],
    dependencies: ['Active Deepwatch MDR or NG-MDR solution.'],
  },
  TS: {
    description:
      'The Deepwatch Threat Signal solution proactively addresses both external and internal risks using strategic attacker methodologies and employs advanced forensic threat hunting to keep your organization ahead of cyber threats.',
    supportedTechnologies: [],
    keyFeatures: [
      'Attack Surface Profiling: Identify high-risk internal and external areas that attackers could exploit, with actionable reports and mitigation recommendations.',
      'Forensic-Agent-Based Threat Hunting: Deepwatch collaborates with your team to install forensic agents and provides detailed threat hunt reports based on the engagement.',
      'Comprehensive Reporting & Reviews: Receive regular intelligence briefs, summary presentations, and executive reviews to stay informed on threat landscapes and engagement status.',
      'Malware Analysis: Deepwatch’s expert team analyzes collected malware, delivering in-depth reports on findings and recommended actions.',
    ],
    dependencies: [
      'Forensic Agent Installation: Essential for gaining access to critical artifacts, with up to 5,000 agents deployable during a threat hunt.',
      'Active Deepwatch MDR or NG-MDR solution.',
    ],
  },
  VM: {
    description:
      'The Deepwatch VM solution provides clear visibility of your threat landscape, helps your team focus on the vulnerabilities most likely to be targeted, and assists with patching and remediation.',
    supportedTechnologies: [
      'Tenable Vulnerability Management fka Tenable.io, hosted by Tenable®',
    ],
    keyFeatures: [
      'Vulnerability program management: Fully managed end-to-end vulnerability management program development and administration.',
      'Asset identification: Complete asset visibility.',
      'Asset profiling: Assets profiled by function, services, environment, lifecycle and more.',
      'Risk-based remediation: Vulnerability and patch remediation efforts prioritization by customer-specific risk-based scoring.',
      'Remediation guidance: Assistance to infrastructure, application, or business owners for remediation of vulnerabilities.',
    ],
    dependencies: ['Active Deepwatch MDR or NG-MDR solution.'],
  },
  NG_VEM: {
    description:
      'Deepwatch NG-VEM offers real-time assessment, management, and prioritization of vulnerabilities, simplifying patch management, compliance, and reporting for strong cyber outcomes.',
    supportedTechnologies: [
      'CrowdStrike Falcon® Exposure Management, hosted by CrowdStrike® with Tenable.io, hosted by Tenable® that includes a  license to either Falcon Complete, Falcon Defend (Insight), or Falcon Cloud Security',
    ],
    keyFeatures: [
      'Real-Time Vulnerability Assessment: Instantly assess vulnerabilities without lengthy scans.',
      'Behavioral Classification and Passive Discovery: Monitor and classify assets based on behavior.',
      'Compliance Verification: Automatically check device configurations against standards.',
      'Guided Remediation and Mitigation Strategies: Receive tailored guidance on addressing vulnerabilities.',
      'Exposure Identification: Quickly identify internet-exposed infrastructure for faster risk mitigation.',
    ],
    dependencies: ['Active Deepwatch MDR or NG-MDR solution.'],
  },
}
