import { DetectionStatus } from './DetectionCatalog/DetectionCatalogStatusTag'

export enum DeprecatedDetectionCatalogStatus {
  Enabled = 'Enabled',
  Available = 'Available',
  Unavailable = 'Unavailable',
  // Recommended = 'Recommended', we don't have recommendations yet
}

export enum DetectionStatusType {
  BLOCKED = 'Blocked',
  ELIGIBLE = 'Eligible',
  ENABLED = 'Enabled',
  NOT_APPLICABLE = 'Not Applicable',
  PENDING_REVIEW = 'Pending Review',
  TRIAL = 'Trial Mode',
  UPDATE_REQUIRED = 'Update Required',
}

export const lowerCaseDetectionStatus: Record<
  DetectionStatusType,
  DetectionStatus
> = {
  [DetectionStatusType.BLOCKED]: 'blocked',
  [DetectionStatusType.ELIGIBLE]: 'eligible',
  [DetectionStatusType.ENABLED]: 'enabled',
  [DetectionStatusType.NOT_APPLICABLE]: 'not applicable',
  [DetectionStatusType.PENDING_REVIEW]: 'pending review',
  [DetectionStatusType.TRIAL]: 'trial mode',
  [DetectionStatusType.UPDATE_REQUIRED]: 'update required',
}
