import { ApolloClient, concat, createHttpLink } from '@apollo/client'

import awsAppSync from '../AwsAppSyncConfig'
import { awsAuthLink, headerMiddleware } from './middleware'
import inMemoryCache from './InMemoryCache'

const url = awsAppSync.aws_appsync_graphqlEndpoint
const httpLink = createHttpLink({ uri: url })

const apolloClient = new ApolloClient({
  cache: inMemoryCache,
  connectToDevTools: process.env.ENV === 'local',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
  link: concat(headerMiddleware, awsAuthLink.concat(httpLink)),
})

export default apolloClient
