import { Box, SxProps, Theme } from '@mui/material'

interface CustomerAdvisoryBackgroundProps {
  sx?: SxProps<Theme>
}

const CustomerAdvisoryBackground: React.FC<CustomerAdvisoryBackgroundProps> = ({
  sx,
}) => (
  <Box
    component="svg"
    sx={Array.isArray(sx) ? sx : [sx]}
    viewBox="0 0 1180 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.25">
      <mask
        id="mask0_24307_57131"
        maskUnits="userSpaceOnUse"
        x="-496"
        y="-105"
        width="1781"
        height="432"
      >
        <path
          d="M1283.88 30.6647C1297.7 141.644 1015.62 268.156 653.833 313.236C292.042 358.317 -481.172 284.48 -495 173.501C-508.828 62.5215 241.965 -43.5754 603.756 -88.6559C965.548 -133.736 1270.05 -80.3148 1283.88 30.6647Z"
          fill="url(#paint0_radial_24307_57131)"
        />
      </mask>
      <g mask="url(#mask0_24307_57131)">
        <path
          d="M1849.23 1170.78C1842.73 1181.08 1831.21 1187.93 1815.13 1191.49C1799.04 1195.04 1778.45 1195.29 1753.89 1192.42C1704.77 1186.69 1639.89 1168.52 1563.75 1139.61C1411.49 1081.79 1214.28 981.028 1008.23 851.04C802.176 721.052 626.313 586.458 508.554 473.936C449.671 417.673 405.332 366.944 379.007 325.08C365.844 304.148 357.198 285.453 353.48 269.407C349.763 253.363 350.982 240.011 357.482 229.707C363.983 219.402 375.508 212.552 391.589 208.998C407.672 205.443 428.268 205.195 452.829 208.061C501.948 213.792 566.826 231.963 642.963 260.876C795.229 318.7 992.437 419.457 1198.49 549.445C1404.54 679.433 1580.4 814.027 1698.16 926.549C1757.05 982.813 1801.38 1033.54 1827.71 1075.41C1840.87 1096.34 1849.52 1115.03 1853.24 1131.08C1856.95 1147.12 1855.74 1160.47 1849.23 1170.78Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1496.71 522.042C1674.87 634.431 1822.68 757.519 1917.63 865.66C1965.12 919.733 1999.36 970.049 2017.59 1013.41C2035.83 1056.78 2038 1093.09 2021.5 1119.24C2005 1145.4 1971.29 1159.08 1924.3 1161.3C1877.31 1163.52 1817.15 1154.28 1747.9 1134.71C1609.41 1095.58 1434.67 1015.19 1256.51 902.798C1078.36 790.409 930.551 667.321 835.594 559.18C788.114 505.107 753.865 454.791 735.635 411.433C717.399 368.061 715.232 331.753 731.733 305.597C748.234 279.44 781.936 265.76 828.933 263.54C875.915 261.321 936.076 270.563 1005.32 290.129C1143.82 329.26 1318.56 409.653 1496.71 522.042Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1344.58 94.6057C1417.15 140.388 1452.75 229.687 1451.54 337.166C1450.33 444.638 1412.31 570.212 1337.76 688.399C1263.2 806.587 1166.23 894.975 1069.76 942.349C973.279 989.726 877.356 996.056 804.784 950.274C732.211 904.492 696.615 815.193 697.825 707.713C699.035 600.242 737.051 474.668 811.61 356.48C886.168 238.293 983.131 149.904 1079.6 102.531C1176.09 55.1533 1272.01 48.8235 1344.58 94.6057Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1327.98 1338.46C1293.12 1337 1250.49 1317.91 1203.17 1284.52C1155.85 1251.13 1103.91 1203.49 1050.51 1145.03C943.722 1028.13 831.187 868.068 738.237 692.565C645.287 517.062 589.539 359.38 574.534 246.32C567.031 189.788 569.718 144.433 583.016 113.584C596.314 82.7322 620.204 66.4296 655.056 67.8892C689.907 69.3489 732.541 88.4376 779.863 121.828C827.182 155.216 879.123 202.859 932.519 261.313C1039.31 378.217 1151.84 538.278 1244.79 713.78C1337.74 889.283 1393.49 1046.97 1408.5 1160.03C1416 1216.56 1413.31 1261.91 1400.01 1292.76C1386.72 1323.61 1362.83 1339.92 1327.98 1338.46Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1722.66 1275.57C1711.71 1282.6 1696.49 1285.58 1677.46 1284.75C1658.43 1283.93 1635.64 1279.3 1609.61 1271.16C1557.55 1254.88 1492.66 1224.59 1419.28 1182.75C1272.52 1099.06 1091.88 969.222 912.152 813.063C732.427 656.905 587.667 504.01 498.907 383.056C454.525 322.577 424.158 270.101 410.414 229.205C403.541 208.757 400.832 191.224 402.589 177.043C404.346 162.864 410.563 152.063 421.51 145.029C432.456 137.995 447.679 135.019 466.705 135.844C485.733 136.67 508.528 141.297 534.557 149.436C586.613 165.714 651.504 196.006 724.888 237.85C871.649 321.535 1052.29 451.373 1232.01 607.532C1411.74 763.691 1556.5 916.585 1645.26 1037.54C1689.64 1098.02 1720.01 1150.49 1733.75 1191.39C1740.62 1211.84 1743.33 1229.37 1741.58 1243.55C1739.82 1257.73 1733.6 1268.53 1722.66 1275.57Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1570.79 654.777C1726.18 789.793 1844.21 926.56 1909.43 1038.53C1942.04 1094.52 1961.44 1144.28 1965.71 1184.52C1969.99 1224.76 1959.13 1255.4 1931.37 1273.24C1903.61 1291.08 1861.44 1294.51 1808.83 1285.33C1756.23 1276.14 1693.28 1254.36 1624.08 1221.89C1485.68 1156.94 1322.38 1049.26 1166.99 914.246C1011.6 779.231 893.567 642.464 828.348 530.495C795.737 474.508 776.341 424.742 772.067 384.505C767.791 344.261 778.646 313.623 806.409 295.784C834.172 277.945 876.338 274.513 928.95 283.698C981.553 292.88 1044.5 314.661 1113.7 347.136C1252.1 412.085 1415.4 519.762 1570.79 654.777Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1559.06 264.565C1622.34 319.549 1629.01 401.958 1588.44 490.83C1547.89 579.69 1460.14 674.928 1334.81 755.465C1209.47 836.001 1070.31 886.563 946.654 903.219C822.981 919.878 714.933 902.613 651.65 847.629C588.368 792.644 581.701 710.235 622.263 621.364C662.819 532.504 750.563 437.265 875.899 356.729C1001.24 276.192 1140.4 225.63 1264.05 208.974C1387.73 192.315 1495.77 209.58 1559.06 264.565Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M693.834 878.329C507.316 832.114 331.536 760.059 198.126 680.937C131.419 641.376 75.3281 600.061 33.7867 559.345C-7.75361 518.629 -34.7095 478.548 -43.2344 441.417C-51.7593 404.287 -41.0661 373.534 -14.3381 349.734C12.3908 325.933 55.1333 309.108 110.626 299.824C221.608 281.258 383.511 292.874 570.029 339.089C756.548 385.304 932.327 457.359 1065.74 536.48C1132.44 576.041 1188.54 617.356 1230.08 658.073C1271.62 698.788 1298.57 738.869 1307.1 776C1315.62 813.131 1304.93 843.883 1278.2 867.683C1251.47 891.484 1208.73 908.31 1153.24 917.593C1042.26 936.159 880.352 924.544 693.834 878.329Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1086.8 1292.28C1010.89 1273.47 926.513 1188.77 851.432 1066.73C776.376 944.737 710.73 785.597 672.306 618.242C633.883 450.886 629.967 307.043 654.68 209.766C679.401 112.456 732.725 61.8805 808.627 80.6874C884.529 99.4944 968.911 184.19 1043.99 306.229C1119.05 428.226 1184.69 587.365 1223.12 754.721C1261.54 922.076 1265.46 1065.92 1240.74 1163.2C1216.02 1260.51 1162.7 1311.08 1086.8 1292.28Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1544.86 1332.75C1515.49 1339.31 1473.22 1328.3 1421.75 1302.58C1370.31 1276.87 1309.83 1236.52 1244.21 1184.6C1112.96 1080.76 961.199 930.697 820.052 759.014C678.905 587.33 575.115 426.561 521.405 305.424C494.549 244.853 480.223 194.211 479.994 157.074C479.765 119.919 493.644 96.382 523.01 89.8298C552.375 83.2777 594.654 94.2848 646.119 120.008C697.559 145.718 758.036 186.063 823.663 237.983C954.912 341.82 1106.67 491.887 1247.82 663.57C1388.96 835.253 1492.75 996.023 1546.46 1117.16C1573.32 1177.73 1587.65 1228.37 1587.88 1265.51C1588.1 1302.66 1574.23 1326.2 1544.86 1332.75Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M555.619 761.855C366.847 679.755 199.341 578.741 81.1239 481.805C22.0134 433.335 -24.7527 385.902 -55.6935 342.382C-86.6399 298.853 -101.701 259.319 -97.5571 226.58C-93.4126 193.841 -70.4358 170.851 -32.2737 157.378C5.88168 143.909 59.1495 139.982 123.722 145.304C252.863 155.947 427.128 203.574 615.9 285.674C804.673 367.774 972.179 468.788 1090.4 565.724C1149.51 614.194 1196.27 661.627 1227.21 705.148C1258.16 748.676 1273.22 788.21 1269.08 820.949C1264.93 853.688 1241.96 876.679 1203.79 890.151C1165.64 903.62 1112.37 907.547 1047.8 902.225C918.657 891.582 744.392 843.955 555.619 761.855Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M383.053 666.202C290.715 626.043 210.245 565.149 154.786 499.186C99.3199 433.214 68.9241 362.242 76.5682 301.858C84.2123 241.474 128.467 202.969 193.975 189.611C259.476 176.254 346.16 188.058 438.498 228.218C530.836 268.377 611.306 329.27 666.765 395.234C722.231 461.206 752.627 532.178 744.983 592.561C737.338 652.945 693.084 691.45 627.576 704.809C562.075 718.166 475.391 706.361 383.053 666.202Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M837.782 1197.39C760.905 1163.95 698.793 1073.87 660.569 955.078C622.35 836.303 608.054 688.932 626.764 541.13C645.475 393.329 693.643 273.125 756.376 198.255C819.117 123.375 896.364 93.903 973.241 127.338C1050.12 160.773 1112.23 250.854 1150.45 369.645C1188.67 488.42 1202.97 635.791 1184.26 783.593C1165.55 931.394 1117.38 1051.6 1054.65 1126.47C991.905 1201.35 914.659 1230.82 837.782 1197.39Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1915.96 1025.55C1914.33 1038.46 1907.26 1048.72 1895.22 1056.41C1883.17 1064.1 1866.18 1069.21 1844.77 1071.81C1801.94 1077.02 1741.5 1072.21 1667.81 1058.2C1520.42 1030.18 1320.09 965.373 1101.75 870.417C883.421 775.46 688.447 668.342 549.718 571.926C480.35 523.716 425.066 478.195 387.957 438.223C369.402 418.236 355.41 399.656 346.47 382.837C337.533 366.022 333.663 351.002 335.297 338.093C336.931 325.184 344.001 314.922 356.042 307.23C368.084 299.537 385.075 294.432 406.489 291.828C449.317 286.622 509.755 291.43 583.452 305.441C730.84 333.461 931.17 398.267 1149.5 493.223C1367.84 588.18 1562.81 695.298 1701.54 791.714C1770.91 839.925 1826.19 885.445 1863.3 925.417C1881.86 945.404 1895.85 963.984 1904.79 980.803C1913.73 997.618 1917.6 1012.64 1915.96 1025.55Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1832.11 1191.28C1824.83 1201.08 1812.63 1207.31 1795.97 1210.14C1779.32 1212.98 1758.25 1212.4 1733.33 1208.64C1683.48 1201.1 1618.28 1180.8 1542.25 1149.57C1390.18 1087.11 1194.82 980.966 992.215 845.944C789.612 710.923 618.149 572.599 504.712 458.117C447.991 400.873 405.795 349.606 381.463 307.705C369.297 286.753 361.61 268.165 358.797 252.356C355.985 236.549 358.052 223.565 365.33 213.764C372.608 203.963 384.809 197.733 401.464 194.9C418.12 192.066 439.185 192.639 464.111 196.407C513.962 203.943 579.153 224.244 655.191 255.475C807.259 317.935 1002.62 424.076 1205.22 559.098C1407.83 694.12 1579.29 832.443 1692.73 946.926C1749.45 1004.17 1791.64 1055.44 1815.97 1097.34C1828.14 1118.29 1835.83 1136.88 1838.64 1152.69C1841.45 1168.49 1839.39 1181.48 1832.11 1191.28Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1511.71 544.151C1686.89 660.892 1830.38 786.875 1920.78 896.18C1965.99 950.835 1997.9 1001.3 2013.86 1044.34C2029.83 1087.39 2029.81 1122.92 2011.34 1147.8C1992.87 1172.67 1957.58 1184.69 1909.41 1185C1861.25 1185.31 1800.34 1173.91 1730.76 1152.07C1591.62 1108.4 1417.97 1023.03 1242.8 906.286C1067.63 789.545 924.127 663.561 833.726 554.257C788.524 499.602 756.614 449.137 740.649 406.097C724.679 363.043 724.697 327.519 743.171 302.641C761.645 277.763 796.932 265.744 845.102 265.436C893.256 265.128 954.176 276.528 1023.75 298.363C1162.89 342.032 1336.54 427.409 1511.71 544.151Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1382.51 120.938C1453.87 168.493 1484.76 257.059 1476.91 361.916C1469.07 466.765 1422.49 587.827 1339.02 700.235C1255.54 812.642 1151.01 895.079 1049.49 937.502C947.967 979.929 849.539 982.314 778.182 934.759C706.825 887.205 675.936 798.638 683.783 693.781C691.629 588.933 738.207 467.87 821.679 355.463C905.151 243.055 1009.69 160.619 1111.2 118.195C1212.73 75.7679 1311.16 73.3834 1382.51 120.938Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1289.03 1334.31C1253.49 1331.51 1211.08 1311.19 1164.77 1276.73C1118.47 1242.27 1068.31 1193.72 1017.33 1134.55C915.381 1016.23 810.21 855.558 726.005 680.587C641.799 505.616 594.52 349.51 586.138 238.569C581.947 183.096 587.482 138.935 602.97 109.352C618.458 79.7677 643.879 64.7953 679.421 67.599C714.963 70.4028 757.369 90.7258 803.679 125.185C849.987 159.643 900.141 208.197 951.119 267.36C1053.07 385.683 1158.24 546.356 1242.45 721.327C1326.65 896.298 1373.93 1052.4 1382.31 1163.35C1386.51 1218.82 1380.97 1262.98 1365.48 1292.56C1349.99 1322.15 1324.57 1337.12 1289.03 1334.31Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1696.29 1288.55C1684.66 1294.98 1668.92 1297.28 1649.52 1295.73C1630.12 1294.17 1607.11 1288.75 1581.01 1279.78C1528.81 1261.83 1464.35 1229.7 1391.92 1185.96C1247.07 1098.47 1070.43 964.604 896.348 805.08C722.264 645.557 583.715 490.602 500.437 369.038C458.796 308.253 430.987 255.834 419.455 215.376C413.688 195.147 411.997 177.927 414.665 164.155C417.333 150.385 424.352 140.085 435.979 133.649C447.606 127.213 463.354 124.911 482.748 126.468C502.146 128.027 525.159 133.444 551.26 142.417C603.462 160.363 667.921 192.495 740.349 236.238C885.199 323.72 1061.84 457.591 1235.92 617.114C1410.01 776.638 1548.55 931.592 1631.83 1053.16C1673.47 1113.94 1701.28 1166.36 1712.81 1206.82C1718.58 1227.05 1720.27 1244.27 1717.6 1258.04C1714.94 1271.81 1707.92 1282.11 1696.29 1288.55Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1579.27 676.63C1729.78 814.555 1842 952.711 1901.77 1064.57C1931.66 1120.51 1948.42 1169.85 1950.32 1209.29C1952.21 1248.74 1939.23 1278.22 1909.75 1294.53C1880.27 1310.85 1836.96 1312.53 1783.75 1301.49C1730.56 1290.45 1667.56 1266.7 1598.83 1232.26C1461.38 1163.4 1301.1 1051.85 1150.58 913.923C1000.07 775.998 887.848 637.842 828.078 525.978C798.191 470.044 781.429 420.704 779.535 381.263C777.64 341.817 790.617 312.337 820.098 296.018C849.578 279.7 892.893 278.02 946.098 289.065C999.295 300.107 1062.29 323.855 1131.02 358.288C1268.47 427.152 1428.75 538.706 1579.27 676.63Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1591.27 295.303C1652.56 351.467 1654.31 432.162 1607.39 517.305C1560.47 602.434 1464.93 691.923 1331.88 765.575C1198.82 839.227 1053.47 883.075 926.063 894.443C798.632 905.812 689.267 884.686 627.976 828.522C566.686 772.358 564.936 691.663 611.861 606.52C658.778 521.391 754.316 431.902 887.373 358.25C1020.43 284.599 1165.77 240.75 1293.19 229.382C1420.62 218.013 1529.98 239.139 1591.27 295.303Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M669.209 860.141C481.416 807.657 306.188 730.376 174.699 647.873C108.952 606.621 54.1638 564.077 14.21 522.693C-25.7438 481.309 -50.8232 441.128 -57.2506 404.56C-63.6781 367.992 -50.8623 338.403 -22.0799 316.229C6.70239 294.055 51.4234 279.322 108.712 272.456C223.286 258.723 388.049 276.463 575.841 328.947C763.634 381.431 938.862 458.712 1070.35 541.215C1136.1 582.467 1190.89 625.011 1230.84 666.395C1270.79 707.779 1295.87 747.96 1302.3 784.527C1308.73 821.095 1295.91 850.685 1267.13 872.859C1238.35 895.033 1193.63 909.765 1136.34 916.632C1021.76 930.365 857.001 912.625 669.209 860.141Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M491.244 789.696C399.399 764.027 311.456 713.424 243.521 652.382C175.585 591.339 127.689 519.889 115.841 452.483C103.993 385.077 130.423 334.399 182.811 306.984C235.198 279.57 313.52 275.435 405.365 301.103C497.21 326.772 585.154 377.375 653.089 438.417C721.024 499.46 768.921 570.91 780.769 638.317C792.617 705.723 766.186 756.4 713.799 783.815C661.412 811.229 583.089 815.364 491.244 789.696Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1045.38 1279.7C968.947 1258.34 887.913 1172.33 818.687 1050.26C749.48 928.219 692.175 770.301 663.197 605.439C634.22 440.578 639.064 300.029 670.304 206.063C701.552 112.07 759.161 64.8068 835.594 86.1682C912.026 107.53 993.06 193.541 1062.29 315.615C1131.49 437.653 1188.8 595.571 1217.78 760.433C1246.75 925.295 1241.91 1065.84 1210.67 1159.81C1179.42 1253.8 1121.81 1301.07 1045.38 1279.7Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1511.06 1337.34C1480.63 1342.57 1438.08 1330.15 1387.07 1303.01C1336.08 1275.88 1276.75 1234.12 1212.87 1180.85C1085.12 1074.31 939.241 921.839 805.541 748.689C671.841 575.539 575.65 414.519 528.206 294.162C504.483 233.981 492.956 183.988 495.006 147.733C497.057 111.464 512.687 89.0323 543.118 83.7959C573.548 78.5596 616.09 90.9811 667.102 118.12C718.095 145.247 777.423 187.016 841.303 240.286C969.058 346.823 1114.93 499.294 1248.63 672.444C1382.33 845.594 1478.52 1006.61 1525.97 1126.97C1549.69 1187.15 1561.22 1237.14 1559.17 1273.4C1557.12 1309.67 1541.49 1332.1 1511.06 1337.34Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M535.112 740.989C347.224 653.391 182.235 548.172 67.371 448.883C9.93706 399.236 -34.944 351.09 -63.8911 307.388C-92.8444 263.677 -105.803 224.498 -99.5551 192.716C-93.3073 160.934 -68.4147 139.403 -28.5802 127.762C11.2456 116.124 65.9308 114.398 131.601 122.146C262.937 137.641 438.118 191.018 626.006 278.616C813.894 366.214 978.883 471.433 1093.75 570.722C1151.18 620.369 1196.06 668.515 1225.01 712.217C1253.96 755.929 1266.92 795.107 1260.67 826.889C1254.43 858.671 1229.53 880.202 1189.7 891.843C1149.87 903.481 1095.19 905.208 1029.52 897.46C898.181 881.965 723.001 828.587 535.112 740.989Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M364.622 640.809C272.715 597.959 194.213 535.341 141.587 468.764C88.9526 402.176 62.2561 331.707 73.7807 273.081C85.3053 214.456 132.899 178.623 200.677 168.174C268.444 157.727 356.317 172.677 448.224 215.527C540.131 258.376 618.633 320.994 671.259 387.572C723.893 454.159 750.59 524.628 739.065 583.254C727.541 641.879 679.947 677.713 612.169 688.161C544.401 698.608 456.529 683.658 364.622 640.809Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M796.712 1177.24C720.189 1141.57 662.248 1051.18 630.488 933.732C598.732 816.294 593.184 671.892 621.397 528.375C649.609 384.858 706.232 269.441 774.952 198.848C843.679 128.246 924.443 102.531 1000.97 138.207C1077.49 173.884 1135.43 264.267 1167.19 381.719C1198.95 499.157 1204.49 643.559 1176.28 787.076C1148.07 930.593 1091.45 1046.01 1022.73 1116.6C953.999 1187.21 873.235 1212.92 796.712 1177.24Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1909.25 1052.17C1906.78 1064.7 1898.93 1074.44 1886.15 1081.47C1873.37 1088.5 1855.69 1092.81 1833.64 1094.51C1789.55 1097.89 1728.07 1090.81 1653.61 1074.22C1504.69 1041.05 1303.93 969.87 1086.62 868.554C869.309 767.239 676.621 654.977 540.761 555.384C472.828 505.585 419.123 458.968 383.653 418.486C365.918 398.244 352.759 379.555 344.657 362.786C336.556 346.02 333.527 331.211 335.99 318.682C338.453 306.154 346.307 296.418 359.086 289.387C371.867 282.355 389.547 278.044 411.594 276.35C455.688 272.961 517.164 280.045 591.626 296.634C740.542 329.811 941.307 400.987 1158.62 502.302C1375.93 603.618 1568.62 715.879 1704.48 815.472C1772.41 865.271 1826.11 911.888 1861.58 952.371C1879.32 972.613 1892.48 991.301 1900.58 1008.07C1908.68 1024.84 1911.71 1039.65 1909.25 1052.17Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1813.35 1210.61C1805.3 1219.89 1792.45 1225.49 1775.25 1227.6C1758.05 1229.71 1736.56 1228.31 1711.32 1223.65C1660.83 1214.32 1595.45 1191.93 1519.65 1158.44C1368.07 1091.47 1174.93 980.142 976.165 840.344C777.398 700.546 610.66 558.757 501.762 442.532C447.311 384.417 407.337 332.71 385.046 290.851C373.9 269.92 367.187 251.473 365.284 235.932C363.382 220.393 366.292 207.8 374.335 198.52C382.378 189.24 395.233 183.642 412.429 181.535C429.627 179.428 451.121 180.82 476.366 185.484C526.851 194.811 592.232 217.203 668.026 250.692C819.607 317.669 1012.75 428.992 1211.52 568.79C1410.28 708.588 1577.02 850.378 1685.92 966.603C1740.37 1024.72 1780.34 1076.42 1802.64 1118.28C1813.78 1139.21 1820.49 1157.66 1822.4 1173.2C1824.3 1188.74 1821.39 1201.33 1813.35 1210.61Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1525.68 566.315C1697.54 687.186 1836.46 815.825 1922.13 926.085C1964.96 981.218 1994.47 1031.74 2008.15 1074.38C2021.82 1117.03 2019.62 1151.7 1999.2 1175.25C1978.79 1198.81 1941.98 1209.14 1892.73 1207.54C1843.5 1205.94 1781.94 1192.4 1712.17 1168.34C1572.65 1120.21 1400.42 1030.01 1228.56 909.143C1056.71 788.272 917.79 659.633 832.119 549.373C789.281 494.241 759.771 443.723 746.101 401.083C732.426 358.43 734.629 323.757 755.042 300.203C775.455 276.65 812.262 266.314 861.512 267.918C910.748 269.522 972.31 283.058 1042.07 307.121C1181.6 355.246 1353.83 445.444 1525.68 566.315Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1419.66 148.139C1489.67 197.375 1515.79 285.04 1501.32 387.075C1486.85 489.102 1431.8 605.422 1339.58 711.837C1247.35 818.251 1135.43 894.578 1029.07 931.971C922.696 969.368 821.951 967.802 751.947 918.566C681.942 869.33 655.818 781.665 670.287 679.63C684.755 577.603 739.806 461.283 832.033 354.869C924.261 248.454 1036.17 172.127 1142.54 134.734C1248.91 97.3375 1349.66 98.9032 1419.66 148.139Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1249.49 1328.74C1213.32 1324.59 1171.23 1303.08 1126.02 1267.61C1080.81 1232.15 1032.54 1182.78 984.073 1123.02C887.152 1003.5 789.544 842.523 714.244 668.417C638.944 494.311 600.223 340.079 598.479 231.467C597.607 177.16 605.98 134.278 623.629 106.016C641.277 77.7547 668.181 64.1415 704.345 68.2849C740.51 72.4282 782.608 93.9467 827.817 129.41C873.026 164.873 921.299 214.245 969.761 274.005C1066.68 393.521 1164.29 554.5 1239.59 728.605C1314.89 902.711 1353.61 1056.94 1355.36 1165.56C1356.23 1219.86 1347.85 1262.74 1330.21 1291.01C1312.56 1319.27 1285.65 1332.88 1249.49 1328.74Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1668.55 1300.18C1656.26 1306 1640.02 1307.63 1620.29 1305.34C1600.56 1303.05 1577.37 1296.85 1551.25 1287.06C1499 1267.48 1435.1 1233.57 1363.76 1188.02C1221.1 1096.9 1048.8 959.255 880.688 796.671C712.576 634.086 580.502 477.366 502.864 355.423C464.044 294.448 438.846 242.187 429.547 202.243C424.898 182.271 424.229 165.397 427.803 152.06C431.376 138.725 439.185 128.946 451.471 123.121C463.758 117.295 480 115.672 499.726 117.959C519.457 120.247 542.644 126.445 568.768 136.235C621.017 155.814 684.919 189.724 756.254 235.283C898.917 326.396 1071.22 464.043 1239.33 626.628C1407.44 789.213 1539.52 945.932 1617.15 1067.88C1655.97 1128.85 1681.17 1181.11 1690.47 1221.06C1695.12 1241.03 1695.79 1257.9 1692.21 1271.24C1688.64 1284.57 1680.83 1294.35 1668.55 1300.18Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1586.59 698.29C1731.94 838.86 1838.14 978.143 1892.35 1089.69C1919.45 1145.46 1933.55 1194.28 1933.06 1232.85C1932.57 1271.43 1917.49 1299.69 1886.35 1314.46C1855.21 1329.22 1810.83 1329.15 1757.13 1316.27C1703.44 1303.38 1640.51 1277.71 1572.39 1241.39C1436.14 1168.74 1279.19 1053.52 1133.84 912.953C988.487 772.382 882.289 633.1 828.08 521.554C800.975 465.78 786.878 416.96 787.368 378.389C787.857 339.815 802.933 311.55 834.076 296.784C865.22 282.018 909.6 282.093 963.296 294.976C1016.99 307.858 1079.91 333.528 1148.04 369.853C1284.29 442.501 1441.24 557.719 1586.59 698.29Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1622.31 326.579C1681.49 383.815 1678.32 462.643 1625.12 543.895C1571.94 625.131 1468.78 708.7 1328.26 775.326C1187.73 841.953 1036.48 879.005 905.558 885.062C774.61 891.12 664.136 866.173 604.955 808.937C545.773 751.702 548.943 672.873 602.142 591.622C655.331 510.386 758.483 426.817 899.008 360.19C1039.53 293.563 1190.78 256.512 1321.71 250.455C1452.66 244.397 1563.13 269.344 1622.31 326.579Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M645.159 841.408C456.45 782.755 282.108 700.396 152.789 614.668C88.1286 571.804 34.7471 528.112 -3.54317 486.139C-41.8345 444.165 -64.9899 403.961 -69.3089 368.026C-73.6279 332.09 -58.7145 303.719 -27.9324 283.214C2.8489 262.709 49.4633 250.097 108.439 245.661C226.388 236.788 393.696 260.618 582.405 319.272C771.113 377.925 945.456 460.284 1074.77 546.012C1139.44 588.876 1192.82 632.568 1231.11 674.541C1269.4 716.515 1292.55 756.719 1296.87 792.654C1301.19 828.59 1286.28 856.962 1255.5 877.467C1224.71 897.971 1178.1 910.583 1119.12 915.019C1001.18 923.892 833.868 900.062 645.159 841.408Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M467.602 765.62C375.305 736.933 288.531 684.062 222.85 621.795C157.167 559.525 112.615 487.898 104.653 421.65C96.6906 355.403 126.815 306.986 182.046 282.299C237.276 257.612 317.584 256.673 409.88 285.36C502.177 314.047 588.952 366.918 654.632 429.185C720.315 491.454 764.867 563.082 772.829 629.329C780.791 695.577 750.667 743.994 695.436 768.681C640.206 793.368 559.898 794.307 467.602 765.62Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1003.82 1265.8C927.008 1241.93 849.476 1154.76 786.234 1032.89C723.009 911.038 674.153 754.642 654.676 592.588C635.199 430.534 648.794 293.548 686.501 203.072C724.217 112.573 786.001 68.7129 862.818 92.5886C939.635 116.464 1017.17 203.626 1080.41 325.504C1143.63 447.351 1192.49 603.748 1211.97 765.802C1231.44 927.856 1217.85 1064.84 1180.14 1155.32C1142.43 1245.82 1080.64 1289.68 1003.82 1265.8Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1476.22 1340.47C1444.78 1344.38 1402.06 1330.56 1351.6 1302.06C1301.15 1273.57 1243.08 1230.46 1181.07 1175.94C1057.05 1066.9 917.34 912.318 791.341 738.031C665.342 563.744 576.934 402.781 535.846 283.434C515.302 223.758 506.596 174.508 510.921 139.204C515.247 103.889 532.599 82.6063 564.037 78.6971C595.475 74.7878 638.199 88.6003 688.662 117.102C739.108 145.596 797.175 188.708 859.186 243.227C983.205 352.262 1122.92 506.845 1248.92 681.132C1374.92 855.419 1463.32 1016.38 1504.41 1135.73C1524.96 1195.41 1533.66 1244.65 1529.34 1279.96C1525.01 1315.27 1507.66 1336.56 1476.22 1340.47Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M515.436 719.816C328.791 626.886 166.632 517.664 55.3402 416.21C-0.307774 365.482 -43.2183 316.713 -70.1164 272.913C-97.0212 229.102 -107.852 190.354 -99.5142 159.588C-91.1759 128.822 -64.4144 108.791 -22.9835 99.0037C18.4371 89.219 74.4353 89.6966 141.078 99.8558C274.359 120.173 450.122 179.2 636.768 272.129C823.414 365.059 985.572 474.281 1096.86 575.735C1152.51 626.463 1195.42 675.232 1222.32 719.032C1249.23 762.843 1260.06 801.591 1251.72 832.357C1243.38 863.123 1216.62 883.154 1175.19 892.941C1133.77 902.726 1077.77 902.248 1011.13 892.089C877.845 871.772 702.082 812.745 515.436 719.816Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M347.346 615.289C256.046 569.831 179.662 505.608 129.969 438.543C80.2673 371.467 57.3199 301.634 72.7022 244.877C88.0845 188.119 138.927 155.026 208.845 147.508C278.751 139.991 367.644 158.058 458.945 203.516C550.245 248.974 626.629 313.197 676.322 380.261C726.024 447.337 748.971 517.17 733.589 573.928C718.207 630.685 667.364 663.778 597.446 671.297C527.54 678.813 438.647 660.747 347.346 615.289Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M755.976 1155.96C679.954 1118.11 626.294 1027.6 601.058 911.709C575.824 795.832 579.035 654.673 616.696 515.713C654.357 376.752 719.326 266.342 793.902 200.159C868.485 133.969 952.613 112.06 1028.63 149.911C1104.66 187.762 1158.32 278.274 1183.55 394.163C1208.79 510.041 1205.57 651.2 1167.91 790.16C1130.25 929.12 1065.28 1039.53 990.708 1105.71C916.125 1171.9 831.998 1193.81 755.976 1155.96Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1900.74 1077.89C1897.45 1090.02 1888.83 1099.21 1875.34 1105.56C1861.84 1111.92 1843.51 1115.43 1820.87 1116.21C1775.59 1117.78 1713.19 1108.43 1638.11 1089.29C1487.95 1051.02 1287.13 973.614 1071.26 866.132C855.386 758.651 665.352 641.459 532.62 538.879C466.25 487.587 414.227 439.963 380.465 399.046C363.583 378.588 351.283 359.826 344.034 343.139C336.785 326.454 334.602 311.884 337.888 299.758C341.174 287.632 349.797 278.442 363.291 272.085C376.786 265.726 395.121 262.218 417.76 261.436C463.035 259.872 525.433 269.218 600.517 288.354C750.678 326.625 951.494 404.034 1167.37 511.516C1383.24 618.998 1573.27 736.19 1706.01 838.769C1772.38 890.062 1824.4 937.686 1858.16 978.602C1875.04 999.061 1887.34 1017.82 1894.59 1034.51C1901.84 1051.19 1904.02 1065.76 1900.74 1077.89Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1792.98 1228.74C1784.18 1237.49 1770.7 1242.44 1752.99 1243.82C1735.29 1245.2 1713.4 1242.99 1687.89 1237.44C1636.86 1226.34 1571.42 1201.9 1496.01 1166.21C1345.21 1094.85 1154.65 978.553 960.101 834.244C765.548 689.936 603.853 544.951 499.701 427.205C447.623 368.329 409.948 316.281 389.74 274.544C379.635 253.674 373.909 235.404 372.92 220.16C371.931 204.918 375.681 192.74 384.474 183.998C393.267 175.256 406.752 170.3 424.457 168.924C442.164 167.547 464.047 169.756 489.561 175.306C540.586 186.406 606.032 210.846 681.437 246.531C832.242 317.896 1022.8 434.19 1217.35 578.498C1411.9 722.807 1573.6 867.792 1677.75 985.538C1729.83 1044.41 1767.5 1096.46 1787.71 1138.2C1797.82 1159.07 1803.54 1177.34 1804.53 1192.58C1805.52 1207.82 1801.77 1220 1792.98 1228.74Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1538.6 588.51C1706.81 713.28 1840.88 844.33 1921.66 955.335C1962.05 1010.84 1989.11 1061.31 2000.46 1103.47C2011.81 1145.64 2007.43 1179.4 1985.11 1201.59C1962.8 1223.77 1924.54 1232.4 1874.3 1228.89C1824.08 1225.38 1761.99 1209.73 1692.17 1183.49C1552.53 1131 1382.05 1036.15 1213.83 911.379C1045.62 786.609 911.555 655.559 830.776 544.554C790.384 489.049 763.33 438.575 751.981 396.416C740.628 354.246 745.011 320.488 767.326 298.303C789.641 276.118 827.898 267.486 878.135 270.999C928.357 274.512 990.444 290.157 1060.27 316.403C1199.91 368.892 1370.39 463.739 1538.6 588.51Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1455.96 176.157C1524.48 226.98 1545.79 313.576 1524.72 412.596C1503.66 511.607 1440.24 622.965 1339.43 723.183C1238.63 823.402 1119.55 893.476 1008.54 925.767C897.515 958.061 794.645 952.548 726.128 901.726C657.61 850.903 636.3 764.307 657.364 665.287C678.426 566.276 741.846 454.918 842.654 354.7C943.461 254.481 1062.54 184.407 1173.55 152.116C1284.57 119.822 1387.44 125.335 1455.96 176.157Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1209.42 1321.73C1172.7 1316.25 1130.99 1293.58 1086.97 1257.18C1042.94 1220.78 996.644 1170.68 950.79 1110.44C859.084 989.958 769.225 828.98 702.973 656.071C636.72 483.162 606.632 331.098 611.53 225.022C613.978 171.983 625.173 130.46 644.949 103.575C664.724 76.6895 693.06 64.4622 729.777 69.9379C766.495 75.4136 808.204 98.0866 852.227 134.486C896.251 170.886 942.551 220.983 988.405 281.225C1080.11 401.707 1169.97 562.685 1236.22 735.594C1302.47 908.503 1332.56 1060.57 1327.67 1166.64C1325.22 1219.68 1314.02 1261.2 1294.25 1288.09C1274.47 1314.98 1246.14 1327.2 1209.42 1321.73Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1639.47 1310.43C1613.62 1320.84 1572.62 1314.19 1520.38 1293C1468.19 1271.82 1404.96 1236.2 1334.86 1188.91C1194.65 1094.34 1027.02 953.178 865.198 787.842C703.377 622.506 578.03 464.32 506.18 342.229C470.254 281.181 447.715 229.177 440.669 189.824C433.617 150.441 442.103 123.864 467.952 113.457C493.8 103.05 534.807 109.701 587.042 130.893C639.238 152.069 702.463 187.693 772.569 234.98C912.773 329.551 1080.41 470.712 1242.23 636.048C1404.05 801.384 1529.4 959.57 1601.24 1081.66C1637.17 1142.71 1659.71 1194.71 1666.76 1234.07C1673.81 1273.45 1665.32 1300.03 1639.47 1310.43Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1592.74 719.726C1732.65 862.674 1832.62 1002.82 1881.17 1113.83C1905.44 1169.34 1916.84 1217.55 1913.97 1255.17C1911.1 1292.8 1893.95 1319.8 1861.2 1332.99C1828.46 1346.17 1783.09 1344.34 1729.01 1329.64C1674.93 1314.94 1612.19 1287.4 1544.8 1249.25C1410.02 1172.96 1256.69 1054.3 1116.78 911.349C976.871 768.4 876.898 628.257 828.355 517.243C804.082 461.734 792.677 413.527 795.55 375.9C798.422 338.272 815.568 311.274 848.317 298.09C881.065 284.905 926.427 286.737 980.513 301.434C1034.6 316.13 1097.33 343.672 1164.72 381.82C1299.5 458.114 1452.83 576.777 1592.74 719.726Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1652.11 358.336C1709.07 416.533 1700.99 493.345 1641.62 570.551C1582.26 647.739 1471.69 725.229 1323.96 784.703C1176.23 844.178 1019.36 874.361 885.177 875.096C750.959 875.831 639.589 847.111 582.629 788.914C525.67 730.717 533.752 653.904 593.125 576.699C652.485 499.51 763.055 422.021 910.78 362.546C1058.51 303.071 1215.38 272.888 1349.56 272.153C1483.78 271.419 1595.15 300.139 1652.11 358.336Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M621.732 822.188C432.466 757.477 259.341 670.195 132.44 581.407C68.9884 537.011 17.1155 492.255 -19.4384 449.773C-55.9945 407.288 -77.1823 367.137 -79.3858 331.903C-81.5892 296.669 -64.6073 269.568 -31.8836 250.771C0.838133 231.975 49.2574 221.509 109.808 219.51C230.906 215.514 400.441 245.39 589.707 310.101C778.972 374.812 952.098 462.093 1079 550.882C1142.45 595.277 1194.32 640.034 1230.88 682.516C1267.43 725 1288.62 765.151 1290.82 800.386C1293.03 835.62 1276.05 862.72 1243.32 881.518C1210.6 900.314 1162.18 910.78 1101.63 912.778C980.532 916.775 810.997 886.899 621.732 822.188Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M444.919 741.187C352.347 709.536 266.907 654.498 203.605 591.124C140.3 527.746 99.1775 456.079 95.1148 391.115C91.0521 326.152 124.812 280.087 182.783 258.174C240.751 236.263 322.89 238.522 415.463 270.173C508.035 301.824 593.475 356.861 656.776 420.236C720.081 483.613 761.204 555.281 765.267 620.244C769.329 685.207 735.569 731.272 677.598 753.185C619.63 775.097 537.491 772.838 444.919 741.187Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M962.206 1250.59C885.153 1224.24 811.271 1136.1 754.135 1014.65C697.01 893.222 656.696 738.644 646.757 579.707C636.817 420.769 659.136 287.607 703.238 200.792C747.349 113.959 813.193 73.5859 890.246 99.9308C967.3 126.276 1041.18 214.422 1098.32 335.872C1155.44 457.297 1195.76 611.874 1205.7 770.811C1215.64 929.749 1193.32 1062.91 1149.21 1149.73C1105.1 1236.56 1039.26 1276.93 962.206 1250.59Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1925.29 940.769C1927.03 968.579 1906.7 987.683 1868.12 998.279C1829.57 1008.87 1772.93 1010.92 1702.36 1004.79C1561.23 992.517 1364.48 947.51 1145.57 872.665C926.666 797.82 727.074 707.316 581.344 621.505C508.476 578.597 449.097 536.876 407.508 498.888C365.886 460.87 342.225 426.725 340.485 398.914C338.746 371.104 359.078 352 397.659 341.404C436.21 330.817 492.851 328.763 563.42 334.898C704.551 347.166 901.298 392.173 1120.2 467.018C1339.11 541.863 1538.7 632.367 1684.43 718.178C1757.3 761.086 1816.68 802.807 1858.27 840.795C1899.89 878.813 1923.55 912.958 1925.29 940.769Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1440.43 1342.15C1408.04 1344.72 1365.22 1329.54 1315.4 1299.73C1265.6 1269.93 1208.9 1225.55 1148.88 1169.89C1028.83 1058.56 895.545 902.162 777.487 727.071C659.428 551.979 578.971 391.378 544.318 273.269C526.991 214.212 521.122 165.799 527.714 131.513C534.307 97.2188 553.349 77.1252 585.735 74.5517C618.121 71.9782 660.945 87.1556 710.762 116.967C760.568 146.772 817.262 191.146 877.287 246.81C997.333 358.134 1130.62 514.536 1248.68 689.628C1366.74 864.719 1447.19 1025.32 1481.85 1143.43C1499.17 1202.49 1505.04 1250.9 1498.45 1285.19C1491.86 1319.48 1472.81 1339.57 1440.43 1342.15Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M496.631 698.381C311.583 600.297 152.564 487.279 45.0563 383.855C-8.69969 332.14 -49.5576 282.843 -74.3556 239.029C-99.1602 195.202 -107.845 156.957 -97.4324 127.263C-87.0199 97.5694 -58.4398 79.0766 -15.4916 71.1621C27.4449 63.2498 84.6494 65.9303 152.138 78.4816C287.11 103.583 463.121 168.146 648.168 266.23C833.216 364.314 992.235 477.332 1099.74 580.756C1153.5 632.47 1194.36 681.768 1219.15 725.582C1243.96 769.409 1252.64 807.654 1242.23 837.348C1231.82 867.042 1203.24 885.534 1160.29 893.449C1117.35 901.361 1060.15 898.681 992.661 886.129C857.689 861.028 681.678 796.465 496.631 698.381Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M331.261 589.713C240.741 541.733 166.621 476.027 119.956 408.604C73.2817 341.167 54.1262 272.103 73.3363 217.32C92.5465 162.537 146.542 132.246 218.467 127.673C290.378 123.101 380.122 144.25 470.642 192.23C561.162 240.21 635.282 305.916 681.947 373.339C728.621 440.776 747.777 509.84 728.567 564.623C709.357 619.406 655.361 649.697 583.436 654.27C511.525 658.843 421.781 637.693 331.261 589.713Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M715.651 1133.58C640.276 1093.63 591 1003.16 572.336 889.057C553.673 774.96 565.636 637.313 612.673 503.173C659.71 369.034 732.901 263.84 813.191 202.193C893.488 140.54 980.82 122.481 1056.19 162.433C1131.57 202.385 1180.85 292.853 1199.51 406.96C1218.17 521.057 1206.21 658.705 1159.17 792.844C1112.14 926.983 1038.94 1032.18 958.655 1093.82C878.358 1155.48 791.026 1173.54 715.651 1133.58Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1890.47 1102.66C1886.36 1114.36 1876.99 1122.99 1862.8 1128.66C1848.62 1134.33 1829.67 1137.03 1806.48 1136.9C1760.11 1136.63 1696.91 1125.04 1621.34 1103.4C1470.22 1060.11 1269.74 976.609 1055.71 863.166C841.689 749.723 654.67 627.824 525.318 522.453C460.64 469.765 410.397 421.224 378.406 379.953C362.41 359.316 350.993 340.518 344.609 323.944C338.227 307.372 336.893 293.067 340.996 281.366C345.099 269.666 354.475 261.038 368.657 255.366C382.841 249.694 401.796 246.994 424.983 247.126C471.354 247.39 534.554 258.979 610.117 280.626C761.238 323.919 961.723 407.415 1175.75 520.858C1389.77 634.301 1576.79 756.2 1706.14 861.571C1770.82 914.259 1821.06 962.8 1853.06 1004.07C1869.05 1024.71 1880.47 1043.51 1886.85 1060.08C1893.23 1076.65 1894.57 1090.96 1890.47 1102.66Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1771.04 1245.63C1761.52 1253.82 1747.42 1258.12 1729.24 1258.77C1711.06 1259.41 1688.83 1256.39 1663.1 1249.96C1611.63 1237.11 1546.25 1210.67 1471.37 1172.86C1321.63 1097.24 1134.03 976.199 944.057 827.655C754.088 679.111 597.744 531.206 498.537 412.163C448.932 352.639 413.627 300.348 395.54 258.813C386.497 238.044 381.767 219.986 381.694 205.068C381.621 190.152 386.202 178.412 395.73 170.224C405.259 162.036 419.35 157.732 437.529 157.089C455.712 156.445 477.941 159.466 503.676 165.892C555.143 178.744 620.528 205.187 695.402 242.998C845.143 318.616 1032.75 439.658 1222.72 588.202C1412.69 736.746 1569.03 884.651 1668.24 1003.69C1717.84 1063.22 1753.15 1115.51 1771.23 1157.04C1780.28 1177.81 1785.01 1195.87 1785.08 1210.79C1785.15 1225.71 1780.57 1237.45 1771.04 1245.63Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1550.44 610.677C1714.69 739.109 1843.65 872.32 1919.39 983.859C1957.25 1039.63 1981.8 1089.97 1990.81 1131.56C1999.82 1173.17 1993.26 1205.95 1969.09 1226.72C1944.91 1247.5 1905.27 1254.41 1854.15 1248.99C1803.03 1243.58 1740.54 1225.85 1670.79 1197.48C1531.3 1140.72 1362.89 1041.41 1198.64 912.975C1034.39 784.543 905.431 651.332 829.697 539.793C791.829 484.021 767.283 433.687 758.276 392.09C749.267 350.483 755.821 317.704 779.997 296.929C804.174 276.154 843.809 269.243 894.936 274.659C946.05 280.073 1008.54 297.798 1078.29 326.176C1217.78 382.929 1386.19 482.245 1550.44 610.677Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1491.34 204.94C1558.24 257.251 1574.69 342.613 1547.07 438.43C1519.46 534.238 1447.79 640.421 1338.59 734.253C1229.4 828.085 1103.38 891.773 987.931 918.901C872.471 946.032 767.673 936.581 700.774 884.27C633.874 831.958 617.418 746.596 645.037 650.779C672.654 554.972 744.323 448.789 853.519 354.956C962.715 261.124 1088.73 197.437 1204.18 170.309C1319.64 143.178 1424.44 152.629 1491.34 204.94Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1168.89 1313.31C1131.69 1306.51 1090.45 1282.73 1047.7 1245.46C1004.94 1208.19 960.706 1157.46 917.546 1096.85C831.23 975.636 749.292 814.965 692.213 643.582C635.134 472.199 613.735 322.592 625.265 219.255C631.029 167.585 645.024 127.501 666.889 102.042C688.753 76.584 718.468 65.7665 755.668 72.5648C792.869 79.363 834.108 103.147 876.862 140.414C919.618 177.682 963.857 228.408 1007.02 289.018C1093.33 410.236 1175.27 570.907 1232.35 742.29C1289.43 913.673 1310.83 1063.28 1299.3 1166.62C1293.53 1218.29 1279.54 1258.37 1257.67 1283.83C1235.81 1309.29 1206.09 1320.11 1168.89 1313.31Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1609.13 1319.29C1582.06 1328.43 1540.55 1320.31 1488.47 1297.57C1436.43 1274.83 1374 1237.56 1305.26 1188.64C1167.78 1090.79 1005.13 946.384 849.91 778.611C694.69 610.838 576.307 451.487 510.383 329.482C477.419 268.477 457.582 216.829 452.8 178.141C448.016 139.428 458.319 113.816 485.392 104.677C512.464 95.5367 553.974 103.656 606.051 126.403C658.094 149.134 720.522 186.404 789.264 235.33C926.743 333.178 1089.39 477.584 1244.61 645.357C1399.83 813.13 1518.21 972.481 1584.14 1094.49C1617.1 1155.49 1636.94 1207.14 1641.72 1245.83C1646.51 1284.54 1636.2 1310.15 1609.13 1319.29Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1680.61 390.503C1735.24 449.55 1722.26 524.2 1656.83 597.213C1591.41 670.207 1473.63 741.469 1318.99 793.678C1164.34 845.887 1002.15 869.144 864.955 864.555C727.722 859.964 615.668 827.526 561.039 768.479C506.411 709.432 519.388 634.782 584.823 561.77C650.242 488.776 768.02 417.514 922.665 365.305C1077.31 313.095 1239.5 289.838 1376.7 294.427C1513.93 299.018 1625.98 331.457 1680.61 390.503Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M598.969 802.481C409.507 731.835 237.929 639.798 113.688 548.118C51.5656 502.276 1.30022 456.539 -33.4476 413.629C-68.1989 370.715 -87.3793 330.694 -87.4643 296.227C-87.5494 261.76 -68.5315 235.982 -33.9282 218.929C0.671589 201.876 50.8036 193.575 112.814 194.02C236.831 194.908 408.27 230.773 597.732 301.418C787.194 372.064 958.772 464.101 1083.01 555.781C1145.14 601.623 1195.4 647.36 1230.15 690.27C1264.9 733.184 1284.08 773.205 1284.17 807.672C1284.25 842.139 1265.23 867.917 1230.63 884.971C1196.03 902.023 1145.9 910.324 1083.89 909.88C959.87 908.992 788.431 873.126 598.969 802.481Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M423.238 716.434C330.567 681.88 246.625 624.78 185.823 560.42C125.017 496.054 87.401 424.483 87.2441 360.927C87.0873 297.371 124.419 253.745 185.019 234.649C245.614 215.554 329.43 221.007 422.101 255.562C514.771 290.116 598.714 347.216 659.516 411.576C720.322 475.942 757.938 547.513 758.095 611.069C758.252 674.625 720.92 718.251 660.32 737.347C599.724 756.442 515.909 750.989 423.238 716.434Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M920.606 1234.09C843.464 1205.32 773.374 1116.36 722.449 995.569C671.534 874.799 639.839 722.335 639.455 566.816C639.071 411.297 670.072 282.211 720.484 199.223C770.905 116.22 840.684 79.412 917.827 108.176C994.969 136.941 1065.06 225.902 1115.98 346.695C1166.9 467.465 1198.59 619.929 1198.98 775.448C1199.36 930.967 1168.36 1060.05 1117.95 1143.04C1067.53 1226.04 997.749 1262.85 920.606 1234.09Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1923.99 969.794C1924.03 983.391 1918.56 994.65 1908.07 1003.62C1897.57 1012.59 1882.05 1019.25 1862.03 1023.65C1821.97 1032.46 1763.95 1032.23 1692.2 1023.45C1548.71 1005.9 1350.39 954.186 1131.26 872.477C912.128 790.768 713.699 694.54 570.03 605.021C498.192 560.259 440.068 517.189 399.897 478.465C379.811 459.103 364.232 440.845 353.67 424.023C343.111 407.205 337.581 391.847 337.548 378.25C337.514 364.652 342.978 353.393 353.474 344.426C363.972 335.456 379.489 328.794 399.516 324.389C439.57 315.579 497.589 315.815 569.337 324.593C712.829 342.149 911.148 393.858 1130.28 475.567C1349.41 557.276 1547.84 653.504 1691.51 743.023C1763.35 787.785 1821.47 830.855 1861.64 869.579C1881.73 888.941 1897.31 907.198 1907.87 924.02C1918.43 940.839 1923.96 956.197 1923.99 969.794Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1403.74 1342.37C1370.47 1343.61 1327.63 1327.09 1278.55 1296.03C1229.48 1264.97 1174.27 1219.42 1116.34 1162.71C1000.5 1049.31 873.891 891.39 763.998 715.827C654.106 540.264 581.752 380.332 553.6 263.684C539.523 205.358 536.502 157.875 545.347 124.672C554.194 91.4638 574.892 72.5983 608.163 71.3666C641.435 70.135 684.278 86.6484 733.356 117.713C782.425 148.772 837.639 194.323 895.563 251.025C1011.41 364.427 1138.01 522.35 1247.91 697.913C1357.8 873.476 1430.15 1033.41 1458.31 1150.06C1472.38 1208.38 1475.4 1255.86 1466.56 1289.07C1457.71 1322.28 1437.01 1341.14 1403.74 1342.37Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M478.725 676.707C295.628 573.655 140.052 457.057 36.5329 351.859C-15.2286 299.257 -53.9561 249.526 -76.6064 205.78C-99.2636 162.021 -105.786 124.351 -93.3191 95.7849C-80.8526 67.2183 -50.5075 50.2985 -6.12376 44.2719C38.2467 38.247 96.5485 43.1255 164.754 58.0449C301.161 87.8825 477.083 157.859 660.18 260.91C843.278 363.962 998.854 480.561 1102.37 585.759C1154.13 638.36 1192.86 688.092 1215.51 731.838C1238.17 775.597 1244.69 813.266 1232.22 841.833C1219.76 870.399 1189.41 887.319 1145.03 893.346C1100.66 899.37 1042.36 894.492 974.151 879.573C837.745 849.735 661.823 779.759 478.725 676.707Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M316.392 564.107C226.826 513.697 155.111 446.634 111.563 378.98C68.0057 311.312 52.6776 243.147 75.6788 190.441C98.68 137.735 155.726 110.304 229.521 108.684C303.3 107.065 393.724 131.257 483.291 181.667C572.857 232.078 644.572 299.141 688.12 366.795C731.677 434.463 747.005 502.628 724.004 555.334C701.003 608.04 643.957 635.471 570.162 637.09C496.383 638.71 405.959 614.518 316.392 564.107Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M675.811 1110.15C601.228 1068.17 556.429 977.924 544.371 865.815C532.314 753.715 553.006 619.84 609.33 490.777C665.654 361.713 746.928 261.935 832.779 204.94C918.637 147.941 1009.01 133.766 1083.59 175.744C1158.17 217.721 1202.97 307.971 1215.03 420.08C1227.09 532.18 1206.4 666.055 1150.07 795.119C1093.75 924.183 1012.47 1023.96 926.622 1080.96C840.764 1137.95 750.394 1152.13 675.811 1110.15Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1878.44 1126.43C1873.53 1137.68 1863.42 1145.73 1848.57 1150.71C1833.73 1155.68 1814.19 1157.57 1790.5 1156.52C1743.12 1154.43 1679.24 1140.62 1603.34 1116.51C1451.55 1068.27 1251.77 978.851 1040.01 859.662C828.236 740.474 644.589 614.099 518.864 506.137C455.999 452.154 407.634 402.789 377.474 361.241C362.394 340.466 351.881 321.667 346.377 305.238C340.873 288.81 340.39 274.798 345.302 263.542C350.213 252.287 360.325 244.238 375.169 239.262C390.014 234.287 409.554 232.402 433.244 233.447C480.622 235.539 544.504 249.35 620.403 273.466C772.195 321.697 971.967 411.121 1183.74 530.31C1395.5 649.498 1579.15 775.872 1704.88 883.834C1767.74 937.818 1816.11 987.182 1846.27 1028.73C1861.35 1049.51 1871.86 1068.3 1877.36 1084.73C1882.87 1101.16 1883.35 1115.17 1878.44 1126.43Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1747.59 1261.25C1737.34 1268.86 1722.67 1272.51 1704.05 1272.42C1685.43 1272.33 1662.89 1268.5 1636.99 1261.21C1585.18 1246.63 1519.98 1218.24 1445.78 1178.37C1297.39 1098.64 1113.09 973.082 928.064 820.585C743.041 668.089 592.345 517.546 498.273 397.433C451.234 337.374 418.367 284.941 402.436 243.686C394.47 223.058 390.747 205.245 391.59 190.681C392.433 176.12 397.837 164.839 408.084 157.221C418.33 149.603 433.001 145.959 451.621 146.05C470.244 146.141 492.778 149.969 518.684 157.259C570.495 171.837 635.696 200.232 709.895 240.097C858.287 319.825 1042.59 445.385 1227.61 597.881C1412.63 750.378 1563.33 900.921 1657.4 1021.03C1704.44 1081.09 1737.31 1133.53 1753.24 1174.78C1761.2 1195.41 1764.93 1213.22 1764.08 1227.79C1763.24 1242.35 1757.84 1253.63 1747.59 1261.25Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1561.18 632.781C1721.15 764.63 1844.76 899.748 1915.31 1011.61C1950.58 1067.54 1972.57 1117.64 1979.22 1158.59C1985.87 1199.56 1977.16 1231.3 1951.16 1250.62C1925.17 1269.95 1884.23 1275.13 1832.31 1267.82C1780.4 1260.51 1717.62 1240.74 1648.08 1210.29C1509 1149.38 1342.99 1045.78 1183.02 913.932C1023.05 782.083 899.431 646.965 828.887 535.104C793.614 479.172 771.622 429.074 764.975 388.118C758.327 347.154 767.039 315.415 793.033 296.09C819.026 276.764 859.966 271.588 911.885 278.895C963.793 286.2 1026.57 305.972 1096.11 336.428C1235.19 397.336 1401.2 500.931 1561.18 632.781Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1525.72 234.428C1590.88 288.128 1602.45 372.093 1568.33 464.525C1534.21 556.947 1454.43 657.754 1337.05 745.021C1219.67 832.288 1086.96 889.467 967.29 911.381C847.61 933.296 741.085 919.926 675.932 866.226C610.779 812.527 599.207 728.562 633.329 636.129C667.448 543.708 747.23 442.901 864.607 355.633C981.985 268.366 1114.7 211.187 1234.37 189.274C1354.05 167.358 1460.57 180.728 1525.72 234.428Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M718.987 895.941C534.098 856.083 358.102 789.39 223.025 713.801C155.486 676.006 98.1989 635.999 55.149 596.028C12.101 556.058 -16.6803 516.153 -27.2875 478.53C-37.8947 440.907 -29.3451 409.05 -4.72191 383.669C19.9025 358.286 60.5852 339.4 114.176 327.718C221.354 304.354 380.09 309.823 564.979 349.681C749.868 389.539 925.864 456.231 1060.94 531.82C1128.48 569.616 1185.77 609.623 1228.82 649.594C1271.87 689.564 1300.65 729.469 1311.25 767.092C1321.86 804.715 1313.31 836.571 1288.69 861.953C1264.06 887.335 1223.38 906.222 1169.79 917.904C1062.61 941.268 903.877 935.799 718.987 895.941Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1127.99 1303.48C1090.38 1295.37 1049.69 1270.52 1008.29 1232.46C966.879 1194.39 924.786 1143.13 884.404 1082.27C803.641 960.547 729.779 800.49 681.982 630.959C634.186 461.428 621.517 314.563 639.655 214.16C648.724 163.958 665.493 125.389 689.405 101.405C713.316 77.4227 744.354 68.0362 781.966 76.1445C819.578 84.2529 860.269 109.103 901.673 147.166C943.079 185.231 985.172 236.49 1025.55 297.352C1106.32 419.076 1180.18 579.133 1227.98 748.664C1275.77 918.194 1288.44 1065.06 1270.3 1165.46C1261.23 1215.66 1244.47 1254.23 1220.55 1278.22C1196.64 1302.2 1165.6 1311.59 1127.99 1303.48Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1577.57 1326.74C1549.33 1334.59 1507.39 1325.02 1455.57 1300.76C1403.78 1276.52 1342.27 1237.67 1275.02 1187.2C1140.53 1086.26 983.177 938.886 834.853 768.996C686.528 599.106 575.336 438.893 515.462 317.206C485.523 256.36 468.425 205.166 465.918 167.218C463.409 129.248 475.511 104.65 503.757 96.7961C532.002 88.942 573.936 98.5139 625.757 122.772C677.548 147.016 739.059 185.86 806.307 236.332C940.799 337.27 1098.15 484.647 1246.48 654.537C1394.8 824.427 1505.99 984.64 1565.87 1106.33C1595.81 1167.17 1612.9 1218.37 1615.41 1256.32C1617.92 1294.29 1605.82 1318.88 1577.57 1326.74Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1707.77 423.03C1759.96 482.813 1742.11 555.16 1670.74 623.84C1599.39 692.5 1474.63 757.399 1313.36 802.243C1152.09 847.086 984.882 863.373 844.937 853.468C704.95 843.56 592.426 807.465 540.232 747.682C488.037 687.899 505.885 615.553 577.259 546.872C648.612 478.212 773.374 413.314 934.644 368.47C1095.91 323.626 1263.12 307.339 1403.06 317.244C1543.05 327.152 1655.57 363.247 1707.77 423.03Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M576.92 782.362C387.623 705.917 217.919 609.299 96.5747 514.901C35.9003 467.7 -12.6616 421.071 -45.5373 377.814C-78.4174 334.552 -95.5545 294.736 -93.5222 261.102C-91.49 227.468 -70.4726 203.06 -34.0554 187.783C2.35684 172.507 54.106 166.388 117.458 169.273C244.157 175.044 417.174 216.83 606.472 293.276C795.769 369.721 965.473 466.339 1086.82 560.737C1147.49 607.938 1196.05 654.567 1228.93 697.823C1261.81 741.086 1278.95 780.902 1276.91 814.536C1274.88 848.17 1253.86 872.577 1217.45 887.855C1181.04 903.13 1129.29 909.25 1065.93 906.365C939.235 900.593 766.218 858.808 576.92 782.362Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M402.606 691.43C310.013 654.037 227.728 594.985 169.542 529.76C111.351 464.53 77.3126 393.19 81.0604 331.162C84.8082 269.133 125.64 228.029 188.754 211.786C251.862 195.545 337.193 204.182 429.786 241.574C522.379 278.966 604.664 338.019 662.85 403.244C721.041 468.474 755.079 539.814 751.331 601.842C747.584 663.871 706.752 704.975 643.638 721.218C580.53 737.459 495.198 728.822 402.606 691.43Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M879.107 1216.34C802.023 1185.21 735.859 1095.6 691.243 975.697C646.634 855.81 623.616 705.75 632.788 553.946C641.961 402.141 681.583 277.378 738.21 198.374C794.845 119.357 868.428 86.1852 945.511 117.315C1022.6 148.444 1088.76 238.051 1133.38 357.957C1177.98 477.843 1201 627.903 1191.83 779.708C1182.66 931.512 1143.04 1056.28 1086.41 1135.28C1029.77 1214.3 956.191 1247.47 879.107 1216.34Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1920.89 998.076C1920.08 1011.34 1913.81 1022.11 1902.53 1030.45C1891.25 1038.79 1874.98 1044.68 1854.24 1048.19C1812.76 1055.2 1753.48 1052.68 1680.68 1041.27C1535.11 1018.46 1335.59 960.149 1116.65 871.732C897.707 783.315 700.818 681.545 559.484 588.489C488.814 541.958 432.056 497.62 393.379 458.235C374.04 438.542 359.24 420.105 349.481 403.269C339.723 386.436 335.019 371.233 335.82 357.968C336.622 344.702 342.895 333.932 354.174 325.594C365.455 317.255 381.724 311.366 402.465 307.858C443.945 300.843 503.23 303.368 576.022 314.773C721.6 337.582 921.115 395.895 1140.06 484.312C1359 572.729 1555.89 674.499 1697.22 767.555C1767.89 814.086 1824.65 858.423 1863.33 897.809C1882.67 917.502 1897.47 935.938 1907.23 952.775C1916.98 969.607 1921.69 984.811 1920.89 998.076Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1366.23 1341.14C1332.14 1341.03 1289.36 1323.21 1241.11 1290.95C1192.87 1258.7 1139.25 1212.05 1083.53 1154.42C972.109 1039.16 852.425 880.014 750.907 704.314C649.389 528.614 585.277 369.655 563.678 254.692C552.878 197.209 552.711 150.745 563.793 118.689C574.876 86.6284 597.191 69.0273 631.285 69.1409C665.379 69.2545 708.158 87.0725 756.404 119.33C804.644 151.585 858.272 198.226 913.986 255.859C1025.41 371.122 1145.09 530.266 1246.61 705.966C1348.13 881.666 1412.24 1040.62 1433.84 1155.59C1444.64 1213.07 1444.81 1259.53 1433.72 1291.59C1422.64 1323.65 1400.33 1341.25 1366.23 1341.14Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M461.756 654.852C280.958 547.028 129.121 427.071 29.7887 320.3C-19.8795 266.912 -56.4028 216.84 -76.8624 173.246C-97.3284 129.639 -101.676 92.6159 -87.1793 65.2292C-72.6822 37.8425 -40.6289 22.5279 5.10533 18.4007C50.8251 14.2748 110.113 21.3419 178.906 38.601C316.487 73.1179 491.986 148.374 672.784 256.197C853.583 364.021 1005.42 483.978 1104.75 590.749C1154.42 644.137 1190.94 694.209 1211.4 737.803C1231.87 781.41 1236.22 818.433 1221.72 845.82C1207.22 873.206 1175.17 888.521 1129.44 892.648C1083.72 896.774 1024.43 889.707 955.635 872.448C818.054 837.931 642.555 762.675 461.756 654.852Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M302.768 538.532C214.325 485.788 145.153 417.495 104.805 349.739C64.448 281.969 52.9755 214.833 79.7242 164.302C106.473 113.771 166.463 89.2529 241.986 90.5903C317.493 91.9275 408.423 119.116 496.866 171.86C585.309 224.605 654.481 292.898 694.829 360.653C735.186 428.423 746.658 495.559 719.91 546.091C693.161 596.622 633.171 621.14 557.648 619.802C482.141 618.465 391.21 591.277 302.768 538.532Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M636.532 1085.7C562.883 1041.77 522.648 951.914 517.218 842.015C511.79 732.126 541.172 602.278 606.675 478.535C672.179 354.791 761.382 260.619 852.631 208.385C943.887 156.147 1037.12 145.885 1110.77 189.807C1184.42 233.729 1224.66 323.589 1230.08 433.488C1235.51 543.378 1206.13 673.226 1140.63 796.969C1075.12 920.712 985.921 1014.88 894.672 1067.12C803.416 1119.36 710.18 1129.62 636.532 1085.7Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
        <path
          d="M1864.69 1149.14C1858.98 1159.93 1848.15 1167.39 1832.67 1171.66C1817.19 1175.93 1797.1 1176.99 1772.96 1175.04C1724.66 1171.12 1660.22 1155.12 1584.13 1128.57C1431.96 1075.5 1233.28 980.316 1024.17 855.609C815.057 730.902 635.13 600.293 513.272 489.946C452.34 434.77 405.944 384.675 377.675 342.93C363.539 322.056 353.951 303.291 349.335 287.038C344.72 270.787 345.089 257.092 350.8 246.303C356.511 235.513 367.34 228.056 382.817 223.788C398.296 219.518 418.383 218.451 442.531 220.408C490.826 224.324 555.267 240.329 631.358 266.87C783.532 319.948 982.211 415.129 1191.32 539.836C1400.43 664.543 1580.36 795.151 1702.21 905.498C1763.15 960.674 1809.54 1010.77 1837.81 1052.51C1851.95 1073.39 1861.54 1092.15 1866.15 1108.41C1870.77 1124.66 1870.4 1138.35 1864.69 1149.14Z"
          stroke="currentColor"
          strokeWidth="0.864917"
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_24307_57131"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(628.794 112.292) rotate(94.6988) scale(253.327 825.841)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Box>
)

export default CustomerAdvisoryBackground
