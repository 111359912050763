import { MouseEvent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LazyQueryExecFunction, useMutation } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'

import { handleDownload } from '@utils/index'
import { AlertSeverity, useToast } from '@hooks/useToast'
import { Report } from '@models/Report'
import {
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
} from '@queries/report'
import {
  DELETE_REPORT,
  DeleteReportData,
  DeleteReportVariables,
} from '@mutations/report'
import Icon from '@common/Icon'

export interface DeleteReportModalProps {
  downloadReport: LazyQueryExecFunction<
    DownloadReportData,
    DownloadReportVariables
  >
  report: Report
  closeModal: VoidFunction
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({
  closeModal,
  downloadReport,
  report,
}) => {
  const { handleShowToast } = useToast()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [deleteReport] = useMutation<DeleteReportData, DeleteReportVariables>(
    DELETE_REPORT,
    {
      refetchQueries: [GET_REPORTS],
      onCompleted: () => {
        handleShowToast(
          AlertSeverity.Success,
          `"${report.name}" has been deleted successfully!`,
        )
      },
      onError: () => {
        handleShowToast(
          AlertSeverity.Error,
          'Your report has failed to delete, please try again in a minute.',
        )
      },
    },
  )

  const cancelAction = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    closeModal()
  }

  const handleDeleteReport = async () => {
    closeModal()

    await deleteReport({
      variables: {
        input: { reportId: report.id },
        selectedCustomer,
      },
    })
  }

  // Focus the first button when the modal opens
  useEffect(() => {
    document
      .getElementById('delete-report-modal')
      ?.querySelector('button')
      ?.focus()
  }, [])

  return (
    <Box
      id="delete-report-modal"
      data-testid="delete-report-modal"
      sx={{ padding: '1rem' }}
    >
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          paddingBottom: '1rem',
        })}
        variant="body1"
      >
        Are you sure you want to permanently delete this report?
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.5rem',
          gap: '1rem',
        }}
        data-testid="delete-report-modal-buttons"
      >
        <Button
          variant="text"
          onClick={() =>
            downloadReport({
              variables: {
                input: { reportId: report.id },
                selectedCustomer,
              },
              onCompleted: ({ downloadReport: path }) => {
                handleDownload(path)
              },
            })
          }
          sx={{ gap: 0.5 }}
        >
          Download backup
          <Icon variant="downloadOutline" />
        </Button>
        <Button variant="outlined" onClick={(e) => cancelAction(e)}>
          Cancel
        </Button>
        <Button
          onClick={handleDeleteReport}
          variant="contained"
          data-testid="delete-report-button"
        >
          Delete
        </Button>
      </Box>
    </Box>
  )
}

export default DeleteReportModal
