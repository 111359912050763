import { Box, Link, List, Typography } from '@mui/material'

import { toKebabCase } from '@utils/index'

const TIFRTableOfContents = ({ threatIntelReportSections }) => {
  const filteredHeadings = threatIntelReportSections?.filter(
    (section) => section.level < 3,
  )

  const scrollToSection = (id: string) => {
    const sectionElement = document.getElementById(id)
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <Box data-testid="report-table-of-contents">
      <Typography fontWeight={600} variant="body1">
        On this page
      </Typography>

      <List component="ol" sx={{ listStyle: 'decimal', padding: '1rem' }}>
        {filteredHeadings.map((section, index) => (
          <Link
            key={index}
            onClick={(e) => {
              e.preventDefault()
              scrollToSection(`section-${toKebabCase(section.heading)}`)
            }}
            sx={{ cursor: 'pointer', display: 'list-item' }}
          >
            {section.heading}
          </Link>
        ))}
      </List>
    </Box>
  )
}

export default TIFRTableOfContents
