import { createContext, ReactNode, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { captureException } from '@sentry/react'

import {
  TicketDataInput,
  UPDATE_TICKET,
  UpdateTicketVariables,
} from '@mutations/ticket'
import { Ticket, TicketStateEnum } from '@models/index'
import {
  GET_TICKET_STATUS,
  GET_TOP_LEVEL_TICKET_DATA,
  GetTicketDetailsVariables,
  TicketStatusResponse,
} from '@queries/ticket'
import apolloClient from '@config/apolloClient'
import { Context } from '@components/App'

import { hasValidDraftTicketFields, updateTicketInCache } from '../utils'
import { ClientSideErrors, TicketEditContextModel } from '../Types'

export const TicketEditContext = createContext<TicketEditContextModel | null>(
  null,
)

export const TicketEditContextProvider = ({
  children,
  sysId,
  isEditable,
  overrides,
}: {
  children: ReactNode
  sysId: string
  isEditable: boolean | undefined
  overrides?: Partial<TicketEditContextModel>
}) => {
  const [draftTicketFields, setDraftTicketFields] = useState<
    Partial<TicketDataInput>
  >({})

  const [isMutationSuccessful, setIsMutationSuccessful] = useState(false)
  const [clientSideErrors, setClientSideErrors] = useState<ClientSideErrors>({})

  const resetDraftTicketFields = () => setDraftTicketFields({})

  const resetClientSideErrorsField = (field?: string) => {
    setClientSideErrors((prev) => {
      delete prev[`${field}`]

      return prev
    })
  }

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer,
    },
  } = useContext(Context)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    loading: isFetchLoading,
    error: fetchError,
    data,
  } = useQuery<{ getTicketDetails: Ticket }, GetTicketDetailsVariables>(
    GET_TOP_LEVEL_TICKET_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer:
          selectedCustomer || dwExpertsCustomer.customerShortName,
        ticketId: sysId,
      },
      skip: !sysId,
    },
  )

  const ticketData = apolloClient.readQuery<
    { getTicketDetails: TicketStatusResponse },
    GetTicketDetailsVariables
  >({
    query: GET_TICKET_STATUS,
    variables: {
      ticketId: sysId,
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
    },
  })

  const [
    updateTicket,
    { loading: isSubmissionLoading, error: submissionError },
  ] = useMutation<{ updateTicket: boolean }, UpdateTicketVariables>(
    UPDATE_TICKET,
    {
      onCompleted: ({ updateTicket }) => {
        if (!updateTicket) {
          return
        }

        // Update apollo cache to have new values that the user submitted
        updateTicketInCache({
          ticketId: sysId,
          newFields: draftTicketFields,
        })

        setIsMutationSuccessful(true)

        resetDraftTicketFields()
      },
    },
  )

  const saveDraftTicketFields = (ticketFields: Partial<TicketDataInput>) => {
    const nextDraftTicketFields = {
      ...draftTicketFields,
      ...ticketFields,
    }
    setDraftTicketFields(nextDraftTicketFields)
  }

  const submitDraftTicketFields = async () => {
    const resolvedCustomerTicket =
      ticketData?.getTicketDetails.state === TicketStateEnum.Resolved &&
      !isDWEmployee
    const invalidCustomerValidateState =
      ticketData?.getTicketDetails.customerValidateState === null &&
      !draftTicketFields?.customerValidateState
    const newClientSideErrors = {}

    if (resolvedCustomerTicket && invalidCustomerValidateState) {
      newClientSideErrors['customerValidateState'] =
        'Please select validation state'
      setClientSideErrors(newClientSideErrors)
      return
    }

    try {
      await updateTicket({
        variables: {
          input: {
            ticketId: sysId,
            ticketData: {
              ...draftTicketFields,
            },
          },
        },
      })
      /**
       * Note: Try/catch block necessary for when Apollo throws errors.
       * Catch block is required with try block, but empty since
       * `useMutation` hook handles error.
       */
      // eslint-disable-next-line no-empty
    } catch (e) {
      captureException(e)
    }
  }

  const hasValidChangesToSave =
    !!Object.keys(draftTicketFields).length &&
    hasValidDraftTicketFields(draftTicketFields)

  return (
    <TicketEditContext.Provider
      value={{
        sysId,
        hasValidChangesToSave,
        saveDraftTicketFields,
        isSubmissionLoading,
        submissionError,
        submitDraftTicketFields,
        fetchError,
        isFetchLoading: isFetchLoading && !data,
        isEditable: isEditable ?? undefined,
        draftTicketFields,
        resetDraftTicketFields,
        clientSideErrors,
        resetClientSideErrorsField,
        isMutationSuccessful,
        ...overrides,
      }}
    >
      {children}
    </TicketEditContext.Provider>
  )
}
