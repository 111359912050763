import { useQuery } from '@apollo/client'
import { Box, Skeleton, SxProps, Theme, Typography } from '@mui/material'

import { MetricType } from '@models/CyberAssistant'
import { CYBER_ASSITANT_METRIC_AVERAGES } from '@queries/cyberAssistant'

import {
  formatSeconds,
  metricDescriptions,
} from '../KnowledgeBase/KnowledgeBase.utils'
import { MetricChart } from '../MetricChart/MetricChart'
import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
    whiteSpace: 'pre-line',
    textWrap: 'wrap',
  }),
})

export const getFormattedMetric = (metricType: MetricType) =>
  metricType === MetricType.MTTCACK ? 'MTTCAck' : metricType

const getFormattedMetricText = (metricType: MetricType) => {
  const formattedMetric = getFormattedMetric(metricType)

  if (metricType === MetricType.MTTD) {
    return `mean time to detect (${formattedMetric})`
  }
  if (metricType === MetricType.MTTR) {
    return `mean time to respond (${formattedMetric})`
  }
  if (metricType === MetricType.MTTCACK) {
    return `mean time to customer acknowledgment (${formattedMetric})`
  }
}

const MTTContainer: React.FC<{
  selectedCustomer: string | null
  metric: MetricType
}> = ({ selectedCustomer, metric }) => {
  const {
    data: {
      cyberAssistantMetricAverages: {
        pastQuarterAverage,
        monthlyAverages,
        comparison,
        percentageDifference,
      },
    } = {
      cyberAssistantMetricAverages: {
        pastQuarterAverage: 0,
        monthlyAverages: 0,
        comparison: 0,
        percentageDifference: 0,
      },
    },
    error,
    loading,
  } = useQuery(CYBER_ASSITANT_METRIC_AVERAGES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer,
      input: {
        metricType: metric,
      },
    },
  })

  const anyDataIsNullOrUndefined = [
    pastQuarterAverage,
    monthlyAverages,
    comparison,
    percentageDifference,
  ].some((dataPoint) => dataPoint === null || undefined)

  if (loading) {
    return (
      <Box data-testid="skeleton-loader">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    )
  }

  if (error || anyDataIsNullOrUndefined) {
    return <EmptyDataAnswer />
  }
  return (
    <>
      <Typography
        variant="body1"
        sx={(theme) => ({
          ...typographyContent(theme),
        })}
      >
        {`Your ${getFormattedMetricText(metric)} of high and critical security events in this past quarter was ${formatSeconds(pastQuarterAverage)}, which is ${percentageDifference}% ${comparison} than the previous quarter.`}
      </Typography>
      <MetricChart data={monthlyAverages} metricType={metric} />
      <Typography
        variant="body1"
        sx={(theme) => ({
          ...typographyContent(theme),
        })}
      >
        {metricDescriptions[metric as MetricType] ?? ''}
      </Typography>
      <FeedbackForm answerId={metric} />
    </>
  )
}

export default MTTContainer
