import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { MultiValue } from 'react-select'

import { OktaGroup, User } from '@models/index'
import Icon from '@common/Icon'
import { Dropdown, OptionType } from '@common/Dropdown'
import { zIndex } from '@components/App/Styles/zIndex'

import { ModalTitles } from '../UserManagementSettings'
import {
  filterAdminGroup,
  mapToListItems,
} from '../UserManagementSettings.util'

export interface AddEditUserFormValues {
  firstName: string
  lastName: string
  email: string
  title: string
  isAdmin: boolean
  groupIDs: string[]
}

interface AddEditUserFormProps {
  customerGroups: OktaGroup[]
  initialGroups: OptionType[]
  isEditingSelf: boolean
  isFederatedCustomer: boolean
  isLastAdmin: boolean
  modalState: ModalTitles
  onCancelButtonClick: VoidFunction
  onSubmit: (values: AddEditUserFormValues) => void
  user?: User
}

const AddEditUserForm: React.FC<AddEditUserFormProps> = ({
  initialGroups,
  customerGroups,
  isEditingSelf,
  isFederatedCustomer,
  isLastAdmin,
  modalState,
  onCancelButtonClick,
  onSubmit,
  user,
}) => {
  const firstInputRef = useRef<HTMLInputElement>(null)
  const handlePermissionsSelect = (newValues: MultiValue<OptionType>) => {
    setFieldValue(
      'groupIDs',
      newValues.map((value) => value.value),
    )
  }

  const AddEditUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'First name must be between 1 and 256 characters.')
      .max(256, 'First name must be between 1 and 256 characters.')
      .required('First name is required.'),
    lastName: Yup.string()
      .min(1, 'Last name must be between 1 and 256 characters.')
      .max(256, 'Last name must be between 1 and 256 characters.')
      .required('Last name is required.'),
    email: Yup.string()
      .email('Enter a valid email address.')
      .required('Email is required.'),
    title: Yup.string()
      .min(1, 'Title must be between 1 and 256 characters.')
      .max(256, 'Title must be between 1 and 256 characters.'),
  })

  const initialValues: AddEditUserFormValues = {
    firstName: user?.firstName ? user.firstName : '',
    lastName: user?.lastName ? user.lastName : '',
    email: user?.email ? user.email : '',
    title: user?.title ? user.title : '',
    isAdmin: user?.isAdmin ? user.isAdmin : false,
    groupIDs: user?.groupIDs ? user.groupIDs : [],
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    errors,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => onSubmit(values),
    validationSchema: AddEditUserSchema,
  })

  const appGroupOptions = mapToListItems(customerGroups)

  // Focus the first input when the modal opens
  useEffect(() => {
    firstInputRef.current?.focus()
  }, [])

  return (
    <Box data-testid="user-form">
      <TextField
        inputRef={firstInputRef}
        sx={{ marginBottom: '1.5rem' }}
        required
        error={errors.firstName !== undefined && touched.firstName}
        helperText={errors.firstName}
        label={'First Name'}
        placeholder={'Enter First Name'}
        value={values.firstName}
        name="firstName"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <TextField
        sx={{ marginBottom: '1.5rem' }}
        required
        error={errors.lastName !== undefined && touched.lastName}
        helperText={errors.lastName}
        label={'Last Name'}
        placeholder={'Enter Last Name'}
        value={values.lastName}
        name="lastName"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <TextField
        sx={{ marginBottom: '1.5rem' }}
        required
        disabled={modalState === ModalTitles.ADD ? false : true}
        error={errors.email !== undefined && touched.email}
        helperText={errors.email}
        label={'Email'}
        placeholder={'Enter Email Address'}
        value={values.email}
        name="email"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <TextField
        sx={{ marginBottom: '1.5rem' }}
        required
        label={'Job Title (optional)'}
        placeholder={'Enter Job Title'}
        error={errors.title !== undefined && touched.title}
        helperText={errors.title}
        value={values.title}
        name="title"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <Typography color="textPrimary" variant="h6">
        Permissions
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        data-testid="permissions-container"
        id="permissions-admin-content"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography color="textPrimary" variant="body2">
              Administrator
            </Typography>

            <Tooltip
              followCursor
              placement="top"
              title="Administrators manage user application access and can promote other admins; these permissions can change at any time"
              PopperProps={{
                sx: {
                  zIndex: zIndex.MAX,
                },
              }}
              slotProps={{
                arrow: {
                  sx: (theme) => ({
                    color: theme.vars.palette.common.white,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.secondary.darker,
                    }),
                  }),
                },
              }}
            >
              <Box>
                <Icon
                  variant="informationCircleOutline"
                  sx={{ verticalAlign: 'middle', pl: '4px' }}
                />
              </Box>
            </Tooltip>
          </Box>

          <Switch
            name="User Privelege"
            onClick={() => setFieldValue('isAdmin', !values.isAdmin)}
            checked={values.isAdmin}
            disabled={isLastAdmin && isEditingSelf}
          />
        </Box>

        {isEditingSelf && isLastAdmin && (
          <Typography
            color="textPrimary"
            sx={{ position: 'relative', bottom: '14px' }}
            variant="caption"
          >
            You cannot disable this user&apos;s admin permissions because there
            is only one admin
          </Typography>
        )}
      </Box>

      <Box
        data-testid="application-groups"
        sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
      >
        <Typography fontWeight={600}>Application Groups</Typography>

        <Dropdown
          defaultValue={filterAdminGroup(initialGroups)}
          id="application-groups"
          aria-label="application-groups"
          isMulti
          onChange={handlePermissionsSelect}
          options={filterAdminGroup(appGroupOptions)}
          placeholder="Select groups"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 0.5,
          padding: '1rem 0',
        }}
      >
        <Button variant="outlined" onClick={onCancelButtonClick}>
          Cancel
        </Button>
        <Button
          disabled={!isValid || !dirty}
          onClick={() => handleSubmit()}
          variant="contained"
        >
          {modalState === ModalTitles.ADD ? 'Add' : 'Save'}
        </Button>
      </Box>
    </Box>
  )
}

export default AddEditUserForm
