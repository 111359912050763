const CustomerAdvisoryRead = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34848 2.216C4.9655 2.216 4.59822 2.36814 4.32741 2.63894C4.05661 2.90974 3.90448 3.27703 3.90448 3.66V17.34C3.90448 17.723 4.05661 18.0903 4.32741 18.3611C4.59822 18.6319 4.9655 18.784 5.34848 18.784H9.9731C10.2818 19.237 10.6502 19.6461 11.0668 20H5.34848C4.643 20 3.96642 19.7198 3.46757 19.2209C2.96873 18.7221 2.68848 18.0455 2.68848 17.34V3.66C2.68848 2.95452 2.96873 2.27794 3.46757 1.7791C3.96642 1.28025 4.643 1 5.34848 1H9.57004H9.57013C10.094 1.00008 10.5964 1.2082 10.9669 1.5786L10.967 1.57865L17.0058 7.61751L17.0059 7.61756C17.3763 7.98805 17.5844 8.49046 17.5845 9.01435L17.5845 9.01444V9.85876C17.1995 9.67778 16.7925 9.53598 16.3685 9.43852V9.056H11.5045C10.9804 9.056 10.4778 8.84782 10.1072 8.47724C9.73666 8.10667 9.52848 7.60407 9.52848 7.08V4.63993V4.63993V2.216H5.34848ZM10.5149 11.26H6.71648C6.38069 11.26 6.10848 11.5322 6.10848 11.868C6.10848 12.2038 6.38069 12.476 6.71648 12.476H9.64919C9.88767 12.035 10.1791 11.6268 10.5149 11.26ZM8.95885 14.68H6.71648C6.38069 14.68 6.10848 14.9522 6.10848 15.288C6.10848 15.6238 6.38069 15.896 6.71648 15.896H8.94374C8.92855 15.7207 8.92079 15.5433 8.92079 15.3641C8.92079 15.1328 8.93371 14.9046 8.95885 14.68ZM15.0469 7.84H15.5086L10.7445 3.07584V5.34507V7.08C10.7445 7.28156 10.8245 7.47487 10.9671 7.6174C11.1096 7.75993 11.3029 7.84 11.5045 7.84H15.0469L10.7445 5.34507L15.0469 7.84Z"
      fill="currentcolor"
    />
    <path
      d="M19.7727 15.5448C19.7727 12.9957 17.7045 10.9275 15.1554 10.9275C12.6063 10.9275 10.5381 12.9957 10.5381 15.5448C10.5381 18.0939 12.6063 20.1621 15.1554 20.1621C17.7045 20.1621 19.7727 18.0939 19.7727 15.5448Z"
      stroke="currentcolor"
      strokeMiterlimit="10"
    />
    <path
      d="M15.0172 13.3818L15.1553 16.3146L15.2931 13.3818C15.2939 13.3631 15.2909 13.3444 15.2843 13.3268C15.2777 13.3093 15.2675 13.2932 15.2545 13.2797C15.2415 13.2662 15.2258 13.2555 15.2085 13.2483C15.1912 13.241 15.1726 13.2374 15.1538 13.2376V13.2376C15.1353 13.2377 15.117 13.2416 15.1 13.2491C15.083 13.2565 15.0676 13.2672 15.0549 13.2807C15.0421 13.2942 15.0322 13.3101 15.0258 13.3274C15.0193 13.3448 15.0164 13.3633 15.0172 13.3818V13.3818Z"
      stroke="currentcolor"
      strokeWidth="0.769552"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1553 18.2361C15.0602 18.2361 14.9672 18.2079 14.8881 18.1551C14.809 18.1022 14.7473 18.0271 14.7109 17.9392C14.6745 17.8513 14.665 17.7546 14.6836 17.6613C14.7021 17.568 14.7479 17.4823 14.8152 17.415C14.8825 17.3478 14.9682 17.302 15.0615 17.2834C15.1548 17.2649 15.2515 17.2744 15.3393 17.3108C15.4272 17.3472 15.5023 17.4088 15.5552 17.4879C15.608 17.567 15.6363 17.66 15.6363 17.7551C15.6363 17.8827 15.5856 18.005 15.4954 18.0952C15.4052 18.1854 15.2828 18.2361 15.1553 18.2361Z"
      fill="currentcolor"
    />
  </svg>
)

export default CustomerAdvisoryRead
