import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Box } from '@mui/material'

import { CustomerHighlight } from '@models/index'
import { DeepwatchExpertCustomerTable } from '@components/DeepwatchExperts/DeepwatchExpertCustomerTable'

interface AllCustomersProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
}

export const AllCustomers: React.FC<AllCustomersProps> = ({
  customerHighlights,
  loading,
}) => {
  return (
    <Box data-testid="all-customers" id="all-customers">
      <DeepwatchExpertCustomerTable
        customerHighlights={cloneDeep(customerHighlights)}
        disableRowClick={false}
        disableAddedTags={false}
        loading={loading}
      />
    </Box>
  )
}

export default AllCustomers
