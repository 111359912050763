import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useTicketsContext } from '@hooks/index'
import { zIndex } from '@components/App/Styles/zIndex'

import { TicketEditContextProvider } from './context/TicketEditContext'
import Body from './components/Body'
import Header from './components/Header'
import PermissionWarning from './components/PermissionWarning'

export default function TicketEdit() {
  const { sysId = '' } = useParams()
  const { isEditable } = useTicketsContext()

  return (
    //NOTE: isEditable will be updated in follow on work once logic is available from backend
    <TicketEditContextProvider sysId={sysId} isEditable={isEditable}>
      <Box data-testid="ticket-edit" id="ticket-edit">
        <Box sx={{ position: 'sticky', top: 0, zIndex: zIndex.DROPDOWN_MENU }}>
          <PermissionWarning />
          <Header />
        </Box>

        <Body />
      </Box>
    </TicketEditContextProvider>
  )
}
