import {
  arrow,
  autoUpdate,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { useRef, useState } from 'react'
import { Box } from '@mui/material'

import PopoverContent from '@common/PopoverContent'
import { NotificationsIconBadge } from '@components/Layout/Sidebar/Notifications'
import { useNotificationsContext } from '@components/Layout/NotificationsContext'
import NotificationsEmptyState from '@components/Layout/Sidebar/Notifications/NotificationsEmptyState'
import NotificationItem from '@components/Layout/Sidebar/Notifications/NotificationItem'
import { Loader } from '@common/Loader'

const NotificationsNavItem = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const arrowRef = useRef<SVGSVGElement>(null)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      offset(10),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 12 }), // Padding for border-radius
    ],
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const {
    notifications,
    loadingNotifications,
    fetchMoreNotifications,
    fetchingMore,
    toggleNotificationReadStatus,
  } = useNotificationsContext()

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target

    if (!fetchingMore && scrollTop + clientHeight + 10 >= scrollHeight) {
      fetchMoreNotifications()
    }
  }

  return (
    <Box
      data-testid="notification-nav-item"
      ref={refs.setReference}
      role="option"
      aria-selected={isMenuOpen}
      tabIndex={0}
      {...getReferenceProps()}
    >
      <NotificationsIconBadge isActive={isMenuOpen} />

      {isMenuOpen && (
        <FloatingFocusManager context={context}>
          <PopoverContent
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            setFloating={refs.setFloating}
            sx={{ borderRadius: '12px' }}
            arrowProps={{ arrowRef, context }}
          >
            <div
              style={{
                padding: '0.5rem',
                minHeight: '200px',
                minWidth: '400px',
              }}
              data-testid="notifications-drawer-content"
              onScroll={handleScroll}
              onClick={(e) => e.stopPropagation()} // Prevent the popover from closing when clicking inside the popover
              role="presentation"
            >
              {notifications.length === 0 ? (
                <NotificationsEmptyState />
              ) : (
                <>
                  {notifications.map((notification) => (
                    <NotificationItem
                      notification={notification}
                      key={notification.id}
                      loadingNotifications={loadingNotifications}
                      toggleNotificationReadStatus={
                        toggleNotificationReadStatus
                      }
                    />
                  ))}
                  {fetchingMore && (
                    <div
                      className="pagination-loader-container"
                      data-testid="notifications-loader-container"
                    >
                      <Loader centered={true} strokeWidth={2} size={24} />
                    </div>
                  )}
                </>
              )}
            </div>
          </PopoverContent>
        </FloatingFocusManager>
      )}
    </Box>
  )
}

export default NotificationsNavItem
