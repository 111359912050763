import { Chip, SxProps, Theme } from '@mui/material'

import type { MouseEventHandler } from 'react'

interface ThreatIntelTagProps {
  /** The text to be displayed in the `<ThreatIntelTag />` */
  text?: string
  /** If `true`, bolds the text of the `<Tag />` */
  highlighted?: boolean
  /** An optional function which will be called on a `click` or `keydown` event */
  onClickCallback?: MouseEventHandler<HTMLDivElement>
  sx?: SxProps<Theme>
}

export const ThreatIntelTag: React.FC<ThreatIntelTagProps> = (props) => (
  <Chip
    data-testid={`tag-new-${(props.text ?? 'no_text_supplied').trim().toLowerCase().replace(' ', '-')}`}
    variant="outlined"
    label={props.text}
    sx={[
      (theme) => ({
        '&.MuiChip-root': {
          height: '100%',
        },
        '&.MuiChip-outlined': {
          ...(props.highlighted
            ? {
                backgroundColor: theme.vars.palette.primary.dark,
                borderColor: theme.vars.palette.primary.dark,
              }
            : { backgroundColor: theme.vars.palette.primary.main }),
          ':hover': {
            backgroundColor: theme.vars.palette.primary.dark,
            borderColor: theme.vars.palette.primary.dark,
          },
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.primary,
            borderColor: theme.vars.palette.secondary.main,
            backgroundColor: theme.vars.palette.secondary.darker,
          }),
        },
        '.MuiChip-label': {
          fontWeight: props.highlighted ? '700 !important' : 'initial',
          whiteSpace: 'wrap',
          ...theme.applyStyles('dark', {
            color: props.highlighted
              ? theme.vars.palette.text.secondary
              : theme.vars.palette.text.primary,
          }),
        },
        '&.MuiChip-clickable:hover': {
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.light,
            borderColor: theme.vars.palette.secondary.light,
          }),
        },
        transition: 'none',
      }),
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
    ]}
    onClick={props.onClickCallback}
  />
)
