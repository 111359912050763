import { useMutation } from '@apollo/client'
import { Button, Typography, Box } from '@mui/material'

import {
  DELETE_USER,
  DeleteUserData,
  DeleteUserVariables,
} from '@mutations/user'
import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'
import { AlertSeverity, useToast } from '@hooks/useToast'

export interface DeleteUserModalProps {
  userId: string
  userName: string
  closeModal: VoidFunction
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  closeModal,
  userId,
  userName,
}) => {
  const { handleShowToast } = useToast()

  const [deleteUser] = useMutation<DeleteUserData, DeleteUserVariables>(
    DELETE_USER,
    {
      refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
    },
  )

  const toggleModal = () => {
    closeModal()
  }

  const displaySuccessToast = () => {
    handleShowToast(AlertSeverity.Success, 'User deleted!')
  }

  const displayProcessingToast = () => {
    handleShowToast(AlertSeverity.Info, 'Processing request to delete user')
  }

  const displayErrorToast = () => {
    handleShowToast(
      AlertSeverity.Error,
      'Deleting user has failed. Please try again in a minute.',
    )
  }

  const handleDeleteUser = async () => {
    toggleModal()
    displayProcessingToast()

    await deleteUser({
      variables: {
        input: {
          userId,
        },
      },
      onCompleted: displaySuccessToast,
      onError: displayErrorToast,
    })
  }
  return (
    <Box
      sx={{ padding: '1.5rem' }}
      id="delete-user-modal"
      data-testid="delete-user-modal"
    >
      <Typography
        fontWeight={600}
        color="textSecondary"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body1"
      >
        {`Are you sure you want to delete ${userName}? They will no longer have access once deleted.`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: 1,
          marginTop: '25px',
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          data-testid="user-status-cancel-btn"
          variant="outlined"
          onClick={() => toggleModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid="user-status-button"
          onClick={() => handleDeleteUser()}
        >
          Delete
        </Button>
      </Box>
    </Box>
  )
}

export default DeleteUserModal
