import { Box, Button } from '@mui/material'

import Icon from '@common/Icon'

interface CreateTicketButtonProps {
  classes?: string
}

const CreateTicketButton: React.FC<CreateTicketButtonProps> = () => {
  const SERVICE_NOW_CUSTOMER_SERVICE_URL =
    'https://deepwatch.service-now.com/csm?id=sc_category&sys_id=f60aecac1b122410b05ffd9f034bcbbf'

  return (
    <Box data-testid="create-ticket-button-container">
      <Button
        onClick={() =>
          window.open(`${SERVICE_NOW_CUSTOMER_SERVICE_URL}`, '_blank')
        }
        size="small"
        variant="contained"
        sx={{ gap: 0.5 }}
      >
        Create Ticket
        <Icon size={16} variant="openOutline" />
      </Button>
    </Box>
  )
}
export default CreateTicketButton
