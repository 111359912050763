export enum FORWARDER_TYPE {
  HEAVY = 'HEAVY',
  UNIVERSAL = 'UNIVERSAL',
}

export enum FORWARDER_STATUS {
  ACTIVE = 'ACTIVE',
  AT_RISK = 'AT_RISK',
}
export interface Forwarder {
  hostname: string
  version: string
  os: string
  type: FORWARDER_TYPE
  status: FORWARDER_STATUS
  lastIndexConnection: string
}
