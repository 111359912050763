import { useState } from 'react'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { FormControlLabel, IconButton, Switch } from '@mui/material'

import PopoverContent from '@common/PopoverContent'
import Icon from '@common/Icon'

export interface SuToggleProps {
  disablePopover: boolean
  pastPeriodChart: boolean
  setPastPeriodChart: React.Dispatch<React.SetStateAction<boolean>>
}

const SuToggle: React.FC<SuToggleProps> = ({
  disablePopover,
  pastPeriodChart,
  setPastPeriodChart,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(8)],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left',
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  return (
    <>
      <IconButton
        disabled={disablePopover}
        ref={refs.setReference}
        {...getReferenceProps()}
        data-testid="pastPeriodToggle"
      >
        <Icon variant="ellipsisVertical" />
      </IconButton>

      {isOpen && (
        <FloatingFocusManager context={context}>
          <PopoverContent
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            setFloating={refs.setFloating}
          >
            <FormControlLabel
              className="switch-label"
              tabIndex={0}
              labelPlacement="start"
              onKeyUp={() => setPastPeriodChart(!pastPeriodChart)}
              sx={(theme) => ({
                '.MuiFormControlLabel-label': {
                  color: theme.palette.text.primary,
                },
                margin: '0 8px 0',
                padding: '12px',
              })}
              control={
                <Switch
                  onClick={() => setPastPeriodChart(!pastPeriodChart)}
                  checked={pastPeriodChart}
                  sx={{
                    marginLeft: '8px',
                  }}
                />
              }
              label="Compare to previous period"
            />
          </PopoverContent>
        </FloatingFocusManager>
      )}
    </>
  )
}

export default SuToggle
