import { useRef } from 'react'
import { Box, IconButton } from '@mui/material'

import { SideSheet, sideSheetHeaderCss } from '@common/SideSheet'
import { Detections } from '@models/Detections'
import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../context/MitreOverviewContext'

export enum SideSheetArrowDirection {
  UP = 'up',
  DOWN = 'down',
}

export type MitreSideSheetPropsDiscriminatedUnion =
  | {
      animationEnabled?: true
      onArrowClick?(direction: SideSheetArrowDirection): void
      animationDirection?: SideSheetArrowDirection | undefined
      setAnimationDirection?(value: SideSheetArrowDirection | undefined): void
    }
  | {
      animationEnabled?: false
      onArrowClick?: never
      animationDirection?: never
      setAnimationDirection?: never
    }

export interface BaseMitreSideSheetProps {
  open: boolean
  closeSideSheet: () => void
  children: React.ReactNode
  footer: React.ReactNode
}

export type MitreSideSheetProps = BaseMitreSideSheetProps &
  MitreSideSheetPropsDiscriminatedUnion

const MitreSideSheet: React.FC<MitreSideSheetProps> = ({
  open,
  closeSideSheet,
  children,
  footer,
  animationEnabled,
}) => {
  const {
    sideSheetData,
    focusedDetection,
    detections,
    handleSideSheet,
    setFocusedDetection,
    setPreviousMitreSelection,
    previousMitreSelection,
    setMitreSelection,
  } = useMitreOverviewContext()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const handleArrowClick = (direction: SideSheetArrowDirection) => {
    const currentIndex = detections.indexOf(focusedDetection as Detections)
    let newIndex = currentIndex

    // Update index based on direction
    if (direction === SideSheetArrowDirection.UP) {
      newIndex = Math.max(0, currentIndex - 1) // Ensure it's not less than 0
    } else if (direction === SideSheetArrowDirection.DOWN) {
      newIndex = Math.min(detections.length - 1, currentIndex + 1) // Ensure it doesn't exceed array length
    }

    // Update focused detection
    const newFocusedDetection = detections[Number(newIndex)]
    setFocusedDetection(newFocusedDetection)

    // Call handleSideSheet with the new focused detection
    handleSideSheet(0, undefined, undefined, () => {}, newFocusedDetection)

    // Scroll to top of the container once the new detection is focused
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0
    }
  }

  const closeSideSheetFunc = () => {
    closeSideSheet()
    // Setting state to show the previous selection
    setPreviousMitreSelection({
      ...previousMitreSelection,
      subtechnique: undefined,
    })
    setMitreSelection({
      tactic: undefined,
      technique: undefined,
      subtechnique: undefined,
    })
  }
  return (
    <SideSheet
      open={open}
      closeSideSheet={closeSideSheet}
      data-testid={`mitre-sidesheet-${open ? 'open' : 'closed'}`}
      footer={footer}
      renderTitle={() => (
        <Box sx={sideSheetHeaderCss.header}>
          <Box sx={sideSheetHeaderCss.headerContent}>
            <Box
              sx={{
                ...sideSheetHeaderCss.titleContent,
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {sideSheetData.title}
              {sideSheetData.title === 'Detection detail' &&
                animationEnabled && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      paddingRight: '12px',
                    }}
                  >
                    <IconButton
                      data-testid="mitre-detection-up-arrow"
                      onClick={() =>
                        handleArrowClick(SideSheetArrowDirection.UP)
                      }
                      disabled={focusedDetection === detections[0]}
                    >
                      <Icon
                        sx={(theme) => ({
                          color:
                            focusedDetection === detections[0]
                              ? theme.vars.palette.text.disabled
                              : theme.vars.palette.text.primary,
                        })}
                        variant="arrowUpCircleOutline"
                      />
                    </IconButton>

                    <IconButton
                      data-testid="mitre-detection-down-arrow"
                      onClick={() =>
                        handleArrowClick(SideSheetArrowDirection.DOWN)
                      }
                      disabled={
                        focusedDetection === detections[detections.length - 1]
                      }
                    >
                      <Icon
                        sx={(theme) => ({
                          color:
                            focusedDetection ===
                            detections[detections.length - 1]
                              ? theme.vars.palette.text.disabled
                              : theme.vars.palette.text.primary,
                        })}
                        variant="arrowDownCircleOutline"
                      />
                    </IconButton>
                  </Box>
                )}
            </Box>

            <IconButton onClick={closeSideSheetFunc} disabled={!open}>
              <Icon variant="closeOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
    >
      <Box
        id="mitre-sidesheet"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'start',
          width: '100%',
        }}
        data-testid="mitre-sidesheet-testid"
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
          ref={scrollContainerRef}
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </SideSheet>
  )
}

export default MitreSideSheet
