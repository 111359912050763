import { RefObject, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table'
import { Typography } from '@mui/material'

import { ErrorLabel } from '@common/ErrorLabel'
import TicketIdCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketIdCell'
import TicketLastUpdatedCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketLastUpdatedCell'
import { GET_TICKETS_WAITING_ON_ME } from '@queries/ticket'
import { TableHeader } from '@common/Table'
import { useOutsideElementClick } from '@hooks/useOutsideElementClick'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'
import { invertColumnSortDirection } from '@utils/tableUtils'

import {
  buildGenericHeader,
  buildSkeletonRows,
} from '../../../../common/Table/utils/table.utils'
import {
  Ticket,
  TicketRelatedUnion,
  TicketSortingInput,
} from '../../../../models'
import { handleOnRowSelectionChange } from '../TicketOverview'

interface TicketsWaitingOnMeProps {
  selectedCustomer?: string | null
  onTicketSelected?: (ticket: Ticket | undefined) => void
}

const TicketsWaitingOnMe = ({
  selectedCustomer,
  onTicketSelected,
}: TicketsWaitingOnMeProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'sysUpdatedOn',
      desc: false,
    },
  ])
  const formattedSorting = invertColumnSortDirection<TicketRelatedUnion>(
    sorting,
    ['sysCreatedOn', 'sysUpdatedOn'],
  ).reduce<TicketSortingInput>(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur.desc ? 'DESC' : 'ASC',
    }),
    {},
  )
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const {
    loading,
    error,
    data: { getTickets: { pagination, tickets } } = {
      getTickets: { pagination: { total: 0 }, tickets: [] },
    },
  } = useQuery(GET_TICKETS_WAITING_ON_ME, {
    variables: {
      selectedCustomer: selectedCustomer,
      selectedSortings: formattedSorting,
      pagination: {
        limit: 50, //Since there is no filter in ticket library for contactUser, we are fetching all tickets
        offset: 0,
      },
    },
    pollInterval: 120000, // Poll every 2 minutes
  })

  const columnDef: ColumnDef<TicketRelatedUnion, any>[] = useMemo(() => {
    const columnHelper = createColumnHelper<TicketRelatedUnion>()
    return [
      {
        id: 'ticketNumber',
        accessorFn: (row) => ({
          ticketNumber: row.ticketNumber,
          shortDescription: row.shortDescription,
        }),
        cell: TicketIdCell,
        header: () => buildGenericHeader('Ticket ID'),
        minSize: 100,
      },
      columnHelper.accessor('sysUpdatedOn', {
        id: 'sysUpdatedOn',
        cell: (props) =>
          props.getValue() ? (
            <TicketLastUpdatedCell {...props} />
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Last Updated'),
        size: 130,
      }),
    ]
  }, [])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<TicketRelatedUnion>({
    columns: columnDef,
    data: tickets,
    enableSortingRemoval: false,
    getRowId: (row) => row.sysId,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
    manualSorting: true,
    manualFiltering: true,
    state: {
      sorting,
      rowSelection,
      columnSizing,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: (valueFn) =>
      handleOnRowSelectionChange(
        valueFn,
        rowSelection,
        setRowSelection,
        tickets,
        onTicketSelected,
      ),
    onColumnSizingChange,
    columnResizeMode: 'onChange',
  })

  const tbodyRef = useOutsideElementClick(() =>
    setRowSelection({}),
  ) as RefObject<HTMLTableSectionElement>

  const renderContent = () => {
    if (error) {
      return (
        <tr tabIndex={0}>
          <StyledTableCell
            align="center"
            colSpan={columnDef.length}
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.secondary.light,
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              padding: '2rem',
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.dark,
              }),
            })}
          >
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{ paddingBottom: 1 }}
            >
              No data.
            </Typography>

            <Typography color="textPrimary" variant="body1">
              There are too many data points that are incomplete or missing.
              Please check again later.
            </Typography>
          </StyledTableCell>
        </tr>
      )
    }

    if (loading && !tickets.length) {
      return buildSkeletonRows(table.getAllColumns(), 5)
    }

    if (!loading && !tickets.length) {
      return (
        <tr key="empty-state" tabIndex={0}>
          <StyledTableCell
            align="center"
            colSpan={columnDef.length}
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.secondary.light,
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              padding: '2rem',
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.dark,
              }),
            })}
          >
            <Typography color="textPrimary" variant="body2">
              You&apos;re all caught up
            </Typography>
          </StyledTableCell>
        </tr>
      )
    }

    return table
      .getRowModel()
      .rows.map((row) => (
        <MemoizedTanStackTableRow
          key={row.id}
          rowId={`tickets-waiting-on-me-row-${row.id}`}
          testId="tickets-waiting-on-me-row"
          isSelected={row.getIsSelected()}
          toggleSelected={row.toggleSelected}
          visibleCells={row.getVisibleCells()}
          linkTo={`/tickets/library/${row.original.sysId}/edit`}
        />
      ))
  }

  return (
    <>
      <Typography
        color="textPrimary"
        fontWeight={600}
        variant="body1"
        sx={{ padding: '1rem 0' }}
      >
        Tickets waiting on me (
        {loading && !tickets.length ? '--' : pagination.total})
      </Typography>

      <CommonTableContainer ref={tableContainerRef}>
        <CommonTable>
          <thead data-testid="tickets-waiting-on-me-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<TicketRelatedUnion>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>
          <tbody ref={tbodyRef} data-testid="tickets-waiting-on-me-body">
            {renderContent()}
          </tbody>
        </CommonTable>
      </CommonTableContainer>
    </>
  )
}

export default TicketsWaitingOnMe
