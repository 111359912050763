const StatusInProgress = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <circle
      cx="12"
      cy="12"
      r="9"
      transform="rotate(180 12 12)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="1 5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 12C21.75 10.7196 21.4978 9.45176 21.0078 8.26884C20.5178 7.08591 19.7997 6.01108 18.8943 5.10571C17.9889 4.20034 16.9141 3.48216 15.7312 2.99217C14.5482 2.50219 13.2804 2.25 12 2.25H11.25V3V3.79049C11.497 3.76822 11.7472 3.75684 12 3.75684C12.2528 3.75684 12.503 3.76822 12.75 3.79049V3.78416C13.5763 3.85959 14.3879 4.05938 15.1571 4.37799C16.1581 4.79259 17.0675 5.40029 17.8336 6.16637C18.5997 6.93245 19.2074 7.84193 19.622 8.84286C20.0366 9.8438 20.25 10.9166 20.25 12C20.25 13.0834 20.0366 14.1562 19.622 15.1571C19.2074 16.1581 18.5997 17.0675 17.8336 17.8336C17.0675 18.5997 16.1581 19.2074 15.1571 19.622C14.3879 19.9406 13.5763 20.1404 12.75 20.2158V20.2124C12.503 20.2347 12.2528 20.2461 12 20.2461C11.7472 20.2461 11.497 20.2347 11.25 20.2124V21V21.75H12C13.2804 21.75 14.5482 21.4978 15.7312 21.0078C16.9141 20.5178 17.9889 19.7997 18.8943 18.8943C19.7997 17.9889 20.5178 16.9141 21.0078 15.7312C21.4978 14.5482 21.75 13.2804 21.75 12Z"
      fill="currentColor"
    />
  </svg>
)

export default StatusInProgress
