const Error = ({ fill, stroke }) => (
  <svg
    data-testid="general-error-icon"
    viewBox="0 0 227 183"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4094_221500"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="227"
      height="183"
    >
      <rect x="0.5" width="226" height="183" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4094_221500)">
      <path
        d="M9.03598 102.257L137.03 176.154C139.109 177.354 141.692 178.222 144.526 178.674C147.36 179.125 150.349 179.144 153.199 178.729C156.05 178.314 158.665 177.479 160.79 176.305C162.915 175.132 164.476 173.661 165.32 172.035L220.174 66.4724C221.47 63.9764 221.211 59.9767 221.211 49.4767C217.821 45.3587 207.653 52.758 199.011 54.2537L16.1706 85.9234C13.3547 86.4112 10.8061 87.3124 8.77401 88.539C6.21116 86.4763 4.21118 83.4769 4.21095 86.4771C4.21095 90.4769 3.66069 95.8108 4.67244 97.9289C5.45403 99.5652 6.95751 101.056 9.03598 102.257Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M4.21045 96.4766L4.21045 81.9766"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M9.03586 88.9119L137.03 162.809C139.109 164.009 141.692 164.877 144.526 165.329C147.36 165.78 150.349 165.799 153.199 165.384C156.05 164.969 158.665 164.134 160.79 162.96C162.914 161.787 164.475 160.316 165.32 158.69L220.174 53.1273C224.06 45.6414 211.977 38.6648 199.01 40.9087L16.1704 72.5784C13.3545 73.0662 10.806 73.9674 8.77388 75.194C6.74177 76.4206 5.29559 77.9306 4.57669 79.5764C3.85778 81.2223 3.89072 82.9476 4.67231 84.5839C5.4539 86.2202 6.95739 87.7114 9.03586 88.9119Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2.42623"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.067 70.2361L121.411 97.9298L169.355 72.7337C169.661 72.5723 169.907 72.3767 170.078 72.1586C170.248 71.9405 170.34 71.7047 170.347 71.4655C170.355 71.2262 170.278 70.9886 170.121 70.7672C169.964 70.5458 169.73 70.3452 169.434 70.1776C169.143 70.013 168.798 69.8837 168.42 69.7974C168.041 69.711 167.636 69.6693 167.229 69.6748C166.822 69.6802 166.421 69.7327 166.05 69.8291C165.678 69.9255 165.344 70.0639 165.067 70.2361Z"
        stroke={stroke}
        strokeWidth="9.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.4377 112.928C94.7445 112.528 94.2725 112.018 94.0812 111.463C93.89 110.907 93.9881 110.332 94.3633 109.809C94.7384 109.286 95.3737 108.839 96.1888 108.525C97.0038 108.211 97.9621 108.043 98.9424 108.043C99.9227 108.043 100.881 108.211 101.696 108.525C102.511 108.839 103.146 109.286 103.522 109.809C103.897 110.332 103.995 110.908 103.804 111.463C103.612 112.018 103.14 112.528 102.447 112.928C101.518 113.464 100.257 113.766 98.9424 113.766C97.6279 113.766 96.3672 113.464 95.4377 112.928Z"
        stroke={stroke}
        strokeWidth="9.25"
      />
    </g>
  </svg>
)

export default Error
