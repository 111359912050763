import React, { createContext, useContext } from 'react'

import { Context } from '@components/App/Provider'
import { Customer } from '@models/index'

export interface UseCustomerReturn {
  customer: Customer
  isLoading: boolean
  isAdmin: boolean
  isDWEmployee?: boolean
}

/**
 * @description A simple hook to get customer data, taking into account if the user is a Deepwatch user
 * @returns {UseCustomerReturn} The logged-in customer if the user is **not** a Deepwatch employee, or the selected customer if the user **is** a Deepwatch employee, and the loading state of the customer data
 */
export const useCustomer = (): UseCustomerReturn => {
  const {
    state: {
      user: { isDWEmployee, isAdmin },
      dwExpertsCustomer,
      customer,
      customerLoading,
    },
  } = useContext(Context)

  return {
    customer: isDWEmployee ? dwExpertsCustomer : customer,
    isDWEmployee,
    isLoading: customerLoading,
    isAdmin,
  }
}

const AwaitCustomerContext = createContext<boolean>(false)

/**
 * @description A simple hook to get the customer data, ensuring that the data has been loaded before returning
 * @returns {Customer} The logged-in customer if the user is **not** a Deepwatch employee, or the selected customer if the user **is** a Deepwatch employee
 * @throws If the hook is not used within an <AwaitCustomer /> component, an error will be thrown
 */
export const useAwaitedCustomer = (): Customer => {
  const { customer } = useCustomer()
  const isWithinAwaitCustomer = useContext(AwaitCustomerContext)

  if (!isWithinAwaitCustomer) {
    throw new Error(
      'The useAwaitedCustomer hook must be used within an <AwaitCustomer /> component. If you do not need the guarantee that customer data will be loaded within this component, use the useCustomer hook instead, and check its returned isLoading state.',
    )
  }

  return customer
}

interface AwaitCustomerProps {
  children: React.ReactNode
  loadingComponent?: React.ReactNode
}

/**
 * @description A simple component to wait for customer data to load before rendering children
 * @param {AwaitCustomerProps} props The children to render once customer data has loaded
 * @param {React.ReactNode} props.children The children to render once customer data has loaded
 * @param {React.ReactNode} [props.loadingComponent] Optionally, a component to render while the customer data is loading
 * @returns {React.FC<AwaitCustomerProps>} The children to render once customer data has loaded, or `null if the data is still loading
 */
export const AwaitCustomer: React.FC<AwaitCustomerProps> = ({
  children,
  loadingComponent,
}) => {
  const { isLoading } = useCustomer()
  return (
    <AwaitCustomerContext.Provider value={true}>
      {isLoading ? loadingComponent || null : children}
    </AwaitCustomerContext.Provider>
  )
}
