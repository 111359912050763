import { Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { Box } from '@mui/material'

import { NotificationsContextProvider } from '@components/Layout/NotificationsContext'
import { Context } from '@components/App'
import { BetaAgreementModal } from '@components/BetaAgreementModal/BetaAgreementModal'
import { AwaitCustomer } from '@hooks/useCustomer'

import { TopNav } from './TopNav'
import NavSidebar from './NavSidebar'

const Layout = () => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  return (
    <NotificationsContextProvider>
      <TopNav renderLogoAsSidebar />
      <Box
        data-testid="main-layout"
        id="main"
        sx={{
          display: 'grid',
          gridTemplateColumns: '260px auto',
          height: 'calc(100% - 64px)',
        }}
      >
        <NavSidebar />
        <Outlet />
      </Box>

      {!isDWEmployee && (
        <AwaitCustomer>
          <BetaAgreementModal />
        </AwaitCustomer>
      )}
    </NotificationsContextProvider>
  )
}

export default Layout
