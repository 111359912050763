import { RefObject, useEffect } from 'react'

import { addDisablePointerEventsClass } from '@utils/addDisablePointerEventsClass'

const useOutsideClick = (
  callback: VoidFunction,
  isOpen: boolean,
  ref: RefObject<HTMLElement>,
  enabled = true,
  toggleRef?: RefObject<HTMLElement>,
): void => {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target) &&
        !toggleRef?.current?.contains(e.target)
      ) {
        callback()
      }
    }

    addDisablePointerEventsClass(isOpen)

    if (enabled) {
      document.addEventListener('mousedown', checkIfClickedOutside)
      if (toggleRef && toggleRef.current) {
        toggleRef.current.classList.add('keep-pointer-events')
      }
      if (ref && ref.current) {
        ref.current.classList.add('keep-pointer-events')
      }

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
        addDisablePointerEventsClass(false)
      }
    }
  }, [callback, enabled, isOpen, ref, toggleRef])
}

export default useOutsideClick
