import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import {
  DetectionCoverageOverviewResponse,
  DetectionCoverageOverviewVariables,
} from '@models/DetectionCoverage'
import {
  GET_DETECTION_COVERAGE_OVERVIEW,
  GET_DETECTION_COVERAGE_OVERVIEW_WITH_DETECTIONS,
} from '@queries/detection'
import { ComponentError } from '@common/ComponentError'
import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { ComponentErrorType, ErrorCard } from '@common/ErrorCard'
import { Loader } from '@common/Loader'

import Bans from './Bans'
import MitreHeader from './Mitre/MitreHeader'
import MitreCoverageTable from './Mitre/MitreCoverageTable'
import {
  formatOverviewResponse,
  getDetectionCoverageDisplayValues,
  getMitreOverviewData,
} from './utils'
import MitreCoverageList from './Mitre/MitreList/MitreCoverageList'
import { MitreCoverageFallback } from './Mitre/MitreCoverageFallback'
import { useMitreOverviewContext } from './context/MitreOverviewContext'
import {
  LogSourceVolumeLineChart,
  LogSourceVolumeLineChartFallback,
} from '../charts/LogSourceVolume/LogSourceVolume'
import MitreOverview, { MitreOverviewFallback } from './Mitre/MitreOverview'
import MitreSideSheet from './MitreSideSheet/MitreSideSheet'

interface OverViewProps {
  handleDetectionLibNav: (status: string) => void
}

interface MitreComponentProps {
  loading: boolean
  isTableVisible: boolean
}

const MitreComponent: React.FC<MitreComponentProps> = ({
  loading,
  isTableVisible,
}) => {
  const { coverage } = useMitreOverviewContext()
  const isMissingCoverageData = coverage.dw.length === 0 // It is acceptable to have no coverage data for the customer

  if (loading) {
    return (
      <Box
        data-testid="mitreCoverage-loader"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 1034,
          '#ripple-loader-100': {
            alignSelf: 'flexStart',
            top: '5em',
          },
        }}
        id="mitre-coverage-container"
      >
        <Loader strokeWidth={1} size={100} />
      </Box>
    )
  }

  if (isMissingCoverageData) {
    return <ErrorCard errorType={ComponentErrorType.NO_DATA} />
  }

  return isTableVisible ? <MitreCoverageTable /> : <MitreCoverageList />
}

export default function Overview({
  handleDetectionLibNav,
}: OverViewProps): JSX.Element {
  const { featureEnvHealth, featureMitreCoverageSidesheetDetections } =
    useFlags()
  const [searchParams] = useSearchParams()
  const {
    coverage,
    setCoverage,
    tooltipRef,
    detectionCoverageOverview,
    setDetectionCoverageOverview,
    sideSheetData,
    isSideSheetOpen,
    setSelectedBan,
  } = useMitreOverviewContext()

  const [isTableVisible, setIsTableVisible] = useState(true)

  const selectedCustomer = searchParams.get('customer') as string

  const { data: responseData, error: getDetectionOverviewError } = useQuery<
    { getDetectionCoverageOverview: DetectionCoverageOverviewResponse },
    DetectionCoverageOverviewVariables
  >(
    !featureMitreCoverageSidesheetDetections
      ? GET_DETECTION_COVERAGE_OVERVIEW
      : GET_DETECTION_COVERAGE_OVERVIEW_WITH_DETECTIONS,
    {
      variables: {
        selectedCustomer,
      },

      onCompleted: ({ getDetectionCoverageOverview }) =>
        setDetectionCoverageOverview(
          formatOverviewResponse(getDetectionCoverageOverview),
        ),
    },
  )

  const displayValues = useMemo(
    () =>
      detectionCoverageOverview
        ? getDetectionCoverageDisplayValues(detectionCoverageOverview)
        : undefined,
    [detectionCoverageOverview],
  )

  const mitreOverviewData = useMemo(
    () =>
      detectionCoverageOverview
        ? getMitreOverviewData(detectionCoverageOverview)
        : undefined,
    [detectionCoverageOverview],
  )

  useEffect(() => {
    if (displayValues?.coverage) {
      setCoverage(displayValues.coverage)
    }
  }, [displayValues, setCoverage])

  /**
   * Check if the coverage useEffect has run
   *
   * We use the useMitreOverviewContext hook within the MITRE components, so we need to make sure it has been set before rendering.
   */
  const isCoverageEffectInProgress = Boolean(
    displayValues?.coverage &&
      (displayValues.coverage.customer.length !== coverage.customer.length ||
        displayValues.coverage.dw.length !== coverage.dw.length),
  )

  /** These values are set as side-effects of fetching data, so this is more accurate than using the query loading state */
  const haveQuerySideEffectsRun =
    mitreOverviewData !== undefined &&
    displayValues !== undefined &&
    !isCoverageEffectInProgress

  const loading = !getDetectionOverviewError && !haveQuerySideEffectsRun

  if (getDetectionOverviewError) {
    return <ComponentError includeReloadButton errorIcon={fetchErrorIcon} />
  }

  return (
    <Box
      id="detection-coverage-overview"
      sx={{
        padding: '24px',
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      {/* Bans */}
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          gap: '16px',
          flexWrap: 'wrap',
        }}
        data-testid="overview-bans"
      >
        <Bans
          handleDetectionLibNav={handleDetectionLibNav}
          isLoading={loading}
          detectionCoverageData={
            displayValues && responseData
              ? {
                  ...displayValues,
                  splunkUtilization:
                    responseData.getDetectionCoverageOverview.splunkUtilization,
                }
              : undefined
          }
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'auto fit-content(340px)',
        }}
      >
        <Box
          component="section"
          sx={{
            display: 'flex',
            height: 'min-content',
            minWidth: 'min-content',
            gap: '1.5rem',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: 'min-content',
              minWidth: 'min-content',
              gap: '1.5rem',
              flexDirection: 'column',
            }}
          >
            <ErrorBoundary fallbackRender={() => <MitreCoverageFallback />}>
              {/* MITRE header and table/list */}
              <MitreHeader
                isTableVisible={isTableVisible}
                setIsTableVisible={setIsTableVisible}
              />
              <MitreComponent
                loading={loading}
                isTableVisible={isTableVisible}
              />
            </ErrorBoundary>
            <ErrorBoundary
              fallbackRender={() => <LogSourceVolumeLineChartFallback />}
            >
              {!featureEnvHealth && (
                <>
                  {/* Source type volume chart */}
                  <LogSourceVolumeLineChart
                    loading={loading}
                    chartData={displayValues?.logWeekOverWeekData}
                    toolTipDates={displayValues?.toolTipDates}
                  />
                </>
              )}
            </ErrorBoundary>
          </Box>
        </Box>

        <ErrorBoundary FallbackComponent={MitreOverviewFallback}>
          <MitreOverview
            loading={loading}
            mitreOverviewData={mitreOverviewData}
            detectionCoverageOverviewData={detectionCoverageOverview}
          />
        </ErrorBoundary>
      </Box>

      <MitreSideSheet
        open={isSideSheetOpen}
        closeSideSheet={() => {
          sideSheetData?.closeSideSheet?.()
          setSelectedBan('')
        }}
        footer={sideSheetData.footer}
        animationEnabled={true}
      >
        {sideSheetData.children}
      </MitreSideSheet>
      <Box
        sx={(theme) => ({
          display: 'none',
          flexDirection: 'column',
          placeContent: 'center space-between',
          backgroundColor: theme.vars.palette.background.paper,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          borderRadius: '5px',
          padding: '6px',
          gap: '2px',
          position: 'absolute',
          ...theme.applyStyles('dark', {
            background: theme.vars.palette.secondary.darker,
          }),
        })}
        ref={tooltipRef}
      />
    </Box>
  )
}
