import { format } from 'date-fns'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'

import { Paths } from '@components/App/Types'
import { ThreatIntelReport, ThreatIntelReportLabel } from '@models/ThreatIntel'
import {
  getThreatIntelReportLabels,
  isReportLabelSelected,
} from '@components/ThreatIntel/ThreatIntel.utils'

import ThreatIntelSideSheetDetails from '../ThreatIntelSideSheetDetails/ThreatIntelSideSheetDetails'
import { ThreatIntelTag } from '../ThreatIntelTag'

interface ThreatIntelSideSheetContentProps {
  threatIntelReport: ThreatIntelReport | null
}

const ThreatIntelSideSheetContent: React.FC<
  ThreatIntelSideSheetContentProps
> = ({ threatIntelReport }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedLabels = getThreatIntelReportLabels(searchParams)
  const navigate = useNavigate()
  const location = useLocation()

  const isThreatIntelReportsPath =
    location.pathname === Paths.THREAT_INTEL_REPORTS

  const onLabelClick = (label: ThreatIntelReportLabel) => {
    const nextParams = new URLSearchParams(searchParams)
    const labelType = `${label.type}_label`

    if (!nextParams.has(labelType, label.name)) {
      nextParams.append(labelType, label.name)
    }

    if (!isThreatIntelReportsPath) {
      return navigate(`${Paths.THREAT_INTEL_REPORTS}?${nextParams.toString()}`)
    }

    setSearchParams(nextParams)
  }

  return (
    <Box data-testid="threat-intel-sidesheet-content">
      <Box sx={{ padding: '1rem' }}>
        <Typography
          fontWeight={600}
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          variant="body1"
        >
          {threatIntelReport?.title}
        </Typography>

        <Box
          sx={{ alignItems: 'center', display: 'flex', padding: '0.5rem 0' }}
        >
          <Box
            data-testid="ss-layout-subheader"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            {threatIntelReport?.createdDate && (
              <Typography color="textPrimary" variant="caption">
                {format(new Date(threatIntelReport.createdDate), 'MMM d, yyyy')}
              </Typography>
            )}
            {threatIntelReport?.sourceMaterial && (
              <>
                <Typography
                  color="textPrimary"
                  sx={{
                    margin: '0 10px',
                  }}
                >
                  &bull;
                </Typography>
                <Link
                  href={threatIntelReport.sourceMaterial.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  noWrap
                  sx={{
                    maxWidth: '125px',
                  }}
                  variant="caption"
                >
                  {threatIntelReport.sourceMaterial.name}
                </Link>
              </>
            )}
            <>
              <Typography
                color="textPrimary"
                sx={{
                  margin: '0 10px',
                }}
              >
                &bull;
              </Typography>
              <Typography color="textPrimary" variant="caption">
                {threatIntelReport?.source}
              </Typography>
            </>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
            paddingBottom: '1rem',
          }}
        >
          {threatIntelReport?.labels
            .filter((lbl) => Boolean(lbl.name))
            .map((label) => (
              <ThreatIntelTag
                key={`${label.type}-${label.name}`}
                text={label?.name?.toUpperCase()}
                onClickCallback={() => onLabelClick(label)}
                highlighted={isReportLabelSelected(selectedLabels, label)}
              />
            ))}
        </Box>

        <Typography color="textPrimary" variant="body2">
          {threatIntelReport?.overview}
        </Typography>
      </Box>

      <ThreatIntelSideSheetDetails
        associatedDetections={threatIntelReport?.associatedDetections || []}
        relatedTickets={threatIntelReport?.relatedTickets || []}
        targetedIndustries={threatIntelReport?.targetedIndustries || []}
      />
    </Box>
  )
}

export default ThreatIntelSideSheetContent
