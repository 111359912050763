import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'
import { Loader } from '@common/Loader'

export interface DashboardLink {
  title: string
  url: string
  icon: IconVariant
}

interface MyLinksWidgetProps {
  data: DashboardLink[] | null
  loading: boolean
}

const MyLinksWidget = ({ data, loading }: MyLinksWidgetProps) => {
  const renderContent = () => {
    if (!data) {
      return <ComponentError />
    }

    if (loading) {
      return <Loader strokeWidth={2} size={50} centered />
    }

    if (data) {
      return (
        <List>
          {data
            .filter((link) => link.url !== null)
            .map((link, index) => {
              return (
                <Link
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  <ListItem key={index}>
                    <ListItemText primary={link.title} />
                    <ListItemIcon>
                      <Icon size={18} variant="openOutline" />
                    </ListItemIcon>
                  </ListItem>
                </Link>
              )
            })}
        </List>
      )
    }
  }

  return (
    <Box id="dashboard-mylinks-widget" data-testid="dashboard-mylinks-widget">
      <Typography color="textPrimary" variant="h6" sx={{ padding: '1rem 0' }}>
        My links
      </Typography>
      {renderContent()}
    </Box>
  )
}

export default MyLinksWidget
