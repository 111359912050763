import { createContext } from 'react'

import { DashboardThreatProtection } from '@models/Dashboard'

type ThreatProtectionSummaryContext = {
  threatSummaryData: DashboardThreatProtection
}

export const ThreatProtectionSummaryContext =
  createContext<ThreatProtectionSummaryContext | null>(null)

export const ThreatProtectionSummaryProvider = ({
  children,
  threatSummaryData,
}) => {
  return (
    <ThreatProtectionSummaryContext.Provider
      value={{
        threatSummaryData,
      }}
    >
      {children}
    </ThreatProtectionSummaryContext.Provider>
  )
}
