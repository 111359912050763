import { ReactElement } from 'react'
import { Typography } from '@mui/material'

import './TableToolbar.scss'

interface TableToolbarProps {
  children: ReactElement
  loading: boolean
  dataLength: number
  singularEntityName: string
  pluralEntityName: string
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  children,
  loading,
  dataLength,
  singularEntityName,
  pluralEntityName,
}) => {
  return (
    <div className="table-toolbar" data-testid="table-toolbar">
      <div className="toolbar-total">
        <Typography color="textPrimary" variant="body1">
          {constructTotalLabel(
            loading,
            dataLength,
            pluralEntityName,
            singularEntityName,
          )}
        </Typography>
      </div>

      <div>{children}</div>
    </div>
  )
}

const constructTotalLabel = (
  loading: boolean,
  dataLength: number,
  pluralEntityName: string,
  singularEntityName: string,
): string => {
  if (loading) {
    return '--'
  }

  return `${dataLength} ${
    dataLength > 1 || dataLength === 0 ? pluralEntityName : singularEntityName
  }`
}

export default TableToolbar
