/**
 * Calls the `triggerFn` function if the event key is `Enter` or `Space`
 *
 * Useful to add keyboard accessibility to clickable components
 *
 * ### Example usage with no argument to the trigger function
 *
 * ```tsx
 * <tr
 * onClick={toggleSelected}
 * onKeyDown={(keyboardEvent) => {
 * handleKeyboardAction(keyboardEvent, toggleSelected)
 * }}
 * >
 * { ... }
 * </tr>
 * ```
 *
 * ### Example usage with an argument supplied to the trigger function via an annoymous function
 *
 * ```tsx
 * <Toggle
 * onKeyUp={(event, checked) =>
 * handleKeyboardAction(event, () => handleIncludeToggle(checked))
 * }
 * >
 * { ... }
 * </Toggle>
 * ```
 * @param event A keyboard event; this method will stop event propagation by default
 * @param triggerFn A callback function which will be called if the event key is `Enter` or `Space`
 */
export const handleKeyboardAction = (
  event: React.KeyboardEvent<HTMLElement>,
  triggerFn: () => void,
): void => {
  event.stopPropagation()
  if (event.key === 'Enter' || event.key === ' ') {
    triggerFn()
  }
}
