import { useContext, useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { Context } from '@components/App'
import { useTicketEditContext, useTicketsContext } from '@hooks/index'
import { GET_TICKET_ACTIVITY, TicketActivityResponse } from '@queries/ticket'
import usePendingActivitySubmission, {
  PendingActivitySortOrder,
} from '@ticketsHooks/usePendingActivitySubmission'
import { TicketStateEnum } from '@models/Tickets'

import TicketEditCard from '../TicketEditCard'
import { TicketCommentForm } from '../TicketCommentForm'
import TicketActivityItem from './TicketActivityItem'
import { convertTicketCommentToTicketActivity } from './TicketActivity.utils'
import { TicketActivityType, TicketActivityTypeEnum } from '../../Types'
import TicketDataHandler from '../TicketDataHandler'
import { formatCreatedDate } from '../TicketStatus/TicketStatusUtils'

export const mapFieldToLabel = {
  priority: 'Urgency',
  state: 'Status',
  customerValidateState: 'Customer validation state',
  contactUser: 'Customer contact',
}

const TicketActivityContent: React.FC<{ data: TicketActivityResponse }> = ({
  data,
}) => {
  const {
    state: { dwExpertsCustomer, customer },
  } = useContext(Context)
  const allComments = useMemo<TicketActivityType[]>(() => {
    const parsedComments = data.comments
      ? data.comments
          .map(convertTicketCommentToTicketActivity)
          .filter(({ type }) => type === TicketActivityTypeEnum.Comment)
      : []
    const placeholderComments: TicketActivityType[] = []

    // If no comments show created comment
    if (!data.comments?.length && (data.openedBy || data.sysCreatedOn)) {
      const [firstName, lastName] =
        data.openedBy?.split(' ') || `MDR ${dwExpertsCustomer || customer}`

      placeholderComments.push({
        type: TicketActivityTypeEnum.Placeholder,
        id: '',
        createdDate: formatCreatedDate(new Date(data.sysCreatedOn)),
        text: `${data.ticketNumber} Created.`,
        userInfo: {
          id: 'createdBy',
          firstName: firstName,
          lastName: lastName || '', // Account for users with no last name
          type: 'system',
        },
      })
    }

    // Sort in descending order
    const allComments = [parsedComments, placeholderComments]
      .flat()
      .sort(
        ({ createdDate: aCreatedDate }, { createdDate: bCreatedDate }) =>
          new Date(bCreatedDate).getTime() - new Date(aCreatedDate).getTime(),
      )

    return allComments
  }, [data, customer, dwExpertsCustomer])

  const [viewAllActivity, setViewAllActivity] = useState(false)
  const { sysId } = useTicketEditContext()
  const { isEditable: isTicketEditEnabled } = useTicketsContext()
  const { pendingActivityItems, setItemError, setItemCompleted, addItem } =
    usePendingActivitySubmission(PendingActivitySortOrder.DESCENDING)

  const ticketActivityItems = viewAllActivity
    ? [pendingActivityItems, allComments].flat()
    : [pendingActivityItems, allComments.slice(0, 5)].flat()

  const isTicketCommentDisabled =
    !isTicketEditEnabled || data.state === TicketStateEnum.Closed

  return (
    <>
      <TicketCommentForm
        ticketId={sysId}
        disabled={isTicketCommentDisabled}
        onSubmit={addItem}
        onCompleted={setItemCompleted}
        onError={setItemError}
      />

      <Typography
        fontWeight={600}
        variant="body1"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          paddingBottom: '1rem',
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        Activity
      </Typography>
      <Box>
        {ticketActivityItems.map((activity, index) => (
          <TicketActivityItem
            activity={activity}
            key={activity.id}
            shouldShowVerticalLine={
              ticketActivityItems.length > 1 &&
              index !== [pendingActivityItems, allComments].flat().length - 1
            }
          />
        ))}
        {!viewAllActivity && allComments.length > 5 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => setViewAllActivity(true)} variant="text">
              View All
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}

const TicketActivity: React.FC = () => {
  return (
    <TicketEditCard data-testid="ticket-activity" title="Notes">
      <TicketDataHandler<TicketActivityResponse>
        graphqlQuery={GET_TICKET_ACTIVITY}
        renderContent={(data) => <TicketActivityContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketActivity
