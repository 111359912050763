import { gtag } from 'ga-gtag'

export const addUserContextToAnalytics = (
  oktaSession: string,
  user: any,
  customer: any,
) => {
  const checks = [typeof user !== 'undefined', oktaSession === 'active']

  if (checks.every(Boolean)) {
    const { isDWEmployee } = user
    const { customerShortName } = customer

    gtag('set', {
      user_id: user.id,
      user_properties: {
        is_dw_employee: isDWEmployee,
        customer_short_name: customerShortName,
      },
    })
  }
}

export const trackAnalyticEvent = (eventName: string, options: object) => {
  gtag('event', eventName, options)
}
