import { gql } from '@apollo/client'

import { DATA_POINT_FRAGMENT, SOURCE_TYPE_FRAGMENT } from '@fragments/envHealth'
import {
  Dimension,
  EnvironmentHealthUtilAlertsNoValue,
  Metric,
  Segment,
} from '@models/EnvHealth'

type TopSourceTypes = {
  logSource: string
  deviationAmount: {
    delta: number
    gb: number
  }
}

export type EnvironmentHealthBanData = {
  environmentHealthBan: EnvironmentHealthBan
}

export type EnvironmentHealthBan = {
  avgLicenseUsage: number
  daysOverLicensePeriod: number
  licenseCapacity: number
  topSourceTypesUsageDeviation: TopSourceTypes[]
}

export interface EnvironmentHealthBanVariables {
  selectedCustomer: string | null
  startDate: string
  endDate: string
}

export const ENVIRONMENT_HEALTH_BAN = gql`
  query EnvironmentHealthBan(
    $selectedCustomer: String
    $startDate: AWSDate!
    $endDate: AWSDate!
  ) {
    environmentHealthBan(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      avgLicenseUsage
      daysOverLicensePeriod
      licenseCapacity
      topSourceTypesUsageDeviation {
        logSource
        deviationAmount {
          delta
          gb
        }
      }
    }
  }
`

interface ForwarderCounts {
  activeCount: number
  atRiskCount: number
}

export interface EnvironmentHealthForwarderCounts {
  universalForwarders: ForwarderCounts
  heavyForwarders: ForwarderCounts
}

export type EnvironmentHealthForwarderCountsData = {
  environmentHealthForwarderCounts: EnvironmentHealthForwarderCounts
}

export interface EnvironmentHealthForwarderCountsVariables {
  selectedCustomer: string | null
}

export const ENVIRONMENT_HEALTH_FORWARDER_COUNTS = gql`
  query EnvironmentHealthForwarderCounts($selectedCustomer: String) {
    environmentHealthForwarderCounts(selectedCustomer: $selectedCustomer) {
      universalForwarders {
        activeCount
        atRiskCount
      }
      heavyForwarders {
        activeCount
        atRiskCount
      }
    }
  }
`

export type DailySourceTypesDeviations = {
  lt10Count: number
  gte10Count: number
  gt25Count: number
  gt50Count: number
}

export type EnvironmentHealthDailySourceTypesDeviations = {
  environmentHealthDailySourceTypesDeviations: DailySourceTypesDeviations
}

export const ENVIRONMENT_HEALTH_DAILY_SOURCE_TYPES_DEVIATIONS = gql`
  query EnvironmentHealthDailySourceTypesDeviations($selectedCustomer: String) {
    environmentHealthDailySourceTypesDeviations(
      selectedCustomer: $selectedCustomer
    ) {
      lt10Count
      gte10Count
      gt25Count
      gt50Count
    }
  }
`

export type UtilizationMetricInput<Metric> = {
  [value in keyof Metric]: Metric[value]
}

export type UtilizationDimensionInput<Dimension> = {
  [value in keyof Dimension]: Dimension[value]
}

export type UtilizationSegmentInput<Segment> = {
  [value in keyof Segment]: Segment[value]
}

export interface UtilizationInput {
  startDate: string
  endDate: string
  metric: UtilizationMetricInput<Metric>
  dimension: UtilizationDimensionInput<Dimension>
  segment: UtilizationSegmentInput<Segment>
}

export type EnvironmentHealthUtilAlertsNoValueData = {
  environmentHealthUtilAndAlerts: EnvironmentHealthUtilAlertsNoValue | null
}

export interface EnvironmentHealthUtilAlertsNoValueDataVariables {
  selectedCustomer: string | null
  input: UtilizationInput
}

export const ENVIRONMENT_HEALTH_UTIL_ALERTS_NO_VALUE = gql`
  ${DATA_POINT_FRAGMENT}
  ${SOURCE_TYPE_FRAGMENT}
  query EnvironmentHealthUtilAndAlerts(
    $selectedCustomer: String
    $input: UtilizationInput!
  ) {
    environmentHealthUtilAndAlerts(
      selectedCustomer: $selectedCustomer
      input: $input
    ) {
      previousPeriodUtilization {
        date
        gb
      }
      currentPeriod {
        licenseCapacity {
          ...DataPointFields
        }
        topSourceTypes {
          ...SourceTypeFields
        }
      }
    }
  }
`
