import { useLazyQuery } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'

import { SideSheet } from '@common/SideSheet'
import { Report } from '@models/index'
import { handleDownload } from '@utils/index'
import { FormatStringDateRange, getFormattedDate } from '@utils/DateTimeUtils'
import { DOWNLOAD_REPORT } from '@queries/report'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'
import { InlineMessage, InlineMessageType } from '@common/InlineMessage'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import Icon from '@common/Icon'

interface ReportDetailsProps {
  isOpen: boolean
  closeSideSheet: () => void
  deleteReportCallback: () => void
  selectedReport?: Report
  selectedCustomer: string | null
}

const ReportDetails = ({
  isOpen = false,
  closeSideSheet,
  deleteReportCallback,
  selectedReport,
  selectedCustomer,
}: ReportDetailsProps) => {
  const [downloadReport] = useLazyQuery(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
      closeSideSheet()
    },
  })

  const deleteReport = () => {
    deleteReportCallback()
    closeSideSheet()
  }

  const reportDataItems = selectedReport?.reportData?.map(
    ({ metric, title }) => {
      let value: string | number = metric ?? '- -'

      if (title === 'Customer Savings' && metric !== undefined) {
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        }).format(Number(metric))
      }

      return { key: title, label: title, value }
    },
  )

  const items: SidesheetDualColumnValues[] = [
    {
      key: 'Report name',
      label: 'Report name',
      value: `${selectedReport?.name}`,
    },
    {
      key: 'Report date range',
      label: 'Report date range',
      value: (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          noWrap={true}
          variant="body2"
        >
          {selectedReport &&
            FormatStringDateRange(
              selectedReport?.startDate,
              selectedReport?.endDate,
              'MMM dd, yyyy',
            )}
        </Typography>
      ),
    },
    {
      key: 'Date created',
      label: 'Date created',
      value: `${
        selectedReport && getFormattedDate(selectedReport?.createdAt, 'P')
      }`,
    },
    ...(reportDataItems ?? []),
    {
      key: 'User',
      label: 'User',
      value: `${selectedReport?.userName}`,
    },
  ]

  return (
    <SideSheet
      open={isOpen}
      icon={<Icon variant="documentTextOutline" />}
      title={'Reports Details'}
      closeSideSheet={closeSideSheet}
      footer={
        <Box
          data-testid="report-details-buttons"
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '16px',
            gap: '16px',
          }}
        >
          <Button
            variant="outlined"
            disabled={selectedReport?.status !== 'Generated'}
            onClick={() => deleteReport()}
            sx={{ gap: 0.5 }}
          >
            Delete Report
            <Icon
              sx={(theme) => ({
                color:
                  selectedReport?.status !== 'Generated'
                    ? theme.vars.palette.text.primary
                    : theme.vars.palette.primary.main,
              })}
              variant="trashOutline"
            />
          </Button>
          <Button
            disabled={selectedReport?.status !== 'Generated'}
            onClick={() => {
              downloadReport({
                variables: {
                  input: { reportId: selectedReport?.id },
                  selectedCustomer,
                },
              })
            }}
            variant="contained"
          >
            Download Report
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'start',
          width: '100%',
        }}
        id="reports-sidesheet"
        data-testid="reports-sidesheet-test-id"
      >
        <CollapsiblePanel
          title="Details"
          borderTop={false}
          accordionStyles={{ marginTop: '-1rem !important' }}
        >
          <SidesheetDualColumn items={items} />
        </CollapsiblePanel>
        <CollapsiblePanel
          count={selectedReport?.errors.length ?? 0}
          title={'Errors'}
        >
          {(selectedReport?.errors ?? []).map(({ title, messages }, index) => (
            <InlineMessage
              key={title + index}
              message={messages}
              title={title}
              variant={InlineMessageType.ERROR}
            />
          ))}
        </CollapsiblePanel>
      </Box>
    </SideSheet>
  )
}

export default ReportDetails
