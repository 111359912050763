import { Box } from '@mui/material'

import AdditionalDetailsColumn, {
  AdditionalDetailsTopRow,
} from './AdditionalDetailsColumns'
import { AdditionalDetailsRow } from '../../Types'

interface AdditionalDetailsTabProps {
  data: AdditionalDetailsRow
}

const AdditionalDetailsTab: React.FC<AdditionalDetailsTabProps> = ({
  data,
}) => (
  <>
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      data-testid="additional-details-top-row"
    >
      <AdditionalDetailsTopRow data={data} />
    </Box>

    <Box sx={{ display: 'flex', padding: '1rem 0', width: '100%' }}>
      <Box
        data-testid="additional-details-column-0"
        sx={{
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <AdditionalDetailsColumn data={data} colIndex={0} />
      </Box>
      <Box
        data-testid="additional-details-column-1"
        sx={{
          display: 'flex',
          flexBasis: '50%',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <AdditionalDetailsColumn data={data} colIndex={1} />
      </Box>
    </Box>
  </>
)

export default AdditionalDetailsTab
