import { format } from 'date-fns'
import { SliceTooltipProps as NivoSliceTooltipProps, Point } from '@nivo/line'
import cloneDeep from 'lodash/cloneDeep'
import { Box, Typography } from '@mui/material'

import Icon from '@common/Icon'

interface SliceTooltipProps {
  slice: NivoSliceTooltipProps['slice']
  boxColors: Record<string, string>
}

const today = new Date()

const SliceTooltip: React.FC<SliceTooltipProps> = ({ slice, boxColors }) => {
  const duplicatePoints = cloneDeep(slice.points) as Point[]
  const sortedPoints = duplicatePoints.sort((pointA, pointB) =>
    pointA.data.y > pointB.data.y ? -1 : 0,
  )

  const sliceDate = new Date(slice.points[0].data.x as Date)
  const formattedDate =
    sliceDate.getFullYear() < today.getFullYear()
      ? format(sliceDate, 'MMM do, yyyy')
      : format(sliceDate, 'MMM do')

  return (
    <Box
      data-testid="slice-tooltip"
      id="slice-tooltip"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        border: `1px solid ${theme.vars.palette.secondary.main}`,
        borderRadius: '5px',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.darker,
          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
        }),
      })}
    >
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          padding: '0.25rem',
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="caption"
      >
        {formattedDate}
      </Typography>

      <Box
        sx={(theme) => ({
          borderTop: `0.75px solid ${theme.vars.palette.secondary.main}`,
          padding: '0.25rem',
          ...theme.applyStyles('dark', {
            borderTop: `0.75px solid ${theme.vars.palette.secondary.lighter}`,
          }),
        })}
      >
        {sortedPoints.map((point) => {
          return (
            <Box
              key={point.serieId}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '0.5rem',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.25rem',
                }}
              >
                <Icon
                  sx={{ color: boxColors[point.serieId], borderRadius: '2px' }}
                  variant="square"
                  size={12}
                />
                <Typography
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                  variant="caption"
                >
                  {point.serieId}
                </Typography>
              </Box>
              <Typography
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
                variant="caption"
              >
                {point.data.yFormatted}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
export default SliceTooltip
