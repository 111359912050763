import { CSSProperties, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import {
  InlineMessageType,
  MessageProps,
  ToastType,
} from '@design-interfaces/index'
import * as MessageIcons from '@assets/messageIcons/index'
import { colors } from '@theme/index'
import { useTheme } from '@design-hooks/index'

import './Message.scss'

/**
 * @param root0
 * @param root0.messageType
 * @param root0.title
 * @param root0.text
 * @param root0.autoDismissAt
 * @param root0.dismissible
 * @param root0.style
 * @param root0.onDismissed
 * @category Deprecated
 * @deprecated Please use InlineMessage.tsx in `@common` instead
 */
const Message = ({
  messageType,
  title,
  text,
  autoDismissAt,
  dismissible,
  style,
  onDismissed,
}: MessageProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const [show, setShow] = useState(false)
  const ref0 = useRef(null)
  //TODO replace with Typography component when available
  const icon = (type: string) => {
    if (type === 'Info') {
      return isDarkTheme ? MessageIcons.InfoDark : MessageIcons.Info
    } else if (type === 'SuccessInline') {
      return isDarkTheme ? MessageIcons.SuccessDark : MessageIcons.Success
    } else if (type === 'WarningInline') {
      return isDarkTheme ? MessageIcons.WarningDark : MessageIcons.Warning
    } else if (type === 'ErrorInline') {
      return isDarkTheme ? MessageIcons.ErrorDark : MessageIcons.Error
    } else if (type === 'BasicInline') {
      return isDarkTheme ? MessageIcons.InfoLight : MessageIcons.InfoDark
    } else if (type === 'Default') {
      return isDarkTheme ? MessageIcons.NeutralDarkToast : MessageIcons.Info
    } else if (type === 'SuccessToast') {
      return isDarkTheme ? MessageIcons.SuccessDarkToast : MessageIcons.Success
    } else if (type === 'WarningToast') {
      return isDarkTheme ? MessageIcons.WarningDarkToast : MessageIcons.Warning
    } else if (type === 'ErrorToast') {
      return isDarkTheme ? MessageIcons.ErrorDarkToast : MessageIcons.Error
    }
  }
  const messageColor = (type: string) => {
    if (type === 'Info' || type === 'Default') {
      return isDarkTheme
        ? colors.brand.secondary.light
        : colors.brand.secondary.lighter
    } else if (type === 'SuccessInline' || type === 'SuccessToast') {
      return isDarkTheme
        ? colors.brand.primary.light
        : colors.brand.primary.lighter
    } else if (type === 'WarningInline' || type === 'WarningToast') {
      return isDarkTheme ? colors.util.four.main : colors.util.four.light
    } else if (type === 'ErrorInline' || type === 'ErrorToast') {
      return isDarkTheme ? colors.util.two.light : colors.util.two.lighter
    } else if (type === 'BasicInline') {
      return isDarkTheme
        ? colors.util.navy[500]
        : colors.brand.secondary.lighter
    }
  }

  const borderColor = (type: string) => {
    if (type === 'Info') {
      return isDarkTheme
        ? undefined
        : `1px solid ${colors.brand.secondary.dark}`
    } else if (type === 'SuccessInline' || type === 'SuccessToast') {
      return isDarkTheme ? undefined : `1px solid ${colors.brand.primary.dark}`
    } else if (type === 'WarningInline') {
      return isDarkTheme ? undefined : `1px solid ${colors.util.four.main}`
    } else if (type === 'ErrorInline' || type === 'ErrorToast') {
      return isDarkTheme ? undefined : `1px solid ${colors.util.two.dark}`
    }
  }

  const messageContainer = (
    type: InlineMessageType | ToastType,
  ): CSSProperties => ({
    width: 343,
    backgroundColor: messageColor(type),
    display: 'flex',
    alignItems: 'start',
    gap: 8,
    padding: 8,
    border: type in InlineMessageType ? borderColor(type) : undefined,
    borderRadius: 5,
    fontFamily: 'Inter',
    marginBottom: 8,
    ...style,
  })

  const messageContents: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }

  const textStyling: CSSProperties = {
    display: 'block',
    marginBlock: 0,
    marginInline: 0,
  }

  const isInline = (): boolean => {
    return (
      messageType === InlineMessageType.BasicInline ||
      messageType === InlineMessageType.SuccessInline ||
      messageType === InlineMessageType.WarningInline ||
      messageType === InlineMessageType.ErrorInline ||
      messageType === InlineMessageType.Info
    )
  }

  const messageTitle = (
    type: InlineMessageType | ToastType,
  ): CSSProperties => ({
    ...textStyling,
    color:
      type === InlineMessageType.BasicInline
        ? colors.util.navy[100]
        : colors.util.one.main,
    fontSize: type in InlineMessageType ? '12px' : '14px',
    fontWeight: type in InlineMessageType ? 500 : 600,
    lineHeight: '16px',
  })

  const messageText = (type?: InlineMessageType | ToastType) => ({
    ...textStyling,
    color:
      type === InlineMessageType.BasicInline
        ? colors.util.navy[100]
        : colors.util.navy[600],
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  })

  const dismissStyle: CSSProperties = {
    marginLeft: 'auto',
    height: 14.4,
    width: 14.4,
  }

  const getMessageAnimation = (): string => {
    let result = ''
    if (isInline() === false) {
      result = 'apptoastmessage'
    }
    return result
  }

  useEffect(() => {
    setShow(true)
  }, [])

  if (autoDismissAt && autoDismissAt > 0) {
    setTimeout(() => {
      setShow(false)
    }, autoDismissAt)
  }

  return (
    <CSSTransition
      nodeRef={ref0}
      in={show}
      timeout={500}
      onExited={() => {
        onDismissed?.()
      }}
      classNames={getMessageAnimation()}
    >
      <div
        style={messageContainer(messageType)}
        ref={ref0}
        className={getMessageAnimation()}
      >
        <img src={icon(messageType)} alt={`${messageType} Message Icon`} />
        <div style={messageContents}>
          {title && <p style={messageTitle(messageType)}>{title}</p>}
          <p style={messageText(messageType)}>{text}</p>
        </div>
        {dismissible && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <img
            style={dismissStyle}
            src={MessageIcons.Dismiss}
            alt={'Dismiss Toast Message'}
            onClick={() => {
              setShow(false)
            }}
          />
        )}
      </div>
    </CSSTransition>
  )
}

export default Message
