import { CellContext, Column } from '@tanstack/react-table'
import { Box, Skeleton, Typography } from '@mui/material'

import StyledTableCell from '../styled/StyledTableCell'

export const buildGenericCell = <T,>(
  props: CellContext<T, never | string | number | undefined>,
  isLoading = false,
) => (
  <Typography
    noWrap
    sx={(theme) => ({
      color: isLoading
        ? theme.vars.palette.text.disabled
        : theme.vars.palette.text.primary,
      ...theme.applyStyles('dark', {
        color: isLoading
          ? theme.vars.palette.text.disabled
          : theme.vars.palette.text.secondary,
      }),
    })}
    variant="body2"
  >
    {props.getValue()}
  </Typography>
)

export const buildGenericHeader = (text: string): JSX.Element => (
  <Typography
    noWrap
    fontWeight={600}
    sx={(theme) => ({
      color: theme.vars.palette.text.secondary,
      display: 'block',
      ...theme.applyStyles('dark', {
        color: theme.vars.palette.text.primary,
      }),
    })}
    variant="caption"
  >
    {text}
  </Typography>
)

export const buildSkeletonRows = <T,>(
  columns: Column<T, unknown>[],
  numberOfRows: number = 25,
) => {
  return Array(numberOfRows)
    .fill({})
    .map((_, index) => (
      <Box component="tr" key={index}>
        {columns.map((cell) => {
          return (
            <StyledTableCell key={cell.id}>
              <Skeleton data-testid="skeleton-block" />
            </StyledTableCell>
          )
        })}
      </Box>
    ))
}
