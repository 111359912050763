import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import WindowsLogo from '@common/SVGs/WindowsLogo'
import LinuxLogo from '@common/SVGs/LinuxLogo'
import MacOSLogo from '@common/SVGs/MacOSLogo'

import { EDRAsset } from '..'

interface OperatingSystemCellValues {
  platform: EDRAsset['platform']
  operatingSystem: EDRAsset['operatingSystem']
}

const OperatingSystemCell = (
  props: CellContext<EDRAsset, OperatingSystemCellValues>,
) => {
  const { platform, operatingSystem } = props.getValue()

  const getLogo = () => {
    if (platform && operatingSystem) {
      if (platform.toLowerCase().includes('windows')) {
        return <WindowsLogo />
      }

      if (platform.toLowerCase().includes('linux')) {
        return <LinuxLogo />
      }

      if (platform.toLowerCase().includes('mac')) {
        return <MacOSLogo />
      }
    }

    return null
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
      }}
    >
      <Box sx={{ display: 'flex' }}>{getLogo()}</Box>
      <Typography
        color="textSecondary"
        noWrap={true}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {operatingSystem}
      </Typography>
    </Box>
  )
}

export default OperatingSystemCell
