import { Box, SxProps, Theme } from '@mui/material'

const PopoverListItemStyles = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '0.5rem',
  padding: '0.5rem',
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
  ...theme.applyStyles('dark', {
    borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
  }),

  ':hover': {
    backgroundColor: theme.vars.palette.secondary.main,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.vars.palette.secondary.light,
    }),
  },

  ':first-of-type': {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },

  ':last-child': {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottom: 'none',
  },
})

interface PopoverListItemProps {
  children: React.ReactNode
  onClick: React.DOMAttributes<HTMLDivElement>['onClick']
  onKeyDown: React.DOMAttributes<HTMLDivElement>['onKeyDown']
  sx?: SxProps<Theme>
}

const PopoverListItem: React.FC<PopoverListItemProps> = ({
  children,
  onClick,
  onKeyDown,
  sx,
}) => {
  return (
    <Box
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="menuitem"
      sx={[PopoverListItemStyles, ...(Array.isArray(sx) ? sx : [sx])]}
      tabIndex={0}
    >
      {children}
    </Box>
  )
}

export default PopoverListItem
