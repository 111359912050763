import { AxisTickProps } from '@nivo/axes'
import { format } from 'date-fns'
import { Typography } from '@mui/material'

import { SplunkUtilization } from '@models/DetectionCoverage'
import { getTimezoneRemovedDate } from '@utils/DateTimeUtils'
import { colors } from '@design-system/theme'
import Icon from '@common/Icon'

import { LineChartData, SeriesPoint } from '../../charts/ChartTypes'

export function getToolTipDates(data: SplunkUtilization[] = []): string[] {
  const dates = data.map((utilization) => {
    //empty string after c.MetricDate is used to default to UTC
    return format(getTimezoneRemovedDate(utilization.metricDate), 'EE, MMM dd')
  })
  return dates
}

export function formatLineChartData(
  data: { currentWeek: SplunkUtilization[]; previousWeek: SplunkUtilization[] },
  currentWeekColor: string,
  previousWeekColor: string,
): LineChartData[] {
  const currentWeekData = data.currentWeek.map((c) => ({
    //empty string after c.MetricDate is used to default to UTC
    x: format(getTimezoneRemovedDate(c.metricDate), 'EE'),
    y: c.totalGb,
  }))

  const previousWeekData = data.previousWeek.map((c) => ({
    x: format(getTimezoneRemovedDate(c.metricDate), 'EE'),
    y: c.totalGb,
  }))

  const formattedData: LineChartData[] = [
    { id: 'currentWeek', color: currentWeekColor, data: currentWeekData },
    {
      id: 'previousWeek',
      color: previousWeekColor,
      data: previousWeekData,
    },
  ]
  const currentWeekLogArray: number[] = currentWeekData.map((curr) => curr.y)
  const previousWeekLogArray: number[] = previousWeekData.map((prev) => prev.y)

  formattedData.forEach((chartData) => {
    chartData.data.forEach((xValues, index) => {
      const diff =
        currentWeekLogArray[`${index}`] - previousWeekLogArray[`${index}`]
      const change = diff / currentWeekLogArray[`${index}`]
      const percent = change * 100
      xValues.x = xValues.x + ' ' + Math.round(percent).toFixed(0) + '%'
    })
  })
  return formattedData
}

export function displayToolTipItem(seriePoint: SeriesPoint | undefined) {
  if (seriePoint?.serieId === 'currentWeek') {
    return (
      <div
        key={seriePoint.serieId}
        className="flex-container align-items-center"
      >
        <Icon
          sx={{ borderRadius: '2px', color: colors.brand.secondary.main }}
          variant="square"
          size={12}
        />
        <Typography
          color="textPrimary"
          sx={{ marginLeft: '2px' }}
          variant="caption"
        >
          {Number(seriePoint.data.yFormatted)} GB
        </Typography>
      </div>
    )
  }

  if (seriePoint?.serieId === 'previousWeek') {
    return (
      <div
        key={seriePoint.serieId}
        className="flex-container align-items-center"
      >
        <Icon
          sx={{ borderRadius: '2px', color: colors.util.one.lighter }}
          variant="square"
          size={12}
        />
        <Typography
          color="textPrimary"
          sx={{ marginLeft: '2px' }}
          variant="caption"
        >
          {Number(seriePoint.data.yFormatted)} GB
        </Typography>
      </div>
    )
  }
}

export const AxisLeftTick = (tick: AxisTickProps<string>): JSX.Element => {
  return (
    <g transform={`translate(${tick.x - 55},${tick.y + 5})`}>
      <text
        textAnchor="left"
        dominantBaseline="left"
        style={{
          fontSize: 12,
          fill: '#A2BBC3',
          paddingRight: '30px',
        }}
      >
        {tick.value} GB
      </text>
    </g>
  )
}

export const AxisBottomTick = (tick: AxisTickProps<string>): JSX.Element => {
  function returnTickColor() {
    const tickValue = Number(tick.value.slice(3, -1))
    if (
      (tickValue >= 10 && tickValue < 15) ||
      (tickValue < -10 && tickValue > -15)
    ) {
      return '#FFE684'
    }

    if (tickValue > 15 || tickValue < -15) {
      return '#FF9431'
    } else {
      return '#A2BBC3'
    }
  }

  if (tick.tickIndex === 6) {
    return (
      <>
        <g transform={`translate(${tick.x - 22},${tick.y + 20})`}>
          <text
            textAnchor="left"
            dominantBaseline="left"
            style={{
              fontSize: 12,
              fill: '#A2BBC3',
              paddingRight: '20px',
            }}
          >
            {tick.value.slice(0, 3)}
          </text>
        </g>
        <g transform={`translate(${tick.x - 19},${tick.y + 35})`}>
          <text
            textAnchor="left"
            dominantBaseline="left"
            style={{
              fontSize: 12,
              fill: returnTickColor(),
              paddingRight: '20px',
            }}
          >
            {tick.value.slice(3)}
          </text>
        </g>
      </>
    )
  } else {
    return (
      <>
        <g transform={`translate(${tick.x + 2},${tick.y + 20})`}>
          <text
            textAnchor="left"
            dominantBaseline="left"
            style={{
              fontSize: 12,
              fill: '#A2BBC3',
              paddingRight: '20px',
            }}
          >
            {tick.value.slice(0, 3)}
          </text>
        </g>
        <g transform={`translate(${tick.x + 2},${tick.y + 35})`}>
          <text
            textAnchor="left"
            dominantBaseline="left"
            style={{
              fontSize: 12,
              fill: returnTickColor(),
              paddingRight: '20px',
            }}
          >
            {tick.value.slice(3)}
          </text>
        </g>
      </>
    )
  }
}
