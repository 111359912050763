import { useQuery } from '@apollo/client'
import { Box, Skeleton } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CYBER_ASSISTANT_TIME_SAVED,
  CYBER_ASSISTANT_TIME_SAVED_V2,
} from '@queries/cyberAssistant'
import { formatLargeNumber } from '@utils/formatLargeNumber'

import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import { AnswerId } from '../CyberAssistantQuestions'

const TimeSaved: React.FC<{ selectedCustomer: string | null }> = ({
  selectedCustomer,
}) => {
  const { featureCyberAssistantV2 } = useFlags()

  const { data, loading, error } = useQuery(
    featureCyberAssistantV2
      ? CYBER_ASSISTANT_TIME_SAVED_V2
      : CYBER_ASSISTANT_TIME_SAVED,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer,
      },
    },
  )

  const {
    totalLoggedEvents = 0,
    totalDaysSaved = 0,
    totalEscalatedTickets = 0,
    totalAlerts = 0,
  } = featureCyberAssistantV2 && !loading
    ? {
        ...data?.cyberAssistantTimeSaved,
        totalAlerts: data?.cyberAssistantTPFPQuarterlyRate?.totalAlerts ?? 0,
      }
    : data?.cyberAssistantTimeSaved || {}

  const anyDataIsNullOrUndefined = [
    totalDaysSaved,
    totalAlerts,
    totalLoggedEvents,
    totalEscalatedTickets,
  ].some((dataPoint) => dataPoint === null || undefined)

  if (loading) {
    return (
      <Box data-testid="skeleton-loader">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    )
  }

  if (error || anyDataIsNullOrUndefined) {
    return <EmptyDataAnswer />
  }

  const daysSaved = featureCyberAssistantV2
    ? Math.ceil(totalDaysSaved)
    : totalDaysSaved

  return (
    <>
      Based on an average of 20 minutes per validation of each alert, you saved
      ~{daysSaved} total working days with Deepwatch last quarter.
      <br />
      <br />
      The above was calculated based on the fact that in the past quarter{' '}
      {totalAlerts?.toLocaleString()} alerts were triaged from{' '}
      {formatLargeNumber(totalLoggedEvents, 'long')} logs, of which{' '}
      {totalEscalatedTickets} tickets were escalated for further actions.
      <FeedbackForm answerId={AnswerId.TIME_SAVED} />
    </>
  )
}

export default TimeSaved
