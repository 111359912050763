import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Box, Typography } from '@mui/material'

import { colors } from '@design-system/index'
import {
  ComponentErrorType,
  ErrorCard,
  ErrorCardProps,
} from '@common/ErrorCard'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'

import {
  AxisBottomTick,
  AxisLeftTick,
  displayToolTipItem,
} from '../LogSourceVolume/LogSourceUtils'
import { LineChartData } from '../ChartTypes'

/**
 * @deprecated should be removed with feature-env-health flag
 * @returns
 */
const LogSourceVolumeChartHeader: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flex: '1 1 auto',
      height: 'min-content',
      minWidth: 'min-content',
      justifyContent: 'space-between',
    }}
  >
    <Typography sx={{ marginBottom: 1 }} color="textPrimary" variant="h6">
      Source type volume week over week
    </Typography>

    <Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            gap: '6px',
            alignItems: 'center',
          }}
        >
          <Icon
            variant="square"
            sx={(theme) => ({ color: theme.vars.palette.text.secondary })}
          />
          <Typography color="textPrimary" variant="body2">
            Previous Week
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            gap: '6px',
            alignItems: 'center',
          }}
        >
          <Icon variant="square" sx={{ color: colors.brand.secondary.main }} />
          <Typography color="textPrimary" variant="body2">
            Current Week
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
)

const LogSourceVolumeLineChartContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <Box
      tabIndex={0}
      role={'group'}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <LogSourceVolumeChartHeader />
      <Box
        sx={(theme) => ({
          position: 'relative',
          height: '385px',
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.secondary.lighter}`,
          borderRadius: '5px',
          '& > div': {
            position: 'absolute',
          },
        })}
      >
        {children}
      </Box>
    </Box>
  )
}

export const LogSourceVolumeLineChartFallback: React.FC<ErrorCardProps> = (
  props,
) => (
  /** Not using `<LogSourceVolumeLineChartContainer />` here because we don't want to include the styles from .responsive-nivo-chart-container */
  <Box
    tabIndex={0}
    role={'group'}
    sx={{
      display: 'flex',
      flex: '1 1 auto',
      height: 'min-content',
      minWidth: 'min-content',
      flexDirection: 'column',
      gap: '24px',
    }}
  >
    <LogSourceVolumeChartHeader />
    <ErrorCard {...props} />
  </Box>
)

interface LogSourceVolumeLineChartProps {
  chartData?: LineChartData[] | 0
  toolTipDates?: string[]
  loading: boolean
}

type LogSourceVolumeLineChartPropsWithData = {
  chartData: LineChartData[]
  toolTipDates: string[]
  loading: boolean
}

const chartHasData = (
  props: LogSourceVolumeLineChartProps,
): props is LogSourceVolumeLineChartPropsWithData => {
  const { chartData, toolTipDates } = props

  return (
    chartData !== undefined &&
    chartData !== 0 &&
    Array.isArray(chartData) &&
    chartData.length > 0 &&
    toolTipDates !== undefined &&
    toolTipDates.length > 0
  )
}

export const LogSourceVolumeLineChart: React.FC<
  LogSourceVolumeLineChartProps
> = (props) => {
  const themeConfig = {
    grid: {
      line: {
        stroke: '#455358',
        strokeWidth: 1,
      },
    },
    axis: {
      ticks: {
        line: {
          strokeWidth: 0,
        },
        text: {
          fontSize: 12,
          fill: '#A3A3A3',
        },
      },
    },
    crosshair: {
      line: {
        stroke: colors.util.navy[100],
        strokeWidth: 1,
        strokeOpacity: 0.75,
        strokeDasharray: '6 6',
      },
    },
  }

  if (props.loading) {
    return (
      <LogSourceVolumeLineChartContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Loader strokeWidth={2} size={25} />
        </Box>
      </LogSourceVolumeLineChartContainer>
    )
  }

  if (!chartHasData(props)) {
    return (
      <LogSourceVolumeLineChartFallback
        errorType={ComponentErrorType.NO_DATA}
      />
    )
  }

  return (
    <LogSourceVolumeLineChartContainer>
      <ResponsiveLine
        margin={{
          top: 25,
          right: 30,
          bottom: 50,
          left: 65,
        }}
        colors={[colors.brand.secondary.main, colors.util.navy[50]]}
        enableGridX={false}
        enableGridY={true}
        data={props.chartData}
        gridYValues={6}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          reverse: false,
        }}
        enableSlices="x"
        crosshairType="x"
        theme={themeConfig}
        axisBottom={{
          renderTick: AxisBottomTick,
          tickPadding: 1,
        }}
        axisLeft={{
          renderTick: AxisLeftTick,
          tickPadding: 5,
          tickValues: 5,
        }}
        pointSize={3}
        pointLabelYOffset={0}
        lineWidth={1}
        sliceTooltip={({ slice }) => {
          const reOrderedPoints = [
            slice.points.find((point) => point.serieId === 'previousWeek'),
            slice.points.find((point) => point.serieId === 'currentWeek'),
          ].filter((point) => point !== undefined)
          const day = String(reOrderedPoints[0]?.data.xFormatted).substring(
            0,
            3,
          )
          const returnedDate = props.toolTipDates.filter(
            (d) => d.substring(0, 3) === day,
          )
          return (
            <Box
              data-testid="slice-tooltip"
              sx={(theme) => ({
                display: 'flex',
                flex: '1 1 auto',
                height: 'min-content',
                minWidth: 'min-content',
                flexDirection: 'column',
                backgroundColor: theme.vars.palette.secondary.dark,
                borderRadius: 3,
                border: `0.75px solid ${theme.vars.palette.secondary.dark}`,
              })}
            >
              <Typography
                sx={{ padding: 0.25 }}
                color="textPrimary"
                variant="body2"
              >
                {returnedDate[0]}
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flex: '1 1 auto',
                  height: 'min-content',
                  minWidth: 'min-content',
                  padding: '4px',
                  flexDirection: 'column',
                  gap: '1px',
                  borderTop: `0.75px solid ${theme.vars.palette.secondary.dark}`,
                })}
              >
                <Box>{reOrderedPoints.map((p) => displayToolTipItem(p))}</Box>
              </Box>
            </Box>
          )
        }}
      />
    </LogSourceVolumeLineChartContainer>
  )
}
