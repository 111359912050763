import { CustomSVGProps } from './types'

const AzureLogo: React.FC<CustomSVGProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" fill="none">
    <g clipPath="url(#clip0_25149_597)">
      <path
        d="M5.8019 0.971436H0.418823V6.43684H5.8019V0.971436Z"
        fill="#F25022"
      />
      <path
        d="M11.7519 0.971436H6.36884V6.43684H11.7519V0.971436Z"
        fill="#7FBA00"
      />
      <path
        d="M5.8019 7.01123H0.418823V12.4767H5.8019V7.01123Z"
        fill="#00A4EF"
      />
      <path
        d="M11.7521 7.01123H6.36902V12.4767H11.7521V7.01123Z"
        fill="#FFB900"
      />
    </g>
    <defs>
      <clipPath id="clip0_25149_597">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default AzureLogo
