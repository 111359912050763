import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'

import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { PaginationLoadingRow, TableHeader } from '@common/Table'
import { ComponentError } from '@common/ComponentError'
import { NoResults } from '@common/NoResults'
import {
  FIREWALL_DEVICES,
  FirewallDevice,
  FirewallDevices,
  FirewallVariables,
} from '@queries/firewall'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { TableToolbar } from '@common/TableToolbar'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'

const FirewallTable: React.FC = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'revisions',
      desc: true,
    },
  ])

  const tableRef = useRef<HTMLDivElement>(null)

  const {
    data: { firewallDevices: { devices, pagination } } = {
      firewallDevices: {
        devices: [],
        pagination: { offset: 0, limit: 25, total: 0 },
      },
    },
    error,
    loading,
    fetchMore,
  } = useQuery<FirewallDevices, FirewallVariables>(FIREWALL_DEVICES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer: selectedCustomer,
      pagination: {
        limit: 25,
        offset: 0,
      },
      sorting: sorting[0],
    },
  })

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  const handleSortingChange = useCallback(
    (newSortingState: React.SetStateAction<SortingState>) => {
      setSorting(newSortingState)
      handleScrollToTop()
    },
    [setSorting, handleScrollToTop],
  )

  const columnHelper = createColumnHelper<FirewallDevice>()
  const columnDef = useMemo(
    () => [
      columnHelper.accessor('deviceName', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('DEVICE NAME'),
        enableSorting: false,
      }),
      columnHelper.accessor('deviceType', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('DEVICE TYPE'),
        enableSorting: false,
      }),
      columnHelper.accessor('securityConcernIndex', {
        cell: (props) => {
          const cellValue = props.getValue()
          return (
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
              }}
            >
              <Typography
                noWrap
                variant="body2"
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              >
                {cellValue}
              </Typography>
            </Box>
          )
        },
        header: () => buildGenericHeader('SECURITY CONCERN'),
        enableSorting: true,
      }),
      columnHelper.accessor('revisions', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('REVISIONS'),
        enableSorting: true,
      }),
    ],
    [],
  )

  const table = useReactTable<FirewallDevice>({
    columns: columnDef,
    data: devices,
    getCoreRowModel: getCoreRowModel(),
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    manualSorting: true,
    state: {
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: handleSortingChange,
  })

  const onBottomReached = useCallback(() => {
    fetchMore({
      variables: {
        pagination: {
          limit: pagination.limit,
          offset: devices.length,
        },
      },
    })
  }, [fetchMore, pagination.limit, devices.length])

  const handleOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        if (scrollHeight - scrollTop - clientHeight < 300) {
          onBottomReached()
        }
      }
    },
    [onBottomReached],
  )

  const render = () => {
    const COL_SPAN = 5
    let element: JSX.Element | null = null
    // error state
    if (error) {
      element = (
        <ComponentError
          errorText="No Devices Available"
          errorSubText="There are no devices currently available for active scanning."
        />
      )
    }
    // no results found
    if (!error && !loading && devices.length < 1) {
      element = <NoResults />
    }

    if (element) {
      return (
        <tbody>
          <tr>
            <td
              align="center"
              colSpan={COL_SPAN}
              style={{ border: 'none', padding: '50px' }}
            >
              {element}
            </td>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody data-testid="firewall-table-body">
        {loading
          ? buildSkeletonRows(table.getAllColumns())
          : table.getRowModel().rows.map((row) => {
              return (
                <MemoizedTanStackTableRow
                  key={row.id}
                  rowId={`${row.id}`}
                  testId="detection-catalog-table-row"
                  isSelected={false}
                  toggleSelected={row.toggleSelected}
                  visibleCells={row.getVisibleCells()}
                  isDisabled={true}
                />
              )
            })}
        <PaginationLoadingRow
          currentLength={devices.length}
          pageSize={pagination.limit}
          total={pagination.total}
          onScrollToTop={handleScrollToTop}
        />
      </tbody>
    )
  }
  return (
    <>
      <TableToolbar
        loading={loading && !devices.length}
        dataLength={pagination.total}
        singularEntityName={'Asset'}
        pluralEntityName={'Assets'}
      >
        <></>
      </TableToolbar>

      <CommonTableContainer
        data-testid="firewall-table-container"
        onScroll={(e) => handleOnBottomReached(e.target as HTMLDivElement)}
        ref={tableRef}
      >
        <CommonTable cellSpacing={0} data-testid="firewall-table">
          <thead data-testid="firewall-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader<FirewallDevice>
                    key={header.id}
                    header={header}
                  />
                ))}
              </tr>
            ))}
          </thead>
          {render()}
        </CommonTable>
      </CommonTableContainer>
    </>
  )
}

export default FirewallTable
