import { TicketingComment } from '@models/index'
import { UserInfo } from '@models/User'

import {
  TicketActivityType,
  TicketActivityTypeEnum,
  TicketCommentActivity,
} from '../../Types'

/**
 * This method takes the data we receive from the backend for the comments (text, type, date, user) and converts
 * them to the data format the ticket activity component expects
 * @param data
 */
export const convertTicketCommentToTicketActivity = (
  data: TicketingComment,
): TicketActivityType => {
  const [firstName, lastName] = data.user.split(' ')

  const userInfo: UserInfo = {
    id: '',
    firstName: firstName || '',
    lastName: lastName || '',
    // This is used in the UserAvatar component to determine the color of the avatar (green for Deepwatch user / red for customer)
    type: data.internalUser ? 'dw' : 'cust',
  }

  if (data.type === TicketActivityTypeEnum.Comment) {
    return {
      id: crypto.randomUUID(), // The backend doesn't have IDs for the comments
      createdDate: data.time,
      type: TicketActivityTypeEnum.Comment,
      text: data.text,
      userInfo,
    }
  }

  return {
    id: '',
    type: TicketActivityTypeEnum.FieldChanges,
    createdDate: data.time,
    fieldChanges: [],
    userInfo,
  }
}

/**
 * This method takes the client side TicketActivityType and converts it to a TicketingComment type (the value we stored
 * in the cache and get from the server). This is primarily used for updating the cache when a user submits a comment
 * @param data
 */
export const convertTicketActivityToTicketComment = (
  data: TicketCommentActivity,
): TicketingComment => {
  return {
    time: data.createdDate,
    text: data.text,
    // Type here refers to the type of the ticket activity, not the type of the user
    type: TicketActivityTypeEnum.Comment,
    user: [data.userInfo.firstName, data.userInfo.lastName].join(' '),
    internalUser: data.userInfo.type === 'dw',
  }
}

export const TICKET_COMMENT_TIME_FORMAT = 'MMM d, yyyy h:mm a'

export const TICKET_ACTIVITY_FOOTER_TEXT: Record<
  TicketActivityTypeEnum,
  string
> = {
  [TicketActivityTypeEnum.Comment]: 'Additional comments',
  [TicketActivityTypeEnum.FieldChanges]: 'Field changes',
  [TicketActivityTypeEnum.Placeholder]: 'Field changes',
}
