/* eslint-disable @getify/proper-ternary/nested */
import { ResponsiveFunnel } from '@nivo/funnel'
import { CSSProperties, useRef, useState } from 'react'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, Card, IconButton, Typography } from '@mui/material'

import { ComponentError } from '@common/ComponentError'
import { colors } from '@design-system/index'
import ChartDownloadMenu from '@common/ChartDownloadMenu'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

import {
  customLegend,
  CustomParts,
  CustomSeparator,
} from './ThreatProtectionSummary.customLayers'
import { ThreatProtectionSummaryInterface } from './ThreatProtectionSummary.models'
import {
  scaleValues,
  threatSummaryHasData,
} from './ThreatProtectionSummary.utils'
import { useThreatProtectionSummaryContext } from './useThreatProtectionSummaryContext'

const ThreatProtectionSummary = ({
  loading,
  error,
}: ThreatProtectionSummaryInterface): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const chartRef = useRef<HTMLDivElement>(null)
  const { threatSummaryData } = useThreatProtectionSummaryContext()

  const { featureNgMdr } = useFlags()
  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(16)],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const centeredStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  }

  const dateFilterableStyles = useDateFilterableStyles()

  const renderChart = () => {
    if (loading) {
      return (
        <div style={centeredStyles}>
          <Loader size={50} strokeWidth={2} />
        </div>
      )
    }

    if (
      !threatSummaryData ||
      !threatSummaryHasData(threatSummaryData) ||
      error
    ) {
      return <ComponentError errorContainerStyles={{ height: '100%' }} />
    }

    const showFourthChartPart = threatSummaryData.isLogEventCountAvailable

    const scaleValuesData = scaleValues(
      threatSummaryData,
      featureNgMdr,
      showFourthChartPart,
    )

    return (
      <Box
        sx={{
          display: 'flex',
          height: 0,
          minWidth: 0,
          minHeight: '100%',
          width: '99%',
        }}
      >
        <ResponsiveFunnel
          data={scaleValuesData}
          borderOpacity={0}
          labelColor={{
            from: 'color',
            modifiers: [['darker', 3]],
          }}
          enableLabel={false}
          layers={[CustomParts, CustomSeparator]}
          enableAfterSeparators={false}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={0}
          afterSeparatorLength={100}
          afterSeparatorOffset={0}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
          direction="horizontal"
          ariaDescribedBy="tps-chart-description"
          isInteractive={true}
        />

        <Box
          data-testid="tps-chart-legend"
          aria-hidden
          sx={(theme) => ({
            position: 'absolute',
            top: 25,
            right: 0,
            width: '20%',
            pl: showFourthChartPart ? 4 : 0,
            borderLeft: showFourthChartPart
              ? `1px solid ${theme.vars.palette.secondary.lighter}`
              : '',
          })}
        >
          {customLegend(threatSummaryData, featureNgMdr)}
        </Box>
      </Box>
    )
  }

  const isExportDisabled =
    loading ||
    (threatSummaryData == null
      ? false
      : !threatSummaryHasData(threatSummaryData)) ||
    error !== undefined

  return (
    <Box id="threat-protection-summary" data-testid="threat-protection-summary">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1rem 0',
        }}
      >
        <Box>
          <Typography color="textPrimary" variant="h6">
            Threat protection summary
          </Typography>

          {!loading &&
            (!threatSummaryData ||
              !threatSummaryHasData(threatSummaryData) ||
              error) && (
              <Icon
                variant="warningOutline"
                size={20}
                htmlColor={colors.util.orange[100]}
              />
            )}
        </Box>

        <Box data-testid="threat-protection-summary-export">
          <IconButton
            disabled={isExportDisabled}
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <Icon
              sx={(theme) => ({
                color: isExportDisabled
                  ? theme.vars.palette.text.disabled
                  : theme.vars.palette.text.primary,
              })}
              variant="downloadOutline"
            />
          </IconButton>

          {isOpen && (
            <FloatingFocusManager context={context}>
              <ChartDownloadMenu
                handleOutsideClick={() => setIsOpen(false)}
                data={threatSummaryData!}
                floatingStyles={floatingStyles}
                getFloatingProps={getFloatingProps}
                setFloating={refs.setFloating}
                chartRef={chartRef}
                fields={[
                  'alertCount',
                  'closedCriticalTicketCount',
                  'closedTicketCount',
                  'openCriticalTicketCount',
                  'openTicketCount',
                  'totalTicketCount',
                  'loggedEventCount',
                ]}
                svgOptions={{
                  backgroundColor: 'white',
                  style: {
                    borderInline: 'none',
                    border: 'none',
                  },
                }}
              />
            </FloatingFocusManager>
          )}
        </Box>
      </Box>

      <Card
        sx={[
          (theme) => ({
            height: '309px',
            width: '100%',
            position: 'relative',
            padding: '24px',
            background: theme.vars.palette.secondary.light,
            boxShadow: 'none',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.light,
            }),
          }),
          dateFilterableStyles,
        ]}
      >
        {renderChart()}
      </Card>
    </Box>
  )
}

export default ThreatProtectionSummary
