import React, { createContext, useState, useContext, ReactNode } from 'react'

interface EnvironmentHealthBanSidesheetContextProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const EnvironmentHealthBanSidesheetContext = createContext<
  EnvironmentHealthBanSidesheetContextProps | undefined
>(undefined)

export const useEnvironmentHealthBanSidesheetContext = () => {
  const context = useContext(EnvironmentHealthBanSidesheetContext)
  if (!context) {
    throw new Error(
      'useEnvironmentHealthBanSidesheetContext must be used within EnvironmentHealthBanSidesheetContextProvider',
    )
  }
  return context
}

interface EnvironmentHealthBanSidesheetContextProviderProps {
  children: ReactNode
}

export const EnvironmentHealthBanSidesheetContextProvider: React.FC<
  EnvironmentHealthBanSidesheetContextProviderProps
> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <EnvironmentHealthBanSidesheetContext.Provider
      value={{ isOpen, setIsOpen }}
    >
      {children}
    </EnvironmentHealthBanSidesheetContext.Provider>
  )
}
