import { ApolloError } from '@apollo/client'
import { Box, Skeleton } from '@mui/material'

import { FormattedSecurityIndexEvent } from '@models/SecurityIndex'
import { ComponentError } from '@common/ComponentError'
import { AwaitCustomer } from '@hooks/useCustomer'
import { useDateFilterableStyles } from '@hooks/useDateFilterableStyles'

import Carousel from '../../../Carousel/Carousel'

interface IndexTrendEvents {
  loading: boolean
  error: ApolloError | undefined
  events: FormattedSecurityIndexEvent[] | null | undefined
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const IndexTrendEvents: React.FC<IndexTrendEvents> = ({
  loading,
  error,
  events,
  selectedTrendEventState,
}) => {
  const dateFilterableStyles = useDateFilterableStyles()
  if (error || !events) {
    return (
      <div style={{ textAlign: 'center', flex: 1 }}>
        <ComponentError />
      </div>
    )
  }

  return (
    <Box
      sx={[{ width: '100%', borderRadius: '5px' }, dateFilterableStyles]}
      data-testid="security-index-trend-events"
    >
      <Box component="section" sx={{ width: '100%' }} data-testid="dw-carousel">
        <AwaitCustomer
          loadingComponent={
            <Skeleton
              sx={{ minHeight: '75px', width: '100%' }}
              data-testid="skeleton-block"
            />
          }
        >
          <Carousel
            loading={loading}
            events={events}
            selectedTrendEventState={selectedTrendEventState}
          />
        </AwaitCustomer>
      </Box>
    </Box>
  )
}
export default IndexTrendEvents
