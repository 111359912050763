import { CellContext } from '@tanstack/react-table'
import { Typography } from '@mui/material'

import { ErrorLabel } from '@common/ErrorLabel'

import { Ticket, TicketRelatedUnion } from '../../../../../models'

interface TicketIdCellValues {
  shortDescription: Ticket['shortDescription']
  ticketNumber: Ticket['ticketNumber']
}

const TicketIdCell = (
  props: CellContext<TicketRelatedUnion, TicketIdCellValues>,
) => {
  const { shortDescription, ticketNumber } = props.getValue()

  return (
    <>
      <Typography
        fontWeight={600}
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {ticketNumber ? ticketNumber : <ErrorLabel iconSize={14} />}
      </Typography>
      <Typography noWrap variant="body2">
        {shortDescription ? shortDescription : <ErrorLabel iconSize={14} />}
      </Typography>
    </>
  )
}

export default TicketIdCell
