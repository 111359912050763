import { gql } from '@apollo/client'

export const NOTIFICATIONS_DATA_FRAGMENT = gql`
  fragment NotificationsData on Notification {
    id
    title
    publishedAt
    category
    isRead
    source {
      __typename
      ... on ThreatIntelReport {
        id
      }
    }
  }
`
