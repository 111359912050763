export enum ChangeHistoryType {
  LOG_SOURCE = 'log source',
  DETECTION = 'detection',
  APP_VERSION_CHANGE = 'app version change',
}
export const CHANGE_HISTORY_LOG_SOURCE_REMOVED = false
export const CHANGE_HISTORY_LOG_SOURCE_ADDED = true
export enum ChangeHistoryWeight {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export interface SecurityIndexChangeHistory {
  events: SecurityIndexEvent[] | null
}
interface SecurityIndexEventBase {
  id: number | string
  dataSource: string[]
  sourceName: string
  type: ChangeHistoryType
  action: boolean
  weight: ChangeHistoryWeight | null
}

export interface SecurityIndexEvent extends SecurityIndexEventBase {
  createdAt: string
}

export interface ChangeHistoryEventTableItem {
  id: number | string
  dataSource?: string
  sourceName: string
  type: ChangeHistoryType
  action: boolean
  weight: ChangeHistoryWeight | null
  createdAt: string
  subRows?: ChangeHistoryEventTableItem[]
}

export interface FormattedSecurityIndexEvent extends SecurityIndexEventBase {
  createdAt: Date
}

export enum BanKeysEnum {
  YOUR_INDEX = 'YOUR INDEX',
  AVG_CUSTOMER_INDEX = 'AVG CUSTOMER INDEX',
  AVG_INDUSTRY_INDEX = 'AVG INDUSTRY INDEX',
}

export interface SecurityIndexBan {
  index: string
  delta: string
}

export interface SecurityIndexBansMetrics {
  yourIndex: SecurityIndexBan
  avgCustomerIndex: SecurityIndexBan
  avgIndustryIndex: SecurityIndexBan
}

export interface IndexDetailsMetric {
  lifetimeIncrease: string
  enabledLogSourcesCount: number
  enabledDetectionsCount: number
  missingDataSourcesCount: number
}

export interface Trend {
  index: number
  metricDate: string
}

export interface IndexTrends {
  yourTrend: Trend[]
  avgCustomerTrend: Trend[]
  avgIndustryTrend: Trend[]
  pastPeriodTrend: Trend[]
}

export interface PreTransformedIndexValues {
  logSources: number
  detections: number
  enterpriseCoverage: number
}

export interface YourIndex {
  trends: IndexTrends
  details: IndexDetailsMetric
  preTransformedIndexValues: PreTransformedIndexValues
  unclassifiedLogSourceCount: number
  latestIndex: number
}

export interface UnclassifiedLogSource {
  index: string
  logSource: string
  dataModel: string
  dataType: string[]
}

export interface SecurityIndexNearestChangeDates {
  nearestStartDate: string
  nearestEndDate: string
}

export enum MaturityImpact {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}
export interface SiCalculation {
  dataType: string
  maturityImpact: MaturityImpact
  enabledSourceTypes: string[]
  detectionCount: number
  sourceTypes: string[] | string
  subRows?: SiCalculation[]
}

export interface SecurityIndexCalculationDataTypes {
  dataTypes: SiCalculation[]
}
