import { CellContext } from '@tanstack/react-table'
import { Box, IconButton, Typography } from '@mui/material'

import { User } from '@models/User'
import Icon from '@common/Icon'
import { zIndex } from '@components/App/Styles/zIndex'

export enum ModalTitles {
  ADD = 'Add User',
  EDIT = 'Edit User',
  ACTIVATE = 'Activate User',
  DEACTIVATE = 'Deactivate User',
  RESEND_EMAIL = 'Resend Email',
  DELETE = 'Delete User',
}
interface UserLoggedInCellValues {
  userLoggedIn: User['lastLogin']
  userOktaStatus: User['oktaStatus']
}

interface UserLoggedInCellProps {
  cellContext: CellContext<User, UserLoggedInCellValues>
  handleActivateUser: (user: User) => void
  handleDeactivateUser: (user: User) => void
  handleReactivateUser: (user: User) => void
  handleDeleteUser: (user: User) => void
  handleEditUser: (user: User) => void
}

const UserLoggedInCell: React.FC<UserLoggedInCellProps> = ({
  cellContext,
  handleActivateUser,
  handleDeactivateUser,
  handleReactivateUser,
  handleDeleteUser,
  handleEditUser,
}) => {
  const { userLoggedIn, userOktaStatus } = cellContext.getValue()

  const tableButtons = () => {
    const hoverButtons = [
      <IconButton
        data-testid="edit-user-btn"
        key={`${cellContext.row.original.id}-edit`}
        onClick={(e) => {
          e.stopPropagation()
          handleEditUser(cellContext.row.original)
        }}
      >
        <Icon variant="pencilOutline" />
      </IconButton>,
    ]

    if (userOktaStatus === 'PROVISIONED') {
      hoverButtons.push(
        <IconButton
          data-testid="resend-email-btn"
          key={`${cellContext.row.original.id}-resendemail`}
          onClick={(e) => {
            e.stopPropagation()
            handleReactivateUser(cellContext.row.original)
          }}
        >
          <Icon variant="resendEmail" />
        </IconButton>,
      )
    }

    if (userOktaStatus === 'STAGED' || userOktaStatus === 'DEPROVISIONED') {
      hoverButtons.push(
        <IconButton
          data-testid="activate-user-button"
          key={`${cellContext.row.original.id}-activate`}
          onClick={(e) => {
            e.stopPropagation()
            handleActivateUser(cellContext.row.original)
          }}
        >
          <Icon variant="checkmarkCircle" />
        </IconButton>,
      )
    }

    if (userOktaStatus === 'PROVISIONED' || userOktaStatus === 'ACTIVE') {
      hoverButtons.push(
        <IconButton
          data-testid="deactivate-user-btn"
          key={`${cellContext.row.original.id}-deactivate`}
          onClick={(e) => {
            e.stopPropagation()
            handleDeactivateUser(cellContext.row.original)
          }}
        >
          <Icon variant="banOutline" />
        </IconButton>,
      )
    }

    if (userOktaStatus === 'DEPROVISIONED') {
      hoverButtons.push(
        <IconButton
          data-testid="delete-user-btn"
          key={`${cellContext.row.original.id}-delete`}
          onClick={(e) => {
            e.stopPropagation()
            handleDeleteUser(cellContext.row.original)
          }}
        >
          <Icon variant="trashOutline" />
        </IconButton>,
      )
    }

    return hoverButtons
  }

  const lastLogin = new Date(userLoggedIn ? userLoggedIn : '')
    .toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    })
    .replace(',', '')

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {lastLogin === 'Invalid Date' ? '--' : lastLogin}
      </Typography>

      <Box
        id="cell-hover-buttons"
        sx={{
          display: 'flex',
          flex: '1',
          position: 'absolute',
          inset: '0',
          alignItems: 'center',
          opacity: '0',
          zIndex: zIndex.CELL_HOVER_BUTTONS,
        }}
      >
        <Box
          sx={(theme) => ({
            background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.lighter} 90%)`,
            width: '100%',
            height: '100%',
            ...theme.applyStyles('dark', {
              background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.light} 90%)`,
            }),
          })}
        />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.lighter,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.light,
            }),
            display: 'flex',
          })}
        >
          {tableButtons()}
        </Box>
      </Box>
    </Box>
  )
}

export default UserLoggedInCell
