import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useContext } from 'react'

import { Context } from '@components/App'
import {
  GET_SECURITY_INDEX_CHANGE_HISTORY,
  GetSecurityIndexChangeHistory,
  SecurityIndexVariables,
} from '@queries/securityIndex'

import ChangeHistoryTable from '../ChangeHistoryTable/ChangeHistoryTable'

const ChangeHistory: React.FC = () => {
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  const {
    state: {
      dateFilter: { startDate, endDate },
    },
  } = useContext(Context)

  const {
    data: { getSecurityIndexChangeHistory } = {
      getSecurityIndexChangeHistory: { events: [] },
    },
    loading: loading,
    error: error,
  } = useQuery<GetSecurityIndexChangeHistory, SecurityIndexVariables>(
    GET_SECURITY_INDEX_CHANGE_HISTORY,
    {
      variables: {
        selectedCustomer,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  return (
    <ChangeHistoryTable
      loading={loading && !getSecurityIndexChangeHistory?.events?.length}
      events={getSecurityIndexChangeHistory?.events || null}
      error={error}
    />
  )
}

export default ChangeHistory
