import { useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { Paths } from '@components/App/Types'
import { Customer } from '@models/index'
import { ComponentError } from '@common/ComponentError'
import { CreateTicketButton } from '@common/CreateTicketButton'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import { useCustomer } from '@hooks/useCustomer'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'
import { Loader } from '@common/Loader'
import { trackAnalyticEvent } from '@utils/analytics'

import dwEye from '../../../../../public/dw-eye.svg'

export interface TicketDetailsData {
  openCriticalCount: number
  openHighCount: number
  openWaitingCustomerCount: number
  openWaitingDeepwatchCount: number
}

export interface LinksData {
  splunk?: string
  servicenow: string
}

export interface TicketDetailsProps {
  customer: Customer
  data: TicketDetailsData | null
  loading: boolean
}

interface CurrentTicketListData {
  id: number
  leftIcon?: IconVariant
  name: string
  rightText: string
  loading: boolean
  iconColor: string | undefined
  onClick: VoidFunction
  leftAlternateImage?: boolean
}

const CurrentTicketsWidget = ({
  customer,
  data,
  loading,
}: TicketDetailsProps): JSX.Element => {
  const navigate = useNavigate()
  const theme = useTheme()

  const { search: currentCustomer } = useLocation()

  const { featureNgMdr } = useFlags()

  const { isDWEmployee } = useCustomer()

  const moduleFilter = featureNgMdr ? ',"module":["MDR"]' : ''

  const handleTicketNav = (
    ticketUrlPath: string,
    newParamFilters: string,
    newModuleFilter: string,
  ) => {
    const currParams = new URLSearchParams(currentCustomer)
    const newUrlPath: string = isDWEmployee
      ? `${ticketUrlPath}?${currParams}&selectedFilters={${newParamFilters}${newModuleFilter}}`
      : `${ticketUrlPath}?selectedFilters={${newParamFilters}${newModuleFilter}}`
    navigate(newUrlPath)
  }

  const listData = [
    {
      id: 0,
      leftIcon: 'skull',
      name: 'Open critical',
      rightText: `${data?.openCriticalCount}`,
      loading: loading,
      iconColor: theme.vars.palette.severity.critical,
      onClick: () => {
        handleTicketNav(
          `${Paths.TICKET_LIBRARY}`,
          '"priority":["Critical"]',
          moduleFilter,
        )
        trackAnalyticEvent('current_ticket_widget_clicked', {
          link_text: 'Open critical',
          ticket_count: data?.openCriticalCount,
        })
      },
    },
    {
      id: 1,
      leftIcon: 'alertCircle',
      name: 'Open high',
      rightText: `${data?.openHighCount}`,
      loading: loading,
      iconColor: theme.vars.palette.severity.high,
      onClick: () => {
        handleTicketNav(
          `${Paths.TICKET_LIBRARY}`,
          '"priority":["High"]',
          moduleFilter,
        )
        trackAnalyticEvent('current_ticket_widget_clicked', {
          link_text: 'Open high',
          ticket_count: data?.openHighCount,
        })
      },
    },
    {
      id: 2,
      leftIcon: 'personCircle',
      name: `Waiting for ${
        !customer?.customerName ? '...' : customer.customerName
      }`,
      rightText: `${data?.openWaitingCustomerCount}`,
      loading: loading,
      iconColor: theme.vars.palette.important.main,
      onClick: () => {
        handleTicketNav(
          `${Paths.TICKET_LIBRARY}`,
          '"state":["In Progress - Customer"]',
          moduleFilter,
        )
        trackAnalyticEvent('current_ticket_widget_clicked', {
          link_text: `Waiting for ${!customer?.customerName ? '...' : customer.customerName}`,
          ticket_count: data?.openWaitingCustomerCount,
        })
      },
    },
    {
      id: 3,
      leftAlternateImage: true,
      name: 'Waiting on Deepwatch',
      rightText: `${data?.openWaitingDeepwatchCount}`,
      loading: loading,
      iconColor: theme.vars.palette.brand.main,
      onClick: () => {
        handleTicketNav(
          `${Paths.TICKET_LIBRARY}`,
          '"state":["In Progress - dw"]',
          moduleFilter,
        )
        trackAnalyticEvent('current_ticket_widget_clicked', {
          link_text: 'Waiting on Deepwatch',
          ticket_count: data?.openWaitingDeepwatchCount,
        })
      },
    },
  ]

  const renderLinks = () => {
    if (!loading && !data) {
      return <ComponentError />
    }

    return (
      <List>
        {listData.map((item) => {
          return (
            <ListItem
              key={item.id}
              onClick={item.onClick}
              onKeyUp={(event) => handleKeyboardAction(event, item.onClick)}
            >
              <CurrentTicketsListItemIcon
                leftAlternateImage={item.leftAlternateImage}
                leftIcon={item.leftIcon as IconVariant}
                iconColor={item.iconColor}
              />
              <ListItemText primary={item.name} />
              {item.loading ? (
                <Loader />
              ) : (
                <ListItemText
                  id={`current-ticket-link--${item.id}`}
                  secondary={item.rightText}
                />
              )}
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <Box id="ticket-details" data-testid="current-tickets-widget">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        <Tooltip
          title="Tickets currently open in your environment."
          placement="top-start"
          followCursor
        >
          <Box className="current-tickets-title">
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{
                display: 'inline-block',
                verticalAlign: 'text-bottom',
                mr: 1,
              }}
            >
              Current tickets
            </Typography>

            {!loading && !data && (
              <Icon variant="warningOutline" size={20} color="warning" />
            )}
          </Box>
        </Tooltip>

        <CreateTicketButton />
      </Box>

      {renderLinks()}
    </Box>
  )
}

/**
 * Helper component for current tickets widget to render correct icon
 * @param param
 * @param param.leftAlternateImage
 * @param param.leftIcon
 * @param param.iconColor
 * @returns JSX
 */
const CurrentTicketsListItemIcon: React.FC<
  Pick<CurrentTicketListData, 'iconColor' | 'leftAlternateImage' | 'leftIcon'>
> = ({ leftAlternateImage, leftIcon, iconColor }) => {
  return (
    <ListItemIcon>
      {leftAlternateImage ? (
        <img src={dwEye} alt="Deepwatch" />
      ) : (
        <Icon
          variant={leftIcon as IconVariant}
          size={20}
          sx={{
            color: iconColor,
          }}
        />
      )}
    </ListItemIcon>
  )
}

export default CurrentTicketsWidget
