import { Box, Typography } from '@mui/material'

import Icon from '@common/Icon'

interface MitreTacticProps {
  key?: number | string
  name: string
  color: string
  active: boolean
  value: number
}

const MitreTactic = ({ name, color, active, value }: MitreTacticProps) => {
  return (
    <Box className={`tactic${active === true ? ' active' : ''}`}>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          gap: '4px',
          alignItems: 'center',
        }}
        tabIndex={0}
        role={'contentinfo'}
        aria-label={`${name} has ${value} alerts`}
      >
        <Icon sx={{ color, paddingRight: '4px' }} size={8} variant="ellipse" />
        <Typography color="textPrimary" variant="caption">
          {name}
        </Typography>
      </Box>
    </Box>
  )
}

export default MitreTactic
