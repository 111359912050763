import { Box, InputAdornment, TextField } from '@mui/material'

import Icon from '@common/Icon'

import { CustomerQuestionType } from '../CyberAssistantQuestions'

interface SearchBoxProps {
  searchValue: string
  questions: {
    question: string
  }[]
  onSearch: (searchTerm: string) => void
  onKeyPress?: () => void
  results?: (results: CustomerQuestionType[]) => void
}

const SearchBox: React.FC<SearchBoxProps> = ({
  searchValue,
  questions,
  onSearch,
  onKeyPress,
  results,
}) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    onSearch(value)

    const words = value.trim().toLowerCase().split(/\s+/)

    const filteredResults = questions.filter((question) => {
      // Convert question to lowercase and split it into words
      const questionWords = question.question.toLowerCase().split(/\s+/)

      // Extract words inside parentheses
      const parenthesisWords = (
        question.question.match(/\(([^)]+)\)/g) || []
      ).map((match) => match.slice(1, -1).toLowerCase()) // Remove parentheses and convert to lowercase

      // Merge words inside parentheses with the normal words
      const allWords = [...questionWords, ...parenthesisWords]

      // Check if every search term matches at least one word in the question
      return words.every((word) =>
        allWords.some((qWord) => qWord.startsWith(word.toLowerCase())),
      )
    })
    if (results) {
      results(filteredResults)
    }
  }

  return (
    <Box
      sx={() => ({
        width: '100%',
      })}
    >
      <Box
        sx={() => ({
          display: 'flex',
        })}
      >
        <TextField
          sx={{
            '.MuiInputBase-root': {
              padding: '0px',
            },
            paddingRight: '0px',
          }}
          type="search"
          data-testid="search-input"
          placeholder="Enter your question"
          value={searchValue}
          onChange={handleSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onKeyPress?.()
            }
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    variant="chevronForward"
                    size={24}
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                    })}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default SearchBox
