import { Cell, flexRender } from '@tanstack/react-table'
import { Box, SxProps, Theme } from '@mui/material'
import { Link } from 'react-router-dom'

import { useDWExpertEnabledUrl } from '@hooks/useDWExpertEnabledUrl'

import StyledTableCell from '../styled/StyledTableCell'

interface TableCellProps<T> {
  cell: Cell<T, unknown>
  onClick?: () => void
  sx?: SxProps<Theme>
  /**
   * React Router path that enables "open in new tab" functionality for this cell.
   * Regular clicks will be intercepted, but right-click and middle-click will work normally.
   * Do not include the `customer` query parameter; it will be automatically added by internal routing if necessary.
   * @example
   * linkTo="/tickets/123"
   */
  linkTo?: string
}

const TableCell = <T,>({
  cell,
  onClick,
  sx,
  linkTo,
}: TableCellProps<T>): JSX.Element => {
  const content = flexRender(cell.column.columnDef.cell, cell.getContext())
  const to = useDWExpertEnabledUrl(linkTo)
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.metaKey || e.ctrlKey) {
      e.stopPropagation() // Prevent the event from bubbling up to the parent cell and selecting the row
      return // Allow cmd/ctrl + click to work normally
    }
    e.preventDefault() // Otherwise, prevent the link from navigating the user; we only want to enable the right-click context menu
  }
  return (
    <StyledTableCell
      data-testid="table-cell"
      onClick={onClick}
      sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {to ? (
        <>
          {/*
           * This empty <Link /> spans the entire cell to create a full-size click target.
           * Table cells have their own layout model, so this is more reliable than
           * trying to make the content itself span the entire cell.
           */}
          <Link
            tabIndex={-1}
            to={to}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              inset: 0,
              position: 'absolute',
            }}
            onClick={handleLinkClick}
          />
          <Box>{content}</Box>
        </>
      ) : (
        content
      )}
    </StyledTableCell>
  )
}

export default TableCell
