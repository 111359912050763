import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import { User } from '@models/User'
import Icon from '@common/Icon'

interface UserNameCellValues {
  firstName: User['firstName']
  lastName: User['lastName']
  userIsAdmin: User['isAdmin']
}

const UserNameCell = (props: CellContext<User, UserNameCellValues>) => {
  const { firstName, lastName, userIsAdmin } = props.getValue()

  const fullName = firstName + ' ' + lastName

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      <Typography
        fontWeight={600}
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {fullName}
      </Typography>

      {userIsAdmin && (
        <Icon variant="key" size={18} sx={{ paddingLeft: '5px' }} />
      )}
    </Box>
  )
}

export default UserNameCell
