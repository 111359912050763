import { Box, Button, Theme, Typography } from '@mui/material'

import Icon from '@common/Icon'
import { Loader } from '@common/Loader'

const PaginationLoadingRowStyles = { padding: '1rem', textAlign: 'center' }

const PaginationTableCellStyles = (theme: Theme) => ({
  backgroundColor: theme.vars.palette.secondary.light,
  border: `1px solid ${theme.vars.palette.secondary.main}`,
  borderTop: 0,
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.dark,
    border: `1px solid ${theme.vars.palette.secondary.light}`,
  }),
})

interface PaginationLoadingRowProps {
  colSpan?: number
  currentLength: number
  pageSize: number
  total: number
  onScrollToTop: () => void
}

const PaginationLoadingRow: React.FC<PaginationLoadingRowProps> = ({
  colSpan = 6,
  currentLength,
  pageSize,
  total,
  onScrollToTop,
}) => {
  // don't show pagination if total size is less than/equal to page size
  if (total <= pageSize) {
    return null
  }

  if (currentLength === total) {
    return (
      <Box
        component="tr"
        className="loader-table-row"
        data-testid="pagination-loading-row"
        sx={PaginationLoadingRowStyles}
      >
        <Box component="td" colSpan={colSpan} sx={PaginationTableCellStyles}>
          <Box sx={{ alignItems: 'center', gap: '0.5rem', padding: '1rem 0' }}>
            <Typography variant="body2" color="textPrimary">
              All of your data has been loaded
            </Typography>
            <Button onClick={onScrollToTop} variant="text">
              Back to the top
              <Icon variant="arrowUp" />
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      component="tr"
      data-testid="pagination-loading-row"
      sx={PaginationLoadingRowStyles}
    >
      <Box component="td" colSpan={colSpan} sx={PaginationTableCellStyles}>
        <Box sx={{ alignItems: 'center', gap: '0.5rem', padding: '1rem 0' }}>
          <Typography variant="body2" color="textPrimary">
            Hang tight, we&apos;re fetching data
          </Typography>
          <Loader centered={true} strokeWidth={2} />
        </Box>
      </Box>
    </Box>
  )
}

export default PaginationLoadingRow
