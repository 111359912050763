import { useMemo, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import {
  MitreCoverageTactic,
  MitreCoverageTechnique,
} from '@models/DetectionCoverage'
import { useSelectedRow } from '@hooks/tableHooks'
import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../../context/MitreOverviewContext'
import { IconForCard, getFormattedTableData } from './utils'
import MitreTable from '../MitreTable/MitreTable'

interface MitreTacticsCardsProps {
  carouselTactic: number
}
export default function MitreTacticsCards({
  carouselTactic,
}: MitreTacticsCardsProps) {
  const { coverage } = useMitreOverviewContext()

  return (
    <Box
      data-testid="mitre-tactics-cards-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        padding: '16px',
        gap: '12px',
      }}
    >
      {coverage.dw.map((tactic, tacticIndex) => {
        if (carouselTactic !== tacticIndex) {
          return null
        }

        return tactic.techniques.map((technique, techniqueIndex) => (
          <TacticCard
            key={`${tactic.mitreTacticId}-${technique.mitreTechniqueId}`}
            tactic={tactic}
            technique={technique}
            tacticIndex={tacticIndex}
            techniqueIndex={techniqueIndex}
            carouselTactic={carouselTactic}
          />
        ))
      })}
    </Box>
  )
}

interface TacticCardProps {
  tactic: MitreCoverageTactic
  technique: MitreCoverageTechnique
  tacticIndex: number
  techniqueIndex: number
  carouselTactic: number
}
function TacticCard({
  tactic,
  technique,
  tacticIndex,
  techniqueIndex,
  carouselTactic,
}: TacticCardProps) {
  const {
    handleSideSheet,
    hasUserClosedSideSheet,
    carouselTechnique,
    mitreSelection,
    coverage,
    setCarouselTechnique,
    setHasUserClosedSideSheet,
    setMitreSelection,
  } = useMitreOverviewContext()
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'
  const backgroundColorWithMode = isLightMode
    ? theme.palette.text.primary
    : theme.palette.text.secondary

  const backgroundColor = () => {
    if (customerTechnique && customerTechnique.totalEnabledUseCaseCount > 0) {
      return { background: backgroundColorWithMode }
    } else {
      return { background: theme.palette.secondary.lighter }
    }
  }
  const customerTechnique = useMemo(() => {
    const customerTechnique = coverage.customer.find(
      (customerTechnique) =>
        customerTechnique.mitreTechniqueId === technique.mitreTechniqueId,
    )

    return customerTechnique
  }, [coverage, technique])

  const [shouldShowSubtechniques, setShouldShowSubtechniques] = useState(
    /* eslint-disable security/detect-object-injection */
    mitreSelection.technique !== undefined &&
      coverage.dw[carouselTactic].techniques[mitreSelection.technique] &&
      coverage.dw[carouselTactic].techniques[mitreSelection.technique]
        .subtechniques.length > 0,
    /* eslint-enable security/detect-object-injection */
  )

  const getTypographyStyles = () => {
    const fallbackColor = 'textPrimary'

    if (!customerTechnique) {
      return fallbackColor
    }

    if (customerTechnique && customerTechnique.totalEnabledUseCaseCount > 0) {
      return theme.palette.secondary.dark
    } else if (customerTechnique && !isLightMode) {
      return 'textSecondary'
    }

    return fallbackColor
  }

  const tableData = useMemo(() => {
    return getFormattedTableData(technique, customerTechnique)
  }, [technique, customerTechnique])

  const { rowSelection, setRowSelection } = useSelectedRow({
    data: tableData,
    rowIdKey: 'subtechniqueIndex',
  })

  /**
   * This method gets called when the technique card header is clicked. When
   * this is called, if the user has not already clicked this card then the side
   * sheet is opened and the subtechnique table is visible. if the user has already
   * clicked the card then we hide the table and close the side sheet
   */
  const handleTechniqueSelect = () => {
    const newVisibleValue = !shouldShowSubtechniques
    setShouldShowSubtechniques(newVisibleValue)
    const hasSubtechniques = technique.subtechniques.length > 0

    setRowSelection({})

    if (
      !hasSubtechniques ||
      !hasUserClosedSideSheet ||
      carouselTechnique !== technique.mitreTechniqueId
    ) {
      handleSideSheet(
        tacticIndex,
        techniqueIndex,
        undefined,
        () => {},
        undefined,
        false,
      )
    }

    const shouldOpenWithSubtechniques =
      hasSubtechniques &&
      (!shouldShowSubtechniques ||
        mitreSelection.subtechnique !== undefined ||
        carouselTechnique !== technique.mitreTechniqueId)
    const shouldOpenWithoutSubtechniques =
      mitreSelection.subtechnique !== undefined ||
      carouselTechnique !== technique.mitreTechniqueId
    const shouldOpen =
      shouldOpenWithSubtechniques || shouldOpenWithoutSubtechniques

    setShouldShowSubtechniques(shouldOpenWithSubtechniques)
    setHasUserClosedSideSheet(false)

    setMitreSelection({
      tactic: shouldOpen ? tacticIndex : undefined,
      technique: shouldOpen ? techniqueIndex : undefined,
      subtechnique: undefined,
    })
    setCarouselTechnique(shouldOpen ? technique.mitreTechniqueId : undefined)
  }

  /**
   * Called when a table row is clicked
   * @param id
   * @param rowData
   * @param subtechniqueIndex
   */
  const handleRowClick = (subtechniqueIndex: number) => {
    const sameSubtechnique = mitreSelection.subtechnique === subtechniqueIndex

    if (sameSubtechnique) {
      setRowSelection({})
      setMitreSelection({
        ...mitreSelection,
        subtechnique: undefined,
      })
    } else {
      setRowSelection({ [subtechniqueIndex]: true })
      setMitreSelection({
        ...mitreSelection,
        subtechnique: subtechniqueIndex,
      })
    }

    setHasUserClosedSideSheet(sameSubtechnique)
    handleSideSheet(tacticIndex, techniqueIndex, subtechniqueIndex, () => {
      setMitreSelection({
        ...mitreSelection,
        subtechnique: undefined,
      })
      setHasUserClosedSideSheet(true)
    })
  }

  return (
    <Box
      key={`${tactic.mitreTacticId}-${technique.mitreTechniqueId}}`}
      tabIndex={0}
      data-testid="mitre-card"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '4.5px 6px',
        minHeight: '36px',
        gap: '8px',
        borderRadius: '2px',
        cursor: 'pointer',
        '&:hover, &:focus-visible': {
          backgroundColor: theme.palette.primary.main,
          '#mitre-card-label, svg': {
            color: theme.palette.background.paper,
          },
        },
        ...(carouselTechnique === technique.mitreTechniqueId && {
          backgroundColor: theme.palette.primary.main,
        }),
        ...(technique.totalUseCaseCount === 0
          ? {
              border: `2px solid ${theme.palette.text.disabled}`,
              backgroundColor: theme.palette.background.paper,
            }
          : backgroundColor()),
      })}
      onClick={handleTechniqueSelect}
      onKeyDown={(e) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
          handleTechniqueSelect()
        }
      }}
      role="button"
    >
      {/* Label */}
      <Box
        data-testid="mitre-card-inner"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 1 auto',
          height: 'min-content',
          minWidth: 'min-content',
          '&:hover': {
            color: theme.palette.background.paper,
          },
        }}
      >
        <Typography
          id="mitre-card-label"
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            '&:hover': {
              color: theme.palette.background.paper,
            },
          })}
          color={getTypographyStyles()}
          fontWeight={600}
          variant="caption"
        >
          {`${technique.mitreTechniqueName}${
            technique.subtechniques.length > 0
              ? ` (${technique.subtechniques.length})`
              : ''
          }`}
        </Typography>
        {technique.subtechniques.length > 0 && (
          <Icon
            sx={{ color: getTypographyStyles() }}
            variant={IconForCard(
              shouldShowSubtechniques,
              carouselTechnique,
              technique.mitreTechniqueId,
            )}
          />
        )}
      </Box>
      {/* Table */}
      {shouldShowSubtechniques &&
        carouselTechnique === technique.mitreTechniqueId && (
          <MitreTable
            data={tableData}
            rowSelection={rowSelection}
            handleRowClick={handleRowClick}
            hasUserClosedSideSheet={hasUserClosedSideSheet}
          />
        )}
    </Box>
  )
}
