import { alpha, createTheme } from '@mui/material'
import type {} from '@mui/material/themeCssVarsAugmentation'

import { colors } from '@design-system/theme'
import { zIndex } from '@components/App/Styles/zIndex'

import darkTheme from './darkTheme'
import lightTheme from './lightTheme'

const FONT_FAMILY = ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(',')

export const defaultTheme = createTheme({
  colorSchemes: {
    dark: darkTheme,
    light: lightTheme,
  },
  cssVariables: {
    colorSchemeSelector: 'class',
  },
  defaultColorScheme: 'dark',
  typography: {
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    fontFamily: FONT_FAMILY,
    h6: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Paper-shadow': 'none !important',
          '--Paper-overlay': 'none !important',
          backgroundColor: `${theme.vars.palette.common.white} !important`,
          ...theme.applyStyles('dark', {
            backgroundColor: `${theme.vars.palette.secondary.dark} !important`,
          }),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: '0 -1rem',
          '--Paper-shadow': 'none !important',
          '--Paper-overlay': 'none !important',
          backgroundColor: 'transparent',
          '&.Mui-expanded': {
            margin: '0 -1rem',
          },
          '&:after': {
            position: 'absolute',
            left: 0,
            bottom: '-1px',
            right: 0,
            height: '1px',
            content: '""',
            opacity: 1,
            backgroundColor: theme.vars.palette.secondary.light,
            transition: 'none',
            zIndex: -1, // Allows the default browser focus outline to be visible when the panel is focused
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '24px 16px',
          '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded':
            {
              margin: 0,
              '&:before': {
                position: 'absolute',
                left: 0,
                top: '-1px',
                right: 0,
                height: '1px',
                content: '""',
                opacity: 1,
                backgroundColor: theme.vars.palette.secondary.main,
                transition: 'none',
                ...theme.applyStyles('dark', {
                  backgroundColor: theme.vars.palette.secondary.light,
                }),
              },
            },
          '.MuiAccordionSummary-content': {
            '.MuiTypography-root': {
              fontWeight: 500, // This overrides the default body2 font weight used in the CollapsiblePanel component
            },
          },
          /**
           * Remove the default MUI focus color in favor of default browser behavior;
           * I think we should revisit this in the future, because the MUI styles look nice.
           */
          '&.Mui-focusVisible': {
            backgroundColor: 'initial',
            outline: 'revert',
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 5,

          variants: [
            {
              props: { variant: 'outlined' },
              style: ({ theme }) => ({
                backgroundColor: theme.vars.palette.secondary.light,
                ...theme.applyStyles('dark', {
                  backgroundColor: theme.vars.palette.secondary.main,
                }),
              }),
            },
          ],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&.MuiPaper-outlined': {
            border: `1px solid ${colors.util.navy[300]}`,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.secondary.main,
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:last-child': {
            paddingBottom: '1rem',
          },
          backgroundColor: theme.vars.palette.secondary.dark,
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          visibility: 'hidden',
          position: 'absolute',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'initial', // Required to allow interaction with the page while the drawer is open; using pointer events had too many side effects with the drawer content
          /** Sidesheet root styles */
          '.MuiDrawer-paperAnchorRight': {
            backdropFilter: 'blur(4px)',
            background: alpha(theme.palette.common.white, 0.92),

            bottom: 0,
            borderRight: '1px solid rgba(238, 246, 249, 6%)',
            width: '450px',
            minWidth: '450px',
            maxWidth: '40vw',
            ...theme.applyStyles('dark', {
              background: alpha(theme.palette.secondary.dark, 0.92),
            }),
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: 0,
          padding: 0,
          position: 'relative',
          '&.switch-label': {
            justifyContent: 'space-between',
            '.MuiFormControlLabel-label': {
              border: 'none',
              padding: 0,
              margin: 0,
            },
          },
          '&.checkbox-label': {
            '.MuiFormControlLabel-label': {
              borderRadius: '5px',
              padding: '8px',
              margin: '4px',
              color: theme.vars.palette.text.primary,
              border: `1px solid ${theme.vars.palette.text.primary}`,
            },
            '.Mui-checked + span': {
              color: theme.vars.palette.text.primary,
              backgroundColor: theme.vars.palette.secondary.main,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.secondary.dark,
                backgroundColor: theme.vars.palette.text.primary,
              }),
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '.3rem',
          '.MuiChip-label': {
            display: 'flex',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-filledDefault': {
            '&.MuiChip-colorDefault': {
              backgroundColor: theme.vars.palette.text.secondary,
              border: `1px solid ${theme.vars.palette.secondary.main}`,
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.text.primary,
              }),
              padding: '0 .5rem',
              '.MuiTypography-root': {
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.secondary.dark,
                }),
              },
            },
            '.MuiChip-deleteIcon': {
              display: 'flex',
              margin: '0 0 0 .5rem',
            },
            '.MuiChip-label': {
              padding: '0',
            },
          },
          '&.MuiChip-outlined': {
            fontSize: '.75rem',
            padding: '0.125rem 0.25rem',
            borderRadius: '0.125rem',
            height: '1.1rem',
            color: theme.vars.palette.text.secondary,
            borderColor: theme.vars.palette.primary.main,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.darker,
            }),
            '.MuiChip-label': {
              padding: 0,
              fontWeight: 500,
            },
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            'ion-icon': {
              color: `${theme.vars.palette.text.disabled} !important`,
            },
          },
          '&.Mui-focusVisible': {
            outline: `1px solid ${theme.vars.palette.text.secondary}`,
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
    },
    MuiLink: {
      defaultProps: {
        variant: 'subtitle2',
        underline: 'hover',
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: zIndex.MUI_DIALOG,
          '.MuiDialog-paper': {
            backgroundColor: theme.vars.palette.common.white,
            backgroundImage: 'none',
            borderRadius: '5px',
            minWidth: '500px',
            border: `1px solid ${theme.vars.palette.secondary.main}`,
            maxWidth: 'none',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.main,
              border: `1px solid ${theme.vars.palette.text.secondary}`,
            }),
          },
          '.MuiDialogTitle-root': {
            borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
            alignItems: 'center',
            boxShadow: `0 1px 0 0 ${theme.vars.palette.secondary.light}`,
            ...theme.applyStyles('dark', {
              borderBottom: `1px solid ${theme.vars.palette.secondary.lighter}`,
            }),
          },
          '.MuiDialogContent-root': {
            padding: 0,
          },
          '.MuiModal-backdrop': {
            backdropFilter: 'blur(12px)',
            backgroundColor: 'rgba(28, 28, 28, 54%)',
          },
          '.MuiDialog-container': {
            opacity: 1,
            overflowY: 'hidden',
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: zIndex.MUI_DIALOG,
            inset: 0,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'transparent !important',
        }),
      },
    },
    MuiAvatar: {
      defaultProps: {
        'data-testid': 'user-avatar',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 700,
          fontSize: 11,
          width: 28,
          height: 28,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px',
          letterSpacing: '1px',
          color: theme.vars.palette.text.secondary,
        }),
      },
      variants: [
        {
          props: { variant: 'cust' },
          style: ({ theme }) => ({
            background: theme.vars.palette.avatar.cust,
          }),
        },
        {
          props: { variant: 'dw' },
          style: ({ theme }) => ({
            background: theme.vars.palette.avatar.dw,
          }),
        },
        {
          props: { variant: 'system' },
          style: ({ theme }) => ({
            background: theme.vars.palette.secondary.main,
          }),
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          },
          '&:not(.Mui-selected)': {
            fontWeight: 500,
            color: theme.vars.palette.text.primary,
            '&:hover': {
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        fixed: ({ theme }) => ({
          borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
          ...theme.applyStyles('dark', {
            borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
          }),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          paddingRight: '0.5rem', // Should we keep this?
          fontSize: '1rem',
          width: '100%',
          marginBottom: '0.5rem',

          '.MuiInputBase-root': {
            backgroundColor: `${theme.vars.palette.common.white} !important`,
            border: `1px solid ${theme.vars.palette.primary.main}`,
            borderRadius: '5px',
            padding: '0.5rem',
            ...theme.applyStyles('dark', {
              backgroundColor: `${theme.vars.palette.secondary.main} !important`,
              border: `1px solid ${theme.vars.palette.secondary.light}`,
            }),
          },
          '.MuiOutlinedInput-root:not(.Mui-error):not(Mui-disabled)': {
            ...theme.applyStyles('dark', {
              outline: `1px solid ${theme.vars.palette.secondary.lighter}`,
            }),
          },
          '.MuiInputBase-root.MuiOutlinedInput-root': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent', // Only use outline for border
            },
          },
          '.MuiInputAdornment-positionEnd': {
            paddingLeft: 0,
          },
          '.MuiInputAdornment-positionStart': {
            marginLeft: '8px',
            marginRight: 0,
          },
          input: {
            textOverflow: 'ellipsis',
            padding: '8px 11px',
            WebkitTextFillColor: `${theme.vars.palette.text.primary} !important`,
          },
          'input::placeholder': {
            opacity: '1 !important',
          },
          label: {
            position: 'relative',
            display: 'block',
            fontSize: '16px',
            lineHeight: 1.4,
            marginBottom: '8px',
            fontWeight: 600,
            color: theme.vars.palette.text.primary,
            transform: 'initial',
            '.MuiFormLabel-asterisk': {
              display: 'none',
            },
          },
          // Error
          '.MuiFormHelperText-root': {
            margin: 0,
            position: 'absolute',
            bottom: '-1.5rem',
          },
          '.MuiOutlinedInput-root.Mui-error': {
            outline: `1px solid ${theme.vars.palette.error.main}`,
          },
          // Focused
          '.Mui-focused': {
            '&.MuiOutlinedInput-root:not(.Mui-error)': {
              outline: `1px solid ${theme.vars.palette.primary.main}`,
              ...theme.applyStyles('dark', {
                outline: `1px solid ${theme.vars.palette.text.primary}`,
              }),
            },
            '.MuiInputAdornment-root': {
              'ion-icon:not(button.Mui-disabled > ion-icon)': {
                // Don't change the color if the button is disabled
                color: `${theme.vars.palette.text.secondary} !important`,
              },
            },
            input: {
              color: theme.vars.palette.text.primary,
              WebkitTextFillColor: `${theme.vars.palette.text.primary} !important`,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
                WebkitTextFillColor: `${theme.vars.palette.text.secondary} !important`,
              }),
            },
          },
          'label.Mui-focused:not(.Mui-error)': {
            color: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          },
          // Disabled
          '.Mui-disabled': {
            backgroundColor: 'transparent',
            '.MuiInputAdornment-root': {
              'ion-icon': {
                color: `${theme.vars.palette.text.disabled} !important`,
              },
            },
            outline: `0 !important`,
            input: {
              opacity: 0.64,
              WebkitTextFillColor: theme.vars.palette.text.primary,
            },
          },
          'label.Mui-disabled': {
            color: theme.vars.palette.text.primary,
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: ({ theme }) => ({
          color: `${theme.vars.palette.common.white} !important`,
          ...theme.applyStyles('dark', {
            color: `${theme.vars.palette.secondary.darker} !important`,
          }),
        }),
        tooltip: ({ theme }) => ({
          cursor: 'default !important',
          fontSize: '.75rem',
          maxWidth: '230px',
          width: 'max-content',
          padding: '6px',
          backgroundColor: `${theme.vars.palette.common.white} !important`,
          boxShadow: 'rgb(0 0 0 / 35%) 0 5px 15px',
          color: `${theme.vars.palette.text.primary} !important`,
          ...theme.applyStyles('dark', {
            backgroundColor: `${theme.vars.palette.secondary.darker} !important`,
            color: `${theme.vars.palette.text.secondary} !important`,
          }),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
    MuiList: {
      defaultProps: {
        role: 'listbox',
      },
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        tabIndex: 0,
        role: 'option',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          cursor: 'pointer',
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          borderRadius: '5px',
          paddingLeft: 0,
          paddingRight: 0,
          padding: '8px',
          ':hover': {
            backgroundColor: theme.vars.palette.secondary.lighter,
            '.MuiListItemText-primary': {
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            },
          },
          '.MuiListItemText-secondary': {
            color: theme.vars.palette.primary.main,
            fontWeight: 600,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          },
          '.MuiTypography-root': {
            fontSize: '.875rem',
            marginTop: 0,
            marginBottom: 0,
          },
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
            border: `1px solid ${theme.vars.palette.secondary.light}`,
          }),
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: 0,
          marginBottom: 0,
          color: theme.vars.palette.text.primary,
          '.MuiListItemText-primary': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '.MuiListItemText-secondary': {
            textAlign: 'right',
            marginRight: '10px',
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: '8px',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        square: true,
      },
      styleOverrides: {
        filledInfo: ({ theme }) => ({
          backgroundColor: theme.vars.palette.primary.light,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.common.black,
          }),
        }),
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '.MuiBadge-badge': {
            backgroundColor: '#cc0041',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiPaper-root': {
            backgroundColor: theme.vars.palette.secondary.dark,
            '--Paper-overlay': 'none !important',
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.text.primary,
          fontWeight: 600,
          marginBottom: '0.5rem',
          '&.Mui-focused': {
            color: theme.vars.palette.text.primary,
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.primary,
          }),
          '&.Mui-checked': {
            color: theme.vars.palette.primary.main,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          },
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '6px',
          padding: '2px',
          borderRadius: '2px',
        },
      },
    },
  },
})
