import { CellContext } from '@tanstack/react-table'
import { formatDistanceToNowStrict } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, IconButton, Typography } from '@mui/material'

import Icon from '@common/Icon'
import { zIndex } from '@components/App/Styles/zIndex'

import { navigateUserType } from '../../../../../utils'
import { Ticket, TicketRelatedUnion } from '../../../../../models'
import { Context } from '../../../../App'

const TicketLastUpdatedCell = (
  props: CellContext<TicketRelatedUnion, Ticket['sysUpdatedOn']>,
) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const { featureEditTicketPage } = useFlags()

  const navigate = useNavigate()

  const navigateToTicketEdit = () => {
    navigateUserType(
      isDWEmployee,
      `/tickets/library/${props.row.original.sysId}/edit`,
      navigate,
      customerShortName,
    )
  }

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Typography
        noWrap
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {formatDistanceToNowStrict(new Date(props.getValue()), {
          addSuffix: true,
        })}
      </Typography>

      {featureEditTicketPage && (
        <Box
          id="cell-hover-buttons"
          data-testid="last-updated-cell-hover-buttons"
          sx={{
            display: 'flex',
            flex: '1',
            position: 'absolute',
            inset: '0',
            alignItems: 'center',
            opacity: '0',
            zIndex: zIndex.CELL_HOVER_BUTTONS,
          }}
        >
          <Box
            sx={(theme) => ({
              background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.lighter} 90%)`,
              width: '100%',
              height: '100%',
              ...theme.applyStyles('dark', {
                background: `linear-gradient(90deg,rgb(2 0 36 / 0%) 0%,${theme.vars.palette.secondary.light} 90%)`,
              }),
            })}
          />

          <Box
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.secondary.lighter,
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.light,
              }),
              display: 'flex',
            })}
          >
            <IconButton
              data-testid="view-ticket-button"
              onKeyDown={(keyboardEvent) => {
                keyboardEvent.stopPropagation()
              }}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault() // Prevent the event from bubbling to the link and navigating the user
                navigateToTicketEdit()
              }}
            >
              <Icon variant="arrowForwardCircleOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default TicketLastUpdatedCell
