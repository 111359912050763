import {
  FieldFunctionOptions,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'

import { TicketData } from '@models/Tickets'
import { ThreatIntelReport } from '@models/ThreatIntel'
import { ThreatIntelReportsData } from '@queries/threatIntel'
import { NotificationsData } from '@models/Notification'
import { PaginatedDetectionsData } from '@queries/detection'
import { PaginatedForwardersData } from '@queries/forwarders'

import { cloneAndAppendMergedData, mergeUtil } from './apolloUtils'

const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        detections: {
          keyArgs: [
            '$selectedCustomer',
            '$sorting',
            '$filters',
            '$keywordSearch',
          ],

          merge: (
            existing: PaginatedDetectionsData,
            incoming: PaginatedDetectionsData,
            opts,
          ) => {
            const mergedDetections = mergeUtil(
              existing?.detections,
              incoming.detections,
              opts,
            )

            return cloneAndAppendMergedData(
              incoming,
              'detections',
              mergedDetections,
            )
          },
        },
        edrAssets: {
          keyArgs: ['$selectedCustomer', '$filters', '$sorting'],
          merge: (existing, incoming, opts) => {
            const mergedAssets = mergeUtil(existing?.data, incoming.data, opts)

            return cloneAndAppendMergedData(incoming, 'data', mergedAssets)
          },
        },
        edrAssetsFilterOptions: {
          keyArgs: ['$selectedCustomer'],
        },
        edrBans: {
          keyArgs: ['$selectedCustomer', 'input', ['banType']],
        },
        edrChart: {
          keyArgs: ['$selectedCustomer', 'input', ['chartType']],
        },
        firewallDevices: {
          keyArgs: ['$selectedCustomer', '$sorting'],
          merge: (existing, incoming, opts) => {
            const mergedAssets = mergeUtil(existing?.data, incoming.data, opts)

            return cloneAndAppendMergedData(incoming, 'devices', mergedAssets)
          },
        },
        environmentHealthForwarders: {
          keyArgs: ['$selectedCustomer', '$sorting', '$filters'],
          merge: (
            existing: PaginatedForwardersData,
            incoming: PaginatedForwardersData,
            opts,
          ) => {
            const mergedForwarders = mergeUtil(
              existing?.forwarders,
              incoming.forwarders,
              opts,
            )

            return cloneAndAppendMergedData(
              incoming,
              'forwarders',
              mergedForwarders,
            )
          },
        },
        getTickets: {
          keyArgs: [
            '$selectedCustomer',
            'input',
            ['selectedFilters', 'selectedSortings'],
          ],
          merge: (
            existing: TicketData,
            incoming: TicketData,
            opts: FieldFunctionOptions,
          ): TicketData => {
            const mergedTickets = mergeUtil(
              existing?.tickets,
              incoming.tickets,
              opts,
              true,
            )

            return cloneAndAppendMergedData(incoming, 'tickets', mergedTickets)
          },
        },
        // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
        getTicketDetails: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Ticket',
              sysId: args?.input.ticketId,
            })
          },
        },
        getThreatIntelReports: {
          keyArgs: [
            '$selectedCustomer',
            '$input',
            [
              'reportTypes',
              'filters',
              'keywordSearch',
              'sortBy',
              'sortDirection',
            ],
          ],

          merge: (
            existing: ThreatIntelReportsData | undefined,
            incoming: ThreatIntelReportsData,
            opts: FieldFunctionOptions,
          ): ThreatIntelReportsData => {
            const mergedReports = mergeUtil(
              existing?.threatIntelReports,
              incoming.threatIntelReports,
              opts,
            )

            return cloneAndAppendMergedData(
              incoming,
              'threatIntelReports',
              mergedReports,
            )
          },
        },
        getThreatIntelReport: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ThreatIntelReport',
              id: args?.reportId,
            })
          },
        },
        getDashboardThreatIntelReports: {
          keyArgs: ['$selectedCustomer', '$input'],
          merge: (
            existing: ThreatIntelReport[],
            incoming: ThreatIntelReport[],
          ): ThreatIntelReport[] => {
            return mergeUtil(existing, incoming)
          },
        },
        paginateNotifications: {
          keyArgs: ['$input'],
          merge: (
            existing: NotificationsData,
            incoming: NotificationsData,
          ): NotificationsData => {
            const mergedNotifications = mergeUtil(
              existing?.notifications,
              incoming.notifications,
            )

            return cloneAndAppendMergedData(
              incoming,
              'notifications',
              mergedNotifications,
            )
          },
        },
        pollNotifications: {
          keyArgs: ['$input'],
          merge: (
            existing: Notification[],
            incoming: Notification[],
          ): Notification[] => {
            return mergeUtil(existing, incoming)
          },
        },
        ticketStats: {
          keyArgs: ['$selectedCustomer', '$input'],
        },
      },
    },
    Detection: {
      keyFields: ['useCase'],
    },
    EDRAsset: {
      keyFields: ['deviceId'],
    },
    Devices: {
      keyFields: ['deviceId'],
    },
    SquadHomeCustomerData: {
      keyFields: ['customerShortName'],
    },
    Ticket: {
      keyFields: ['sysId'],
      fields: {
        __all__: {
          merge: (existing, incoming, opts) =>
            opts.mergeObjects(existing, incoming),
          read(existing) {
            // To prevent undefined fields from breaking fragment reads, we'll convert them to null
            return existing ?? null
          },
        },
      },
    },
  },
}

const inMemoryCache = new InMemoryCache(inMemoryCacheConfig)

export default inMemoryCache
