import { Link, SxProps, Theme, Typography } from '@mui/material'

import { Paths } from '@components/App/Types'
import { CustomerInfoVariables } from '@queries/customer'

import { SERVICE_NOW_CUSTOMER_SERVICE_URL } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import { AnswerId } from '../CyberAssistantQuestions'

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
    whiteSpace: 'pre-line',
    textWrap: 'wrap',
  }),
})

const EvaluateMitreCoverage = (selectedCustomer: CustomerInfoVariables) => {
  return (
    <>
      <Typography
        variant="body1"
        sx={(theme) => ({
          ...typographyContent(theme),
        })}
      >
        <Link
          href={
            selectedCustomer.selectedCustomer !== null
              ? `${Paths.DETECTION_COVERAGE_OVERVIEW}?customer=${selectedCustomer.selectedCustomer}`
              : Paths.DETECTION_COVERAGE_OVERVIEW
          }
          rel="noopener noreferrer"
          target="_self"
          variant="body1"
        >
          MITRE ATT@CK Detection Coverage
        </Link>

        {` is a useful visual that allows a security program to review their current visibility for the central, and most impactful, tactics and techniques.`}
      </Typography>
      <br />
      <Typography
        variant="body1"
        sx={(theme) => ({ ...typographyContent(theme) })}
      >
        {` Work with your Deepwatch Experts to determine the best next steps in your security journey based on which coverage is right for you.`}
      </Typography>
      <br />
      <Typography
        variant="body1"
        sx={(theme) => ({ ...typographyContent(theme) })}
      >
        {`You can `}
        {
          <Link
            href={SERVICE_NOW_CUSTOMER_SERVICE_URL}
            rel="noopener noreferrer"
            target="_blank"
            variant="body1"
          >
            create a ticket
          </Link>
        }
        {` if you’d like to request a detection assessment.`}
      </Typography>
      <FeedbackForm answerId={AnswerId.MITRE} />
    </>
  )
}

export default EvaluateMitreCoverage
