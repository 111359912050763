import { useContext, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import { Context } from '@components/App'
import { Paths } from '@components/App/Types'
import { navigateUserType } from '@utils/index'

import { TicketLibrary } from './TicketLibrary'
import { TicketEdit } from './TicketEdit'
import { TicketsContextProvider } from './context/TicketsContext'
import TicketOverview from './TicketOverview/TicketOverview'

interface TicketsProps {
  component?: 'library' | 'edit' | 'overview'
}

const components = {
  library: TicketLibrary,
}
const RedirectToTicketLibrary = () => {
  const navigate = useNavigate()
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer') || ''

  useEffect(() => {
    navigateUserType(
      isDWEmployee,
      Paths.TICKET_LIBRARY,
      navigate,
      selectedCustomer || customerShortName,
    )
  }, [customerShortName, isDWEmployee, navigate, selectedCustomer])

  return null
}

const Tickets = ({ component = 'library' }: TicketsProps) => {
  const [searchParams] = useSearchParams()

  const { featureEditTicketPage } = useFlags()

  const selectedCustomer = searchParams.get('customer') || ''

  components['edit'] = featureEditTicketPage
    ? TicketEdit
    : RedirectToTicketLibrary

  components['overview'] = TicketOverview

  const ResultComponent = components[`${component}`]

  return (
    <TicketsContextProvider selectedCustomer={selectedCustomer}>
      <Box data-testid="tickets" sx={{ overflowY: 'auto', width: '100%' }}>
        {components[`${component}`] && <ResultComponent />}
      </Box>
    </TicketsContextProvider>
  )
}

export default Tickets
