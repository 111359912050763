import {
  ColumnFiltersState,
  Table,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { debounce } from '@tanstack/react-virtual'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { ApolloError } from '@apollo/client'
import {
  alpha,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { TableHeader } from '@common/Table'
import { ComponentError } from '@common/ComponentError'
import { NoResults } from '@common/NoResults'
import { SiCalculation, MaturityImpact } from '@models/SecurityIndex'
import Icon from '@common/Icon'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'

type SiCalculationTableProps = {
  dataTypes: SiCalculation[] | null
  error?: ApolloError
  loading: boolean
}

interface SiCalculationTableContentProps
  extends Omit<SiCalculationTableProps, 'dataTypes'> {
  table: Table<SiCalculation>
}

const SiMetricsTable: React.FC<SiCalculationTableProps> = ({
  dataTypes,
  loading,
  error,
}) => {
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const formattedData: SiCalculation[] = useMemo(() => {
    if (!dataTypes) return []

    const sortedEnabledSourceTypes = dataTypes.map((types) => {
      if (types.enabledSourceTypes.length > 0) {
        return {
          ...types,
          enabledSourceTypes: (types.enabledSourceTypes as string[])
            .slice()
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
        }
      } else {
        return types
      }
    })

    return sortedEnabledSourceTypes.map(
      ({ dataType, detectionCount, enabledSourceTypes, maturityImpact }) => {
        const formatted = {
          dataType,
          detectionCount,
          enabledSourceTypes: enabledSourceTypes && [enabledSourceTypes[0]],
          maturityImpact,
          sourceTypes:
            enabledSourceTypes.length > 0
              ? Array(enabledSourceTypes).join(' ')
              : 'None', // for filtering
        }
        // only apply subrows if more than one data type
        if (enabledSourceTypes?.length > 1) {
          const subRows: SiCalculation[] = []

          // skip first item; parent row will display first data source
          for (let i = 1; i < enabledSourceTypes.length; i++) {
            subRows.push({
              ...formatted,
              enabledSourceTypes: [enabledSourceTypes[i]],
            })
          }

          return {
            ...formatted,
            subRows,
          }
        }
        return formatted
      },
    )
  }, [dataTypes])

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<SiCalculation>()

    return [
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('DATA TYPE'),
        sortingFn: 'textCaseSensitive',
      }),
      columnHelper.accessor('maturityImpact', {
        id: 'maturityImpact',
        cell: (props) => {
          const weight = props.getValue()
          let color = ''

          if (weight === MaturityImpact.High) {
            color = theme.palette.success.main
          }
          if (weight === MaturityImpact.Medium) {
            color = theme.palette.primary.light
          }
          if (weight === MaturityImpact.Low) {
            color = isLightMode
              ? theme.palette.text.primary
              : theme.palette.text.secondary
          }
          return (
            <Chip
              variant="outlined"
              label={weight.toString().toUpperCase()}
              sx={{
                '&.MuiChip-outlined': {
                  color: color,
                  borderColor: alpha(color, 0.54),
                },
              }}
            />
          )
        },
        header: () => buildGenericHeader('DW WEIGHT'),
        sortingFn: (rowA, rowB) => {
          const rowAImpact = rowA.original.maturityImpact
          const rowBImpact = rowB.original.maturityImpact
          if (
            rowAImpact === MaturityImpact.High ||
            rowBImpact === MaturityImpact.Low
          ) {
            return 1
          } else {
            return -1
          }
        },
        enableSorting: true,
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor('enabledSourceTypes', {
        id: 'enabledSourceTypes',
        cell: (props) => {
          const hasSourceTypes = !props.getValue()[0]
            ? 'None'
            : props.getValue()
          return (
            <Typography
              noWrap
              variant="body2"
              sx={(theme) => ({
                color: hasSourceTypes === 'None' ? 'warning' : 'textPrimary',
                ...theme.applyStyles('dark', {
                  color:
                    hasSourceTypes === 'None' ? 'warning' : 'textSecondary',
                }),
              })}
            >
              {hasSourceTypes}
            </Typography>
          )
        },
        header: () => buildGenericHeader('ENABLED SOURCE TYPES'),
        enableSorting: false,
        filterFn: (row, _, value) => {
          return !!(row.original.sourceTypes as string | undefined)
            ?.toLowerCase()
            .includes(value.toLowerCase())
        },
      }),
      columnHelper.accessor('detectionCount', {
        id: 'detectionCount',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('ENABLED DETECTIONS'),
        sortingFn: 'alphanumeric',
        size: 160,
      }),
    ]
  }, [])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table: Table<SiCalculation> = useReactTable({
    columns: columnDef,
    data: formattedData,
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    state: { columnFilters, expanded: true, columnSizing },
    initialState: {
      sorting: [
        {
          id: 'dataType',
          desc: false,
        },
      ],
    },
    columnResizeMode: 'onChange',
    onColumnSizingChange,
  })

  const [searchInput, setSearchInput] = useState('')

  const debouncedHandleInputChange = useCallback(
    debounce(
      window,
      (value: string) => {
        setColumnFilters([
          {
            id: 'enabledSourceTypes',
            value,
          },
        ])
      },
      500,
    ),
    [setColumnFilters],
  )

  useEffect(() => {
    debouncedHandleInputChange(searchInput)
  }, [searchInput, debouncedHandleInputChange])

  return (
    <Box
      data-testid="si-calculation-table-container"
      sx={{
        marginTop: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography color="textPrimary" fontWeight={600} variant="body2">
            Data type mapping
          </Typography>
        </Box>
        <Box>
          <TextField
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon variant="searchOutline" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchInput}
                      onClick={() => setSearchInput('')}
                    >
                      <Icon variant="close" />
                    </IconButton>
                  </InputAdornment>
                ),
              },
              htmlInput: {
                'aria-label': 'source-type-search',
              },
            }}
            role="search"
            name="source-type-search"
            type="text"
            onChange={({ target }) => setSearchInput(target.value)}
            placeholder="Search by source type"
            value={searchInput}
            disabled={loading}
          />
        </Box>
      </Box>

      <CommonTableContainer ref={tableContainerRef}>
        <CommonTable data-testid="si-calculation__table">
          <thead data-testid="si-calculation__table-header">
            <tr data-testid="si-calculation__table-header-row">
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<SiCalculation>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>
          <TableContent table={table} loading={loading} error={error} />
        </CommonTable>
      </CommonTableContainer>
    </Box>
  )
}
export default SiMetricsTable

const TableContent: React.FC<SiCalculationTableContentProps> = ({
  table,
  loading,
  error,
}) => {
  if (loading) {
    return <tbody>{buildSkeletonRows(table.getAllColumns())}</tbody>
  }

  if (error) {
    return (
      <CommonTableStruct>
        <ComponentError
          errorSubText="Check your connection and reload the page. If the problem persists, contact our support team for assistance."
          includeReloadButton={true}
          errorIcon={fetchErrorIcon}
        />
      </CommonTableStruct>
    )
  }

  if (table.getRowCount() === 0) {
    return (
      <CommonTableStruct>
        <NoResults />
      </CommonTableStruct>
    )
  }

  return (
    <tbody data-testid="si-calculation__table-body">
      {table.getRowModel().rows.map((row) => {
        const rowId = row.id.split('_')[0]
        // to adjust table border styles
        const isSubRow = rowId.includes('.') // ? ' notSubRow' : ''

        return (
          <MemoizedTanStackTableRow
            disableCursor
            key={row.id}
            testId={
              isSubRow ? 'si-calculation__row' : 'si-calculation__notSubRow'
            }
            rowId={`si-calculations-table-row-${rowId}`}
            isSelected={false}
            visibleCells={row.getVisibleCells()}
            sx={(theme) => ({
              '&:first-child td': {
                borderTop: 'none !important',
              },
              td: {
                borderTop: isSubRow
                  ? 'none'
                  : `1px solid ${theme.vars.palette.secondary.main}`,
                ...theme.applyStyles('dark', {
                  borderTop: isSubRow
                    ? 'none'
                    : `1px solid ${theme.vars.palette.secondary.light}`,
                }),
                borderBottom: 'none',
                '&:nth-child(1), &:nth-child(2)': {
                  borderTop: isSubRow ? 'none' : undefined,
                  borderBottom: isSubRow ? 'none' : undefined,
                  '*': {
                    visibility: isSubRow ? 'hidden' : undefined,
                  },
                },
                '&:nth-child(3), &:nth-child(4)': {
                  borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
                  ...theme.applyStyles('dark', {
                    borderTop: `1px solid ${theme.vars.palette.secondary.light}`,
                  }),
                },
              },
              '&:last-child td': {
                borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
                ...theme.applyStyles('dark', {
                  borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
                }),
              },
            })}
          />
        )
      })}
    </tbody>
  )
}

const CommonTableStruct: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <tbody>
      <tr>
        <td
          align="center"
          colSpan={4}
          style={{ border: 'none', padding: '50px' }}
        >
          {children}
        </td>
      </tr>
    </tbody>
  )
}
