import { gql } from '@apollo/client'

export const ADD_CYBER_ASSISTANT_FEEDBACK = gql`
  mutation AddCyberAssistantFeedback(
    $selectedCustomer: String
    $input: CyberAssistantFeedbackInput
  ) {
    addCyberAssistantFeedback(
      selectedCustomer: $selectedCustomer
      input: $input
    )
  }
`
