import { useState } from 'react'
import { ApolloError } from '@apollo/client'

import { TicketCommentActivity } from '../../components/Tickets/TicketEdit/Types'

interface ReturnType {
  pendingActivityItems: TicketCommentActivity[]
  setItemCompleted(itemId: string): void
  setItemError(itemId: string, error: ApolloError): void
  addItem(item: TicketCommentActivity): void
}

export enum PendingActivitySortOrder {
  DESCENDING = 'descending',
  ASCENDING = 'ascending',
}

/**
 * @category Hooks
 * @description This hook handles the pending activity state and updating the loading/error state of the pending activity
 */

const usePendingActivitySubmission = (
  pendingCommentOrder: PendingActivitySortOrder,
): ReturnType => {
  const [pendingActivityItems, setPendingActivityItems] = useState<
    TicketCommentActivity[]
  >([])

  const handleSort = (
    aActivityItem: TicketCommentActivity,
    bActivityItem: TicketCommentActivity,
  ): number => {
    if (pendingCommentOrder === PendingActivitySortOrder.ASCENDING) {
      return (
        new Date(aActivityItem.createdDate).getTime() -
        new Date(bActivityItem.createdDate).getTime()
      )
    }

    return (
      new Date(bActivityItem.createdDate).getTime() -
      new Date(aActivityItem.createdDate).getTime()
    )
  }

  const setItemCompleted = (itemId: string) => {
    setPendingActivityItems((prevItems) => {
      return prevItems.filter((item) => item.id !== itemId).sort(handleSort)
    })
  }
  const setItemError = (itemId: string, error: ApolloError) => {
    setPendingActivityItems((prevItems) => {
      return prevItems
        .map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              status: 'error' as const,
              error,
            }
          }

          return item
        })
        .sort(handleSort)
    })
  }
  const addItem = (item: TicketCommentActivity) => {
    setPendingActivityItems((prevItems) =>
      [item, ...prevItems].sort(handleSort),
    )
  }

  return {
    pendingActivityItems,
    setItemCompleted,
    setItemError,
    addItem,
  }
}

export default usePendingActivitySubmission
