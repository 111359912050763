import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

import {
  SortDirection,
  ThreatIntelReportSortOptions,
} from '@queries/threatIntel'
import { ThreatIntelReportsInput } from '@components/ThreatIntel/ThreatIntelReports/types'
import {
  DEFAULT_THREAT_INTEL_REPORTS_INPUT,
  getThreatIntelReportLabels,
} from '@components/ThreatIntel/ThreatIntel.utils'
import { ThreatIntelReportLabel } from '@models/ThreatIntel'

type ThreatIntelFilters = {
  filters: {
    detection?: Array<{ name: string; id: string }>
    labels: ThreatIntelReportLabel[]
  }
}

export const useThreatIntelReportsInput = (): ThreatIntelReportsInput => {
  const [searchParams] = useSearchParams()

  const keywordSearch = searchParams.get('keywordSearch')
  const sortBy = searchParams.get('sortBy')
  const sortDirection = searchParams.get('sortDirection')
  const useCaseId = searchParams.get('useCaseId')
  const detection = searchParams.get('detection')

  const labels = useMemo(
    () => getThreatIntelReportLabels(searchParams),
    [searchParams],
  )

  const filters: ThreatIntelFilters | undefined = useMemo(() => {
    const hasDetection = detection && useCaseId
    const hasLabels = labels.length > 0
    const filters = {
      ...(hasDetection
        ? { detection: [{ name: detection, id: useCaseId }] }
        : {}),
      labels,
    }
    return hasLabels || hasDetection ? { filters } : undefined
  }, [useCaseId, detection, labels])

  if (!searchParams.size) return DEFAULT_THREAT_INTEL_REPORTS_INPUT

  return {
    ...filters,
    ...(keywordSearch ? { keywordSearch } : {}),
    ...(sortBy
      ? { sortBy: sortBy as ThreatIntelReportSortOptions }
      : { sortBy: DEFAULT_THREAT_INTEL_REPORTS_INPUT.sortBy }),
    ...(sortDirection
      ? { sortDirection: sortDirection as SortDirection }
      : {
          sortDirection: DEFAULT_THREAT_INTEL_REPORTS_INPUT.sortDirection,
        }),
  }
}
