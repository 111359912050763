import { Alert, Slide, SlideProps, Snackbar } from '@mui/material'

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction={'left'} />
}

const ToastMessage = ({
  message,
  handleToastClose,

  open,
}): JSX.Element => {
  return (
    <Snackbar
      sx={{
        width: '100%',
        position: 'fixed',
        right: '16px',
        top: '100px',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: '100px',
        overflow: 'hidden',
      }}
      key={message.id}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={3000}
      onClose={() => handleToastClose(message.id)}
      TransitionComponent={SlideTransition}
    >
      <Alert
        severity={message.messageType}
        variant="filled"
        onClose={() => handleToastClose(message.id)} //INFO this gives us default close icon from MUI
        sx={(theme) => ({
          width: '25%',
          flexGrow: 1,
          position: 'fixed',
          right: '16px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: theme.vars.palette.secondary.dark,
          fontSize: theme.typography.caption.fontSize,
          borderRadius: '5px',
          wordWrap: 'break-word',
        })}
      >
        {message.text}
      </Alert>
    </Snackbar>
  )
}

export default ToastMessage
