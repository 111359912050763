import { gql } from '@apollo/client'

import { Report } from '@models/index'

export interface DownloadReportData {
  downloadReport: string
}

export interface DownloadReportVariables {
  selectedCustomer: string | null
  input: {
    reportId: number
  }
}

export interface GetReportsData {
  getReports: Report[]
}

export interface GetReportsVariables {
  selectedCustomer: string | null
}

export const DOWNLOAD_REPORT = gql`
  query DownloadReport($input: ReportInput!, $selectedCustomer: String) {
    downloadReport(input: $input, selectedCustomer: $selectedCustomer)
  }
`

export const GET_REPORTS = gql`
  query GetReports($selectedCustomer: String) {
    getReports(selectedCustomer: $selectedCustomer) {
      createdAt
      endDate
      numberDownloadAttempt
      id
      name
      startDate
      status
      userEmail
      userName
      reportData {
        title
        metric
      }
      errors {
        id
        title
        messages
      }
    }
  }
`
