import Icon from '@common/Icon'
import { colors } from '@design-system/theme'

interface ErrorLabelProps {
  iconSize?: number
  fontSize?: number
}

export const ErrorLabel: React.FC<ErrorLabelProps> = ({
  iconSize = 24,
  fontSize,
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      color: colors.util.two.light,
      fontSize,
    }}
  >
    Error
    <Icon
      variant="alertCircleOutline"
      size={iconSize}
      sx={{ color: colors.util.two.light }}
    />
  </div>
)
