import { Box } from '@mui/material'

import { Loader } from '@common/Loader'

interface LoadingProps {
  size?: number
}

const Loading: React.FC<LoadingProps> = ({ size = 100 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '25%',
      }}
    >
      <Loader strokeWidth={2} size={size}></Loader>
    </Box>
  )
}

export default Loading
