import { createContext, FunctionComponent, ReactNode, useReducer } from 'react'
import { ApolloProvider } from '@apollo/client'

import apolloClient from '@config/apolloClient'

import { reducer, initialState } from './Reducer'
import { AppContext } from './Types'
import * as package_json from '../../../package.json'

export const Context = createContext<AppContext>({
  state: initialState,
  dispatch: () => null,
  client: apolloClient,
})

type ProviderProps = {
  children: ReactNode
}

export const Provider: FunctionComponent<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Context.Provider value={{ state, dispatch, client: apolloClient }}>
      <div hidden>{package_json.version}</div>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </Context.Provider>
  )
}
