import { Pagination, PaginationInput } from '@models/index'

import { ThreatIntelReport } from './ThreatIntel'

export enum NotificationCategory {
  THREAT_INTEL_REPORT = 'THREAT_INTEL_REPORT',
}

export interface BaseNotification {
  id: string
  title: string
  publishedAt: string
  category: NotificationCategory
  isRead?: boolean
}

export type ThreatIntelNotification = BaseNotification & {
  category: NotificationCategory.THREAT_INTEL_REPORT
  source: ThreatIntelReport
}

export type Notification = ThreatIntelNotification

export type NotificationsData = {
  notifications: Notification[]
  pagination: Pagination
  unreadCount: number
}

export interface PaginateNotificationsData {
  paginateNotifications: NotificationsData
}

export interface NotificationsListInput {
  pagination: PaginationInput
}

export interface PaginateNotificationsVariables {
  input: NotificationsListInput
}

export interface PollNotificationsData {
  pollNotifications: Notification[]
}

export interface PollNotificationsVariables {
  startDate: string
}
