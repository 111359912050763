import { Typography } from '@mui/material'

import Icon from '@common/Icon'

import './ChartLegend.scss'

export interface ChartLegendItem {
  label: string
  color: string | undefined
}

interface ChartLegendProps {
  justify?: 'left' | 'right'
  legendItems: Array<ChartLegendItem>
}

export const ChartLegend: React.FC<ChartLegendProps> = ({
  justify = 'right',
  legendItems,
}) => {
  return (
    <div
      className="chart-legend"
      data-testid="chart-legend"
      style={{
        justifyContent: justify === 'left' ? 'flex-start' : 'flex-end',
      }}
    >
      {legendItems.map((item) => (
        <div
          data-testid="chart-legend-item"
          key={item.label}
          className="chart-legend-item"
        >
          <Icon size={12} variant="square" sx={{ color: item.color }} />
          <Typography
            color="textPrimary"
            variant="caption"
            sx={{ paddingLeft: 0.25 }}
          >
            {item.label}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default ChartLegend
