import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { startOfSecond } from 'date-fns'
import { captureException } from '@sentry/react'

import {
  PAGINATE_NOTIFICATIONS,
  POLL_NOTIFICATIONS,
} from '@queries/notifications'
import {
  PaginateNotificationsData,
  PaginateNotificationsVariables,
  PollNotificationsData,
  PollNotificationsVariables,
  Notification,
} from '@models/Notification'
import { Pagination } from '@models/Tickets'
import { TOGGLE_NOTIFICATION_READ } from '@mutations/notifications'
import apolloClient from '@config/apolloClient'
import { NOTIFICATIONS_DATA_FRAGMENT } from '@fragments/notifications'

import { NotificationsContextModel } from './types'
import { Context } from '../../App/Provider'

const POLL_INTERVAL = 1200000

interface NotificationsContextProviderProps {
  children: ReactNode
}

export const NotificationsContext =
  createContext<NotificationsContextModel | null>(null)

export const NotificationsContextProvider: React.FC<
  NotificationsContextProviderProps
> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
  const [pagination, setPagination] = useState<Pagination>({
    limit: 0,
    offset: 0,
    total: 0,
  })
  const [fetchingMore, setFetchingMore] = useState(false)
  const [queryExecuted, setQueryExecuted] = useState(false)
  const [pollDate, setPollDate] = useState(new Date())

  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  useEffect(() => {
    const updatePollDateInterval = setInterval(() => {
      setPollDate(new Date())
    }, POLL_INTERVAL)

    return () => {
      clearInterval(updatePollDateInterval)
    }
  }, [])

  const [toggleReadStatus] = useMutation(TOGGLE_NOTIFICATION_READ, {
    refetchQueries: [PAGINATE_NOTIFICATIONS],
  })

  const toggleNotificationReadStatus = (notificationId: string) => {
    if (!isDWEmployee) {
      toggleReadStatus({
        variables: {
          input: {
            notificationId,
          },
        },
      })

      const notification = apolloClient.cache.updateFragment(
        {
          fragment: NOTIFICATIONS_DATA_FRAGMENT,
          id: `Notification:${notificationId}`,
        },
        (data) => {
          return {
            ...data,
            isRead: !data.isRead,
          }
        },
      )

      if (Object.keys(notification).length) {
        setNotifications((prev) => {
          const notificationIndex = prev.findIndex(
            (val) => val.id === notification.id,
          )

          const updatedNotifications = [...prev]

          updatedNotifications[`${notificationIndex}`] = notification

          return updatedNotifications.sort(sortNotifications)
        })

        setUnreadNotificationsCount((prev) =>
          notification.isRead ? prev - 1 : prev + 1,
        )
      }
    }
  }

  const {
    loading: loadingNotifications,
    error: fetchNotificationsError,
    fetchMore,
  } = useQuery<PaginateNotificationsData, PaginateNotificationsVariables>(
    PAGINATE_NOTIFICATIONS,
    {
      variables: {
        input: {
          pagination: {
            limit: 25,
            offset: 0,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data) {
          setQueryExecuted(true)
          setNotifications((prev) =>
            [...prev, ...data.paginateNotifications.notifications].sort(
              sortNotifications,
            ),
          )
          setUnreadNotificationsCount(data.paginateNotifications.unreadCount)
          setPagination(data.paginateNotifications.pagination)
        }
      },
      skip: isDWEmployee || queryExecuted,
    },
  )

  useQuery<PollNotificationsData, PollNotificationsVariables>(
    POLL_NOTIFICATIONS,
    {
      variables: {
        startDate: startOfSecond(pollDate).toISOString(),
      },
      onCompleted: (data) => {
        if (data && data?.pollNotifications.length !== pagination.total) {
          const joinedNotifications = [
            ...new Set([...notifications, ...data.pollNotifications]),
          ]
          const pollUnreadCount = joinedNotifications.filter(
            (notification) => !notification.isRead,
          ).length

          setUnreadNotificationsCount(pollUnreadCount)
        }
      },
      skip: loadingNotifications || isDWEmployee,
    },
  )

  const fetchMoreNotifications = async () => {
    if (
      !loadingNotifications &&
      notifications.length > 0 &&
      pagination.total > notifications.length
    ) {
      setFetchingMore(true)
      try {
        const { data } = await fetchMore({
          variables: {
            input: {
              pagination: {
                limit: pagination.limit,
                offset: notifications.length,
              },
            },
          },
        })
        setNotifications((prev) =>
          [...prev, ...data.paginateNotifications.notifications]
            .filter((val, index, self) => {
              // Remove duplicate by ID
              return (
                self.findIndex((arrValue) => arrValue.id === val.id) === index
              )
            })
            .sort(sortNotifications),
        )
        setFetchingMore(false)
      } catch (error) {
        captureException(error)
        setFetchingMore(false)
      }
    }
  }

  const sortNotifications = (a: Notification, b: Notification): number => {
    return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        unreadNotificationsCount,
        toggleNotificationReadStatus,
        loadingNotifications,
        shouldShowBadge: loadingNotifications || unreadNotificationsCount > 0,
        fetchNotificationsError,
        fetchMoreNotifications,
        fetchingMore,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
