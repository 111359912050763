/* eslint-disable jsdoc/require-returns-type */
import { List, ListItemText, Typography } from '@mui/material'

import { formatEmptyField } from './TicketDetails.utils'

export interface BulletedListProps {
  listItems: string[]
}

export const BulletedList: React.FC<BulletedListProps> = ({ listItems }) => {
  return (
    <List sx={{ listStyleType: 'disc', gap: 0, padding: 0 }}>
      {listItems.map((item, index) => (
        <ListItemText sx={{ display: 'list-item' }} key={index}>
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="body2"
          >
            {item}
          </Typography>
        </ListItemText>
      ))}
    </List>
  )
}
// eslint-disable-next-line jsdoc/require-returns-type
/**
 * @description
 * Formats a text string into either a bulleted list, single value, or empty field.
 * @param {string | null} text Comma-separated string to process, or null
 * @returns
 * - A BulletedList component if text contains 2+ comma-separated items
 * - The plain text value if text contains a single item
 * - An empty field representation if text is null
 * @example
 * formatBulletedList("item1, item2, item3") // returns <BulletedList listItems={["item1", "item2", "item3"]} />
 * formatBulletedList("single item") // returns "single item"
 * formatBulletedList(null) // returns empty field representation
 */
export const formatBulletedList = (text: string | null) => {
  const listItems = text?.split(',').map((item) => item.trim()) ?? []
  if (listItems.length < 2) {
    return formatEmptyField(text)
  }
  return <BulletedList listItems={listItems} />
}
