import { Checkbox, FormControlLabel } from '@mui/material'

import {
  FilterOptionValue,
  FilterOptionValues,
  FilterOptions,
  FilterOptionsGenericObject,
} from '@models/index'

import './Filters.scss'

export interface FilterChipsProps<T> {
  keyValue: string
  filterGroup: string
  filterLabel: string
  filterValues: FilterOptionValue[]
  selectedFilters: FilterOptions<T>
  setSelectedFilters: (FiltersProps: FilterOptions<T>) => void
  disabled?: boolean
}
export interface ToggleSelectedFiltersProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends Omit<FilterChipsProps<any>, 'filterValues'> {
  checked: boolean
  value: string
}

const FilterChips = ({
  keyValue,
  filterGroup,
  filterLabel,
  filterValues,
  selectedFilters,
  setSelectedFilters,
  disabled = false,
}: FilterChipsProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterOptionValues | FilterOptionsGenericObject<any>
>): JSX.Element => {
  const handleToggleSelectedFilterCheckboxes = ({
    checked,
    selectedFilters,
    value,
    setSelectedFilters,
  }: ToggleSelectedFiltersProps) => {
    if (checked) {
      setSelectedFilters({
        ...selectedFilters,
        showOpenTicketOnly: false,
        filters: {
          ...selectedFilters.filters,
          [keyValue]: [...selectedFilters.filters[`${keyValue}`], value],
        },
      })
    } else {
      setSelectedFilters({
        ...selectedFilters,
        showOpenTicketOnly: false,
        filters: {
          ...selectedFilters.filters,
          [keyValue]: selectedFilters.filters[`${keyValue}`].filter(
            (filterValue: string) => filterValue !== value,
          ),
        },
      })
    }
  }

  const isFilterChecked = (value: string) => {
    if (keyValue !== '' && selectedFilters.filters[`${keyValue}`]) {
      return selectedFilters.filters[`${keyValue}`].includes(value)
    }

    return false
  }

  return (
    <>
      {filterValues.map(({ label, value }) => {
        const filterChecked = isFilterChecked(value)
        const lowerCasedValue = value.toLowerCase().replace(' ', '-')

        return (
          <FormControlLabel
            key={`${keyValue}-${lowerCasedValue}`}
            className="checkbox-label"
            label={label}
            control={
              <Checkbox
                key={label}
                aria-label={label}
                name={label}
                data-testid={`dw-checkbox-${lowerCasedValue}`}
                checked={filterChecked}
                hidden={true}
                inputProps={{ type: 'checkbox' }}
                onChange={() => {
                  handleToggleSelectedFilterCheckboxes({
                    keyValue,
                    checked: !filterChecked,
                    selectedFilters,
                    filterGroup,
                    value,
                    filterLabel,
                    setSelectedFilters,
                  })
                }}
                disabled={disabled}
              />
            }
          />
        )
      })}
    </>
  )
}

export default FilterChips
