import { useQuery } from '@apollo/client'
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { RefObject, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { Box, Button, Typography } from '@mui/material'

import { Ticket, TicketRelatedUnion, TicketSortingInput } from '@models/Tickets'
import { RECENTLY_CLOSED_TICKETS } from '@queries/ticket'
import {
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { TableHeader } from '@common/Table'
import { Paths } from '@components/App/Types'
import TicketIdCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketIdCell'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { useOutsideElementClick } from '@hooks/useOutsideElementClick'
import { useResizableColumns } from '@common/Table/ColumnResize/useResizableColumns'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import { invertColumnSortDirection } from '@utils/tableUtils'

import CustomerValidateStateCell from '../cells/CustomerValidateStateCell'
import { handleOnRowSelectionChange } from '../TicketOverview'

interface RecentlyClosedTicketsTableProps {
  selectedCustomer: string | null
  onTicketSelected?: (ticket: Ticket | undefined) => void
}

const RecentlyClosedTicketsTable: React.FC<RecentlyClosedTicketsTableProps> = ({
  selectedCustomer,
  onTicketSelected,
}) => {
  const navigate = useNavigate()
  const { search: currentCustomer } = useLocation()

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'closedAt',
      desc: true,
    },
  ])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const formattedSorting = invertColumnSortDirection<TicketRelatedUnion>(
    sorting,
    ['sysCreatedOn', 'sysUpdatedOn'],
  ).reduce<TicketSortingInput>(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur.desc ? 'DESC' : 'ASC',
    }),
    {},
  )

  const {
    data: { getTickets: { pagination, tickets } } = {
      pagniation: { limit: 5, offset: 0, total: 0 },
      getTickets: { tickets: [] },
    },
    error,
    loading,
  } = useQuery(RECENTLY_CLOSED_TICKETS, {
    variables: {
      pagination: {
        limit: 5,
        offset: 0,
      },
      selectedCustomer,
      selectedSortings: formattedSorting,
    },
  })

  const columnDef: ColumnDef<TicketRelatedUnion, any>[] = useMemo(() => {
    const columnHelper = createColumnHelper<TicketRelatedUnion>()
    return [
      {
        id: 'ticketNumber',
        accessorFn: (row) => ({
          ticketNumber: row.ticketNumber,
          shortDescription: row.shortDescription,
        }),
        cell: TicketIdCell,
        header: () => buildGenericHeader('Ticket ID'),
        minSize: 100,
      },
      columnHelper.accessor('closedAt', {
        id: 'closedAt',
        cell: (props) => (
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {format(props.getValue(), 'MMM dd h:mmaaa')}
          </Typography>
        ),
        header: () => buildGenericHeader('Closed On'),
        size: 130,
      }),
      columnHelper.accessor('customerValidateState', {
        id: 'customerValidateState',
        cell: CustomerValidateStateCell,
        header: () => buildGenericHeader('Customer Validation'),
        size: 300,
      }),
    ]
  }, [])

  const {
    columnSizing,
    tableHeaderRefs,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
    onColumnSizingChange,
    tableContainerRef,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<TicketRelatedUnion>({
    columns: columnDef,
    data: tickets,
    enableMultiRowSelection: false,
    enableSortingRemoval: false,
    manualSorting: true,
    state: {
      rowSelection,
      sorting,
      columnSizing,
    },
    getRowId: (row) => row.sysId,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: (valueFn) =>
      handleOnRowSelectionChange(
        valueFn,
        rowSelection,
        setRowSelection,
        tickets,
        onTicketSelected,
      ),
    onColumnSizingChange,
    columnResizeMode: 'onChange',
  })

  const tbodyRef = useOutsideElementClick(() =>
    setRowSelection({}),
  ) as RefObject<HTMLTableSectionElement>

  const handleViewAllTicketsClick = () => {
    if (currentCustomer) {
      navigate(
        `${Paths.TICKET_LIBRARY}${currentCustomer}&selectedFilters={${'"state":["Closed"]'}}&showOpenTicketOnly=false`,
      )
    } else {
      navigate(
        `${Paths.TICKET_LIBRARY}?selectedFilters={${'"state":["Closed"]'}}&showOpenTicketOnly=false`,
      )
    }
  }

  const renderContent = () => {
    if (error) {
      return (
        <tr tabIndex={0}>
          <StyledTableCell align="center" colSpan={columnDef.length}>
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{ paddingBottom: '1rem' }}
            >
              No data.
            </Typography>

            <Typography color="textPrimary" variant="body1">
              There are too many data points that are incomplete or missing.
              Please check again later.
            </Typography>
          </StyledTableCell>
        </tr>
      )
    }

    if (loading && !tickets.length) {
      return buildSkeletonRows(table.getAllColumns(), 5)
    }

    if (!loading && !tickets.length) {
      return (
        <tr key="empty-state" tabIndex={0}>
          <StyledTableCell align="center" colSpan={columnDef.length}>
            <Typography color="textPrimary" variant="body2">
              You have no closed tickets.
            </Typography>
          </StyledTableCell>
        </tr>
      )
    }

    return table
      .getRowModel()
      .rows.map((row) => (
        <MemoizedTanStackTableRow
          key={row.id}
          rowId={`recently-closed-tickets-table-body-row-${row.id}`}
          testId="recently-closed-tickets-table-body-row"
          isSelected={row.getIsSelected()}
          toggleSelected={row.toggleSelected}
          visibleCells={row.getVisibleCells()}
          linkTo={`/tickets/library/${row.original.sysId}/edit`}
        />
      ))
  }

  return (
    <>
      <Typography sx={{ padding: '1rem 0' }} fontWeight={600} variant="body1">
        Recently closed tickets
      </Typography>
      <CommonTableContainer
        data-testid="recently-closed-tickets-table-container"
        ref={tableContainerRef}
      >
        <CommonTable cellSpacing={0}>
          <thead data-testid="recently-closed-tickets-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<TicketRelatedUnion>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          <tbody ref={tbodyRef} data-testid="ticket-library-body">
            {renderContent()}
          </tbody>
        </CommonTable>
      </CommonTableContainer>

      {pagination && pagination.total > 5 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            onClick={handleViewAllTicketsClick}
            sx={{ marginTop: '1rem' }}
          >
            View all closed tickets
          </Button>
        </Box>
      )}
    </>
  )
}

export default RecentlyClosedTicketsTable
