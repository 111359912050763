import { Typography } from '@mui/material'

export const SectionHeadingBuilder = (
  heading: string,
  headingLevel: number,
) => {
  switch (headingLevel) {
    case 3: {
      return (
        <Typography fontWeight={600} sx={{ padding: '1rem 0' }} variant="body2">
          {heading}
        </Typography>
      )
    }
    case 4: {
      return (
        <Typography
          fontWeight={600}
          sx={{ padding: '1rem 0' }}
          variant="caption"
        >
          {heading}
        </Typography>
      )
    }
    default: {
      return (
        <Typography fontWeight={600} sx={{ padding: '1rem 0' }} variant="body1">
          {heading}
        </Typography>
      )
    }
  }
}
