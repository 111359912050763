/* eslint-disable security/detect-object-injection */
import { useQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { WidgetList } from '@common/WidgetList'
import {
  TicketModuleOffering,
  TicketStatsAggregationOptions,
} from '@models/Tickets'
import {
  TICKET_STATS_WITH_CUSTOMER_INFO,
  TicketStatItem,
  TicketStatsResponse,
  TicketStatsVariables,
} from '@queries/ticket'
import { Customer } from '@models/Customer'
import { TicketServiceOffering } from '@queries/firewall'
import { IconVariant } from '@common/Icon/Icons'
import { ListItemType } from 'src/interfaces/ListItemType'

interface ByModuleWidgetProps {
  selectedCustomer: string | null
}

interface ModuleMappingItem {
  icon: IconVariant
  name: string
  serviceOffering: TicketServiceOffering[]
}

const ByModuleWidget: React.FC<ByModuleWidgetProps> = ({
  selectedCustomer,
}) => {
  const { featureNgMdr } = useFlags()
  const MODULE_MAPPING: Record<string, ModuleMappingItem> = {
    mdrEnabled: {
      icon: 'mdrLogo',
      name: 'MDR',
      serviceOffering: [
        TicketServiceOffering.MDR_ANALYTICS,
        TicketServiceOffering.MDR_ENGINEERING,
        TicketServiceOffering.MDR_ESSENTIALS,
      ],
    },
    edrEnabled: {
      icon: 'edrLogo',
      name: TicketServiceOffering.EDR,
      serviceOffering: [TicketServiceOffering.EDR],
    },
    fwEnabled: {
      icon: 'fwLogo',
      name: TicketServiceOffering.FW,
      serviceOffering: [TicketServiceOffering.FW],
    },
    threatSignalEnabled: {
      icon: 'threatSignalLogo',
      name: TicketServiceOffering.THREAT_SIGNAL,
      serviceOffering: [TicketServiceOffering.THREAT_SIGNAL],
    },
    vmEnabled: {
      icon: 'mvmLogo',
      name: TicketServiceOffering.VM,
      serviceOffering: [TicketServiceOffering.VM],
    },
  }

  const initialState: TicketStatItem[] = [
    { key: 'MDR', count: 0 },
    { key: TicketModuleOffering.EDR, count: 0 },
    { key: TicketModuleOffering.VM, count: 0 },
    { key: TicketModuleOffering.FW, count: 0 },
    { key: TicketModuleOffering.THREAT_SIGNAL, count: 0 },
  ]

  const {
    data: { ticketStats: { data: statItems }, customerInfo } = {
      ticketStats: { data: initialState },
      customerInfo: {} as Customer,
    },
    error,
    loading,
  } = useQuery<TicketStatsResponse, TicketStatsVariables>(
    TICKET_STATS_WITH_CUSTOMER_INFO,
    {
      variables: {
        selectedCustomer,
        input: {
          aggregateBy: TicketStatsAggregationOptions.SERVICE,
        },
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 120000, // 2 minutes
    },
  )

  const statItemMap = statItems.reduce((acc, item) => {
    acc[item.key] = item.count

    return acc
  }, {})

  const getWidgetItems = (): ListItemType[] => {
    const widgetItems: ListItemType[] = []

    const alphabeticalKeys = Object.keys(customerInfo)
      .filter((val) => val !== '__typename')
      .sort((a, b) => a.localeCompare(b))

    alphabeticalKeys.forEach((key) => {
      if (customerInfo[key]) {
        const { icon, name, serviceOffering } = MODULE_MAPPING[key]

        const total = serviceOffering.reduce(
          (acc: number, item: TicketServiceOffering) => {
            return acc + (statItemMap[item] ?? 0)
          },
          0,
        )

        widgetItems.push({
          disabled: true,
          id: name,
          leftIcon: icon,
          loading,
          name,
          rightText: total,
        })
      }
    })

    return widgetItems
  }

  return (
    <WidgetList
      hasError={error !== undefined}
      title={featureNgMdr ? 'By module' : 'By service'}
      widgetItems={getWidgetItems()}
    />
  )
}

export default ByModuleWidget
