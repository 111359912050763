const MyCustomersEmptyState = ({ fill }) => (
  <svg viewBox="0 0 246 144" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M224.5 71.5L117.992 22.9375C110.279 18.4847 97.775 18.4847 90.0625 22.9375L21 51C17.1988 53.1946 18.5542 65.6237 18.5 68.5C18.4442 71.4595 20.9828 74.4293 24.8941 76.6875L127.302 135.813C135.014 140.265 147.518 140.265 155.231 135.813L219 99.0001C222.911 96.7419 226.183 93 226.183 90C226.128 87.1238 228.301 73.6947 224.5 71.5Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M220.399 68.0625L117.992 8.9375C110.279 4.4847 97.7749 4.4847 90.0624 8.9375L24.894 46.5625C17.1816 51.0153 17.1816 58.2347 24.894 62.6875L127.302 121.813C135.014 126.265 147.518 126.265 155.231 121.813L220.399 84.1875C228.112 79.7347 228.112 72.5153 220.399 68.0625Z"
      fill={fill}
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136.611 19.6875L145.921 14.3125C148.39 12.887 151.739 12.0861 155.231 12.0861C158.723 12.0861 162.072 12.887 164.541 14.3125L211.09 41.1875C213.559 42.613 214.946 44.5465 214.946 46.5625C214.946 48.5785 213.559 50.512 211.09 51.9375L201.78 57.3125"
      fill={fill}
    />
    <path
      d="M136.611 19.6875L145.921 14.3125C148.39 12.887 151.739 12.0861 155.231 12.0861C158.723 12.0861 162.072 12.887 164.541 14.3125L211.09 41.1875C213.559 42.613 214.946 44.5465 214.946 46.5625C214.946 48.5785 213.559 50.512 211.09 51.9375L201.78 57.3125"
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.78 94.9375L71.4429 19.6875"
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.231 69.0625L147.248 73.0938C146.631 73.4501 145.794 73.6504 144.921 73.6504C144.048 73.6504 143.211 73.4501 142.594 73.0938L110.009 54.2813C109.392 53.9249 109.045 53.4415 109.045 52.9375C109.045 52.4335 109.392 51.9501 110.009 51.5938L116.992 47.5625"
      fill="#27373C"
    />
    <path
      d="M154.231 69.0625L147.248 73.0938C146.631 73.4501 145.794 73.6504 144.921 73.6504C144.048 73.6504 143.211 73.4501 142.594 73.0938L110.009 54.2813C109.392 53.9249 109.045 53.4415 109.045 52.9375C109.045 52.4335 109.392 51.9501 110.009 51.5938L116.992 47.5625"
      stroke="currentColor"
      strokeWidth="2.42623"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MyCustomersEmptyState
