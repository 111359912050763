import { CellContext } from '@tanstack/react-table'
import { Typography } from '@mui/material'

import { EDRAsset } from '..'

const HostnameCell = (props: CellContext<EDRAsset, string>) => {
  return (
    <Typography
      color="textSecondary"
      noWrap={true}
      fontWeight={600}
      variant="body2"
      sx={(theme) => ({
        color: theme.vars.palette.text.primary,
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.text.secondary,
        }),
      })}
    >
      {props.getValue()}
    </Typography>
  )
}

export default HostnameCell
