import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Typography,
} from '@mui/material'
import { useCallback } from 'react'

import Icon from '@common/Icon'

export interface DialogProps
  extends Pick<MuiDialogProps, 'sx' | 'disableEscapeKeyDown' | 'slotProps'> {
  /** Text used in the header of the dialog, rendered within a `<DialogTitle />` component */
  title: string
  /** An optional react component to render the title */
  renderTitle?: (props: { title: string }) => React.ReactNode
  /** Whether the dialog is currently open; should be controlled via state in the parent component */
  isOpen: boolean
  /** A function called when a close event is triggered (by clicking the `X`, pressing `Escape`, etc.) */
  onClose: VoidFunction
  /** Rendered within a `<DialogContent />` component */
  children: React.ReactNode
  /** If `false`, the `X` button is not rendered in the upper-right corner */
  isCloseButtonVisible?: boolean
  /** If `true`, the modal will close when the backdrop is clicked */
  closeOnBackdropClick?: boolean
}

export const Dialog: React.FC<DialogProps> = ({
  sx,
  slotProps,
  title,
  renderTitle,
  isOpen,
  onClose,
  children,
  disableEscapeKeyDown,
  closeOnBackdropClick,
  isCloseButtonVisible = true,
}) => {
  const handleClose = useCallback<NonNullable<MuiDialogProps['onClose']>>(
    (_event, reason) => {
      if (!closeOnBackdropClick && reason === 'backdropClick') {
        return
      }
      onClose()
    },
    [closeOnBackdropClick, onClose],
  )

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      sx={sx}
      slotProps={slotProps}
      data-testid="modal-content"
    >
      <DialogTitle component={Box} data-testid="modal-header">
        {renderTitle ? (
          renderTitle({ title })
        ) : (
          <>
            <Typography variant="h6">{title}</Typography>
            {isCloseButtonVisible && (
              <IconButton
                onClick={onClose}
                data-testid="modal-close-icon-button"
              >
                <Icon variant="close" />
              </IconButton>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  )
}
