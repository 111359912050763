import { gql } from '@apollo/client'
import { ColumnSort } from '@tanstack/react-table'

import { PaginationInput, Pagination } from '@models/Tickets'

export interface FirewallVariables {
  selectedCustomer: string | null
  pagination?: PaginationInput
  sorting: ColumnSort
}
export interface FirewallMetrics {
  firewallMetrics: {
    totalAssets: number
    totalMonitoredAssets: number
    totalAssetRevisions: number
  }
}

export enum FIREWALL_SECURITY_CONCERN_INDEX {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}
export interface FirewallDevice {
  deviceName: string
  deviceType: string
  securityConcernIndex: FIREWALL_SECURITY_CONCERN_INDEX
  revisions: number
}

export interface FirewallDevices {
  firewallDevices: {
    devices: FirewallDevice[]
    pagination: Pagination
  }
}

export enum TicketServiceOffering {
  MDR_ANALYTICS = 'MDR Analytics',
  MDR_ENGINEERING = 'MDR Engineering',
  VM = 'VM',
  FW = 'FW',
  EDR = 'EDR',
  MDR_ESSENTIALS = 'MDR Essentials',
  THREAT_SIGNAL = 'Threat Signal',
  NG_MEDR = 'NG-MEDR',
}

export const OPEN_TICKETS_BAN = gql`
  query OpenTicketsBan(
    $selectedCustomer: String
    $input: OpenTicketsBanInput!
  ) {
    openTicketsBan(selectedCustomer: $selectedCustomer, input: $input) {
      module
      total
    }
  }
`

export const FIREWALL_METRICS = gql`
  query FirewallMetrics($selectedCustomer: String) {
    firewallMetrics(selectedCustomer: $selectedCustomer) {
      totalAssets
      totalMonitoredAssets
      totalAssetRevisions
    }
  }
`

export const FIREWALL_DEVICES = gql`
  query FirewallDevices(
    $selectedCustomer: String
    $pagination: PaginationInput
    $sorting: SortingInput
  ) {
    firewallDevices(
      selectedCustomer: $selectedCustomer
      input: { pagination: $pagination, sorting: $sorting }
    ) {
      devices {
        deviceType
        securityConcernIndex
        revisions
        deviceName
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`
