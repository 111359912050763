import { ResponsiveBar } from '@nivo/bar'
import { Box, Tooltip, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ChartLegend, ChartLegendItem } from '@common/ChartLegend'
import { colors } from '@design-system/index'
import Icon from '@common/Icon'

import { StatusDetailsCardTooltip } from './StatusDetailsCardTooltip'
import { StatusDetailsCardProps, StatusDetailData } from './StatusDetailsTypes'

export const StatusDetailsCard: React.FC<StatusDetailsCardProps> = ({
  label,
  breakdown,
  breakdownLabel,
  tooltipLabel,
  helpText,
  handleStatusClick,
}) => {
  const { featureEnvHealthForwarders } = useFlags()
  const data = [
    breakdown.reduce<StatusDetailData>((acc, breakdownItem) => {
      acc[breakdownItem.label] = breakdownItem.value
      acc[`${breakdownItem.label}Color`] = breakdownItem.color
      return acc
    }, {} as StatusDetailData),
  ]

  const total = breakdown.reduce(
    (acc, breakdownItem) => acc + breakdownItem.value,
    0,
  )

  const keys = breakdown.map((breakdownItem) => breakdownItem.label)

  const barColors = breakdown.map((breakdownItem) => breakdownItem.color)

  const addPointerCursor =
    featureEnvHealthForwarders && handleStatusClick ? 'pointer' : 'initial'

  return (
    <Box
      data-testid="status-details-card"
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.light,
        boxSizing: 'border-box',
        width: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        transition: 'border-color 0.5s ease-out',
        ':hover': {
          cursor: addPointerCursor,
          backgroundColor:
            featureEnvHealthForwarders && handleStatusClick
              ? theme.palette.secondary.lighter
              : theme.palette.secondary.light,
        },
      })}
      onClick={() => {
        if (handleStatusClick) {
          handleStatusClick({
            type: [label.split(' ')[0]],
            status: ['Active', 'At risk'],
          })
        }
      }}
    >
      <Box
        sx={{
          flex: 1,
          marginBottom: '1.5rem',
          flexDirection: 'column',
          gap: 0,
        }}
      >
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{ marginBottom: 0.25 }}
        >
          {label}
        </Typography>
        {helpText && (
          <Tooltip
            title={helpText}
            placement="top-start"
            followCursor
            sx={{ cursor: 'default' }}
            data-testid="tooltip-content"
          >
            <Box>
              <Icon
                variant={'helpCircleOutline'}
                size={24}
                sx={{
                  color: colors.util.one.light,
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                }}
              />
            </Box>
          </Tooltip>
        )}
        <Typography
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.palette.text.secondary,
            }),
          })}
        >
          {total}
        </Typography>
      </Box>
      <Box>
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{ paddingBottom: 0.5 }}
        >
          {breakdownLabel}
        </Typography>
        <ChartLegend
          justify="left"
          legendItems={breakdown as unknown as ChartLegendItem[]}
        />
        <Box
          sx={{
            height: '20px',
            width: '99%',
            cursor: addPointerCursor,
          }}
          data-testid="status-details-bar-chart"
        >
          <ResponsiveBar
            groupMode="stacked"
            layout="horizontal"
            colors={barColors}
            barAriaLabel={(data) => data.id.toString()}
            enableLabel={false}
            padding={0}
            data={data}
            keys={keys}
            tooltip={(props) => (
              <StatusDetailsCardTooltip {...props} label={tooltipLabel} />
            )}
            onClick={({ id }, event) => {
              event.stopPropagation()
              if (handleStatusClick) {
                handleStatusClick({
                  type: [label.split(' ')[0]],
                  status: [id.toString()],
                })
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
