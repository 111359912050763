import { gql } from '@apollo/client'

import { NOTIFICATIONS_DATA_FRAGMENT } from '@fragments/notifications'

export const PAGINATE_NOTIFICATIONS = gql`
  ${NOTIFICATIONS_DATA_FRAGMENT}
  query PaginateNotifications($input: NotificationsListInput!) {
    paginateNotifications(input: $input) {
      notifications {
        ...NotificationsData
      }
      pagination {
        offset
        limit
        total
      }
      unreadCount
    }
  }
`

export const POLL_NOTIFICATIONS = gql`
  ${NOTIFICATIONS_DATA_FRAGMENT}
  query PollNotifications($startDate: AWSDateTime!) {
    pollNotifications(startDate: $startDate) {
      ...NotificationsData
    }
  }
`
