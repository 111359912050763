import { Box, LinearProgress, Typography, useTheme } from '@mui/material'

import {
  DetectionCoverageOverviewData,
  SplunkUtilization as SplunkUtilizationData,
} from '@models/DetectionCoverage'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'
import { linearProgressBarColor } from '@utils/linearProgressBarColor'

type SplunkUtilizationProps = {
  detectionCoverageOverview: DetectionCoverageOverviewData
}

export const maxSplunkUsage = (splunkUsage: SplunkUtilizationData[]) => {
  return splunkUsage.length
    ? Math.max(...splunkUsage.map((usage) => usage.totalGb))
    : 0
}

const SplunkUtilization = ({
  detectionCoverageOverview,
}: SplunkUtilizationProps) => {
  const splunkUsage = Math.round(
    maxSplunkUsage(
      detectionCoverageOverview?.splunkUtilization.splunkUsage ?? [],
    ),
  )

  const overageAmnt = (
    <Box sx={{ display: 'flex', gap: '.25rem' }}>
      <Typography color="textPrimary" variant="body2">
        {`${!detectionCoverageOverview ? 0 : splunkUsage} GB`}
      </Typography>
      {splunkUsage >
        (detectionCoverageOverview?.splunkUtilization.license ?? 0) && (
        <Typography color="textPrimary" variant="body2">
          {` over by ${
            splunkUsage -
            Math.trunc(
              detectionCoverageOverview?.splunkUtilization.license ?? 0,
            )
          } GB`}
        </Typography>
      )}
    </Box>
  )

  const items: SidesheetDualColumnValues[] = [
    {
      key: 'License',
      label: 'License',
      value: `${detectionCoverageOverview?.splunkUtilization.license} GB`,
    },
    {
      key: 'Peak usage',
      label: 'Peak usage',
      value: overageAmnt,
    },
    {
      key: 'Overages',
      label: '# of overages',
      value: `${
        detectionCoverageOverview?.splunkUtilization.splunkUsage.reduce(
          (acc, usage) =>
            usage.totalGb > usage.totalLicenseGb ? acc + 1 : acc,
          0,
        ) ?? ''
      } days`,
    },
    {
      key: 'Largest source type',
      label: 'Largest source type',
      value: `${detectionCoverageOverview?.splunkUtilization.largestLogSource}`,
    },
  ]
  const theme = useTheme()
  const breakPoints = [
    {
      threshold: 100,
      color: theme.vars.palette.error.light,
    },
  ]

  return (
    <>
      <CollapsiblePanel
        title="Details"
        borderTop={false}
        accordionStyles={{ marginTop: '-1rem !important' }}
      >
        <SidesheetDualColumn items={items} />
      </CollapsiblePanel>
      <CollapsiblePanel title="Monthly usage breakdown">
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            flexDirection: 'column',
            gap: 14,
          }}
          data-testid="progress-bar-container"
        >
          {detectionCoverageOverview?.splunkUtilization.splunkUsage
            .map((utilization, index) => {
              const progress =
                (utilization.totalGb / utilization.totalLicenseGb) * 100
              const date = new Date(`${utilization.metricDate}T00:00:00`)
              const color = progress >= 100 ? 'error' : 'textPrimary'

              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    height: 'min-content',
                    minWidth: 'min-content',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      color={linearProgressBarColor(
                        breakPoints,
                        progress,
                        theme,
                      )}
                      sx={(theme) => ({
                        background: `linear-gradient(to right, ${linearProgressBarColor(breakPoints, progress, theme)} ${progress}% ,${theme.vars.palette.secondary.light} ${
                          progress - 1
                        }%)`,
                      })}
                    />
                  </Box>
                  <Typography variant="caption" color={color}>
                    {`${date.getUTCMonth() + 1}/${date.getUTCDate()}`}
                  </Typography>
                </Box>
              )
            })
            .reverse()}
        </Box>
      </CollapsiblePanel>
    </>
  )
}

export default SplunkUtilization
