/* eslint-disable jsdoc/check-tag-names */
export enum MttxMetric {
  MTTN = 'MTTN',
  MTTCAck = 'MTTCAck',
}

export enum MttxDimension {
  CREATED_DATE = 'CREATED_DATE',
  TICKET_PRIORITY = 'TICKET_PRIORITY',
  TICKET_TYPE = 'TICKET_TYPE',
}

export enum MttxSegment {
  TICKET_PRIORITY = 'TICKET_PRIORITY',
  TICKET_TYPE = 'TICKET_TYPE',
}

export type FilterOption = {
  key: string
  available: boolean
}

export type MttxDetailsPreviousPeriod = {
  endDate: string
  startDate: string
}

export type MTTXDelta = {
  changeAmount: number
  percentage: number
}

/**
 * @category Models
 * @description MTTX details Model
 */
export interface MTTXDetails {
  averageTime: number
  delta: MTTXDelta
  endDate: string
  maxTime: number
  minTime: number
  previousPeriod: MttxDetailsPreviousPeriod
  startDate: string
  totalTickets: number
}

export type MttxFilterOptions = {
  dimensions: MttxDimension[]
  segments: MttxSegment[]
  ticketPriorities: FilterOption[]
  ticketTypes: FilterOption[]
}

export type MttxDataPoint = {
  averageDuration: number
  date: string
  duration: number
  priority: string
  ticketId: string
  totalTickets: number
  ticketType: string
  sysId: string
}

export type MttxBarChartDataPoint = Pick<
  MttxDataPoint,
  'averageDuration' | 'totalTickets' | 'priority' | 'ticketType'
>

export type MttxScatterChartDataPoint = Pick<
  MttxDataPoint,
  'duration' | 'ticketId' | 'priority' | 'date'
>

export type MttxLineChartDataPoint = Pick<
  MttxDataPoint,
  'averageDuration' | 'totalTickets' | 'priority' | 'date'
>

export interface MttxDataVisualization {
  data: MttxDataPoint[]
  dimension: MttxDimension
  segment: MttxSegment
}
