import { Card } from '@mui/material'

import { Loader } from '@common/Loader'

export const LoadingCard: React.FC = () => (
  <Card
    sx={(theme) => ({
      alignItems: 'center',
      backgroundColor: theme.vars.palette.secondary.light,
      border: `1px solid ${theme.vars.palette.secondary.main}`,
      borderRadius: '5px',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
      ...theme.applyStyles('dark', {
        backgroundColor: theme.vars.palette.secondary.main,
        border: `1px solid ${theme.vars.palette.secondary.lighter}`,
      }),
    })}
  >
    <Loader strokeWidth={2} size={50} />
  </Card>
)
