import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { forwardRef, useCallback, useMemo } from 'react'
import {
  Cell,
  ColumnDef,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  functionalUpdate,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ApolloError } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { ThreatIntelReport, ThreatIntelReportLabel } from '@models/ThreatIntel'
import { isReportLabelSelected } from '@components/ThreatIntel/ThreatIntel.utils'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { TableHeader, PaginationLoadingRow } from '@common/Table'
import { ComponentError } from '@common/ComponentError'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import CommonTableRow from '@common/Table/components/CommonTableRow'

import { SortingStateToThreatIntelSortOption } from '../ThreatIntelReportsList/ThreatIntelReportsList'
import { ThreatIntelTag } from '../ThreatIntelTag'

export interface ThreatIntelReportsGlobalFilters {
  keywordSearch: string
  labels: ThreatIntelReportLabel[]
}

interface ThreatIntelReportsTableProps {
  data: ThreatIntelReport[]
  isLoading: boolean
  dataTotal: number
  pageSize: number
  sorting: SortingState
  selectedLabels: ThreatIntelReportLabel[]
  onBottomReached: () => void
  onScrollToTop: () => void
  error: ApolloError | undefined
  onRowSelectionChange: React.Dispatch<React.SetStateAction<RowSelectionState>>
  rowSelection: RowSelectionState
}

export const THREAT_INTEL_REPORTS_TABLE_ROW_ID_PREFIX =
  'threat-intel-report-row-'

const ThreatIntelReportsTable = forwardRef<
  HTMLDivElement,
  ThreatIntelReportsTableProps
>(
  (
    {
      data,
      isLoading,
      dataTotal,
      pageSize,
      selectedLabels,
      sorting,
      onBottomReached,
      onScrollToTop,
      onRowSelectionChange,
      rowSelection,
      error,
    },
    tableRef,
  ) => {
    const { featureThreatReportInNewTab } = useFlags()
    const [, setSearchParams] = useSearchParams()

    const onLabelClick = useCallback(
      (label: ThreatIntelReportLabel) => {
        const labelType = `${label.type}_label`

        setSearchParams((prevParams) => {
          if (!prevParams.has(labelType, label.name)) {
            prevParams.append(labelType, label.name)
          }

          return prevParams
        })
      },
      [setSearchParams],
    )

    const columnDef: ColumnDef<ThreatIntelReport, any>[] = useMemo(() => {
      const columnHelper = createColumnHelper<ThreatIntelReport>()
      return [
        columnHelper.accessor('title', {
          id: 'title',
          cell: (props) => (
            <Typography
              fontWeight={600}
              variant="body2"
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {props.getValue()}
            </Typography>
          ),
          header: () => buildGenericHeader('Title'),
        }),
        columnHelper.accessor('type', {
          id: 'type',
          cell: (props) => buildGenericCell(props),
          header: () => buildGenericHeader('Type'),
          size: 150,
        }),
        columnHelper.accessor('labels', {
          id: 'labels',
          cell: (props) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {props.getValue().map((label) => (
                <ThreatIntelTag
                  key={`${label.type}-${label.name}`}
                  text={label.name.toUpperCase()}
                  onClickCallback={(e) => {
                    e.stopPropagation() // Prevent event from bubbling up to the row and triggering the sidesheet
                    e.preventDefault() // Prevent the link from navigating the user
                    onLabelClick(label)
                  }}
                  highlighted={isReportLabelSelected(selectedLabels, label)}
                />
              ))}
            </Box>
          ),
          enableSorting: false,
          header: () => buildGenericHeader('Labels'),
        }),
        columnHelper.accessor('createdDate', {
          id: 'createdDate',
          cell: (props) => (
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {format(new Date(props.getValue()), 'MMM d, yyyy')}
            </Typography>
          ),
          header: () => buildGenericHeader('Date'),
          size: 120,
        }),
      ]
    }, [onLabelClick, selectedLabels])

    const handleOnScroll = useCallback(
      (containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement

          if (scrollHeight - scrollTop - clientHeight < 300) {
            onBottomReached()
          }
        }
      },
      [onBottomReached],
    )

    const {
      columnSizing,
      onColumnSizingChange,
      tableHeaderRefs,
      tableContainerRef,
      handleMouseUp,
      handleMouseDown,
      resetColumnSize,
    } = useResizableColumns({
      columnDef,
    })

    const table = useReactTable<ThreatIntelReport>({
      columns: columnDef,
      data: data,
      enableMultiRowSelection: false,
      enableSortingRemoval: false,
      manualFiltering: true,
      manualSorting: true,
      state: {
        sorting,
        rowSelection,
        columnSizing,
      },
      onColumnSizingChange,
      columnResizeMode: 'onChange',
      onRowSelectionChange,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id,
      onSortingChange: (updater) => {
        const newValue = functionalUpdate(updater, sorting)
        setSearchParams((prevParams) => {
          prevParams.set(
            'sortBy',
            SortingStateToThreatIntelSortOption(
              newValue.at(0)?.id ?? 'createdDate',
            ),
          )
          prevParams.set('sortDirection', newValue.at(0)?.desc ? 'DESC' : 'ASC')
          return prevParams
        })
      },
    })

    const skeletonResults = useMemo(
      () => buildSkeletonRows(table.getAllColumns()),
      [table],
    )

    const getTableCellMaxWidth = useCallback(
      (cell: Cell<ThreatIntelReport, unknown>, index: number) => {
        return getColumnWidth(index)
      },
      [],
    )

    const render = () => {
      if (error) {
        return (
          <tbody>
            <CommonTableRow isSelected={false}>
              <StyledTableCell
                align="center"
                colSpan={4}
                sx={{ border: 'none', padding: '50px' }}
              >
                <ComponentError
                  errorSubText="Check your connection and reload the page. If the problem persists, contact our support team for assistance."
                  includeReloadButton={true}
                  errorIcon={fetchErrorIcon}
                />
              </StyledTableCell>
            </CommonTableRow>
          </tbody>
        )
      } else {
        return (
          <tbody
            className="threat-intel-report-list-body"
            data-testid="threat-intel-report-list-body"
          >
            {isLoading
              ? skeletonResults
              : table.getRowModel().rows.map((row) => {
                  const linkTo = featureThreatReportInNewTab
                    ? `/threat-intel/${row.id}`
                    : undefined
                  return (
                    <MemoizedTanStackTableRow
                      key={row.id}
                      rowId={`${THREAT_INTEL_REPORTS_TABLE_ROW_ID_PREFIX}${row.id}`}
                      testId="threat-intel-report-list-table-row"
                      isSelected={row.getIsSelected()}
                      toggleSelected={row.toggleSelected}
                      visibleCells={row.getVisibleCells()}
                      getTableCellMaxWidth={getTableCellMaxWidth}
                      linkTo={linkTo}
                    />
                  )
                })}

            <PaginationLoadingRow
              currentLength={data.length}
              pageSize={pageSize}
              total={dataTotal}
              onScrollToTop={onScrollToTop}
            />
          </tbody>
        )
      }
    }
    return (
      <CommonTableContainer
        data-testid="threat-intel-report-list-table-container"
        onScroll={(e) => handleOnScroll(e.target as HTMLDivElement)}
        ref={mergeRefs(tableRef, tableContainerRef)}
        sx={{ overflowX: 'hidden' }}
      >
        <CommonTable>
          <thead data-testid="threat-intel-report-list-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<ThreatIntelReport>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  // eslint-disable-next-line security/detect-object-injection
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>
          {render()}
        </CommonTable>
      </CommonTableContainer>
    )
  },
)

const getColumnWidth = (index: number) => {
  if (index === 0) {
    return '36%'
  }
  if (index === 1) {
    return '18%'
  }
  if (index === 2) {
    return '31%'
  }
  if (index === 3) {
    return '15%'
  }
  return undefined
}

ThreatIntelReportsTable.displayName = 'ThreatIntelReportsTable'

export default ThreatIntelReportsTable
