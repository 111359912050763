/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @getify/proper-ternary/nested */
import TreeView, { flattenTree, INode, NodeId } from 'react-accessible-treeview'
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Box, css, Typography, useColorScheme } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useCustomer } from '@hooks/useCustomer'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'
import { Loader } from '@common/Loader'

import { buildNavTree, handleSearchParamsNav } from './NavSidebar.utils'

const NavSidebarStyles = (theme) => ({
  backgroundColor: theme.vars.palette.primary.dark,
  borderRight: `1px solid ${theme.vars.palette.secondary.lighter}`,
  minWidth: 260,
  position: 'static',
  left: 0,
  top: 0,
  bottom: 0,
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.dark,
  }),
})

type NavSidebarItemMetadata = {
  icon: IconVariant
  path: string | null
  rightIcon?: IconVariant
  hasDivider?: boolean
}

export const SERVICE_NOW_CUSTOMER_SERVICE_URL =
  'https://deepwatch.service-now.com/csm?id=sc_category&sys_id=f60aecac1b122410b05ffd9f034bcbbf'

const NavSidebar = () => {
  const [expandedIds, setExpandedIds] = useState<NodeId[]>(['mdr'])
  const [searchParams] = useSearchParams()
  const {
    featureMyModules,
    featureEnvHealth,
    featureNgMdr,
    featureNgMedrV1,
    featureMfw,
  } = useFlags()
  const { mode, systemMode } = useColorScheme()
  const currentThemeMode = systemMode ?? mode

  const location = useLocation()
  const navigate = useNavigate()

  const {
    customer: {
      ngMdrEnabled,
      ngMedrEnabled,
      ngMfwEnabled,
      isLimitedMdrExperience,
      edrEnabled,
    },
    isLoading,
    isAdmin,
    isDWEmployee,
  } = useCustomer()

  useEffect(() => {
    const nodeMatch = flattenTree(navItems).find(
      (node) => node.metadata?.path === location.pathname,
    )

    if (nodeMatch) {
      const id = nodeMatch.parent !== 'root' ? nodeMatch.parent : nodeMatch.id

      if (!expandedIds.includes(id!)) {
        setExpandedIds([...expandedIds, id!])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const enabledStatuses = useMemo(
    () => ({
      ngMdrEnabled: ngMdrEnabled && featureNgMdr,
      ngMedrEnabled: ngMedrEnabled && featureNgMedrV1,
      ngMfwEnabled: ngMfwEnabled && featureMfw,
      edrEnabled: edrEnabled && featureNgMedrV1,
      isLimitedMdrExperience: isLimitedMdrExperience && featureNgMdr, // re-using same feature flag as ngMdr
    }),
    [
      featureMfw,
      featureNgMdr,
      featureNgMedrV1,
      ngMdrEnabled,
      ngMedrEnabled,
      ngMfwEnabled,
      edrEnabled,
      isLimitedMdrExperience,
    ],
  )

  const navItems = useMemo(
    () =>
      buildNavTree(
        enabledStatuses,
        isDWEmployee,
        isAdmin,
        featureMyModules,
        featureEnvHealth,
      ),
    [
      enabledStatuses,
      isDWEmployee,
      isAdmin,
      featureMyModules,
      featureEnvHealth,
    ],
  )

  const getNavLink = (isBranch, level) => {
    if (level > 1) {
      return '0.5rem'
    } else if (!isBranch) {
      return '28px'
    }

    return '0'
  }

  const handleCreateTicketNav = () => {
    window.open(`${SERVICE_NOW_CUSTOMER_SERVICE_URL}`, '_blank')
  }

  return (
    <Box data-testid="nav-sidebar" sx={NavSidebarStyles}>
      {isLoading ? (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            paddingTop: currentThemeMode === 'dark' ? '1rem' : undefined,
            overflow: 'overlay',
            height: '100%',
          }}
        >
          <TreeView
            css={css({
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              ul: {
                listStyleType: 'none',
              },
            })}
            data-testid="nav-tree"
            data={flattenTree(navItems)}
            expandedIds={expandedIds}
            onExpand={({ element, isExpanded }) => {
              if (!isExpanded) {
                const vals = expandedIds.filter((id) => id !== element.id)
                setExpandedIds(vals)
              } else if (!expandedIds.includes(element.id)) {
                setExpandedIds([...expandedIds, element.id])
              }
            }}
            nodeRenderer={({
              element,
              getNodeProps,
              handleExpand,
              isBranch,
              isExpanded,
              level,
            }) => {
              const { metadata, name, id } =
                element as INode<NavSidebarItemMetadata>

              const handleKeyDown = (
                event: React.KeyboardEvent,
                path: string,
              ) => {
                if (event.key !== 'Enter') {
                  return
                }

                const navigateToPath = () =>
                  id === 'create-ticket'
                    ? handleCreateTicketNav()
                    : navigate(`${path}?${searchParams}`)

                if (isBranch) {
                  !isExpanded && navigateToPath()
                  handleExpand(event)
                } else {
                  navigateToPath()
                }
              }

              return (
                <>
                  {metadata?.hasDivider && (
                    <Box
                      component="hr"
                      sx={(theme) => ({
                        border: 'none',
                        background: theme.vars.palette.secondary.lighter,
                        margin: '1rem 0',
                        height: '1px',
                      })}
                    />
                  )}

                  <Box
                    onKeyDown={(e) =>
                      metadata!.path && handleKeyDown(e, metadata!.path)
                    }
                    sx={[
                      {
                        flexDirection: 'row',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                        cursor: 'pointer',
                        width: '100%',
                      },
                      { paddingLeft: isBranch ? '0.5rem;' : undefined },
                    ]}
                  >
                    {isBranch && (
                      <Box {...getNodeProps()}>
                        <Icon
                          size={12}
                          sx={(theme) => ({
                            color: theme.vars.palette.text.secondary,
                            userSelect: 'none',
                          })}
                          variant={
                            isExpanded ? 'chevronDown' : 'chevronForward'
                          }
                        />
                      </Box>
                    )}

                    <NavLink
                      css={css([
                        {
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '0.5rem',
                          width: '100%',
                          padding: '0.5rem 0.5rem 0.5rem 0',
                          textDecoration: 'none',
                        },
                        { paddingLeft: getNavLink(isBranch, level) },
                      ])}
                      onClick={(e) => {
                        !isExpanded && handleExpand(e)
                        id === 'create-ticket' && handleCreateTicketNav()
                      }}
                      to={handleSearchParamsNav(
                        `${metadata!.path}`,
                        searchParams,
                      )}
                      caseSensitive={true}
                    >
                      {({ isActive }) => {
                        const displayActiveStyles =
                          isActive && id !== 'create-ticket'

                        return (
                          <>
                            <Icon
                              size={level > 1 ? 17 : 24}
                              sx={(theme) => ({
                                color: theme.vars.palette.text.secondary,
                              })}
                              variant={metadata!.icon}
                            />
                            <Typography
                              fontWeight={displayActiveStyles ? 600 : 400}
                              sx={(theme) => ({
                                color: theme.vars.palette.text.secondary,
                              })}
                              variant={'caption'}
                            >
                              {name.toUpperCase()}
                            </Typography>
                            {metadata?.rightIcon && (
                              <Icon
                                sx={(theme) => ({
                                  color: theme.vars.palette.text.secondary,
                                })}
                                size={level > 1 ? 17 : 24}
                                variant={metadata!.rightIcon}
                              />
                            )}
                          </>
                        )
                      }}
                    </NavLink>
                  </Box>
                </>
              )
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default NavSidebar
