/* eslint-disable security/detect-object-injection */
import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import { Box, IconButton, Typography } from '@mui/material'

import { SegmentedControl } from '@common/SegmentedControl'
import { TicketPriorityEnum, TicketTypeEnum } from '@models/Tickets'
import { FilterOption, MttxMetric } from '@models/MttxDetails'
import { InlineMessage, InlineMessageType } from '@common/InlineMessage'
import Icon from '@common/Icon'

export interface MttxFilterProps {
  title: string
  description?: string
  error?: ApolloError
  loading: boolean
  selectedValue?: string
  selectedTicketTypeValue?: string
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>
  setSelectedTicketTypeValue?: React.Dispatch<React.SetStateAction<string>>
  ticketPriorities?: FilterOption[]
  ticketType?: FilterOption[]
  metric: MttxMetric
}

const MttxFilter = ({
  title,
  description,
  error,
  loading,
  selectedValue,
  selectedTicketTypeValue,
  setSelectedValue,
  setSelectedTicketTypeValue,
  ticketPriorities,
  ticketType,
  metric,
}: MttxFilterProps) => {
  const [open, setOpen] = useState(false)

  const ticketPriorityMapping = ticketPriorities?.reduce(
    (dict, x) => ({
      ...dict,
      [x.key]: !x.available,
    }),
    {},
  )

  const ticketTypeMapping = ticketType?.reduce(
    (dict, x) => ({
      ...dict,
      [x.key]: !x.available,
    }),
    {},
  )

  const interactionDisabled =
    loading ||
    error ||
    (!loading &&
      !ticketType?.some((tickType) => tickType.available) &&
      !ticketPriorities?.some((priority) => priority.available))

  return (
    <Box
      data-testid="mttx-filter-section"
      sx={(theme) => ({
        borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
      })}
    >
      <Box
        data-testid="mttx-filter-header"
        role="button"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
        }}
        tabIndex={0}
        onClick={!interactionDisabled ? () => setOpen(!open) : undefined}
        onKeyDown={
          !interactionDisabled
            ? (e) => {
                e.stopPropagation()

                if (e.key === 'Enter') {
                  setOpen(!open)
                }
              }
            : undefined
        }
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
            variant="subtitle2"
          >
            {title}
          </Typography>
          {description && (
            <Typography variant="body2">{description}</Typography>
          )}
        </Box>

        <IconButton size="small" sx={{ height: '100%' }}>
          <Icon
            variant={'filter'}
            sx={(theme) => ({
              color: interactionDisabled
                ? theme.vars.palette.text.disabled
                : theme.vars.palette.text.primary,
            })}
          />
        </IconButton>
      </Box>

      <Box>
        {!loading &&
          !ticketType?.some((tickType) => tickType.available) &&
          !ticketPriorities?.some((priority) => priority.available) && (
            <InlineMessage
              message={`There are no tickets in the given timeframe that have reported a ${metric} time.`}
              title="Not available"
              variant={InlineMessageType.INFORMATIONAL}
            />
          )}
        {!loading && error && (
          <InlineMessage
            message="Something went wrong. Please reload the page and try again."
            title="Error fetching data"
            variant={InlineMessageType.ERROR}
          />
        )}
      </Box>

      {open && (
        <Box
          data-testid="mttx-filter-children-container"
          sx={{ padding: '0 1rem' }}
        >
          <SegmentedControl
            loading={loading}
            options={
              ticketPriorityMapping
                ? [
                    { title: 'All', value: 'All' },
                    {
                      title: 'Critical',
                      value: TicketPriorityEnum.Critical,
                      disabled:
                        ticketPriorityMapping[TicketPriorityEnum.Critical],
                    },
                    {
                      title: 'High',
                      value: TicketPriorityEnum.High,
                      disabled: ticketPriorityMapping[TicketPriorityEnum.High],
                    },
                    {
                      title: 'Moderate',
                      value: TicketPriorityEnum.Moderate,
                      disabled:
                        ticketPriorityMapping[TicketPriorityEnum.Moderate],
                    },
                    {
                      title: 'Low',
                      value: TicketPriorityEnum.Low,
                      disabled: ticketPriorityMapping[TicketPriorityEnum.Low],
                    },
                    {
                      title: 'Info',
                      value: TicketPriorityEnum.Informational,
                      disabled:
                        ticketPriorityMapping[TicketPriorityEnum.Informational],
                    },
                  ]
                : []
            }
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            name={'Priority'}
          />
          {selectedTicketTypeValue && setSelectedTicketTypeValue && (
            <SegmentedControl
              loading={loading}
              options={
                ticketTypeMapping
                  ? [
                      { title: 'All', value: 'All' },
                      {
                        title: 'Security',
                        value: TicketTypeEnum.Security,
                        disabled: ticketTypeMapping[TicketTypeEnum.Security],
                      },
                      {
                        title: 'Engineering',
                        value: TicketTypeEnum.Engineering,
                        disabled: ticketTypeMapping[TicketTypeEnum.Engineering],
                      },
                    ]
                  : []
              }
              selectedValue={selectedTicketTypeValue}
              setSelectedValue={setSelectedTicketTypeValue}
              name={'Ticket type'}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default MttxFilter
