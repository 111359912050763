import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material'

import Icon from '@common/Icon'

interface QuestionSubmissonProps {
  goBack: () => void
}

const typographyContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.vars.palette.text.primary,
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.common.white,
  }),
})

const QuestionSubmisson = ({ goBack }: QuestionSubmissonProps) => {
  return (
    <Box sx={{ padding: '16px' }} data-testid="cyber-question-submission">
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <>
          <IconButton
            onClick={goBack}
            sx={{ padding: '0px', paddingTop: '2px' }}
          >
            <Icon variant="arrowBack" size={20} />
          </IconButton>
        </>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              ...typographyContent(theme),
              paddingLeft: '6px',
            })}
          >
            {`Thats a great question!`}
          </Typography>
        </Box>
      </Box>
      <>
        <Typography
          variant="body1"
          sx={(theme) => ({
            ...typographyContent(theme),
            paddingTop: '28px',
          })}
        >
          {`Your question has been submitted to the team for analysis and review.`}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            ...typographyContent(theme),
            paddingTop: '28px',
          })}
        >
          {`We are constantly working to improve our Cyber Assistant feature. We appreciate your question!`}
        </Typography>
      </>
    </Box>
  )
}

export default QuestionSubmisson
