import { format as formatDate, parseISO, intervalToDuration } from 'date-fns/fp'
import isEmpty from 'lodash/isEmpty'
import { captureException } from '@sentry/react'

/**
 * Date Time Utils Functons
 * @category Utils
 */

export const getTimezoneRemovedDate = (date: string): Date => {
  const formattedDate = new Date(date)
  const dateOnly = new Date(
    formattedDate.valueOf() + formattedDate.getTimezoneOffset() * 60 * 1000,
  )

  return dateOnly
}

export const toTimeUnits = (seconds: number) => {
  if (seconds < 1) {
    return '0 seconds'
  }

  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  if (!duration || isEmpty(duration)) {
    return ''
  }

  const timeUnits = Object.entries(duration).map(([unit, value]) => ({
    unit,
    value,
  }))

  const filteredUnits = timeUnits.filter(({ value }) => value >= 1)

  if (filteredUnits.length === 1) {
    const unit = filteredUnits[0]
    const suffix =
      unit.value === 1 ? unit.unit.slice(0, unit.unit.length - 1) : unit.unit
    return `${unit.value} ${suffix}`
  }

  const largestUnit = filteredUnits[0]
  const secondLargestUnit = filteredUnits[1]

  const firstUnit = `${largestUnit.value}${largestUnit.unit[0]}`
  const secondUnit = `${secondLargestUnit.value}${secondLargestUnit.unit[0]}`

  return `${firstUnit} ${secondUnit}`
}

export interface DateTimeProps {
  date?: Date
  locale?: string
  options: Intl.DateTimeFormatOptions
}

export const FormatStringDateRange = (
  startDate: string,
  endDate: string,
  format: string,
): string => {
  const formattedStartDate = getFormattedDate(startDate, format)
  const formattedEndDate = getFormattedDate(endDate, format)
  return `${formattedStartDate} - ${formattedEndDate}`
}

export const getFormattedDate = (date: string, format: string) => {
  let result = ''
  try {
    result = formatDate(
      format,
      parseISO(date.indexOf('T') > -1 ? date.split('T')[0] : date),
    )
  } catch (error) {
    captureException(error)
    result = ''
  }
  return result
}
