import { useMutation } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { Button, Typography, Box } from '@mui/material'

import { AlertSeverity, useToast } from '@hooks/useToast'
import {
  REACTIVATE_USER,
  ReactivateUserData,
  ReactivateUserVariables,
} from '@mutations/user'
import { GET_USER_MANAGEMENT_SETTINGS } from '@queries/user'

export interface ResendEmailUserModalProps {
  userId: string
  closeModal: VoidFunction
}

const ResendEmailUserModal: React.FC<ResendEmailUserModalProps> = ({
  closeModal,
  userId,
}) => {
  const { handleShowToast } = useToast()
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const [reactivateUser] = useMutation<
    ReactivateUserData,
    ReactivateUserVariables
  >(REACTIVATE_USER, {
    refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
  })

  const toggleModal = () => {
    closeModal()
  }

  const handleResendEmailUser = async () => {
    toggleModal()

    await reactivateUser({
      variables: {
        input: {
          sendEmail: true,
          userId,
        },
      },
      onCompleted: () =>
        handleShowToast(
          AlertSeverity.Success,
          'Email has been resent successfully!',
        ),
      onError: () => {
        handleShowToast(
          AlertSeverity.Error,
          'Resending email to user has failed. Please try again in a minute.',
        )
      },
    })
  }

  // Focus the cancel button when the modal opens
  useEffect(() => {
    cancelButtonRef.current?.focus()
  }, [])

  return (
    <Box
      sx={{ padding: '1.5rem' }}
      id="resend-email-user-modal"
      data-testid="resend-email-user-modal"
    >
      <Typography
        fontWeight={600}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        color="textSecondary"
        variant="body1"
      >
        Are you sure you want to send the invite email again?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: 1,
          marginTop: '25px',
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          ref={cancelButtonRef}
          data-testid="user-status-cancel-btn"
          variant="outlined"
          onClick={() => toggleModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid="user-status-button"
          onClick={() => handleResendEmailUser()}
        >
          Send Email
        </Button>
      </Box>
    </Box>
  )
}

export default ResendEmailUserModal
