/* eslint-disable @getify/proper-ternary/nested */
import { Typography } from '@mui/material'
import React from 'react'

import { formatNumberWithCommas } from '@utils/Common'

interface LicenseTotalProps {
  current?: number
  customColor?: string
  total?: number
}

const LicenseTotal: React.FC<LicenseTotalProps> = ({
  current = 0,
  customColor,
  total = 0,
}) => {
  if (!current && !total) {
    return (
      <Typography
        fontWeight={600}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="h5"
      >
        Not applicable
      </Typography>
    )
  }

  return (
    <Typography
      fontWeight={600}
      sx={(theme) => ({
        display: 'flex',
        gap: 0.5,
        color: theme.vars.palette.text.primary,
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.text.secondary,
        }),
      })}
      variant="h5"
    >
      <Typography
        color={
          customColor
            ? customColor
            : current <= total
              ? 'success'
              : 'error.light'
        }
        fontWeight={600}
        variant="h5"
      >
        {formatNumberWithCommas(current)}
      </Typography>
      {` / ${formatNumberWithCommas(total)}`}
    </Typography>
  )
}

export default LicenseTotal
