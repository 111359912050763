import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, IconButton, Tab, Tabs, Theme } from '@mui/material'

import { SideSheet, sideSheetHeaderCss } from '@common/SideSheet'
import { Ticket, TicketingRelatedCase } from '@models/index'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'

import ActivityTab from '../ActivityTab/ActivityTab'
import NewActivityTab from '../NewActivityTab/ActivityTab'
import TicketDetails from '../TicketDetails/TicketDetails'

import type { SxStyle } from '@mui-theme/types'

const ticketLibrarySideSheetCss: Record<string, SxStyle> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    width: '100%',
  },
  header: (theme: Theme) => ({
    borderBottom: `1px solid ${theme.vars.palette.secondary.main}`,
    minHeight: '73px',
    ...theme.applyStyles('dark', {
      borderBottom: `1px solid ${theme.vars.palette.secondary.light}`,
    }),
  }),
  tabs: {
    ul: {
      overflow: 'unset !important',

      'li div:nth-child(1)': {
        margin: '0 !important',
      },
    },
  },
}

interface TicketSideSheetProps {
  isOpen: boolean
  closeSideSheet: () => void
  focusedTicket: Ticket | TicketingRelatedCase
  isLoading?: boolean
  /**
   * If an error is passed, this component with throw, triggering any surrounding error boundaries.
   * Useful for showing a fallback UI when an error occurs in a parent which only affects this component.
   */
  throwError?: Error
}

const TicketLibrarySideSheet = ({
  isOpen,
  closeSideSheet,
  focusedTicket,
  isLoading,
  throwError,
}: TicketSideSheetProps) => {
  const { featureEditTicketPage } = useFlags()
  const ActivityTabComponent = featureEditTicketPage
    ? NewActivityTab
    : ActivityTab

  const TicketSideSheetTabs = [
    {
      active: true,
      id: 0,
      text: 'Details',
      notification: false,
      headerName: 'Ticket Details',
    },
    {
      active: false,
      id: 1,
      text: 'Activity',
      notification: false,
      headerName: 'Ticket Details',
    },
  ]

  const TicketLibrarySideSheetTabsContent: JSX.Element[] = [
    <TicketDetails
      key={`details-${focusedTicket.sysId}`}
      focusedTicket={focusedTicket}
    />,
    <ActivityTabComponent
      key={`activity-${focusedTicket.sysId}`}
      focusedTicket={focusedTicket}
    />,
  ]

  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setSelectedTab(value)
  }

  if (throwError) {
    throw throwError
  }

  return (
    <SideSheet
      data-testid="tickets-library-sidesheet-test-id"
      open={isOpen}
      closeSideSheet={closeSideSheet}
      renderTitle={() => (
        <>
          <Box
            sx={[sideSheetHeaderCss.header, ticketLibrarySideSheetCss.header]}
            data-testid="tickets-library-sidesheet-header"
          >
            <Box sx={sideSheetHeaderCss.headerContent}>
              {TicketSideSheetTabs[parseInt(selectedTab.toString())].headerName}
              <IconButton
                sx={{ padding: '5px' }}
                onClick={closeSideSheet}
                data-testid="ticket-library-sidesheet-close-button"
              >
                <Icon variant="closeOutline" />
              </IconButton>
            </Box>
          </Box>
          <Box sx={ticketLibrarySideSheetCss.tabs}>
            <Tabs onChange={handleTabChange} value={selectedTab}>
              <Tab label="Details" />
              <Tab label="Activity" />
            </Tabs>
          </Box>
        </>
      )}
    >
      <Box
        sx={ticketLibrarySideSheetCss.container}
        data-testid="ticket-library-sidesheet-content"
      >
        {isLoading ? (
          <Box
            data-testid="ticket-library-sidesheet-loader"
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader strokeWidth={2} size={50} />
          </Box>
        ) : (
          TicketLibrarySideSheetTabsContent.at(selectedTab)
        )}
      </Box>
    </SideSheet>
  )
}

export default TicketLibrarySideSheet
