import React from 'react'
import { Typography, ButtonGroup, Button, Box } from '@mui/material'

import { colors } from '@design-system/theme'

type SelectData = {
  title: string
  value: string
  disabled?: boolean
}

interface SegmentedControlProps {
  loading?: boolean
  name: string
  options: SelectData[]
  selectedValue?: string
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>
}

/**
 * TODO - Update to use MUI component
 * @param param0
 * @param param0.loading
 * @param param0.name
 * @param param0.options
 * @param param0.selectedValue
 * @param param0.setSelectedValue
 * @returns
 */
const SegmentedControl = ({
  loading,
  name,
  options,
  selectedValue,
  setSelectedValue,
}: SegmentedControlProps): JSX.Element => {
  return (
    <Box>
      <Typography
        sx={(theme) => ({
          paddingBottom: '0.5rem',
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {name}
      </Typography>
      <ButtonGroup
        sx={(theme) => ({
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          width: '100%',
          backgroundColor: theme.vars.palette.secondary.light,
          padding: '0 2px',
          gap: '4px',
        })}
      >
        {options.map((option) => (
          <Button
            sx={(theme) => ({
              backgroundColor:
                option.value === selectedValue
                  ? colors.common.white
                  : theme.vars.palette.secondary.light,
              boxShadow: 'none',
              color:
                option.value === selectedValue
                  ? theme.vars.palette.secondary.dark
                  : theme.vars.palette.text.primary,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 600,
              fontSize: '13px',
              height: '28px',
              width: '100%',
              borderRadius: '4px !important',
              lineHeight: 1.2,
              transition: 'background-color 100ms, color 100ms',
              '&:hover': {
                backgroundColor:
                  option.value === selectedValue
                    ? theme.vars.palette.white
                    : colors.util.navy[300], //TODO: discuss with team how to handle this color as [400] is already light
              },
              '&:disabled': {
                color: theme.vars.palette.text.disabled,
                pointerEvents: 'none',
              },
            })}
            key={option.value}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedValue(option.value)
            }}
            onKeyDown={(e) => {
              e.stopPropagation()

              if (e.key === 'Enter') {
                setSelectedValue(option.value)
              }
            }}
            disabled={loading || option.disabled}
            variant="text"
          >
            {option.title}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}

export default SegmentedControl
