/* eslint-disable @getify/proper-ternary/nested */
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ReactNode } from 'react'

import { MttxMetric } from '@models/MttxDetails'
import { MTTN_BAN, MttnBanData } from '@queries/mttx'
import { toTimeUnits } from '@utils/DateTimeUtils'
import { DashboardBanKeys } from '@queries/threatIntel'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import { Delta } from '@common/Delta'
import { trackAnalyticEvent } from '@utils/analytics'

import MTTNContent from '../MTTXSidesheet/MTTNContent'

interface MTTNBanProps {
  endDate: string
  openSideSheet: (content: ReactNode, key: string) => void
  startDate: string
}

const MTTNBan: React.FC<MTTNBanProps> = ({
  endDate,
  openSideSheet,
  startDate,
}) => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    data: { mttnBan } = {
      mttnBan: { averageTime: null, delta: { percentage: 0 } },
    },
    error,
    loading,
  } = useQuery<MttnBanData>(MTTN_BAN, {
    variables: {
      selectedCustomer,
      input: { startDate, endDate, metric: MttxMetric.MTTN },
    },
  })

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      openSideSheet(<MTTNContent />, DashboardBanKeys.MTTN)
    }
  }

  return (
    <Ban
      data-testid="mttn-ban"
      iconVariant="eyeOutline"
      onClick={() => {
        openSideSheet(<MTTNContent />, DashboardBanKeys.MTTN)
        trackAnalyticEvent('ban_clicked', { ban: 'MTTN' })
      }}
      onKeyDown={handleKeyDown}
    >
      <BanLayoutCommon
        caption="The mean time it takes your Deepwatch experts to notify you about an
            event in your environment."
        error={error != null}
        loading={loading}
        title="MTTN"
        value={
          mttnBan.averageTime != null
            ? toTimeUnits(mttnBan.averageTime)
            : 'Not applicable'
        }
      >
        {mttnBan.averageTime != null && (
          <Delta
            delta={Math.round(mttnBan.delta.percentage * 100)}
            suffix="%"
          />
        )}
      </BanLayoutCommon>
    </Ban>
  )
}

export default MTTNBan
