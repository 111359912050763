import { useContext } from 'react'
import { Typography } from '@mui/material'

import { Context } from '@components/App/Provider'
import Icon from '@common/Icon'

import { formatCreatedDate, formatDateAndTime } from '../../TicketLibrary.utils'
import { CommentProps } from '../ActivityTab.utils'
import ActivitySvg from '../../../../../../public/activity-empty-state.svg'

import './ActivityTabMessage.scss'

interface ActivityDetailsProps {
  comment: CommentProps
}

const ActivityTabMessage = ({ comment }: ActivityDetailsProps): JSX.Element => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)
  if (comment.emptyState) {
    return (
      <div id="activity-tab-message">
        <div className="activity-tab-empty">
          <img src={ActivitySvg} alt="Empty Activity" />
          <Typography variant="h5" color="textSecondary">
            No activity yet
          </Typography>
        </div>
      </div>
    )
  }

  if (comment.createdDate || comment.openedBy) {
    return (
      <div>
        {comment.openedBy ? (
          <div id="activity-tab-message">
            <div className="chat-bubble-header">
              <div className="user-avatar-container">
                <div className="user-avatar system">
                  <Icon
                    sx={(theme) => ({ color: theme.vars.palette.common.black })}
                    variant="ticketOutline"
                  />
                </div>
              </div>
              <div className="user-content">
                <div>
                  <Typography
                    fontWeight={600}
                    variant="body2"
                    color="bodySecondary"
                  >
                    {comment?.openedBy}
                  </Typography>
                </div>
                <Typography color="textPrimary" variant="caption">
                  {comment?.createdDate &&
                    formatCreatedDate(comment?.createdDate)}
                </Typography>
              </div>
            </div>
            <div className="chat-bubble-content">
              <div className="chat-bubble-message">
                <Typography variant="body2" color="textSecondary">
                  {`${comment.ticketNumber} created`}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div id="activity-tab-message">
            <div className="chat-bubble-header">
              <div className="user-avatar-container">
                <div className="user-avatar system">
                  <Icon
                    variant="ticketOutline"
                    sx={(theme) => ({ color: theme.vars.palette.common.black })}
                  />
                </div>
              </div>
              <div className="user-content">
                <div>
                  <Typography variant="body2" color="textSecondary">
                    {`${comment.ticketNumber} created`}
                  </Typography>
                </div>
                <Typography color="textPrimary" variant="caption">
                  {comment?.createdDate &&
                    formatCreatedDate(comment?.createdDate)}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div id="activity-tab-message">
        <div className="chat-bubble-header">
          <div className="user-avatar-container">
            <div
              className={`user-avatar ${
                isDWEmployee ? 'employee' : 'customer'
              }`}
            >
              <Typography
                variant="caption"
                fontWeight={700}
                color="textSecondary"
              >
                {comment.initials}
              </Typography>
            </div>
          </div>
          <div className="user-content">
            <div>
              <Typography
                fontWeight={600}
                variant="body2"
                color="textSecondary"
              >
                {comment.fullName}
              </Typography>
            </div>
            <Typography color="textSecondary" variant="caption">
              {formatDateAndTime(comment.time)}
            </Typography>
          </div>
        </div>
        <div className="chat-bubble-content">
          <div className="vl" />
          <div className="chat-bubble-message">
            <Typography variant="body2" color="textSecondary">
              {comment.text}
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityTabMessage
