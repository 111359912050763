import React from 'react'
import { Box, Typography } from '@mui/material'

import { DashedLineRectangles } from '@common/SVGs'

export const AverageLineLegend: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      justifyContent: 'right',
      width: '100%',
    }}
  >
    <DashedLineRectangles />
    <Typography color="textPrimary" variant="caption">
      All-time average
    </Typography>
  </Box>
)
