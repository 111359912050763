import { WidgetOptions } from '@okta/okta-signin-widget'

import { baseUrl, redirectUrl } from './OktaEnvs'

const OktaWidgetConfig: WidgetOptions = {
  el: '#okta-widget',
  baseUrl: baseUrl,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: redirectUrl,
  logoText: 'Deepwatch Portal',
  features: {
    autoPush: true,
    multiOptionalFactorEnroll: true,
    idpDiscovery: true,
  },
  idpDiscovery: {
    requestContext: process.env.REDIRECT_URI,
  },
  authParams: {
    pkce: true,
    issuer: process.env.ISSUER!,
    responseType: ['id_token'],
    scopes: ['openid', 'email', 'groups', 'profile'],
  },
  useClassicEngine: true,
}

export default OktaWidgetConfig

//! IMPORTANT! DO NOT REMOVE! This is kept as a template for the okta widget in
//! case of any future changes.
/* const widget = new OktaSignIn({
  el: '#okta-widget',
  baseUrl: 'https://deepwatchdev.oktapreview.com',
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: 'http://localhost:3000/dashboard',
  logoText: 'Sonar',
  logo: sonarLogo,
  features: {
    autoPush: true,
    multiOptionalFactorEnroll: true,
    idpDiscovery: true,
  },
  idpDiscovery: {
    requestContext: 'http://localhost:3000/dashboard',
  },
  authParams: {
    pkce: true,
    issuer: 'https://deepwatchdev.oktapreview.com/',
    responseType: ['id_token'],
  },
}) */
