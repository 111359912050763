import { NavigateOptions } from 'react-router-dom'

import { addCustomerParamToPath } from '@hooks/useDWExpertEnabledUrl'

export const navigateUserType = (
  dwUser: boolean | undefined,
  pathname: string,
  navigate: (path: string, options?: NavigateOptions) => void,
  shortName?: string | false | undefined,
  options?: NavigateOptions,
) => {
  if (dwUser) {
    const newUrl = addCustomerParamToPath(pathname, shortName)
    navigate(newUrl, options)
  } else {
    navigate(pathname, options)
  }
}
