import React, {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Skeleton, Typography, Box, Theme } from '@mui/material'
import {
  arrow,
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import { Notification, NotificationCategory } from '@models/Notification'
import { toTimeUnits } from '@utils/DateTimeUtils'
import { IconVariant } from '@common/Icon/Icons'
import Icon from '@common/Icon'
import PopoverContent from '@common/PopoverContent'
import PopoverListItem from '@common/PopoverListItem'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'

const NotificationItemStyles = {
  root: (theme: Theme) => ({
    cursor: 'pointer',
    display: 'flex',
    border: `1px solid ${theme.vars.palette.secondary.light}`,
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    width: '100%',
  }),
  icon: (theme: Theme, isRead?: boolean) => ({
    backgroundColor: isRead
      ? theme.vars.palette.secondary.dark
      : theme.vars.palette.primary.main,
    color: isRead
      ? theme.vars.palette.text.primary
      : theme.vars.palette.common.white,
  }),
  darkIcon: (theme: Theme, isRead?: boolean) => ({
    backgroundColor: isRead
      ? theme.vars.palette.secondary.dark
      : theme.vars.palette.text.primary,
    color: isRead
      ? theme.vars.palette.text.primary
      : theme.vars.palette.secondary.dark,
  }),
  title: (theme: Theme, isRead?: boolean) => ({
    color: isRead
      ? theme.vars.palette.text.primary
      : theme.vars.palette.text.primary,
  }),
  darkTitle: (theme: Theme, isRead?: boolean) => ({
    color: isRead
      ? theme.vars.palette.text.primary
      : theme.vars.palette.text.secondary,
  }),
}

interface NotificationItemConfigValue {
  iconVariant: IconVariant
  categoryLabel: string
}
type NotificationItemConfig = Record<
  NotificationCategory,
  NotificationItemConfigValue
>
interface NotificationItemProps {
  notification: Notification
  closeNotificationsDrawer?: () => void
  toggleNotificationReadStatus(notificationId: string): void
  isWidthFixed?: boolean
  loadingNotifications?: boolean
}

const NOTIFICATION_ITEM_CONFIG: NotificationItemConfig = {
  [NotificationCategory.THREAT_INTEL_REPORT]: {
    categoryLabel: 'Threat intel',
    iconVariant: 'globeOutline',
  },
}

const getDateShorthand = (date: string) => {
  const dateObj = new Date(date)
  const now = new Date()

  if (isNaN(dateObj?.getTime())) {
    return 'Invalid Date'
  }

  const secondsDiff = Math.round((now.getTime() - dateObj.getTime()) / 1000)

  return toTimeUnits(secondsDiff)
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  closeNotificationsDrawer,
  loadingNotifications = false,
  toggleNotificationReadStatus,
}) => {
  const arrowRef = useRef<SVGSVGElement>(null)
  const { iconVariant, categoryLabel } =
    NOTIFICATION_ITEM_CONFIG[notification.category]
  const { isRead } = notification

  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    middleware: [offset(8), arrow({ element: arrowRef, padding: 5 })], // Padding for border-radius
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left',
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ])

  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (!isRead) {
      toggleNotificationReadStatus(notification.id)
    }
    const url = getNotificationNavigationURL(
      notification.category,
      notification.source.id,
    )
    navigate(url)
    closeNotificationsDrawer?.()
  }, [
    isRead,
    notification.category,
    notification.source.id,
    notification.id,
    navigate,
    closeNotificationsDrawer,
    toggleNotificationReadStatus,
  ])

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  const toggleReadStatus = (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event?.stopPropagation()
    toggleNotificationReadStatus(notification.id)
    setIsOpen(false)
  }

  const getNotificationNavigationURL = (
    category: NotificationCategory,
    sourceId: string,
  ) => {
    switch (category) {
      case NotificationCategory.THREAT_INTEL_REPORT:
        return `/threat-intel/${sourceId}`
      default:
        return ''
    }
  }

  const notificationActionText = isRead ? 'Mark as unread' : 'Mark as read'

  return (
    <Box
      data-testid="notification-item"
      sx={[
        NotificationItemStyles.root,
        (theme) => ({
          backgroundColor: theme.vars.palette.secondary.light,
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.main,
            border: `1px solid ${theme.vars.palette.secondary.light}`,
          }),
        }),
      ]}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      aria-label={notification.title}
    >
      <Box
        sx={(theme) => ({
          borderRadius: '5px',
          padding: '0.5rem',
          ...NotificationItemStyles.icon(theme, isRead),
          ...theme.applyStyles(
            'dark',
            NotificationItemStyles.darkIcon(theme, isRead),
          ),
          border: isRead
            ? `1px solid ${theme.vars.palette.text.primary}`
            : 'none',
        })}
      >
        <NotificationDetailLoadingWrapper
          loadingNotifications={loadingNotifications}
        >
          <Icon
            variant={iconVariant}
            size={24}
            sx={(theme) => ({
              ...NotificationItemStyles.icon(theme, isRead),
              ...theme.applyStyles(
                'dark',
                NotificationItemStyles.darkIcon(theme, isRead),
              ),
            })}
          />
        </NotificationDetailLoadingWrapper>
      </Box>

      <Box>
        <NotificationDetailLoadingWrapper
          loadingNotifications={loadingNotifications}
        >
          <Typography
            variant="body2"
            fontWeight={isRead ? 400 : 600}
            sx={(theme) => ({
              ...NotificationItemStyles.title(theme, isRead),
              ...theme.applyStyles(
                'dark',
                NotificationItemStyles.darkTitle(theme, isRead),
              ),
            })}
          >
            {notification.title}
          </Typography>
        </NotificationDetailLoadingWrapper>

        <Box>
          <NotificationDetailLoadingWrapper
            loadingNotifications={loadingNotifications}
          >
            <>
              <Typography
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                })}
                variant="caption"
              >
                {getDateShorthand(notification.publishedAt)}
              </Typography>
              <Typography variant="caption" sx={[{ padding: '0 4px' }]}>
                •
              </Typography>
              <Typography variant="caption">{categoryLabel}</Typography>
            </>
          </NotificationDetailLoadingWrapper>
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          // TODO - This is janky af - need to refactor to not use a Popover in a Popover
          // temporary to style popover for light mode
          '.list-item-toggle': {
            backgroundColor: `${theme.vars.palette.secondary.light} !important`,
            border: `1px solid ${theme.vars.palette.secondary.main} !important`,
            span: {
              color: `${theme.vars.palette.text.primary} !important`,
            },
          },
        })}
      >
        <IconButton
          ref={refs.setReference}
          {...getReferenceProps()}
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen((prev) => !prev)
          }}
        >
          <Icon
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
            variant="ellipsisHorizontal"
          />
        </IconButton>
        {isOpen && (
          <FloatingFocusManager context={context}>
            <PopoverContent
              floatingStyles={{ border: 'none', ...floatingStyles }}
              getFloatingProps={getFloatingProps}
              setFloating={refs.setFloating}
              sx={{ minWidth: '0 !important' }}
              arrowProps={{ arrowRef, context }}
            >
              <PopoverListItem
                onClick={(e) => toggleReadStatus(e)}
                onKeyDown={(e) => handleKeyboardAction(e, toggleReadStatus)}
                sx={{ padding: '1rem' }}
              >
                <Icon
                  variant="checkmarkSharp"
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                  })}
                />
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  {notificationActionText}
                </Typography>
              </PopoverListItem>
            </PopoverContent>
          </FloatingFocusManager>
        )}
      </Box>
    </Box>
  )
}

const NotificationDetailLoadingWrapper = ({
  children,
  loadingNotifications,
}: {
  children: ReactNode
  loadingNotifications: boolean
}) => {
  return loadingNotifications ? (
    <Skeleton data-testid="skeleton-block" />
  ) : (
    children
  )
}

export default NotificationItem
