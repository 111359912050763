import { gql } from '@apollo/client'

import { DashboardData } from '@models/index'

export interface GetDashboardResponse {
  getDashboard: DashboardData
}

export interface GetDashboardVariables {
  selectedCustomer: string | null
  startDate?: string
  endDate?: string
}

//TODO: After BE Deployment add crowdstrike field back to links
export const GET_DASHBOARD = gql`
  query GetDashboard(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getDashboard(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      highlightMetrics {
        delta
        key
        metric
      }
      links {
        servicenow
        splunk
        threatLabs
        crowdstrikeNgMdr
        microsoftSentinel
      }
      logSourceUsage {
        alertCount
        source
        usageGb
      }
      maturityTrend {
        customer {
          date
          score
        }
        deepwatch {
          date
          score
        }
      }
      openTicketTrend {
        date
        openCriticalCount
        openHighCount
        openLowCount
        openModerateCount
      }
      threatSummary {
        alertCount
        closedCriticalTicketCount
        closedTicketCount
        openCriticalTicketCount
        openTicketCount
        totalTicketCount
        loggedEventCount
        isLogEventCountAvailable
        truePositivePercentage
        validatedTicketCount
      }
    }
  }
`
