import { Box, Button, Theme } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import React from 'react'

const DayButtonStyle = (
  theme: Theme,
  isEndDate: boolean,
  isStartDate: boolean,
  isInbetweenDates: boolean | undefined | null,
) => ({
  color: theme.vars.palette.primary.main,
  minWidth: 0,
  width: '100%',
  ':hover': {
    backgroundColor: theme.vars.palette.primary.light,
  },
  ...(isStartDate && {
    backgroundColor: theme.vars.palette.primary.main,
    borderRadius: 0,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    color: theme.vars.palette.text.secondary,
    ':hover': {
      backgroundColor: theme.vars.palette.primary.main,
    },
  }),
  ...(isInbetweenDates && {
    color: theme.vars.palette.text.primary,
  }),
  ...(isEndDate && {
    backgroundColor: theme.vars.palette.primary.main,
    borderRadius: 0,
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    color: theme.vars.palette.text.secondary,
    ':hover': {
      backgroundColor: theme.vars.palette.primary.main,
    },
  }),
  ...theme.applyStyles('dark', {
    color: theme.vars.palette.text.secondary,
    ':hover': {
      backgroundColor: theme.vars.palette.secondary.lighter,
    },
    ...(isStartDate && {
      backgroundColor: theme.vars.palette.text.secondary,
      borderRadius: 0,
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
      color: theme.vars.palette.common.black,
      ':hover': {
        backgroundColor: theme.vars.palette.text.secondary,
      },
    }),
    ...(isInbetweenDates && {
      color: theme.vars.palette.text.primary,
    }),
    ...(isEndDate && {
      backgroundColor: theme.vars.palette.text.secondary,
      borderRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      color: theme.vars.palette.common.black,
      ':hover': {
        backgroundColor: theme.vars.palette.text.secondary,
      },
    }),
  }),
})

const DayStyle = (
  theme: Theme,
  isEndDate: boolean,
  isStartDate: boolean,
  isInbetweenDates?: boolean | undefined | null,
) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    width: '100%',
    ...(isInbetweenDates && {
      backgroundColor: theme.vars.palette.secondary.main,
      borderRadius: 0,
    }),
    ...(isStartDate && {
      backgroundColor: theme.vars.palette.secondary.main,
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      ':hover': {
        backgroundColor: theme.vars.palette.secondary.main,
      },
    }),
    ...(isEndDate && {
      backgroundColor: theme.vars.palette.secondary.main,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    }),
  }
}

interface DayProps {
  date: Date | null
  disabledDate?: (date: Date, startDate?: Date, endDate?: Date) => boolean
  handleDateSelect: (date: Date) => void
  startDate?: Date
  endDate?: Date
}

const Day: React.FC<DayProps> = ({
  date,
  disabledDate,
  handleDateSelect,
  startDate,
  endDate,
}) => {
  const formattedDate = date?.getTime()

  const isStartDate = formattedDate === startDate?.getTime()
  const isEndDate = formattedDate === endDate?.getTime()
  const isInbetweenDates =
    date &&
    startDate &&
    endDate &&
    isAfter(date, startDate) &&
    isBefore(date, endDate)

  if (!date) {
    return <Box />
  }

  return (
    <Box
      sx={(theme) => DayStyle(theme, isEndDate, isStartDate, isInbetweenDates)}
    >
      <Button
        disabled={disabledDate && disabledDate(date, startDate, endDate)}
        onClick={() => {
          handleDateSelect(date)
        }}
        sx={(theme) =>
          DayButtonStyle(theme, isEndDate, isStartDate, isInbetweenDates)
        }
      >
        {date.getTime() === Date.now() &&
        date.getTime() !== startDate?.getTime() &&
        date.getTime() !== endDate?.getTime() ? (
          <div className="today">{date.getDate()}</div>
        ) : (
          date.getDate()
        )}
      </Button>
    </Box>
  )
}

export default Day
