import ReactMarkdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import { Box, Typography } from '@mui/material'

import {
  MitreCoverageCustomer,
  MitreCoverageSubTechnique,
  MitreCoverageTechnique,
} from '@models/DetectionCoverage'
import { CollapsiblePanel } from '@common/CollapsiblePanel'
import {
  SidesheetDualColumn,
  SidesheetDualColumnValues,
} from '@common/SidesheetDualColumn'

interface SubTechniqueDetailProps {
  customerTacticTechniques: MitreCoverageCustomer[]
  subTechnique: MitreCoverageSubTechnique
  tacticName: string
  technique: MitreCoverageTechnique
}

const SubTechniqueDetail = ({
  customerTacticTechniques,
  subTechnique,
  tacticName,
  technique,
}: SubTechniqueDetailProps) => {
  const customerTechnique = customerTacticTechniques.find(
    (x) => x.mitreTechniqueId === technique.mitreTechniqueId,
  )

  const customerSubTechnique = customerTechnique?.subtechniques.find(
    (x) => x.mitreSubtechniqueId === subTechnique.mitreSubtechniqueId,
  )

  const subTechniqueDetails = {
    title: subTechnique.mitreSubtechniqueName,
    description: subTechnique.mitreSubtechniqueDescription,
    totalEligibleUseCaseCount: customerSubTechnique
      ? customerSubTechnique.totalEligibleUseCaseCount
      : 0,
    totalEnabledUseCaseCount: customerSubTechnique
      ? customerSubTechnique.totalEnabledUseCaseCount
      : 0,
    totalUseCaseCount: subTechnique.totalUseCaseCount,
    id: subTechnique.mitreSubtechniqueId,
  }

  const items: SidesheetDualColumnValues[] = [
    {
      key: 'Eligible detections',
      label: 'Enabled detections',
      value: subTechniqueDetails.totalEnabledUseCaseCount,
    },
    {
      key: 'Eligible detections',
      label: 'Eligible detections',
      value: subTechniqueDetails.totalEligibleUseCaseCount,
    },
    { key: 'MITRE tactic', label: 'MITRE tactic', value: tacticName },
    {
      key: 'Parent technique',
      label: 'Parent technique',
      value: technique.mitreTechniqueName,
    },
    { key: 'ID', label: 'ID', value: subTechniqueDetails.id },
  ]

  const totalRequiredPerms: string[] = []

  technique.permissionList.forEach((permission) => {
    if (!totalRequiredPerms.includes(permission)) {
      totalRequiredPerms.push(permission)
    }
  })

  items.push({
    key: 'Required permissions',
    label: 'Required permissions',
    value: totalRequiredPerms,
  })

  const totalPlatforms: string[] = []

  technique.platformList.forEach((platform) => {
    if (!totalPlatforms.includes(platform)) {
      totalPlatforms.push(platform)
    }
  })

  items.push({ key: 'Platform', label: 'Platform', value: totalPlatforms })

  return (
    <>
      <Box
        data-testid="mitre-title-description-header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          padding: '1rem',
        }}
      >
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              color: theme.palette.text.secondary,
            }),
          })}
          fontWeight={500}
          variant="body2"
        >
          {subTechniqueDetails.title}
        </Typography>
        <Typography
          sx={(theme) => ({
            '> p': { color: theme.palette.text.primary },
          })}
          variant="body2"
        >
          <ReactMarkdown
            rehypePlugins={[
              [
                rehypeExternalLinks,
                {
                  target: '_blank',
                  rel: ['noopener', 'noreferrer'],
                },
              ],
            ]}
          >
            {subTechniqueDetails.description}
          </ReactMarkdown>
        </Typography>
      </Box>
      <CollapsiblePanel title="Details">
        <SidesheetDualColumn items={items} />
      </CollapsiblePanel>
    </>
  )
}

export default SubTechniqueDetail
