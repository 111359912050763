import { FloatingArrow, FloatingContext } from '@floating-ui/react'
import { useColorScheme, useTheme } from '@mui/material'

export interface PopoverArrowProps {
  arrowRef: React.RefObject<SVGSVGElement>
  context: FloatingContext
}

export const PopoverArrow: React.FC<PopoverArrowProps> = ({
  arrowRef,
  context,
}) => {
  const theme = useTheme()
  const { mode, systemMode } = useColorScheme()
  const currentThemeMode = systemMode ?? mode
  const fill =
    currentThemeMode === 'dark'
      ? theme.vars.palette.secondary.dark
      : theme.vars.palette.secondary.light
  const stroke =
    currentThemeMode === 'dark'
      ? theme.vars.palette.text.secondary
      : theme.vars.palette.secondary.main
  return (
    <FloatingArrow
      ref={arrowRef}
      style={{ transform: 'translateY(-1px)' }} // Overlap the popover border
      fill={fill}
      stroke={stroke}
      strokeWidth={1}
      context={context}
    />
  )
}
