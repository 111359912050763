// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck dataTypes in query has an infite type; need to cleanup/simplify
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'

import {
  SECURITY_INDEX_CALCULATION,
  SecurityIndexCalculation,
  SecurityIndexVariables,
} from '@queries/securityIndex'

import { SiMetricsCards } from './SiMetricsCards'
import { SiMetricsChart } from './SiMetricsChart'
import { SiMetricsTable } from './SiMetricsTable'

const SecurityIndexMetrics: React.FC = () => {
  const [active, setActive] = useState<string>('')

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const setActivePart = (id: string): void => {
    setActive(id)
  }

  const {
    // @ts-expect-error dataTypes is recursively defined; need to clean this up
    data: { securityIndexCalculation: { dataTypes } } = {
      securityIndexCalculation: { dataTypes: null },
    },
    loading,
    error,
  } = useQuery<
    SecurityIndexCalculation,
    Pick<SecurityIndexVariables, 'selectedCustomer'>
  >(SECURITY_INDEX_CALCULATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer,
    },
  })

  return (
    <Box
      data-testid="security-index-metrics"
      sx={{
        width: '100%',
        display: 'flex',
        '& > div': {
          width: '100%',
        },

        '@media screen and (max-width: 768px)': {
          'p:nth-child(2)': {
            display: 'none',
          },
        },

        '@media screen and (min-width: 1700px)': {
          justifyContent: 'center',
        },
      }}
    >
      <Box>
        <Typography variant="body1" fontWeight={600}>
          Security Index Calculation
        </Typography>
        <Typography sx={{ marginTop: '.5rem' }} variant="body2">
          Your Security Index™ is broken down into three parts: Enabled source
          types, enabled detections and enterprise coverage. Each of these
          sections are weighted differently.
        </Typography>
        <Box
          data-testid="si-metrics-chart-cards-container"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '3em',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
            },
            '@media screen and (min-width: 1700px)': {
              justifyContent: 'center',
            },
          }}
        >
          <Box
            sx={{
              display: 'contents',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
              },
            }}
          >
            <SiMetricsChart active={active} />
          </Box>
          <SiMetricsCards setActivePart={setActivePart} />
        </Box>

        <SiMetricsTable dataTypes={dataTypes} loading={loading} error={error} />
      </Box>
    </Box>
  )
}
export default SecurityIndexMetrics
