import { ComputedDatum } from '@nivo/bar'
import { Theme } from '@mui/material'

import { OptionType } from '@common/Dropdown/Dropdown'
import { colors } from '@design-system/theme'
import {
  Metric,
  Dimension,
  Segment,
  EnvironmentHealthUtilAlertsNoValue,
  SourceType,
} from '@models/EnvHealth'

import { ChartData } from './SUCharts/NoValueChart'
import { PastPeriodChartData } from './SUCharts/PastPeriodBarChart'

import type { SupportedChartValue } from '@components/EnvironmentHealth/Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import type { CSSProperties } from 'react'

export const keyColors = [
  colors.util.orange[200],
  colors.util.three.light,
  colors.util.four.dark,
  colors.brand.primary.main,
  colors.util.one.lighter,
]

export const utilizationBySourcetypeAverageColors = [
  colors.brand.primary.main,
  colors.util.three.light,
  colors.util.one.lighter,
  colors.brand.primary.light,
  colors.util.four.light,
  colors.util.orange[100],
  colors.brand.secondary.light,
  colors.util.two.lighter,
  colors.util.orange[300],
  colors.brand.secondary.dark,
  colors.util.three.dark,
]

export const metricOptions: OptionType[] = [
  {
    value: Metric.UTILIZATION,
    label: 'Utilization (GB)',
    color: colors.util.one.light,
  },
  {
    value: Metric.ALERTS,
    label: 'Alerts',
    color: colors.util.one.light,
  },
]

export const dimensionOptions: OptionType[] = [
  {
    value: Dimension.DATE,
    label: 'Date',
    color: colors.util.one.light,
  },
  {
    value: Dimension.SOURCETYPE,
    label: 'Source type',
    color: colors.util.one.light,
  },
  {
    value: Dimension.INDEX,
    label: 'Index',
    color: colors.util.one.light,
  },
]

export const segmentOptions: OptionType[] = [
  {
    value: Segment.NO_VALUE,
    label: 'No value',
    color: colors.util.one.light,
  },
  {
    value: Segment.SOURCETYPE,
    label: 'Source type',
    color: colors.util.one.light,
  },
  {
    value: Segment.INDEX,
    label: 'Index',
    color: colors.util.one.light,
  },
  {
    value: Segment.DEVIATION,
    label: 'Deviation',
    color: colors.util.one.light,
  },
  {
    value: Segment.AVERAGE,
    label: 'Average',
    color: colors.util.one.light,
  },
]

export const utilizationChartTitle = (
  metric: string,
  dimension: string,
  segment: string,
): string => {
  let title = 'Source type utilization'
  if (segment === Segment.DEVIATION) {
    title = 'Source type utilization by deviation'
  }

  if (dimension === Dimension.INDEX || segment === Segment.INDEX) {
    title = 'Source type utilization by Index'
  }

  if (metric === Metric.ALERTS) {
    title = 'Total alerts'
  }

  if (metric === Metric.ALERTS && segment === Segment.SOURCETYPE) {
    title = 'Total alerts by source type'
  }
  return title
}

/**
 * @description To display the incoming data correctly for the util x date x no value chart
 * we need to organize the data by date
 * @param {EnvironmentHealthUtilAlertsNoValue | null} data The data required for the chart
 * @returns {ChartData[]} An array of chart data, chunked by date
 */
export const chunkSourceTypesByDate = (
  data: EnvironmentHealthUtilAlertsNoValue | null,
): ChartData[] => {
  const chartDataMap = new Map<string, ChartData>()
  const topSourceTypes = data?.currentPeriod.topSourceTypes ?? []

  for (const sourceType of topSourceTypes) {
    const { date, gb, alerts, avgIngestOverAllTime } = sourceType

    const chartData: ChartData = chartDataMap.get(date) ?? {
      date,
      totalGbForDay: 0,
      sourceTypes: [],
      totalAlertsForDay: 0,
    }

    chartData.totalGbForDay += gb
    chartData.totalAlertsForDay += alerts

    const ingestDeviation = {
      gb: Math.abs(gb - avgIngestOverAllTime),
      delta: ((gb - avgIngestOverAllTime) / avgIngestOverAllTime) * 100,
    }

    chartData.sourceTypes.push({
      ...sourceType,
      ingestDeviation,
      gb: Math.round(gb * 10) / 10,
    })

    chartDataMap.set(date, chartData)
  }

  // Round the totalGbForDay for each day
  for (const chartData of chartDataMap.values()) {
    chartData.totalGbForDay = Math.ceil(chartData.totalGbForDay)
  }

  return Array.from(chartDataMap.values())
}

/**
 * @description To display the incoming data correctly for the past period chart
 * we need to organize the data by date
 * @param {EnvironmentHealthUtilAlertsNoValue | null} data The data required for the chart
 * @returns {ChartData[]} An array of chart data, chunked by date
 */
export const chunkPastPeriodUtilization = (
  data: EnvironmentHealthUtilAlertsNoValue | null,
): ChartData[] => {
  const chartDataMap = new Map<string, ChartData>()
  const previousPeriodUtilization = data?.previousPeriodUtilization ?? []

  for (const { date, gb } of previousPeriodUtilization) {
    const chartData: ChartData = chartDataMap.get(date) ?? {
      date,
      totalGbForDay: 0,
      sourceTypes: [],
      totalAlertsForDay: 0,
    }

    chartData.totalGbForDay += gb

    chartDataMap.set(date, chartData)
  }

  // Round the totalGbForDay for each day
  for (const chartData of chartDataMap.values()) {
    chartData.totalGbForDay = Math.round(chartData.totalGbForDay)
  }

  return Array.from(chartDataMap.values())
}

/**
 * Return the color for the bar based on the selected bar and license capacity
 *
 * If this bar is selected, or no bar is selected:
 * - render blue if the bar is at or under license capacity
 * - render yellow if the bar is over license capacity
 *
 * If a bar is selected, all unselected bars will be gray
 * @param {string | number} indexValue The index value of the bar
 * @param {ChartData} data The data for the chart
 * @param {string} selectedBar The index value of the selected bar
 * @param {number | undefined} licenseCapacity The license capacity
 * @returns {string} The color for the bar
 */
export const barColors = (
  indexValue: string | number,
  data: ChartData,
  selectedBar: string,
  licenseCapacity: number | undefined,
): string => {
  const isThisBarSelected = selectedBar === indexValue
  const isABarSelected = selectedBar !== ''
  const isBarOverLicenseCapacity =
    licenseCapacity && data.totalGbForDay > licenseCapacity

  /**
   * If this bar is selected, or no bar is selected:
   *
   * - render blue if the bar is at or under license capacity
   * - render yellow if the bar is over license capacity
   */
  if (isThisBarSelected || !isABarSelected) {
    return isBarOverLicenseCapacity
      ? colors.util.four.light
      : colors.brand.secondary.main
  }
  // If another bar is selected, this bar should be gray
  return colors.util.navy[200]
}

/**
 * TODO: remove after the past period bar chart is released. Use hoveredGroupedBarColors instead
 * Return color for the bar based on the hovered bar state and license capacity.
 *
 * If this bar is hovered, then the other bars will have a dimmer color.
 *
 * If no bar is hovered, then their original color will remain.
 * @param {string | number} indexValue The index value of the bar
 * @param {ChartData} data The data for the chart
 * @param {string} hoveredBar The index value of the selected bar
 * @param {number | undefined} licenseCapacity The license capacity
 * @returns {string} The color for the bar
 */
export const hoveredBarColors = (
  indexValue: string | number,
  data: ChartData,
  hoveredBar: string,
  licenseCapacity: number | undefined,
): string => {
  const isBarOverLicenseCapacity =
    licenseCapacity && data.totalGbForDay > licenseCapacity

  if (hoveredBar === indexValue) {
    if (isBarOverLicenseCapacity) {
      return colors.util.four.light
    } else {
      return colors.brand.secondary.main
    }
  } else {
    if (isBarOverLicenseCapacity) {
      return colors.hovered.yellow
    } else {
      return colors.hovered.blue
    }
  }
}

/**
 * Return color for the bar based on the hovered bar state and license capacity.
 *
 * If this bar is hovered, then the other bars will have a dimmer color.
 *
 * If no bar is hovered, then their original color will remain.
 * The pastPeriod bar isn't effected by the overage
 * @param {ComputedDatum<PastPeriodChartData>} barItem all data associated with the bar
 * @param {ChartData} data The data for the chart
 * @param {string} hoveredBar The index value of the selected bar
 * @param {number | undefined} licenseCapacity The license capacity
 * @param {theme} theme MUI theme object
 * @returns {string} The color for the bar
 */
export const hoveredGroupedBarColors = (
  barItem: ComputedDatum<PastPeriodChartData>,
  data: ChartData,
  hoveredBar: string,
  licenseCapacity: number | undefined,
  theme: Theme,
): string => {
  const isBarOverLicenseCapacity =
    licenseCapacity && data.totalGbForDay > licenseCapacity

  if (hoveredBar === barItem.indexValue) {
    if (barItem.id === 'pastPeriod') {
      return theme.vars.palette.text.secondary
    }
    if (isBarOverLicenseCapacity) {
      return theme.vars.palette.severity.medium ?? ''
    } else {
      return theme.vars.palette.severity.low ?? ''
    }
  } else {
    if (barItem.id === 'pastPeriod') {
      return theme.vars.palette.card.main
    }
    if (isBarOverLicenseCapacity) {
      return theme.vars.palette.card.light
    } else {
      return theme.vars.palette.primary.lighter ?? ''
    }
  }
}

/**
 * help determine how much top buffer to add to the chart so that
 * the license capacity dashed line isn't flush against the top
 * @param largestValue
 * @returns number
 */
const topBuffer = (largestValue: number): number => {
  if (largestValue >= 1000) {
    return 500
  } else if (largestValue >= 150) {
    return 75
  } else if (largestValue >= 100) {
    return 20
  } else if (largestValue >= 50) {
    return 10
  } else {
    return 5
  }
}

/**
 * change scale of chart if largest data point's GB usage is greater than the license capacity
 * otherwise, use license capacity as max scale value and add ten for top buffer on the chart
 * @param largestDataPoint
 * @param licenseCapacity number or undefined
 * @returns number
 */
export const modifyMaxValueScale = (
  largestDataPoint: number,
  licenseCapacity: number | undefined,
): number => {
  if (licenseCapacity && licenseCapacity > largestDataPoint) {
    return licenseCapacity + topBuffer(Math.floor(licenseCapacity))
  }
  return largestDataPoint + topBuffer(Math.floor(largestDataPoint))
}

/**
 * Helper class for performing common calculations on source types
 */
export class SourceTypeCalculations {
  constructor(
    /** Source type data to perform calculations on; all data must be from the same source type */
    private sourceTypes: SourceType[],
  ) {
    if (sourceTypes.length === 0) {
      throw new Error('No source type data provided')
    }

    const uniqueSourceTypes = new Set(sourceTypes.map(({ name }) => name))

    if (uniqueSourceTypes.size > 1) {
      throw new Error(
        `Provided source type data must all be from the same source type, received ${uniqueSourceTypes.size}: ${Array.from(
          uniqueSourceTypes,
        ).join(', ')}`,
      )
    }
  }

  /**
   * The name of the source type
   * @returns {string} The source type name
   */
  get name(): string {
    return this.sourceTypes[0].name
  }

  /**
   * The source IP for the source type
   * @returns {string} The source IP
   */
  get sourceIP(): string {
    return this.sourceTypes[0].sourceIP
  }

  /**
   * Average ingest over the period; given in `GB`
   * @returns {number} The average ingest in `GB`
   */
  get averageIngestOverPeriod(): number {
    return (
      this.sourceTypes.reduce((acc, { gb }) => acc + gb, 0) /
      this.sourceTypes.length
    )
  }

  /**
   * Historical all-time average ingest for this source type; same across all dates for the source type; given in `GB`
   * @returns {number} The historical all-time average ingest in `GB`
   */
  get avgIngestOverAllTime(): number {
    return this.sourceTypes[0].avgIngestOverAllTime
  }

  /**
   * Deviation between the average ingest over the period and the average ingest over all-time; given in `GB`
   * @returns {number} The deviation in `GB`
   */
  get deltaGb(): number {
    return this.averageIngestOverPeriod - this.avgIngestOverAllTime
  }

  /**
   * Percentage deviation between the average over the period and the average over all time; given in percentage
   * @returns {number} The percentage deviation
   */
  get deltaPercentage(): number {
    return Math.round((this.deltaGb / this.avgIngestOverAllTime) * 100)
  }

  /**
   * Color to use for displaying the deviation; green for positive, red for negative
   * @returns {CSSProperties['color']} The css `color` value
   */
  get deviationColor(): CSSProperties['color'] {
    return this.deltaPercentage > 0
      ? colors.brand.primary.light
      : colors.util.two.light
  }

  /**
   * Total number of alerts over the period
   * @returns {number} The total number of alerts
   */
  get totalAlertsOverPeriod(): number {
    return this.sourceTypes.reduce((acc, { alerts }) => acc + alerts, 0)
  }
}

/**
 * Add any other non-standard display names for source types here
 */
enum SourceTypeSpecialNames {
  OTHER = 'otherSourceTypes',
}
const SourceTypeSpecialNameMap: Record<SourceTypeSpecialNames, string> = {
  [SourceTypeSpecialNames.OTHER]: 'Other',
}

/**
 * @description A mapping function to format a specially named source type to be human-readable
 * @param {SourceType} sourceType The source type to format
 * @returns {SourceType} The formatted source type
 */
export const formatSourceTypeName = (sourceType: SourceType): SourceType => ({
  ...sourceType,
  name:
    SourceTypeSpecialNameMap[sourceType.name as SourceTypeSpecialNames] ??
    sourceType.name,
})

type SourceTypeCompareFn = (a: SourceType, b: SourceType) => number

/**
 * @description Creates a comparison function to be used to sort an array of source types
 * @param {SupportedChartValue} key The key to sort by
 * @param {'asc' | 'desc'} sortOrder The order in which to sort the source types
 * @returns {SourceTypeCompareFn} A function to compare two source types
 * @example
 * ```ts
 * const sortByGbDesc = createSourceTypeCompareFunction('gb', 'desc')
 * const sortedSourceTypes = sourceTypes.sort(sortByGbDesc)
 * ```
 */
export function createSourceTypeCompareFunction(
  key: SupportedChartValue,
  sortOrder: 'asc' | 'desc',
): SourceTypeCompareFn {
  return (a, b) => {
    if (a.name === SourceTypeSpecialNames.OTHER) {
      return 1
    }
    if (b.name === SourceTypeSpecialNames.OTHER) {
      return -1
    }
    const difference =
      a[key as SupportedChartValue] - b[key as SupportedChartValue]
    return sortOrder === 'asc' ? difference : -difference
  }
}

type DateStringComparisonArg = {
  date: string
}

export const compareByDateAscending = (
  { date: dateA }: DateStringComparisonArg,
  { date: dateB }: DateStringComparisonArg,
): number => new Date(dateA).getTime() - new Date(dateB).getTime()
