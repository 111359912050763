import { useQuery } from '@apollo/client'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTheme } from '@mui/material'

import { useCustomer } from '@hooks/useCustomer'
import { SplunkDeploymentModel } from '@components/EnvironmentHealth/environmentHealthConstants'
import {
  ENVIRONMENT_HEALTH_FORWARDER_COUNTS,
  EnvironmentHealthForwarderCountsData,
  EnvironmentHealthForwarderCountsVariables,
} from '@queries/environmentHealth'
import { ErrorCard } from '@common/ErrorCard'
import { LoadingCard } from '@common/LoadingCard'
import { Paths } from '@components/App/Types'
import { navigateUserType } from '@utils/navigateUserType'

import { StatusDetailsCard } from './StatusDetailCard'

const ForwarderCounts: React.FC = () => {
  const theme = useTheme()
  const { featureEnvHealthForwarders } = useFlags()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    data: { environmentHealthForwarderCounts } = {
      environmentHealthForwarderCounts: null,
    },
    error,
    loading,
  } = useQuery<
    EnvironmentHealthForwarderCountsData,
    EnvironmentHealthForwarderCountsVariables
  >(ENVIRONMENT_HEALTH_FORWARDER_COUNTS, {
    variables: {
      selectedCustomer: searchParams.get('customer'),
    },
    fetchPolicy: 'no-cache', // This is meant to be real-time (polled at 15 minute intervals) data
    pollInterval: 15 * 60 * 1000, // 15 minutes
  })
  const StatusDetailsColor = {
    SUCCESS: theme.palette.success.main,
    ERROR: theme.palette.error.main,
    SEVERITY_HIGH: theme.palette.severity?.high,
    SEVERITY_MEDIUM: theme.palette.severity?.medium,
    SEVERITY_LOW: theme.palette.severity?.low,
  }
  const {
    customer: { splunkDeploymentModel, customerShortName },
    isDWEmployee,
  } = useCustomer()
  const onPremHelpText =
    splunkDeploymentModel === SplunkDeploymentModel.CUSTOMER_ENVIRONMENT
      ? 'Since your deployment model is on-prem, this may also include indexers and search heads in your environment.'
      : undefined

  if (loading && !environmentHealthForwarderCounts) {
    return (
      <>
        <LoadingCard />
        <LoadingCard />
      </>
    )
  } else if (error || !environmentHealthForwarderCounts) {
    return (
      <>
        <ErrorCard />
        <ErrorCard />
      </>
    )
  }

  const handleNavigateToForwardersTable = (
    type: string[],
    status: string[],
  ) => {
    const selectedFilters = JSON.stringify({ type: type, status: status })
    return navigateUserType(
      isDWEmployee,
      `${Paths.FORWARDERS}?selectedFilters=${selectedFilters}`,
      navigate,
      customerShortName,
    )
  }

  return (
    <>
      <StatusDetailsCard
        label="Universal forwarders"
        breakdownLabel="Status"
        tooltipLabel="Universal forwarder status"
        breakdown={[
          {
            label: 'Active',
            value:
              environmentHealthForwarderCounts.universalForwarders.activeCount,
            color: StatusDetailsColor.SUCCESS,
          },
          {
            label: 'At risk',
            value:
              environmentHealthForwarderCounts.universalForwarders.atRiskCount,
            color: StatusDetailsColor.ERROR,
          },
        ]}
        handleStatusClick={({ type, status }) => {
          if (featureEnvHealthForwarders) {
            handleNavigateToForwardersTable(type, status)
          }
        }}
      />
      <StatusDetailsCard
        label="Heavy forwarders"
        breakdownLabel="Status"
        tooltipLabel="Heavy forwarder status"
        breakdown={[
          {
            label: 'Active',
            value: environmentHealthForwarderCounts.heavyForwarders.activeCount,
            color: StatusDetailsColor.SUCCESS,
          },
          {
            label: 'At risk',
            value: environmentHealthForwarderCounts.heavyForwarders.atRiskCount,
            color: StatusDetailsColor.ERROR,
          },
        ]}
        helpText={onPremHelpText}
        handleStatusClick={({ type, status }) => {
          if (featureEnvHealthForwarders) {
            handleNavigateToForwardersTable(type, status)
          }
        }}
      />
    </>
  )
}

export default ForwarderCounts
