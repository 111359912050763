import { flexRender, Header, SortDirection, Table } from '@tanstack/react-table'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@mui/material'

import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import Icon from '@common/Icon'

import { colors } from '../../../design-system'
import { StyledTableHeader } from '../styled/StyledTableHeader'
import { UseResizableColumnsReturn } from '../ColumnResize/useResizableColumns/useResizableColumns'
import { ColumnResizeHandle } from '../ColumnResize/ColumnResizeHandle'

interface TableHeaderProps<T> {
  header: Header<T, unknown>
  width?: number | string
  table?: Table<T>
  thRef?: React.RefObject<HTMLTableCellElement>
  /**
   * A function to handle mouseup events on the column resizing handle, passed from the `useResizableColumns` hook
   */
  onResizeHandleMouseUp?: UseResizableColumnsReturn<T>['handleMouseUp']
  /**
   * A function to handle mousedown events on the column resizing handle, passed from the `useResizableColumns` hook
   */
  onResizeHandleMouseDown?: UseResizableColumnsReturn<T>['handleMouseDown']
  /**
   * A function to reset the column size, passed from the `useResizableColumns` hook
   */
  onResetColumnSize?: UseResizableColumnsReturn<T>['resetColumnSize']
}

const SortArrowUp: React.FC = () => (
  <Icon
    size={16}
    variant="arrowUp"
    sx={{
      color: colors.util.navy[50],
      verticalAlign: 'bottom',
    }}
  />
)

const SortArrowDown: React.FC = () => (
  <Icon
    size={16}
    variant="arrowDown"
    htmlColor={colors.util.navy[50]}
    sx={{
      verticalAlign: 'bottom',
    }}
  />
)

const TableHeader = <T,>({
  header,
  width,
  table,
  thRef,
  onResizeHandleMouseUp,
  onResizeHandleMouseDown,
  onResetColumnSize,
}: TableHeaderProps<T>): JSX.Element => {
  const { featureTableColumnResize } = useFlags()

  const sortDirection: SortDirection | false =
    (header.column.getSortIndex() === 0 && header.column.getIsSorted()) ?? false // Check the sort index to disable arrows on multi-sort until we have designs
  const columnBeingResized = table
    ?.getAllColumns()
    .find((column) => column.getIsResizing())
  const isOtherColumnBeingResized =
    columnBeingResized && columnBeingResized.id !== header.column.id

  return (
    <StyledTableHeader
      thRef={thRef}
      data-testid="header-cell-th"
      isOtherColumnBeingResized={isOtherColumnBeingResized}
      onMouseDown={header.column.getToggleSortingHandler()}
      onKeyDown={(keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
        handleKeyboardAction(keyboardEvent, header.column.toggleSorting)
      }}
      tabIndex={0}
      width={width}
    >
      <Box
        data-testid="header-cell"
        sx={(theme) => ({
          cursor: header.column.getCanSort() ? 'pointer' : 'initial',
          display: 'flex',
          gap: '0.25rem',
          '.MuiTypography-caption': {
            fontWeight: header.column.getIsSorted() ? 600 : 400,
            ...theme.applyStyles('dark', {
              color: header.column.getIsSorted()
                ? theme.vars.palette.text.secondary
                : theme.vars.palette.text.secondary,
            }),
          },
        })}
      >
        {!header.isPlaceholder &&
          flexRender(header.column.columnDef.header, header.getContext())}
        {sortDirection === 'asc' && <SortArrowUp />}
        {sortDirection === 'desc' && <SortArrowDown />}
      </Box>

      {featureTableColumnResize &&
      table &&
      thRef &&
      onResizeHandleMouseUp &&
      onResizeHandleMouseDown &&
      onResetColumnSize ? (
        <ColumnResizeHandle
          header={header}
          table={table}
          onMouseUp={onResizeHandleMouseUp}
          onMouseDown={onResizeHandleMouseDown}
          onResetColumnSize={onResetColumnSize}
        />
      ) : null}
    </StyledTableHeader>
  )
}

export default TableHeader
