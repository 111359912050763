import { format } from 'date-fns'
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import { captureException } from '@sentry/react'

import { Dimension, Metric, Segment } from '@models/EnvHealth'

import { EnvHealthState, inputs } from './Overview'

export const handleQueryCall = (
  selectedInputs: inputs,
  setState: React.Dispatch<React.SetStateAction<EnvHealthState>>,
  state: EnvHealthState,
  searchParams: URLSearchParams,
  startDate: Date,
  endDate: Date,
  DATE_FORMAT: string,
  getUtilAlertsNoValue: LazyQueryExecFunction<string, OperationVariables>,
) => {
  setState({ ...state, loading: true, error: undefined })

  const getData = (fetchFunction, dataKey) => {
    fetchFunction({
      variables: {
        selectedCustomer: searchParams.get('customer'),
        input: {
          startDate: format(startDate, DATE_FORMAT),
          endDate: format(endDate, DATE_FORMAT),
          ...selectedInputs,
        },
      },
    })
      .then((result) => {
        setState({
          data: result.data[String(dataKey)],
          loading: false,
          error: result.error || null,
        })
      })
      .catch((err) => {
        captureException(err)
        setState({
          data: null,
          loading: false,
          error: err,
        })
      })
  }

  if (
    selectedInputs.metric === Metric.UTILIZATION &&
    selectedInputs.dimension === Dimension.DATE &&
    selectedInputs.segment === Segment.NO_VALUE
  ) {
    getData(getUtilAlertsNoValue, 'environmentHealthUtilAndAlerts')
  } else {
    setState({
      data: null,
      loading: false,
      error: undefined,
    })
  }
}
