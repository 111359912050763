import { Box, Typography } from '@mui/material'

import DateButton from './DateButton'
import { MonthPickerProps } from './MonthPicker'

const YearSelection: React.FC<
  {
    changeYear(year: number): void
    date: Date
    updateDate(newDate: Date): void
    twoYearsAgo: Date
    lastYear: Date
    currentYear: Date
  } & Pick<MonthPickerProps, 'isYearDisabled'>
> = ({
  changeYear,
  date,
  updateDate,
  twoYearsAgo,
  lastYear,
  currentYear,
  isYearDisabled,
}) => {
  const activeYear = date.getFullYear()
  const twoYearsAgoNumber = twoYearsAgo.getFullYear()
  const lastYearNumber = lastYear.getFullYear()
  const currentYearNumber = currentYear.getFullYear()

  const handleYearChange = (year: number) => {
    changeYear(year)
    const newDate = new Date(date)
    newDate.setFullYear(year)
    updateDate(newDate)
  }

  return (
    <Box>
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          fontWeight: '600',
          marginBottom: '8px',
          textAlign: 'center',
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        Year
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gridGap: '8px',
          marginBottom: '12px',
        }}
      >
        <DateButton
          label={twoYearsAgoNumber}
          onClick={() => handleYearChange(twoYearsAgoNumber)}
          isActive={activeYear === twoYearsAgoNumber}
          isDisabled={isYearDisabled?.(twoYearsAgo)}
        />
        <DateButton
          label={lastYearNumber}
          onClick={() => handleYearChange(lastYearNumber)}
          isActive={activeYear === lastYearNumber}
          isDisabled={isYearDisabled?.(lastYear)}
        />
        <DateButton
          label={currentYearNumber}
          onClick={() => handleYearChange(currentYearNumber)}
          isActive={activeYear === currentYearNumber}
          isDisabled={isYearDisabled?.(currentYear)}
        />
      </Box>
      {/* Put month label here since there's no where else to position it with react date picker */}
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          fontWeight: '600',
          marginBottom: '8px',
          textAlign: 'center',
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        Month
      </Typography>
    </Box>
  )
}

export default YearSelection
