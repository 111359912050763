/* eslint-disable security/detect-object-injection */
import { FieldFunctionOptions } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

export const mergeUtil = <T>(
  existing: Array<T> | undefined,
  incoming: Array<T>,
  opts: FieldFunctionOptions | undefined = undefined,
  /**
   * If set to true, existing values in the cache beyond the limit will be removed.
   * This is useful if you don't want to present all of the previously paginated data
   * from the cache, and would rather present only the first page from the cache.
   */
  excludeExistingPastLimit = false,
): Array<T> => {
  if (!existing) {
    return incoming
  }

  let offset = 0
  let limit = incoming.length

  if (opts && opts.args && opts.args.input) {
    offset = opts.args.input.pagination.offset
    limit = opts.args.input.pagination.limit || limit
  } else if (opts && opts.args && opts.args.pagination) {
    offset = opts.args.pagination.offset
    limit = opts.args.pagination.limit || limit
  }

  const endIndex = excludeExistingPastLimit ? offset + limit : undefined
  const mergedValues = existing ? existing.slice(0, endIndex) : []

  // iterate over list of incoming and replace existing in-cache array of tickets
  // using offest ensures replacement starts after offset point
  for (let i = 0; i < incoming.length; i++) {
    // eslint-disable-next-line security/detect-object-injection
    mergedValues[offset + i] = incoming[i]
  }

  return mergedValues
}

export const cloneAndAppendMergedData = <
  T,
  TFieldName extends keyof T = keyof T,
>(
  incoming: T,
  fieldName: TFieldName,
  mergedData: T[TFieldName],
): T => {
  const clonedIncomingData = cloneDeep(incoming)
  clonedIncomingData[fieldName] = mergedData

  return clonedIncomingData
}
