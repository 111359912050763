import { formatDistanceToNowStrict } from 'date-fns'
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
// import emptyState from '@app-assets/ticketing-empty-state.svg'

import { useTicketEditContext } from '@hooks/index'
import { TicketRelatedUnion, TicketingRelatedCase } from '@models/Tickets'
import { TableCell, TableHeader } from '@common/Table'
import TicketIdCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketIdCell'
import {
  buildGenericCell,
  buildGenericHeader,
} from '@common/Table/utils/table.utils'
import { formatPriority } from '@utils/Common'
import TicketPriorityCell from '@components/Tickets/TicketLibrary/TicketLibraryTable/cells/TicketPriorityCell'
import { ErrorLabel } from '@common/ErrorLabel'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTableRow from '@common/Table/components/CommonTableRow'
import Icon from '@common/Icon'

type RelatedCases = {
  tickets: TicketingRelatedCase[]
  onClick: (ticket: TicketingRelatedCase) => void
  focusedRelatedCase: TicketingRelatedCase
}

const RelatedCases: React.FC<RelatedCases> = ({
  tickets,
  onClick,
  focusedRelatedCase,
}) => {
  const theme = useTheme()
  const { isFetchLoading: loading } = useTicketEditContext()

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const sortRelatedCaseColumn = (rowA, rowB, column) => {
    const rowAName = rowA.original[column].toLowerCase()
    const rowBName = rowB.original[column].toLowerCase()

    if (rowAName < rowBName) {
      return -1
    }

    if (rowBName < rowAName) {
      return 1
    }

    return 0
  }

  const columnDef: ColumnDef<TicketRelatedUnion, any>[] = useMemo(() => {
    const columnHelper = createColumnHelper<TicketRelatedUnion>()
    return [
      {
        id: 'ticketSubject',
        accessorFn: (row) => ({
          ticketNumber: row.ticketNumber,
          shortDescription: row.shortDescription,
        }),
        cell: TicketIdCell,
        header: () => buildGenericHeader('Ticket Subject'),
        filterFn: (row: any, _, value) => {
          const name = row.original.ticketNumber.toLowerCase()

          return name.includes(value.toLowerCase())
        },
        sortingFn: (rowA, rowB) =>
          sortRelatedCaseColumn(rowA, rowB, 'ticketNumber'),
      },
      {
        id: 'priority',
        accessorFn: (row) => formatPriority(row.priority, theme),
        cell: (props) => <TicketPriorityCell {...props} />,
        header: () => buildGenericHeader('Priority'),
        sortingFn: (rowA, rowB) =>
          sortRelatedCaseColumn(rowA, rowB, 'priority'),
      },
      columnHelper.accessor('state', {
        cell: (props) =>
          props.getValue() ? (
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              noWrap
              variant="body2"
            >
              {props.getValue()}
            </Typography>
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Status'),
        sortDescFirst: true,
        sortingFn: (rowA, rowB) => sortRelatedCaseColumn(rowA, rowB, 'state'),
      }),
      columnHelper.accessor('type', {
        cell: (props) =>
          props.getValue() ? buildGenericCell(props) : <ErrorLabel />,
        header: () => buildGenericHeader('Type'),
        sortingFn: (rowA, rowB) => sortRelatedCaseColumn(rowA, rowB, 'type'),
        sortDescFirst: true,
      }),
      columnHelper.accessor('sysCreatedOn', {
        cell: (props) => {
          props.getValue() ? (
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              noWrap
              variant="body2"
            >
              {formatDistanceToNowStrict(new Date(props.getValue()))}
            </Typography>
          ) : (
            <ErrorLabel />
          )
        },
        header: () => buildGenericHeader('Age'),
        sortingFn: (rowA, rowB) =>
          sortRelatedCaseColumn(rowA, rowB, 'sysCreatedOn'),
        sortDescFirst: true,
      }),
      columnHelper.accessor('sysUpdatedOn', {
        cell: (props) =>
          props.getValue() ? (
            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              noWrap
              variant="body2"
            >
              {formatDistanceToNowStrict(new Date(props.getValue()), {
                addSuffix: true,
              })}
            </Typography>
          ) : (
            <ErrorLabel />
          ),
        header: () => buildGenericHeader('Last Updated'),
        sortingFn: (rowA, rowB) =>
          sortRelatedCaseColumn(rowA, rowB, 'sysUpdatedOn'),
        sortDescFirst: true,
      }),
    ]
  }, [])

  const table = useReactTable<TicketRelatedUnion>({
    columns: columnDef,
    data: tickets,
    enableSortingRemoval: false,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getRowId: (row) => row.sysId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
  })

  const renderContent = () => {
    return table.getRowModel().rows.map((row) => (
      <CommonTableRow
        key={row.id}
        isSelected={
          focusedRelatedCase.ticketNumber === row.original.ticketNumber
        }
        tabIndex={0}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            cell={cell}
            onClick={() => onClick(row.original)}
          />
        ))}
      </CommonTableRow>
    ))
  }

  return (
    <Box
      sx={{
        padding: '1rem',
      }}
    >
      {!loading && !!(tickets.length === 0) ? (
        <Box
          sx={{
            textAlign: 'center',
            margin: '12px auto',
          }}
        >
          <Icon
            size={200}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              fill: theme.vars.palette.secondary.light,
            })}
            variant="ticketsEmptyState"
          />

          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.text.secondary,
              }),
            })}
          >
            There are currently no related cases
          </Typography>
        </Box>
      ) : (
        <CommonTableContainer data-testid="related-cases-table-container">
          <CommonTable>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => {
                    let tabWidth
                    if (index === 0) {
                      tabWidth = '30%'
                    } else {
                      tabWidth = '14%'
                    }
                    return (
                      <TableHeader
                        key={header.id}
                        header={header}
                        width={tabWidth}
                      />
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody>{renderContent()}</tbody>
          </CommonTable>
        </CommonTableContainer>
      )}
    </Box>
  )
}

export default RelatedCases
