import { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Context } from '@components/App'
import {
  GET_DEEPWATCH_EXPERTS_DASHBOARD,
  GET_DEEPWATCH_EXPERTS_DASHBOARD_WITH_MODULES,
  GetDeepwatchExpertsResponse,
} from '@queries/dwExperts'
import Icon from '@common/Icon'
import { AlertSeverity, useToast } from '@hooks/useToast'

import { AllCustomers } from '../AllCustomers'
import MyCustomers from '../MyCustomers/MyCustomers'
import MCEmptyState from '../MyCustomers/MCEmptyState'

import './DeepwatchExpertsHome.scss'

const DeepwatchExpertsHome = (): JSX.Element => {
  const {
    state: { user },
  } = useContext(Context)
  const { featureModulesRenewalDatesColumns } = useFlags()

  const { handleShowToast } = useToast()
  const [selectedTab, setSelectedTab] = useState(0)

  const {
    data: { getSquadDashboard: customerHighlights } = { getSquadDashboard: [] },
    loading,
  } = useQuery<GetDeepwatchExpertsResponse>(
    featureModulesRenewalDatesColumns
      ? GET_DEEPWATCH_EXPERTS_DASHBOARD_WITH_MODULES
      : GET_DEEPWATCH_EXPERTS_DASHBOARD,
    {
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        handleShowToast(AlertSeverity.Error, error.toString())
      },
    },
  )

  const bookmarkedCustomers = customerHighlights.filter(
    (cust) => cust.isBookmarkedCustomer,
  )

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const renderSelectedTab = () => {
    if (!selectedTab) {
      if (bookmarkedCustomers && bookmarkedCustomers.length === 0) {
        return (
          <MCEmptyState
            customerHighlights={customerHighlights}
            loading={loading && !customerHighlights.length}
          />
        )
      }

      return (
        <MyCustomers
          bookmarkedCustomers={bookmarkedCustomers}
          loading={loading && !customerHighlights.length}
        />
      )
    }

    return (
      <AllCustomers
        customerHighlights={customerHighlights}
        loading={loading && !customerHighlights.length}
      />
    )
  }

  return (
    <Box
      data-testid="dw-experts-home"
      id="dw-experts-home"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        padding: '1.5rem',
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
        }),
      })}
    >
      <div className="dw-experts-dashboard-content-bar">
        <Typography variant="h5" color="textPrimary">
          {`Welcome, ${user.firstName}`}
        </Typography>
        <div className="dw-experts-dashboard-info">
          <Icon variant={'informationCircleOutline'} />

          <Typography fontWeight={500} variant="body2" color="textPrimary">
            All data is updated daily
          </Typography>
        </div>
      </div>

      <div className="dw-experts-tabs">
        <Tabs onChange={handleTabChange} value={selectedTab}>
          <Tab label="My Customers" />
          <Tab label="All" />
        </Tabs>

        {renderSelectedTab()}
      </div>
    </Box>
  )
}

export default DeepwatchExpertsHome
