import { useContext, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Tab, Tabs } from '@mui/material'

import { Context } from '@components/App'
import {
  GET_TICKET_ADDITIONAL_DETAILS,
  GET_TICKET_MTTX,
  GET_TICKET_RELATED_CASES,
  TicketAdditionalDetailsResponse,
  TicketMttxResponse,
  TicketRelatedCasesResponse,
} from '@queries/ticket'
import TicketLibrarySideSheet from '@components/Tickets/TicketLibrary/TicketLibrarySideSheet/TicketLibrarySideSheet'
import { TicketLibrarySideSheetFallback } from '@components/Tickets/TicketLibrary/TicketLibrarySideSheetFallback'
import { TicketingRelatedCase } from '@models/Tickets'

import TicketEditCard from './TicketEditCard'
import RelatedCases from './RelatedCases'
import TicketDataHandler from './TicketDataHandler'
import { emptyRelatedCase } from '../utils'
import MttxTab from './MTTX/MttxTab'
import AdditionalDetailsTab from './AdditionalDetails/AdditionalDetailsTab'

const TicketAdditionalDetails: React.FC = () => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  const [focusedRelatedCase, setFocusedRelatedCase] = useState(emptyRelatedCase)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setSelectedTab(value)
  }

  const closeSideSheet = () => {
    setFocusedRelatedCase(emptyRelatedCase)
    setIsOpen(false)
  }

  const onClick = (relatedCase: TicketingRelatedCase) => {
    if (relatedCase.ticketNumber === focusedRelatedCase.ticketNumber) {
      closeSideSheet()
    } else {
      setFocusedRelatedCase(relatedCase)
      setIsOpen(true)
    }
  }

  return (
    <>
      <TicketDataHandler<TicketRelatedCasesResponse>
        graphqlQuery={GET_TICKET_RELATED_CASES}
        renderContent={(data) => (
          <TicketEditCard data-testid="ticket-additional-details">
            <Tabs onChange={handleTabChange} value={selectedTab}>
              <Tab label="Additional Details" value={0} />
              {!isDWEmployee && (
                <Tab
                  label={`Related Cases (${data.relatedCases?.length || 0})`}
                  value={1}
                />
              )}
              <Tab label="MTTx" value={2} />
            </Tabs>

            <Box hidden={selectedTab !== 0} role="tabpanel">
              <TicketDataHandler<TicketAdditionalDetailsResponse>
                graphqlQuery={GET_TICKET_ADDITIONAL_DETAILS}
                renderContent={(data) => {
                  return <AdditionalDetailsTab data={data} />
                }}
              />
            </Box>

            <Box hidden={selectedTab !== 1} role="tabpanel">
              <RelatedCases
                tickets={data.relatedCases}
                onClick={onClick}
                focusedRelatedCase={focusedRelatedCase}
              />
            </Box>

            <Box hidden={selectedTab !== 2} role="tabpanel">
              <TicketDataHandler<TicketMttxResponse>
                graphqlQuery={GET_TICKET_MTTX}
                renderContent={(data) => <MttxTab data={data} />}
              />
            </Box>
          </TicketEditCard>
        )}
      />
      <ErrorBoundary
        fallbackRender={() => (
          <TicketLibrarySideSheetFallback
            open={isOpen}
            closeSideSheet={closeSideSheet}
            title="Ticket Details"
          />
        )}
      >
        <TicketLibrarySideSheet
          isOpen={isOpen}
          closeSideSheet={closeSideSheet}
          focusedTicket={focusedRelatedCase ?? emptyRelatedCase}
        />
      </ErrorBoundary>
    </>
  )
}

export default TicketAdditionalDetails
