import { Theme } from '@mui/material'

import { IconVariant } from '@common/Icon/Icons'

export interface FormattedPriority {
  text: string
  icon: IconVariant
  color?: string
  order: string
}

export const formatEmptyField = (
  value: string | Array<any> | null | undefined,
): string | Array<any> => {
  if (value === null || value === undefined || !value.length) {
    return '--'
  }
  return value
}

export const formatPriority = (
  priority: string,
  theme: Theme,
): FormattedPriority => {
  let priorityIcon: IconVariant
  let priorityColor: string | undefined
  let priorityOrder: string
  switch (priority) {
    case 'Critical':
      priorityIcon = 'skull' as IconVariant
      priorityColor = theme.vars.palette.severity.critical
      priorityOrder = '4'
      break
    case 'High':
      priorityIcon = 'alertCircle' as IconVariant
      priorityColor = theme.vars.palette.severity.high
      priorityOrder = '3'
      break
    case 'Moderate':
      priorityIcon = 'disc' as IconVariant
      priorityColor = theme.vars.palette.severity.medium
      priorityOrder = '2'
      break
    case 'Low':
      priorityIcon = 'arrowDownCircleSharp' as IconVariant
      priorityColor = theme.vars.palette.severity.low
      priorityOrder = '1'
      break
    case 'Informational':
      priorityIcon = 'informationCircleSharp' as IconVariant
      priorityColor = theme.vars.palette.severity.info
      priorityOrder = '0'
      break
    default:
      priorityIcon = 'skull' as IconVariant
      priorityColor = theme.vars.palette.text.primary
      priorityOrder = '4'
      break
  }

  return {
    text: priority,
    icon: priorityIcon,
    color: priorityColor,
    order: priorityOrder,
  }
}

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
