import { useQuery } from '@apollo/client'
import { Box, Skeleton, Typography } from '@mui/material'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useRef, useState } from 'react'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import { ComponentError } from '@common/ComponentError'
import { NoResults } from '@common/NoResults'
import { TableHeader } from '@common/Table'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import {
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { CYBER_ASSISTANT_VENDOR_USAGE } from '@queries/cyberAssistant'
import CommonTableRow from '@common/Table/components/CommonTableRow'

import { EmptyDataAnswer } from '../EmptyDataAnswer/EmptyDataAnswer'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import { AnswerId } from '../CyberAssistantQuestions'
export interface VendorUsage {
  date: string
  vendor: string
  dailyIngestPercent: number
}

const VendorUsageTable = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const {
    data: { cyberAssistantVendorUsage } = { cyberAssistantVendorUsage: [] },
    error,
    loading,
  } = useQuery(CYBER_ASSISTANT_VENDOR_USAGE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer,
    },
  })
  // TODO: Update in SON-5563 if needed
  const filteredData = useMemo(
    () => cyberAssistantVendorUsage.filter(({ vendor }) => vendor !== null),
    [cyberAssistantVendorUsage],
  )

  const tableRef = useRef<HTMLDivElement>(null)

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'dailyIngestPercent', desc: true },
  ])

  //? date is the same for all objects in vendorUsage, so we can just grab the first one.
  const formattedDate =
    filteredData.length && filteredData[0].date
      ? format(new Date(filteredData[0].date), 'LLL, d YYY')
      : null

  const columnHelper = createColumnHelper<VendorUsage>()
  const columnDef = useMemo(
    () => [
      columnHelper.accessor('vendor', {
        id: 'vendor',
        cell: (props) => {
          return (
            <Typography color={'textSecondary'}>{props.getValue()}</Typography>
          )
        },
        header: () => buildGenericHeader('VENDOR'),
        sortingFn: 'textCaseSensitive',
      }),
      columnHelper.accessor('dailyIngestPercent', {
        id: 'dailyIngestPercent',
        cell: (props) => {
          return (
            <Typography color={'textSecondary'}>
              {(props.getValue() * 100).toFixed(2)}%
            </Typography>
          )
        },
        header: () => buildGenericHeader('% DAILY USAGE'),
        sortingFn: 'auto',
      }),
    ],
    [],
  )

  const {
    columnSizing,
    onColumnSizingChange,
    tableHeaderRefs,
    tableContainerRef,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<VendorUsage>({
    columns: columnDef,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    manualSorting: false,
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    onColumnSizingChange,
    state: {
      sorting,
      columnSizing,
    },
  })

  const render = () => {
    const COL_SPAN = 6
    // error state
    if (error) {
      return (
        <tbody>
          <tr>
            <StyledTableCell
              align="center"
              colSpan={COL_SPAN}
              style={{ border: 'none', padding: '50px' }}
            >
              <ComponentError
                errorText="No Ingest Data Available"
                errorSubText="There is no ingest data currently available at the moment."
              />
            </StyledTableCell>
          </tr>
        </tbody>
      )
    }

    // no results found
    if (!loading && cyberAssistantVendorUsage.length === 0) {
      return (
        <tbody>
          <tr>
            <StyledTableCell
              align="center"
              colSpan={COL_SPAN}
              sx={{ border: 'none', padding: '50px' }}
            >
              <NoResults />
            </StyledTableCell>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody data-testid="vendor-usage-table-body">
        {loading && cyberAssistantVendorUsage.length === 0
          ? buildSkeletonRows(table.getAllColumns())
          : table.getRowModel().rows.map((row) => {
              return (
                <MemoizedTanStackTableRow
                  key={row.id}
                  rowId={row.id}
                  testId="vendor-usage-table-row"
                  isSelected={false}
                  toggleSelected={row.toggleSelected}
                  visibleCells={row.getVisibleCells()}
                  isDisabled
                />
              )
            })}
      </tbody>
    )
  }

  const renderTextHeading = () => {
    if (loading) {
      return (
        <Skeleton sx={{ marginBottom: '1rem' }} data-testid="skeleton-loader" />
      )
    }
    if (!formattedDate) {
      return <EmptyDataAnswer />
    }
    return (
      <Box sx={{ marginBottom: '1rem' }}>
        As of {formattedDate} the following sets of vendor data are being
        ingested.
      </Box>
    )
  }

  return (
    <Box sx={{ margin: '1rem' }}>
      {renderTextHeading()}
      <CommonTableContainer
        data-testid="vendor-usage-table-container"
        ref={mergeRefs(tableContainerRef, tableRef)}
      >
        <CommonTable
          data-testid="vendor-usage-table"
          sx={{ tableLayout: 'auto' }}
        >
          <thead data-testid="vendor-usage-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <CommonTableRow key={headerGroup.id} isSelected={false}>
                {headerGroup.headers.map((header, index) => (
                  <TableHeader<VendorUsage>
                    key={header.id}
                    header={header}
                    thRef={tableHeaderRefs[`${index}`]}
                    width={columnSizing[header.id]}
                    table={table}
                    onResizeHandleMouseUp={handleMouseUp}
                    onResizeHandleMouseDown={handleMouseDown}
                    onResetColumnSize={resetColumnSize}
                  />
                ))}
              </CommonTableRow>
            ))}
          </thead>
          {render()}
        </CommonTable>
      </CommonTableContainer>
      <FeedbackForm answerId={AnswerId.VENDOR_USAGE} />
    </Box>
  )
}

export default VendorUsageTable
