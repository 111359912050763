import { Button } from '@mui/material'

interface DateButtonProps {
  label: string | number
  onClick?(): void
  isActive: boolean
  isDisabled?: boolean
}

const DateButton = ({
  label,
  onClick,
  isActive,
  isDisabled,
}: DateButtonProps) => {
  return (
    <Button
      sx={(theme) => ({
        width: '100%',
        height: '45px',
        backgroundColor: theme.vars.palette.background.paper,
        color: theme.vars.palette.text.primary,
        borderRadius: '5px',
        border: `1px solid ${theme.vars.palette.text.primary}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem 0',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '1rem', // 16px to rem
        lineHeight: '120%',
        ...(isActive && {
          backgroundColor: theme.vars.palette.primary.main,
          color: theme.vars.palette.text.secondary,
        }),
        ...(isDisabled && {
          backgroundColor: 'transparent',
          color: theme.vars.palette.text.primary,
          cursor: 'default',
        }),
        '&:hover': {
          color: theme.vars.palette.secondary.dark,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        },
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
          color: theme.vars.palette.text.primary,
          ...(isActive && {
            backgroundColor: theme.vars.palette.text.primary,
            color: theme.vars.palette.secondary.dark,
          }),
        }),
      })}
      onClick={onClick}
      disabled={isDisabled}
      data-isActive={isActive}
    >
      {label}
    </Button>
  )
}

export default DateButton
