import { SideSheet } from '@common/SideSheet'
import { EnvironmentHealthBan } from '@queries/environmentHealth'

import EnvHealthSideSheetContent from './EnvHealthSidesheetContent'
import { useEnvironmentHealthBanSidesheetContext } from './EnvironmentHealthBanSidesheetContext'

interface BanSideSheetProps {
  data: EnvironmentHealthBan | null
}

const EnvHealthBanSideSheet: React.FC<BanSideSheetProps> = ({ data }) => {
  const { isOpen, setIsOpen } = useEnvironmentHealthBanSidesheetContext()
  return (
    <SideSheet
      data-testid="eh-ban-side-sheet"
      open={isOpen}
      closeSideSheet={() => setIsOpen(false)}
      title="License Utilization"
    >
      <EnvHealthSideSheetContent data={data} isOpen={isOpen} />
    </SideSheet>
  )
}

export default EnvHealthBanSideSheet
