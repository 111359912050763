import { Box, Tooltip, Typography } from '@mui/material'

import { IconVariant } from '@common/Icon/Icons'
import Icon from '@common/Icon'

export enum TickDirection {
  UP,
  DOWN,
  NEUTRAL,
  NOT_ENOUGH_DATA,
}

export interface DeltaProps {
  delta: number | null
  skipAbbreviaion?: boolean
  suffix?: string
  tooltipMessage?: string
}

type TickDirectionIconMap = {
  [key in TickDirection]: IconVariant
}

export const tickDirectionIconMappings: TickDirectionIconMap = {
  [TickDirection.UP]: 'arrowUpCircleOutline',
  [TickDirection.DOWN]: 'arrowDownCircleOutline',
  [TickDirection.NEUTRAL]: 'removeCircleOutline',
  [TickDirection.NOT_ENOUGH_DATA]: 'alertCircleOutline',
}

const Delta = ({
  delta,
  skipAbbreviaion = false,
  suffix = '',
  tooltipMessage = 'Not enough data to calculate a trend',
}: DeltaProps): JSX.Element => {
  let tickDirection: TickDirection

  if (delta) {
    tickDirection = delta > 0 ? TickDirection.UP : TickDirection.DOWN
  } else if (delta === 0) {
    tickDirection = TickDirection.NEUTRAL
  } else {
    tickDirection = TickDirection.NOT_ENOUGH_DATA
  }

  const abbreviate = (delta: number): string => {
    const absDelta = Math.abs(delta)

    if (absDelta < 1000) {
      return `${absDelta}`
    } else if (absDelta < 1000000) {
      return `${(absDelta / 1000).toFixed(1)}K`
    } else if (absDelta < 1000000000) {
      return `${(absDelta / 1000000).toFixed(1)}M`
    } else {
      return `${(absDelta / 1000000000).toFixed(1)}B`
    }
  }

  const deltaValue = () => {
    if (!delta) {
      return (
        <Typography
          sx={(theme) => ({
            color: theme.vars.palette.secondary.contrastText,
            ...theme.applyStyles('dark', {
              color: theme.vars.palette.text.secondary,
            }),
          })}
          component="span"
          variant="body2"
        >
          --
        </Typography>
      )
    }
    return (
      <Typography
        sx={(theme) => ({
          color: theme.vars.palette.secondary.contrastText,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        component="span"
        variant="body2"
      >{`${skipAbbreviaion ? Math.abs(delta) : abbreviate(delta)}${suffix}`}</Typography>
    )
  }

  const deltaInfo = (
    <Box
      data-testid={'delta'}
      sx={(theme) => ({
        color: theme.vars.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
      })}
    >
      <Icon
        variant={tickDirectionIconMappings[`${tickDirection}`]}
        size={18}
        sx={(theme) => ({
          color: theme.vars.palette.secondary.contrastText,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      />
      {deltaValue()}
    </Box>
  )

  if (tickDirection === TickDirection.NOT_ENOUGH_DATA) {
    return (
      <Tooltip title={tooltipMessage} placement="top-start">
        <Box>{deltaInfo}</Box>
      </Tooltip>
    )
  }

  return deltaInfo
}

export default Delta
