import { Box, Button, Typography } from '@mui/material'

import Icon from '@common/Icon'

export const ErrorFallback = () => {
  const refreshClick = () => {
    window.location.reload()
  }

  return (
    <Box
      data-testid="errorfallback"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Icon
        variant="errorIcon"
        size={180}
        sx={(theme) => ({
          fill: theme.vars.palette.secondary.lighter,
          stroke: theme.vars.palette.secondary.dark,
        })}
      />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}
      >
        <Typography
          variant="h5"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
            width: '100%',
          }}
        >
          Something went wrong.
        </Typography>
        <Typography variant="body1">
          An error has occurred, check your connection then reload the page.
        </Typography>
        <Typography variant="body1">
          If the problem persists please let us know.
        </Typography>
      </Box>
      <Button onClick={refreshClick} variant="text">
        Reload page
      </Button>
    </Box>
  )
}
