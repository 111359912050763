/* eslint-disable security/detect-object-injection */
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Avatar, Box, Typography } from '@mui/material'

import { GET_TICKET_CONTACT } from '@queries/ticket'
import { useAvatarInitials } from '@hooks/useAvatarInitials'

import { TicketContactData } from '../../Types'
import {
  mapSettingToLabel,
  ngMdrMapSettingsToLabel,
} from './TicketContact.utils'
import TicketDataHandler from '../TicketDataHandler'
import TicketEditCard from '../TicketEditCard'

// Note for ticket edit v2+: When we add the select component for customer contact,`buildSelectOptions` in `TicketContact.utils` can be used.
// Link to original PR w/ select component: https://bitbucket.org/deepwatch/sonar-app/pull-requests/943

interface TicketContactContentProps {
  data: TicketContactData
}

const TicketContactContent: React.FC<TicketContactContentProps> = ({
  data,
}) => {
  const { featureNgMdr } = useFlags()

  const avatarInitials = useAvatarInitials

  const settingToLabelMap = featureNgMdr
    ? ngMdrMapSettingsToLabel
    : mapSettingToLabel

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {Object.entries(settingToLabelMap).map(([key, value]) => {
        if (key === 'contactUser') {
          const contactUserValue = data[key]
          const [firstName, lastName] = contactUserValue?.split(' ') ?? '--'

          return (
            <Box
              data-testid={`ticket-contact-${key}`}
              key={value}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography color="textPrimary" fontWeight={600} variant="body2">
                {value}
              </Typography>

              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '0.5rem',
                  paddingTop: '0.25rem',
                }}
              >
                <Avatar variant="cust" data-testid="user-avatar">
                  {avatarInitials({ firstName, lastName })}
                </Avatar>
                <Typography
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                  variant="body2"
                >
                  {contactUserValue ?? '--'}
                </Typography>
              </Box>
            </Box>
          )
        }

        return (
          <Box data-testid={`ticket-contact-${key}`} key={key}>
            <Typography color="textPrimary" fontWeight={600} variant="body2">
              {value}
            </Typography>

            <Typography
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                paddingTop: '0.25rem',
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
              variant="body2"
            >
              {data[key] ?? '--'}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

const TicketContact: React.FC = () => {
  return (
    <TicketEditCard data-testid="ticket-contact">
      <TicketDataHandler<TicketContactData>
        graphqlQuery={GET_TICKET_CONTACT}
        renderContent={(data) => <TicketContactContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketContact
