import { ApolloError } from '@apollo/client'
import { ResponsiveLine, Serie } from '@nivo/line'
import { format } from 'date-fns'
import { Box, useColorScheme, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { themeConfig } from '@common/nivo/utils'
import ComponentError from '@common/ComponentError/ComponentError'
import { EnvironmentHealthUtilAlertsNoValue } from '@models/EnvHealth'
import { colors } from '@design-system/theme'
import PurpleDashedLine from '@common/SVGs/PurpleDashedLine'
import { useEnvironmentHealthChartDetailsSideSheet } from '@components/EnvironmentHealth/Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import { useXAxisDateFormatting } from '@hooks/index'

import {
  chunkPastPeriodUtilization,
  chunkSourceTypesByDate,
  compareByDateAscending,
  modifyMaxValueScale,
} from '../SourceUtilization.utils'
import { PastPeriodChartTooltip } from '../SuToolTip/SuToolTip'

interface PastPeriodChartProps {
  data: EnvironmentHealthUtilAlertsNoValue | null
  error?: ApolloError
}

const chartMargins = {
  top: 50,
  right: 35,
  bottom: 45,
  left: 50,
} as const

const PastPeriodChart: React.FC<PastPeriodChartProps> = ({ data, error }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()

  const { featureDwLicense } = useFlags()

  const { previousPeriodUtilization } = data ?? {}

  const formattedCurrentDataPoints = chunkSourceTypesByDate(data).sort(
    compareByDateAscending,
  )

  const formattedPreviousDataPoints = chunkPastPeriodUtilization(data).sort(
    compareByDateAscending,
  )

  /**
   * Use the indexes for both current and previous period data points
   * so that we can overlay the lines on top of each
   * other correctly. Using index ensures the x-axis has distinct
   * values that the lines can be plotted against. Otherwise, if either previousPeriod
   * or currentPeriod data set contains values the other doesn't have then the lines start
   * plotting in strange ways.
   * We use the same index in the axisBottom property to identify which dates
   * to display. See below for more info.
   */
  const currentDataPoints = formattedCurrentDataPoints.map((val, index) => ({
    x: index,
    y: val.totalGbForDay,
  }))

  const previousDataPoints = formattedPreviousDataPoints.map((val, index) => ({
    x: index,
    y: val.totalGbForDay,
  }))

  const formattedData: Serie[] = [
    {
      id: 'previousPeriod',
      color: colors.util.three.light,
      data: previousDataPoints,
    },
    {
      id: 'currentPeriod',
      color: colors.brand.secondary.main,
      data: currentDataPoints,
    },
  ]

  const { licenseCapacity } = useEnvironmentHealthChartDetailsSideSheet()

  const formatXAxis = useXAxisDateFormatting(formattedCurrentDataPoints)

  if (
    error ||
    currentDataPoints.length === 0 ||
    previousDataPoints.length === 0
  ) {
    return <ComponentError errorContainerStyles={{ height: '100%' }} />
  }

  // more info - https://stackoverflow.com/a/67499253
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }, index) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          })),
        )}
        fill="none"
        stroke={color}
        style={
          index % 2 === 0
            ? {
                // simulate line will dash stroke when index is even
                strokeDasharray: '10',
                strokeWidth: 1,
              }
            : {
                // simulate line with solid stroke
                strokeWidth: 1,
              }
        }
      />
    ))
  }

  // TODO: update to use ChartLegend once PR is merged: https://github.com/Deepwatchinc/sonar-app/pull/1214
  const LicenseCapacityLine = (data) => {
    const lineYPosition = data.yScale(licenseCapacity)
    return (
      <Box
        component="line"
        sx={(theme) => ({
          stroke: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            stroke: theme.palette.text.secondary,
          }),
        })}
        x1={10}
        y1={lineYPosition}
        x2={data.width - chartMargins.left - chartMargins.right}
        y2={lineYPosition}
        stroke={colors.util.one.lighter}
        strokeDasharray="16"
        data-testid="license-capacity-dashed-line"
      />
    )
  }

  const LicenseCapacityLegend = (data) => {
    /**
     * get start and end dates for previous period to display in legend
     */
    const prevPeriodEndDate = previousPeriodUtilization
      ? previousPeriodUtilization[0].date
      : ''
    const prevPeriodStartDate = previousPeriodUtilization
      ? previousPeriodUtilization[previousPeriodUtilization.length - 1].date
      : ''
    const xMarginOffset = chartMargins.left + chartMargins.right
    const previousPeriodWidth = 200
    const currentLicenseCapacityWidth = featureDwLicense ? 170 : 200
    return (
      <>
        <Box
          component="g"
          transform={`translate(${data.width - xMarginOffset - previousPeriodWidth - currentLicenseCapacityWidth}, -25)`}
        >
          <Box
            sx={(theme) => ({
              stroke: theme.palette.text.primary,
              ...theme.applyStyles('dark', {
                stroke: theme.palette.text.secondary,
              }),
            })}
            component="line"
            x1="-31"
            x2="-10"
            y1="-4"
            y2="-4"
            stroke={colors.util.one.lighter}
            strokeDasharray={4}
            strokeWidth={7}
            strokeDashoffset={3.5}
          />
          <Box
            component="text"
            sx={(theme) => ({
              fill: theme.palette.text.primary,
              fontSize: theme.typography.body2.fontSize,
            })}
          >
            {featureDwLicense
              ? 'Deepwatch license'
              : 'Current license capacity'}
          </Box>
        </Box>
        <Box
          component="g"
          transform={`translate(${data.width - xMarginOffset - previousPeriodWidth}, -25)`}
        >
          <PurpleDashedLine />
          <Box
            component="text"
            sx={(theme) => ({
              fill: theme.palette.text.primary,
              fontSize: theme.typography.body2.fontSize,
            })}
          >
            Previous period {format(prevPeriodStartDate, 'MMM dd')} -{' '}
            {format(prevPeriodEndDate, 'MMM dd')}
          </Box>
        </Box>
      </>
    )
  }

  const largestDataPoint = formattedCurrentDataPoints
    .slice()
    .concat(formattedPreviousDataPoints.slice())
    .sort((a, b) => b.totalGbForDay - a.totalGbForDay)[0].totalGbForDay

  return (
    <Box
      style={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
      id="ppu-chart-container"
      data-testid="ppu-chart-container"
    >
      <ResponsiveLine
        data={formattedData}
        colors={[colors.util.three.light, colors.brand.secondary.main]}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: modifyMaxValueScale(largestDataPoint, licenseCapacity),
        }}
        enableGridX={false}
        enableGridY={true}
        theme={themeConfig(mode, theme)}
        margin={chartMargins}
        enableSlices="x"
        pointSize={0}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'slices',
          'points',
          'mesh',
          DashedSolidLine,
          LicenseCapacityLine,
          LicenseCapacityLegend,
        ]}
        gridYValues={6}
        axisLeft={{
          tickValues: 5,
        }}
        axisBottom={{
          format: (data: number) =>
            formatXAxis(formattedCurrentDataPoints[data as number]?.date),
        }}
        sliceTooltip={(slice) => {
          return (
            <PastPeriodChartTooltip
              nivoSlice={slice}
              formattedCurrentDataPoints={formattedCurrentDataPoints}
            />
          )
        }}
      />
    </Box>
  )
}

export default PastPeriodChart
