import { Box } from '@mui/material'

export const ProfileHorizontalSectionDivider: React.FC = () => {
  return (
    <Box
      component="hr"
      sx={(theme) => ({
        border: `0.5px solid ${theme.vars.palette.secondary.main}`,
        margin: '2rem 0',
        width: '100%',
        ...theme.applyStyles('dark', {
          border: `0.5px solid ${theme.vars.palette.secondary.lighter}`,
        }),
      })}
    />
  )
}
