import { Detections } from './Detections'

export enum ReportType {
  CYBER_INTEL_BRIEF = 'Cyber Intel Brief',
  CUSTOMER_ADVISORY = 'Customer Advisory',
}

export enum ReportLabelType {
  REPORT = 'report',
  VULNERABILITY = 'vulnerability',
  MALWARE = 'malware',
  TOOL = 'tool',
  THREAT_ACTOR = 'threat_actor',
  INTRUSION_SET = 'intrusion_set',
  KILL_CHAIN = 'kill_chain',
  THREAT_TYPE = 'threat_type',
  INDUSTRY = 'industry',
  TACTIC = 'tactic',
  TECHNIQUE = 'technique',
  OTHER = 'other',
}

export type RelatedTicket = {
  sysId: string
  ticketNumber: string
  state: string
}

export type ThreatIntelReportLabel = {
  type: ReportLabelType
  name: string
}

export type ThreatIntelReportContent = {
  heading: string
  level: number
  content: string
  originalMarkdown: string
}

export type ThreatIntelSourceMaterial = {
  name: string
  link: string
} | null

export interface ThreatIntelReportDetail {
  id: string
  title: string
  type: ReportType
  labels: ThreatIntelReportLabel[]
  source: string
  createdDate: string
  overview: string
  associatedDetections: Array<
    Pick<Detections, 'title' | 'mitreTechniques' | 'status' | 'useCase'>
  >
  relatedTickets: Array<RelatedTicket>
  externalReferences: string[]
  relatedIntelReports: string[]
  sections: ThreatIntelReportContent[]
  markAsRead: boolean
  publicUrl: string
  sourceMaterial: ThreatIntelSourceMaterial
  targetedIndustries: string[] | null
}

export type ThreatIntelReport = Omit<ThreatIntelReportDetail, 'sections'>
