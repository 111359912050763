import { ReactNode, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Alert, Box, Collapse, IconButton, Typography } from '@mui/material'

import { Context } from '@components/App'
import { ThreatIntelReportsContextProvider } from '@components/ThreatIntel/ThreatIntelReports/context'
import { ThreatIntelReport } from '@models/ThreatIntel'
import {
  GET_DASHBOARD,
  GetDashboardResponse,
  GetDashboardVariables,
} from '@queries/dashboard'
import { GET_TICKET_METRICS } from '@queries/ticket'
import {
  DashboardBanKeys,
  GET_DASHBOARD_THREAT_INTEL_REPORTS_LIST_QUERY,
  GetDashboardThreatIntelReportsData,
  GetDashboardThreatIntelReportsVariables,
} from '@queries/threatIntel'
import { SideSheet } from '@common/SideSheet'
import { useCustomer } from '@hooks/useCustomer'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { DashboardData } from '@models/index'
import { AlertSeverity, useToast } from '@hooks/useToast'

import { ThreatProtectionSummary } from './charts/ThreatProtectionSummary'
import { CriticalHighOpenTickets } from './charts/CriticalHighOpenTickets'
import { RecentReports } from './RecentReportsWidget'
import { CurrentTicketsWidget } from './CurrentTicketsWidget'
import DashboardBans from './Bans/DashboardBans'
import MyLinksWidget from './MyLinksWidget/MyLinksWidget'
import { shapeMyLinksData } from './MyLinksWidget/MyLinksHelpers'
import { ThreatIntelWidget } from './ThreatIntelWidget'
import TopLogSourcesByUsageTable from './TopLogSourcesByUsage/TopLogSourcesByUsageTable'
import MTTNBan from './Bans/MTTNBan'
import MTTCAckBan from './Bans/MTTCAckBan'
import { ThreatProtectionSummaryProvider } from './charts/ThreatProtectionSummary/ThreatProtectionSummaryContext'

const ticketDetailEmptyState = {
  openWaitingCustomerCount: 0,
  openHighCount: 0,
  openCriticalCount: 0,
  openWaitingDeepwatchCount: 0,
}

const emptyState: Omit<DashboardData, 'threatIntel'> = {
  highlightMetrics: [],
  links: {
    splunk: '',
    servicenow: '',
    threatLabs: '',
    // crowdStrike: '',
    __typename: 'DashboardLink',
  },
  logSourceUsage: [],
  maturityTrend: {
    customer: [],
    deepwatch: [],
  },
  openTicketTrend: [],
  threatSummary: {
    loggedEventCount: 0,
    alertCount: 0,
    totalTicketCount: 0,
    openTicketCount: 0,
    closedTicketCount: 0,
    openCriticalTicketCount: 0,
    closedCriticalTicketCount: 0,
    validatedTicketCount: 0,
    truePositivePercentage: 0,
    isLogEventCountAvailable: true,
  },
  ticketDetail: ticketDetailEmptyState,
}

const initialDashboardReports: ThreatIntelReport[] = []

const Dashboard: React.FC = () => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer,
      customer,
      dateFilter,
    },
  } = useContext(Context)

  const {
    customer: { ngMdrEnabled, sentinelEnabled, isLimitedMdrExperience },
  } = useCustomer()

  const { homePageWarningBanner, featureNgMdr } = useFlags()
  const { handleShowToast } = useToast()

  const [bannerOpen, setBannerOpen] = useState(true)

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false)
  const [sideSheetContent, setSideSheetContent] = useState<ReactNode | null>(
    null,
  )
  const [selectedBan, setSelectedBan] = useState<string | null>(null)

  const openSideSheet = (content: ReactNode, banKey: string) => {
    if (isSideSheetOpen && banKey === selectedBan) {
      setIsSideSheetOpen(false)
      setSelectedBan(null)
    } else {
      setSideSheetContent(content)
      setIsSideSheetOpen(true)
      setSelectedBan(banKey)
    }
  }

  const closeSideSheet = () => {
    setIsSideSheetOpen(false)
    setSideSheetContent(null)
    setSelectedBan(null)
  }

  const getBanTitle = (banKey: string) => {
    switch (banKey) {
      case DashboardBanKeys.MTTN:
        return 'MTTN detail'
      case DashboardBanKeys.MTTCAck:
        return 'MTTCAck detail'
      default:
        return ''
    }
  }

  const startDate = format(dateFilter.startDate, 'yyyy-MM-dd')
  const endDate = format(dateFilter.endDate, 'yyyy-MM-dd')

  const {
    data: { getDashboard: dashboardMetrics } = {
      getDashboard: emptyState,
    },
    loading,
    error: dashboardError,
  } = useQuery<GetDashboardResponse, GetDashboardVariables>(GET_DASHBOARD, {
    variables: {
      selectedCustomer,
      startDate,
      endDate,
    },

    onError: (error) => {
      handleShowToast(AlertSeverity.Error, error.toString())
    },
  })

  const {
    data: { getTicketMetrics: ticketMetrics } = ticketDetailEmptyState,
    loading: loadingTicketMetrics,
  } = useQuery(GET_TICKET_METRICS, {
    variables: {
      selectedCustomer,
      startDate,
      endDate,
    },

    onError: (error) => {
      handleShowToast(AlertSeverity.Error, error.toString())
    },
  })

  const {
    data: { getDashboardThreatIntelReports: dashboardThreatIntelReports } = {
      getDashboardThreatIntelReports: initialDashboardReports,
    },
    loading: advisoriesLoading,
  } = useQuery<
    GetDashboardThreatIntelReportsData,
    GetDashboardThreatIntelReportsVariables
  >(GET_DASHBOARD_THREAT_INTEL_REPORTS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer: selectedCustomer || dwExpertsCustomer.customerShortName,
    },
  })

  const isEmptyLinks = () => {
    return Object.values(dashboardMetrics.links).every(
      (val) => val === '' || val === 'DashboardLink',
    )
  }

  return (
    <Box sx={{ flexDirection: 'column', overflow: 'auto', width: '100%' }}>
      <Box
        id="dashboard-page"
        data-testid="dashboard"
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.common.white,
          padding: '1.5rem',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
          }),
        })}
      >
        {homePageWarningBanner && (
          <Collapse in={bannerOpen}>
            <Alert
              action={
                <IconButton
                  color="inherit"
                  onClick={() => setBannerOpen(false)}
                  size="small"
                >
                  <Icon variant="close" />
                </IconButton>
              }
              icon={<Icon variant="alertCircle" />}
              severity="warning"
              sx={{ marginBottom: '.5rem' }}
              variant="filled"
              data-testid="crowdstrike-banner"
            >
              Deepwatch Operations is not currently experiencing any issue due
              to the Crowdstrike outage. Core MDR monitoring is not impacted.
              Visibility via CrowdStrike sensors is impacted while CrowdStrike
              mitigation steps are being rolled out in a controlled fashion to
              support our customers.
            </Alert>
          </Collapse>
        )}

        <Box
          id="dashboard-bans"
          sx={{
            display: 'grid',
            gap: '1rem',
            gridTemplateColumns:
              featureNgMdr && isLimitedMdrExperience
                ? '1fr 1fr 1fr'
                : '1fr 1fr 1fr 1fr',
            paddingBottom: '1rem',
          }}
        >
          <MTTNBan
            startDate={startDate}
            endDate={endDate}
            openSideSheet={openSideSheet}
          />

          <MTTCAckBan
            startDate={startDate}
            endDate={endDate}
            openSideSheet={openSideSheet}
          />

          <DashboardBans
            data={dashboardMetrics.highlightMetrics}
            loading={loading}
          />
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '2fr fit-content(340px)',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              gridColumn: '1',
            }}
          >
            <CriticalHighOpenTickets
              data={dashboardMetrics.openTicketTrend}
              loading={loading}
              error={dashboardError}
            />
            <ThreatProtectionSummaryProvider
              threatSummaryData={dashboardMetrics.threatSummary}
            >
              <ThreatProtectionSummary
                loading={loading}
                error={dashboardError}
              />
            </ThreatProtectionSummaryProvider>

            {(!featureNgMdr || !isLimitedMdrExperience) && (
              <TopLogSourcesByUsageTable
                loading={loading}
                data={dashboardMetrics.logSourceUsage}
                error={dashboardError}
              />
            )}
          </Box>

          <Box sx={{ gridColumn: '2', minWidth: 0 }}>
            <CurrentTicketsWidget
              customer={isDWEmployee ? dwExpertsCustomer : customer}
              data={ticketMetrics}
              loading={loadingTicketMetrics}
            />

            <Box id="relevant-threat-intel-widget">
              <Typography
                color="textPrimary"
                sx={{ padding: '1rem 0' }}
                variant="h6"
              >
                Relevant threat intel reports
              </Typography>

              {advisoriesLoading ? (
                <Box>
                  <Loader strokeWidth={2} size={50} centered />
                </Box>
              ) : (
                <ThreatIntelReportsContextProvider>
                  <ThreatIntelWidget
                    data={dashboardThreatIntelReports}
                    loading={advisoriesLoading && !dashboardThreatIntelReports}
                  />
                </ThreatIntelReportsContextProvider>
              )}
            </Box>

            <RecentReports selectedCustomer={selectedCustomer} />

            {!isEmptyLinks() && (
              <MyLinksWidget
                data={shapeMyLinksData(dashboardMetrics.links, {
                  ngMdrEnabled: featureNgMdr && ngMdrEnabled,
                  sentinelEnabled: featureNgMdr && sentinelEnabled,
                })}
                loading={loading}
              />
            )}
          </Box>
        </Box>

        <SideSheet
          data-testid="dashboard-sidesheet"
          open={isSideSheetOpen}
          title={getBanTitle(selectedBan as string)}
          closeSideSheet={closeSideSheet}
        >
          {sideSheetContent}
        </SideSheet>
      </Box>
    </Box>
  )
}

export default Dashboard
