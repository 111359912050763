import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import { FormattedPriority } from '@utils/Common'
import Icon from '@common/Icon'

import { TicketRelatedUnion } from '../../../../../models'

const TicketPriorityCell = (
  props: CellContext<TicketRelatedUnion, FormattedPriority>,
) => {
  const { color, icon, text } = props.getValue()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Icon variant={icon} sx={{ color }} />

      <Typography
        noWrap
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default TicketPriorityCell
