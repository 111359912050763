import { alpha, Box, Drawer, DrawerProps, SxProps, Theme } from '@mui/material'
import { useCallback, useEffect, useRef, type ReactNode } from 'react'

import { SideSheetHeader } from './SideSheetHeader'
import { ResizableHandle } from './ResizableHandle'

export const sideSheetFooterCss: SxProps<Theme> = (theme: Theme) => ({
  alignItems: 'flex-end',
  background: alpha(theme.palette.common.white, 0.92),
  borderTop: `1px solid ${theme.vars.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem',
  ...theme.applyStyles('dark', {
    background: alpha(theme.palette.secondary.dark, 0.92),
    borderTop: `1px solid ${theme.vars.palette.secondary.light}`,
  }),

  '.footer-content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '1rem',

    '.footer-button': {
      flex: 'none',
      order: 1,
      flexGrow: 0,
      height: '40px',
    },
  },
})

export interface SideSheetProps {
  children: ReactNode
  sx?: SxProps<Theme>
  open: boolean
  icon?: ReactNode
  title?: string
  renderTitle?: () => ReactNode
  closeSideSheet?: () => void
  footer?: ReactNode
  resizable?: boolean
  'data-testid'?: string
}

export const SideSheet: React.FC<SideSheetProps> = ({
  sx,
  icon,
  open,
  title,
  footer,
  'data-testid': dataTestId,
  children,
  resizable = true,
  renderTitle,
  closeSideSheet,
}) => {
  const refPaper = useRef<HTMLDivElement>(null) // Ref for the root of the sidesheet paper; used by the resize handle to resize the sidesheet

  /** Scroll to the top when the sidesheet content changes */
  const refContent = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (refContent.current !== null) {
      refContent.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [children])

  const handleClose = useCallback<NonNullable<DrawerProps['onClose']>>(
    (event, reason) => {
      // Prevent closing the sidesheet when clicking the backdrop
      if (closeSideSheet && reason !== 'backdropClick') {
        closeSideSheet()
      }
    },
    [closeSideSheet],
  )

  return (
    <Drawer
      PaperProps={{
        ref: refPaper,
        'data-testid': dataTestId,
      }}
      anchor="right"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={[
        {
          '.MuiDrawer-paperAnchorRight': {
            // 64px is to account for TopNav height
            marginTop: `64px`,
            height: `calc(100% - 64px)`,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {resizable ? (
        <ResizableHandle sideSheetRef={refPaper} open={open} />
      ) : null}
      {renderTitle ? renderTitle() : null}
      {!renderTitle && title ? (
        <SideSheetHeader
          title={title}
          closeSideSheet={closeSideSheet}
          icon={icon}
        />
      ) : null}
      <Box
        sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
        ref={refContent}
      >
        {children}
      </Box>
      {footer && <Box sx={sideSheetFooterCss}>{footer}</Box>}
    </Drawer>
  )
}
