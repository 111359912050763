import { Box, BoxProps, styled } from '@mui/material'

interface StyledTableHeader extends BoxProps {
  isOtherColumnBeingResized?: boolean
  width?: string | number
  thRef?: React.RefObject<HTMLTableCellElement>
}

const StyledTableHeader = styled(({ thRef, ...props }: StyledTableHeader) => (
  <Box component="th" {...props} ref={thRef} />
))<StyledTableHeader>(({ isOtherColumnBeingResized, theme, width }) => ({
  backgroundColor: theme.vars.palette.primary.main,
  color: theme.vars.palette.text.secondary,
  padding: '0.75rem 0.5rem',
  position: 'sticky',
  textAlign: 'left',
  textTransform: 'uppercase',
  top: 0,
  width: width ? width : '100%',
  contain: 'layout',
  pointerEvents: isOtherColumnBeingResized ? 'none' : 'auto',
  ':first-of-type': {
    borderTopLeftRadius: '5px',
  },
  ':last-child': {
    borderTopRightRadius: '5px',
  },
  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.main,
  }),
}))

export { StyledTableHeader }
