import { CustomSVGProps } from './types'

const OUTER_PADDING_PERCENT = 10

const MicrosoftLogo: React.FC<CustomSVGProps> = ({ size = 20 }) => {
  const p = OUTER_PADDING_PERCENT / 100 // Convert percentage to decimal

  return (
    <svg
      data-testid="microsoft-logo"
      width={size}
      height={size}
      viewBox={`${-p} ${-p} ${1 + p * 2} ${1 + p * 2}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Top Left Square */}
      <path d="M0 0h.475v.475h-.475z" fill="#F25022" />
      {/* Top Right Square */}
      <path d="M.525 0h.475v.475h-.475z" fill="#7FBA00" />
      {/* Bottom Left Square */}
      <path d="M0 .525h.475v.475h-.475z" fill="#00A4EF" />
      {/* Bottom Right Square */}
      <path d="M.525 .525h.475v.475h-.475z" fill="#FFB900" />
    </svg>
  )
}

export default MicrosoftLogo
